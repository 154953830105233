import axios from "axios"

export const fetchUserProfileAPI = () => axios.get(`${API_V2_URL}user/info`)
export const fetchUserOrientationInfoAPI = () => axios.get(`${ADMIN_API_URL}onboarding-orientation`)
export const createUserOrientationInfoAPI = payload => axios.post(`${ADMIN_API_URL}onboarding-orientation`, payload)
export const updateUserOrientationInfoAPI = payload => axios.put(`${ADMIN_API_URL}onboarding-orientation`, payload)
export const fileUploadOrientationAPI = (name, payload) => axios.put(`${FILE_UPLOAD_API}filename=${name}`, payload)

export const getOnboardingSetupAPI = () => axios
  .get(`${API_URL}onboarding-setup-profile`)

export const createOnboardingSetupAPI = payload => axios
  .post(`${API_URL}onboarding-setup-profile`, payload)

export const updateOnboardingSetupAPI = payload => axios
  .put(`${API_URL}onboarding-setup-profile`, payload)

export const getStripeSecretKeyAPI = () => axios
  .post(`${API_URL}stripe/customers/without-token`)

export const getFeeScheduleAPI = () => axios
  .get(`${API_URL}user/onboarding/fee-schedule`)

export const sendVerifyEmailLinkAPI = payload => axios
  .post(`${API_URL}user/login/send-verification-email`, payload)

export const verifyEmailLinkTokenAPI = payload => axios
  .post(`${API_URL}user/login/verify-email-link-token`, payload)

export const getDisplayScreenAPI = query => axios
  .get(`${API_URL}user/login/office-app/get-display-screen${query}`)

export const getReceiverViewICAAPI = () => axios
  .post(`${API_URL}brokerage-ica/envelopes/receiver-view`)

export const getAllICADocListAPI = () => axios
  .get(`${API_URL}brokerage-ica/envelopes`)

export const updateEnvelopeStatusAPI = payload => axios
  .post(`${API_URL}brokerage-ica/envelopes/sending`, payload)

export const updateAgentEmailAPI = payload => axios
  .put(`${API_V2_URL}user/agent-info/email`, payload)

export const hubspotSubscriptionAPI = payload =>axios.post(`${API_URL}hubspot/subscription`, payload)
