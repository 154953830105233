import React from "react"
import styled from "styled-components"
import colors from "@colors"
import {
  preDesktopWidth,
  maxTabletWidth,
  xsMobile,
  preTabletWidth,
  minDesktopWidth,
  minLargeDesktopWidth,
} from "@consts"
import Slider from "react-slick"
import RadiusSuttle from "images/home-v7/radius-agent-logo-light.png"
import Issac from "images/Graphics/JamesQuintero.png?q=60&h=591&w=657&fm=webp"
import Matthew from "images/Graphics/OtherGuy.png?q=60&h=591&w=530&fm=webp"
import ArrowRight from "images/Graphics/HomeV6/WhiteRightArrow.svg"
import { ATC } from "@fonts"

const Wrap = styled.div`
  margin-top: 70px;
  margin-bottom: 70px;
  position: relative;
  .slick-dots {
    width: 64px;
    position: absolute;
    bottom: 202px;
    right: 1230px;

    li {
      margin: 0 -2px;
    }
  }
  ${preDesktopWidth`
  .slick-dots {
    width: 64px;
    position: absolute;
    bottom: 53px;
    right: unset;
  }
`}

${preTabletWidth`
  .slick-dots {
    position: relative;
    bottom: 40px;
    right: 0px;
  }
`}
`

const SlideWrap = styled.div`
  padding: 200px 0px;
  ${maxTabletWidth(`
    margin-top: 20px;
    padding: 40px 0px;
  `)}
   ${xsMobile(`
    overflow: hidden;
  `)}
`

const ItemsWrap = styled.div`
 /*MAKE SURE ITS RELATIVE*/

  position: relative;
  height: 460px;
  background: ${colors.dreamWhite};
  display: flex;
  padding: 50px 50px;
  width: 85%;
  margin: 0 auto;


  ${minDesktopWidth`
    height: 460px;
  `}

  ${minLargeDesktopWidth`
    height: 490px;
  `}
  

  ${preDesktopWidth(`
    height: 496px;
    flex-direction: column;
    padding: 0px 43px;
    width: 100%;
  `)}

  ${preTabletWidth(`
    height: auto;
    flex-direction: column;
    padding: 0px 43px;
    width: 100%;
  `)}
`

const TextDetails = styled.div`
  width: 65%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 30px;
  margin-top: 30px;
  position: relative;

  ${preDesktopWidth(`
    height: auto;
    width: 65%;
    padding: 30px 0px;
  `)}

  ${preTabletWidth`
    width: 100%;
    padding: 30px 0px;
  `}
  
 

  ${props => props.is50 && `
    width: 50%;
  `}
  > p {
    font-family: ${ATC};
    font-size: 28px;
    line-height: 38px;
    font-weight: 400;
    color: ${colors.black};
    max-width: 640px;
    margin-right: 60px;
    ${preDesktopWidth(`
      height: auto;
      margin-right: 0px;
    `)}
    ${maxTabletWidth(`
      font-size: 22px;
      line-height: 29px;
    `)}

  }
  > h6 {
    font-family: ${ATC};
    font-size: 22px;
    color: ${colors.slateGrey};
    font-weight: 300;
    line-height: 30px;
    margin: 30px 0px;
    ${preDesktopWidth`
      margin: 10px 0px;
      font-family: ${ATC};
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      width: 251px;
    `}

    ${preTabletWidth`
      font-size: 18px;
    `}
  }
`

const ImageWrap = styled.div`
  width: 35%;
  position: absolute;
  ${props => props.is50 && `
    width: 50%;
  `}


  ${minDesktopWidth`
    width: 500px;
    bottom: 0;
    right: 0;
  `}


  ${minLargeDesktopWidth`
    width: 591px;
    bottom: 0;
    right: 0;
  `}
  

  @media(max-width: 1099px) {
    width: 100%;
    bottom: 0;
    right: 0;
  }

  ${preTabletWidth`
    position: static;
  `}
  > img {
    width: 100%;

    ${minDesktopWidth`
      width: 485px;
    `}

    ${minLargeDesktopWidth`
      width: 100%;
    `}

    ${preDesktopWidth`
      position: relative;
      width: 100%;
      width: max-content;
      float: right;
    `}

    ${preTabletWidth`
      max-height: auto;
      margin: 0px auto;
      width: 100%;
    `}
  }
`

const AgentImage = styled.img`
  ${props => props.customheight && `
    @media(max-width: 1099px) {
      height: ${props.customheight};
    }
  `}

    @media(max-width: 767px) {
      height: auto;
    }
`

const SLIDER_SETTINGS = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  adaptiveHeight: true,
}

const OutterWrap = styled.div`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 250px;
    right: 0px;
    height: 550px;
    width: 400px;
    background: transparent;
    // background: transparent no-repeat center;
    // background-image: url(${RadiusSuttle});
    // background-size: contain;
    ${preDesktopWidth(`
      width: 300px;
    `)}
     ${xsMobile(`
        width: 250px;
        top: 400px;
    `)}
  }
`
const HeaderText = styled.h1`
  font-family: Butler;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0.005em;
  text-align: center;
  color: ${colors.black};
`

const CTAWrap = styled.div`
  display: none;
  button {
    display: flex;
    height: 42px;
    cursor: pointer;
    width: max-content;
    border-radius: 0px;
    background: ${colors.new_primary};
    color: ${colors.white};
    font-family: ${ATC};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    border: none;
    width: 130px;
    text-align: center;
    padding: 8px 16px;
    gap: 10px;
  }

  ${preDesktopWidth`
    position: relative;
    top: 6px;
    z-index: 9;
  `}
`

const ArrowSpan = styled.span`
  font-size: 25px;
  position: relative;
  right: -2px;
  top: 4px;
`
const CustomPara = styled.p`
  @media(max-width: 1099px) {
    ${props => props.customParaWidth && `
    width: ${props.customParaWidth || "100%"}
  `}
  }

  @media(max-width: 767px) {
    width: 100%;
  }
`

const Testimonials = ({
  toggleApplyModal,
}) => (
  <OutterWrap>
    <Wrap className="container">
      <HeaderText>Agents from all over the country love our community</HeaderText>
      <Slider {...SLIDER_SETTINGS}>
        <SlideWrap>
          <ItemsWrap>
            <TextDetails>
              <CustomPara customParaWidth="353px">
                {`
                  “The leads come into the app and then I get a notification which makes it easy. 
                  I follow up the leads with my KvCore CRM to set them up on a drip 
                  campaign and this makes the process efficient.”
                `}
              </CustomPara>
              <h6>
                Issac Mead
                <br />
                Greater Denver Metro Area Realtor
              </h6>
              <CTAWrap>
                <button
                  type="button"
                  onClick={() => {
                    toggleApplyModal({
                      bool: true,
                      accountType: "owner",
                      headerText: "Nice to meet you!",
                      subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
                    })
                  }}
                >
                  Apply
                  <ArrowSpan>
                    <img src={ArrowRight} alt="arrow" />
                  </ArrowSpan>
                </button>
              </CTAWrap>
            </TextDetails>
            <ImageWrap>
              <AgentImage
                height="591px"
                width="591px"
                src={Issac}
                alt=" Isaac Mead, Greater Denver Metro Area Realtor"
              />
            </ImageWrap>
          </ItemsWrap>
        </SlideWrap>
        <SlideWrap>
          <ItemsWrap>
            <TextDetails is50>
              <p>
                {`
                “The Radius community in the app is a great way to network. 
                It’s a good way to interact with others and ask questions amongst my peers.”
                `}
              </p>
              <h6>
                Matthew Throckmorton
                <br />
                Temecula Realtor
              </h6>
              <CTAWrap>
                <button
                  type="button"
                  onClick={() => {
                    toggleApplyModal({
                      bool: true,
                      accountType: "owner",
                      headerText: "Nice to meet you!",
                      subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
                    })
                  }}
                >
                  Apply
                  <ArrowSpan>
                    <img src={ArrowRight} alt="arrow" />
                  </ArrowSpan>
                </button>
              </CTAWrap>
            </TextDetails>
            <ImageWrap is50 customBottom="231px">
              <AgentImage
                customheight="396px"
                src={Matthew}
                alt="Matthew Throckmorton, Temecula Realtor"
                className="Testimonial3"
              />
            </ImageWrap>
          </ItemsWrap>
        </SlideWrap>
      </Slider>
    </Wrap>
  </OutterWrap>
)

export default Testimonials
