import {
  Divider, Select, Space,
} from "antd"
import { useWindowSize } from "services/Utils"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import React, { useState, useRef } from "react"
import {
  SelectFieldWrap,
} from "./styles"

const TeamFilterAndSearch = ({
  initOptions,
  onSearch,
  getTeamDetailsResponse,
  onMainSelectChange,
  borderColor,
  currentSearchValue,
}) => {
  const { width: screenWidth } = useWindowSize()
  const selectRef = useRef(null)
  const { data: teams, isFetching: fetchingTeams } = getTeamDetailsResponse || {}
  const [items, setItems] = useState([...initOptions])
  const [currentValue, setValue] = useState(null)
  const addItem = (value, id) => {
    const added = `${value}'s Transactions`
    //e.preventDefault()
    // setItems([...items, added])
    onMainSelectChange(id, added)
    setValue(added)
  }

  const getOptions = (data) => {
    if (data && data.length <= 0) return []
    const opts = data.map(item => ({
      label: item,
      value: item,
    }))
    return opts
  }

  const getTeamOptions = () => {
    const {
      team_members: teamMembers,
    } = teams || {}

    if (!teamMembers || (teamMembers && teamMembers.length <= 0)) return []

    return teamMembers.map(item => ({
      label: `${item.firstname} ${item.lastname}`,
      value: `${item.agent_id}::${item.firstname} ${item.lastname}`,
    }))
  }

  return (
    <SelectFieldWrap
      ref={selectRef}
      style={
        screenWidth <= 1320 ? {
          width: "100%",
        } : {
          width: "200px",
        }}
      onChange={(val) => {
        setValue(val)
        onMainSelectChange(val)
      }}
      value={currentSearchValue}
      borderColor={borderColor}
      placeholder="View transactions for"
      options={getOptions(items)}
      dropdownRender={menu => (
        <>
          {menu}
          <Divider
            style={{
              margin: "8px 0",
            }}
          />
          <Space
            style={{
              padding: "0 8px 4px",
            }}
          >
            {fetchingTeams ? (
              <></>
            ) : (
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="Select Team Member"
                optionFilterProp="children"
                onChange={(val) => {
                  const label = val
                  const agentId = label.split("::")[0]
                  const parsed = label.split("::")[1]
                  addItem(parsed, agentId)
                }}
                onSearch={onSearch}
                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
                options={getTeamOptions()}
              />
            )}
          </Space>
        </>
      )}
    />
  )
}

export default TeamProfileContainer(TeamFilterAndSearch)
