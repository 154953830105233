import React, { useRef } from "react"
import styled from "styled-components"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import {
  InputField,
  SelectField,
  InputNumber,
  InputLocation,
  FloatingCTA,
} from "dumbComponents/common/InputFields"
import { SwapOutlined } from "@ant-design/icons"
import OffersV2 from "container/OffersV2"
import { Form } from "@ui/antd"
import { FormWrap, CTAWrap, OrWrap } from "./commonStyles"
// import {
//   InputField,
//   SelectField,
//   InputNumber,
//   InputLocation,
// } from "../../InputFields"
import { BlueButton } from "../../../../commonStyles"
import { getInputFieldsBasedOnState } from "./formConfig"

const FlexBox = styled.div`
  display: flex;
  ${props => props.wd && `
    width: ${props.wd};
  `}
`

const ArrowWrap = styled.div`
  color: #898989;
  margin: auto 20px;
`;

const DealTermForm = ({
  onFinish,
  onFinishFailed,
  prefilledData,
  state = "california",
  updatingDealTerms,
  initialValues,
  fetchingDealTerms,
  fetchValidateCoverSheetResponse,
  updateBuyerInformationTabResponse,
  updatePropertyInformationTabResponse,
  dealTermsFormRef,
}) => {
  const formRef = useRef(null)

  const {
    isFetching: validating,
  } = fetchValidateCoverSheetResponse || {}

  const {
    isFetching: updatingBuyer,
  } = updateBuyerInformationTabResponse || {}

  const {
    isFetching: updatingProperty,
  } = updatePropertyInformationTabResponse || {}

  const getInitialValues = () => {
    return { ...initialValues }
  }

  const calculatePercentage = (amount, percentage) => {
    const values = formRef?.current?.getFieldsValue()
    if (values[amount] === null) {
      formRef.current.setFieldsValue({
        [percentage]: null,
      });
    }
    if (values.price && (values[amount] || values[amount] === 0)) {
      const percent = (values[amount] * 100) / values.price
      const check_percent = Math.round(percent * 100) / 100;
      formRef.current.setFieldsValue({
        // [percentage]: percentFormatter(percent, 2),
        [percentage]: check_percent,
      })
    }
  };

  const calculateAmount = (amount, percentage) => {
    const values = formRef?.current?.getFieldsValue();
    if (values[percentage] === null || isNaN(values[percentage])) {
      formRef.current.setFieldsValue({
        [amount]: null,
      });
    }
    if (values.price && values[percentage] || values[percentage] === 0) {
      const amount_value = (values[percentage] / 100) * values.price
      const check_percent = Math.round(amount_value * 100) / 100;
      formRef.current.setFieldsValue({
        [amount]: check_percent,
      })
    }
  };

  const handleChange = (value, item) => {
    const values = formRef?.current?.getFieldsValue()
    const check_value = Math.round(value * 100) / 100;
    formRef.current.setFieldsValue({
      [item.name] : check_value,
    })
    if (item.modifiesForm) {
      if (item.name === "loan_amount") {
        calculatePercentage("loan_amount", "loan_amount_percentage")
        totalPrice()
        return
      }

      if (item.name === "initial_deposit_amount") {
        calculatePercentage(
          "initial_deposit_amount",
          "initial_deposit_amount_percentage"
        )
        totalPrice()
        return
      }

      if (item.name === "increased_deposit_amount") {
        calculatePercentage(
          "increased_deposit_amount",
          "increased_deposit_amount_percentage"
        )
        totalPrice()
        return
      }

      if (item.name === "additional_financial_amount") {
        calculatePercentage(
          "additional_financial_amount",
          "additional_financial_amount_percentage"
        )
        totalPrice()
        return
      }

      if (item.name === "earnest_money") {
        calculatePercentage(
          "earnest_money",
          "earnest_money_percentage"
        )
        totalPrice()
        return
      }

      if (item.name === "additional_earnest_money") {
        calculatePercentage(
          "additional_earnest_money",
          "additional_earnest_money_percentage"
        )
        totalPrice()
        return
      }

      if (item.name === "loan_amount_percentage") {
        calculateAmount("loan_amount", "loan_amount_percentage")
        totalPrice()
        return
      }

      if (item.name === "initial_deposit_amount_percentage") {
        calculateAmount(
          "initial_deposit_amount",
          "initial_deposit_amount_percentage"
        )
        totalPrice()
        return
      }

      if (item.name === "increased_deposit_amount_percentage") {
        calculateAmount(
          "increased_deposit_amount",
          "increased_deposit_amount_percentage"
        )
        totalPrice()
        return
      }

      if (item.name === "additional_financial_amount_percentage") {
        calculateAmount(
          "additional_financial_amount",
          "additional_financial_amount_percentage"
        )
        totalPrice()
        return
      }

      if (item.name === "additional_deposit_percentage") {
        calculateAmount(
          "additional_deposit",
          "additional_deposit_percentage",
        )
        totalPrice()
        return
      }

      if (item.name === "earnest_money_percentage") {
        calculateAmount(
          "earnest_money",
          "earnest_money_percentage"
        )
        totalPrice()
        return
      }

      if (item.name === "additional_earnest_money_percentage") {
        calculateAmount(
          "additional_earnest_money",
          "additional_earnest_money_percentage"
        )
        totalPrice()
        return
      }

      if (item.name === "new_loan_amount_percentage") {
        calculateAmount(
          "new_loan_amount",
          "new_loan_amount_percentage"
        )
        totalPrice()
        return
      }

      //FOR PRICE
      if (values.loan_amount) {
        calculatePercentage("loan_amount", "loan_amount_percentage");
      }
      if (values.initial_deposit_amount) {
        calculatePercentage(
          "initial_deposit_amount",
          "initial_deposit_amount_percentage"
        )
      }
      if (values.increased_deposit_amount) {
        calculatePercentage(
          "increased_deposit_amount",
          "increased_deposit_amount_percentage"
        )
      }
      if (values.additional_financial_amount) {
        calculatePercentage(
          "additional_financial_amount",
          "additional_financial_amount_percentage"
        )
      }
      if (values.earnest_money) {
        calculatePercentage(
          "earnest_money",
          "earnest_money_percentage"
        )
      }
      if (item.name === "new_loan_amount") {
        calculatePercentage(
          "new_loan_amount",
          "new_loan_amount_percentage"
        )
        totalPrice()
        return
      }

      totalPrice()
    }
  };

  const totalPrice = () => {
    const values = formRef?.current?.getFieldsValue()
    let total = 0
    console.log("ritvik", values)
    if (values.price) {
      switch (state) {
        case "california":
          total =
            values.price -
            ((values.loan_amount || 0) +
              (values.initial_deposit_amount || 0) +
              (values.increased_deposit_amount || 0) +
              (values.additional_financial_amount || 0))
          formRef.current.setFieldsValue({
            price: values.price,
            balance_of_down_payment: parseFloat(total.toFixed(2)),
          })
          break
        case "texas":
          total =
            values.price -
            (values.loan_amount || 0)
          formRef.current.setFieldsValue({
            price: values.price,
            balance_of_down_payment: parseFloat(total.toFixed(2)),
          })
          break
        case "florida":
          total =
            values.price -
            ((values.other || 0) +
              (values.loan_amount || 0) +
              (values.initial_deposit_amount || 0) +
              (values.additional_deposit || 0))
          formRef.current.setFieldsValue({
            price: values.price,
            balance_to_close: parseFloat(total.toFixed(2)),
          })
          break
        case "washington":
          total =
            values.price -
            ((values.loan_amount || 0) +
              (values.earnest_money || 0))
          formRef.current.setFieldsValue({
            price: values.price,
            balance_of_down_payment: parseFloat(total.toFixed(2)),
          })
          break
        case "oregon":
          total =
            values.price -
            ((values.remainder_of_down_payment || 0) +
              (values.earnest_money || 0))
          formRef.current.setFieldsValue({
            price: values.price,
            balance_of_purchase_price: parseFloat(total.toFixed(2)),
          })
          break
        case "colorado":
          total =
            values.price -
            ((values.earnest_money || 0) +
              (values.assumption_balance || 0) +
              (values.new_loan_amount || 0) +
              (values.private_financing || 0) +
              (values.seller_financing || 0) +
              (values.other_fees_1 || 0) +
              (values.other_fees_2 || 0))
          formRef.current.setFieldsValue({
            price: values.price,
            cash_at_closing: total,
          })
          break
        default:
          break
      }
    }
  }

  return (
    <FormWrap>
      <Form
        name="offers_deal_terms_information"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={getInitialValues()}
        ref={formRef}
        form={dealTermsFormRef}
      >
        {getInputFieldsBasedOnState(state).map((item) => {
          if (item.type === "select") {
            return (
              <>
                <SelectField
                  isFormField
                  name={item.name}
                  options={[]}
                  defaultValue={
                    prefilledData ? prefilledData.listing_type : null
                  }
                  placeholder={item.placeholder}
                  text={item.text}
                  required={item.required}
                  requiredMessage="Required"
                />
                {item.showOr && (
                  <OrWrap>
                    <p>OR</p>
                  </OrWrap>
                )}
              </>
            )
          }

          if (item.type === "pair_calculation") {
            return (
              <>
                <FlexBox wd="100%">
                  <InputNumber
                    name={item.values[0].name}
                    inputType="number"
                    type={item.values[0].formatterType}
                    placeholder={item.values[0].placeholder}
                    formRef={formRef}
                    onChange={(value) => handleChange(value, item.values[0])}
                    text={item.values[0].text}
                    required={item.values[0].required}
                    requiredMessage="Required"
                  />
                  <ArrowWrap>
                    <SwapOutlined
                      style={{ fontSize: "30px", marginBottom: "20px" }}
                    />
                  </ArrowWrap>

                  <InputNumber
                    style={{
                      margeingLeft: 30,
                    }}
                    name={item.values[1].name}
                    inputType="number"
                    type={item.values[1].formatterType}
                    placeholder={item.values[1].placeholder}
                    formRef={formRef}
                    onChange={(value) => handleChange(value, item.values[1])}
                    text={item.values[1].text}
                    required={item.values[1].required}
                    requiredMessage="Required"
                  />
                </FlexBox>
              </>
            );
          }

          return (
            <>
              {/* {console.log("intiakHEre--->",initialValues[item.name])} */}
              <InputNumber
                name={item.name}
                inputType="number"
                //type={initialValues && initialValues[item.name] !==null ? item.formatterType :null}
                type={item.formatterType}
                placeholder={item.placeholder}
                onChange={(value) => handleChange(value, item)}
                text={item.text}
                required={item.required}
                requiredMessage="Required"
              />
            </>
          );
        })}

        {/* <CTAWrap>
          <Form.Item>
            <BlueButton
              maxWidth
              type="primary"
              shape="round"
              size="large"
              // loading={creatingOffer || fetchingDeal || updatingDeal}
              htmlType="submit"
            >
              {updatingDealTerms ? (
                <ThreeDotLoader color="#fff" />
              ) : (
                <p>Save &amp; Continue</p>
              )}
            </BlueButton>
          </Form.Item>
        </CTAWrap> */}
      </Form>

      <FloatingCTA
        customText={(updatingDealTerms || validating || updatingBuyer || updatingProperty) ? "Processing" : "Save & Continue"}
        disabled={false}
        htmlType="submit"
        loading={updatingDealTerms || validating || updatingBuyer || updatingProperty}
        onClick={() => onFinish()}
      />
    </FormWrap>
  )
}

export default OffersV2(DealTermForm)
