/* eslint-disable react/jsx-boolean-value */
import React, { useRef, useState , useEffect} from "react"
import {
  Form,
} from "@ui/antd"
import {
  InputField,
  InputLocation,
  SelectField,
  HiddenInputField,
} from "../../../../InputFields"
import {
  FormWrap,
  SubmitWrap,
  ValidationNumberCircle,
} from "./commonStyles"
import {
  REPRESENTING,
  CLIENT_FILE_MAP,
  STATE_OPTIONS,
} from "./formConfig"
import cookie from "services/CookieStorage"
import TechAssetsContainer from "container/TechAssets"

const PropertyInformationForm = ({
  onFinishFailed,
  handleSubmit,
  prefilledData,
  onValueChange,
  saving,
  disableForm,
  key,
  validationReport,
  handleInitPropertyChange,
  handleStateChange,
  handleClientTypeChange,
  getAgentDetails,
  getAgentDetailsResponse,
  dealToView,
  getContractById,
  getContractByIdResponse,
}) => {
  const formRef = useRef(null)
  const [currentRepresent, setRepresent] = useState("")
  const userAgent = cookie.load("user")
  const agent_id = userAgent?.id


  const getInitialValues = () => {
    let initValues = {}

    if (prefilledData && Object.keys(prefilledData).length > 0) {
      initValues = prefilledData
    }
    return initValues
  }

  const getCurrentOptions = () => {
    if (currentRepresent) {
      return CLIENT_FILE_MAP[currentRepresent]
    }
    if (prefilledData && prefilledData.client_type) {
      return CLIENT_FILE_MAP[prefilledData.client_type]
    }
    return []
  }

  const handleFileTypeChange = (value) => {
    console.log("value", value , 'form values', formRef.current.getFieldsValue())
    const state = formRef.current.getFieldsValue().state;
    if(state){
      getAgentDetails({
        state: state,
        agentId:agent_id
      })
    }
  }

  

  return (
    <FormWrap
      id={`${key}_wrap`}
      key={key}
    >
      <Form
        name="property_information"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
        initialValues={getInitialValues()}
        onKeyDown={(e) => { e.stopPropagation() }}
        onValuesChange={onValueChange}
        ref={formRef}
        // disabled={disableForm}
        id={`${key}_form`}
        key={key}
      >
        <InputLocation
          name="listing_address"
          inputType="text"
          type="text"
          placeholder="Street Address*"
          formRef={formRef}
          text="Street Address"
          requiredMessage="Property address is required."
          required={true}
          handleInitChange={handleInitPropertyChange}
        />

        <HiddenInputField name="county" />
        <HiddenInputField name="country" />

        <InputField
          name="house_number"
          inputType="text"
          placeholder="Apartment/Unit/Suite No."
          text="Apartment/Unit/Suite No."
        />

        <InputField
          name="city"
          inputType="text"
          placeholder="City"
          text="City"
          required={true}
        />

        <SelectField
          isFormField
          name="state"
          options={STATE_OPTIONS}
          placeholder="State"
          text="State"
          required={true}
          requiredMessage="State is required."
          onChange={(value) => {
            handleStateChange(value)
          }}
        />

        <InputField
          name="zipcode"
          inputType="text"
          placeholder="Zip Code"
          text="Zip Code"
          required={true}
        />

        <SelectField
          isFormField
          name="client_type"
          options={REPRESENTING}
          placeholder="Representing"
          text="Representing"
          onChange={(value) => {
            setRepresent(value)
            handleClientTypeChange(value)
          }}
          required={true}
          requiredMessage="Client type is required."
        />

        <SelectField
          isFormField
          name="file_type"
          options={getCurrentOptions()}
          placeholder="File Type"
          text="File Type"
          required={true}
          requiredMessage="File type is required."
          onChange={(value) => {
            handleFileTypeChange(value)
          }}
        />

        {Boolean(validationReport) && validationReport > 0 && (
          <SubmitWrap>
            <ValidationNumberCircle>
              {validationReport}
            </ValidationNumberCircle>
          </SubmitWrap>
        )}

        {saving && (
          <SubmitWrap>
            <button
              type="button"
              onClick={() => {}}
            >
              Saving..
            </button>
          </SubmitWrap>
        )}

      </Form>
    </FormWrap>
  )
}

export default TechAssetsContainer(PropertyInformationForm)
