import React from "react"
import styled, { css } from "styled-components"
import uuid from "uuid"
import Button from "@ui/Button"

const EmptyStateIcon = styled.img`
  width: 40px;
`

const H3 = styled.h3`
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  font-size: 24px;
  max-width: 400px;
  margin: 20px auto;
`

const EmptyButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  color: #5A5FF2;
  font-size: 14px;
  margin-top: 15px;

  ${props => props.isMain && css`
    border: inherit;
    background: inherit;
    color: inherit;
  `}
`

class EmptyState extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.setState({
      id: uuid(),
    })
  }

  render() {
    const { id } = this.state
    const {
      src,
      title,
      handleEditClick,
      initialValues,
      isMain,
      heroText,
      type,
      descriptionText,
    } = this.props
    return (
      <div style={{ background: "transparent", textAlign: "center" }} key={id}>
        <div className="emptyWrap">
          <EmptyStateIcon src={src} />
          {isMain
            ? (
              <div>
                <H3>{heroText}</H3>
                {descriptionText && (
                  <p style={{ fontWeight: 300 }}>{descriptionText}</p>
                )}
                <Button
                  onClick={() => {
                    handleEditClick({ type, initialValues })
                  }}
                  style={{
                    width: "auto",
                    padding: "8px 30px",
                  }}
                >
                  {title}
                </Button>
              </div>
            )
            : (
              <EmptyButton
                onClick={() => {
                  handleEditClick({ type, initialValues })
                }}
              >
                {title}
              </EmptyButton>
            )}
        </div>
      </div>
    )
  }
}

export default EmptyState
