import React, { useState } from "react"
import styled from "styled-components"
import colors from "@colors"
import { ATC } from "@fonts"
import root from "window-or-global"
import { get, isEmpty } from "lodash"
import queryString from "query-string"
import { withRouter } from "react-router-dom"
import TechAssetsContainer from "container/TechAssets"
import CheckboxIcon from "dumbComponents/Listings/images/check_1.svg"
import Alert from "dumbComponents/Offers/Components/Alert"

import { Divider } from "@ui/antd"
import ConfimToGoToContract from "./ConfimToGoToContract"

const CheckboxWrap = styled.div`
  padding: 0 20px 0 10px;
`
const CheckboxContainer = styled.div`
  display: flex;
  cursor: pointer;
  ${props => props.disabled === true && `
    opacity: 0.5;
    cursor: not-allowed;
  `}
  b {
    margin-top: 1px;
    font-size: 10px;
    margin-left: 8px;
    color: #ff3f3e;
    font-size: 22;
    font-weight: 700;
  }
`

const LocalCheckBox = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid #303030;
  border-radius: 4px;
  margin-right: 12px;
  ${props => props.disabled && `
    border: 1px solid #C7C7C7;
  `}
  ${props => props.enabled && `
    background: #5A5FF2;
    border: 1px solid #5A5FF2;
    border: none;
  `}
  ${props => props.activeStage && `
    border: 1px solid #5A5FF2;
    background: #ced9ff;
  `}
`

const Image = styled.img`
  margin: 0 0px 0 -25px;
  padding-right: 15px;
`

const CheckboxLabelText = styled.h2`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  color: ${colors.black};
  text-transform: uppercase;
  ${props => props.disabled && `
    color: #C7C7C7;
  `}
  ${props => props.activeStage && `
    color: #5A5FF2;
  `}
`

const StyledDivider = styled(Divider)`
  min-width: 180px !important;
  width: 180px !important;
  margin: 16px 0 !important;
  text-align: left !important;
  ${props => props.mt && `
    margin-top: ${props.mt} !important;
  `}
  .ant-divider-inner-text {
    font-family: ${ATC};
    font-size: 18px;
    font-weight: 400;
  }
  ${props => props.disabled && `
    opacity: 0.5;
    cursor: not-allowed;
  `}
`

const Sidebar = ({
  enabled, clickActions, currentStage, history, dealToView, getListingDocumentsResponse, setLoaderOnAgentInfo,
  getListingStepsResponse, toggleAlertNavigation, showAlertNavigation, getAllListingsResponse, toggleComfirmToGoToContract,
}) => {
  const search = get(root, "location.search", {})
  const parsedQuery = queryString.parse(search)

  const [listingStatus, setListingStatus] = useState(parsedQuery?.status || "listing")

  const canGoBackToCoverSheet = () => getListingDocumentsResponse?.data?.filter(dt => ["action_required", "processing", "sent", "completed", "declined"].includes(dt.status)).length === 0
  const listingData = getAllListingsResponse?.data?.find(dt => dt.id === dealToView)

  const navigateTo = (routeName, isListingInContract) => {
    if (isEmpty(listingData) && routeName.includes("documents")) {
      toggleAlertNavigation({
        content: "Please complete the cover sheet before you proceed forward to the next steps",
        nextRoute: () => { },
        buttonOkText: "Got it",
        buttonCancelText: "Cancel",
      })
      return
    }
    if (isListingInContract) {
      history.push(`${routeName}?dealId=${dealToView}&status=listing_pending`)
    }
    if (listingStatus !== "listing_pending" && isListingInContract) {
      return
    }
    if (listingData.displayDealStatus === "Incomplete" && routeName.includes("listing-agent-info")) {
      return
    }
    if (listingData.displayDealStatus === "Incomplete" && routeName.includes("documents")) {
      toggleAlertNavigation({
        content: "Please complete the cover sheet before you proceed forward to the next steps",
        nextRoute: () => { },
        buttonOkText: "Got it",
        buttonCancelText: "Cancel",
      })
      return
    }
    if (listingData.displayDealStatus !== "Incomplete" && routeName.includes("documents")) {
      history.push(`/${routeName}`)
    }
    if (canGoBackToCoverSheet() && routeName.includes("listing-agent-info")) {
      setLoaderOnAgentInfo(true)
      history.push(`${routeName}?dealId=${dealToView}`)
    }
    if (!canGoBackToCoverSheet() && routeName.includes("listing-agent-info") && !root.location.href.includes("listing-agent-info")) {
      toggleAlertNavigation({
        content: "You are not allowed to make change in cover sheet once the document are sent for signature",
        nextRoute: () => { },
        buttonOkText: "Got it",
        buttonCancelText: "Cancel",
      })
    }
  }

  return (
    <>
      <CheckboxWrap>
        <StyledDivider orientation="left" orientationMargin="0">
          ADD NEW LISTING
        </StyledDivider>
        <CheckboxWrap>
          <CheckboxContainer onClick={() => navigateTo("/realty/listings/coversheet/listing-agent-info")}>
            <LocalCheckBox enabled={enabled?.[0]} activeStage={currentStage === 1} />
            {enabled?.[0] && (<Image src={CheckboxIcon} />)}
            <CheckboxLabelText activeStage={currentStage === 1}>Cover sheet</CheckboxLabelText>
          </CheckboxContainer>
          <StyledDivider />
          <CheckboxContainer className="listing_document" onClick={() => navigateTo("realty/listings/documents/lists")}>
            <LocalCheckBox activeStage={currentStage === 2} />
            <CheckboxLabelText activeStage={currentStage === 2}>Documents</CheckboxLabelText>
            <b>BETA</b>
          </CheckboxContainer>
        </CheckboxWrap>
        <StyledDivider orientation="left" orientationMargin="0" mt="30px" disabled={listingData?.displayDealStatus === "Incomplete" || isEmpty(listingData)}>
          SUBMIT A
          <br />
          PENDING LISTING
        </StyledDivider>
        <CheckboxWrap ml="10px">
          <CheckboxContainer onClick={() => { if (!(listingData?.displayDealStatus === "Incomplete" || isEmpty(listingData))) toggleComfirmToGoToContract(true) }} disabled={listingData?.displayDealStatus === "Incomplete" || isEmpty(listingData)}>
            <LocalCheckBox activeStage={currentStage === 3} />
            {enabled?.[0] && (<Image src={CheckboxIcon} />)}
            <CheckboxLabelText activeStage={currentStage === 3}>Contract Info</CheckboxLabelText>
          </CheckboxContainer>
        </CheckboxWrap>
      </CheckboxWrap>
      <ConfimToGoToContract
        isListing
      />
      {showAlertNavigation && <Alert />}
    </>
  )
}

export default TechAssetsContainer(withRouter(Sidebar))
