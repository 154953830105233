import React from "react"
import styled from "styled-components"
import CRMV2Container from "container/CRM"
import { ATC } from "@fonts"
import colors from "@colors"
import Button from "@ui/Button"
import GotALead from "images/gotALead.png"
import { CustomHeaderDrawer } from "dumbComponents/CRMV2/CRMDashboard/Components/Drawers"

const Wrap = styled.div`
`

const BoxWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 370px;
  background: #F7F7F7;
  border: 1px solid #D3DAE8;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
`

const H1 = styled.h1`
  font-family: ${ATC};
  font-size: 28px;
  color: ${colors.black};
  margin: ${props => props.marginTop || "12px"} 0 12px 0; 
`

const P = styled.p`
  font-family: ${ATC};
  font-size: 17px;
  color: ${colors.black};
  width: 330px;
  text-align: center;
  font-weight: 200;
  margin: 0 0 20px 0;
`

const ZeroStateCRM = ({
  toggleAddNewLeadDrawer,
  isAddNewLeadDrawerOpen,
  setCurrentDrawerPage,
  currentFilters,
}) => (
  <Wrap>
    <BoxWrap>
      <img src={GotALead} alt="Got a Lead" height={180} width={200} />
      {currentFilters
      && (currentFilters.view_type === "view_mine"
      || currentFilters.view_type === "view_all") ? (
        <>
          <H1>
            Got a Lead?
          </H1>
          {/* <P>
            Submit your first lead.
          </P> */}
          <Button
            style={{
              backgroundColor: `${colors.new_primary}`,
              border: "none",
              borderRadius: "4px",
              height: "48px",
              width: "142px",
            }}
            onClick={() => {
              setCurrentDrawerPage("add-lead")
              toggleAddNewLeadDrawer(true)
            }}
          >
            Add Lead
          </Button>
        </>
        ) : (
          <H1
            marginTop="40px"
          >
            No Leads
          </H1>
        )}
    </BoxWrap>
    <CustomHeaderDrawer
      handleClose={() => toggleAddNewLeadDrawer(false)}
      open={isAddNewLeadDrawerOpen}
      hideDrawerTitle
      width="690px"
    />
  </Wrap>
)

export default CRMV2Container(ZeroStateCRM)
