import React, { useState, useEffect } from "react"
import {
  Modal, Input, Button, Select,
} from "antd"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import moment from "moment"
import Navbar from "container/Navbar"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import {
  ModalWrapper,
  CreditHeadingWrap,
  CreditHeading,
  CreditImage,
  InfoWrap,
  CommonText,
  CancelButton,
  CreditProceedButton,
  CreditCancelButton,
  RadioDiv,
  ToggleButtonWrap,
  ActiveButton,
} from "./styles"
import {
  HorizontalBar,
} from "../styles"
import InjectedACHForm from "./InjectedACHForm"
import InjectedCreditCardForm from "./InjectedCreditCardForm"
const stripePromise = loadStripe(STRIPE_PUBLISH_KEY)
import AppContainer from "container/App"
import { withRouter } from "react-router-dom";

const CancelledAddPayment = ({
  contentType="settings",
  toClose,
  isOpen,
  handleProceed,
  billingDate,
  handleClickForCancel,
  planDetail,
  defaultPayment,
  defaultPaymentObject,
  isLoadingCard,
  setLoadingCard,
  expired=false,
  fetchAddOns,
  fetchAddOnsResponse,
  user,
  addOns,
}) => {
  const [selectedPaymentMethod, setPaymentMethod] = useState("card")
  const [isEditPayment, setEditPayment] = useState(false)
  const onClickPaymentMethod = (value) => {
    setPaymentMethod(value)
  }
  console.log("addOns", addOns)
  console.log("planDetail", planDetail)

  const handClickForPayment = (status="success") => {
    if(expired){
      handleProceed({value:true, status})
    } else{
      handleProceed(true)
    }
    
  }

  const handleClick = () => {
    setLoadingCard(true)
    handleProceed(false)
  }
  console.log("defaultPaymentObject", defaultPaymentObject)
  const startDate = moment() // Today's date
  const endDate = planDetail?.billing_cycle === "MONTHLY"
    ? startDate.clone().add(1, "month") // Add 1 month for monthly billing
    : startDate.clone().add(1, "year") // Add 1 year for yearly billing

  const formattedBillingPeriod = `${startDate.format("MMMM D, YYYY")} - ${endDate.format("MMMM D, YYYY")}`



  const [addOnSubscription, setAddOnSubscription] = useState({
    mlsFee:"",
    setupFee:""
  })

  useEffect(() => {
    console.log("fetchAddOnsResponse", fetchAddOnsResponse)
    if(fetchAddOnsResponse.data){
      console.log("addOns data", fetchAddOnsResponse.data)
      handleAddOns(fetchAddOnsResponse.data)
    }
  }, [fetchAddOnsResponse])


  const handleAddOns = (addOns) => {
      if(addOns.length > 0){
        const mlsFee = addOns.find(addOn => addOn.value === "mls_fee")
        const setupFee = addOns.find(addOn => addOn.value === "setup_fee")
        
        setAddOnSubscription({
          mlsFee: mlsFee?.type === "RECURRING" ? mlsFee.plan_details[0]?.unit_price : null,
          setupFee: setupFee?.type === "REQUEST" ? setupFee.unit_price : null,
        })
      }
  }
  return (
    <Modal open={isOpen} onCancel={toClose} footer={null} width={500}>
      <ModalWrapper>
        <CreditHeadingWrap>
          <CreditImage>
            <img src={`${CLOUDFRONT}/brokeragePage/settingsModeule.svg`} width="19px" />
          </CreditImage>
        </CreditHeadingWrap>
        <>
          <CreditHeadingWrap>
            <CreditHeading>
              {contentType == "settings" ? " Your free trial has been cancelled" : "No credit card in file"}
            </CreditHeading>
            <CommonText
              color="#6B7280"
              fontSize="14px"
              weight="400"
              lineHeigth
            >
              Continue accessing Radius App - All features by upgrading to a premium
              subscription. Unlock BRBC, MEL AI, and advanced features.
            </CommonText>
          </CreditHeadingWrap>
        </>
        {
          ((defaultPaymentObject && defaultPaymentObject.length > 0 && !isEditPayment)) ? (
            <InfoWrap>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <CommonText
                  color="#232338"
                  fontSize="14px"
                  weight="500"
                  lineHeigth
                >
                  {planDetail.name ? planDetail.name : "Individual Plan" }
                </CommonText>
                <CommonText
                  color="#373758"
                  fontSize="14px"
                  weight="500"
                  lineHeigth
                >
                  First payment on
                  {" "}
                  {billingDate}
                </CommonText>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <CommonText
                  color="#232338"
                  fontSize="14px"
                  weight="500"
                  lineHeigth
                >
                  Payment Method
                </CommonText>
                <CommonText
                  color="#373758"
                  fontSize="14px"
                  weight="500"
                  lineHeigth
                >
                  {defaultPaymentObject[0].bank_name ? (
                    <>
                      {defaultPaymentObject[0].bank_name}
                      {" "}
                      ACH
                    </>
                  ) : (
                    <>
                      **** **** ****
                      {" "}
                      {defaultPaymentObject[0].last_four_digits}
                      {" "}
                      {defaultPaymentObject[0].brand.charAt(0).toUpperCase() + defaultPaymentObject[0].brand.slice(1)}
                    </>
                  )
                  }
                </CommonText>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <CommonText
                  color="#232338"
                  fontSize="14px"
                  weight="500"
                  lineHeigth
                >
                  Billing Period
                </CommonText>
                <CommonText
                  color="#373758"
                  fontSize="14px"
                  weight="500"
                  lineHeigth
                >
                  {formattedBillingPeriod}
                </CommonText>
              </div>
            </InfoWrap>
          ) : (
            <>
              <RadioDiv>
                {selectedPaymentMethod == "card" ? (
                  <>
                    <ToggleButtonWrap onClick={() => { onClickPaymentMethod("ach") }}>
                      {" "}
                      <img
                        src={`${CLOUDFRONT}/brokeragePage/ach.svg`}
                        width="19px"
                      />
                      {" "}
                      ACH transfer
                    </ToggleButtonWrap>
                    <ActiveButton onClick={() => { onClickPaymentMethod("card") }}>
                      {" "}
                      <img
                        src={`${CLOUDFRONT}/brokeragePage/creditCard.svg`}
                        width="19px"
                      />
                      {" "}
                      Credit card
                    </ActiveButton>
                  </>
                ) : (
                  <>
                    <ActiveButton onClick={() => { onClickPaymentMethod("ach") }}>
                      <img
                        src={`${CLOUDFRONT}/brokeragePage/activeAch.svg`}
                        width="19px"
                      />
                      ACH transfer
                    </ActiveButton>
                    <ToggleButtonWrap onClick={() => { onClickPaymentMethod("card") }}>
                      <img
                        src={`${CLOUDFRONT}/brokeragePage/greyCreditCard.svg`}
                        width="19px"
                      />
                      Credit card
                    </ToggleButtonWrap>
                  </>
                )}
              </RadioDiv>
              <Elements stripe={stripePromise}>
                {selectedPaymentMethod === "card" ? (
                  <InjectedCreditCardForm custom planDetail={planDetail} handleClick={handClickForPayment} handleClickForCancel={handleClickForCancel} addOns={addOns} addOnSubscription={addOnSubscription} />
                ) : (
                  <InjectedACHForm custom handleClick={handClickForPayment} handleClickForCancel={handleClickForCancel} planDetail={planDetail} addOns={addOns}/>
                )}
              </Elements>
            </>
          )
        }
        {(defaultPaymentObject && defaultPaymentObject.length > 0 && !isEditPayment) && (
          <>
            <HorizontalBar />
            {addOns && addOns.map((addOn, index) => (
          <div
            key={addOn.id || index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              alignSelf: "stretch",
            }}
          >
          <CommonText
            color="#232338"
            fontSize="14px"
            weight="400"
            lineHeigth
          >
            {addOn.name}
          </CommonText>
          <CommonText
            color="#0C9F6E"
            fontSize="14px"
            weight="400"
            lineHeigth
          >
            $
            {addOn?.pricing?.amount ? addOn.pricing.amount : "N/A"}
            /
            {addOn?.pricing?.billing_cycle === "MONTHLY" ? "month" : "year"}
          </CommonText>
        </div>
    ))}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          alignSelf: "stretch",
              }}
            >
              <CommonText
                color="#232338"
                fontSize="14px"
                weight="400"
                lineHeigth
              >
                {planDetail?.name || "Radius Plan"}
              </CommonText>
              <CommonText
                color="#0C9F6E"
                fontSize="14px"
                weight="400"
                lineHeigth
              >
                $
                {(planDetail.unit_price !== undefined || planDetail?.unit_price !== null) ? planDetail.unit_price : 'N/A'}
                /
                {planDetail.billing_cycle === "MONTHLY" ? "month" : "year"}
              </CommonText>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                alignSelf: "stretch",
              }}
            >
              <CommonText
                color="#232338"
                fontSize="14px"
                weight="600"
                lineHeigth
              >
                Total amount 
              </CommonText>
              <CommonText
                color="#0C9F6E"
                fontSize="14px"
                weight="600"
                lineHeigth
              >
                ${(
                (planDetail?.unit_price || 0) + 
                (addOns ? addOns.reduce((sum, addOn) => sum + (addOn?.pricing?.amount || 0), 0) : 0)
                )}/
                {planDetail?.billing_cycle === "MONTHLY" ? "month" : "year"}
              </CommonText>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                gap: "14px",
                flexDirection: "column",
              }}
            >
              {!isEditPayment && <CancelButton onClick={() => setEditPayment(true)}> Edit Payment Method</CancelButton>}
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  alignSelf: "stretch",
                  gap: "32px",
                }}
              >
                <CreditCancelButton width onClick={handleClickForCancel}><p>Cancel</p></CreditCancelButton>
                <CreditProceedButton width onClick={handleClick}>
                  {isLoadingCard ? (
                    <ThreeDotLoader color="#fff" />
                  ) : (
                    <p>Confirm and Pay</p>
                  )}

                </CreditProceedButton>

              </div>
            </div>
          </>
        ) }
      </ModalWrapper>
    </Modal>
  )
}

export default AppContainer(withRouter(Navbar(CancelledAddPayment)) )
