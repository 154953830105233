import React from "react"
import Cleave from "cleave.js/react"
import "cleave.js/dist/addons/cleave-phone.us"
import styled from "styled-components"

const Bar = styled.span.attrs({
  className: "fg-bar",
})`
  position: absolute;
  width: 0%;
  height: 2px;
  background: #5A5FF2;
  bottom: 0px;
  border-radius: 2px;
  transition-duration: 0.3s;
  left: 0px;
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  input {
    outline: none;
    &:active {
      outline: none;
    }
  }

  input:focus ~ .fg-label,
  input.fg-input-valid ~ .fg-label {
    font-size: 12px;
    bottom: 35px;
  }
`

const StyledCleave = styled(Cleave)`
  border: none;
  border: 1px solid #949494;
  border-radius: 8px;
  padding: 0px 0px 0px 6px;
  height: 40px;
  box-shadow: none;
  margin-top: 10px;
  width: 99%;
  font-size: 14px;

  ${props => props.isAreaCodeVisible && `
     width: 90%;
     margin-left: 30px;
   `}
`

const AreaCode = styled.div`
  position: absolute;
  top:15px;
  z-index: 1;
  ${props => props.isAreaCodeVisible && `
     top: 23px;
   `}
`

const Label = styled.label.attrs({
  className: "fg-label",
})`
  transition-duration: 0.3s;
  background: #FFF;
  pointer-events: none;
  position: absolute;
  bottom: 10px;
  width: 100%;
  font-size: 16px;
  text-align: left;
  display: block;
  line-height: 1.75;
  color: #dadada;
  font-weight: 300;
`

class PhoneNumber extends React.Component {
 state = {
   isAreaCodeVisible: false,
 }

//  handleFocus = () => {
//    this.setState({
//      isAreaCodeVisible: true,
//    })
//  }

// handleBlur = () => {
//   this.setState({
//     isAreaCodeVisible: false,
//   })
// }

render() {
   const {
     placeholder,
     onChange,
     value,
     required,
     label,
   } = this.props

   const {
     isAreaCodeVisible,
   } = this.state

   return (
     <Wrapper>
        <label>Phone Number</label>
       <StyledCleave
         placeholder={placeholder || "xxx xxx xxxx"}
         options={{ prefix: "+1", phone: true, phoneRegionCode: "US" }}
         onChange={e => onChange(e)}
         className={value ? "fg-input-valid" : ""}
         required={required}
         isAreaCodeVisible={false}
       />
       {isAreaCodeVisible && (
         <AreaCode
           className="areaCode"
           isAreaCodeVisible={isAreaCodeVisible}
         >
           +1
         </AreaCode>
       )}
      <Bar />
     </Wrapper>
   )
 }
}

export default PhoneNumber
