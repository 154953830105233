import React, { useState, useEffect } from "react"
import {
  Modal, Select,
} from "antd"
import moment from "moment"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import Navbar from "container/Navbar"
import {
  ModalWrapper,
  CreditHeadingWrap,
  CreditHeading,
  CreditImage,
  CommonText,
  ButtonWrap,
  CancelButton,
  YellowInfo,
  YellowInfoElement,
  CreditProceedButton,
} from "./styles"

const DeclinedMethodPayment = ({
  toClose,
  isOpen,
  handleProceed,
  handleClickForCancel,
  isLoadingCard,
  setLoadingCard,
  custom=false,
  errorValue = "generic_declined",
}) => {
  const handleSubmit = () => {
    setLoadingCard(true)
    handleProceed()
  }

  console.log("errorValue", errorValue)
  const errorHeadings = {
    generic_declined: "Payment declined",
    card_declined: "Payment declined",
    expired_card: "Card expired",
    incorrect_cvc: "Incorrect security code",
    processing_error: "Processing error",
    incorrect_number: "Invalid card number",
  }
  const errorContent = {
    generic_declined: "We're having trouble processing your subscription payment. This usually happens when your bank declines the transaction for security reasons.",
    card_declined: "We're having trouble processing your subscription payment. This usually happens when your bank declines the transaction for security reasons.",
    expired_card: "Your subscription payment failed because the card has expired.",
    incorrect_cvc: "The security code (CVC) entered for your card is incorrect.",
    processing_error: "We encountered an unexpected error while processing your subscription payment.",
    incorrect_number: "Please double-check your card number and try again to maintain your subscription.",
  }

  const errorInfo = {
    generic_declined: "Please verify your card details or try a different payment method to continue accessing your real estate tools.",
    card_declined: "Please verify your card details or try a different payment method to continue accessing your real estate tools.",
    expired_card: "Please update your payment method with your new card details to continue using our real estate services.",
    incorrect_cvc: "Please verify the 3-digit security code on the back of your card and try again.",
    processing_error: "Please try again in a few minutes. If the problem persists, contact your bank or use a different payment method.",
    incorrect_number: "Please double-check your card number and try again to maintain your subscription.",
  }
  const headingText = errorHeadings[errorValue] || "Payment Declined"
  const contentText = errorContent[errorValue] || "We're having trouble processing your subscription payment. This usually happens when your bank declines the transaction for security reasons."
  const infoText = errorInfo[errorValue] || "Please verify your card details or try a different payment method to continue accessing your real estate tools."

  return (
    <Modal open={isOpen} onCancel={toClose} footer={null} width={500}>
      <ModalWrapper>
        <CreditHeadingWrap>
          <CreditImage>
            <img src={`${CLOUDFRONT}/brokeragePage/cube.svg`} width="19px" />
          </CreditImage>
        </CreditHeadingWrap>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            alignSelf: "stretch",
          }}
        >
          <div>
            <CreditHeadingWrap>
              <CreditHeading>
                {headingText}
              </CreditHeading>
            </CreditHeadingWrap>
            <CommonText
              color="#6B7280"
              fontSize="12px"
              weight="400"
              lineHeigth
            >
              {contentText}
            </CommonText>
          </div>
          <YellowInfo>
            <div
              style={{
                display: "flex",
                gap: "12px",
              }}
            >
              <YellowInfoElement />
              <CommonText
                color="#92400E"
                fontSize="14px"
                weight="500"
                lineHeigth
              >
                {infoText}
              </CommonText>
            </div>
          </YellowInfo>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "12px",
            alignSelf: "stretch",
            marginTop: "16px",
          }}
        >
          {
            custom ? (
              <CreditProceedButton onClick={handleSubmit}>
                {isLoadingCard ? (
                  <ThreeDotLoader color="#fff" />
                  ) : (
                    <p>Close</p>
                )}
          </CreditProceedButton>
            ) : (
              <>
                <CancelButton onClick={handleClickForCancel}>Cancel</CancelButton>
                <CreditProceedButton onClick={handleSubmit}>
                {isLoadingCard ? (
                    <ThreeDotLoader color="#fff" />
                      ) : (
                    <p>Update payment method</p>
                )}
          </CreditProceedButton>
              </>
            )
          }
          
        </div>
      </ModalWrapper>
    </Modal>
  )
}

export default Navbar(DeclinedMethodPayment)
