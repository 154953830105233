import styled from "styled-components"
import { ONBOARDING_BANNER_HEIGHT } from "@consts"
import { ATC, INTER, MONTSERRAT } from "@fonts"
import { InfoCircleOutlined } from "@ant-design/icons"

export const AlertDiv = styled.div`
display: flex;
width: 100%;
margin-top: 12px;
padding: 12px 24px;
justify-content: space-between;
align-items: center;
gap:8px;
background: var(--Color-Red-Red-100, #FAC9C9);
`
export const InfoDiv = styled(InfoCircleOutlined)`
  position: relative;
  margin-top: 4px;
  width: 20px;
  color: #AA3A3A;
`
export const CommonText = styled.div`
color: ${props => props.color ? props.color : "#6B7280"}
align-self: stretch;
font-family: Inter;
font-size: ${props => props.fontSize ? props.fontSize : "14px"}
font-style: normal;
font-weight: ${props => props.weight ? props.weight : 400}
${props => props.marginTop && `
  margin-top: 10px
`}
`
export const AlertButton = styled.button`
${props => props.width && `
    width: 50%
`}
display: flex;
padding: 8px 12px;
justify-content: center;
align-items: center;
border-radius: 8px;
border: 1px solid var(--Color-Red-Red-500, #F05152);
background: var(--Color-Red-Red-500, #F05152);
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
p {
color: var(--Global-Mapping-White, #FDFDFD);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 14px;
margin: 0px
}`
