import React from "react"
import styled from "styled-components"
import { supressPopups } from "shared/utils"
import { ATC } from "@fonts"
import OnboardingContainer from "container/OnboardingSetup"
import Select from "react-select"
import Button from "@ui/LoaderButton"
import cookie from "services/CookieStorage"
import DropArrow from "../../Images/DropArrow.png"

const FormWrap = styled.div`
  width: 449.43px;
  margin-left: 31px;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar { 
    display: none;
  }
`
const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 449px;
`
const Label = styled.div`
height: 22px;
font-family: ${ATC};
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 22px;
color: #303030;
margin-bottom: 8px;
`
const Dropdown = styled.div`
  margin-top: 24px;
`

const TextInputSmall = styled.input`
  width: 220.5px;
  height: 49px;
  font-weight: 200;
  font-size: 16px;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: 0px;
  padding-left: 16px;

  ${props => props.isPhoneNumber && `
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  `}
`
const TextInputBig = styled.input`
  width: 449.43px;
  height: 49px;
  font-weight: 200;
  font-size: 16px;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: 0px;
  margin-top: 8px;
  padding-left: 16px;
`
const DoubleInputDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`

const ContinueButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  color: white;
  gap: 10px;
  width: 449.43px;
  height: 48px;
  background: #5A5FF2;
  border-radius: 36px;
  margin-top: 25px;
  margin-bottom: 16px;
  border: 0px;
  cursor: pointer;

  ${props => props.disabled === true && `
    background: #989898;
    color: white;
  `}
`
const GoBackButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 10px;
  width: 449.43px;
  margin-bottom: 16px;
  height: 48px;
  border: 1.25px solid #5A5FF2;
  border-radius: 36px;
  color: #5A5FF2;
  cursor: pointer;
`
const SaveForLater = styled.h3`
  display: flex;
  align-self: center;
  width: 113px;
  height: 22px;
  margin: 0px;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #8C8C8C;
  cursor: pointer;
`

class ThirdFormDetails extends React.PureComponent {
  constructor(props) {
    super(props)
    const { response } = this.props
    this.state = {
      form: {
        step_completed: "assistant_info",
        legal_action_faced: response && response.legal_action_faced === 0 ? "no" : "yes",
        assistant_company_name: response.assistant_company_name ? response.assistant_company_name : null,
        assistant_phone_number: response.assistant_phone_number ? response.assistant_phone_number : null,
        assistant_email: response.assistant_email ? response.assistant_email : null,
      },
    }
  }

  updateOrientationInfoFunction = () => {
    const { updateOrientationInfo, setFormStage } = this.props
    const { form } = this.state
    const payload = { ...form }
    this.setState({
      isLoading: true,
    })
    payload.legal_action_faced === "yes" ? payload.legal_action_faced = 1 : payload.legal_action_faced = "no"
    updateOrientationInfo({ payload, setStage: "4" })
    // setFormStage("4")
  }

  goBackFunction = () => {
    const { setFormStage, setIsGoBack } = this.props
    setIsGoBack(true)
    setFormStage("2")
  }

  saveForLater = () => {
    const { setFormStage, setIsGoBack } = this.props
    setIsGoBack(true)
    setFormStage("0")
    supressPopups("reportingPopup")
  }

  validateForm = () => {
    const { form } = this.state
    if (
      !form.legal_action_faced
    ) {
      return true
    }
    return false
  }

  handleNumChange = (event) => {
    const limit = 10
    this.setState({
      num: event.target.value.slice(0, limit),
    })
  }

  render() {
    const colorStyles = {
      control: provided => ({
        ...provided,
        background: "#fff",
        border: "0px",
        height: "49px",
        boxShadow: "0px 1px 11px rgba(0, 0, 0, 0.05)",
        borderRadius: "4px",
      }),
    }
    const {
      form,
      legalActionChanged,
      companyChanged,
      numberChanged,
      emailChanged,
      num,
      isLoading,
    } = this.state
    const { response } = this.props

    const thisAgentIsALead = parseInt(cookie.load("brokerageTeamId"), 10) > 0

    const sentence = thisAgentIsALead ? `Do you have an TC/assistant? If so, provide their information 
    below & we will invite them` : `Do you have an TC/assistant? If so, provide their information 
    below`
    return (
      <FormWrap>
        <TopDiv>
          <Label>
            Have you had any legal action taken against you in the last
            5 years?
            <span style={{ color: "#F53F3F" }}>*</span>
          </Label>
          <Dropdown>
            <Select
              placeholder={response?.assistant_company_name ? (response.legal_action_faced === 1 ? "Yes" : "No") : "Yes / No"}
              value={legalActionChanged ? form.legal_action_faced.value : form.legal_action_faced}
              options={[
                { label: "Yes", value: "yes" },
                { label: "No", value: "no" },
              ]}
              styles={colorStyles}
              onChange={(e) => {
                this.setState({
                  form: {
                    ...form,
                    legal_action_faced: e.value,
                  },
                  legalActionChanged: true,
                })
              }}
            />
          </Dropdown>
        </TopDiv>
        <Label style={{ marginTop: "22px", marginBottom: "50px" }}>
          {sentence}
        </Label>
        <TextInputBig
          type="text"
          label="Assistant Details"
          value={companyChanged ? form.assistant_company_name : response.assistant_company_name}
          placeholder="Assistant's Name"
          // required={response.assistant_company_name ? false : true}
          onChange={(e) => {
            this.setState({
              form: {
                ...form,
                assistant_company_name: e.target.value,
              },
              companyChanged: true,
            })
          }}
        />
        <DoubleInputDiv>
          <TextInputSmall
            isPhoneNumber
            placeholder="Phone Number"
            // required
            value={numberChanged ? num : response.assistant_phone_number}
            onChange={(e) => {
              this.setState({
                form: {
                  ...form,
                  assistant_phone_number: e.target.value,
                },
                numberChanged: true,
              })
              this.handleNumChange(e)
            }}
          />
          <TextInputSmall
            placeholder="Email"
            // required
            style={{ marginLeft: "8px" }}
            value={emailChanged ? form.assistant_email : response.assistant_email}
            onChange={(e) => {
              this.setState({
                form: {
                  ...form,
                  assistant_email: e.target.value,
                },
                emailChanged: true,
              })
            }}
          />
        </DoubleInputDiv>
        <ContinueButton
          isShowLoader={isLoading}
          disabled={this.validateForm()}
          onClick={this.updateOrientationInfoFunction}
        >
          Continue
        </ContinueButton>
        <GoBackButton onClick={this.goBackFunction}>Go back</GoBackButton>
        <SaveForLater onClick={this.saveForLater}>Save For Later</SaveForLater>
      </FormWrap>
    )
  }
}

export default OnboardingContainer(ThirdFormDetails)
