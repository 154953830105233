import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {
  fetchSubscriptions,
  addPlanToCart,
  clearPricingCart,
  fetchPricingPlans,
  changePlanType,
} from "container/Referral/actionCreators"
import {
  toggleSubscriptionCancelModal,
} from "container/Settings/actionCreators"
import * as ReferralSelectors from "container/Referral/selector"
import * as SettingsSelectors from "container/Settings/selector"
import * as AppSelectors from "container/App/selector"
import * as Actions from "./actions"

const mapStateToProps = (state) => {
  const { referral, app, settings } = state
  const {
    user: {
      isTeamMember,
    }
  } = app
  const {
    assistPreferenceResponse,
    saveCardResponse,
    brokerAddonsResponse,
    saveStripeSubscriptionResponse,
    fetchFinancingDocumentsResponse,
    fetchEarningsResponse,
  } = settings
  const {
    pricingPlans,
    isCartHavingTeamPlan,
    pricingPlansMap,
    leadsCapThreshold
  } = referral
  return {
    pricingPlans,
    isCartHavingTeamPlan,
    isTeamMember,
    pricingPlansMap,
    assistPreferenceResponse,
    saveCardResponse,
    brokerAddonsResponse,
    leadsCapThreshold: leadsCapThreshold && leadsCapThreshold[0] || [],
    isUserATeamOwner: AppSelectors.getIsUserATeamOwner(state),
    isUserATeamMember: AppSelectors.getIsUserATeamMember(state),
    isUserATeamAdmin: AppSelectors.getIsUserATeamAdmin(state),
    isSubscribedToAnyISAPlan: AppSelectors.getIsSubscribedToAnyISAPlan(state),
    categorizedSubscriptions: ReferralSelectors.getCategorizedSubscriptions(state),
    isFetchingSubscription: ReferralSelectors.getIsFetchingSubscriptions(state),
    showSubscriptionCancelModal: SettingsSelectors.getShowSubscriptionCancelModalFromStore(state),
    finishedFetchingSubscriptions: ReferralSelectors.getFinishedFetchingSubscriptions(state),
    saveStripeSubscriptionResponse,
    fetchFinancingDocumentsResponse,
    fetchEarningsResponse,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    addPlanToCart,
    fetchSubscriptions,
    toggleSubscriptionCancelModal,
    clearPricingCart,
    fetchPricingPlans,
    changePlanType,
    fetchAssistPreference: Actions.fetchAssistPreferenceAction.request,
    saveStripeToken: Actions.saveStripeTokenAction.request,
    getCardDetails: Actions.getCardDetailsAction.request,
    getBrokerAddons: Actions.getBrokerAddonsAction.request,
    saveStripeSubscription: Actions.saveStripeSubscriptionAction.request,
    fetchFinancingDocuments: Actions.fetchFinancingDocumentsAction.request,
    fetchEarnings: Actions.fetchEarningsAction.request,
  }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
