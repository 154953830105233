import React from "react"
import styled from "styled-components"
import queryString from "query-string"
import CRMContainer from "container/CRM"
import AppContainer from "container/App"
import TeamContainer from "container/Realty/TeamProfileContainer"
import root from "window-or-global"
import ReferralContainer from "container/Referral"
import flowContainer from "container/App/applyFlowContainer"
import { Link } from "react-router-dom"
import CookiesStorage from "services/CookieStorage"
import cookie from "services/CookieStorage"
import ExpiredBanner from "dumbComponents/common/ExpiredBanner"
import BrbcContainer from "container/BRBC"
import { LeaderBoardFontStyle } from "dumbComponents/Leaderboard/components/LeaderBoardStyledComponent"
import LinkButton from "@ui/LinkButton"
import colors from "@colors"
import { ATC } from "@fonts"
import { ga4DataLayerPush } from "services/Utils"
import PaywallBlocker from "dumbComponents/common/SettingsTab/Billings/PaywallBlocker"
import moment from "moment"
import CRMDashboard from "./CRMDashboard"
import {
  DragAndDropArea, UserHeaderDrawer, TeamFilterComponent, RivenMortgageBanner,
  ArchiveLeadsModal,
} from "./CRMDashboard/Components"
import SectionHeader from "./CRMDashboard/Components/SectionHeader/index"
import AllCRMModals from "./CRMDashboard/Components/AllCRMModals/index"
import FilterComponent from "./Filters/index"

const Wrap = styled.div`
  padding: 47px 42px 30px 42px;
`

const OnboardingV2Banner = styled.div `
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
  background: var(--Global-Mapping-Gray-50, #EFEFF4);
`

export const ContentContainer = styled.div `
  display: flex;
  flex-direction: column;

  div {
    display: flex;
  }

   p {
    margin: 0;
  }
`

export const ActionPill = styled.div `
  display: flex;
  height: 20px;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: var(--Color-Yellow-Yellow-600, #E18308);
  border-radius: 24px;
  border: 1px solid var(--Color-Yellow-Yellow-500, #F79009);
  text-align: center;
  font-family: Inter;
  margin-right: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`

export const BannerTitle = styled.p `
  color: var(--Global-Mapping-Gray-800, #2B2B45);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`

export const BannerDesc = styled.p `
  color: var(--Global-Mapping-Gray-700, #373758);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  margin-top: 4px;
  font-weight: 400;
  line-height: 20px; 
`

export const ButtonsContainer = styled.div `
  display: flex;
  gap: 8px;
`

export const FillBtn = styled.button `
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 32px;
  border: 1px solid var(--primary-500-main, #6366F1);
  background: var(--primary-500-main, #6366F1);
  color: var(--Generic-White, #FFF);
  text-align: justify;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
`

export const HelpBtn = styled.button `
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 41px;
  border: 1px solid var(--Global-Mapping-Gray-50, #EFEFF4);
  background: var(--Global-Mapping-White, #FDFDFD);
  color: var(--Global-Mapping-Gray-700, #373758);
  text-align: center;

  /* Label/Small/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
`

const DragDropWrap = styled.div`

`

const Tabs = styled.div`
  display: flex;
  margin: 20px 0px 0px 0px;
  justify-content: space-between;

  li {
    list-style: none;
  }
`

const StyledLink = styled(Link)`
  ${LeaderBoardFontStyle}
  font-family: ${ATC};
  font-weight: 200;
  font-size: 14px;
  line-height: 18.9px;
  text-decoration: none;
  letter-spacing: 0.5px;
  padding: 4px 18px;
  display: inline-block;
  position: relative;
  color: ${colors.suvaGray} !important;
  ${props => props.width && `
    width: ${props.width}
  `}

  ${props => props.isActive ? `
    font-weight: 600;
    color: ${colors.new_primary}!important;
    &:after {
      content: "";
      height: 3px;
      position: absolute;
      bottom: -6px;
      left: 0px;
      width: 100%;
      background: ${colors.new_primary};
      border-radius: 2px;
      ${props.width && `
        width: 160px;
      `}
    }
  ` : `
    color: ${colors.black};
    border-bottom: 3px solid transparent;
  `}
`

// code for landing page revamp of CRM
class CRMV2 extends React.Component {
  state = {
    selectedMenu: "My leads",
  }

  componentDidMount() {
    const {
      getClientTransactions, getClientFilter, location,
      getClient, toggleUserOverview,
      setCurrentDrawerPage, fetchUserInfo,
      currentFilters, setCurrentClientId, clearData, getClientTransactionsById,
      getBrokerageTransactionStatus, user: userData, isUserOverviewDrawerOpen,
      currentClientTransactionID, currentCRMDealId, setCurrentTab, fetchPresignedBrbcDocs, fetchBrbcCoversheet, fetchBrbcStatus,
    } = this.props
    const parsedQuery = queryString.parse(location.search)
    const {
      client_id, transactionId, type, tab,
    } = parsedQuery
    const { agentInfo } = isUserOverviewDrawerOpen || {}
    const { type: agentType } = agentInfo || {}
    const clientType = type || agentType
    if (client_id || currentClientTransactionID) {
      const clientId = client_id || currentClientTransactionID
      // setCurrentDrawerPage("drawer-header")
      toggleUserOverview({
        showUserOverviewDrawer: true,
        agentInfo,
      })
      clearData()
      getClient({ clientId })
      setCurrentClientId(clientId)
      getBrokerageTransactionStatus({ transactionId: transactionId || currentCRMDealId })
      getClientTransactionsById({ transactionId: transactionId || currentCRMDealId })
      const user = CookiesStorage.load("user") || {}
      user && fetchPresignedBrbcDocs({ agentId: user?.agent_id, clientId })
      user && fetchBrbcStatus({ agentId: user?.agent_id, clientId })
      if (clientType === "buyer") {
        setCurrentDrawerPage("search-criteria")
      } else if (clientType === "seller" || clientType === "Seller") {
        setCurrentDrawerPage("property-listing")
      } else {
        setCurrentDrawerPage("drawer-header")
      }
      if (tab === "REMINDERS") setCurrentTab(tab)
    }
    if (root.HubSpotConversations) {
      root.HubSpotConversations.widget.remove()
    }
    fetchUserInfo()
    getClientTransactions({
      currentFilters,
    })
    getClientFilter()

    const noUserData = !userData || (userData && Object.keys(userData).length <= 0)

    fetchUserInfo()

    if (!noUserData) {
      ga4DataLayerPush(userData)
    }
  }

  handleClose = () => {
    const {
      toggleUserOverview,
      history,
      setClientID,
    } = this.props
    toggleUserOverview({ isUserOverviewDrawerOpen: false })
    setClientID({
      id: "",
      deal_id: "",
    })
    history.push("/crm")
  }

  getFiltersOptions = () => {
    const {
      getTeamDetailsResponse,
    } = this.props

    const {
      data: teamDetails,
    } = getTeamDetailsResponse || {}
    const user = CookiesStorage.load("user") || {}
    const teamNames = []

    teamNames.push({
      value: "view_mine",
      label: "View my leads",
      key: JSON.stringify({
        value: "view_mine",
        label: "My leads",
      }),
    })

    teamNames.push({
      value: "view_all",
      label: "View all leads",
      key: JSON.stringify({
        value: "view_all",
        label: "All leads",
      }),
    })

    teamNames.push({
      value: "view_all_team_members",
      label: "View associate's leads",
      key: JSON.stringify({
        value: "view_all_team_members",
        label: "All associate's leads",
      }),
    })

    if (teamDetails && teamDetails.team_members) {
      teamDetails.team_members.filter(td => td.agent_id !== user.id).forEach((tm) => {
        teamNames.push({
          key: JSON.stringify({
            value: tm.agent_id,
            label: `${tm.firstname}'s leads`,
          }),
          label: `${tm.firstname}'s leads`,
          value: tm.agent_id,
        })
      })
    }

    return teamNames || []
  }

  handleMenuClick = (e) => {
    try {
      const {
        currentFilters,
        updateFilterQueries,
        getClientTransactions,
      } = this.props
      const selectedMenu = JSON.parse(e.key).label
      const selectedValue = JSON.parse(e.key).value
      const updated = currentFilters
      updated.team_member_id = selectedValue

      updated.view_type = selectedValue !== "view_all"
      && selectedValue !== "view_mine"
      && selectedValue !== "view_all_team_members"
        ? "view_specific_team_members" : selectedValue

      updated.view_type_label = selectedMenu

      updateFilterQueries({
        filters: updated,
      })

      getClientTransactions({
        currentFilters,
      })
    // this.handleFilterSelect(team_member_id, keys[0])
    } catch (error) {
      console.log(error)
    }
  }

  handleNavigation = () => {
    const {
      history,
    } = this.props
    history.push("/onboarding")
  }

  render() {
    const {
      toggleUserOverview,
      isUserOverviewDrawerOpen,
      location,
      getClientTransactions,
      getClientTransactionResponse,
      getTeamDetailsResponse,
      currentFilters,
      isArchiveModalOpen,
      isShowWarning,
      subscriptionStatus,
      fetchSubscriptionStatusEOIResponse,
      subscriptionEndDate,
      isSubscriptionExist,
    } = this.props

    const {
      data: teamDetails,
      isFetching: fetchingTeamDetails,
    } = getTeamDetailsResponse || {}

    const {
      selectedMenu,
    } = this.state

    const {
      view_type_label: viewTypeLabel,
    } = currentFilters || {}

    const { data: ClientTransactionResponse, isFetching: clientTransactionFetching } = getClientTransactionResponse
    const { client_transactions_count, client_transactions } = ClientTransactionResponse || {}
    const { total_client_transactions_count } = client_transactions_count || {}
    const dndColumns = client_transactions || {}
    const { pathname } = location
    const isUserCompletedProfile = cookie.load("isUserCompletedProfile")
    const isExpiringSoon = moment.unix(subscriptionEndDate).diff(moment(), "days") <= 3
    const shouldShowPaywallBlocker = ["canceled", "unpaid"].includes(subscriptionStatus)
      const EOIsubscriptionStatus = cookie.load("EOIstatusForSubscription")
  const subscriptionStatusForBanner = cookie.load("subscriptionStatus")
  const negativeStatuses = ["incomplete", "past_due", "paused", "incomplete_expired", "overdue", "canceled", "unpaid"];
const isEOIOrSubsStatusNegative = 
    negativeStatuses.includes(EOIsubscriptionStatus) || 
  negativeStatuses.includes(subscriptionStatusForBanner);
    
    return (
      <div
        style={{
          marginTop: "10px",
        }}
      >
        {isSubscriptionExist && ( isExpiringSoon || isEOIOrSubsStatusNegative) ? (<ExpiredBanner/>) : (<></>)}
        {shouldShowPaywallBlocker ? (<PaywallBlocker />) : (<></>)}
        {
          !isUserCompletedProfile && (
            <OnboardingV2Banner>
              <ContentContainer>
                <div>
                  <ActionPill>Action Required</ActionPill>
                  <BannerTitle>Complete your onboarding</BannerTitle>
                </div>
                <BannerDesc>To access all features and get the most out of radius, please complete your onboarding process.</BannerDesc>
              </ContentContainer>
              <ButtonsContainer>
                <FillBtn onClick={this.handleNavigation}>Complete onboarding now</FillBtn>
                {/* <HelpBtn>Need Help?</HelpBtn> */}
              </ButtonsContainer>
            </OnboardingV2Banner>
          )
        }
        {/* <RivenMortgageBanner /> */}
        <Wrap>
          <SectionHeader />
          <Tabs>
            {/* {teamDetails && teamDetails.id ? (
              <TeamFilterComponent
                // handleMenuClick={this.handleMenuClick}
                firstValueOption={{ key: "My Leads", label: "My Active Clients", value: "view_mine" }}
                secondValueOption={{ key: "Team Leads", label: "Team Clients", value: "view_all_team_members" }}
                firstValueLabel="My Active Clients"
                secondValueLabel="Team Clients"
                defaultInitialName="My Active Clients"
              />
            ) : (
              <li>
                <StyledLink to="/assist/crm" isActive={pathname.includes("crm")}>
                  CLIENTS
                </StyledLink>
              </li>
            )} */}
              <li>
                <StyledLink to="#" isActive={pathname.includes("crm")}>
                  Active client dashboard
                </StyledLink>
              </li>
            {/* <li>
            <StyledLink
              to="/assist/crmagain/assistleads"
              isActive={pathname.includes("assistleads")}
            >
              SUBMITTED LEADS
            </StyledLink>
          </li>*/}
            <CRMDashboard />
          </Tabs>
          <FilterComponent />
          <DragDropWrap>
            <DragAndDropArea
              dndColumns={dndColumns}
              leadsCount={total_client_transactions_count}
              stageLeadCount={client_transactions_count}
              hideMarkAsQualified
              toggleUserOverviewDrawer={toggleUserOverview}
              getClientTransactions={getClientTransactions}
              getClientTransactionResponse={getClientTransactionResponse}
              fetchingTransactions={clientTransactionFetching}
            />
          </DragDropWrap>
          {/* <LinkButton
            color={colors.marineBLue}
            onClick={() => {
              window.location.href = "/archivedleads"
            }}
          >
            Archived Leads
          </LinkButton> */}
        </Wrap>
        <UserHeaderDrawer
          toggleUserOverview={toggleUserOverview}
          isUserOverviewDrawerOpen={isUserOverviewDrawerOpen}
          handleClose={() => this.handleClose()}
        />
        {isArchiveModalOpen && <ArchiveLeadsModal />}
        <AllCRMModals />
      </div>
    )
  }
}

export default ReferralContainer(AppContainer(TeamContainer(CRMContainer(BrbcContainer(flowContainer(CRMV2))))))
