import React, { useState, useEffect } from "react"
import {
  Modal,
} from "antd"
import moment from "moment"
import {
  SuccessHeadingWrap,
  CreditHeading,
  SuccessImage,
  BillingWrapper,
  CommonText,
  CreditProceedButton,
} from "./styles"
import InjectedACHForm from "./InjectedACHForm"
import InjectedCreditCardForm from "./InjectedCreditCardForm"

const AddCardCheckout = ({
  toClose,
  isOpen,
  planDetail,
  handleProceed,
  handleClickForCancel,
  addOns=null,
}) => {
  console.log("planDetail in sucess", planDetail)
  console.log("addOns in sucess", addOns)
  const nextBillingDate = moment()
    .add(planDetail?.billing_cycle === "YEARLY" ? 1 : 0, "years")
    .add(planDetail?.billing_cycle === "MONTHLY" ? 1 : 0, "months")
    .format("MMMM D, YYYY");
  return (
    <Modal open={isOpen} onCancel={toClose} footer={null} width={500}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}
      >
        <SuccessHeadingWrap>
          <SuccessImage>
            <img src={`${CLOUDFRONT}/brokeragePage/tick.svg`} width="19px" />
          </SuccessImage>
        </SuccessHeadingWrap>
        <SuccessHeadingWrap>
          <CreditHeading>
            Your subscription has been successfully renewed
          </CreditHeading>
        </SuccessHeadingWrap>
        <BillingWrapper>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CommonText
              color="#232338"
              fontSize="16px"
              weight="500"
              lineHeigth
            >
              Next billing date
            </CommonText>
            <CommonText
              color="#373758"
              fontSize="14px"
              weight="400"
              lineHeigth
            >
              {nextBillingDate}
            </CommonText>
          </div>
          <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          alignItems: "flex-start",
        }}
      >
        {addOns && addOns.map((addOn, index) => (
          <div
            key={addOn.id || index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              alignSelf: "stretch",
            }}
          >
          <CommonText
            color="#232338"
            fontSize="14px"
            weight="400"
            lineHeigth
          >
            {addOn.name}
          </CommonText>
          <CommonText
            color="#0C9F6E"
            fontSize="14px"
            weight="400"
            lineHeigth
          >
            $
            {addOn?.pricing?.amount ? addOn.pricing.amount : "N/A"}
            /
            {addOn?.pricing?.billing_cycle === "MONTHLY" ? "month" : "year"}
          </CommonText>
        </div>
    ))}
          {planDetail.unit_price>0?
            <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              alignSelf: "stretch",
            }}
          >
            <CommonText
          color="#232338"
          fontSize="16px"
          weight="500"
          lineHeigth
        >
          {planDetail ? planDetail.name : "Radius Plan"}
        </CommonText>
        <CommonText
              color="#0C9F6E"
              fontSize="14px"
              weight="400"
              lineHeigth
            >
              $
              {planDetail?.unit_price ? (planDetail.unit_price) : "N/A"}
              /
              {planDetail?.billing_cycle === "MONTHLY" ? "month" : "year"}
            </CommonText>

          </div>
          :null
          }

      </div>
          
          
        </BillingWrapper>
        <CreditProceedButton onClick={toClose}>
          <p>
            Close
          </p>
        </CreditProceedButton>
      </div>
    </Modal>
  )
}

export default (AddCardCheckout)
