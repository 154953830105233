import React from "react"
import { HIGHLIGHT_FONT, ATC } from "@fonts"
import colors from "@colors"
import styled from "styled-components"
import Button from "@ui/Button"
import quote from "dumbComponents/NewAgentLP/images/quote.png"
import sharon from "dumbComponents/NewAgentLP/images/sharon.png"
import chris from "dumbComponents/NewAgentLP/images/christian.png"

const Wrap = styled.div`
  padding: 30px;
  @media(max-width: 1099px) {
    padding-bottom: 100px;
  }
  @media(max-width: 425px) {
    padding: 0px;
  }
`

const TestimonialsWrap = styled.div`
  display: flex;
  gap: 40px;

  @media(max-width: 1024px) {
    flex-direction: column;
    gap: 0px;
  }
`

const TextWrap = styled.div`
  flex: 1;
  background-color: #FFFFFF;
  padding: 40px;
  margin-top: 50px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  @media(max-width: 425px) {
    padding: 36px;
  }
  @media(max-width: 1099px) {
    margin-top: 24px;
  }
  > img {
    @media(max-width: 425px) {
      margin: 10px 0px;
    }
  }

  > p {
    line-height: auto;
    color: #303030;
    margin-bottom: 0px;
    font-size: 22px;
    margin-top: 40px;
    font-family: ${ATC};
    @media(max-width: 1099px) {
      font-size: 20px;
    }
    @media(max-width: 425px) {
      font-size: 18px;
    }
  }
  > h4 {
    color: #8C8C8C;
    font-size: 20px;
    font-family: ${ATC} !important;
    font-weight: 300;
    margin-top: 6px;
    @media(max-width: 1099px) {
      font-size: 22px;
    }
    
    @media(max-width: 425px) {
      font-size: 18px;
      margin-top: 0px;
    }
  }
  
`

const UserWrap = styled.div`
  display: flex;
  color: #303030;
  margin-bottom: 30px;

  @media(max-width: 425px) {
    flex-direction: column;
  }
  div {
    &:nth-child(1) {
      margin-top: 10px;
      > img {
        width: 75px;
        height: 75px;
      }
    }
    &:nth-child(2) {
      margin-left: 16px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      
      > p {
        margin: 5px 0px;
        font-size: 22px;
        color: #303030;
        font-family: ${ATC};
        font-weight: 300;
        @media(max-width: 1099px) {
          font-size: 20px;
        }
        @media(max-width: 425px) {
          font-size: 18px;
        }
      }
      > h6 {
        font-family: ${ATC};
        font-size: 12px;
        margin: 0px;
        font-weight: 200;
        &.role {
          font-size: 16px;
          @media(max-width: 1099px) {
            font-size: 16px;
          }
        }
      }
    }
  }
`

const ButtonWrap = styled.div`
  width: fit-content;
  margin: 60px auto;
`

const InnerWrap = styled.div`
  padding: 30px;
  background: ${colors.dreamWhite};
  > h2 {
    font-size: 42px;
    color: #303030;
    text-align: center;
    font-family: ${HIGHLIGHT_FONT};
    @media(max-width: 1099px) {
      font-size: 36px;
    }
    @media(max-width: 425px) {
      font-size: 32px;
    }
  }
`

const Testimonials = ({
  toggleApplyModal,
}) => (
  <Wrap>
    <InnerWrap>
      <h2>What our agents are saying</h2>
      <TestimonialsWrap>
        <TextWrap>
          <UserWrap>
            <div>
              <img src={sharon} alt="" />
            </div>
            <div>
              <p>
                Sharon Seline Wright
              </p>
              <h6 className="role">
                Texas Founding Member
              </h6>
            </div>
          </UserWrap>
          <img src={quote} alt="" />
          <p>
            Now, more than ever, I need to continue investing in
            and developing my business to best meet my client’s needs.
            That means finding solutions that will not only solve for
            today’s current climate, but sustain us for the future.
            Radius is that solution.
          </p>
        </TextWrap>
        <TextWrap>
          <UserWrap>
            <div>
              <img src={chris} alt="" />
            </div>
            <div>
              <p>
                Christian Buitron
              </p>
              <h6 className="role">
                Radius Realtor
              </h6>
            </div>
          </UserWrap>
          <img src={quote} alt="" />
          <p>
            Transitioning from Keller Williams to Radius was a breeze.
            With an Agent Success Manager to help with the transition,
            my business was up and running in no time.
          </p>
        </TextWrap>
      </TestimonialsWrap>
      <ButtonWrap>
        <Button
          style={{
            width: "180px",
            padding: 10,
            background: `${colors.new_primary}`,
            border: "none",
            borderRadius: "0px",
          }}
          onClick={() => {
            toggleApplyModal({
              bool: true,
              accountType: "owner",
              headerText: "Nice to meet you!",
              subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
            })
          }}
        >
          Join Radius
        </Button>
      </ButtonWrap>
    </InnerWrap>
  </Wrap>
)

export default Testimonials
