import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import Button from "@ui/Button"
import colors from "@colors"
import { HIGHLIGHT_FONT, ATC } from "@fonts"
import WebinarInfoImage from "dumbComponents/NewAgentLP/images/webinarInfo.png"

const RadiusInfoWrapper = styled.div`
  display: flex;
  padding: 60px;
  padding-top: 0px;
  justify-content: space-evenly;
  position: relative;
  max-width: 1440px;
  margin: 100px 80px;
  @media(max-width: 1109px) {
    padding: 30px 10px;
    flex-direction: column-reverse;
    margin: 85px 50px;
  }

  @media(min-width: 1512px) {
    margin: 40px auto;
  }

  @media(max-width: 425px) {
    margin: 85px 15px;
  }
`
const RadiusInfo = styled.div`
  width: 50%;
  padding-top: 40px;
  @media(max-width: 1109px) {
    width: 100%;
    padding: 20px;
  }

  @media(max-width: 425px) {
    padding: 0px;
  }
`

const InfoHeader = styled.h1`
  font-family: ${HIGHLIGHT_FONT};
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  max-width: 485px;
  width: 100%;
  line-height: 49px;
  letter-spacing: 0.005em;
  color: ${colors.black};
  @media(max-width: 1024px) {
    text-align: center;
    max-width: 100%;
    font-size: 36px;
  }
  @media(max-width: 767px) {
    font-size: 32px;
    line-height: 36px;
  }
`
const InfoPara = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 22px;
  max-width: 460px;
  width: 100%;
  line-height: 30px;
  color: ${colors.black};
  margin-bottom: 22px;
  @media(max-width: 1024px) {
    font-size: 20px;
    max-width: 100%;
    text-align: center;
    line-height: 22px;
  }
  @media(max-width: 425px) {
    font-size: 18px;
  }
`

const Colored = styled.span`
  color: ${colors.new_primary};
  white-space: nowrap;
`

const Image = styled.img`
  display: block;
  max-width: 530px;
  max-height: 425px;
  width: auto;
  height: auto;
  margin: auto;
  cursor: pointer;
  @media(max-width: 1109px) {
    width: 100%;
    height: auto;
  }
`

const ButtonWrap = styled.div`
  margin-top: 26px;
  @media(max-width: 1109px) {
    text-align: center;
  }
  ${props => props.section === "3" && `
    text-align: center;
  `}

  > button {
    line-height: 0px;
  }
`

const WebinarInfo = () => {
  return (
    <RadiusInfoWrapper>
      <RadiusInfo>
        <InfoHeader>
          Learn more about Radius in&nbsp;
          <Colored>10-Minutes</Colored>
        </InfoHeader>
        <InfoPara>
          Get an overview of how our brokerage works for you. Our next webinar starts now.
        </InfoPara>
        <ButtonWrap>
          <Button
            bsStyle="marineBLueFill"
            padding="16px"
            width="180px"
            height="47px"
            borderRadius="0px"
            loaderOption={{
              color: colors.new_primary,
            }}
            onClick={() => {
              root.open("https://app.ewebinar.com/webinar/radius-brokerage-overview-6293")
              if (root.heap) {
                root.heap.track("Click_Register_Webinar_Recruit_And_Earn")
              }
            }}
          >
            View Webinar
          </Button>
        </ButtonWrap>
      </RadiusInfo>
      <Image
        src={WebinarInfoImage}
        alt="radius brokerage overview"
        width="600"
        onClick={() => {
          root.open("https://app.ewebinar.com/webinar/radius-brokerage-overview-6293")
          if (root.heap) {
            root.heap.track("Click_Register_Webinar_Recruit_And_Earn")
          }
        }}
      />
    </RadiusInfoWrapper>
  )
}

export default WebinarInfo
