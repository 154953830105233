import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
// import root from "window-or-global"
import * as AppActions from "./actions"

// eslint-disable-next-line max-len
const initState = {
  isFetchingUserObject: false,
  setFormStageValue: "0",
  fileUploading: null,
  toggleSetupPopupStep: null,
  paymentBannerVisible: false,
  ...createDeltaReducer("fetchUserInfoResponse"),
  ...createDeltaReducer("fetchOrientationInfoResponse"),
  ...createDeltaReducer("uploadDocsToBucketResponse"),
  ...createDeltaReducer("createOrientationInfoResponse"),
  ...createDeltaReducer("updateOrientationInfoResponse"),
  ...createDeltaReducer("hubspotSubscriptionRequestResponse"),

  ...createDeltaReducer("getOnboardingSetupResponse"),
  ...createDeltaReducer("createOnboardingSetupResponse"),
  ...createDeltaReducer("updateOnboardingSetupResponse"),
  ...createDeltaReducer(AppActions.getStripeSecretKeyAction.RESPONSE),
  ...createDeltaReducer(AppActions.getFeeScheduleAction.RESPONSE),
  ...createDeltaReducer(AppActions.sendVerifyEmailLinkAction.RESPONSE),
  ...createDeltaReducer(AppActions.verifyEmailLinkTokenAction.RESPONSE),
  ...createDeltaReducer(AppActions.getDisplayScreenAction.RESPONSE),
  ...createDeltaReducer(AppActions.getReceiverViewICAAction.RESPONSE),
  ...createDeltaReducer(AppActions.getAllICADocListAction.RESPONSE),
  ...createDeltaReducer(AppActions.updateEnvelopeStatusAction.RESPONSE),
  ...createDeltaReducer(AppActions.updateAgentEmailAction.RESPONSE),
  ...createDeltaReducer(AppActions.checkPaymentBannerAction.RESPONSE),
}

export default function appReducer(state = initState, action) {
  switch (action.type) {
    // App Mount START
    case AppActions.fetchUserInfoAction.REQUEST: {
      return {
        ...state,
        isFetchingUserObject: true,
        ...action.data,
      }
    }

    // case AppActions.fetchUserInfoAction.FAILURE:
    // case AppActions.fetchUserInfoAction.SUCCESS: {
    //   const {
    //     phone,
    //     firstname,
    //     lastname,
    //     email,
    //     brokerageTeamId,
    //   } = action.data || {}
    //   const isPhoneNumberMissing = action.data && !phone
    //   const isNameIncomplete = (action.data && !firstname)
    //       || (action.data && !lastname)
    //   const isEmailMissing = action.data && !email
    //   return {
    //     ...state,
    //     brokerageTeamId,
    //     isIncompleteProfileBannerVisible: isPhoneNumberMissing
    //         || isNameIncomplete || isEmailMissing,
    //     user: action.data || {},
    //     isFetchingUserObject: false,
    //   }
    // }

    // case AppActions.fetchUserInfoAction.REQUEST:
    case AppActions.fetchUserInfoAction.SUCCESS:
    case AppActions.fetchUserInfoAction.CLEAR:
    case AppActions.fetchUserInfoAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, AppActions.getTeamsActions, "fetchUserInfoResponse"),
      }
    }

    case AppActions.fetchUserOrientationInfoAction.SUCCESS:
    case AppActions.fetchUserOrientationInfoAction.CLEAR:
    case AppActions.fetchUserOrientationInfoAction.FAILURE: {
      console.log("inside reducer")
      return {
        ...state,
        ...handleDeltaReducer(state, action, AppActions.fetchUserOrientationInfoAction, "fetchOrientationInfoResponse"),
      }
    }

    case AppActions.createUserOrientationInfoAction.SUCCESS:
    case AppActions.createUserOrientationInfoAction.CLEAR:
    case AppActions.createUserOrientationInfoAction.FAILURE: {
      console.log("inside reducer")
      return {
        ...state,
        ...handleDeltaReducer(state, action, AppActions.createUserOrientationInfoAction, "createOrientationInfoResponse"),
      }
    }

    case AppActions.updateUserOrientationInfoAction.SUCCESS:
    case AppActions.updateUserOrientationInfoAction.CLEAR:
    case AppActions.updateUserOrientationInfoAction.FAILURE: {
      console.log("inside reducer")
      return {
        ...state,
        ...handleDeltaReducer(state, action, AppActions.updateUserOrientationInfoAction, "updateOrientationInfoResponse"),
      }
    }

    case AppActions.uploadDocsToBucketAction.REQUEST:
    case AppActions.uploadDocsToBucketAction.FAILURE:
    case AppActions.uploadDocsToBucketAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          AppActions.uploadDocsToBucketAction,
          "uploadDocsToBucketResponse"
        ),
      }
    }

    case AppActions.setFormStage.type: {
      return {
        ...state,
        setFormStageValue: action.data,
      }
    }

    case AppActions.setFileUploading.type: {
      return {
        ...state,
        fileUploading: action.data,
      }
    }

    case AppActions.toggleSetupPopup.type: {
      return {
        ...state,
        toggleSetupPopupStep: action.data,
      }
    }

    case AppActions.getOnboardingSetup.REQUEST:
    case AppActions.getOnboardingSetup.FAILURE:
    case AppActions.getOnboardingSetup.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          AppActions.getOnboardingSetup,
          "getOnboardingSetupResponse"
        ),
      }
    }

    case AppActions.createOnboardingSetup.REQUEST:
    case AppActions.createOnboardingSetup.FAILURE:
    case AppActions.createOnboardingSetup.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          AppActions.createOnboardingSetup,
          "createOnboardingSetupResponse"
        ),
      }
    }

    case AppActions.updateOnboardingSetup.REQUEST:
    case AppActions.updateOnboardingSetup.FAILURE:
    case AppActions.updateOnboardingSetup.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          AppActions.updateOnboardingSetup,
          "updateOnboardingSetupResponse"
        ),
      }
    }

    case AppActions.getStripeSecretKeyAction.REQUEST:
    case AppActions.getStripeSecretKeyAction.FAILURE:
    case AppActions.getStripeSecretKeyAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          AppActions.getStripeSecretKeyAction,
          AppActions.getStripeSecretKeyAction.RESPONSE,
        ),
      }
    }

    case AppActions.getFeeScheduleAction.REQUEST:
    case AppActions.getFeeScheduleAction.FAILURE:
    case AppActions.getFeeScheduleAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          AppActions.getFeeScheduleAction,
          AppActions.getFeeScheduleAction.RESPONSE,
        ),
      }
    }

    case AppActions.sendVerifyEmailLinkAction.REQUEST:
    case AppActions.sendVerifyEmailLinkAction.FAILURE:
    case AppActions.sendVerifyEmailLinkAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          AppActions.sendVerifyEmailLinkAction,
          AppActions.sendVerifyEmailLinkAction.RESPONSE,
        ),
      }
    }

    case AppActions.verifyEmailLinkTokenAction.REQUEST:
    case AppActions.verifyEmailLinkTokenAction.FAILURE:
    case AppActions.verifyEmailLinkTokenAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          AppActions.verifyEmailLinkTokenAction,
          AppActions.verifyEmailLinkTokenAction.RESPONSE,
        ),
      }
    }

    case AppActions.getDisplayScreenAction.REQUEST:
    case AppActions.getDisplayScreenAction.FAILURE:
    case AppActions.getDisplayScreenAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          AppActions.getDisplayScreenAction,
          AppActions.getDisplayScreenAction.RESPONSE,
        ),
      }
    }

    case AppActions.getReceiverViewICAAction.REQUEST:
    case AppActions.getReceiverViewICAAction.FAILURE:
    case AppActions.getReceiverViewICAAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          AppActions.getReceiverViewICAAction,
          AppActions.getReceiverViewICAAction.RESPONSE,
        ),
      }
    }

    case AppActions.getAllICADocListAction.REQUEST:
    case AppActions.getAllICADocListAction.FAILURE:
    case AppActions.getAllICADocListAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          AppActions.getAllICADocListAction,
          AppActions.getAllICADocListAction.RESPONSE,
        ),
      }
    }

    case AppActions.triggerPaymentBannerAction.type: {
      return {
        ...state,
        paymentBannerVisible: action.data,
      }
    }

    case AppActions.updateEnvelopeStatusAction.REQUEST:
    case AppActions.updateEnvelopeStatusAction.FAILURE:
    case AppActions.updateEnvelopeStatusAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          AppActions.updateEnvelopeStatusAction,
          AppActions.updateEnvelopeStatusAction.RESPONSE,
        ),
      }
    }

    case AppActions.updateAgentEmailAction.REQUEST:
    case AppActions.updateAgentEmailAction.FAILURE:
    case AppActions.updateAgentEmailAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          AppActions.updateAgentEmailAction,
          AppActions.updateAgentEmailAction.RESPONSE,
        ),
      }
    }

    case AppActions.checkPaymentBannerAction.REQUEST:
    case AppActions.checkPaymentBannerAction.FAILURE:
    case AppActions.checkPaymentBannerAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          AppActions.checkPaymentBannerAction,
          AppActions.checkPaymentBannerAction.RESPONSE,
        ),
      }
    }
    
    case AppActions.hubspotSubscriptionAction.REQUEST:
    case AppActions.hubspotSubscriptionAction.FAILURE:
    case AppActions.hubspotSubscriptionAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          AppActions.hubspotSubscriptionAction,
          'hubspotSubscriptionRequestResponse'
        ),
      }
    }

    default:
      return state
  }
}
