import * as React from 'react'

const Plus = props => {
  const { color = '#fff'} = props

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path d="M16.6654 10.0003H3.33203" stroke={color} stroke-linecap="round" />
      <path d="M10.0013 3.33301V16.6663" stroke={color} stroke-linecap="round" />
    </svg>
  )
}

export default Plus
