import STATES from "shared/US_STATES_RADIUS.json"

export const TYPE_OF_OWNERSHIP = [{
  value: "individual",
  label: "Individual",
}, {
  value: "trust",
  label: "Trust",
}, {
  value: "entity",
  label: "Entity(LLC, Corp, Partnership, Other)",
}, {
  value: "power_of_attorney",
  label: "Power of Attorney",
}, {
  value: "probate_court_administration",
  label: "Probate/Court Administration",
}, {
  value: "estate",
  label: "Estate (Estate, Conservatorship, Guardianship, Other)",
},
{
  value: "successor_trust",
  label: "Successor Trust",
}]


export const REPRESENTING = [{
  label: "Buyer",
  value: "buyer",
}, {
  label: "Seller",
  value: "seller",
}, {
  label: "Landlord",
  value: "landlord",
}, {
  label: "Tenant",
  value: "tenant",
}, {
  label: "Dual",
  value: "dual",
}, {
  label: "Other",
  value: "other",
}]

const SELLER_OPTIONS = [{
  label: "Listing with accepted offer",
  value: "listing_with_accepted_offer",
}, {
  label: "Dual",
  value: "dual",
}, {
  label: "External Referral Agreement",
  value: "external_referral_agreement",
}]

const BUYER_OPTIONS = [{
  label: "Pending Contract",
  value: "pending_contract",
}, {
  label: "External Referral Agreement",
  value: "external_referral_agreement",
}, {
  label: "Dual",
  value: "dual",
}]

const LANDLORD_OPTIONS = [{
  label: "Lease Listing",
  value: "lease_listing",
}, {
  label: "External Referral Agreement",
  value: "external_referral_agreement",
}]

const TENANT_OPTIONS = [{
  label: "Pending Lease",
  value: "pending_lease",
}, {
  label: "External Referral Agreement",
  value: "external_referral_agreement",
}]

const DUAL_OPTIONS = [{
  label: "Dual",
  value: "dual",
}]

const OTHER_OPTIONS = [{
  label: "External Referral Agreement",
  value: "external_referral_agreement",
}]

export const CLIENT_FILE_MAP = {
  seller: SELLER_OPTIONS,
  buyer: BUYER_OPTIONS,
  landlord: LANDLORD_OPTIONS,
  tenant: TENANT_OPTIONS,
  dual: DUAL_OPTIONS,
  other: OTHER_OPTIONS,
}

export const CLIENT_SOURCE = [
  {
    label: "OpCity/Realtor.com",
    value: "opcity_realtor_com",
  },
  {
    label: "Radius Marketplace",
    value: "radius_marketplace",
  }, {
    label: "Referrals Agent Lender",
    value: "referrals_agent_Lender",
  }, {
    label: "Self",
    value: "self",
  }, {
    label: "Sphere of Influence/Personal",
    value: "sphere_of_influence",
  }, 
  {
    label: "My mobile app",
    value: "my_mobile_app",
  },
  {
    label: "Team/Mentor Lead",
    value: "team_mentor_lead",
  }, {
    label: "Zillow (Agent's Personal Account)",
    value: "zillow_agent_personal_account",
  }, {
    label: "Zillow Flex",
    value: "zillow_flex",
  }, {
    label: "Zillow (Radius Provided)",
    value: "zillow_radius_provided",
  }, {
    label: "Other",
    value: "other",
  }, {
    label: "FSBO",
    value: "fsbo",
  }, {
    label: "Mail",
    value: "mail",
  }, {
    label: "Networking",
    value: "networking",
  }, {
    label: "New Construction",
    value: "new_construction",
  }, {
    label: "Open House",
    value: "open_house",
  }, {
    label: "Other - Agency",
    value: "other_agency",
  }, {
    label: "Other - Builder",
    value: "other_builder",
  }, {
    label: "Paid Marketing - Farming/Direct",
    value: "paid_marketing_farming_direct",
  }, {
    label: "Paid Marketing - Other",
    value: "paid_marketing_other",
  }, {
    label: "Paid Marketing - Zillow",
    value: "paid_marketing_zillow",
  }, {
    label: "Personal Transaction",
    value: "personal_transaction",
  }, {
    label: "Property Management",
    value: "property_management",
  }, {
    label: "Floor Call",
    value: "floor_call",
  }, {
    label: "Referral - Attorney",
    value: "referral_attorney",
  }, {
    label: "Referral - From Past Client",
    value: "referral_from_past_client",
  }, {
    label: "Referral - Lendor",
    value: "referral_lendor",
  }, {
    label: "Referral - Other",
    value: "referral_other",
  }, {
    label: "Referral - Real Estate Agent(External)",
    value: "referral_real_estate_agent_external",
  }, {
    label: "Referral - Sphere of Influence",
    value: "referral_sphere_of_influence",
  }, 
  {
    label: "My mobile app",
    value: "my_mobile_app",
  },
  {
    label: "Referral - Title",
    value: "referral_title",
  }, {
    label: "Sign Call",
    value: "sign_call",
  }, {
    label: "Social Profile - Facebook",
    value: "social_profile_facebook",
  }, {
    label: "Social Profile - Instagram",
    value: "social_profile_instagram",
  }, {
    label: "Social Profile - Other",
    value: "social_profile_other",
  }, {
    label: "Events - Other",
    value: "events_other",
  },
]

export const RENT_INTERVAL_TYPE = [{
  label: "Month",
  value: "month",
}, {
  label: "Annual",
  value: "annual",
}, {
  label: "Other",
  value: "other",
}]

export const COMMISSION_TYPE = [{
  label: "Percentage",
  value: "percentage",
}, {
  label: "Fixed Fee",
  value: "fixed",
}, {
  label: "Both",
  value: "both",
}]

export const TRANSACTION_TYPE = [{
  label: "Standard Residential",
  value: "residential_1_4",
}, {
  label: "Residential Income Property",
  value: "residential_inc",
}, {
  label: "Vacant Land",
  value: "vacant_land",
}, {
  label: "Manufactured/Mobile Home",
  value: "manufactured_mo",
}, {
  label: "New Construction",
  value: "new_construction",
}, {
  label: "Lease",
  value: "lease",
}, {
  label: "Short Sales",
  value: "short_sales",
}, {
  label: "Relocation",
  value: "relocation",
}, {
  label: "Referral Agreement",
  value: "referral_agreem",
}, {
  label: "Condominium",
  value: "condominium",
}, {
  label: "Fame & Ranch",
  value: "farm_and_ranch",
}, {
  label: "Commercial",
  value: "commercial",
}, {
  label: "Other",
  value: "other",
}]

export const PROPERTY_MEASURED_AS = [{
  label: "Sq.ft",
  value: "Sq.ft",
}, {
  label: "Sq.mt",
  value: "Sq.mt",
}, {
  label: "Acres",
  value: "Acres",
}]

export const DEFAULT_TRANSACTION_TYPE = [{
  label: "Standard Residential",
  value: "residential_1_4",
}, {
  label: "Residential Income Property",
  value: "residential_inc",
}, {
  label: "Vacant Land",
  value: "vacant_land",
}, {
  label: "Manufactured/Mobile Home",
  value: "manufactured_mo",
}, {
  label: "New Construction",
  value: "new_construction",
}, {
  label: "Lease",
  value: "lease",
}]

export const YES_OR_NO = [{
  label: "Yes",
  value: 1,
}, {
  label: "No",
  value: 0,
}]

export const STATE_OPTIONS = Object.keys(STATES).map(key => ({ label: STATES[key], value: STATES[key] }))
