import React from "react"
import NeedHelpButton from "dumbComponents/NeedHelpButton/index";
import ListingsV2Container from "container/ListingsV2"
import {
  Wrap,
  TabWrap,
  TabInfo,
  TabTitle,
} from "./styles"

const ListingsTabs = ({
  currentTab,
  toggleTab,
  steps,
  tabWidth,
  showNeedHelpButton,
}) => {
  return (
    <Wrap>
      <TabWrap width={tabWidth} showNeedHelpButton={showNeedHelpButton}>
        {steps && steps.map(step => (
          <TabInfo 
            onClick={() => toggleTab(step.value)} 
            isActive={currentTab === step.value}
            disabled={step.disabled}
          >
            <TabTitle isActive={currentTab === step.value}>
              {step.label}
            </TabTitle>
          </TabInfo>
        ))}

        {showNeedHelpButton && <NeedHelpButton pullToTheRight />}
      </TabWrap>
    </Wrap>
  )
}

export default ListingsV2Container(ListingsTabs)
