import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import Container from "container/App"
import TechAssets from "container/TechAssets"
import root from "window-or-global"
import colors from "@colors"
import cookie from "services/CookieStorage"
import PhoneCollectionModal from "dumbComponents/common/PhoneCollectionModal"
import SiteOnBoarding from "dumbComponents/common/NewOnboardingFlow/TooltipJoyride"
import NetworkAgentModal from "dumbComponents/common/NetworkAgentModal"
import { get } from "lodash"
import { checkForUserObject, isAuthenticated } from "services/Utils"
import ConfirmModal from "@ui/ConfirmModal"
import Popups from "@ui/Popups"
import { withRouter } from "react-router-dom"
import InviteModal from "dumbComponents/common/GlobalModals/InviteModal"
import InviteFeatureContainer from "container/App/inviteFeatureContainer"
import EWebinarComponent from "dumbComponents/common/EWebinarScript"
import Intercom from "dumbComponents/common/IntercomScript"
import BannerModal from "./GlobalModals/BannerModal/index"
//import MessagesModal from "dumbComponents/common/MessagesTab"
import Pubnub from "./Pubnub"

const Wrap = styled.div`
  background: ${colors.white};
  ${props => props.isShowInviteeBanner && `
    padding-top: 50px;
  `}
`

class Layout extends React.Component {
  state = {
    isClient: false,
  }

  componentDidMount() {
    const {
      fetchUserInfo,
      appReady,
      getPopUpScreen,
      trackSession,
      history,
      fetchInviteStatus,
    } = this.props
    const user = checkForUserObject()
    const isWCR = cookie.load("isWcr")
    this.isLogged = isAuthenticated()

    getPopUpScreen()
    trackSession()
    fetchInviteStatus()

    if (!cookie.load("cookies-allowed")) {
      this.removeCookiesPostUserChoice()
    }

    const {
      leadAgreementSigned,
      referralManager,
      isCrsDesignee,
      allowLeadAgreement,
      isaLeadAgreementSigned,
      gmail_email,
      office_short_state_name,
      office_city,
      profile_name,
      isaManager,
    } = user
    if (
      !leadAgreementSigned
      || !referralManager
      || office_short_state_name
      || office_city
      || profile_name
      || isCrsDesignee === undefined
      || allowLeadAgreement === undefined
      || isaLeadAgreementSigned === undefined
      || gmail_email === undefined
      || gmail_email === "null"
      || (referralManager && !referralManager.calendly_links)
      || !isWCR
      || !isaManager
    ) {
      fetchUserInfo({ 
        history,
        triggerDataLayerPush: true,
      })
    } else {
      appReady()
    }

    this.setState({
      isClient: true,
    })
  }

  componentDidUpdate() {
    const { pathname } = root.location
    const {
      user,
      user: { phone },
      togglePhoneCollectionModal,
      isPhoneCollectionModalOpen,
      isAppMounted,
      isPhoneSaved,
    } = this.props
    if (isAppMounted
        && !user.isUnauthorised
        && !isPhoneSaved
        && !phone
        && !isPhoneCollectionModalOpen
        && this.isLogged
        && pathname !== "/community/onboarding/welcome"
        // && !(pathname === "/radius-assist" || pathname === "/isa-program")
    ) {
      setTimeout(() => {
        togglePhoneCollectionModal(true)
        // TODO: [BUGGY] Does not work as expected, rca and fix required
        // bugsnag.notify(
        //   new Error("Phone collection modal opened", {
        //     severity: "info",
        //     context: "Call-Modal",
        //     user,
        //   })
        // )
      }, 2000)
    }

    if (this.isLogged || pathname === "/login" || pathname === "/signup") {
      // if (root.HubSpotConversations) {
      //   root.HubSpotConversations.widget.load()
      // }
    }
  }

  removeCookiesPostUserChoice = () => {
    cookie.remove("_gat") //remove google analytics cookie.
    cookie.remove("_fbp") //remove facebook cookie.

    /*PubMatic Cookies*/
    cookie.remove("KRTBCOOKIE_10")
    cookie.remove("PugT")

    // Remove the default tracker.
    if (window.ga) window.ga("remove")
    // Remove the default cookies
    // _ga is used to distinguish users.
    cookie.remove("_ga", { path: "/", domain: document.domain })
    // _gid is used to distinguish users.
    cookie.remove("_gid", { path: "/", domain: document.domain })
    // _gat is used to throttle request rate.
    cookie.remove("_gat", { path: "/", domain: document.domain })
  }

  getClassName = (loc) => {
    if (!loc) return ""

    /**
     *  Home page - external_home_page
        Rooms page - external_rooms_page
        About Us page - external_aboutus_page
     */

    switch (loc) {
      case "/":
        return "external_home_page"
      case "/rooms":
        return "external_rooms_page"
      case "/about":
        return "external_aboutus_page"
      default:
        return "radius"
    }
  }

  render() {
    const {
      children,
      showNewFeedsAvailable,
      collectPhoneNumber,
      isSavingPhoneNumber,
      isPhoneCollectionModalOpen,
      errorMessage,
      isShowConfirmModal,
      popups,
      closePopUp,
      togglePhoneCollectionModal,
      isAllowedClosingPhoneModal,
      isShowInviteeBanner,
      isStartNavbarSpotlightFlow,
      user,
      finishTabsSpotlightFlow,
      isInvitePopUpOpen,
      firstTimeReferralPopup,
      isBroker,
      getStateAnnouncementResponse,
      sideMenuToggle,
      isSideMenuVisible,
      history,
      isAppMounted,
      showNetworkAgentPopup
    } = this.props
    const { isClient } = this.state
    const isUserPresent = user && Object.keys(user).length > 0

    const {
      state,
      role,
      individual_annual_production,
      isSubscribedToBrokerPlan,
    } = user || {}

    const {
      data: stateBanner,
      isFetching: fetchingStateBanner,
    } = getStateAnnouncementResponse || {}

    const isStateBannerViewed = cookie.load("stateBannerViewedUpdated")
    const isNetworkPopupViewed = cookie.load("networkAgentBannerStatus")
    const isUserTc = cookie.load("tcInformation");
    
    const { pathname } = root.location
    const isRoomsPath = pathname && (pathname.includes("rooms") || pathname.includes("scheduledrooms"))

    const currentLocation = history.location && history.location.pathname

    const currentClassName = this.getClassName(currentLocation)

    return (
      <>
        <Wrap
          id="index-layout"
          className={`${currentClassName}`}
          isShowInviteeBanner={isShowInviteeBanner}
          onClick={(e) => {
            const isHamburgerClicked = e.target.id === "radius-hamburger-img" || e.target.id === "radius-hamburger"
            if (!isSideMenuVisible && isHamburgerClicked) {
              sideMenuToggle(true)
            } else if (isSideMenuVisible) {
              sideMenuToggle(false)
            }
          }}
        >
          <Helmet>
            <title>Real Estate Agent Community | Virtual Brokerage | Radius Agent</title>
            <meta property="description" content="Join the Radius - A virtual brokerage where you keep 100% of your commissions and social network to exchange market insights, share success stories and earn more money through our lead referral marketplace."/>
            {/*<meta property="og:image" content={SEOImg} />*/}
            <meta property="og:description" content="Join the Radius - A virtual brokerage where you keep 100% of your commissions and social network to exchange market insights, share success stories and earn more money through our lead referral marketplace." />
          </Helmet>
          {isClient && (
            <Pubnub />
          )}
          {firstTimeReferralPopup || isInvitePopUpOpen && (
            <InviteModal />
          )}

          {/* {!fetchingStateBanner && stateBanner && !isStateBannerViewed && !isRoomsPath && (
            <BannerModal stateBanner={stateBanner[0]} />
          )} */}
          {/* {showNetworkAgentPopup && !isNetworkPopupViewed && !isUserTc?.is_tc && (
            <NetworkAgentModal />
          )} */}
          {/* {isStartNavbarSpotlightFlow && (
            <SiteOnBoarding
              user={user}
              finishTabsSpotlightFlow={finishTabsSpotlightFlow}
            />
          )} */}
          <div>{children}</div>
          {/* {isShowConfirmModal && (
            <ConfirmModal />
          )} */}
          {/* <Popups
            popups={popups}
            closePopUp={closePopUp}
          /> */}
          {/* {firstTimeReferralPopup || isInvitePopUpOpen && (
            <InviteModal />
          )} */}

           {/* {!fetchingStateBanner
          && stateBanner
          && !isStateBannerViewed
          && !isRoomsPath
          && (
            <BannerModal stateBanner={get(stateBanner, "[0]")} />
          )} */}
        </Wrap>
        {false && (this.isLogged && isUserPresent) && (
          <EWebinarComponent
            isBroker={isBroker}
            user={user}
          />
        )}
        {isAppMounted && (
          <Intercom
            isBroker={isBroker}
            user={user}
          />
        )}
      </>
    )
  }
}

export default withRouter(Container(InviteFeatureContainer(TechAssets(Layout))))
