import React from "react"
import styled, { keyframes } from "styled-components"
import root from "window-or-global"
import CircularImage from "dumbComponents/common/CircularImage"
import { authCheck } from "services/auth"
import RARPopupContainer from "container/App/RARPopUpContainer"
import MariImage from "images/realty-v2/mari_kawaski.png"
import { getReferralManager } from "services/Utils"
import queryString from "query-string"
import colors from "@colors"
import cookie from "services/CookieStorage"
import RealtyForm from "./RealtyForm"
import { ATC } from "@fonts"

const showAnimation = keyframes`
  0% {
    height: 0px;
    width: 0px;
    box-shadow: 0px;
  }

  100% {
    height: 79px;
    width: 375px;
    box-shadow: 0 2px 12px 4px rgba(0,0,0,0.05);
  }
`

const showAnimationFlip = keyframes`
  0% {
    height: 340px;
    width: 300px;
    box-shadow: 0 2px 12px 4px rgba(0,0,0,0.05);
  }
  100% {
    height: 0px;
    width: 0px;
    box-shadow: 0px;
  }


`

const StyledAnchor = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  text-decoration: none;
  // background: ${colors.darkPurple};
  background: white;
  cursor: pointer;
  z-index:9999;
  border: 1px solid #FBF8EF;
  // border-radius: 24px;
  padding: 14px 13px 14px 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media(max-width: 375px) {
    right: 0px;
  }

  h2 {
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    // color: white;
    margin: 0px 0px 2px 0px;
  }

  p {
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    // color: white;
    margin: 0;
  }

  button {
    padding: 8px 14px;
    // height: 44px;
    // width: 128.656px;
    // border-radius: 100px;
    cursor: pointer;
    // color: ${colors.black};
    color: white;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    border: none;
    background: #5A5FF2;
    font-family: ${ATC};
  }

  ${props => props.isOpen ? `
    animation: ${showAnimation} 0.3s ease forwards;
  ` : `
    animation: ${showAnimationFlip} 0s ease forwards;
  `}


  /* ${props => props.isISA && `
    width: 320px !important;
  `} */
  /* ${props => props.isOpen && props.isISA && `
    height: 375px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 4px;
    cursor: inherit;
    width: 310px !important;
    background: yellow;
  `}

   ${props => props.isOpen && !props.isISA && `
      height: 79px;
      width: 375px;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
      text-align: center;
      border-radius: 5px;
      cursor: inherit;
   `} */
`

const TextWrap = styled.div`
  h2{
    font-family: Butler;
    font-size: 16px;
  }

  p{
    font-family: ATC;
    font-weight: 300;
    font-size: 12px;
  }
  width: 144px;
`

const FoldedView = styled.div`
  width: 60px;
  height: 60px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 10px;
  position: fixed;
  background: center no-repeat;
  background-size: cover;
  right: 20px;
  bottom: 40px;
  border: 4px solid ${colors.white};
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 2px 12px 4px rgba(0,0,0,0.05);
  background-color: #3C4857;
`

class ReferralManagerContactV3 extends React.Component {
  constructor(props) {
    super(props)
    const {
      isOpen = false,
    } = props
    this.state = {
      isOpen,
      isMounted: false,
      isOpenForm: false,
      formError: false,
      currentPath: "",
      showComponent: false,
    }
  }

  componentDidMount() {
    const { search, pathname } = root.location
    const parsedQuery = queryString.parse(search)
    const utmSource = parsedQuery && parsedQuery.utm_source
    const isHomePage = !!(pathname === "/" || pathname === "")
    const rar_promotions_source_page = pathname && !isHomePage ? `${pathname.replace("/", "")}_chatbox_form_fill` : "homepage_chatbox_form_fill"
    const currentPath = pathname && !isHomePage ? pathname.replace("/", "") : "homepage"

    const {
      ms,
      toggleRARFormOpen,
      isPlugIn,
    } = this.props

    this.setState({
      utmSource,
      rar_promotions_source_page,
      currentPath,
    })

    //settimeout to show component after a minute
    // setTimeout(() => {
    //   this.setState({
    //     showComponent: true,
    //   })
    // }, 60000)

    const someTimeoutOne = setTimeout(() => {
      this.setState({
        isMounted: true,
        isOpen: true,
      })
      clearTimeout(someTimeoutOne)
    }, isPlugIn ? 3000 : ms)

    const someTimeoutTwo = setTimeout(() => {
      cookie.save("openRARForm", true)
      this.setState({
        isOpenForm: true,
      })
      toggleRARFormOpen(true)
      clearTimeout(someTimeoutTwo)
    }, 10000)
  }

  toggleOpen = () => {
    const { isOpen } = this.state
    const newIsOpen = !isOpen
    this.setState({
      isOpen: newIsOpen,
    })
  }

  handleClick = () => {
    const {
      isOpenForm,
      currentPath,
    } = this.state

    const {
      RARTracker,
      toggleRARFormOpen,
      isRARPopupOpen,
    } = this.props

    const isLogged = authCheck()

    if (root.heap && !isOpenForm) {
      root.heap.track("Click_FreeConsultation_Widget_Web")
    }

    if (isLogged) {
      RARTracker({
        payload: {
          source: `${currentPath}_chatbox_click`,
        },
      })
    }

    this.setState({
      isOpenForm: !isOpenForm,
    })
    toggleRARFormOpen(!isRARPopupOpen)
  }

  handleFormSubmit = (values) => {
    const {
      submitScheduleACall,
      currentState,
      currentCity,
      currentRARPlanSelected,
      RARTracker,
    } = this.props

    const {
      currentPath,
    } = this.state

    if (root.heap) {
      root.heap.track("Click_Send_Web")
    }

    const {
      rar_promotions_source_page,
      utmSource,
      isOpenForm,
      teamSize,
    } = this.state

    const firstName = values.name && values.name.split(" ")[0]
    const lastName = values.name && values.name.split(" ")[1] || ""
    const promoAgentId = parseInt(cookie.load("realty_promotion_id"), 10)
    const payload = values
    payload.state = currentState
    payload.city = currentCity
    payload.firstname = firstName || ""
    payload.lastname = lastName || ""
    payload.rar_promotions_source_page = rar_promotions_source_page || ""

    if (utmSource) {
      payload.utm_source = utmSource || ""
    }

    if (promoAgentId) {
      payload.from_agent_id = promoAgentId
    }

    if (currentRARPlanSelected && Object.keys(currentRARPlanSelected).length > 0) {
      payload.plan_name_display = currentRARPlanSelected.plan_name_display
      payload.is_team_plan = currentRARPlanSelected.is_team_plan
      payload.plan_name_internal = currentRARPlanSelected.plan_name_internal

      if (currentRARPlanSelected.is_team_plan === "1") {
        payload.team_members_count = teamSize
      }
    }

    delete payload.isFromList

    const getHubSpotSource = (path) => {
      switch (path) {
        case "/":
          return "Home-page-pop-up"
        case "/realty/agents":
          return "Our-Agents-pop-up"
        case "/network":
          return "Community-LP-pop-up"
        case "/realty/for-agents":
          return "For-Agents-pop-up"
        case "/realty/teams":
          return "For-Teams-pop-up"
        case "/teams/jobs":
          return "Jobs-LP-pop-up"
        case "/realty/radius-financing":
          return "Radius-FinancingLP-pop-up"
        case "/radius-assist":
          return "Radius-Assist-pop-up"
        case "/realty/pricing":
          return "Radius-PricingLP-pop-up"
        default:
          return "Home-page-pop-up"
      }
    }

    if (currentCity) {
      this.setState({
        formError: false,
      })
      this.toggleOpen()
      /*RAR TRACKER TO TRACK POPUP SUBMISSION */
      RARTracker({
        payload: {
          source: getHubSpotSource(currentPath),
        },
      })

      submitScheduleACall({
        payload,
      })
    } else {
      this.setState({
        formError: true,
      })
    }
  }

  handleChatClick = () => {
    this.toggleOpen()
    root.HubSpotConversations.open()
    root.HubSpotConversations.close()
  }

  getBookADemoLink = () => "https://meetings.hubspot.com/mari23/rar-m"

  componentDidUpdate(prevProps) {
    const {
      hideHubspot,
    } = prevProps
    // if (root.HubSpotConversations) {
    //   const status = root.HubSpotConversations.widget.status()
    //   if (status.loaded) {
    //     root.HubSpotConversations.widget.remove()
    //   } else if (!hideHubspot) {
    //     root.HubSpotConversations.widget.load()
    //   } else {
    //     root.HubSpotConversations.widget.remove()
    //   }
    // }
  }

  render() {
    const {
      isOpen,
      isMounted,
      isOpenForm,
      formError,
      showComponent,
    } = this.state
    const {
      type,
      user,
      setCurrentLocationForRealty,
      currentCity,
      isRARPopupOpen,
      isPlugIn,
      scheduleACallResponse,
      isInterestSubmitted,
    } = this.props

    let referralManager = null
    if (type === "isa") {
      // This is for logged in state for Radius Assist
      referralManager = getReferralManager(true, "isa")
    } else {
      // This is for other pages.
      referralManager = getReferralManager(false, type)
    }
    const {
      photo,
      firstname,
      lastname,
      phone,
      email,
      title,
    } = referralManager || {}
    if (!isMounted) {
      return null
    }

    return (
      showComponent && (
      <>
        <React.Fragment>
          {/* <FoldedView
            style={{ backgroundImage: `url(${MariImage})` }}
          /> */}
          <StyledAnchor
            isOpen={isOpen}
            isISA={type === "isa" || type === "concierge"}
            onClick={this.handleClick}
          >
            <div>
              <CircularImage
                image={MariImage}
                size={52}
                name="Mari Kawasaki "
                alt="rar support"
                style={{
                  transitionDuration: "0.3s",
                  backgroundPosition: "center",
                }}
              />
            </div>
            <TextWrap>
              <h2>Want more info?</h2>
              <p>We&apos;ll answer any question you may have.</p>
            </TextWrap>
            <div>
              <button type="button">{ isRARPopupOpen ? "Close Form" : "Contact Mari"}</button>
            </div>
          </StyledAnchor>
          <RealtyForm
            formError={formError}
            currentCity={currentCity}
            user={user}
            onSubmit={this.handleFormSubmit}
            setCurrentLocationForRealty={setCurrentLocationForRealty}
            isOpenForm={isRARPopupOpen}
            isPlugIn={isPlugIn}
            scheduleACallResponse={scheduleACallResponse}
          />
        </React.Fragment>
      </>
      )
    )
  }
}

export default RARPopupContainer(ReferralManagerContactV3)
