import { createSelector } from "reselect"
import * as AppSelector from "container/App/selector"

export const getShowSubscriptionCancelModalFromStore = state => state.settings.showSubscriptionCancelModal

export const getIsFetchingNotificationSettingsFromStore = state => state.settings.isFetchingNotificationSettings

export const getNotificationSettingsFromStore = state => state.settings.notificationSettings

export const getNotificationSettingsByIdFromStore = state => state.settings.notificationSettingsById

export const getLeadSourcesFromStore = state => state.settings.leadSources

export const getUniqueEmailFromStore = state => state.settings.uniqueEmail

export const getReferralSettingsFromStore = state => state.settings.referralSettings

export const getIsUpdateReferralSettings = state => state.settings.isUpdateReferralSettings

export const getLeadIntegrationErrorModalFromStore = state => state.settings.leadIntegrationErrorModal

export const getIsChangingPasswordFromStore = state => state.settings.isChangingPassword

export const getUserSettingErrorModalFromStore = state => state.settings.userSettingErrorModal

export const getIsSyncingGmailFromStore = state => state.settings.isSyncingGmail

export const getIsFetchingLeadSourcesFromStore = state => state.settings.isFetchingLeadSources

export const getIsUpdatingDisplayEmailFromStore = state => state.settings.isUpdatingDisplayEmail

export const getIsUpdatingPhoneFromStore = state => state.settings.isUpdatingPhone

export const isFetchingIntroValues = state => state.settings.isFetchingIntroValues

export const isFetchingSMSTemplates = state => state.settings.isFetchingSMSTemplates

export const introValues = state => state.settings.introValues

export const smsTemplates = state => state.settings.smsTemplates

export const isUpdatingSMSTemplate = state => state.settings.isUpdatingSMSTemplate

export const isFetchingSMSTemplateLegends = state => state.settings.isFetchingSMSTemplateLegends

export const smsTemplateLegends = state => state.settings.smsTemplateLegends

export const getRadiusPhoneNumber = state => state.settings.radiusPhoneNumberResponse

export const getSavingMultiPhoneAdminResponse = state => state.settings.savingMultiPhoneAdminResponse

export const getSavedCard = state => state.settings.saveCardResponse

export const fetchNotificationsTypesSelectors = state =>state.settings.fetchNotificationTypesResponse

export const getUserEmailsSelector = createSelector([
  AppSelector.getUserObjectFromStore
], (userObject) => {
  const {
    user_emails,
  } = userObject
  return user_emails
})

const rectifyNumbers = (phoneNumber) => {
  let phEdited
  if (phoneNumber) {
    phEdited = ((phoneNumber.replace(/[^\w\s]/gi, "")).split(" ")).join("")
    if (phEdited && phEdited.length > 10) {
      return (phEdited).substr(1, phEdited.length)
    }
  }
  return phEdited
}

export const getUserPhoneNumbersSelector = createSelector([
  AppSelector.getUserObjectFromStore,
], (userObject) => {
  const {
    phone,
    phoneNumbers,
  } = userObject
  const commonPhoneArray = []
  commonPhoneArray.push({
    areaCode: "",
    number: phone && rectifyNumbers(phone),
    ext: "",
    description: "settings",
    isPrimary: true,
  })
  if (phoneNumbers) {
    phoneNumbers.forEach((key) => {
      key.phone = rectifyNumbers(key.phone)
      commonPhoneArray.push(key)
    })
  }
  return commonPhoneArray
})

export const getCategorizedNetworkSettings = createSelector(
  [getNotificationSettingsFromStore],
  (notificationSettings) => {
    const notificationCategoryMap = {}
    const notificationCategoryOrder = []
    notificationSettings.forEach((obj) => {
      obj.settings.forEach((notificationObject) => {
        if (notificationCategoryMap[notificationObject.category]) {
          notificationCategoryMap[notificationObject.category].push(notificationObject.type_id)
        } else {
          notificationCategoryMap[notificationObject.category] = [notificationObject.type_id]
          notificationCategoryOrder.push(notificationObject.category)
        }
      })
    })
    return {
      notificationCategoryMap,
      notificationCategoryOrder,
    }
  }
)
