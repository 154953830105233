import React, { useState } from "react"
import styled from "styled-components"
import colors from "@colors"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import { Radio, Space, Divider, Checkbox, Button, Select } from "@ui/antd"
import CRMV2Container from "container/CRM"
import CookiesStorage from "services/CookieStorage"
import { RadioButton } from "../common"
import { ATC } from "@fonts"

const { Group } = Radio

const Wrap = styled.div`
  padding: 20px;
  position: relative;
`

const TypeWrap = styled.div`
  display: flex;
  margin-bottom: 24px;

  border: 1px solid #D3DAE8;
  border-radius: 11px;
  overflow: hidden;
`

const SelectorButton = styled.button`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 24px;

  flex-grow: 1;
  height: 35px;

  border: none;
  cursor: pointer;

  /* Brand Colors/Radius Agent/Primary/Sapphire */

  background-color: #fff;
  color: #5A5FF2;

  transition: background-color 0.2s ease-in;


  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;

  ${props => props.selected && `
    background-color: #5A5FF2;
    color: white;
  `}
`

const FiltersWrap = styled.div``

const TitleWrap = styled.div`

  p {
    font-family: ${ATC};
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

  }
`

const GroupWrap = styled.div`
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`
const RedDot = styled.div`
  height: 6px;
  width: 6px;
  background: ${colors.new_primary};
  display: block;
  position: absolute;
  z-index: 100;
  border-radius: 100px;
  right: 10px;
  top: 7px;


  ${props => props.selected && `
    background: white;
  `}
`

const CTAWrap = styled.div`
  // position: absolute;
  right: 0;
  bottom: 0;
  padding-right: 10px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`

/**
 * @param {object} param
 * @returns React Component
 * Filters to be used
 * clientTransactionFilters - Status
 * leadTypes - Type
 */

const LeadFilters = ({
  currentTopFilter,
  setTopFilter,
  getClientFiltersResponse,
  currentFilters,
  updateFilterQueries,
  getClientTransactions,
  toggleFilterDrawer,
  getTeamDetailsResponse,
}) => {
  const [value, setValue] = useState("")
  const thisAgentHasATeam = parseInt(CookiesStorage.load("brokerageTeamId"), 10) > 0
  const onChange = (checkedValues, key) => {
    const updated = {
      ...currentFilters,
      [key]: checkedValues,
      // view_type: "view_mine",
    }

    updateFilterQueries({
      filters: updated,
    })
  }

  const teamMemberSelection = (e, key) => {
    const getLabel = (type) => {
      const isAgentId = type != "view_mine"
      && type !== "view_all"
      && type !== "view_all_team_members"
      let temp = type
      let agentId = null

      if(isAgentId) {
        type = "id"
        agentId = parseInt(temp, 10)
      }
      
      switch(type) {
        case "view_mine":
          return "My leads"
        case "view_all":
          return "All leads"
        case "view_all_team_members":
          return "All associate's leads"
        case "id": 
          const label = getFiltersOptions()
            .filter((item) => item.value === agentId)[0].label
          return label
        default:
          return "My leads"
      }
    }

    const selectedValue = e.target.value
    const updated = currentFilters
    updated[key] = selectedValue
    updated.view_type_label = getLabel(selectedValue)
    updated.view_type = selectedValue !== "view_all" 
    && selectedValue !== "view_mine" 
    && selectedValue !== "view_all_team_members" 
    ? "view_specific_team_members" : selectedValue

    updateFilterQueries({
      filters: updated,
    })
    setValue(selectedValue)
  }

  const sortOnChange = (e, key) => {
    const selectedValue = e.target.value
    const updated = currentFilters
    updated[key] = selectedValue

    if(selectedValue === "view_all")
    updateFilterQueries({
      filters: updated,
    })
    setValue(selectedValue)
  }

  const isFavouriteOnChange = (checkedValues, key) => {
    const updated = {
      ...currentFilters,
      [key]: checkedValues,
    }
    updateFilterQueries({
      filters: updated,
    })
  }

  const handleSubmit = () => {
    getClientTransactions({
      currentFilters,
    })
    toggleFilterDrawer(false)
  }

  const handleClear = () => {
    const cleared = {
      types: [],
      sortBy: null,
      statuses: [],
      isFavourite: null,
      team_member_id: null,
      view_type_label: "My leads",
      view_type: "view_mine",
    }
    setValue("")
    updateFilterQueries({
      filters: cleared,
    })
    getClientTransactions()
    toggleFilterDrawer(false)
  }

  const getFiltersOptions = () => {
    const {
      data: teamDetails,
    } = getTeamDetailsResponse || {}
    const user = CookiesStorage.load("user") || {}
    const teamNames = []

    teamNames.push({
      value: "view_mine",
      label: "View my clients",
    })

    teamNames.push({
      value: "view_all",
      label: "View all clients",
    })

    teamNames.push({
      value: "view_all_team_members",
      label: "View associate's clients",
    })

    if (teamDetails && teamDetails.team_members) {
      teamDetails.team_members.filter(td => td.agent_id !== user.id).forEach((tm) => {
        console.log(tm, "here")
        teamNames.push({
          key: `${tm.agent_id}::${tm.firstname}`,
          label: `${tm.firstname} ${tm.lastname} clients`,
          value: tm.agent_id,
        })
      })
    }
    return teamNames || []
  }

  const getOptions = options => options && options.map(item => ({ value: item.value, label: item.display_name || item.value }))
  const {
    data: filterSkeleton,
  } = getClientFiltersResponse || {}

  return (
    <Wrap>
      <TypeWrap>
        <SelectorButton
          selected={currentTopFilter === "status"}
          onClick={() => {
            setTopFilter({
              type: "status",
            })
          }}
        >
          Status
          <>
            {currentFilters.statuses.length > 0 && (
              <RedDot
                selected={currentTopFilter === "status"}
              />
            )}
          </>
        </SelectorButton>
        <SelectorButton
          selected={currentTopFilter === "type"}
          onClick={() => {
            setTopFilter({
              type: "type",
            })
          }}
        >
          Type
          <>
            {currentFilters.types.length > 0 && (
              <RedDot
                selected={currentTopFilter === "type"}
              />
            )}
          </>
        </SelectorButton>
        <SelectorButton
          selected={currentTopFilter === "sort"}
          onClick={() => {
            setTopFilter({
              type: "sort",
            })
          }}
        >
          Sort
          <>
            {currentFilters.sortBy && (
              <RedDot
                selected={currentTopFilter === "sort"}
              />
            )}
          </>
        </SelectorButton>
        {thisAgentHasATeam && (
          <SelectorButton
            selected={currentTopFilter === "team"}
            onClick={() => {
              setTopFilter({
                type: "team",
              })
            }}
          >
            Team
            <>
              {currentFilters.team_member_id && (
                <RedDot
                  selected={currentTopFilter === "team"}
                />
              )}
            </>
          </SelectorButton>
        )}
      </TypeWrap>
      {currentTopFilter === "status" && (
        <FiltersWrap>
          <TitleWrap>
            <Divider orientation="left">Category</Divider>
          </TitleWrap>
          <GroupWrap>
            <Checkbox.Group value={currentFilters.statuses} options={getOptions(filterSkeleton.clientTransactionFilters)} onChange={e => onChange(e, "statuses")} />
          </GroupWrap>
        </FiltersWrap>
      )}
      {currentTopFilter === "type" && (
        <FiltersWrap>
          <TitleWrap>
            <Divider orientation="left">Category</Divider>
          </TitleWrap>
          <GroupWrap>
            <Checkbox.Group value={currentFilters.types} options={getOptions(filterSkeleton.leadTypes)} onChange={e => onChange(e, "types")} />
          </GroupWrap>
        </FiltersWrap>
      )}
      {currentTopFilter === "team" && (
        <FiltersWrap>
          <TitleWrap>
            <Divider orientation="left">Members</Divider>
          </TitleWrap>
          {/* <GroupWrap>
            <Checkbox.Group value={currentFilters.team_member_id} options={getFiltersOptions()} onChange={e => onChange(e, "team_member_id")} />
          </GroupWrap> */}
          <Group onChange={e => teamMemberSelection(e, "team_member_id")} value={currentFilters.team_member_id}>
            <Space direction="vertical">
              {/* <RadioButton value="Favorites">Favorites</RadioButton> */}
              {getFiltersOptions().map(item => (
                <RadioButton value={item.value}>{item.label}</RadioButton>
              ))}
            </Space>
          </Group>
        </FiltersWrap>
      )}
      {currentTopFilter === "sort" && (
        <FiltersWrap>
          <TitleWrap>
            <Divider orientation="left">Sort by</Divider>
          </TitleWrap>
          <Group onChange={e => sortOnChange(e, "sortBy")} value={currentFilters.sortBy}>
            <Space direction="vertical">
              {/* <RadioButton value="Favorites">Favorites</RadioButton> */}
              {filterSkeleton && filterSkeleton.clientTransactionSort.map(item => (
                <RadioButton value={item.value}>{item.value}</RadioButton>
              ))}
            </Space>
          </Group>
        </FiltersWrap>
      )}
      {/*Commented until we enable Favourites*/}
      {/* <FiltersWrap>
        <TitleWrap>
          <Divider orientation="left">Favourites</Divider>
        </TitleWrap>
        <GroupWrap>
          <Checkbox.Group value={currentFilters.isFavourite} options={[{ value: true, label: "Favourite" }]} onChange={e => isFavouriteOnChange(e, "isFavourite")} />
        </GroupWrap>
      </FiltersWrap> */}
    
      <CTAWrap>
        <Button
          type="danger"
          onClick={handleClear}
        >
          Clear Filters
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit}
        >
          Apply Filters
        </Button>
      </CTAWrap>
    </Wrap>
  )
}

export default TeamProfileContainer(CRMV2Container(LeadFilters))
