import React from "react"
import styled from "styled-components"
import colors from "@colors"
import { ATC } from "@fonts"
import Button from "@ui/Button"
import PublicRoomIcon from "images/rooms/icons/public-room.svg"
import PrivateRoomIcon from "images/rooms/icons/private-room.svg"
import {
  FormTitle,
} from "../common"

const PrivacyButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  gap: 25px;
  align-items: flex-start;
`

const PublicButton = styled(Button)`
  display: flex;
  border: none;
  background: ${colors.white};
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  width: 223px;
  height: 81px;
  justify-content: space-between;
  padding: 0px;

  ${props => props.isActive && `
    border: 2px solid ${colors.new_primary};
    background: ${colors.whiteBlueFaded};
  `}

  > div {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 18px;
    
    > p {
      flex: 1;
      padding: 0px;
      margin: 0px;
      font-size: 20px;
      font-family: ${ATC};
      font-weight: 300;
      line-height: 24px;
      color: ${colors.black};
    }
  
    > small {
      font-size: 13px;
      font-family: ${ATC};
      font-weight: 300;
      line-height: 24px;
      color: ${colors.suvaGray};
    }
  
  }
`

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const PrivacyUI = ({
  change,
  is_public,
}) => (
  <Wrap>
    <FormTitle>
      <p>Privacy</p>
    </FormTitle>
    <PrivacyButtons>
      <PublicButton onClick={() => { change("is_public", 1) }} type="button" isActive={is_public === 1}>
        <div>
          <p>
            Public
          </p>
          <small>
            Open to everyone
          </small>
        </div>
        <img src={PublicRoomIcon} alt="Public Room Radius Agent"></img>
      </PublicButton>
      <PublicButton onClick={() => { change("is_public", 0) }} type="button" isActive={is_public === 0}>
        <div>
          <p>
            Network Only
          </p>
          <small>
            People I follow can join
          </small>
        </div>
        <img src={PrivateRoomIcon} alt="Public Room Radius Agent"></img>
      </PublicButton>
    </PrivacyButtons>
  </Wrap>
)

export default PrivacyUI

