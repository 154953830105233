import React, { useState } from "react"
import styled from "styled-components"
import colors from "@colors"
import CRMV2Container from "container/CRM"
import FilterIcon from "images/Filter.svg"
import PlusIcon from "images/icons/WhiteIconPlus.png"
import Button from "@ui/Button"
import { RightOutlined } from "@ant-design/icons";
import { CustomHeaderDrawer } from "dumbComponents/CRMV2/CRMDashboard/Components/Drawers"

const Wrap = styled.div``

const SearchWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
  padding: 0 0 30px 0;
  input {
    width: 325px;
    height: 42px;
    border: 1px solid ${colors.moonGrey};
    border-radius: 100px;
    padding-left: 23px;
  }
`

const RedDot = styled.div`
  height: 6px;
  width: 6px;
  background: ${colors.new_primary};
  display: block;
  position: absolute;
  z-index: 100;
  border-radius: 100px;
  right: 10px;
  top: 7px;


  ${props => props.selected && `
    background: white;
  `}
`

const Image = styled.img`
  position: absolute;
  left: ${props => props.left};
  top: ${props => props.top};
`

const CTAButton = styled(Button)`
  height: 33px;
  width: 155px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;
  border-width: 1px;
  position: relative;
    // color: #fff;
  span img {
    height: 12px;
    position: relative;
    left: 8px;
    top: 1px;
  }
`

const ClickableText = styled.p `
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Global-Mapping-Gray-700, #373758);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  letter-spacing: 0.5px;
  cursor: pointer;
`

const SearchHeader = ({
  toggleFilterDrawer,
  isFilterClientLeadsDrawerOpen,
  toggleAddNewLeadDrawer,
  isAddNewLeadDrawerOpen,
  setCurrentDrawerPage,
  currentFilters,
  getClientTransactions,
  crmClietClear,
}) => {
  const [searchText, setSearchText] = useState("")

  const isFilterActive = () => currentFilters
    && (currentFilters.statuses.length > 0
    || currentFilters.types.length > 0
    || currentFilters.sortBy
    || currentFilters.isFavourites
    || currentFilters.team_member_id
    )

  return (
    <Wrap>
      <SearchWrap>
        {/* <Image src={SearchIcon} alt="Search Bar" top="43px" left="5px" />
        <input type="text" placeholder="Search Leads" value={searchText} />
        <Image
          src={FilterIcon}
          alt="Filter"
          onClick={() => { toggleFilterDrawer(true) }}
          style={{ cursor: "pointer" }}
          top="40px"
          left="288px"
        /> */}
        <ClickableText onClick={() => {
            window.location.href = "/archivedleads"
          }}
        >
          View archived leads <RightOutlined />
        </ClickableText>
        {/* <CTAButton
          className="add_client"
          bsStyle="marineBLueFill"
          style={{color: '#fff'}}
          onClick={() => {
            setCurrentDrawerPage("add-lead")
            toggleAddNewLeadDrawer(true)
            crmClietClear()
          }}
        >
          Add Client
          <span>
            <img src={PlusIcon} alt="refer and agent" />
          </span>
        </CTAButton> */}
        {/* <CTAButton
          onClick={() => {
            toggleFilterDrawer(true)
            setCurrentDrawerPage("lead-filters")
          }}
          bsStyle="marineBLueOutlined"
          style={{
            padding: "0",
          }}
        >
          Filters
          <>
            {isFilterActive() && (
              <RedDot />
            )}
          </>
        </CTAButton> */}
      </SearchWrap>
      <CustomHeaderDrawer
        handleClose={() => {
          if (isFilterActive()) {
            getClientTransactions({
              currentFilters,
            })
          }
          toggleFilterDrawer(false)
        }}
        open={isFilterClientLeadsDrawerOpen}
        title="Filter Clients"
        titleFontSize="28px"
      />
      <CustomHeaderDrawer
        handleClose={() => toggleAddNewLeadDrawer(false)}
        open={isAddNewLeadDrawerOpen}
        hideDrawerTitle
        width="446px"
      />
    </Wrap>
  )
}

export default CRMV2Container(SearchHeader)
