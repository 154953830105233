import * as SettingsActions from "container/Settings/actions"
import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"

const initState = {
  toggleAppointmentSchedulingData: {
    isAppointmentSchedulingEnabled: false,
  },
  isLeadIntegrationModalOpen: false,
  isFetchingLeadSources: false,
  isUpdateReferralSettings: false,
  isUpdatingLeadIntegrationSource: false,
  isGettingUniqueEmail: false,
  isSyncingGmail: false,
  dontShowListView: false,
  isFetchingIntroValues: false,
  isFetchingSMSTemplates: false,
  smsTemplates: [],
  isUpdatingSMSTemplate: false,
  smsTemplateLegends: [],
  isFetchingSMSTemplateLegends: false,
  isUnsubscribeFromEmail: {
    request: false,
    success: false,
    error: false,
  },
  showSubscriptionCancelModal: false,
  isFetchingNotificationSettings: false,
  notificationSettings: [],
  notificationSettingsById: {},
  leadSources: [],
  referralSettings: {},
  uniqueEmail: null,
  leadIntegrationErrorModal: {
    show: false,
    errorCode: null,
  },
  isChangingPassword: false,
  userSettingErrorModal: {
    show: false,
    message: "",
  },
  introValues: [],
  currentLeadType: [{ value: "Old online leads (Seller)", label: "Old online leads (Seller)" }],
  isUpdatingDisplayEmail: false,
  currentAgentType: "",
  isSmsEditModalVisible: false,
  currentTemplate: null,
  showPremiumBlockerPrompt: false,
  isEditAssistPreference: false,
  currentEditedTemplate: "",
  ...createDeltaReducer("smsIntroValue"),
  ...createDeltaReducer("smsTemplates"),
  ...createDeltaReducer("updateSMStemplate"),
  ...createDeltaReducer("smsTemplatesLegends"),
  ...createDeltaReducer("savingMultiPhoneAdminResponse"),
  ...createDeltaReducer("promoInvited"),
  ...createDeltaReducer("inviteNewAgent"),
  ...createDeltaReducer("promoReinviteAgent"),
  ...createDeltaReducer("toggleAppointmentSchedulingData"),
  ...createDeltaReducer("currentAppointmentSchedulingStatus"),
  ...createDeltaReducer("submitCalendlyLinkResponse"),
  ...createDeltaReducer("triggerEmailResponse"),
  ...createDeltaReducer("radiusPhoneNumberResponse"),
  ...createDeltaReducer("assistNotesResponse"),
  ...createDeltaReducer("assistPreferenceResponse"),
  ...createDeltaReducer("apiGenerationResponse"),
  ...createDeltaReducer("apiReGenerationResponse"),
  ...createDeltaReducer("savedAssistPreferenceResponse"),
  ...createDeltaReducer("savedAssistNotesResponse"),
  ...createDeltaReducer("saveCardResponse"),
  ...createDeltaReducer(SettingsActions.verifyAuthTokenForStripeAction.RESPONSE),
  ...createDeltaReducer(SettingsActions.saveStripeTokenAction.RESPONSE),
  ...createDeltaReducer(SettingsActions.sendAddCreditCardLinkV2Action.RESPONSE),
  ...createDeltaReducer(SettingsActions.saveStripeSubscriptionAction.RESPONSE),
  ...createDeltaReducer(SettingsActions.getClientSecretAction.RESPONSE),
  ...createDeltaReducer("fetchFinancingDocumentsResponse"),
  ...createDeltaReducer("fetchNotificationsTypeResponse"),
  ...createDeltaReducer("togglePushNotificationsResponse"),
  ...createDeltaReducer("fetchEarningsResponse"),
}

export default function settingsReducer(state = initState, action) {
  switch (action.type) {
    case SettingsActions.fetchAllLeadIntegrationSourcesAction.REQUEST: {
      return {
        ...state,
        isFetchingLeadSources: true,
      }
    }
    case SettingsActions.fetchAllLeadIntegrationSourcesAction.SUCCESS: {
      const { leadSources, referral_settings } = action.data
      return {
        ...state,
        referralSettings: referral_settings,
        isFetchingLeadSources: false,
        leadSources,
      }
    }
    case SettingsActions.fetchAllLeadIntegrationSourcesAction.FAILURE: {
      return {
        ...state,
        isFetchingLeadSources: false,
      }
    }

    case SettingsActions.updateReferralSettingsAction.REQUEST: {
      const { loaderId } = action.data
      return {
        ...state,
        isUpdateReferralSettings: loaderId,
      }
    }

    case SettingsActions.updateReferralSettingsAction.SUCCESS: {
      return {
        ...state,
        referralSettings: action.data || {},
        isUpdateReferralSettings: false,
      }
    }

    case SettingsActions.updateReferralSettingsAction.FAILURE: {
      return {
        ...state,
        isUpdateReferralSettings: false,
      }
    }

    case SettingsActions.getUniqueEmailAction.REQUEST: {
      return {
        ...state,
        isGettingUniqueEmail: true,
      }
    }

    case SettingsActions.getUniqueEmailAction.SUCCESS:
    case SettingsActions.getUniqueEmailAction.FAILURE: {
      const { uniqueEmail } = action.data
      return {
        ...state,
        isGettingUniqueEmail: false,
        uniqueEmail,
      }
    }

    case SettingsActions.updateLeadIntegrationSourceAction.REQUEST: {
      return {
        ...state,
        isUpdatingLeadIntegrationSource: true,
      }
    }

    case SettingsActions.updateLeadIntegrationSourceAction.SUCCESS:
    case SettingsActions.updateLeadIntegrationSourceAction.FAILURE: {
      return {
        ...state,
        isUpdatingLeadIntegrationSource: false,
      }
    }

    case SettingsActions.syncGmailAction.REQUEST:
    case SettingsActions.deactivateGmail.REQUEST: {
      return {
        ...state,
        isSyncingGmail: true,
      }
    }


    case SettingsActions.syncGmailAction.SUCCESS:
    case SettingsActions.deactivateGmail.SUCCESS:
    case SettingsActions.syncGmailAction.FAILURE:
    case SettingsActions.deactivateGmail.FAILURE: {
      return {
        ...state,
        isSyncingGmail: false,
      }
    }

    case SettingsActions.unsubscribeFromEmailAction.REQUEST: {
      return {
        ...state,
        isUnsubscribeFromEmail: {
          ...state.isUnsubscribeFromEmail,
          request: true,
          success: false,
          error: false,
        },
      }
    }

    case SettingsActions.unsubscribeFromEmailAction.SUCCESS: {
      return {
        ...state,
        isUnsubscribeFromEmail: {
          ...state.isUnsubscribeFromEmail,
          request: false,
          success: true,
          error: false,
        },
      }
    }

    case SettingsActions.unsubscribeFromEmailAction.FAILURE: {
      const { message } = action.data
      return {
        ...state,
        isUnsubscribeFromEmail: {
          ...state.isUnsubscribeFromEmail,
          request: false,
          success: false,
          error: message,
        },
      }
    }

    case SettingsActions.OPEN_LEAD_INTEGRATION_MODAL: {
      return {
        ...state,
        isLeadIntegrationModalOpen: true,
      }
    }

    case SettingsActions.CLOSE_LEAD_INTEGRATION_MODAL: {
      return {
        ...state,
        isLeadIntegrationModalOpen: false,
      }
    }

    case SettingsActions.SHOW_LIST_VIEW: {
      return {
        ...state,
        dontShowListView: false,
      }
    }

    case SettingsActions.HIDE_LIST_VIEW: {
      const { type } = action.data
      return {
        ...state,
        dontShowListView: type,
      }
    }

    case SettingsActions.toggleSubscriptionCancelModal.type: {
      const showSubscriptionCancelModal = action.data
      return {
        ...state,
        showSubscriptionCancelModal,
      }
    }

    case SettingsActions.getNotificationSettings.REQUEST: {
      return {
        ...state,
        isFetchingNotificationSettings: true,
      }
    }

    case SettingsActions.getNotificationSettings.SUCCESS: {
      const { notificationSettings, notificationSettingsById } = action.data
      return {
        ...state,
        isFetchingNotificationSettings: false,
        notificationSettings,
        notificationSettingsById,
      }
    }

    case SettingsActions.getNotificationSettings.FAILURE: {
      return {
        ...state,
        isFetchingNotificationSettings: false,
      }
    }

    case SettingsActions.toggleNotificationSettingDisplay.type: {
      const { notificationId, showSetting } = action.data
      return {
        ...state,
        notificationSettingsById: {
          ...state.notificationSettingsById,
          [notificationId]: {
            ...state.notificationSettingsById[notificationId],
            showSetting,
          },
        },
      }
    }

    case SettingsActions.requestToggleNotificationSetting.success: {
      const { notificationId, enabledPush, enabledEmail } = action.data
      return {
        ...state,
        notificationSettingsById: {
          ...state.notificationSettingsById,
          [notificationId]: {
            ...state.notificationSettingsById[notificationId],
            enabledPush,
            enabledEmail,
          },
        },
      }
    }

    case SettingsActions.toggleNotificationSetting.type: {
      const {
        notificationId,
        key,
        value,
        isFrequency,
        index
      } = action.data
      if (isFrequency) {
        return {
          ...state,
          notificationSettingsById: {
            ...state.notificationSettingsById,
            [notificationId]: {
              ...state.notificationSettingsById[notificationId],
              push_frequency: {
                ...state.notificationSettingsById[notificationId].push_frequency,
                [index]: {
                  ...state.notificationSettingsById[notificationId].push_frequency[index],
                  enabled: value
                }
              },
            },
          },
        }
      }
      return {
        ...state,
        notificationSettingsById: {
          ...state.notificationSettingsById,
          [notificationId]: {
            ...state.notificationSettingsById[notificationId],
            [key]: value,
          },
        },
      }
    }

    case SettingsActions.toggleIntegrationDisplay.type: {
      const { integrationId, showSetting } = action.data
      const leadSources = state.leadSources.map((sourceData, idx) => {
        if (idx === integrationId) {
          return {
            ...sourceData,
            showSetting,
          }
        }
        return sourceData
      })
      return {
        ...state,
        leadSources,
      }
    }

    case SettingsActions.toggleLeadIntegrationErrorModal.type: {
      const { show, errorCode } = action.data
      return {
        ...state,
        leadIntegrationErrorModal: {
          ...state.leadIntegrationErrorModal,
          show,
          errorCode,
        },
      }
    }

    case SettingsActions.requestChangePassword.REQUEST: {
      return {
        ...state,
        isChangingPassword: true,
      }
    }

    case SettingsActions.requestChangePassword.SUCCESS: {
      return {
        ...state,
        isChangingPassword: false,
      }
    }

    case SettingsActions.requestChangePassword.FAILURE: {
      return {
        ...state,
        isChangingPassword: false,
      }
    }

    case SettingsActions.toggleUserSettingsErrorModal.type: {
      const { show, message, isPhoneError } = action.data
      return {
        ...state,
        userSettingErrorModal: {
          ...state.userSettingErrorModal,
          show,
          message,
          isPhoneError,
        },
      }
    }

    case SettingsActions.requestUpdateDisplayEmail.REQUEST: {
      return {
        ...state,
        isUpdatingDisplayEmail: true,
      }
    }

    case SettingsActions.fetchIntroValuesAction.REQUEST:
    case SettingsActions.fetchIntroValuesAction.FAILURE:
    case SettingsActions.fetchIntroValuesAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, SettingsActions.fetchIntroValuesAction, "smsIntroValue")
      }
    }

    case SettingsActions.fetchSMSTemplatesAction.REQUEST:
    case SettingsActions.fetchSMSTemplatesAction.FAILURE:
    case SettingsActions.fetchSMSTemplatesAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, SettingsActions.fetchSMSTemplatesAction, "smsTemplates"),
      }
    }

    case SettingsActions.saveMultipleEmailsAndPhoneAction.REQUEST:
    case SettingsActions.saveMultipleEmailsAndPhoneAction.FAILURE:
    case SettingsActions.saveMultipleEmailsAndPhoneAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, SettingsActions.saveMultipleEmailsAndPhoneAction, "savingMultiPhoneAdminResponse"),
      }
    }

    case SettingsActions.requestUpdateDisplayEmail.SUCCESS:
    case SettingsActions.requestUpdateDisplayEmail.FAILURE: {
      return {
        ...state,
        isUpdatingDisplayEmail: false,
      }
    }

    case SettingsActions.updateSMSTemplateAction.REQUEST:
    case SettingsActions.updateSMSTemplateAction.FAILURE:
    case SettingsActions.updateSMSTemplateAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, SettingsActions.updateSMSTemplateAction, "updateSMStemplate"),
      }
    }

    case SettingsActions.getSMSTemplateLegendsAction.REQUEST:
    case SettingsActions.getSMSTemplateLegendsAction.FAILURE:
    case SettingsActions.getSMSTemplateLegendsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, SettingsActions.getSMSTemplateLegendsAction, "smsTemplatesLegends"),
      }
    }

    case SettingsActions.fetchSubscriptionInviteAction.REQUEST:
    case SettingsActions.fetchSubscriptionInviteAction.FAILURE:
    case SettingsActions.fetchSubscriptionInviteAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, SettingsActions.fetchSubscriptionInviteAction, "promoInvited"),
      }
    }

    case SettingsActions.getCardDetailsAction.REQUEST:
    case SettingsActions.getCardDetailsAction.FAILURE:
    case SettingsActions.getCardDetailsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, SettingsActions.getCardDetailsAction, "saveCardResponse"),
      }
    }

    case SettingsActions.getBrokerAddonsAction.REQUEST:
    case SettingsActions.getBrokerAddonsAction.FAILURE:
    case SettingsActions.getBrokerAddonsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, SettingsActions.getBrokerAddonsAction, "brokerAddonsResponse"),
      }
    }

    case SettingsActions.fetchAssistNotesAction.REQUEST:
    case SettingsActions.fetchAssistNotesAction.FAILURE:
    case SettingsActions.fetchAssistNotesAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, SettingsActions.fetchAssistNotesAction, "assistNotesResponse"),
      }
    }

    case SettingsActions.fetchAssistPreferenceAction.REQUEST:
    case SettingsActions.fetchAssistPreferenceAction.FAILURE:
    case SettingsActions.fetchAssistPreferenceAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, SettingsActions.fetchAssistPreferenceAction, "assistPreferenceResponse"),
      }
    }

    case SettingsActions.resendNewSubscriptionPromoInviteAction.REQUEST:
    case SettingsActions.resendNewSubscriptionPromoInviteAction.FAILURE:
    case SettingsActions.resendNewSubscriptionPromoInviteAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, SettingsActions.resendNewSubscriptionPromoInviteAction, "promoReinviteAgent"),
      }
    }

    case SettingsActions.sendNewSubscriptionPromoInviteAction.REQUEST:
    case SettingsActions.sendNewSubscriptionPromoInviteAction.FAILURE:
    case SettingsActions.sendNewSubscriptionPromoInviteAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, SettingsActions.sendNewSubscriptionPromoInviteAction, "inviteNewAgent"),
      }
    }

    case SettingsActions.saveAssistPreferenceAction.REQUEST:
    case SettingsActions.saveAssistPreferenceAction.FAILURE:
    case SettingsActions.saveAssistPreferenceAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, SettingsActions.saveAssistPreferenceAction, "savedAssistPreferenceResponse"),
      }
    }

    case SettingsActions.saveAssistNotesActions.REQUEST:
    case SettingsActions.saveAssistNotesActions.FAILURE:
    case SettingsActions.saveAssistNotesActions.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, SettingsActions.saveAssistNotesActions, "savedAssistNotesResponse"),
      }
    }

    case SettingsActions.fetchRadiusPhoneNumberAction.REQUEST:
    case SettingsActions.fetchRadiusPhoneNumberAction.FAILURE:
    case SettingsActions.fetchRadiusPhoneNumberAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          SettingsActions.fetchRadiusPhoneNumberAction,
          "radiusPhoneNumberResponse"
        ),
      }
    }

    case SettingsActions.toggleAppointmentSchedulingAction.REQUEST:
    case SettingsActions.toggleAppointmentSchedulingAction.FAILURE:
    case SettingsActions.toggleAppointmentSchedulingAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          SettingsActions.toggleAppointmentSchedulingAction,
          "toggleAppointmentSchedulingData"
        ),
      }
    }

    //currentAppointmentSchedulingStatus
    case SettingsActions.getCurrentAppointmentSchedulingAction.REQUEST:
    case SettingsActions.getCurrentAppointmentSchedulingAction.FAILURE:
    case SettingsActions.getCurrentAppointmentSchedulingAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          SettingsActions.getCurrentAppointmentSchedulingAction,
          "currentAppointmentSchedulingStatus"
        ),
      }
    }

    //submitCalendlyLinkResponse
    case SettingsActions.submitCalendlyLinkAction.REQUEST:
    case SettingsActions.submitCalendlyLinkAction.FAILURE:
    case SettingsActions.submitCalendlyLinkAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          SettingsActions.submitCalendlyLinkAction,
          "submitCalendlyLinkResponse"
        ),
      }
    }

    case SettingsActions.triggerEmailRequestAction.REQUEST:
    case SettingsActions.triggerEmailRequestAction.FAILURE:
    case SettingsActions.triggerEmailRequestAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          SettingsActions.triggerEmailRequestAction,
          "triggerEmailResponse"
        ),
      }
    }

    case SettingsActions.generateAPITokenAction.REQUEST:
    case SettingsActions.generateAPITokenAction.FAILURE:
    case SettingsActions.generateAPITokenAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          SettingsActions.generateAPITokenAction,
          "apiGenerationResponse",
        ),
      }
    }

    case SettingsActions.reGenerateAPITokenAction.REQUEST:
    case SettingsActions.reGenerateAPITokenAction.FAILURE:
    case SettingsActions.reGenerateAPITokenAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          SettingsActions.reGenerateAPITokenAction,
          "apiReGenerationResponse",
        ),
      }
    }

    case SettingsActions.setCurrentAgentTypeAction.type: {
      const { data } = action
      return {
        ...state,
        currentAgentType: data,
      }
    }

    case SettingsActions.setLeadTypeAction.type: {
      const { data } = action
      return {
        ...state,
        currentLeadType: data,
      }
    }

    case SettingsActions.setCurrentLeadIdAction.type: {
      const { data } = action
      return {
        ...state,
        currentLeadID: data,
      }
    }

    case SettingsActions.handleToggleForSMSModalAction.type: {
      const {
        isSmsEditModalVisible,
        currentTemplate,
        showPremiumBlockerPrompt,
      } = action.data
      return {
        ...state,
        isSmsEditModalVisible,
        currentTemplate,
        showPremiumBlockerPrompt,
      }
    }

    case SettingsActions.saveToStoreCurrentTemplateAction.type: {
      const {
        currentEditedTemplate,
      } = action.data
      return {
        ...state,
        currentEditedTemplate,
      }
    }

    case SettingsActions.toggleAssistNotesModalAction.type: {
      return {
        ...state,
        ...action.data,
      }
    }

    case SettingsActions.toggleAssistPreferenceEditAction.type: {
      return {
        ...state,
        isEditAssistPreference: action.data,
      }
    }

    case SettingsActions.verifyAuthTokenForStripeAction.REQUEST:
    case SettingsActions.verifyAuthTokenForStripeAction.FAILURE:
    case SettingsActions.verifyAuthTokenForStripeAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          SettingsActions.verifyAuthTokenForStripeAction,
          SettingsActions.verifyAuthTokenForStripeAction.RESPONSE,
        ),
      }
    }

    case SettingsActions.saveStripeTokenAction.REQUEST:
    case SettingsActions.saveStripeTokenAction.FAILURE:
    case SettingsActions.saveStripeTokenAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          SettingsActions.saveStripeTokenAction,
          SettingsActions.saveStripeTokenAction.RESPONSE,
        ),
      }
    }

    case SettingsActions.sendAddCreditCardLinkV2Action.REQUEST:
    case SettingsActions.sendAddCreditCardLinkV2Action.FAILURE:
    case SettingsActions.sendAddCreditCardLinkV2Action.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          SettingsActions.sendAddCreditCardLinkV2Action,
          SettingsActions.sendAddCreditCardLinkV2Action.RESPONSE,
        ),
      }
    }

    case SettingsActions.saveStripeSubscriptionAction.REQUEST:
    case SettingsActions.saveStripeSubscriptionAction.FAILURE:
    case SettingsActions.saveStripeSubscriptionAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          SettingsActions.saveStripeSubscriptionAction,
          SettingsActions.saveStripeSubscriptionAction.RESPONSE,
        ),
      }
    }

    case SettingsActions.fetchFinancingDocumentsAction.REQUEST:
    case SettingsActions.fetchFinancingDocumentsAction.FAILURE:
    case SettingsActions.fetchFinancingDocumentsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, SettingsActions.fetchFinancingDocumentsAction, "fetchFinancingDocumentsResponse"),
      }
    }

    case SettingsActions.fetchEarningsAction.REQUEST:
    case SettingsActions.fetchEarningsAction.FAILURE:
    case SettingsActions.fetchEarningsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, SettingsActions.fetchEarningsAction, "fetchEarningsResponse"),
      }
    }

    case SettingsActions.getClientSecretAction.REQUEST:
    case SettingsActions.getClientSecretAction.FAILURE:
    case SettingsActions.getClientSecretAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          SettingsActions.getClientSecretAction,
          SettingsActions.getClientSecretAction.RESPONSE,
        ),
      }
    }
      
      
    case SettingsActions.fetchNotificationTypesAction.REQUEST:
    case SettingsActions.fetchNotificationTypesAction.FAILURE:
    case SettingsActions.fetchNotificationTypesAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          SettingsActions.fetchNotificationTypesAction,
          "fetchNotificationsTypeResponse"
        ),
      }
    }

          case SettingsActions.togglePushNotificationsAction.REQUEST:
    case SettingsActions.togglePushNotificationsAction.FAILURE:
    case SettingsActions.togglePushNotificationsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          SettingsActions.togglePushNotificationsAction,
          "togglePushNotificationsResponse"
        ),
      }
    }
      
    default:
      return { ...state }
  }
}
