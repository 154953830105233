import React, { PureComponent } from "react"
import styled from "styled-components"
import { ATC } from "@fonts"
import ListingList from "dumbComponents/Listings/components/ListingList"
import TCOfferBanner from "dumbComponents/TransactionCoordinationV2/Navbar/TCOfferBanner"
import colors from "@colors"
import Loader from "@ui/Loader"
import cookie from "services/CookieStorage"
import TechAssetsContainer from "container/TechAssets"
import TCContainer from "container/Realty/TransactionFormContainer"
import PlusIcon from "images/icons/WhiteIconPlus.png"
import {
  Input, InputNumber, Select, Form, Checkbox,
  Divider, Button, Radio, Space, AutoComplete,
} from "@ui/antd"
import { normalizePhoneNumber, normalizePhone } from "dumbComponents/TransactionCoordinationV2/Forms/Common/Utils"
// import "antd/dist/antd.css"
import dynamic from "next/dynamic"
const loadGapi = dynamic(() => import("gapi-script").then(mod => mod.loadGapiInsideDOM), { ssr: false })

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
  .ant-form-item-label {    
    > label {
      color: #303030;
      font-family: ${ATC};
      font-style: normal;
      font-weight: 200;
      font-size: 18px;
      line-height: 22px;
    }
  }
  .ant-radio-checked .ant-radio-inner {
    
  }
`

const CheckboxContainer = styled.div`
  display: flex;
`

const HeaderNav = styled.div`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: ${colors.black};
`

const ProgressBarContainer = styled.div`
  padding: 30px 0px 50px 0;
  display: flex;
`

const ProgressBar = styled.div`
  background: #E5E5E5;
  ${props => (props.completed || props.ongoing) && `
    background: #5A5FF2;
  `}
  height: 6px;
  margin: 0 4px;
  width: 100%;
`

const Container = styled.div`
  display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 100%;
`

const LabelText = styled.h2`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
  color: ${colors.black};
`

const Required = styled.span`
  color: #E34C4C
`

const LocalCheckBox = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid #303030;
  border-radius: 4px;
  margin-right: 12px;
  ${props => props.disabled && `
    border: 1px solid #C7C7C7;
  `}
`

const CheckboxLabelText = styled.h2`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  color: ${colors.black};
  text-transform: uppercase;
  ${props => props.disabled && `
    color: #C7C7C7;
  `}
`

const FlexBox = styled.div`
  display: flex;
  ${props => props.wd && `
    width: ${props.wd};
  `}
`

const CheckboxWrap = styled.div`
  padding: 0 20px 0 10px;
`

const StyledDivider = styled(Divider)`
  min-width: 180px !important;
  width: 180px !important;
  margin: 18px 0 !important;
`

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)

class CoverSheet extends PureComponent {
  state = {
    showEscrowOption: null,
    notTCOption: null,
    showLenderEmail: null,
  }

  formRef = React.createRef();

  componentDidMount() {
    const initializeGapi = async () => {
      try {
        await loadGapi()
        // Initialize gapi client
        window.gapi.load("client:auth2", this.fetchGoogleContacts)
      } catch (e) {
        //
      }
    }
    initializeGapi()
    const { getContractById, dealToView, getListingCoverSheet, setShowTCBannerForm, getTCBanner } = this.props
    console.log("----componentDidMount----CALLED")
    getListingCoverSheet({
      listing_id: dealToView || 0,
      callAPI: ["select_vendor"],
    })
    if (dealToView) {
      getContractById({
        dealId: dealToView || 0,
        // contractOnly: 1,
      })
    }
    // const user = cookie.load("user") || {}
    // const agentId = user.id
    // if (agentId) {
    //   getTCBanner(agentId)
    // }
    // setShowTCBannerForm(true)
  }

  onFinish = (fieldValues, isDraft, byPassUpdate) => {
    const user = cookie.load("user") || {}
    Object.keys(fieldValues).forEach((key) => {
      if (fieldValues[key] === undefined) {
        // eslint-disable-next-line no-param-reassign
        fieldValues[key] = null
      }
    })
    const { updateContract, history, dealToView, getListingCoverSheetResponse, updateDealStatus, getContractByIdResponse } = this.props
    const { showEscrowOption } = this.state
    const state = getContractByIdResponse.data?.[0]?.state
    const enableTC = ["California", "Oregon", "Washington"]
    const selectVendor = getListingCoverSheetResponse.data?.selectVendor
    let nextRoute = isDraft ? "/realty/transaction-coordination" : "/realty/transaction-coordination/contract/upload"
    // if (fieldValues.is_radius_tc_to_open_escrow) {
      // if (enableTC.includes(state)) {
      //   if (selectVendor && selectVendor.length) {
      //     nextRoute = isDraft ? "/realty/transaction-coordination" : "/realty/transaction-coordination/contract/vendors"
      //   } else {
      //     nextRoute = isDraft ? "/realty/transaction-coordination" : "/realty/transaction-coordination/contract/escrow-info"
      //   }
      // } else {
      //   if ((fieldValues.is_using_deal_tc === 0 && showEscrowOption === false) || fieldValues.is_radius_tc_to_open_escrow === 0) {
      //     nextRoute = isDraft ? "/realty/transaction-coordination" : "/realty/transaction-coordination/contract/upload?fromTC=true"
      //   } else {
      //       if (selectVendor && selectVendor.length) {
      //         nextRoute = isDraft ? "/realty/transaction-coordination" : "/realty/transaction-coordination/contract/vendors"
      //       } else {
      //         nextRoute = isDraft ? "/realty/transaction-coordination" : "/realty/transaction-coordination/contract/escrow-info"
      //       }
      //   }

      // }
    // }

    if (byPassUpdate) {
      if (selectVendor.length && fieldValues.is_radius_tc_to_open_escrow) {
        nextRoute = "/realty/transaction-coordination/contract/vendors"
      } else {
        nextRoute = "/realty/transaction-coordination/contract/upload?fromTC=true"
      }
      history.push(nextRoute)
      return
    }

    if (enableTC.includes(state)) {
      updateContract({
        payload: {
          ...fieldValues,
          is_using_deal_tc: 0,
          is_radius_tc_to_open_escrow: 0,
          agent_id: user.id,
          current_contract_step: "transaction_coordination",
          saveDraft: isDraft ? 1 : 0,
        },
        dealId: dealToView,
        callAPI: ["tc_info", "select_vendor"],
        nextRoute,
        history,
        callback: () => {
          // if (!fieldValues.is_radius_tc_to_open_escrow) {
          //   updateDealStatus({
          //     dealId: dealToView,
          //     step: "coming_soon",
          //   })
          // }
        },
      })
    } else {
      updateContract({
        payload: {
          ...fieldValues,
          agent_id: user.id,
          current_contract_step: "transaction_coordination",
          saveDraft: isDraft ? 1 : 0,
        },
        dealId: dealToView,
        callAPI: ["tc_info", "select_vendor"],
        nextRoute,
        history,
        callback: () => {
          // if (!fieldValues.is_radius_tc_to_open_escrow) {
          //   updateDealStatus({
          //     dealId: dealToView,
          //     step: "coming_soon",
          //   })
          // }
        },
      })
    }
  }

  onSaveAsDraft = (values) => {
    console.log("===this.formRef.current.getFieldsValue===", this.formRef.current.getFieldsValue())
    this.onFinish(this.formRef.current.getFieldsValue(), true)
    // this.formRef.current.setFieldsValue({
    //   "name-0": "Hello world!",
    // })
    // alert("onFinishFailed")
  }

  byPassUpdate = () => {
    console.log("===this.formRef.current.getFieldsValue===", this.formRef.current.getFieldsValue())
    this.onFinish(this.formRef.current.getFieldsValue(), false, true)
    // this.formRef.current.setFieldsValue({
    //   "name-0": "Hello world!",
    // })
    // alert("onFinishFailed")
  }

  setShowEscrowOption = (value) => {
    this.setState({
      showEscrowOption: value === 1,
      notTCOption: value === 0,
    })
  }

  setLenderEmail = (value) => {
    this.setState({
      showLenderEmail: value === 0,
    })
  }

  getOptions = () => {
    const { agentGoogleContacts } = this.props
    console.log("==contacts===", agentGoogleContacts)
    return agentGoogleContacts && agentGoogleContacts.length ? agentGoogleContacts.filter(ct => ct.names).map((ct, index) => ({ label: ct.names[0].displayName, value: `${ct.names[0].displayName}`, key: index })) : []
  }

  changeOtherFieldsValues = (objString, option) => {
    console.log("==payload=values=", objString)
    let { agentGoogleContacts } = this.props
    agentGoogleContacts = agentGoogleContacts.filter(ct => ct.names)
    const name = objString
    const contactIndex = option.key
    const phoneNumber = agentGoogleContacts[contactIndex]?.phoneNumbers?.[0].value
    const emailAddresses = agentGoogleContacts[contactIndex]?.emailAddresses?.[0].value
    if (phoneNumber || emailAddresses) {
      this.formRef.current.setFieldsValue({
        tc_name: name,
        tc_email: emailAddresses,
        tc_phone: phoneNumber,
      })
    }
  }

  getInitialValues = () => {
    const { getContractByIdResponse } = this.props
    const { showEscrowOption, notTCOption, showLenderEmail } = this.state
    const tcInfo = getContractByIdResponse.data?.[0]
    const enableTC = ["California", "Oregon", "Washington"]
    const result = {}
    if (tcInfo) {
      if (showEscrowOption === null && notTCOption === null && showLenderEmail == null) {
        if (tcInfo.state && enableTC.includes(tcInfo.state)) {
          this.setState({
            showEscrowOption: false,
            notTCOption: true,
            showLenderEmail: tcInfo.is_all_cash_offer === 0,
          })
        } else {
          this.setState({
            showEscrowOption: tcInfo.is_using_deal_tc === 1,
            notTCOption: tcInfo.is_using_deal_tc === 0,
            showLenderEmail: tcInfo.is_all_cash_offer === 0,
          })
        }
      }
      return {
        ...tcInfo,
      }
    }
    return result
  }

  getIsEdit = deal => deal && !(deal.step === "commission_verified" || deal.step === "closed" || deal.step === "payment_received")

  // onClickFunction = () => {
  //   this.formRef.current.setFieldsValue({
  //     is_using_deal_tc: 1,
  //   })
  //   this.setState({
  //     notTCOption: false,
  //   })
  // }

  render() {
    const { showEscrowOption, notTCOption, showLenderEmail } = this.state
    const { history, getContractByIdResponse, updateContractResponse, loaderOnAgentInfo, getListingCoverSheetResponse, offerClaimed } = this.props || {}
    const commonRules = [
      {
        required: true,
        message: "Required",
      },
    ]
    let showEscrowBasedOnFileType = true
    if (getContractByIdResponse.data?.[0].file_type && ["dual", "pending_contract", "external_referral_agreement"].includes(getContractByIdResponse.data?.[0].file_type)) {
      showEscrowBasedOnFileType = true
    }
    // const user = cookie.load("user") || {}
    // const agentId = user.id
    const enableTC = ["California", "Oregon", "Washington"]
    const state = getContractByIdResponse.data?.[0]?.state

    return (
      <>
        {/* {notTCOption && offerClaimed === false && (
          <TCOfferBanner
            onClick={this.onClickFunction}
            agentId={agentId}
          />
        )}
        {offerClaimed === true && (
          <TCOfferBanner onClick={null} agentId={agentId} />
        )} */}
        <Wrap>
          <HeaderNav>
            Transaction Coordination
          </HeaderNav>
          <ProgressBarContainer />
          <Container>
            <RightSideBar>
              {getContractByIdResponse.isFetching || loaderOnAgentInfo ? (<Loader />) : (
                <Form colon={false} layout="vertical" requiredMark={false} onFinish={this.onFinish} initialValues={this.getInitialValues()} preserve={false} ref={this.formRef} disabled={getContractByIdResponse?.data?.[0] ? !this.getIsEdit(getContractByIdResponse?.data?.[0]) : false}>
                  {enableTC.includes(state) ? (
                    null
                  ) : (
                    <Form.Item name="is_using_deal_tc" label="Would you like to use your Radius in-house TC for this transaction?" rules={commonRules}>
                      <Radio.Group
                        onChange={(e) => {
                          this.setShowEscrowOption(e.target.value)
                        }}
                      >
                        <Space direction="vertical">
                          <Radio
                            value={1}
                          >
                            <LabelComponent text="Yes" />
                          </Radio>
                          <Radio value={0}>
                            <LabelComponent text="No" />
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  )}
                  {(showEscrowOption && showEscrowBasedOnFileType) && (
                    <Form.Item name="is_radius_tc_to_open_escrow" label="Do you want Radius TC to open escrow?" rules={commonRules}>
                      <Radio.Group>
                        <Space direction="vertical">
                          <Radio value={1}>
                            <LabelComponent text="Yes" />
                          </Radio>
                          <Radio value={0}>
                            <LabelComponent text="No" />
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  )}
                  {notTCOption && (
                  <>
                    <Form.Item
                      name="tc_name"
                      label={ (enableTC.includes(state)) ? <LabelComponent text="TC First and Last Name" /> : <LabelComponent text="TC First and Last Name" required /> }
                      rules={(enableTC.includes(state)) ? null : commonRules}
                    >
                      {/* <Input /> */}
                      <AutoComplete
                        filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                        options={this.getOptions()}
                        onChange={(value, option) => this.changeOtherFieldsValues(value, option)}
                      />
                    </Form.Item>
                    <FlexBox>
                      <Form.Item
                        name="tc_email"
                        label={ (enableTC.includes(state)) ? <LabelComponent text="TC Email" /> : <LabelComponent text="TC Email" required /> }
                        rules={[
                          {
                            type: "email",
                            message: "email is invalid",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="tc_phone"
                        label={ (enableTC.includes(state)) ? <LabelComponent text="TC Cell Phone" /> : <LabelComponent text="TC Cell Phone" required /> }
                      rules={(enableTC.includes(state)) ? null : commonRules}
                        normalize={normalizePhone}
                      >
                        <Input />
                      </Form.Item>
                    </FlexBox>
                  </>
                  )}
                  <Form.Item name="is_all_cash_offer" label="Is this transaction an all cash offer?" rules={commonRules}>
                    <Radio.Group onChange={(e) => { this.setLenderEmail(e.target.value) }}>
                      <Space direction="vertical">
                        <Radio value={1}>
                          <LabelComponent text="Yes" />
                        </Radio>
                        <Radio value={0}>
                          <LabelComponent text="No" />
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                  {showLenderEmail && (
                    <Form.Item name="lender_email_address" label="Lender Email*" rules={commonRules}>
                      <Input />
                    </Form.Item>
                  )}
                  <FlexBox>
                    <FlexBox wd="100%">
                      <Form.Item>
                        <Button
                          style={{
                            width: 119,
                            height: 48,
                            color: "#849DF8",
                            padding: 0,
                            marginLeft: "-10px",
                          }}
                          size="large"
                          type="link"
                          onClick={this.onSaveAsDraft}
                        >
                          Save for Later
                        </Button>
                      </Form.Item>
                    </FlexBox>
                    <FlexBox>
                      <Form.Item>
                        <Button
                          style={{
                            width: 119,
                            height: 48,
                            background: "white",
                            float: "right",
                            borderColor: "#5A5FF2",
                            color: "#5A5FF2",
                          }}
                          type="primary"
                          shape="round"
                          size="large"
                          disabled={false}
                          // htmlType="submit"
                          onClick={() => { history.push("/realty/transaction-coordination/contract/transaction-details") }}
                        >
                          Back
                        </Button>
                      </Form.Item>
                      {!getContractByIdResponse?.data?.[0] || (getContractByIdResponse?.data?.[0] && this.getIsEdit(getContractByIdResponse?.data?.[0])) ? (
                        <Form.Item>
                          <Button
                            style={{
                              width: 119,
                              height: 48,
                              background: "#5A5FF2",
                              float: "right",
                              borderColor: "white",
                            }}
                            type="primary"
                            shape="round"
                            size="large"
                            htmlType="submit"
                            loading={getContractByIdResponse.isFetching || updateContractResponse.isFetching}
                            // onClick={() => { history.push("/realty/listings/coversheet/escrow-info") }}
                          >
                            Next
                          </Button>
                        </Form.Item>
                      ) : (
                        <Form.Item>
                          <Button
                            style={{
                              width: 119,
                              height: 48,
                              background: "#5A5FF2",
                              float: "right",
                              borderColor: "white",
                            }}
                            type="primary"
                            shape="round"
                            size="large"
                            disabled={false}
                            onClick={this.byPassUpdate}
                            // onClick={() => { history.push("/realty/listings/coversheet/escrow-info") }}
                          >
                            Next
                          </Button>
                        </Form.Item>
                      )}
                    </FlexBox>
                  </FlexBox>
                </Form>
              )}
            </RightSideBar>
          </Container>
        </Wrap>
      </>
    )
  }
}

export default TCContainer(TechAssetsContainer(CoverSheet))

