import React from "react"
import Box from "@ui/BuilderComponents/BoxV2/index"
import Paragraph from "@ui/BuilderComponents/Paragraph/index"
import MemberElement from "./MemberElement/index"
import { INTER } from "@fonts"

const TeamMembersListing = ({
  teamMembers,
  title,
  fs,
  ff,
}) => (
  <Box
    margin="20px 0px 50px 0px"
  >
    <Paragraph
      paraFamily={ff || INTER}
      minTabletParaSize={fs || "18px"}
      preDesktopParaSize={fs || "20px"}
      minLargeDesktopParaSize={fs || "24px"}
      minDesktopParaSize={fs || "20px"}
      paraWeight="500"
      letterSpacing="2px"
    >
      {title || "Your Team"}
    </Paragraph>
    <Box
      className="member-box"
      displayStyle="flex"
      margin="30px 0px 0px 0px"
      width="688px"
      flexWrap="wrap"
      flexDirection="row"
      flexGap="15px"
      height="max-content"
    >
      {/* Members are dynamic */}
      {teamMembers && teamMembers.map(member => (
        <MemberElement
          name={`${member.firstname} ${member.lastname}`}
          photo={member.profile_pic}
          position={member.member_type}
          profileUrl={member.profile_name || ""}
          isSubscribedToBrokerPlan={member.isSubscribedToBrokerPlan}
        />
      ))}
    </Box>
  </Box>
)

export default TeamMembersListing
