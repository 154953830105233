import React from "react"
import { HIGHLIGHT_FONT } from "@fonts"
import colors from "@colors"
import Button from "@ui/Button"
import styled from "styled-components"
import RadiusCoverage from "./RadiusCoverage"
import TraditionalCoverage from "./TraditionalCoverage"

const Wrap = styled.div`

  @media(min-width: 1520px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  > h1 {
    font-size: 42px;
    font-family: ${HIGHLIGHT_FONT};
    margin-bottom: 80px;
    color: ${colors.black};

    @media(max-width: 1099px) {
      text-align: center;
      font-size: 36px;
      padding-top: 0;
    }

    @media(max-width: 425px) {
      font-size: 32px;
    }
  }
`

const BrokerageCoverage = styled.div`
  display: flex;
  justify-content: space-between;

  @media(min-width: 1024px) {
    gap: 50px;
  }

  @media(min-width: 1366px) {
    align-content: center;
    gap: 100px;
  }

  @media(max-width: 1099px) {
    flex-direction: column;
    gap: 30px;
  }
`

const ButtonWrap = styled.div`
  width: fit-content;
  margin: 60px auto;

  @media(max-width: 425px) {
    margin: 60px 0px;
    width: 100%;
    > button {
      width: 100% !important;
    }
  }
`

const Coverage = ({
  toggleApplyModal,
  isTeamLP,
}) => (
  <Wrap>
    <h1>
      Elevate your brokerage experience
    </h1>
    <BrokerageCoverage>
      <RadiusCoverage />
      <TraditionalCoverage toggleApplyModal={toggleApplyModal} />
    </BrokerageCoverage>
    {isTeamLP ? (
      <ButtonWrap>
        <Button
          style={{
            width: "365px",
            padding: 10,
            background: `${colors.new_primary}`,
            border: "none",
            borderRadius: "0px",
          }}
          onClick={() => {
            toggleApplyModal({
              bool: true,
              accountType: "owner",
              headerText: "Nice to meet you!",
              subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
            })
          }}
        >
          Join Radius
        </Button>
      </ButtonWrap>
    ) : ""}
  </Wrap>
)

export default Coverage
