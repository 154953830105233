import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import app from "container/App"
import colors from "@colors"
import Box from "@ui/Box"
import cookie from "services/CookieStorage"
import { Link, withRouter } from "react-router-dom"
import icons from "@icons"
import RealtyContainer from "container/Realty"
import {
  MOBILE_BREAK_POINT,
  minDesktopWidth,
  minMediumDesktopWidth,
  xsMobile,
} from "shared/consts"
import RadiusRARLogo from "images/home-v3/radiusAgentRealty.svg"
import buttonArrow from "images/home-v3/buttonArrow.svg"
import StyledIcons from "library/icons/StyledIcons"
import { authCheck } from "services/auth"
import { handleDataLayerPush } from "services/dataLayerUtil"
import { ATC, INTER } from "@fonts"

const EVENT = "top_navigation_menu_clicked"

const LoginButtonWrap = styled.ul`
  display: flex;
  position: relative;
  li {
    height: 70px;
    color: ${colors.contentText};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 20px 0px 20px 5px;
    text-decoration: none;
    position: relative;
    i {
      margin-left: 10px;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    &.highlight {
      background: ${colors.primaryColor};
      color: #FFF;
    }

    ${props => props.isOldSafariVersion && `
        padding-top: 9px;
      `}
  }
`

const DroDownArea = styled.div`
  opacity: 0;
  position: absolute;
  top: 58px;
  border-radius: 10px;
  left: 0px;
  right: 0px;
  z-index: 9999;
  width: 257px;
  //min-width: 300px;
  border: 1px solid #EEE;
  box-shadow: 0px 10px 12px 0px rgba(41, 42, 55, 0.15);
  backdrop-filter: blur(10.5px);
  //padding-bottom: 16px;
  background: #fff;
  padding: 15px;
  display: none;
  height: 127px;
  padding-bottom: 26px;

  img {
    width: 308px;
  }

  ${props => props.activeDropDown && `
    display: block;
  `}

  animation: appear 0.5s ease-out forwards;


  @keyframes appear {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  ${props => props.additional && `
    height: 215px;
    width: 319px;
  `}
`

const ItemList = styled.ul`
  background: ${colors.white};
  border-radius: 4px;
  overflow: hidden;
  flex-direction: column;
  gap: 20px;
  display: flex;

  //padding-top: 29px;
`

const LinkStyles = `
  display: flex;
  // height: 76px
 //align-items: center;
  text-decoration: none;
  color: ${colors.contentText};
  // padding-bottom: 12px;
  // margin: 0px 16px 0px 16px;
  // padding-top: 12px;

  //padding: 10px;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  // p {
    
  // }

  img {
    max-height: 32px;
  }
`

const NewTextForAnchorLinks = styled.p`
  color: var(--BG-Dark-mode, #292A37);
  text-align: left;
  font-family: ${INTER};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  text-transform: uppercase;
  margin: 0;

  &:hover {
    color: #5a5ff2;
  }
`

const ListLink = styled(Link)`
  ${LinkStyles}
  padding: 10px;
  &:hover {
    border-radius: 8px;
    background: #E9F3FB;
  }
`

const ALink = styled.a`
  ${LinkStyles}
`

const DropdownIcon = styled.i`
  position: relative;
  transition-duration: 0.1s;
  filter: grayscale(1) contrast(5);
  color: ${colors.new_primary};
  display: inline-block;
  ${props => props.isActive && `
    transform: rotate(180deg);
  `}
`

const ProductTitle = styled.span`
  transition-duration: 0.3s;
  cursor: pointer;
  font-family: ${INTER};
  font-size: 15px;
  font-weight: 400;
  line-height: 6px;
  letter-spacing: 0px;
  text-align: center;

  color: ${colors.black};

  &:hover {
    color: ${colors.new_primary};
  }

  ${minDesktopWidth`
    font-size: 14px;
  `}

  ${minMediumDesktopWidth`
    font-size: 16px;
  `}

  ${props => props.isActive && `
    color: ${colors.new_primary};
  `}
  ${props => props.whatWeOffer && `
    width: max-content;
   `}
`

const Filler = styled.span`
  background: ${colors.white};
  position: relative;
  display: block;
`

const ImageWrap = styled.div`
  padding-left: 24px;
  height: 38px;
`

const PurpleDiv = styled.div`
  height: 48px;
  width: 100%;
  background: #524969;
  text-align: center;
  color: white;
  font-family: ${ATC};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  padding-top: 15px;
  margin-top: 26px;

  img {
    height: 10px;
    width: 10px;
    margin-left: 10px;
  }
  button {
    background: transparent;
    color: white;
    border: none;
    padding: 0;
    cursor: pointer;
  }
`

const TabTitle = styled.p`
  font-family: ${ATC};
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 8px;
  letter-spacing: 0px;
  text-align: left;
  color: #303030;
`

const TabDescription = styled.p`
  font-family: ${ATC};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 0px;
  color: #8C8C8C;
`

const handleLearnMoreNav = (history, url, RARTracker) => {
  const isLogged = authCheck()
  if (isLogged) {
    RARTracker({
      payload: {
        source: "community-navigation-droddown",
      },
    })
  }
  history.push(url)
}

const ProductDropdown = ({
  handleVisiblity,
  handleOnClick,
  variant,
  history,
  RARTracker,
}) => (
  <ItemList>
    <ListLink
      to="/realty/agents"
      onClick={() => handleOnClick("agents", "navbar-meet-our-agents", RARTracker)}
    >
      <Box>
        <TabTitle>Meet Our Agents</TabTitle>
        <TabDescription>Grow your business with Radius.</TabDescription>
      </Box>
    </ListLink>
    <ListLink
      to="/realty/for-agents"
      onClick={() => handleOnClick("agents", "navbar-agents", RARTracker)}
    >
      <Box>
        <TabTitle>For Agents</TabTitle>
        <TabDescription>How we help agents succeed.</TabDescription>
      </Box>
    </ListLink>
    <ListLink
      to="/realty/teams"
      onClick={() => handleOnClick("teams", "navbar-teams", RARTracker)}
    >
      <Box>
        <TabTitle>For Teams</TabTitle>
        <TabDescription>How we help teams succeed.</TabDescription>
      </Box>
    </ListLink>
    <ListLink
      to="/teams/jobs"
      onClick={() => handleOnClick("team-jobs", "navbar-team-jobs", RARTracker)}
    >
      <Box>
        <TabTitle>Jobs</TabTitle>
        <TabDescription>Your future with real estate starts here</TabDescription>
      </Box>
    </ListLink>
    {/*<ListLink
      onClick={() => handleOnClick("royale", "navbar-royale", RARTracker)}
      to="/realty/elite"
    >
      <Box>
        <TabTitle>Royale</TabTitle>
        <TabDescription>Luxury Services</TabDescription>
      </Box>
    </ListLink>*/}
    <ListLink
      to="/realty/radius-financing"
      onClick={() => handleOnClick("edge", "navbar-edge", RARTracker)}
    >
      <Box>
        <TabTitle>Radius Financing</TabTitle>
        <TabDescription>White Glove Solutions</TabDescription>
      </Box>
    </ListLink>
  </ItemList>
)

const ResourceDropdown = () => (
  <ItemList>
    <ListLink
      onClick={() => {
        root.location.href = "https://blog.radiusagent.com/"
      }}
      to="/"
    >
      <Box>
        <TabTitle>Blog</TabTitle>
        <TabDescription>Real estate topics, trends, and reports.</TabDescription>
      </Box>
    </ListLink>
    <ListLink
      onClick={() => {
        if (root.heap) {
          root.heap.track("Click_academy_navbarDropdown_web")
        }
      }}
      to="/academy"
    >
      <Box>
        <TabTitle>Radius Academy</TabTitle>
        <TabDescription>Curated real estate courses and trainings.</TabDescription>
      </Box>
    </ListLink>
  </ItemList>
)

const LearnDropdown = () => (
  <ItemList>
    <ListLink to="/academy">
      <Box>
        <p>Radius Academy</p>
        <p>Brush up on skills or learn the basics with our easy-to-use courses.</p>
      </Box>
    </ListLink>
    <ALink href="https://blog.radiusagent.com/">
      <Box>
        <p>Radius Blog</p>
        <p>Read about what we&apos;ve been up to, catch up on industry insights, and more.</p>
      </Box>
    </ALink>
  </ItemList>
)

const WhoWeHelpDropdown = ({ isLogged, user, userData }) => {
  let constructedQuery = ""
  if (isLogged && user && Object.keys(user).length > 0) {
    const payload = {
      agent_id: user.id,
      ag_eml: user.email,
      ag_nm: `${user.firstname} ${user.lastname}`,
      login_status: "logged_in",
      agent_type: "unknown_prospect",
    }
    constructedQuery = `ag_nm=${payload.ag_nm}&ag_eml=${payload.ag_eml}&agent_type=${payload.agent_type}&agent_id=${payload.agent_id}&login_status=${payload.login_status}`
  }
  return (
    <ItemList>
      <ALink
        onClick={(e) => {
          e.stopPropagation()
          handleDataLayerPush(userData, EVENT, "launch_your_brand")
        }}
        href="https://www.radiusagent.com/launch-your-label"
      >
        <Box>
          <NewTextForAnchorLinks className="912938129u30921ahsjd">LAUNCH YOUR BRAND</NewTextForAnchorLinks>
        </Box>
      </ALink>
      <ALink
        onClick={(e) => {
          e.stopPropagation()
          handleDataLayerPush(userData, EVENT, "up_level_your_brokerage")
        }}
        href="https://www.radiusagent.com/uplevel-your-brokerage"
      >
        <Box>
          <NewTextForAnchorLinks>UP-LEVEL YOUR BROKERAGE</NewTextForAnchorLinks>
        </Box>
      </ALink>
      <ALink
        onClick={(e) => {
          e.stopPropagation()
          handleDataLayerPush(userData, EVENT, "join_a_team")
        }}
        href="https://www.radiusagent.com/agents"
      >
        <Box>
          <NewTextForAnchorLinks>JOIN A TEAM</NewTextForAnchorLinks>
        </Box>
      </ALink>
    </ItemList>
  )
}

const BenefitsDropDown = ({
  userData,
}) => (
  <ItemList
    style={{
      width: "300px",
      height: "200px",
      paddingBottom: "20px",
    }}
  >
    <ALink
      className="19u31h2idh91do"
      onClick={(e) => {
        e.stopPropagation()
        handleDataLayerPush(userData, "radius-community")
      }}
      href="https://www.radiusagent.com/radius-community"
    >
      <Box>
        <NewTextForAnchorLinks>RADIUS COMMUNITY</NewTextForAnchorLinks>
      </Box>
    </ALink>
    <ALink
      onClick={(e) => {
        e.stopPropagation()
        handleDataLayerPush(userData, "mel")
      }}
      href="https://www.radiusagent.com/mel"
    >
      <Box>
        <NewTextForAnchorLinks>MEL AI ASSISTANT</NewTextForAnchorLinks>
      </Box>
    </ALink>
    <ALink
      onClick={(e) => {
        e.stopPropagation()
        handleDataLayerPush(userData, "transaction-management")
      }}
      href="https://www.radiusagent.com/transaction-management"
    >
      <Box>
        <NewTextForAnchorLinks>TRANSACTION MANAGEMENT SUITE</NewTextForAnchorLinks>
      </Box>
    </ALink>
    <ALink
      onClick={(e) => {
        e.stopPropagation()
        handleDataLayerPush(userData, "copilot")
      }}
      href="https://www.radiusagent.com/copilot"
    >
      <Box>
        <NewTextForAnchorLinks>COPILOT FROM LOANDESK</NewTextForAnchorLinks>
      </Box>
    </ALink>
    <ALink
      onClick={(e) => {
        e.stopPropagation()
        handleDataLayerPush(userData, "meet_the_team")
      }}
      href="https://www.radiusagent.com/meet-the-team"
    >
      <Box>
        <NewTextForAnchorLinks>MEET THE TEAM</NewTextForAnchorLinks>
      </Box>
    </ALink>
  </ItemList>
)

const DropComp = ({
  type,
  activeDropDown,
  handleMouseOver,
  handleOnClick,
  handleVisiblity,
  variant,
  history,
  RARTracker,
  userData,
  user,
}) => {
  const isLogged = authCheck()
  return (
    <DroDownArea
      additional={type === "benefits"}
      onMouseLeave={() => {
        handleMouseOver(false, "")
      }}
      activeDropDown={activeDropDown}
    >
      <Filler />
      {type === "product" && (
        <ProductDropdown
          history={history}
          variant={variant}
          handleOnClick={handleOnClick}
          handleVisiblity={handleVisiblity}
          RARTracker={RARTracker}
        />
      )}
      {type === "resources" && <ResourceDropdown />}
      {type === "learn" && <LearnDropdown />}
      {type === "who_we_help" && <WhoWeHelpDropdown isLogged={isLogged} user={user} userData={userData} />}
      {type === "benefits" && <BenefitsDropDown userData={userData} />}

    </DroDownArea>
  )
}

class MainDropDownNav extends React.PureComponent {
  state = {
    activeDropDown: "",
    variant: "",
  }

  getOldSafari = () => {
    try {
      const {
        userAgent,
      } = root.navigator
      const isSafari = !userAgent.includes("Firefox/") &&
        !userAgent.includes("Chrome/") && !userAgent.includes("Edg/") && userAgent.includes("Safari/")
      const isOldSafariVersion = isSafari &&
        parseInt(userAgent.split("Version/")[1].split(" ")[0].split(".")[0], 10) < 14
      return isOldSafariVersion
    } catch (error) {
      return false
    }
  }

  componentDidMount() {
    this.setState({
      isOldSafariVersion: this.getOldSafari(),
      variant: cookie.load("G_HOME_PAGE_VARIANT")
      || (root.google_optimize && root.google_optimize.get("yJSVhbOBQWWbe1LwDM8kIQ")) || "",
    })
  }

  handleMouseOver = (isShow, panel) => {
    if (root.heap && isShow) {
      root.heap.track("Hover_OurBrokerage_Web")
    }
    this.setState({
      activeDropDown: isShow ? panel : false,
    })
  }

  handleDropdownVisiblity = () => {
    this.setState({
      activeDropDown: false,
    })
  }

  handleSectionScroll = (section, events, RARTracker) => {
    const { setActiveNavbarSection, scrollToElement } = this.props
    if (root.heap && section === "overview") {
      root.heap.track("Click_BrokerageOverview_Web")
    }

    if (root.heap && section === "our-team") {
      root.heap.track("Click_OurTeam_Web")
    }

    if (root.heap && section === "testimonials") {
      root.heap.track("Click_Testimonials_Web")
    }

    if (root.heap && section === "agents") {
      root.heap.track("click_for_agents_navbarDropdown_web")
    }

    if (root.heap && section === "teams") {
      root.heap.track("click_for_teams_navbar_web")
    }

    if (root.heap && section === "royale") {
      root.heap.track("Click_Royale_OurBrokerage_Dropdown_Web")
    }

    if (root.heap && section === "edge") {
      root.heap.track("Click_Radius_Edge_OurBrokerage_Dropdown_Web")
    }

    if (root.heap && section === "pricing") {
      root.heap.track("Click_Radius_Realty_Pricing_OurBrokerage_Dropdown_Web")
    }

    if (root.heap && section === "isa") {
      root.heap.track("Click_Radius_Realty_ISA_Dropdown_Web")
    }
    if (root.heap && section === "team-jobs") {
      root.heap.track("Click_Radius_Realty_Team_Jobs_Web")
    }

    if (root.heap && section === "our-agents") {
      root.heap.track("click_ouragents_navbar_web")
    }

    const isLogged = authCheck()
    if (isLogged) {
      RARTracker({
        payload: {
          source: events,
        },
      })
    }

    setActiveNavbarSection(section)
    scrollToElement({
      id: `#realty-v2-${section}`,
      offset: -130,
    })
  }

  handleRedirection = (e, type) => {
    e.preventDefault()
    const {
      RARTracker,
      user,
    } = this.props

    if (root.heap) {
      if (type === "resources") {
        root.heap.track("Click_OurResourcesNavbar_Web")
      } else {
        root.heap.track("Click_OurBrokerageNavbar_Web")
      }
    }

    this.setState({
      activeDropDown: false,
    })
    const isLogged = authCheck()
    let addedQuery = ""
    if (isLogged) {
      RARTracker({
        payload: {
          source: "navbar-our-brokerage",
        },
      })

      const payload = {
        agent_id: user.agent_id,
        ag_eml: user.email,
        ag_nm: `${user.firstname} ${user.lastname}`,
        login_status: "logged_in",
        agent_type: "unknown_prospect",
      }
      addedQuery = `ag_nm=${payload.ag_nm}&ag_eml=${payload.ag_eml}&agent_type=${payload.agent_type}&agent_id=${payload.agent_id}&login_status=${payload.login_status}`
    }

    // if (user.agent_id && addedQuery) {
    //   root.location.href = `https://www.radiusagent.com/who-we-serve?${addedQuery}`
    // } else {
    //   root.location.href = "https://www.radiusagent.com/who-we-serve"
    // }
  }

  render() {
    const { activeDropDown, variant, isOldSafariVersion } = this.state
    const { history, RARTracker, type, isEven, user, userData } = this.props

    return (
      <StyledIcons>
        <LoginButtonWrap
          href={`${WEB_URL}login`}
          onMouseLeave={() => {
            this.handleMouseOver(false, "")
          }}
          isOldSafariVersion={isOldSafariVersion}
        >
          {/* <li>
            <ProductTitle
              whatWeOffer
              isEven={isEven}
              isActive={activeDropDown === "product"}
              onClick={(e) => {
                e.stopPropagation()
                if (root.heap) {
                  if (type === "resources") {
                    root.heap.track("Click_resources_navbar_web")
                  } else {
                    root.heap.track("click_ourbrokerage_navbar1_web")
                  }
                  root.heap.track("click_ourbrokerage_navbar1_web")
                }
                handleDataLayerPush(userData, EVENT, type)
                this.handleRedirection(e, type)
              }}
              onMouseEnter={() => {
                if (root.heap) {
                  if (type === "resources") {
                    root.heap.track("hover_resources_navbar1_web")
                  } else {
                    root.heap.track("hover_ourbrokerage_navbar1_web")
                  }
                }
                this.handleMouseOver(true, type)
              }}
            >
              {type === "resources" && "Resources"}
              {type === "product" && "Our Brokerage"}
              {type === "who_we_help" && "WHO WE SERVE"}
              {type === "benefits" && "RADIUS DIFFERENCE"}
              <DropdownIcon
                className={icons.arrowsExpandDown}
                isActive={activeDropDown === type}
              />
            </ProductTitle>
            <DropComp
              activeDropDown={activeDropDown}
              type={type}
              variant={variant}
              history={history}
              RARTracker={RARTracker}
              handleMouseOver={this.handleMouseOver}
              handleOnClick={this.handleSectionScroll}
              handleVisiblity={this.handleDropdownVisiblity}
              userData={userData}
              user={user}
            />
          </li> */}
        </LoginButtonWrap>
      </StyledIcons>
    )
  }
}

export default app(withRouter(RealtyContainer(MainDropDownNav)))
