import React, { useState } from "react"
import { reduxForm, Field } from "redux-form"
import {
  required,
  email,
  normalizePhone,
  minLength,
  maxLength,
  nonSpecialCharOnly,
  leastChars,
} from "services/formUtils"
import { ATC } from "@fonts"
import colors from "@colors"
import US_STATES from "shared/US_STATES_RADIUS.json"
import { groupedOptions } from "shared/GroupedStates"
import Button from "@ui/Button"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import {
  Input,
  PhoneInput,
  Select,
  HiddenField,
} from "@ui/Form"
import { Checkbox } from "@ui/antd"
import styled from "styled-components"

const SubTypeBox = styled.div``

const CheckBoxText = styled.p`
  color: #303030;
  font-size: 14px;
  position: relative;
  top: -3px;
`

const formatGroupLabel = data => (
  <SubTypeBox>
    <span
      style={{
        color: "black",
      }}
    >
      {data.label}
    </span>
  </SubTypeBox>
)

const I_WANT_TO_OPTIONS = [
  { value: "Start my own team / brokerage", label: "Start my own team / brokerage", production: "group" },
  { value: "Up-level my existing team / brokerage", label: "Up-level my existing team / brokerage", production: "group" },
  { value: "Join a high-performing team", label: "Join a high-performing team", production: "annual" },
  { value: "Create a solo brand ", label: "Create a solo brand ", production: "annual" },
]

const customStyles = {
  placeholder: defaultStyles => ({
    ...defaultStyles,
    fontSize: "16px",
    color: "#ccc",
    fontWeight: "300",
  }),
  groupHeading: defaultStyles => ({
    ...defaultStyles,
    fontSize: "14px",
    color: "#ccc",
  }),
}

const GROUP_PRODUCTION = [
  { value: "$250M", label: "$250M+" },
  { value: "$100-$250M", label: "$100M-$250M" },
  { value: "$50M-$100M", label: "$50M-$100M" },
  { value: "$25M-$50M", label: "$25M-$50M" },
  { value: "$10M-$25M", label: "$10M-$25M" },
  { value: "< $10M", label: "< $10M" },
]

const STATE_OPTIONS = Object.keys(US_STATES).map(key => ({ label: US_STATES[key], value: US_STATES[key] }))
const minLength3 = leastChars(2)
const minLength10 = minLength(10)
const maxLenth30 = maxLength(30)

const ANNUAL_PRODUCTION = [
  { value: "$1M or less", label: "$1M or less" },
  { value: "$1M - $2.9M", label: "$1M - $2.9M" },
  { value: "$3M - $10M", label: "$3M - $10M" },
  { value: "$11M - $25M", label: "$11M - $25M" },
  { value: "+$25M", label: "+$25M" },
]


const SIGN_UP_FORM_AGENT_TYPE = [
  { value: "Independent Real Estate Agent", label: "Independent Real Estate Agent" },
  { value: "Team Lead", label: "Team Lead" },
  { value: "Managing Broker", label: "Managing Broker" },
  { value: "Agent Looking for Team", label: "Agent Looking for Team" },
  { value: "Other", label: "Other" },
]

const Wrap = styled.form`
  width: 409px;
  label {
    color: ${colors.black};
  }
  input {
    background: ${colors.white};
    box-shadow: 0px 1px 11px rgb(0 0 0 / 5%);
    border-radius: 4px;
    border: none;
    padding: 15px 16px;
    height: 49px;

    ${props => props.isAgentsLP && `
      width: 409px;
      @media(max-width: 768px) {
        width: 100%;
      }
    `}

    color: ${colors.black};
    font-size: 17px;
    font-weight: 500;  
    border: none !important;
  }
  #react-select-2-input {
    height: 35px;
  }
  input:focus {
    border: none;
  }
  input::placeholder {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    color: ${colors.suvaGray} !important;
  }
  .input_state {
    >div {
      >div {
        >div {
          background: ${colors.white};
          box-shadow: 0px 1px 11px rgb(0 0 0 / 5%);
          border-radius: 4px;
          border: none;
          padding: 0px 8px;
          > div {
            > div {
            }
          }
        }
      }
    }
  }
`

const Spacer = styled.div`
  height: 16px;
`

const SubmitButton = styled(Button)`
  width: 100%;
  background: ${colors.new_primary};
  border-radius: 0px;
  border: none;
  height: 48px;
`

const Form = ({
  handleSubmit,
  isSubmitting,
  isAgentsLP,
  signUpFormValues,
}) => {
  const {
    account_type: productionType,
  } = signUpFormValues || {}

  const [optIn, setOptIn] = useState(false)

  console.log(signUpFormValues)

  return (
    <Wrap onSubmit={handleSubmit} isAgentsLP={isAgentsLP}>
      <Field
        component={HiddenField}
        name="unique_form_submitted"
        label=""
        placeholder="explore_ownership_mql_form"
        hidden
        readOnly
        value="explore_ownership_mql_form"
      />
      <Field
        component={Input}
        validate={[required, minLength3, maxLenth30, nonSpecialCharOnly]}
        name="firstname"
        label=""
        placeholder="First and Last Name"
        labelStyles={{
          color: colors.black,
          fontFamily: ATC,
        }}
        required
      />
      <Spacer />
      <Field
        component={PhoneInput}
        validate={[minLength10, required]}
        normalize={normalizePhone}
        name="phone"
        label=""
        placeholder="Phone Number"
        isShowAreaCode={false}
        inputStyle={{
          paddingLeft: 15,
          marginTop: 5,
          fontFamily: ATC,
          marginBottom: 5,
          fontSize: "16px",
          fontWeight: 500,
        }}
        isMaterial={false}
      />
      <Spacer />
      <Field
        component={Input}
        validate={[required, email]}
        name="email"
        label=""
        placeholder="Email"
        labelStyles={{
          color: colors.black,
          fontFamily: ATC,
        }}
        required
      />
      <Spacer />
      <div className="input_state">
        <Field
          component={Select}
          validate={[required]}
          options={groupedOptions}
          name="state"
          label=""
          formatGroupLabel={formatGroupLabel}
          onBlur={event => event.preventDefault()}
          placeholder="State"
          labelStyles={{
            margin: "0px",
            fontFamily: ATC,
          }}
          placeholderStyled={{
            fontFamily: ATC,
            fontWeight: 200,
            fontSize: "16px",
            color: colors.suvaGray,
          }}
          selectStyles={{
            singleValue: provided => ({
              ...provided,
              color: colors.black,
              fontSize: "17px",
              fontWeight: 500,
            }),
            placeholder: provided => ({
              ...provided,
              fontSize: "16px",
            }),
          }}
        />
      </div>
      <Spacer />
      <div className="input_state">
        {/* <Field
        component={Select}
        name="account_type"
        options={SIGN_UP_FORM_AGENT_TYPE}
        placeholder={isAgentsLP ? "I am a..." : "Real estate agent"}
        onBlur={event => event.preventDefault()}
        labelStyles={{
          margin: "0px",
          fontFamily: ATC,
        }}
        placeholderStyled={{
          fontFamily: ATC,
          fontWeight: 200,
          fontSize: "16px",
          color: colors.suvaGray,
        }}
        selectStyles={{
          singleValue: provided => ({
            ...provided,
            color: colors.black,
            fontSize: "17px",
            fontWeight: 500,
          }),
        }}
      /> */}
        <Field
          component={Select}
          validate={[required]}
          onBlur={event => event.preventDefault()}
          name="account_type"
          options={I_WANT_TO_OPTIONS}
          label=""
          placeholder="Please select a role.."
          //selectStyles={customStyles}
          labelStyles={{
            margin: "0px",
            fontFamily: ATC,
          }}
          placeholderStyled={{
            fontFamily: ATC,
            fontWeight: 200,
            fontSize: "16px",
            color: colors.suvaGray,
          }}
          selectStyles={{
            singleValue: provided => ({
              ...provided,
              color: colors.black,
              fontSize: "17px",
              fontWeight: 500,
            }),
            placeholder: provided => ({
              ...provided,
              fontSize: "16px",
            }),
          }}
          required
        />
        <Spacer />
        {productionType && productionType.production === "group" && (
          <Field
            component={Select}
            validate={[required]}
            onBlur={event => event.preventDefault()}
            options={GROUP_PRODUCTION}
            name="group_annual_production"
            // label="Group's annual production"
            placeholder="Group's annual production"
            labelStyles={{
              margin: "0px",
              fontFamily: ATC,
            }}
            placeholderStyled={{
              fontFamily: ATC,
              fontWeight: 200,
              fontSize: "16px",
              color: colors.suvaGray,
            }}
            selectStyles={{
              singleValue: provided => ({
                ...provided,
                color: colors.black,
                fontSize: "17px",
                fontWeight: 500,
              }),
              placeholder: provided => ({
                ...provided,
                fontSize: "16px",
              }),
            }}
            required
          />
        )}
        {productionType && productionType.production === "annual" && (
          <Field
            component={Select}
            validate={[required]}
            onBlur={event => event.preventDefault()}
            options={ANNUAL_PRODUCTION}
            name="annual_production"
            // label="Your Annual Production"
            placeholder="Your Annual Production"
            labelStyles={{
              margin: "0px",
              fontFamily: ATC,
            }}
            placeholderStyled={{
              fontFamily: ATC,
              fontWeight: 200,
              fontSize: "16px",
              color: colors.suvaGray,
            }}
            selectStyles={{
              singleValue: provided => ({
                ...provided,
                color: colors.black,
                fontSize: "17px",
                fontWeight: 500,
              }),
              placeholder: defaultStyles => ({
                ...defaultStyles,
                fontSize: "16px",
              }),
            }}
            required
          />
        )}
        <Spacer />
      </div>
      <Spacer />
      <Checkbox
        onChange={() => {
          setOptIn(!optIn)
        }}
      >
        <CheckBoxText>
          {`I agree to be contacted by Radius via call, email, and text. 
          To opt out, you can reply "stop" at any time or click the unsubscribe 
          link in the emails. Message and data rates may apply.`}
        </CheckBoxText>
      </Checkbox>
      <Spacer />
      <SubmitButton type="submit" disabled={!optIn || isSubmitting}>
        {isSubmitting ? (
          <ThreeDotLoader size={10} color={colors.white} />
        ) : (
          isAgentsLP ? "Send"
            : "Learn More"
        )}
      </SubmitButton>
    </Wrap>
  )
}

const WrappedForm = reduxForm({
  form: "APPLY_FORM_V2",
  destroyOnUnmount: false,
  enableReinitialize: true,
})(Form)

export default WrappedForm
