import Modal from "@ui/Modal"
import React, { useEffect } from "react"
import {
  File,
} from "@ui/Form"
import PhotoEditor from "dumbComponents/TeamProfile/EditPage/EditPhoto"
import styled from "styled-components"
import colors from "@colors"
import { withRouter } from "react-router-dom"
import { Field } from "redux-form"
import Rooms from "container/Rooms"
import Loader from "@ui/Loader"
import uuid from "uuid"
import { ATC } from "@fonts"

const Fallback = "https://s3.amazonaws.com/cdn.agentdesks.com/images/Rooms_New_Fallback.jpg"

const Wrap = styled.div`
  width: 955px;
  border-radius: 9px;
  padding: 60px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const FetchedImageWrap = styled.div`
  > p {
  color: ${colors.black};
  }
`

const Title = styled.div`
  p {
    font-family: ${ATC};
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: ${colors.black};
  }
`

const UploadCustomImageWrap = styled.div`
  margin-top: 20px;

  ::-webkit-file-upload-button {
    display: none;
  }
  
  input#file, #room_image {
    display: none;
  }
`

const CurrentImageWrap = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  height: 350px;
  width: 100%;

  background-image: url(${props => props.image});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`

const ImageList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  max-height: 400px;
  overflow: scroll;
  overflow-x: hidden;

  p {
    font-family: ${ATC};
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
  }
`

const ImageCard = styled.li`
  height: 200px;
  flex-grow: 1;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    border: 0.5px solid ${colors.new_primary};
  }
`

const RoomImage = styled.img`
  width: max-content;
  height: 100%;
`

const CustomLabel = styled.label`
  height: 41px;
  width: 179px;
  border-radius: 8px;
  border: 1px dotted rgba(140, 140, 140, 1);
  cursor: pointer;
  padding: 20px;

  background: linear-gradient(0deg, #F9F9F7, #F9F9F7),
  linear-gradient(0deg, #D0D0D0, #D0D0D0);
 
  cursor: pointer;
  display: block;


  p {
    font-family: ${ATC};
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: rgba(140, 140, 140, 1);
    position: relative;
    top: -13px;
  }
`

const CTAWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
`

const CloseButton = styled.button`
  height: 42px;
  width: 206px;
  background: transparent;
  color: #303030;
  cursor: pointer;

  display: block;
  padding: 19px;
  border-radius: 8px;
  border: 0.5px solid black;

  p {
    font-family: ${ATC};
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: rgba(140, 140, 140, 1);
    position: relative;
    top: -13px;
  }
`

const ImageUploadModal = ({
  toClose,
  currentImage,
  getDefaultImages,
  setImage,
  setCurrentImageToBeCropped,
  currentImageToBeCropped,
  uploadToS3,
  uploadTeamImageToS3Response,
  getRoomsMetaDataObjectsResponse,
  getRoomsMetaDataConfig,
}) => {
  const {
    data: metaData,
    isFetching: fetchingMetaData,
  } = getRoomsMetaDataObjectsResponse || {}

  useEffect(() => {
    if (!metaData || metaData === null) {
      getRoomsMetaDataConfig()
    }
  }, [])

  const handleImageSet = (url) => {
    setImage(url)
  }

  const handleCropImage = () => {
    const payload = {
      image: currentImage,
      type: "Image",
    }
    setCurrentImageToBeCropped(payload)
  }

  const closeEditModal = () => {
    setCurrentImageToBeCropped({
      image: null,
      type: null,
    })
  }

  const {
    isFetching: isUploading,
  } = uploadTeamImageToS3Response || {}

  return (
    <Modal
      show
      toClose={toClose}
      modalVersion={3}
      modalStyles={{
        backgroundColor: colors.dreamWhite,
        padding: "20px",
        height: "max-content",
        minHeight: "230px",
      }}
      dialogStyles={{
        width: "max-content",
        borderRadius: "6px",
      }}
      overlayStyles={{
        zIndex: 9991,
      }}
    >
      <Wrap>
        <Title>
          <p>Room Image</p>
        </Title>
        <CurrentImageWrap
          image={currentImage || Fallback}
        />
        {fetchingMetaData ? (
          <Loader />
        ) : (
          <FetchedImageWrap>
            <p>Select from images below</p>
            <ImageList>
              {metaData && metaData.imageData.map(image => (
                <ImageCard onClick={() => handleImageSet(image.image_url)}>
                  <RoomImage src={image.image_url} alt="room_select_image" />
                </ImageCard>
              ))}
            </ImageList>
          </FetchedImageWrap>
        )}
        <UploadCustomImageWrap>
          {isUploading ? (
            <Loader />
          ) : (
            <CTAWrap>
              <div>
                <CustomLabel
                  transparent
                  style={{
                    height: "30px",
                    width: "max-content",
                  }}
                  onClick={() => handleCropImage()}
                >
                  <p>Upload Custom Image</p>
                </CustomLabel>
                {/* <Field
                  name="room_image"
                  component={File}
                  version2
                  accept="image/*"
                  className="file_upload"
                  id="room_image"
                  customOnChange={(file) => {
                    if (uploadingImage) {
                      return
                    }
                    handleCustomImageUpload(file)
                  }}
                /> */}
              </div>
              <CloseButton onClick={toClose}>
                <p>Confirm</p>
              </CloseButton>
            </CTAWrap>
          )}
          {currentImageToBeCropped && (
            <PhotoEditor
              uploadAction={uploadToS3}
              image={currentImageToBeCropped}
              currentCroppedType="roomsPhoto"
              closeModal={closeEditModal}
              isUploading={isUploading}
              isRoomsModal
              minScale="1"
            />
          )}
        </UploadCustomImageWrap>
      </Wrap>
    </Modal>
  )
}

export default Rooms(withRouter(ImageUploadModal))
