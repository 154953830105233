import React, { useEffect } from "react"
import styled from "styled-components"
import Button from "@ui/Button"
import { ATC, HIGHLIGHT_FONT } from "@fonts"
import CreateRoomV2 from "dumbComponents/Network/MiddleContainer/RoomsSection/CreateRoomV2"
import DeleteRoomModal from "dumbComponents/Network/MiddleContainer/RoomsSection/DeleteRoomModal"
import RoomsContainer from "container/Rooms"
import colors from "@colors"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import SomeIcon from "dumbComponents/VideoRooms/components/LandingPage/Components/images/Group.svg"
import { minTabletWidth, preDesktopWidth } from "@consts"

const Wrap = styled.div`

  display: none;

  @media(min-width: 768px) {
    display: block;
    background: #E2E8FF;
    padding: 20px 80px 20px 80px;
  }
`

const InnerWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media(max-width: 1099px) {
    flex-direction: row;
  }

  @media(max-width: 767px) {
    display: none;
  }
`

const HeaderWrap = styled.div``

const Header = styled.h1`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 37px;
  color: ${colors.black};

  @media(max-width: 1099px){
    width: 400px;
  }
`

const Description = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.black};

  @media(max-width: 1099px){
    width: 330px;
  }
`

const ButtonWrap = styled.div`
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  justify-content: center;
`

const HeaderImageWrap = styled.div`
  display: flex;
  gap: 15px;

  img {
    position: relative;
    top: 9px;
  }
`

const RoomsBanner = ({
  createRoomPageSwitch,
  toggleCreateRoomModal,
  isShowCreateRoomModal,
  createRoom,
  createRoomResponse,
  getSuggestedUsers,
  getSuggestedUsersResponse,
  fetchAgentSearch,
  fetchAgentSearchResponse,
  sendUserInvitation,
  sendUserInvitationResponse,
  clearAgents,
  getTags,
  getTagsResponse,
  createScheduleRoom,
  clearTags,
  clearForms,
  getRoomsMetaDataConfig,
  getRoomsMetaDataObjectsResponse,
}) => {

  const {
    isFetching: fetchingMetaData,
  } = getRoomsMetaDataObjectsResponse || {}

  useEffect(() => {
    getRoomsMetaDataConfig()
  }, [])

  return (
    <Wrap>
      <InnerWrap>
        <HeaderImageWrap>
          <img src={SomeIcon} alt="Some Icon" />
          <HeaderWrap>
            <Header>
              Have something to share? Start a Room now.
            </Header>
            <Description>
              Bring on the knowledge. Start or schedule a Radius Room now—we’re all ears!
            </Description>
          </HeaderWrap>
        </HeaderImageWrap>
        <ButtonWrap>
          {fetchingMetaData ? (
            <ThreeDotLoader color={colors.new_primary} />
          ) : (
            <Button
              className="start_a_room"
              bsStyle="marineBLueFill"
              style={{
                width: "202px",
                height: "44px",
                borderRadius: "4px",
                fontSize: "16px",
                lineHeight: "0",
              }}
              onClick={() => {
                createRoomPageSwitch({ page: "room-details", type: null })
                toggleCreateRoomModal(true)
              }}
            >
              Quick Start a Room
            </Button>
          )}
          {fetchingMetaData ? (
            <ThreeDotLoader color={colors.white} />
          ) : (
            <Button
              className="schedule_a_room"
              bsStyle="marineBLueOutlined"
              style={{
                width: "202px",
                height: "44px",
                borderRadius: "4px",
                fontSize: "16px",
                lineHeight: "0",
                border: "1px solid #5A5FF2",
              }}
              onClick={() => {
                createRoomPageSwitch({ page: "room-details", type: "scheduled" })
                toggleCreateRoomModal(true)
              }}
            >
              Schedule Event
            </Button>
          )}
        </ButtonWrap>
      </InnerWrap>
      {isShowCreateRoomModal && (
        <CreateRoomV2
          toClose={() => {
            clearForms()
            createRoomPageSwitch({
              page: "select-type",
              type: null,
            })
            toggleCreateRoomModal(false)
          }}
          createRoom={createRoom}
          isCreatingRoom={createRoomResponse.isFetching}
          getSuggestedUsers={getSuggestedUsers}
          getSuggestedUsersResponse={getSuggestedUsersResponse}
          fetchAgentSearch={fetchAgentSearch}
          fetchAgentSearchResponse={fetchAgentSearchResponse}
          sendUserInvitation={sendUserInvitation}
          sendUserInvitationResponse={sendUserInvitationResponse}
          clearAgents={clearAgents}
          getTags={getTags}
          getTagsResponse={getTagsResponse}
          createScheduleRoom={createScheduleRoom}
          clearTags={clearTags}
        />
      )}
    </Wrap>
  )
}

export default RoomsContainer(RoomsBanner)
