import React from "react"
import styled from "styled-components"
import { ATC } from "@fonts"
import colors from "@colors"
import { convertToHTML } from "services/Utils"
import root from "window-or-global"
import {
  HeaderContainer,
  Header,
  Paragraph,
  RichTextEditorWrapper,
} from "dumbComponents/RealtyV3/RARRecruitment/pages/CommonComponents"

import { MIN_DESKTOP_BREAKPOINT_WIDTH_LIMIT } from "@consts"
const ViewTeamButton = styled.span`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: ${colors.new_primary};
  width: 100%;
  text-align: right;
  cursor: pointer;
`
// const dummyPara = "PHA+UmlzZSBHcm91cCBpcyBidWlsdCBvbiBhIGZvdW5kYXRpb24gb2YgZnJpZW5kc2hpcC4gSW4gMjAxNCwgUmlzZSBHcm91cCBmb3VuZGVycyBDcmlzIGFuZCBUaGFpIHNldCBzYWlsIG9uIHRoZWlyIGVudHJlcHJlbmV1ciBqb3VybmV5LiBUaHJvdWdoIHJpc2stbGFkZW4gc2VhcyB0aGUgdHdvIHdlYXRoZXJlZCBzdGFydC11cCBzdG9ybXMgdW50aWwgZXZlbnR1YWxseSBjYXRjaGluZyB3aW5kIG9mIHRoZWlyIGZpcnN0IHN1Y2Nlc3NmdWwgdmVudHVyZS48L3A+PHA+PGJyPjwvcD48cD5SaXNpbmcgYWJvdmUgY2hhbGxlbmdpbmcgd2F0ZXJzIGNvbmZpcm1lZCB0byB0aGVtIHRoYXQgZ3JpdCwgZ3Jhdml0YXMsIGFuZCBnb29kIHBlb3BsZSBhcmUgYW4gZXNzZW50aWFsIHBhcnQgb2Ygc3VjY2Vzcy4gTW9yZSBpbXBvcnRhbnRseSwgdHJpYWxzIGFuZCB0cmlidWxhdGlvbnMgYWxvbmcgdGhlIHdheSBrZXB0IHRoZW0gYm90aCBodW1ibGUuIFJpc2UgR3JvdXAgaXMgbW9yZSB0aGFuIGEgcmVhbCBlc3RhdGUgZ3JvdXAuIFdlIGRyYXcgdXBvbiBvdXIgZXh0ZW5zaXZlIG5ldHdvcmtzIGFuZCBoYXJkLWVhcm5lZCBleHBlcmllbmNlIGluIG9yZGVyIHRvIGNyZWF0aXZlbHkgc29sdmUgb3VyIGNsaWVudHPigJkgcHJvYmxlbXMuPC9wPg=="

// const dummyPara = null

const AboutTeamSection = ({
  header, paragraph, isDummy, isLandingPage, enableTeamProfile, paragraph2, isIndividualJobPage, hideTeamLink,
}) => {
  const isTabletOrMobile = root.innerWidth <= MIN_DESKTOP_BREAKPOINT_WIDTH_LIMIT
  return (
    <React.Fragment>
      <HeaderContainer>
        <Header fw="700" fs="24px" source="aboutTeam">
          {header}
        </Header>
        {!hideTeamLink && isLandingPage && (!isTabletOrMobile || isIndividualJobPage) && (<ViewTeamButton onClick={() => enableTeamProfile(true)}>{"View Team Profile >"}</ViewTeamButton>)}
      </HeaderContainer>
      <Paragraph>
        {isDummy ? (
          <>
            {paragraph}
            {paragraph2 && (
            <>
              <br />
              {paragraph2}
            </>
            )}
          </>
        ) : (
          <RichTextEditorWrapper readOnly>
            <span dangerouslySetInnerHTML={{ __html: convertToHTML(paragraph) }}></span>
          </RichTextEditorWrapper>
        )}
      </Paragraph>
    </React.Fragment>

  )
}

export default AboutTeamSection
