import {
  takeLatest,
  call,
  put,
  select,
} from "redux-saga/effects"
import root from "window-or-global"
import * as TechAssetActions from "container/TechAssets/actions"
import createToast from "dumbComponents/common/Toast/customToast"
import { isSuccess } from "services/Utils"
import { capitalize } from "lodash"
import * as ListingsApi from "./api"
import * as ListingsActions from "./actions"

function* handleValidateListingsCoversheet(action) {
  try {
    const { id } = action.data
    const res = yield call(ListingsApi.validateListingFormAPI, id)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ListingsActions.validateListingFormAction.success(response))
    }
  } catch (error) {
      createToast("Something went wrong", "error")
    yield put(ListingsActions.validateListingFormAction.failure(error))
  }
}

function* handleFetchListingStatuses() {
  try {
    const res = yield call(ListingsApi.getListingStatusMapAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ListingsActions.getBrokerageListingsStatusesAction.success(response))
    }
  } catch (error) {
      createToast("Something went wrong", "error")
    yield put(ListingsActions.getBrokerageListingsStatusesAction.failure(error))
  }
}

function* handleListingAgentUpdate(action) {
  try {
    const {
      dealId,
      payload,
      callback
    } = action.data || {}
    const res = yield call(ListingsApi.updateListingAgentInformationAPI, dealId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ListingsActions.updateListingAgentInformationAction.success(response))
      if (callback) {
        callback()
      }
    }
  } catch (error) {
    if (error?.data?.error?.dev_message) {
      const message = JSON.parse(error.data.error.dev_message)
    
      if (message?.message) {
        createToast(capitalize(message.message.replace(/_/g, " ")), "error")
      }
    } else {
      createToast("Something went wrong", "error")
    }
    yield put(ListingsActions.updateListingAgentInformationAction.failure(error))
  }
}

function* handlePostMLSSelectionUpdate(action) {
  try {
    const {
      dealId,
      payload,
    } = action.data || {}
    const res = yield call(ListingsApi.updateListingAgentInformationAPI, dealId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ListingsActions.updateListingAgentInformationPostMlsSelectionAction.success(response))
      yield put(ListingsActions.getListingDataAgentsDataPostMLSAction.request({
        dealId,
      }))
    }
  } catch (error) {
    createToast("Something went wrong", "error")
    yield put(ListingsActions.updateListingAgentInformationPostMlsSelectionAction.failure(error))
  }
}

function* handlePostMLSPropertyDetailsUpdate(action) {
  try {
    const {
      dealId,
      payload,
    } = action.data || {}
    const res = yield call(ListingsApi.updatePropertyInformationAPI, payload, dealId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ListingsActions.updatePropertyInformationPostMLSUpdateAction.success(response))
    }
  } catch (error) {
    createToast("Something went wrong", "error")
    yield put(ListingsActions.updatePropertyInformationPostMLSUpdateAction.failure(error))
  }
}

function* handleMoveToMarket(action) {
  try {
    const {
      payload,
      statusChangePayload,
    } = action.data || {}
    const query = yield select(state => state.listings.query)
    const res = yield call(ListingsApi.moveToOnMarketAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ListingsActions.moveToOnMarketAction.success(response))
      createToast("Status updated")
      yield put(ListingsActions.toggleMoveToMarketAction.call(false))
      yield put(ListingsActions.setMLSIdForOnMarketAction.call(null))
      //call api
      yield put(ListingsActions.updateBrokerageListingStatusAction.request({
        payload: statusChangePayload,
      }))
      const currentListingsData = yield select(state => state.listings.listingData)
      yield put(ListingsActions.currentDealDetailsObjectAction.call({
        ...currentListingsData,
        listingStatus: "active_listing",
      }))
      // yield put(ListingsActions.clearExistingDataAction.call())
      // yield put(ListingsActions.constructListingsQueryAction.call({
      //   query,
      // }))
    }
  } catch (error) {
    createToast("Could not be updated.", "error")
    yield put(ListingsActions.setMLSIdForOnMarketAction.call(null))
    yield put(ListingsActions.clearExistingDataAction.call())
    yield put(ListingsActions.toggleMoveToMarketAction.call(false))
    yield put(ListingsActions.moveToOnMarketAction.failure(error))
  }
}

function* handleGetListingAgentsData(action) {
  try {
    const {
      dealId,
    } = action.data || {}
    const res = yield call(ListingsApi.getListingAgentsDetailsAPI, dealId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ListingsActions.getListingAgentsDetailsAction.success(response))
    }
  } catch (error) {
      createToast("Something went wrong", "error")
    yield put(ListingsActions.getListingAgentsDetailsAction.failure(error))
  }
}

function* handleGetListingAgentDataPostMLSChange(action) {
  try {
    const {
      dealId,
    } = action.data || {}
    const res = yield call(ListingsApi.getListingAgentsDetailsAPI, dealId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ListingsActions.getListingDataAgentsDataPostMLSAction.success(response))
    }
  } catch (error) {
      createToast("Something went wrong", "error")
    yield put(ListingsActions.getListingDataAgentsDataPostMLSAction.failure(error))
  }
}

function* handleInitiateListing(action) {
  try {
    const {
      payload,
      callback,
      history,
      currentDetails,
      propertyDetails,
    } = action.data
    const res = yield call(ListingsApi.initiateListingAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      createToast("Listing created")
      createToast("Fetching additional information, please wait..")
      yield put(ListingsActions.initiateListingAction.success(response))
      yield put(ListingsActions.currentDealDetailsObjectAction.call({
        ...currentDetails,
        dealId: response.listing_id,
      }))
      if(propertyDetails){
        yield put(ListingsActions.currentDealDetailsObjectAction.call({
          ...currentDetails,
          dealId: response.listing_id,
          propertyData : {...propertyDetails}
        }))
      }
      yield put(ListingsActions.setListingIdAction.call(response.listing_id))
  
      yield put(ListingsActions.fetchAgentInformationForCoverSheetAction.request({
        agentId: currentDetails.editingAgentId,
        state: payload.state,
        dealId: response.listing_id,
      }))

      history.push(`/listings/form/${response.listing_id}`)

      if (callback) {
        callback()
      }
    }
  } catch (error) {
      createToast("Something went wrong", "error")
    yield put(ListingsActions.initiateListingAction.failure(error))
  }
}

function* handleFetchListingMappings(action) {
  try {
    const {
      state,
    } = action.data
    const res = yield call(ListingsApi.getListingMappingsAPI, state)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ListingsActions.getListingMappingsAction.success(response))
    }
  } catch (error) {
    yield put(ListingsActions.getListingMappingsAction.failure(error))
  }
}

function* handleStatusChange(action) {
  try {
    const { payload } = action.data
    //const query = yield select(state => state.listings.query)
    const query = yield select(state => state.techAssetsRarDashboard.transactionQuery)
    const res = yield call(ListingsApi.updateBrokerageListingStatusAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ListingsActions.updateBrokerageListingStatusAction.success(response))
      // yield put(ListingsActions.constructListingsQueryAction.call({
      //   query,
      // }))
      yield put(TechAssetActions.constructTransactionQueryAction.call({
        query,
      }))
      yield put(ListingsActions.toggleStatusChangeModalAction.call({
        listingID: null,
        bool: false,
        statusChangedTo: "",
      }))

      if (payload.step === "listing_expired") {
        const currentListingsData = yield select(state => state.listings.listingData)

        yield put(ListingsActions.currentDealDetailsObjectAction.call({
          ...currentListingsData,
          listingStatus: payload.step,
        }))
      }
    }
  } catch (error) {
      createToast("Something went wrong", "error")
    yield put(ListingsActions.updateBrokerageListingStatusAction.failure(error))
  }
}

function* handleUpdatePropertyInformationForListing(action) {
  console.log(action)
  try {
    const { payload, dealId, callback } = action.data
    const res = yield call(ListingsApi.updatePropertyInformationAPI, payload, dealId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ListingsActions.updatePropertyInformationAction.success(response))
      if (callback) {
        callback()
      }
    }
  } catch (error) {
    if (error?.data?.error?.dev_message) {
      const message = JSON.parse(error.data.error.dev_message)

      if (message?.message) {
        createToast(capitalize(message.message.replace(/_/g, " ")), "error")
      }
    } else {
      createToast("Something went wrong", "error")
    }
    yield put(ListingsActions.updatePropertyInformationAction.failure(error))
  }
}

function* handleUpdateSellerInformation(action) {
  try {
    const { payload, dealId, callback } = action.data
    const res = yield call(ListingsApi.updateSellerInformationForListingAPI, dealId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ListingsActions.updateSellerInformationForListingAction.success(response))
      if (callback) {
        callback()
      }
    }
  } catch (error) {
    if (error?.data?.error?.dev_message) {
      const message = JSON.parse(error.data.error.dev_message)

      if (message?.message) {
        createToast(capitalize(message.message.replace(/_/g, " ")), "error")
      }
    } else {
      createToast("Something went wrong", "error")
    }
    yield put(ListingsActions.updateSellerInformationForListingAction.failure(error))
  }
}

const LISTING_AGENT_INFORMATION = {
  name: "",
  agent_id: 0,
  email: "",
  phone: "",
  landline: null,
  listing_brokerage: "",
  brokerage_license_number: "",
  brokerage_address: "",
  apt: "",
  city: "",
  zipcode: "",
  agent_license_number: "",
  is_primary: 1,
  state: "",
}

function* handleFetchAgentsDetailsForListings(action) {
  try {
    const { state, agentId, dealId } = action.data
    const currentListingsData = yield select(redux => redux.listings.listingData)
    const res = yield call(ListingsApi.fetchAgentsInformationForListingBasedOnStateAPI, state, agentId)
    if (isSuccess(res)) {
      const { response } = res.data

      const agentsPayload = {
        agents: [],
        draft: "Y",
        current_listing_step: "agent_info",
      }

      const {
        agents,
      } = currentListingsData || []

      if (agents && agents.length > 0) {
        const primaryAgent = {
          ...LISTING_AGENT_INFORMATION,
          ...agents[0],
          ...response,
        }

        const updated = {
          ...currentListingsData,
        }

        updated.agents[0] = { ...primaryAgent }

        agentsPayload.agents = [...updated.agents]
        console.log("=== listingDate",updated )
        yield put(ListingsActions.currentDealDetailsObjectAction.call(updated))
        yield put(ListingsActions.updateListingAgentInformationPostMlsSelectionAction.request({
          dealId,
          payload: agentsPayload,
        }))
      } else {
        const primaryAgent = {
          ...LISTING_AGENT_INFORMATION,
          ...response,
        }

        const updated = {
          ...currentListingsData,
          agents: [primaryAgent],
        }

        agentsPayload.agents = [...updated.agents]
        console.log("=== listingDate===",updated )
        yield put(ListingsActions.currentDealDetailsObjectAction.call(updated))
        yield put(ListingsActions.updateListingAgentInformationPostMlsSelectionAction.request({
          dealId,
          payload: agentsPayload,
        }))
      }

      yield put(ListingsActions.fetchAgentInformationForCoverSheetAction.success(response))
    }
  } catch (error) {
    createToast("Something went wrong", "error")
    yield put(ListingsActions.fetchAgentInformationForCoverSheetAction.failure(error))
  }
}

function* handleListingTermsUpdate(action) {
  try {
    const { payload, dealId, callback } = action.data
    const res = yield call(ListingsApi.updateListingTermsForListingAPI, dealId, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ListingsActions.updateListingTermsForListingAction.success(response))
      if (callback) {
        callback()
      }
    }
  } catch (error) {
    if (error?.data?.error?.dev_message) {
      const message = JSON.parse(error.data.error.dev_message)

      if (message?.message) {
        createToast(capitalize(message.message.replace(/_/g, " ")), "error")
      }
    } else {
      createToast("Something went wrong", "error")
    }
    yield put(ListingsActions.updateListingTermsForListingAction.failure(error))
  }
}

function* handleGetListings(action) {
  try {
    const { query = "", currentQuery } = action.data
    const res = yield call(ListingsApi.getListingsAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ListingsActions.updateListingsQueryAction.call({
        query: currentQuery,
      }))
      yield put(ListingsActions.getListingsAction.success(response))
    }
  } catch (error) {
    createToast("Something went wrong", "error")
    yield put(ListingsActions.getListingsAction.failure(error))
  }
}

function* handleFetchMetrics(action) {
  try {
    const { query = "", currentQuery } = action.data
    const res = yield call(ListingsApi.getListingMetricsAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ListingsActions.updateListingsQueryAction.call({
        query: currentQuery,
      }))
      yield put(ListingsActions.getListingMetricsAction.success(response))
    }
  } catch (error) {
    createToast("Something went wrong", "error")
    yield put(ListingsActions.getListingMetricsAction.failure(error))
  }
}

//handleFetchPaginationCount
function* handleFetchPaginationCount(action) {
  try {
    const { query = "", currentQuery } = action.data
    const res = yield call(ListingsApi.getPaginationCountAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ListingsActions.updateListingsQueryAction.call({
        query: currentQuery,
      }))
      yield put(ListingsActions.getPaginationCountAction.success(response))
    }
  } catch (error) {
    createToast("Something went wrong", "error")
    yield put(ListingsActions.getPaginationCountAction.failure(error))
  }
}

function* handleListingDelete(action) {
  try {
    const { id, query, callback } = action.data
    const res = yield call(ListingsApi.deleteListingAPI, id)
    if (isSuccess(res)) {
      createToast("Success! Deleted the selected transaction.")
      const { response } = res.data
      yield put(ListingsActions.deleteListingAction.success(response))
      // yield put(ListingsActions.toggleDeleteModalAction.call({
      //   bool: false,
      //   id: "",
      // }))
      // yield put(ListingsActions.constructListingsQueryAction.call({
      //   query,
      // }))
      if (callback) { callback() }
    }
  } catch (error) {
    createToast("Something went wrong", "error")
    yield put(ListingsActions.deleteListingAction.failure(error))
  }
}

function* handleListingEnevelopeDelete(action) {
  try {
    const { envelopeIds, callback } = action.data
    const res = yield call(ListingsApi.deleteBulkListingEnevelopes, { envelopeIds })
    if (isSuccess(res)) {
      createToast("Success! Deleted Envelopes.")
      const { response } = res.data
      yield put(ListingsActions.deleteListingEnvelopeAction.success(response))
      if (callback) {
        callback()
      }
    }
  } catch (error) {
    createToast("Something went wrong", "error")
    yield put(ListingsActions.deleteListingEnvelopeAction.failure(error))
  }
}

function* handleOfferEnevelopeDelete(action) {
  try {
    const { envelopeIds, callback } = action.data
    const res = yield call(ListingsApi.deleteBulkOfferEnevelopes, { envelopeIds })
    if (isSuccess(res)) {
      createToast("Success! Deleted Envelopes.")
      const { response } = res.data
      yield put(ListingsActions.deleteOfferEnvelopeAction.success(response))
      if (callback) {
        callback()
      }
    }
  } catch (error) {
    createToast("Something went wrong", "error")
    yield put(ListingsActions.deleteOfferEnvelopeAction.failure(error))
  }
}

function* handleFetchQuery(action) {
  const {
    query,
  } = action.data

  let queryStr = ""

  if (!query?.viewType) {
    localStorage.setItem("listing_type_dropdown", "Your Listings")
  } else if (query?.viewType == "view_all") {
    localStorage.setItem("listing_type_dropdown", "All Listings")
  }

  //make string of query
  Object.keys(query).forEach((item) => {
    if (item === "forFilter") {
      queryStr += `${query[item].status ? `status=${query[item].status}&` : ""}`
    } else if (query[item] !== undefined && query[item]) {
      queryStr += `${item}=${query[item]}&`
    }
  })

  yield put(ListingsActions.getPaginationCountAction.request({
    query: queryStr,
    currentQuery: query,
  }))

  yield put(ListingsActions.getListingMetricsAction.request({
    query: queryStr,
    currentQuery: query,
  }))

  yield put(ListingsActions.getListingsAction.request({
    query: queryStr,
    currentQuery: query,
  }))
}

function* handleProcessForViewListing(action) {
  try {
    const {
      dealId,
      record,
      history,
      suppressNotifications,
      callback,
      isListingFromCRM,
    } = action.data

    if (!suppressNotifications) {
      createToast("Fetching details to view...")
    }
    yield put(ListingsActions.handleCollapseValuesAction.call(["property_information"]))

    const status = record.listingStatus || record.listing_status || record.list_status
    const agentId = record.agent_id
    const contractId = record.active_contract_id || record.contractId || null

    if (status === "pending") {
      yield put(TechAssetActions.processBeforeViewingContractAction.request({
        dealId,
      }))
      // yield put(ListingsActions.setCurrentListingTabAction.call("contract_details"))
    }

    if (status !== "incomplete") {
      yield put(ListingsActions.updateListingTabDisabledStatusAction.call({
        key: "contract_details",
        bool: false,
      }))

      yield put(ListingsActions.updateListingTabDisabledStatusAction.call({
        key: "compliance",
        bool: false,
      }))
    }

    const payload = {
      dealId,
      editingAgentId: agentId,
      contractId,
      listingStatus: status,
      propertyData: null,
      agents: null,
      sellers: null,
      listingTerms: null,
      coversheetDisabled: false,
    }

    const envelopesResponse = yield call(ListingsApi.fetchEnvelopeListingsAPI, dealId)
    const currentListingStatusResponse = yield call(ListingsApi.validateListingFormAPI, dealId)
    const properyInformationResponse = yield call(ListingsApi.getListingPropertyInformationAPI, dealId)
    const listingAgentInformationResponse = yield call(ListingsApi.getListingAgentsDetailsAPI, dealId)
    const sellersInformationResponse = yield call(ListingsApi.getSellerInformationForListingAPI, dealId)
    const listingTermsResponse = yield call(ListingsApi.getListingTermsDetailsAPI, dealId)

    if (isSuccess(envelopesResponse)) {
      const { response } = envelopesResponse.data

      if (response && response.length > 0) {
        yield put(ListingsActions.fetchEnvelopeListingsAction.request({
          type: "listings",
          listingId: dealId,
        }))
      }
    }

    let fetchedPropertyData = null
    let fetchedAgentsData = null
    let fetchedSellersData = null
    let listingTermsData = null
    if (isSuccess(properyInformationResponse)) {
      const { response } = properyInformationResponse.data
      fetchedPropertyData = { ...response }
    }

    if (isSuccess(listingAgentInformationResponse)) {
      const { response } = listingAgentInformationResponse.data
      if (response && response.agents) {
        fetchedAgentsData = [...response.agents]
      }
    }

    if (isSuccess(sellersInformationResponse)) {
      const { response } = sellersInformationResponse.data
      if (response && response.sellers) {
        fetchedSellersData = [...response.sellers]
      }
    }

    if (isSuccess(listingTermsResponse)) {
      const { response } = listingTermsResponse.data
      listingTermsData = { ...response }
    }

    if (isSuccess(currentListingStatusResponse)) {
      const { response } = currentListingStatusResponse.data
      const {
        current_contract_step,
        mlsUniqueId
      } = response || {}
      const enabledStates = ["incomplete", "audit_verified", "submitted", "contract_canceled", "archived"]
      payload.coversheetDisabled = current_contract_step
      && !enabledStates.includes(current_contract_step)
      if(fetchedPropertyData){
        fetchedPropertyData.mls_unique_id = mlsUniqueId
      }
    }
    if (fetchedPropertyData) {
      payload.propertyData = { ...fetchedPropertyData}
      let listingState = fetchedPropertyData.state
      listingState = listingState.toLowerCase()
      yield put(ListingsActions.setCurrentListingsStateAction.call(listingState))
    }

    if (fetchedAgentsData) {
      payload.agents = [...fetchedAgentsData]
    }

    if (fetchedSellersData) {
      payload.sellers = [...fetchedSellersData]
    }

    if (listingTermsData) {
      payload.listingTerms = { ...listingTermsData }
    }

    if (!isListingFromCRM) {
      yield put(ListingsActions.togglePropertyFormOrCardViewAction.call(true))
    } else if (isListingFromCRM) {
      yield put(ListingsActions.setListingCardFromCRMAction.call(true))
    }
    yield put(ListingsActions.performNecessaryActionsBeforeLoadingListingAction.success({
      success: true,
    }))
    // yield put(ListingsActions.clearExistingDataAction.call())
    console.log("===performNecessaryActionsBe", payload)
    yield put(ListingsActions.currentDealDetailsObjectAction.call({
      ...payload,
    }))
    yield put(ListingsActions.setListingIdAction.call(dealId))
    if (!suppressNotifications) {
      createToast("Success!")
    }
    if(suppressNotifications){
      yield put(ListingsActions.setCurrentListingTabAction.call("contract_details"))
    }

    if (callback) {
      callback(record)
    }
    yield put(ListingsActions.setIsLoadingViewListingPageAction.call(false))

    //yield put(ListingsActions.handleCollapseValuesAction.call(["property_information", "listing_agent_information", "seller_information", "listing_terms"]))
    if (history) {
      history.push(`/listings/form/${dealId}`)
    }
  } catch (e) {
    createToast("Something went wrong", "error")
    yield put(ListingsActions.performNecessaryActionsBeforeLoadingListingAction.failure(e))
  }
}

function* handleValidateAndSubmit(action) {
  try {
    const {
      dealId,
      propertyPayload,
      agentsPayload,
      sellersPayload,
      listingTermsPayload,
      callback
    } = action.data || {}

    const validateResponse = yield call(ListingsApi.validateListingFormAPI, dealId)
    createToast("Validating coversheet")

    if (isSuccess(validateResponse)) {
      const { response } = validateResponse.data
      const {
        agentsInformation,
        listingTermsInformation,
        propertyInformation,
        sellersInformation,
        // listingStatus,
      } = response || {}

      if (agentsInformation === 0
        && listingTermsInformation === 0
        && propertyInformation === 0
        && sellersInformation === 0) {
        createToast("Coversheet is being submitted.")
        if (callback) {
          callback()
        }
        // yield put(ListingsActions.updatePropertyInformationAction.request({ dealId, payload: propertyPayload }))
        // yield put(ListingsActions.updateListingAgentInformationAction.request({ dealId, payload: agentsPayload }))
        // yield put(ListingsActions.updateSellerInformationForListingAction.request({ dealId, payload: sellersPayload }))
        // yield put(ListingsActions.updateListingTermsForListingAction.request({ dealId, payload: listingTermsPayload }))
        /**Just enable the compliance and contracts*/
        //yield put(ListingsActions.viewListingContractAction.call(true))
        yield put(ListingsActions.viewComplianceForListingAction.call(true))
        //update the tab here
        yield put(ListingsActions.updateListingTabDisabledStatusAction.call({
          key: "contract_details",
          bool: false,
        }))
        yield put(ListingsActions.updateListingTabDisabledStatusAction.call({
          key: "compliance",
          bool: false,
        }))
        const existingListingData = yield select(state => state.listings.listingData)
        const updated = {
          ...existingListingData,
          listingStatus: "coming_soon",
          propertyDataErrorCount: null,
          agentsDataErrorCount: null,
          sellersDataErrorCount: null,
          listingTermsErrorCount: null,
        }
        yield put(ListingsActions.currentDealDetailsObjectAction.call(updated))
        createToast("Success!")
      } else {
        root.scrollTo(0, 0)
        const existingListingData = yield select(state => state.listings.listingData)
        const updated = {
          ...existingListingData,
          dealId,
          propertyDataErrorCount: propertyInformation,
          agentsDataErrorCount: agentsInformation,
          sellersDataErrorCount: sellersInformation,
          listingTermsErrorCount: listingTermsInformation,
        }
        yield put(ListingsActions.currentDealDetailsObjectAction.call(updated))
        createToast("Please check all the fields", "error")
      }
      yield put(ListingsActions.validateAndSubmitAction.success(response))
    }
  } catch (e) {
    createToast("Something went wrong", "error")
    yield put(ListingsActions.validateAndSubmitAction.failure(e))
  }
}

function* handleDeletionOfCoversheetFields(action) {
  try {
    const {
      propertyPayload,
      listingAgentPayload = [],
      dealId,
      callback,
    } = action.data || {}

    const updatingPropertyInformationRes = yield call(ListingsApi.updatePropertyInformationAPI, propertyPayload, dealId)

    if (isSuccess(updatingPropertyInformationRes)) {
      if (callback) {
        callback()
      }
      yield put(ListingsActions.processDeletionOfFieldsInCoversheetAction.success({
        success: true,
      }))
    }
  } catch (e) {
    createToast("Something went wrong", "error")
    yield put(ListingsActions.processDeletionOfFieldsInCoversheetAction.failure(e))
  }
}

function* handleFetchChecklistMetadata(actions) {
  try {
    const {
      type,
      payload
    } = actions.data
    let queryStr=""
    Object.keys(payload).forEach((item) => {
      if (payload[item] !== undefined && payload[item]) {
        queryStr += `${item}=${payload[item]}&`
      }
    })
    const res = yield call(ListingsApi.fetchChecklistMetadataAPI, queryStr)
    if (isSuccess(res)) {
      const { response } = res.data
      if(type == "checklistTemplateMetadata"){
        const updatedResponse = response.map(item => ({
          ...item,
          label: item.checklist_template,
          value: item.checklist_template_value,
        }));
        yield put(ListingsActions.fetchChecklistMetadataAction.success(updatedResponse))
      } else{
        yield put(ListingsActions.fetchChecklistMetadataAction.success(response))
      }
    }
  } catch (error) {
    createToast("Something went wrong", "error")
    yield put(ListingsActions.fetchChecklistMetadataAction.failure(error))
  }
}

function* handleFetchChecklistTemplate(actions){
  try {
    const {
      type,
      offerId,
      listingId,
      callback,
    } = actions.data
    let res
    if(type === "offers") {
      res = yield call(ListingsApi.fetchChecklistTemplateOffersAPI, offerId)
    } else {
      res = yield call(ListingsApi.fetchChecklistTemplateAPI, listingId)
    }
    if (isSuccess(res)) {
      const { response } = res.data
      if(Object.keys(response).length > 0){
        const filterResponse = response.data
        filterResponse.forEach((item) => {
          item.document_items.forEach((documentItem)=> {
          documentItem.isSelected = false
        })
      })
      yield put(ListingsActions.updateChecklistObjectAction.call(filterResponse))
      } else {
        yield put(ListingsActions.updateChecklistObjectAction.call(null))
      }
      yield put(ListingsActions.fetchChecklistTemplateAction.success(response))
      if(callback) callback(response)
    }
  } catch (error){
    createToast("Something went wrong", "error")
    yield put(ListingsActions.fetchChecklistTemplateAction.failure(error))
  }
}

function* handleUpdateChecklistTemplateMetadata(actions) {
  try {
    const {
      type,
      payload,
      callback
    } = actions.data
    let res
    console.log(payload)
    if(type == "offers") {
      res = yield call(ListingsApi.updateChecklistTemplateOffersMetadataAPI,payload)
    } else {
      res = yield call(ListingsApi.updateChecklistTemplateMetadataAPI,payload)
    }
    console.log(res)
    createToast("Fetching checklists...")
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ListingsActions.updateChecklistTemplateMetadataAction.success(response))
      if(callback) callback()
    }

  } catch (e){
    createToast("Something went wrong", "error")
    yield put(ListingsActions.updateChecklistTemplateMetadataAction.failure(error))
  }
}

function* handleFetchEnvelopeListings(actions) {
  try {
    const {
      type,
      offerId,
      listingId
    } = actions.data
    let res
    if(type == "offers"){
      res = yield call(ListingsApi.fetchEnvelopeOffersAPI,offerId)
    } else {
      res = yield call(ListingsApi.fetchEnvelopeListingsAPI, listingId)
    }
    if(isSuccess(res)) {
      const {response} = res.data
      if(response && response.length > 0){
        let iter = 0;
        const envelopeListingsObjectTemp = response.reduce((acc, item) => {
          if (item.status === "active") {
            let key = ""
            if(item.document_item) {
              key = item.document_item.toLowerCase().replace(/\s+/g, '_');
            } else {
              key = "document_item"
            }
            if (!acc[key]) {
                acc[key] = [];
            }
            iter++;
            item.name = item.name || item.title || `Your Document ${iter}`;
            item.isSelected = false;
            item.showFillAndSign = false;
            acc[key].push(item);
        }
        return acc;
      }, {});
      yield put(ListingsActions.updateEnvelopeObjectAction.call(envelopeListingsObjectTemp))
      }
      else{
        yield put(ListingsActions.updateEnvelopeObjectAction.call([]))
      }
      yield put(ListingsActions.fetchEnvelopeListingsAction.success(response))
      
    }
  } catch(error) {
    createToast("Something went wrong", "error")
    yield put(ListingsActions.fetchEnvelopeListingsAction.failure(error))
  }
}

function* handleFetchMergedEnvelopes(actions){
  try{
    const {
      type,
      offerId,
      listingId,
    } = actions.data
    let res
    if(type=="offers"){
      res = yield call(ListingsApi.fetchEnvelopeOffersAPI,offerId)
    } else{
      res = yield call(ListingsApi.fetchEnvelopeListingsAPI, listingId)
    }
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      let flag = false;
      if (response && response.length > 0) {
        const filtered = response.filter(item => item.status !== "active" && item.status !== "created")
        filtered.forEach((item) => {
          item.isSelected = false
          item.showFillAndSign = false
          item.date = item.created_at
          if(item.status === "completed"){
            flag=true;
          }
        })
        filtered.sort((a, b) => {
          const dateA = new Date(a.updated_at);
          const dateB = new Date(b.updated_at);
          return dateB - dateA; 
        })
        //yield put(ListingsActions.toggleMultipleEnvelopeShareAction.call(flag))
        yield put(ListingsActions.updateMergeEnvelopeObjectAction.call(filtered))
      }

      yield put(ListingsActions.fetchMergedEnvelopesAction.success(response))
    }

  } catch(error) {
    createToast("Something went wrong", "error")
    yield put(ListingsActions.fetchMergedEnvelopesAction.failure(error))
  }
}

function* handleReceiverViewForListings(action) {
  try {
    const {
      type,
      payload,
    } = action.data
    let res
    if(type == "offers"){
      res = yield call(ListingsApi.createReceiverOffersViewV2API,payload)
    }else{
      res = yield call(ListingsApi.createReceiverListingViewV2API, payload)
    }
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      console.log("response", response)
      root.open(response.url, "_blank")
      yield put(ListingsActions.createReceiverListingViewV2Action.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    yield put(ListingsActions.createReceiverListingViewV2Action.failure(e))
  }
}

function* handleFetchListingPackageDocuments(action) {
  try {
    const {
      offerId,
      type,
      listingId,
      payload,
      callback,
    } = action.data
    let res 
    if(type == "offers"){
      res = yield call(ListingsApi.fetchOfferChecklistDocumentsAPI, payload)
    } else {
      res = yield call(ListingsApi.fetchListingPackageDocumentsAPI, payload)
    }
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      //const envelopes = response.filter(item => item.type === "docusign_envelope")
      const envelopes = response

      const payload = {
        listing_package_to_recipient: "",
        listing_package_subject: "",
        custom_message: "",
        envelopes: [...envelopes]
      }

      yield put(ListingsActions.updateListingPackageDetailsAction.call(payload))
      yield put(ListingsActions.fetchListingPackageDocumentsAction.success(response))
      if (callback) {
        callback()
      }
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    yield put(ListingsActions.fetchListingPackageDocumentsAction.failure(e))
  }
}

function* handleSendListingPackageMail(action) {
  try {
    const {
      payload,
      type,
    } = action.data
    let res 
    if(type == "offers"){
      res = yield call(ListingsApi.sendOfferChecklistAPI, payload)
    } else {
      res = yield call(ListingsApi.sendListingPackageMailAPI, payload)
    }
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      if (type == "offers"){
        createToast("Package sent.")
      } else {
        createToast("Package sent.")
      }
      

      yield put(ListingsActions.sendListingPackageMailAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    yield put(ListingsActions.sendListingPackageMailAction.failure(e))
  }
}

function* handleFetchOfferDocsList(action) {
  try {
    const {
      state,
    } = action.data
    const res = yield call(ListingsApi.fetchOfferDocumentsAPI, state)
    console.log(res)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      response.forEach((item) => {
        item.isSelected = false
      })
      yield put(ListingsActions.updateOfferDocsListViewAction.call(response))

      yield put(ListingsActions.fetchOfferDocumentsListAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    yield put(ListingsActions.fetchOfferDocumentsListAction.failure(e))
  }
}

function* handleListingDocumentsFetch(action) {
  try {
    const {
      state,
    } = action.data
    const res = yield call(ListingsApi.fetchListingDocumentsAPI, state)
    console.log(res)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      console.log(response)
      response.forEach((item) => {
        item.isSelected = false
      })

      yield put(ListingsActions.updateListingDocsAction.call(response))
      yield put(ListingsActions.fetchListingDocumentsListAction.success(response))
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    yield put(ListingsActions.fetchListingDocumentsListAction.failure(e))
  }
}

function* handleFetchLibraryUploads(action) {
  try {
    let query = ""

    if (action?.data?.payload) {
      Object.keys(action.data.payload).forEach((dt) => {
        query += `${dt}=${action.data.payload[dt]}&`
      })
    }

    const res = yield call(ListingsApi.fetchLibraryUploadsAPI, query)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data || {}

      if (response) {
        const payload = {
          sharedWithMe: [],
          teamDocs: [],
          transactionDocs: [],
        }

        payload.sharedWithMe = [...response.for_own_use]
        payload.sharedWithMe.forEach((item, iter) => {
          item.name = item.title || `Personal Document ${iter + 1}`
          item.isSelected = false
        })

        payload.teamDocs = [...response.shared_with_team]
        payload.teamDocs.forEach((item, iter) => {
          item.name = item.title || `Team Document ${iter + 1}`
          item.isSelected = false
        })

        payload.transactionDocs = [...response.transaction_documents]
        payload.transactionDocs.forEach((item, iter) => {
          item.name = item.title || `Transaction Document ${iter + 1}`
          item.isSelected = false
        })

        yield put(ListingsActions.updateUploadedDocsListObjectAction.call(payload))
      }

      yield put(ListingsActions.fetchLibraryUploadedFilesListAction.success(response))
    }
  } catch (error) {
    yield put(ListingsActions.fetchLibraryUploadedFilesListAction.failure(error))
  }
}
function* handleUploadToLibrary(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(ListingsApi.updateLibraryUploadsAPI, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      yield put(ListingsActions.updateLibraryUploadsListAction.success(response))

      if (payload.metadata && payload.metadata.transaction_type && payload.metadata.transaction_id) {
        yield put(ListingsActions.fetchLibraryUploadedFilesListAction.request({
          payload: {
            transaction_type: payload.metadata.transaction_type,
            transaction_id: payload.metadata.transaction_id,
          },
        }))
      } else if (payload.transaction_type && payload.transaction_id) {
        yield put(ListingsActions.fetchLibraryUploadedFilesListAction.request({
          payload: {
            transaction_type: payload.transaction_type,
            transaction_id: payload.transaction_id,
          },
        }))
      } else {
        yield put(ListingsActions.fetchLibraryUploadedFilesListAction.request())
      }
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    yield put(ListingsActions.updateLibraryUploadsListAction.failure(e))
  }
}
function* handleDeleteFileFromLibrary(action) {
  try {
    const {
      id,
      transaction_type,
      transaction_id,
    } = action.data
    const res = yield call(ListingsApi.deleteLibraryUploadedFileAPI, id)

    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      yield put(ListingsActions.deleteLibraryUploadedFileListAction.success(response))
      createToast("Document deleted.")

      if (transaction_type && transaction_id) {
        yield put(ListingsActions.fetchLibraryUploadedFilesListAction.request({
          payload: {
            transaction_type,
            transaction_id,
          },
        }))
      } else {
        yield put(ListingsActions.fetchLibraryUploadedFilesListAction.request())
      }
    }
  } catch (e) {
    // const errorMessage = get(e, "data.response.error") || "Something went wrong, Please try again later!"
    // createToastNew.error(errorMessage, toastConfig)
    console.log(e)
    yield put(ListingsActions.deleteLibraryUploadedFileListAction.failure(e))
  }
}
function* handleUpdateEnvelopes(action) {
  try {
    const {
      type,
      payload,
      id,
      callBack,
    } = action.data
    let res 
    if(type == "offers"){
      res = yield call(ListingsApi.updateEnvelopesOfferChecklistAPI, payload)
    } else {
      res = yield call(ListingsApi.updateEnvelopesAPI, payload)
    }
    console.log(res)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      console.log(response)

      yield put(ListingsActions.updateEnvelopesAction.success(response))
      //yield put(ListingsActions.toggleSideNavAction.call("templates"))
      if (callBack) {
        callBack()
        // yield put(ListingsActions.fetchEnvelopeListingsAction.request({
        //   listingId:id
        // }))
        if(type == "offers"){
          yield put(ListingsActions.fetchEnvelopeListingsAction.request({
            type,
            offerId:id
          }))
        }else{
          yield put(ListingsActions.fetchEnvelopeListingsAction.request({
            type,
            listingId:id
          }))
        }
      }
    }
  } catch (e) {
    console.log(e)
    yield put(ListingsActions.updateEnvelopesAction.failure(e))
  }
}
function* handleCreateEnvelopeCheckList(action){
  try {
    const{
      type,
      id,
      payload,
      callback,
    } = action.data
    // createToast("Creating Envelopes...")
    let res
    if(type == "offers"){
      res = yield call(ListingsApi.createEnvelopeChecklistOffersAPI, payload)
    } else {
      res = yield call(ListingsApi.createEnvelopeChecklistAPI, payload)
    }
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      yield put(ListingsActions.createEnvelopeChecklistAction.success(response))
      if (callback) {
        callback()
        if(type == "offers"){
          yield put(ListingsActions.fetchEnvelopeListingsAction.request({
            type,
            offerId:id
          }))
        }else{
          yield put(ListingsActions.fetchEnvelopeListingsAction.request({
            type,
            listingId:id
          }))
        }
        
      }
    }
  }
  catch (error) {
    createToast("Something went wrong", "error")
    yield put(ListingsActions.createEnvelopeChecklistAction.failure(error))
  }
}

function* handleRefreshListingDocuments(actions) {
  try {
    const {
      offerId,
      listingId,
    } = actions.data
    let res
    if(offerId){
      res = yield call(ListingsApi.fetchEnvelopeOffersAPI, offerId)
    } else if(listingId){
      res = yield call(ListingsApi.fetchEnvelopeListingsAPI, listingId)
    }
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      if (!response.length && actions.data.callback) {
        actions.data.callback()
      }
      let flag = false;
      if (response && response.length > 0) {
        const filtered = response.filter(item => item.status !== "active" && item.status !== "created")
        filtered.forEach((item) => {
          item.isSelected = false
          item.showFillAndSign = false
          item.date = item.created_at
          if(item.status === "completed"){
            flag=true;
          }
        })
        filtered.sort((a, b) => {
          const dateA = new Date(a.updated_at);
          const dateB = new Date(b.updated_at);
          return dateB - dateA; 
        })
        //yield put(ListingsActions.toggleMultipleEnvelopeShareAction.call(flag))
        yield put(ListingsActions.updateMergeEnvelopeObjectAction.call(filtered))
      }
      console.log("======response==>>>>", response)
      yield put(ListingsActions.refreshListingsV2DocumentsAction.success(response))
      yield put(ListingsActions.fetchMergedEnvelopesAction.success(response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.message") || "Something went wrong, Please try again later!"
    customToast(errorMessage)
    yield put(ListingsActions.refreshListingsV2DocumentsAction.failure(e))
    yield put(ListingsActions.fetchMergedEnvelopesAction.failure(e))
  }
}

function* handleSendOffersChecklistDocuments(action) {
  try {
    const {
      payload,
      callback,
    } = action.data

    const res = yield call(ListingsApi.sendOffersChecklistDocumentsAPI, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      console.log("===res.data===", res.data)
      yield put(ListingsActions.sendOffersChecklistDocumentsAction.success(response))
      if (callback) {
        callback()
      }
    }
  } catch (error) {
    console.log("===error===", error)
    customToast("something went wrong")
    yield put(ListingsActions.sendOffersChecklistDocumentsAction.failure(response))
  }
}

function* handleSendReminderListing(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(ListingsApi.sendReminderListingAPI, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      console.log("response", response)

      yield put(ListingsActions.sendReminderListingAction.success(response))
    }
  } catch (e) {
    console.log(e)
    yield put(ListingsActions.sendReminderListingAction.failure(e))
  }
}

function* handleSendReminderOfferChecklist(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(ListingsApi.sendReminderOfferChecklistAPI, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      console.log("response", response)

      yield put(ListingsActions.sendReminderOfferChecklistAction.success(response))
    }
  } catch (e) {
    console.log(e)
    yield put(ListingsActions.sendReminderOfferChecklistAction.failure(e))
  }
}

function* handleViewListingPersistence(action) {
  try {
    const {
      id,
      history,
      callback,
      showToastsWhenCreatingListingFromCRM,
      isListingFromCRM = false,
    } = action.data

    if (showToastsWhenCreatingListingFromCRM) {
      createToast("Creating a listing, please wait...", "success")
    }

    const res = yield call(ListingsApi.validateListingFormAPI, id)

    if (isSuccess(res)) {
      const { response } = res.data
      const payload = {
        dealId: id,
        isListingFromCRM,
        record: {
          ...response,
          list_status: response.list_status || response.listing_status || "incomplete",
          agent_id: response.primaryAgent.agent_id,
          active_contract_id: response.active_contract_id || null,
          contractId: response.active_contract_id || null,
        },
        history,
        callback: showToastsWhenCreatingListingFromCRM ? () => {
          createToast("Listing created. Viewing the listing now...", "success")
        } : callback,
      }

      yield put(ListingsActions.viewListingPersistenceAction.success(response))
      yield put(ListingsActions.clearExistingDataAction.call())
      yield put(ListingsActions.performNecessaryActionsBeforeLoadingListingAction.request(payload))
    }
  } catch (error) {
    createToast("Something went wrong", "error")

    yield put(ListingsActions.validateListingFormAction.failure(error))
  }
}

export default function* main() {
  yield takeLatest(ListingsActions.getListingsAction.REQUEST, handleGetListings)
  yield takeLatest(ListingsActions.constructListingsQueryAction.type, handleFetchQuery)
  yield takeLatest(ListingsActions.getListingMetricsAction.REQUEST, handleFetchMetrics)
  yield takeLatest(ListingsActions.getPaginationCountAction.REQUEST, handleFetchPaginationCount)
  yield takeLatest(ListingsActions.deleteListingAction.REQUEST, handleListingDelete)
  yield takeLatest(ListingsActions.deleteListingEnvelopeAction.REQUEST, handleListingEnevelopeDelete)
  yield takeLatest(ListingsActions.deleteOfferEnvelopeAction.REQUEST, handleOfferEnevelopeDelete)
  yield takeLatest(ListingsActions.initiateListingAction.REQUEST, handleInitiateListing)
  yield takeLatest(ListingsActions.getListingMappingsAction.REQUEST, handleFetchListingMappings)
  yield takeLatest(ListingsActions.updatePropertyInformationAction.REQUEST, handleUpdatePropertyInformationForListing)
  yield takeLatest(ListingsActions.validateListingFormAction.REQUEST, handleValidateListingsCoversheet)
  yield takeLatest(ListingsActions.getBrokerageListingsStatusesAction.REQUEST, handleFetchListingStatuses)
  yield takeLatest(ListingsActions.updateBrokerageListingStatusAction.REQUEST, handleStatusChange)
  yield takeLatest(ListingsActions.updateListingAgentInformationAction.REQUEST, handleListingAgentUpdate)
  yield takeLatest(ListingsActions.updateListingAgentInformationPostMlsSelectionAction.REQUEST, handlePostMLSSelectionUpdate)
  yield takeLatest(ListingsActions.getListingAgentsDetailsAction.REQUEST, handleGetListingAgentsData)
  yield takeLatest(ListingsActions.getListingDataAgentsDataPostMLSAction.REQUEST, handleGetListingAgentDataPostMLSChange)
  yield takeLatest(ListingsActions.performNecessaryActionsBeforeLoadingListingAction.REQUEST, handleProcessForViewListing)
  yield takeLatest(ListingsActions.updatePropertyInformationPostMLSUpdateAction.REQUEST, handlePostMLSPropertyDetailsUpdate)
  yield takeLatest(ListingsActions.updateSellerInformationForListingAction.REQUEST, handleUpdateSellerInformation)
  yield takeLatest(ListingsActions.updateListingTermsForListingAction.REQUEST, handleListingTermsUpdate)
  yield takeLatest(ListingsActions.validateAndSubmitAction.REQUEST, handleValidateAndSubmit)
  yield takeLatest(ListingsActions.fetchAgentInformationForCoverSheetAction.REQUEST, handleFetchAgentsDetailsForListings)
  yield takeLatest(ListingsActions.moveToOnMarketAction.REQUEST, handleMoveToMarket)
  yield takeLatest(ListingsActions.fetchChecklistMetadataAction.REQUEST, handleFetchChecklistMetadata)
  yield takeLatest(ListingsActions.updateChecklistTemplateMetadataAction.REQUEST, handleUpdateChecklistTemplateMetadata)
  yield takeLatest(ListingsActions.fetchChecklistTemplateAction.REQUEST, handleFetchChecklistTemplate)
  yield takeLatest(ListingsActions.fetchEnvelopeListingsAction.REQUEST, handleFetchEnvelopeListings)
  yield takeLatest(ListingsActions.fetchMergedEnvelopesAction.REQUEST, handleFetchMergedEnvelopes)
  yield takeLatest(ListingsActions.createReceiverListingViewV2Action.REQUEST, handleReceiverViewForListings)
  yield takeLatest(ListingsActions.fetchListingPackageDocumentsAction.REQUEST,handleFetchListingPackageDocuments)
  yield takeLatest(ListingsActions.fetchOfferDocumentsListAction.REQUEST, handleFetchOfferDocsList)
  yield takeLatest(ListingsActions.fetchListingDocumentsListAction.REQUEST, handleListingDocumentsFetch)
  yield takeLatest(ListingsActions.fetchLibraryUploadedFilesListAction.REQUEST, handleFetchLibraryUploads)
  yield takeLatest(ListingsActions.updateLibraryUploadsListAction.REQUEST, handleUploadToLibrary)
  yield takeLatest(ListingsActions.deleteLibraryUploadedFileListAction.REQUEST, handleDeleteFileFromLibrary)
  yield takeLatest(ListingsActions.updateEnvelopesAction.REQUEST, handleUpdateEnvelopes)
  yield takeLatest(ListingsActions.createEnvelopeChecklistAction.REQUEST, handleCreateEnvelopeCheckList)
  yield takeLatest(ListingsActions.processDeletionOfFieldsInCoversheetAction.REQUEST, handleDeletionOfCoversheetFields)
  yield takeLatest(ListingsActions.sendListingPackageMailAction.REQUEST, handleSendListingPackageMail)
  yield takeLatest(ListingsActions.refreshListingsV2DocumentsAction.REQUEST, handleRefreshListingDocuments)
  yield takeLatest(ListingsActions.sendOffersChecklistDocumentsAction.REQUEST, handleSendOffersChecklistDocuments)
  yield takeLatest(ListingsActions.sendReminderListingAction.REQUEST, handleSendReminderListing)
  yield takeLatest(ListingsActions.sendReminderOfferChecklistAction.REQUEST, handleSendReminderOfferChecklist)
  yield takeLatest(ListingsActions.viewListingPersistenceAction.REQUEST, handleViewListingPersistence)
}

