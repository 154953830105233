import styled, { css } from "styled-components"
import colors from "@colors"
import { hexRgba } from "library/cssUtils"
import { ATC } from "@fonts"

const btnColors = {
  primary: {
    backgroundColor: colors.primaryColor,
    fontColor: colors.white,
    fontHoverColor: colors.primaryHover,
    borderColor: colors.primaryColor,
    boxShadowColor: colors.primaryColor,
    disabledColor: colors.disabledColor,
  },
  secondary: {
    backgroundColor: colors.white,
    fontColor: colors.primaryColor,
    fontHoverColor: colors.primaryHover,
    borderColor: colors.primaryColor,
    boxShadowColor: false,
    disabledColor: colors.disabledColor,
  },
  tertiary: {
    backgroundColor: colors.white,
    fontColor: colors.redText,
    fontHoverColor: colors.redText,
    borderColor: colors.redText,
    boxShadow: false,
    disabledColor: colors.disabledColor,
  },
  attention: {
    backgroundColor: colors.redText,
    fontColor: colors.white,
    fontHoverColor: colors.white,
    borderColor: colors.redText,
    boxShadow: false,
    disabledColor: colors.disabledColor,
  },
  black: {
    backgroundColor: colors.contentText,
    fontColor: colors.white,
    fontHoverColor: colors.white,
    borderColor: colors.contentText,
    boxShadow: false,
    disabledColor: colors.disabledColor,
  },
  blackBtn: {
    backgroundColor: colors.contentText,
    fontColor: colors.white,
    fontHoverColor: colors.white,
    borderRadius: "4px",
    boxShadow: false,
  },
  darkPurple: {
    backgroundColor: colors.darkPurple,
    fontColor: colors.white,
    fontHoverColor: colors.white,
    borderRadius: "4px",
    boxShadow: false,
  },
  blueFadedBG: {
    backgroundColor: colors.whiteBlueFaded,
    fontColor: colors.black,
    fontHoverColor: colors.black,
    borderRadius: "4px",
    boxShadow: false,
  },
  blueFadedTX: {
    backgroundColor: colors.darkPurple,
    fontColor: colors.whiteBlueFaded,
    fontHoverColor: colors.whiteBlueFaded,
    borderRadius: "4px",
    boxShadow: false,
  },
  white: {
    backgroundColor: colors.white,
    fontColor: colors.textColor,
    fontHoverColor: colors.white,
    borderRadius: "4px",
    boxShadow: false,
  },
  lightPurpleOutlined: {
    backgroundColor: colors.white,
    fontColor: colors.deepPurple,
    fontHoverColor: colors.deepPurple,
    borderRadius: "100px",
    borderWidth: "2px",
    boxShadow: false,
    borderColor: colors.deepPurple,
    padding: "16px",
  },
  lightPurpleFill: {
    fontColor: colors.white,
    backgroundColor: colors.deepPurple,
    borderRadius: "100px",
    borderWidth: "2px",
    boxShadow: false,
    padding: "16px",
    disabledColor: colors.disabledColor,
  },
  marineBLueFill: {
    backgroundColor: colors.new_primary,
    fontColor: colors.white,
    fontHoverColor: colors.whiteBlueFaded,
    borderRadius: "4px",
    boxShadow: false,
    disabledColor: colors.disabledColor,
  },
  marineBLueOutlined: {
    backgroundColor: colors.white,
    fontColor: colors.new_primary,
    fontHoverColor: colors.whiteBlueFaded,
    borderColor: colors.new_primary,
    borderRadius: "4px",
    boxShadow: false,
  },
  primaryV2: {
    fontColor: colors.white,
    backgroundColor: colors.new_primary,
    borderRadius: "100px",
    borderWidth: "2px",
    boxShadow: false,
    padding: "16px",
    disabledColor: colors.disabledColor,
  },
  blackAndWhite: {
    backgroundColor: colors.white,
    fontColor: colors.black,
    fontHoverColor: colors.black,
    borderRadius: "0px",
    boxShadow: false,
  },
}

const Button = styled.button`
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: none;
  cursor: pointer;
  font-family: ${ATC};
  height: ${props => props.height};
  width: ${props => (props.width ? props.width : "auto")};
  line-height: normal;
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  color: ${props => btnColors[props.bsStyle].fontColor || "black"};
  border: ${props => (props.border ? `${props.borderWidth} solid ${btnColors[props.bsStyle].borderColor}` : "none")};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : "25px")};
  background-color: ${props => btnColors[props.bsStyle].backgroundColor};
  box-shadow: ${props => props.boxShadow && btnColors[props.bsStyle].boxShadowColor
    ? `24px 12px 30px 0 ${hexRgba(btnColors[props.bsStyle].boxShadowColor, 0.23)}`
    : "none"};

  ${props => props.size === "large"
    && `
      padding: 15px 40px;
    `}
  ${props => props.size === "small" && `
      padding: 8px;
    `}
  &:hover,
  &:active {
    border-color: ${props => btnColors[props.bsStyle].borderColor};
    opacity: 0.8;
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
    border: ${props => `1px solid ${btnColors[props.bsStyle].disabledColor}`} !important;
    background-color: ${props => btnColors[props.bsStyle].disabledColor} !important;
    color: ${props => btnColors[props.bsStyle].fontColor} !important;
  }

  &:disabled:hover {
    border: ${props => `1px solid ${btnColors[props.bsStyle].disabledColor}`};
    background-color: ${props => btnColors[props.bsStyle].disabledColor};
    color: ${props => btnColors[props.bsStyle].fontColor};
  }
`

Button.defaultProps = {
  bsStyle: "primary",
  boxShadow: false,
  border: true,
  borderWidth: "2px",
  width: "200px",
  padding: "8px 12px",
  fontSize: "16px",
  fontColor: "black",
}

export default Button
