import React from "react"
import root from "window-or-global"
import NewFeature from "images/newFeature.png"
import Cookies from "react-cookies"
import {
  InnerTab,
  NavLink,
} from "./commonStyles"
import ReferAndEarn from "../Icons/NewIcons/ReferAndEarn"

const JoinClientApp = ({
  routeArray,
  isBroker,
}) => {
  const isSubscribedToNetwork = Cookies.load("isSubscribedToNetworkPlan")
  const isSubscribedToNetworkPlan = isSubscribedToNetwork == "false" ? false : true
  console.log("isSubscribedToNetworkPlan", isSubscribedToNetworkPlan)
  return (
    <>
      {
        !isSubscribedToNetworkPlan ? (
          <InnerTab
            isClientActive
          >
            <div className="icon-block-new">
              <ReferAndEarn />
            </div>
            <div className="text-block">
              <NavLink
                to="/realty/join-client-app"
                isActive={routeArray[1] === "join-client-app"}
                onClick={() => {
                  root.scrollTo(0, 0)
                //   if (root.heap) {
                //     root.heap.track("click_sidebar_recruitAndEarnRAR_web")
                //   }
                }}
              >
                Get Radius Client
              </NavLink>
            </div>
          </InnerTab>
        ) : (
          <></>
        )
      }
    </>
  )
}

export default JoinClientApp
