import React, { useState } from "react"
import styled from "styled-components"
import CRMV2Container from "container/CRM"
import { get, uniq } from "lodash"
import {
  Modal, Checkbox,
} from "@ui/antd"
import colors from "@colors"
import { ATC } from "@fonts"
import { CloseIcon } from "dumbComponents/CRMV2/CRMDashboard/Components/Icons"

const CustomModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    .ant-modal-close {
      top: 15px;
    }
  }
  .ant-modal-footer {
    border-top: none;

    .ant-btn-primary {
      border-radius: 4px;
      background: ${colors.new_primary} !important;
      border-color: ${colors.new_primary} !important;
    }

    .ant-btn-default {
      border-radius: 4px;
      color: ${colors.new_primary} !important;
      border-color: ${colors.new_primary} !important;
    }
  }
  .ant-modal-body {
    padding: 20px;
  }
`

const ModalTitle = styled.h1`
  margin: 0;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: ${colors.black};
`

const ModalDescription = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.black};
  margin: 5px 0px 15px;
`

const GroupWrap = styled.div`
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`

const UnarchiveLeadsModal = ({
  isModalForArchivedLeadOpen,
  toggleModalForArchivedLeads,
  getClientTransactionResponse,
  unArchiveLeads,
  currentFilters,
}) => {
  const [selectedLeads, setSelectedLeads] = useState([])
  const { data } = getClientTransactionResponse || {}
  const archivedLeads = get(data, "client_transactions.Archived")
  const onChange = (checkedValues) => {
    setSelectedLeads(checkedValues)
  }
  const handleSubmit = () => {
    const payload = {
      transaction_ids: selectedLeads,
    }
    unArchiveLeads({ payload, currentFilters })
    toggleModalForArchivedLeads(false)
  }
  const getOptions = options => options
    && options.map(item => ({ value: item.id, label: item.client_name }))
  return (
    <CustomModal
      open={isModalForArchivedLeadOpen}
      onOk={handleSubmit}
      onCancel={() => toggleModalForArchivedLeads(false)}
      okText="Unarchive"
      closeIcon={<CloseIcon />}
    >
      <ModalTitle>
        Archived Leads
      </ModalTitle>
      <ModalDescription>
        Select leads to unarchive:
      </ModalDescription>
      <GroupWrap>
        <p>
          <Checkbox.Group value={selectedLeads} options={getOptions(archivedLeads)} onChange={e => onChange(e)} />
        </p>
      </GroupWrap>
    </CustomModal>
  )
}

export default CRMV2Container(UnarchiveLeadsModal)
