import React from 'react'

const AddNew = props => {
  const {color = "#5A5FF2"} = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11603_16498)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.66667 5C7.66667 4.8159 7.81591 4.66667 8 4.66667C8.18409 4.66667 8.33333 4.8159 8.33333 5V7.66667H11C11.1841 7.66667 11.3333 7.81591 11.3333 8C11.3333 8.18409 11.1841 8.33333 11 8.33333H8.33333V11C8.33333 11.1841 8.18409 11.3333 8 11.3333C7.81591 11.3333 7.66667 11.1841 7.66667 11V8.33333H5C4.8159 8.33333 4.66667 8.18409 4.66667 8C4.66667 7.81591 4.8159 7.66667 5 7.66667H7.66667V5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_11603_16498">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AddNew
