import React, { useState } from "react"
import OffersV2 from "container/ListingsV2/index"
import Listings from "container/Listings"
import OldOffers from "container/TechAssets"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import { LISTING_DOCS, OFFER_DOCS } from "dumbComponents/ListingsV2/const"
import {
  BlueButton,
} from "dumbComponents/ListingsV2/commonStyles"
import { EyeIcon, ShareIcon } from "dumbComponents/ListingsV2/icons"
import SearchBar from "../../../SearchBar"
import { Wrap, SubmitWrap, StickyWrap } from "./style"
import CheckboxList from "../../../CheckboxList/index"

const DocsUI = ({
  docs,
  handleSubmit,
  dealToView,
  offersDocList,
  getTemplatePreview,
  mainList,
  updatingRecommendedDocs,
  customHandle,
  listingDocsList,
  updateListingOfferDocs,
  currentLibraryTab,
  isUploadModal,
  offersDocListView,
  listingDocsListView,
  updateOfferDocsListView,
  updateListingDocs,
  placeholder,
}) => {
  const [filterText, setFilterText] = useState("")

  const OptionList2 = []

  const filterFunction = (val) => {
    console.log(val)
    setFilterText(val)
  }

  const handleSelectedState = (e, eID, template_id) => {
    const checked = e.target.checked
    if (currentLibraryTab === OFFER_DOCS) {
      const currentDocs = [
        ...offersDocListView,
      ]

      currentDocs.forEach((item) => {
        // if (item.id === eID) {
        //   item.isSelected = checked
        // }
        if (item.template_id === template_id && item.id === eID){
          item.isSelected = checked
        }
      })
      updateOfferDocsListView(currentDocs)
    }
    if (currentLibraryTab === LISTING_DOCS) {
      const currentDocs = [
        ...listingDocsListView,
      ]

      currentDocs.forEach((item) => {
        // console.log(item.id, eID)
        // if (item.id === eID) {
        //   item.isSelected = checked
        // }
        if (item.template_id === template_id && item.id === eID){
          item.isSelected = checked
        }
      })

      updateListingDocs(currentDocs)
    }
  }

  const isSelected = docs && docs.length > 0 && docs.filter(doc => doc.isSelected)

  return (
    <Wrap flex>
      <SearchBar
        customFunc={filterFunction}
        placeholder={placeholder}
      />
      <CheckboxList
        list={docs}
        onClick={e => { console.log("clicked")}}
        handleSelectedCheck={customHandle || handleSelectedState}
        dropdownList={OptionList2}
        mainList={mainList}
        customFilterText={filterText}
        isUploadModal={isUploadModal}
      />
      {handleSubmit && typeof handleSubmit === "function" && (
        <StickyWrap>
        <SubmitWrap>
          <BlueButton 
            disabled={isSelected.length === 0 || updatingRecommendedDocs}
            maxWidth onClick={handleSubmit}>
            {updatingRecommendedDocs ? (
              <ThreeDotLoader color="#fff" />
            ) : (
              <p>Attach selected documents</p>
            )}
          </BlueButton>
          </SubmitWrap>
          </StickyWrap>
      )}
    </Wrap>
  )
}

export default Listings(OldOffers(OffersV2(DocsUI)))
