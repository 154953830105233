import {
  takeLatest, call, put, all, select, throttle
} from "redux-saga/effects"
import { findIndex, get, isEmpty, isArray } from "lodash"
import { isSuccess, getQueryFromURL } from "services/Utils"
import { uploadImageAPI } from "container/Client/api"
import root from "window-or-global"
import { getInitialValueBasedOnType, getTypeMapping } from "ssr/Profile/HelperComponents"
import { saveNewProfileImageAction, syncUserCookieAction } from "container/App/actions"
import {
  fetchClientSearchCriteriaAction,
  fetchClientPropertiesDetailsActions,
  toggleEditPropertyModalAction,
} from "container/ClientProfile/actions"
import {
  selectCityInPreferenceAction,
  toggleLoaderAction,
  handleCityRemovalAction,
} from "container/Marketplace/actions"
import cookie from "services/CookieStorage"
import {
  blockAndUnblockAPI,
} from "container/Network/api"
import customToast from "dumbComponents/OffersV3/components/Toast/customToast"
import {
  getFollowersAndFollowingAPI,
  saveProfileInfoAPI,
  fetchSpecializationsAPI,
  getNeighbourhoodByCityAPI,
  getProprtyTypesAPI,
  getProprtyStatusAPI,
  saveAddListingAPI,
  getProfilecompletionAPI,
  getUserProfileAPI,
  getNeighbourhoodsAPI,
  getUserActivityAPI,
  getMlsServingListsAPI,
  removeSyncedMlsAPI,
  syncMLSAPI,
  followAgentAPI,
  fetchBuyerNeedsAPI,
  unfollowAgentAPI,
  saveCityServedAPI,
  addCityAPI,
  removeCityAPI,
  addBuyerNeedsAPI,
  getOwnFollowerAndFollowingAPI,
  editBuyerNeedsAPI,
  fetchCityNeighborhoodServedAPI,
  fetchMoreFeedsAPI,
  removeCityServedCityAPI,
  removeCityServedNeighbourhoodAPI,
  removeBuyerNeedsAPI,
  editPropertyAPI,
  getGoalsAPI,
  sendGoalsAPI,
  submitOnboardingForm,
  getAgentsfeedsAPI,
  profileViewedAPI,
  uploadProfileViewAPI,
  fetchCityNeighborhoodNewServedAPI,
  fetchLicenseNumbers,
  fetchLicenseNumbersAPI,
  saveLicenseNumberAPI,
  getDocusignIntegrationStatusAPI,
  docusignIntegrationAPI,
  saveDBAInformationAPI,
  fetchDBAInformationAPI
} from "./api"
import * as ProfileAction from "./action"
import { message } from "antd";

const differenceUtil = (arr1 = [], arr2 = []) => {
  if (!isArray(arr1) || !isArray(arr2)) {
    return []
  }
  const biggerArr = arr1.length > arr2.length ? arr1 : arr2
  const smallerArray = arr1.length < arr2.length ? arr1 : arr2
  const differentArray = biggerArr.filter(item => !smallerArray.includes(item))
  return differentArray
}

function* handleProfileUpdateCount(action) {
  try {
    const { agentId } = action.data
    const res = yield call(uploadProfileViewAPI, agentId)
    if (isSuccess(res)) {
      console.log("Profile count updated")
    }
  } catch (error) {
    console.log("Error Updating Profile View Count");
  }
}

function* fetchSpecializations() {
  try {
    const res = yield call(fetchSpecializationsAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ProfileAction.fetchSpecializationsAction.success(response))
    }
  } catch (e) {
    yield put(ProfileAction.fetchSpecializationsAction.failure(e))
  }
}

function* getFollowersAndFollowing(action) {
  try {
    const { data } = action
    const res = yield call(getFollowersAndFollowingAPI, data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ProfileAction.getFollowersAndFollowingAction.success(response))
    }
  } catch (e) {
    yield put(ProfileAction.getFollowersAndFollowingAction.failure(e))
  }
}

function* saveAndMoveToNextStep(data) {
  try {
    const { agent } = data
    const { profileCompletionRemainingSteps } = agent
    const step = profileCompletionRemainingSteps[0]
    let activeTab
    if (step === "mls") {
      activeTab = "Sync MLS"
    }
    const type = getTypeMapping(step)
    yield put(ProfileAction.openEditProfileModalAction({
      type,
      initialValues: getInitialValueBasedOnType(type, agent),
      isProfileCompletion: true,
      activeTab,
    }))
  } catch (e) {
    yield put(ProfileAction.closeEditProfileModalAction())
    console.error(e)
    // handle Error
  }
}

function* saveProfileUpdate(action) {
  try {
    const profile = yield select(state => state.profile)
    const { isProfileCompletion } = profile
    const isRedirect = action.data.redirectToAppDownload
    delete action.data.redirectToAppDownload
    const res = yield call(saveProfileInfoAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(syncUserCookieAction(response))
      yield put(ProfileAction.saveProfileUpdateAction.success(response))
      yield put(ProfileAction.updateAgentProfileInfoAction(response))
      const { isCloseModal = true } = action.data
      if (isProfileCompletion) {
        yield* saveAndMoveToNextStep({ agent: response, profile })
      } else if (isCloseModal) {
        yield put(ProfileAction.closeEditProfileModalAction())
      }
      if (isRedirect) {
        root.location.href = "/signup/download-app?showNetworkApp=true"
      }
    }
  } catch (e) {
    customToast(e.data.error.message,"error")
    yield put(ProfileAction.saveProfileUpdateAction.failure(e))
  }
}

function* uploadProfileImage(action) {
  try {
    const { file, agentId, type } = action.data
    const fd = new FormData()
    console.log(file);
    fd.append("imageFile", file)
    const data = {
      filePath: `profile/${agentId}`,
      config: {
        bucket: "profile",
        convertTo: "jpeg",
      },
    }
    fd.append("requestBody", JSON.stringify(data))
    const imageRes = yield call(uploadImageAPI, fd)
    if (isSuccess(imageRes)) {
      const { imageURL } = imageRes.data.response
      const images = yield select(state => state.profile.agent.images || [])
      const imageIndex = findIndex(images, item => item.type === type)
      if (imageIndex === -1) {
        images.push({
          path: imageURL,
          type,
        })
      } else {
        images[imageIndex].path = imageURL
        images[imageIndex].type = type
      }
      if (type === "profile") {
        yield put(saveNewProfileImageAction(imageURL))
        yield put(ProfileAction.closeEditProfileModalAction())
      }
      const updatePayload = {
        id: agentId,
        images,
      }
      yield put(ProfileAction.saveProfileUpdateAction.request(updatePayload))
    }
  } catch (error) {
    yield put(ProfileAction.uploadProfileImageAction.failure(error))
    yield put(ProfileAction.closeEditProfileModalAction())
  }
}

function* getNeighbourhoodByCity(action) {
  try {
    const {
      neighbourhood,
      city,
      offset,
      limit,
      isFetchingMore,
      isMarketplace,
      agentId,
    } = action.data
    let param = `city=${city}`
    if (neighbourhood) {
      param += `&neighbourhood=${neighbourhood}`
    }
    if (offset) {
      param += `&offset=${offset}`
    }
    if (limit) {
      param += `&limit=${limit}`
    }
    const res = yield call(getNeighbourhoodByCityAPI, param)
    if (isSuccess(res)) {
      const { response } = res.data
      if (isFetchingMore) {
        const neighbourhoods = yield select(state => state.profile.neighbourhoods) || []
        yield put(
          ProfileAction.getNeighbourhoodByCityAction.success({
            neighbourhoods: [...neighbourhoods, ...response.neighbourhoods],
            isFetchingMore,
          })
        )
      } else {
        yield put(ProfileAction.getNeighbourhoodByCityAction.success(response))
      }
      if (isMarketplace) {
        yield put(toggleLoaderAction.call({
          key: "isNeighbourhoodBusy",
          value: false,
        }))
      }
    }
  } catch (e) {
    yield put(ProfileAction.getNeighbourhoodByCityAction.failure(e))
  }
}

function* getPropertyTypes() {
  try {
    const res = yield call(getProprtyTypesAPI)
    if (isSuccess(res)) {
      const { response: { types = [] } = {} } = res.data
      const mappedTypes = types.map(item => ({ label: item, value: item }))
      yield put(ProfileAction.getPropertyTypesAction.success({ types: mappedTypes }))
    }
  } catch (e) {
    yield put(ProfileAction.getPropertyTypesAction.failure(e))
  }
}

function* getPropertyStatus() {
  try {
    const res = yield call(getProprtyStatusAPI)
    if (isSuccess(res)) {
      const { response: { statuses = [] } = {} } = res.data
      const mappedStatus = statuses.map((item) => {
        const { display_name, name, ...others } = item
        return {
          label: display_name,
          value: name,
          ...others,
        }
      })
      yield put(ProfileAction.getPropertyStatusAction.success({ statuses: mappedStatus }))
    }
  } catch (e) {
    yield put(ProfileAction.getPropertyStatusAction.failure(e))
  }
}

function* uploadPropertyImages(action) {
  try {
    const { files } = action.data

    if (files && files.length > 0) {
      const res = yield all(
        Array.from(files).map((file) => {
          const fd = new FormData()
          fd.append("imageFile", file)
          const data = {
            filePath: "properties/images",
            config: {
              bucket: "HD",
              convertTo: "jpeg",
            },
          }
          fd.append("requestBody", JSON.stringify(data))
          return call(uploadImageAPI, fd)
        })
      )
      // Map Response to support ImageCarousel
      const responses = res.map(({ data: { response: { dynamicImageURL, imageURL, statusCode } } }) => ({
        dynamicImageURL,
        image_url: imageURL,
        statusCode,
      }))
      yield put(ProfileAction.uploadPropertyImagesAction.success(responses))
    }
  } catch (e) {
    yield put(ProfileAction.uploadPropertyImagesAction.failure(e))
  }
}

function* saveAddListing(action) {
  try {
    const payload = { ...action.data }
    const {
      isFetchClientProperties,
      u_cust_id, clientType,
      isEditing,
    } = payload
    delete payload.isFetchClientProperties
    delete payload.clientId
    delete payload.clientType

    let res = null
    if (isEditing) {
      const { propertyId } = payload
      delete payload.propertyId
      delete payload.isEditing
      delete payload.price
      res = yield call(editPropertyAPI, propertyId, payload)
    } else {
      res = yield call(saveAddListingAPI, payload)
    }
    if (isSuccess(res)) {
      const { response } = res.data
      const { agent_id } = response
      yield put(ProfileAction.saveAddListingAction.success(response))
      yield put(ProfileAction.fetchCityNeighborhoodServedAction.request({ agentId: agent_id }))
      yield put(ProfileAction.closeEditProfileModalAction())
      yield put(ProfileAction.clearPropertyImagesAction.call())
      if (isFetchClientProperties) {
        yield put(fetchClientPropertiesDetailsActions.request({ clientId: u_cust_id, clientType }))
      }

      if (isEditing) {
        yield put(toggleEditPropertyModalAction.call(false))
      }
    }
  } catch (e) {
    yield put(ProfileAction.saveAddListingAction.failure(e))
  }
}

function* getProfilecompletion(action) {
  try {
    const { type, id } = action.data
    const query = `?${type}=${id}`
    const res = yield call(getProfilecompletionAPI, query)
    if (isSuccess(res)) {
      const { response } = action.data
      yield put(ProfileAction.getProfilecompletionAction.success(response))
    }
  } catch (e) {
    yield put(ProfileAction.getProfilecompletionAction.failure(e))
  }
}

function* getProfileInfo(action) {
  try {
    const query = getQueryFromURL(root.location.pathname + root.location.search)
    const res = yield all(
      [call(getUserProfileAPI, `${query}&filter=is_blocked`), call(getUserActivityAPI, query), call(getNeighbourhoodsAPI, query)],
      query
    )

    // Check if all request are successfull
    if (res.filter(item => item.status !== 200).length === 0) {
      const payload = {
        profile: {
          agent: get(res, "[0].data.response", {}),
          feeds: get(res, "[1].data.response", {}),
          properties: get(res, "[2].data.response", {}),
        },
      }
      yield put(ProfileAction.fetchUserProfileInfoAction.success({ ...payload }))
    }

    // If AgentID is there fetch other Informations
    const agentId = get(res, "[0].data.response.id")
    if (agentId) {
      yield put(ProfileAction.fetchBuyerNeedsAction.request(`?agentId=${agentId}`))
      yield put(ProfileAction.getFollowersAndFollowingAction.request(agentId))
    }
  } catch (e) {
    action.data.push("/error/502")
    yield put(ProfileAction.fetchUserProfileInfoAction.failure(e))
  }
}

function* getMlsServingLists() {
  try {
    const res = yield call(getMlsServingListsAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ProfileAction.getMLSServingListsAction.success(response))
    }
  } catch (e) {
    yield put(ProfileAction.getMLSServingListsAction.failure(e))
  }
}

function* syncMLS(action) {
  try {
    const { payload } = action.data
    payload.type = 1
    const res = yield call(syncMLSAPI, payload)
    if (isSuccess(res)) {
      yield put(ProfileAction.syncMLSAction.success())
      yield put(ProfileAction.closeEditProfileModalAction())
      yield put(ProfileAction.fetchUserProfileAction.request())
    } else {
      yield put(ProfileAction.syncMLSAction.failure(res))
    }
  } catch (e) {
    yield put(ProfileAction.syncMLSAction.failure(e))
  }
}

function* removeSyncedMLS(action) {
  try {
    const { mlsId } = action.data
    const res = yield call(removeSyncedMlsAPI, mlsId)
    if (isSuccess(res)) {
      yield put(ProfileAction.removeSyncedMlsAction.success({ mlsId }))
      yield put(ProfileAction.fetchUserProfileAction.request())
    }
  } catch (e) {
    yield put(ProfileAction.removeSyncedMlsAction.failure(e))
  }
}

function* followAgent(action) {
  try {
    let payload = {}
    const agent = yield select(state => state.profile.agent)
    if (action.data) {
      const { follows } = action.data
      if (follows) {
        payload = action.data
      } else {
        const {
          id, email, phone, firstname,
        } = action.data || {}
        payload = {
          follows: [
            {
              follow_agent_id: id,
              email,
              phone,
              firstname,
            },
          ],
          type: "agent",
        }
      }
    }
    const res = yield call(followAgentAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ProfileAction.followAgentAction.success(response))
      yield put(ProfileAction.getFollowersAndFollowingAction.request(agent.id))
      yield put(ProfileAction.getOwnFollowerAndFollowingAction.request())
    }
  } catch (e) {
    yield put(ProfileAction.followAgentAction.failure(e))
  }
}

function* unfollowAgent(action) {
  try {
    const { unfollowId, activeId } = action.data
    const res = yield call(unfollowAgentAPI, unfollowId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ProfileAction.unfollowAgentAction.success(response))
      yield put(ProfileAction.getFollowersAndFollowingAction.request(activeId))
      yield put(ProfileAction.getOwnFollowerAndFollowingAction.request())
    }
  } catch (e) {
    yield put(ProfileAction.unfollowAgentAction.failure(e))
  }
}

function* fetchBuyerNeeds(action) {
  try {
    const res = yield call(fetchBuyerNeedsAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ProfileAction.fetchBuyerNeedsAction.success(response))
    }
  } catch (e) {
    yield put(ProfileAction.fetchBuyerNeedsAction.failure(e))
  }
}

function* saveCityServed(action) {
  let agent_id
  try {
    const {
      neighbourhoods,
      cityServedId: cityId,
      isMarketplace,
      selectedCity,
    } = action.data
    if (isMarketplace) {
      yield put(toggleLoaderAction.call({
        key: "isNeighbourhoodBusy",
        value: "Adding",
      }))
    }
    const profile = yield select(state => state.profile)
    const cityServedId = get(profile, "addCityResponse.id", cityId)
    agent_id = get(profile, "agent.id") || cookie.load("agentId")
    const payload = {
      neighbourhoods,
      cityServedId,
    }
    const res = yield call(saveCityServedAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ProfileAction.saveCityServedAction.success(response))
      if (isMarketplace) {
        const agentId = cookie.load("agentId")
        yield put(ProfileAction.fetchCityNeighborhoodServedAction.request({
          agentId,
          selectedCity,
          isMarketplace,
        }))
        return
      }
      yield put(ProfileAction.fetchCityNeighborhoodServedAction.request({ agentId: agent_id }))
      yield put(ProfileAction.closeEditProfileModalAction())
      const $ele = root.document.getElementById("cityServedActive")
      if ($ele) {
        $ele.click()
      }
    }
  } catch (e) {
    yield put(ProfileAction.saveCityServedAction.failure(e))
    yield put(ProfileAction.closeEditProfileModalAction())
    yield put(ProfileAction.fetchCityNeighborhoodServedAction.request({ agentId: agent_id }))
  }
}

function* addCity(action) {
  try {
    const { payload, isFetchCityServed } = action.data
    // This will start a loader only when called from market place
    if (isFetchCityServed) {
      yield put(toggleLoaderAction.call({
        key: "isAddingCity",
        value: "adding",
      }))
    }
    const res = yield call(addCityAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ProfileAction.addCityAction.success(response))
      yield put(ProfileAction.fetchUserProfileAction.request())
      if (isFetchCityServed) {
        const { agentId } = action.data
        yield put(ProfileAction.fetchCityNeighborhoodServedAction.request({
          agentId,
          isMarketplace: true,
        }))
      }
    }
  } catch (e) {
    yield put(ProfileAction.addCityAction.failure(e))
  }
}

function* removeCity(action) {
  try {
    const {
      cityId,
      agentId,
      isMarketplace,
    } = action.data
    let cityServedId = null
    if (cityId) {
      yield put(toggleLoaderAction.call({
        key: "isAddingCity",
        value: "removing",
      }))
      cityServedId = cityId
    } else {
      cityServedId = yield select(state => state.profile.addCityResponse.id)
    }
    const res = yield call(removeCityAPI, cityServedId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ProfileAction.removeCityAction.success(response))
      // Only for Marketplace
      if (isMarketplace) {
        yield put(handleCityRemovalAction.call({}))
        yield put(ProfileAction.fetchCityNeighborhoodServedAction.request({
          agentId,
          isMarketplace: true,
        }))
        return
      }
      yield put(ProfileAction.fetchUserProfileAction.request())
    }
  } catch (e) {
    yield put(ProfileAction.removeCityAction.failure(e))
  }
}

function* getOwnFollowerAndFollowing() {
  try {
    const res = yield call(getOwnFollowerAndFollowingAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ProfileAction.getOwnFollowerAndFollowingAction.success(response))
    }
  } catch (e) {
    yield put(ProfileAction.getOwnFollowerAndFollowingAction.failure(e))
  }
}

function* addBuyerNeeds(action) {
  try {
    let res = {}
    const { agent_id, isEditing, isFetchClientSearchCriteria, u_cust_id } = action.data
    delete action.data.isFetchClientSearchCriteria
    if (isEditing) {
      const { req_id } = action.data
      delete action.data.isEditing
      res = yield call(editBuyerNeedsAPI, req_id, action.data)
    } else {
      delete action.data.isEditing
      res = yield call(addBuyerNeedsAPI, action.data)
    }
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ProfileAction.addBuyerNeedsAction.success(response))
      if (agent_id) {
        yield put(ProfileAction.fetchBuyerNeedsAction.request(`?agentId=${agent_id}`))
      }
      yield put(ProfileAction.closeEditProfileModalAction())
      if (isFetchClientSearchCriteria) {
        yield put(fetchClientSearchCriteriaAction.request(u_cust_id))
      }
    }
  } catch (e) {
    yield put(ProfileAction.addBuyerNeedsAction.failure(e))
  }
}

function* fetchCityNeighborhoodServed(action) {
  try {
    const { agentId, isMarketplace, selectedCity } = action.data
    const query = `?agentId=${agentId}`
    const res = yield call(fetchCityNeighborhoodServedAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ProfileAction.fetchCityNeighborhoodServedAction.success(response))
      const { cities } = response
      if (isMarketplace) {
        if (cities && cities.length > 0 && isEmpty(selectedCity)) {
          yield put(selectCityInPreferenceAction.call(cities[0]))
        }
        if (!isEmpty(selectedCity) && cities && cities.length > 0) {
          const newCitySelected = cities.find(city => city.id === selectedCity.id)
          const newNeighbourhoodsArray = get(newCitySelected, "neighbourhoods", []).map(x => x.name)
          const oldNeighbourhoodsArray = get(selectedCity, "neighbourhoods", []).map(x => x.name)
          const newNeighbourhoodAdded = differenceUtil(oldNeighbourhoodsArray, newNeighbourhoodsArray)
          yield put(selectCityInPreferenceAction.call({
            ...newCitySelected,
            newNeighbourhoodAdded,
          }))
        }
        yield put(toggleLoaderAction.call({
          key: "isAddingCity",
          value: false,
        }))
      }
    }
  } catch (e) {
    yield put(ProfileAction.fetchCityNeighborhoodServedAction.failure(e))
  }
}

function* fetchUserProfile(action) {
  try {
    let query
    if (action.data) {
      query = action.data
    } else {
      query = getQueryFromURL(root.location.pathname + root.location.search)
    }
    const res = yield call(getUserProfileAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ProfileAction.fetchUserProfileAction.success(response))
    }
  } catch (e) {
    yield put(ProfileAction.fetchUserProfileAction.failure(e))
  }
}

let prevBefore = ""

function* fetchMoreFeeds() {
  try {
    const profile = yield select(state => state.profile)
    const { agent, feeds } = profile || {}
    const { id } = agent || {}
    const { activities } = feeds || {}
    const { networkActivities } = activities || {}
    const lastItem = networkActivities[networkActivities.length - 1]
    const { created_at } = lastItem
    const payload = {
      agentId: id,
      count: 5,
    }
    if (created_at && prevBefore !== created_at) {
      payload.before = created_at
      prevBefore = created_at
      const res = yield call(fetchMoreFeedsAPI, payload)
      if (isSuccess(res)) {
        const { response } = res.data
        yield put(ProfileAction.fetchMoreFeedsAction.success(response))
      } else {
        yield put(ProfileAction.fetchMoreFeedsAction.failure())
      }
    } else {
      yield put(ProfileAction.fetchMoreFeedsAction.failure())
    }
  } catch (e) {
    yield put(ProfileAction.fetchMoreFeedsAction.failure(e))
  }
}

function* removeCityServedCity(action) {
  try {
    const res = yield call(removeCityServedCityAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      const agentId = yield select(state => state.profile.agent.id)
      yield put(ProfileAction.removeCityServedCityAction.success(response))
      yield put(ProfileAction.fetchCityNeighborhoodServedAction.request({ agentId }))
      yield put(ProfileAction.closeEditProfileModalAction())
    }
  } catch (e) {
    yield put(ProfileAction.removeCityServedCity.failure(e))
  }
}

function* removeCityServedNeighbourhood(action) {
  try {
    const { isMarketplace, selectedCity } = action.data
    delete action.data.isMarketplace
    delete action.data.selectedCity
    if (isMarketplace) {
      yield put(toggleLoaderAction.call({
        key: "isNeighbourhoodBusy",
        value: "Removing",
      }))
    }
    const res = yield call(removeCityServedNeighbourhoodAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      const agentId = yield select(state => get(state, "profile.agent.id", cookie.load("agentId")))
      yield put(ProfileAction.removeCityServedNeighbourhoodAction.success(response))
      yield put(ProfileAction.fetchCityNeighborhoodServedAction.request({ agentId, isMarketplace, selectedCity }))
    } else {
      yield put(ProfileAction.removeCityServedNeighbourhoodAction.failure(res))
    }
  } catch (e) {
    yield put(ProfileAction.removeCityServedNeighbourhoodAction.failure(e))
  }
}

function* removeBuyerNeeds(action) {
  try {
    const { reqId, clientId } = action.data
    const res = yield call(removeBuyerNeedsAPI, reqId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ProfileAction.removeBuyerNeedsAction.success(response))
      const agentId = yield select(state => get(state, "profile.agent.id", cookie.load("agentId")))
      yield put(ProfileAction.fetchBuyerNeedsAction.request(`?agentId=${agentId}`))
      yield put(fetchClientSearchCriteriaAction.request(clientId))
      yield put(ProfileAction.closeEditProfileModalAction())
    }
  } catch (e) {
    yield put(ProfileAction.removeBuyerNeedsAction.failure(e))
  }
}

function* handleGoalsRequest() {
  try {
    const res = yield call(getGoalsAPI)
    if (isSuccess(res)) {
      yield put(ProfileAction.getGoalsAction.success(res))
    }
  } catch (error) {
    yield put(ProfileAction.getGoalsAction.failure(error))
  }
}

function* handleUserOnboarding(action) {
  const { data } = action
  const { payload } = data
  try {
    const res = yield call(submitOnboardingForm, payload)
    if (isSuccess(res)) {
      yield put(ProfileAction.submitOnboardingFormAction.success(res))
    }
  } catch (error) {
    yield put(ProfileAction.submitOnboardingFormAction.failure())
  }
}

function* handleAgentFeeds(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(getAgentsfeedsAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ProfileAction.getAgentFeedsAction.success(response))
    }
  } catch (error) {
    console.log(error)
    yield put(ProfileAction.getAgentFeedsAction.failure(error))
    yield put(ProfileAction.feedErrorFetchAction.call())
  }
}

function* handleProfileViewed(action) {
  try {
    const {
      type,
    } = action.data
    const payload = {
      featureName: type,
    }
    const res = yield call(profileViewedAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(ProfileAction.profileViewedAction
        .success(response))
    }
  } catch (error) {
    yield put(ProfileAction.profileViewedAction.failure(error))
  }
}

function* handleUnfollow(action) {
  try {
    const { payload } = action.data
    const res = yield call(blockAndUnblockAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      root.location.reload()
    }
  } catch (error) {
    yield put(ProfileAction.unblockUserAction.failure(error))
  }
}

function* fetchLicenseNumbersSaga(action) {
  try {
    const { agentId } = action.data
    const res = yield call(fetchLicenseNumbersAPI, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
            yield put(ProfileAction.fetchLicenseNumbersAction
        .success(response))
    }
  } catch (error) {
    yield put(ProfileAction.fetchLicenseNumbersAction.failure(error))
  }
}

function* getDocusignIntegrationStatusSaga(action) {
  try {
    const { agentId } = action.data
    const res = yield call(getDocusignIntegrationStatusAPI, agentId)
    if (isSuccess(res)) {
      const { response } = res.data
            yield put(ProfileAction.getDocusignIntegrationStatusAction
        .success(response))
    }
  } catch (error) {
    yield put(ProfileAction.getDocusignIntegrationStatusAction.failure(error))
  }
}

function* addDocusignIntegrationStatusSaga(action) {
  try {
    const { payload, callback } = action.data
    const res = yield call(docusignIntegrationAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
            yield put(ProfileAction.docusignIntegrationStatusAction
              .success(response))
      if (callback) {
        callback();
      }
    }
  } catch (error) {
        const { payload, callback } = action.data
 if (callback) {
        callback();
      }
    yield put(ProfileAction.docusignIntegrationStatusAction.failure(error))
  }
}


function* saveLicenseNumbersSaga(action) {
  try {
    const {licensePayload, callback}  = action.data
    const res = yield call(saveLicenseNumberAPI, licensePayload)
    if (isSuccess(res)) {
      const { response } = res.data
            yield put(ProfileAction.saveLicenseNumbersAction
              .success(response))
            message.success('License details saved successfully')
      if (callback) {
        callback();
      }
    }
  } catch (error) {
    const { licensePayload, callback } = action.data
    message.error('Something went wrong')
    yield put(ProfileAction.saveLicenseNumbersAction.failure(error))
      if (callback) {
        callback();
      }
  }
}

function* saveDBAInformationSaga(action) {
  try {
    const {dbaPayload, callback} = action.data
    const res = yield call(saveDBAInformationAPI, dbaPayload)
    if (isSuccess(res)) {
      const { response } = res.data
            yield put(ProfileAction.saveDBAInformationAction
              .success(response))
      message.success('DBA details saved successfully')
      if (callback) {
        callback();
      }
    }
  } catch (error) {
    const { dbaPayload, callback } = action.data
    message.error('Something went wrong')
    yield put(ProfileAction.saveDBAInformationAction.failure(error))
       if (callback) {
        callback();
      }
  }
}

function* fetchDBAInformationSaga(action) {
  try {
    // const { payload } = action.data
    const res = yield call(fetchDBAInformationAPI)
    if (isSuccess(res)) {
      const { response } = res.data
            yield put(ProfileAction.fetchDBAInformationAction
        .success(response))
    }
  } catch (error) {
    yield put(ProfileAction.fetchDBAInformationAction.failure(error))
  }
}





export default function* main() {
  yield takeLatest(ProfileAction.removeCityAction.REQUEST, removeCity)
  yield takeLatest(ProfileAction.fetchUserProfileAction.REQUEST, fetchUserProfile)
  yield takeLatest(ProfileAction.getFollowersAndFollowingAction.REQUEST, getFollowersAndFollowing)
  yield takeLatest(ProfileAction.unfollowAgentAction.REQUEST, unfollowAgent)
  yield takeLatest(ProfileAction.saveProfileUpdateAction.REQUEST, saveProfileUpdate)
  yield takeLatest(ProfileAction.fetchUserProfileInfoAction.REQUEST, getProfileInfo)
  yield takeLatest(ProfileAction.followAgentAction.REQUEST, followAgent)
  yield takeLatest(ProfileAction.getProfilecompletionAction.REQUEST, getProfilecompletion)
  yield takeLatest(ProfileAction.saveAddListingAction.REQUEST, saveAddListing)
  yield takeLatest(ProfileAction.uploadPropertyImagesAction.REQUEST, uploadPropertyImages)
  yield takeLatest(ProfileAction.fetchSpecializationsAction.REQUEST, fetchSpecializations)
  yield takeLatest(ProfileAction.uploadProfileImageAction.REQUEST, uploadProfileImage)
  yield takeLatest(ProfileAction.getNeighbourhoodByCityAction.REQUEST, getNeighbourhoodByCity)
  yield takeLatest(ProfileAction.getPropertyTypesAction.REQUEST, getPropertyTypes)
  yield takeLatest(ProfileAction.getPropertyStatusAction.REQUEST, getPropertyStatus)
  yield takeLatest(ProfileAction.getMLSServingListsAction.REQUEST, getMlsServingLists)
  yield takeLatest(ProfileAction.syncMLSAction.REQUEST, syncMLS)
  yield takeLatest(ProfileAction.removeSyncedMlsAction.REQUEST, removeSyncedMLS)
  yield takeLatest(ProfileAction.fetchBuyerNeedsAction.REQUEST, fetchBuyerNeeds)
  yield takeLatest(ProfileAction.saveCityServedAction.REQUEST, saveCityServed)
  yield takeLatest(ProfileAction.addCityAction.REQUEST, addCity)
  yield takeLatest(ProfileAction.getOwnFollowerAndFollowingAction.REQUEST, getOwnFollowerAndFollowing)
  yield takeLatest(ProfileAction.addBuyerNeedsAction.REQUEST, addBuyerNeeds)
  yield takeLatest(ProfileAction.fetchCityNeighborhoodServedAction.REQUEST, fetchCityNeighborhoodServed)
  yield takeLatest(ProfileAction.removeCityServedCityAction.REQUEST, removeCityServedCity)
  yield takeLatest(ProfileAction.removeCityServedNeighbourhoodAction.REQUEST, removeCityServedNeighbourhood)
  yield takeLatest(ProfileAction.removeBuyerNeedsAction.REQUEST, removeBuyerNeeds)
  yield takeLatest(ProfileAction.getGoalsAction.REQUEST, handleGoalsRequest)
  yield takeLatest(ProfileAction.submitOnboardingFormAction.REQUEST, handleUserOnboarding)
  yield throttle(2000, ProfileAction.fetchMoreFeedsAction.REQUEST, fetchMoreFeeds)
  yield takeLatest(ProfileAction.getAgentFeedsAction.REQUEST, handleAgentFeeds)
  yield takeLatest(ProfileAction.profileViewedAction.REQUEST, handleProfileViewed)
  yield takeLatest(ProfileAction.unblockUserAction.REQUEST, handleUnfollow)
  yield takeLatest(ProfileAction.updateProfileViewCountAction.REQUEST, handleProfileUpdateCount)
  yield takeLatest(ProfileAction.fetchLicenseNumbersAction.REQUEST, fetchLicenseNumbersSaga)
  yield takeLatest(ProfileAction.saveLicenseNumbersAction.REQUEST, saveLicenseNumbersSaga)
  yield takeLatest(ProfileAction.getDocusignIntegrationStatusAction.REQUEST, getDocusignIntegrationStatusSaga)
  yield takeLatest(ProfileAction.docusignIntegrationStatusAction.REQUEST, addDocusignIntegrationStatusSaga)
  yield takeLatest(ProfileAction.fetchDBAInformationAction.REQUEST,fetchDBAInformationSaga )
  yield takeLatest(ProfileAction.saveDBAInformationAction.REQUEST,saveDBAInformationSaga )

  //yield takeLatest(ProfileAction.SKIP_PROFILE_COMPLETION_STEP, skipProfileCompletionStep)
}
