import React, { useState, useEffect } from "react"
import { Flex, Radio, Checkbox } from "antd"
import Container from "container/Settings/SubscriptionsContainer"
import SettingsContainer from "container/Settings"
import Navbar from "container/Navbar"
import Loader from "@ui/Loader"
import { isEmpty, get, sortBy } from "lodash"
import ReferralContainer from "container/Referral"
import CookiesStorage from "services/CookieStorage"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import { withRouter } from "react-router-dom"
import CancelBlockerModal from "./Modals/cancelledRenew"
import SuccessModal from "../Modals/SuccesModal"
const PaywallBlocker = ({
  history,
  subscriptionStatus,
  subscriptionStatusObject,
  getSubscriptionPlans,
  fetchPayment,
  getSubscriptionPlansResponse,
  fetchPaymentResponse,
  paymentObject,
  makeDefaultMethod,
  addSubscriptionPlan,
  setLoadingCard,
}) => {
  console.log("subscriptionStatus", subscriptionStatus)
  const [isOpenCancelled, setCancelled] = useState(true)
  const [initialBillingData, setBillingDate] = useState(null)
  const [defaultPaymentObject, setDefaultPayment] = useState(null)
  const [nonActivePlan, setNonActivePlan] = useState(null)
  const [isSuccess, setSuccess] = useState(false)
  const handleCloseCancel = () => {
    history.push("/settings")
  }
  const currentSubscriptionStatus = subscriptionStatus
  useEffect(() => {
    console.log("here calling planes")
    getSubscriptionPlans({
      callback: () => {
        console.log("subscriptionStatus", currentSubscriptionStatus)
        if (subscriptionStatus && subscriptionStatus != "incomplete_expired") {
          fetchPayment()
        }
      },
    })
  }, [])
  const {
    isFetching,
    data: plansList,
  } = getSubscriptionPlansResponse || {}
  const {
    isFetching: fetchingPayments,
    data: paymentData,
  } = fetchPaymentResponse || {}

  useEffect(() => {
    if (paymentObject !== null && paymentObject.length > 0) {
      const defaultOption = paymentObject.filter(item => item.is_default_source === true)
      setDefaultPayment(defaultOption)
    }
  }, [paymentObject])
  useEffect(() => {
    if (subscriptionStatusObject && plansList) {
      setBillingDate(subscriptionStatusObject.start_date)
      let currentPlan
      let planFromSub
      const { data: items } = subscriptionStatusObject.items
      if (items && items.length) {
        for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
          const currentItem = items[itemIndex]
          const currentPlanName = get(currentItem, "plan.id")
          if (currentPlanName && currentPlanName.includes("broker")) {
            currentPlan = items[itemIndex].plan
            if (plansList && plansList.single_plans.broker) {
              planFromSub = plansList.single_plans.broker.find(x => x.plan_id === currentPlan.id)
              if (planFromSub) {
                setNonActivePlan(planFromSub)
              }
            }
          } else if (currentPlanName && currentPlanName.includes("rn")) {
            currentPlan = items[itemIndex].plan
            if (plansList && plansList?.single_plans?.rn) {
              planFromSub = plansList?.single_plans?.rn.find(x => x.plan_id === currentPlan.id)
              if (planFromSub) {
                setNonActivePlan(planFromSub)
              }
            }
          }
        }
      }
    }
  }, [subscriptionStatusObject, plansList])
  const closeSuccessModal = () => {
    setNonActivePlan(null)
    setSuccess(false)
    window.location.reload()
  }
  const handleAddCardDetails = (value) => {
    console.log("here close, handleAddCardDetails")
    if (value) {
      makeDefaultMethod({
        callback: () => {
          console.log("reached callback")
          const stripePlanId = nonActivePlan?.stripe_plan_id
          const payload = {
            plansMap: [{
              planId: stripePlanId,
              couponCode: "",
            }],
          }
          addSubscriptionPlan({
            payload,
            callback: () => {
              console.log("here back in loop")
              setLoadingCard(false)
              setCancelled(false)
              setSuccess(true)
            },
          })
        },
      })
    } else {
      const stripePlanId = nonActivePlan?.stripe_plan_id
      const payload = {
        plansMap: [{
          planId: stripePlanId,
          couponCode: "",
        }],
      }
      addSubscriptionPlan({
        payload,
        callback: () => {
          console.log("here back in loop")
          setLoadingCard(false)
          setCancelled(false)
          setSuccess(true)
        },
      })
    }
  }

  return (
    <>
      {(isFetching || fetchingPayments || !nonActivePlan) ? (
        <>
          <CancelBlockerModal
            toClose={handleCloseCancel}
            isOpen={isOpenCancelled}
            planDetail={nonActivePlan}
            defaultPaymentObject={defaultPaymentObject}
            initialBillingData={initialBillingData}
            handleProceed={handleAddCardDetails}
            loading
          />
        </>
      ) : (
        <>
          <CancelBlockerModal toClose={handleCloseCancel} isOpen={isOpenCancelled} planDetail={nonActivePlan} defaultPaymentObject={defaultPaymentObject} initialBillingData={initialBillingData} handleProceed={handleAddCardDetails} />
          <SuccessModal isOpen={isSuccess} planDetail={nonActivePlan} toClose={closeSuccessModal} handleClickForCancel={closeSuccessModal} />
        </>

      )}
    </>
  )
}

export default withRouter(TeamProfileContainer(ReferralContainer(Navbar(SettingsContainer(Container(PaywallBlocker))))))
