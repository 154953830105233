import React, { useState } from "react"
import styled from "styled-components"
import { get } from "lodash"
// import OffersV2 from "container/OffersV2"
import fallbackImage from "images/realty-v2/RadiusEdgeAssets/HomePrep-min.png"
import {
  DropdownWrap,
  MLSImage,
  MLSWrap,
  MLSAddress,
  MLSNumber,
} from "./googleLocationSearchStyles"
import { ATC, INTER, MONTSERRAT } from "@fonts"
import { getState } from "dumbComponents/ListingsV2/Components/ListingTabs/consts"

export const EditButton = styled.button`
  border: none;
  color: var(--Color-Primary-Primary, #5A5FF2);
  background: transparent;
  font-family: ${MONTSERRAT};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.12px;
  text-decoration-line: underline;
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 10px;
  margin-right: 10px;
`

const FilledMLSTab = ({
  selectedOption,
  setSelectedOption,
  setDisplay,
  toggleMLSTab,
}) => {
  const city = ""
  const state = ""
  const street = ""
  const handleClick = () => {
    setDisplay("block")
    setSelectedOption()
    toggleMLSTab()
    // updatePropertyInformationObject({
    //   mls_number: "",
    //   currentState,
    //   form,
    //   sellers,
    //   type_of_transaction,
    //   addressRepresentation,
    //   listing_agents,
    // })
  }
  return (
    <DropdownWrap
      isDropdown
    >
      <MLSImage src={(selectedOption && selectedOption.image) || fallbackImage} alt="mls" />
      <MLSWrap>
        <MLSNumber>
          {`MLS# ${(selectedOption && selectedOption.id)}`}
        </MLSNumber>
        <MLSAddress
          fontSize="18px"
        >
          {(selectedOption && selectedOption.address || `${street}, ${city}, ${state}`)}, {selectedOption && getState(selectedOption.StateOrProvince)}

        </MLSAddress>
      </MLSWrap>
      <EditButton
        onClick={() => handleClick()}
      >
        x
      </EditButton>
    </DropdownWrap>
  )
}

export default FilledMLSTab
