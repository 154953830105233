import { createSignalAction, createSimpleCreator } from "shared/reduxUtils"

const BASE = "REALTY"

export const submitNewsLetterAction = createSignalAction(BASE, "SUBMIT_NEW_LETTER")
export const submitScheduleACallAction = createSignalAction(BASE, "SUBMIT_SCHEDULE_A_CALL")
export const pageTrackerAction = createSignalAction(BASE, "PAGE_TRACKER")
export const setActiveNavbarSectionAction = createSimpleCreator(BASE, "SET_NAVBAR_SECTION")
// export const toggleVideoModalAction = createSimpleCreator(BASE, "SHOW_VIDEO_MODAL")
export const toggleHubSpotModalAction = createSimpleCreator(BASE, "SHOW_HUBSPOT_MODAL")
//select city for state
export const setCurrentLocationAction = createSimpleCreator(BASE, "SET_CURRENT_LOCATION_ACTION")
export const URLBasedHubspotTrackingAction = createSimpleCreator(BASE, "URL_BASED_HUBSPOT_TRACKING")
//Feature-Viewed
export const brokerageFeatureViewedAction = createSignalAction(BASE, "BROKERAGE_VIEWED")
export const showReferralManagerV2Action = createSimpleCreator(BASE, "TOGGLE_REFERRAL_MANAGER")
export const RARTrackerAction = createSignalAction(BASE, "RAR_TRACKER")
export const togglePlanTypeAction = createSimpleCreator(BASE, "TOGGLE_RAR_PLAN_TYPE")
export const storeRARPlanSelectedAction = createSimpleCreator(BASE, "STORE_CURRENT_RAR_PLAN")
export const storeTeamSizeAction = createSimpleCreator(BASE, "STORE_TEAM_SIZE")
export const rarFormModalToggleAction = createSimpleCreator(BASE, "TOGGLE_RAR_FORM")
export const getCommunityUpcomingEventsForRARDboardAction = createSignalAction(BASE, "RAR_DB_COMMUNITY_UPCOMING_EVENTS")
export const getQuotesAction = createSignalAction(BASE, "GET_QUOTES")
export const getAgentSupportListAction = createSignalAction(BASE, "GET_AGENT_SUPPORT")
export const getBrokerageSupportAction = createSignalAction(BASE, "GET_BROKERAGE_SUPPORT")
export const initiateSupportClickAction = createSignalAction(BASE, "POST_SUPPORT_CLICK")
export const initiateUpgradeClickAction = createSignalAction(BASE, "POST_UPGRADE_CLICK")
export const initiateTransactionCoordinationClickAction = createSignalAction(BASE, "POST_TC_CLICK")
export const getCurrentPlanDetailsAction = createSignalAction(BASE, "GET_CURRENT_PLAN_DETAIL")
export const toggleFilterComponentAction = createSimpleCreator(BASE, "TOGGLE_FILTER_RESOURCES")
export const fetchResourceFiltersAction = createSignalAction(BASE, "FETCH_FILTERS")
export const createFilterMapAction = createSimpleCreator(BASE, "CREATE_FILTERS")
export const updateFilterMapAction = createSimpleCreator(BASE, "UPDATE_FILTERS")
export const fetchResourceDocumentsAction = createSignalAction(BASE, "FETCH_DOCUMENTS")
export const setCurrentFetchResourceSearchQueryAction = createSimpleCreator(BASE, "SET_QUERY")
export const setResourcesPaginationLimitAction = createSimpleCreator(BASE, "SET_RS_PG_LIMIT")
export const setResourcesPaginationSkipAction = createSimpleCreator(BASE, "SET_RS_SKP_LIMIT")
export const toggleInitialSearchAction = createSimpleCreator(BASE, "TOGGLE_INITIAL_SEARCH")
//Transaction Form Actions
export const transactionFormFetchValuesAction = createSignalAction(BASE, "FETCH_TC_FORM_VALUES")
export const transactionFormCreateDealViaFormAction = createSignalAction(BASE, "CREATE_DEAL_TC_FORM")
export const transactionFormUpdateStageAction = createSignalAction(BASE, "TC_FORM_STAGE_UPDATE")
export const transactionFormUpdateAction = createSignalAction(BASE, "TC_FORM_UPDATE")
export const fetchTCFilesAction = createSignalAction(BASE, "TC_FETCH_FILES")
export const uploadTCFilesAction = createSignalAction(BASE, "UPLOAD_TC_FILES")
export const uploadTCFilesToDbAction = createSignalAction(BASE, "UPLOAD_TC_FILES_TO_DB")
export const resetAllTCFromAction = createSignalAction(BASE, "RESET_TC_FORMS")
export const getClientSearchAction = createSignalAction(BASE, "GET_CLIENT_DETAILS")
export const fetchTCDocumentsFromS3Action = createSignalAction(BASE, "GET_TC_S3_FILES")
export const deleteTCDocumentsFromS3Action = createSignalAction(BASE, "DELETE_TC_S3_FILES")
export const triggerSlackNotificationAction = createSignalAction(BASE, "TRIGGER_DEAL_NOTIFICATION")
export const getRarResourcesAction = createSignalAction(BASE, "GET_RAR_RESOURCES")
export const setShowTCBannerFormAction = createSimpleCreator(BASE, "TOGGLE_TC_BANNER")
export const setOfferClaimedAction = createSimpleCreator(BASE, "TC_OFFER_CLAIMED")
export const getTCBannerAction = createSignalAction(BASE, "GET_TC_BANNER_RESPONSE")
export const postTCBannerAction = createSignalAction(BASE, "POST_TC_BANNER_RESPONSE")
/*TEAM PROFILE*/
export const setRTFContentAction = createSimpleCreator(BASE, "SET_RTF_CONTENT")
export const setImageBlocksAction = createSimpleCreator(BASE, "SET_IMAGE_BLOCK")
export const uploadImagesToS3Action = createSignalAction(BASE, "UPLOAD_TEAM_IMAGE_TO_S3")
export const submitTeamDetailsAction = createSignalAction(BASE, "UPLOAD_TEAM_DETAILS")
export const getTeamDetailsAction = createSignalAction(BASE, "GET_TEAM_DETAILS")
export const getTeamDetailsForLogoAction = createSignalAction(BASE, "GET_TEAM_DETAILS_FOR_LOGO")
export const getTeamDetailsViaNameAction = createSignalAction(BASE, "GET_TEAM_DETAILS_VIA_NAME")
export const setCurrentImageToBeCroppedAction = createSimpleCreator(BASE, "IMAGE_TO_BE_CROPPED")
export const setRemovedImagesArrayAction = createSimpleCreator(BASE, "SET_DELETED_IMAGES")
export const toggleDeleteConfirmationAction = createSimpleCreator(BASE, "TOGGLE_DELETE_CONFIRM")
export const fileSizeErrorToggleAction = createSimpleCreator(BASE, "TOGGLE_UPLOAD_ERROR")
export const toggleDeleteModeAction = createSimpleCreator(BASE, "TOGGLE_DELETE_MODE")
export const setCurrentImageTypeToBeDeletedAction = createSimpleCreator(BASE, "IMAGE_TO_BE_DELETED")
export const toggleColorProfileModalAction = createSimpleCreator(BASE, "TOGGLE_COLOR_MODAL")
export const getOtherTeamDetailsAction = createSignalAction(BASE, "GET_OTHER_TEAM_DETAILS")

export const getAllInviteesAction = createSignalAction(BASE, "GET_ALL_INVITEES")

export const toggleRecruitAndEarnFormAction = createSimpleCreator(BASE, "TOGGLE_RAR_INAPP_FORM")

export const toggleSuccessModalAction = createSimpleCreator(BASE, "TOGGLE_SUCCESS_MODAL")
export const toggleTeamProfileEditModeAction = createSimpleCreator(BASE, "TOGGLE_TEAM_PROFILE_EDIT_MODE")
export const getTCAgentAssignedAction = createSignalAction(BASE, "GET_TC_AGENT_ASSIGNED")
export const getTeamTCs = createSignalAction(BASE, "GET_TEAM_TCS")
export const inviteTeamTCs = createSignalAction(BASE, "INVITE_TEAM_TCS")
export const removeTeamTCAction = createSignalAction(BASE, "REMOVE_TEAM_TCS")
export const saveTeamMembersAction = createSignalAction(BASE, "SAVE_TEAM_MEMBERS")
export const fetchTeamMembersListAction = createSignalAction(BASE, "FETCH_TEAM_MEMBERS")

// Room Calendar
export const getRoomCalendarEventsAction = createSignalAction(BASE, "GET_ROOMS_CALENDAR_EVENTS")

// Cashback
export const getCashbackProgramAction = createSignalAction(BASE, "GET_CASHBACK_PROGRAM")

// TC Payment Form
export const toggleCommissionsPopup = createSimpleCreator(BASE, "TOGGLE_COMMISSIONS_POPUP")
export const getOnboardingCommission = createSignalAction(BASE, "GET_ONBOARDING_COMMISSION")
export const createOnboardingCommission = createSignalAction(BASE, "CREATE_ONBOARDING_COMMISSION")
export const updateOnboardingCommission = createSignalAction(BASE, "UPDATENBOARDING_COMMISSION")

export const toggleCreateTCInvitation = createSimpleCreator(BASE, "TOGGLE_CREATE_TC_INVITATION")

export const saveDocumentsForUploadToContractsAction = createSimpleCreator(BASE, "SAVE_DOCUMENTS_TO_UPLOAD_ACTION")
export const clearTCDataAction = createSimpleCreator(BASE, "RESET")
