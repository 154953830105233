import { createSelector } from "reselect"
import deepmerge from "deepmerge"
import columnConfig from "dumbComponents/Referral/Inbound/columnConfig"
import { getProductFromPlanId, getProductFromTeamPlanId } from "services/Utils"

const getFilterSkeleton = state => state.referral.filterSkeleton
const getOutboundReferral = state => state.referral.outboundReferrals
const getInboundReferral = state => state.referral.inboundReferrals
export const getCartFromStore = state => state.referral.isaPricing.cart
export const getCouponObjectFromStore = state => state.referral.isaPricing.coupon
export const getPaymentModalStateFromStore = state => state.referral.isaPricing.paymentModalState
export const getSubscriptionsFromStore = state => state.referral.subscriptions
export const getIsFetchingSubscriptions = state => state.referral.isFetchingSubscription
export const getFinishedFetchingSubscriptions = state => state.referral.finishedFetchingSubscriptions
export const getCardsFromStore = state => state.referral.cards
export const getIsFetchingCardFromStore = state => state.referral.isFetchingCard
export const getDefaultCardFromStore = state => state.referral.defaultCard
export const getUpdateCardModalStateFromStore = state => state.referral.updateCardModalState
export const getIsFetchingInvoicesFromStore = state => state.referral.isFetchingInvoices
export const getInvoicesFromStore = state => state.referral.invoices
export const isInterested = state => state.referral.isInterested
export const isSubmittingForm = state => state.referral.isSubmittingForm
export const interestFormSubmitted = state => state.referral.interestFormSubmitted
export const isaFormDropdownValues = state => state.referral.isaDropdownValuesObject
export const isUserConfirmModalOpen = state => state.referral.isUserConfirmModalOpen
export const confirmationModalMessage = state => state.referral.confirmationModalMessage
export const isFormLoaded = state => state.referral.isFormLoaded
export const teamSize = state => state.referral.teamSize
export const checkoutTotal = state => state.referral.checkoutTotal
export const subTotal = state => state.referral.subTotal
export const isCartHavingTeamPlan = state => state.referral.isCartHavingTeamPlan
export const monthlyPlanIfTeam = state => state.referral.monthlyPlanIfTeam
export const teamMembersList = state => state.referral.teamMembersList
export const currentTeamSize = state => state.referral.currentTeamSize
export const pricingPlansMap = state => state.referral.pricingPlansMap
export const plansDuration = state => state.referral.plansDurationResponse
export const currentPlanType = state => state.referral.currentPlanType
export const getShareTheLoveInviteeResponse = state => state.referral.shareTheLoveInviteeResponse

const mapReferralToConfig = (status = "", filters = []) => filters.find(
  x => x.value && x.value.toLowerCase() === status.toLowerCase()
)

export const getMappedOutbounds = createSelector(
  [getFilterSkeleton, getOutboundReferral],
  (filters, outbounds) => {
    const {
      closedReferrals,
      pipelineReferrals,
      inactiveReferrals,
      referralsCount,
    } = outbounds || {}
    const { outboundReferralFilters, clientTypes } = filters || {}
    const mappedData = {}
    if (filters && outbounds) {
      if (pipelineReferrals && pipelineReferrals.length > 0) {
        mappedData.pipelineReferrals = pipelineReferrals.map(item => ({
          ...item,
          statusConfig: { ...mapReferralToConfig(item.referred_client_status, outboundReferralFilters) },
          typeConfig: { ...mapReferralToConfig(item.type, clientTypes) },
        }))
      }
      if (closedReferrals && closedReferrals.length > 0) {
        mappedData.closedReferrals = closedReferrals.map(item => ({
          ...item,
          statusConfig: { ...mapReferralToConfig(item.referred_client_status, outboundReferralFilters) },
          typeConfig: { ...mapReferralToConfig(item.type, clientTypes) },
        }))
      }
      if (inactiveReferrals && inactiveReferrals.length > 0) {
        mappedData.submittedReferrals = inactiveReferrals.map(item => ({
          ...item,
          statusConfig: { ...mapReferralToConfig(item.referred_client_status, outboundReferralFilters) },
          typeConfig: { ...mapReferralToConfig(item.type, clientTypes) },
        }))
      }
    }
    return { ...mappedData, referralsCount }
  }
)

export const getMappedInbounds = createSelector(
  [getInboundReferral],
  (inbounds) => {
    if (inbounds) {
      const {
        allReferrals,
        dragDropColumns,
        checklistData,
        referralsCount,
      } = inbounds || {}
      return {
        referralsCount,
        allReferrals,
        dragDropColumns,
        checklistData: checklistData || {},
      }
    }
    return {}
  }
)

export const mergeReferralResponse = createSelector(
  [
    params => params.prevReferral,
    params => params.currentReferrals,
  ],
  (prevReferral, currentReferrals) => {
    const parsedResp = deepmerge(prevReferral, currentReferrals)
    return {
      ...parsedResp,
    }
  }
)

export const getProcessedInboundReferrals = createSelector(
  [params => params],
  (referrals) => {
    const allReferrals = {}
    const dragDropColumns = {}
    columnConfig().map((column) => {
      const referralTypeArray = referrals[column.value] || []
      dragDropColumns[column.value] = []
      referralTypeArray.map((referral) => {
        dragDropColumns[column.value].push(referral.id)
        allReferrals[referral.id] = referral
      })
    })
    return {
      allReferrals,
      dragDropColumns,
      referralsCount: referrals.referralsCount,
    }
  }
)

export const getActiveSubscriptionsSelector = createSelector(
  [getSubscriptionsFromStore],
  (subscriptions = []) => {
    const activeSubscriptions = {}
    subscriptions.forEach((subscriptionObj) => {
      if (subscriptionObj && subscriptionObj.plan && subscriptionObj.plan.active) {
        const planId = subscriptionObj.plan.id
        if (subscriptionObj.plan.interval === "month") {
          if (subscriptionObj.plan.interval_count === 1) {
            activeSubscriptions.monthly = planId
          } else if (subscriptionObj.plan.interval_count === 3) {
            activeSubscriptions.threeMonths = planId
          } else if (subscriptionObj.plan.interval_count === 6) {
            activeSubscriptions.sixMonths = planId
          }
        } else if (subscriptionObj.plan.interval === "year") {
          activeSubscriptions.yearly = planId
        }
      } else if (subscriptionObj &&
       subscriptionObj.items.data[0].plan &&
       subscriptionObj.items.data[0].plan.active) {
        activeSubscriptions.teamPlan = subscriptionObj.items.data[0].plan.id
      }
    })

    return activeSubscriptions
  }
)

export const getCategorizedSubscriptions = createSelector(
  [getSubscriptionsFromStore],
  (subscriptions = []) => {
    const categorizedSubscriptions = {}
    subscriptions.forEach((subscriptionObj) => {
      if (subscriptionObj && subscriptionObj.plan && subscriptionObj.plan.active) {
        const planId = subscriptionObj.plan.id
        const product = getProductFromPlanId(planId)
        const teamProduct = getProductFromTeamPlanId(planId)
        categorizedSubscriptions[product] = planId
        categorizedSubscriptions[teamProduct] = planId
      }
      if (subscriptionObj && subscriptionObj.items && subscriptionObj.items.data) {
        const planId = subscriptionObj.items.data[0].plan.id
        const product = getProductFromPlanId(planId)
        const teamProduct = getProductFromTeamPlanId(planId)
        categorizedSubscriptions[product] = planId
        categorizedSubscriptions[teamProduct] = planId
      }
    })
    return categorizedSubscriptions
  }
)
