import {
  takeEvery,
  takeLatest,
  call,
  put,
  select,
} from "redux-saga/effects"
import { get } from "lodash"
import { delay } from "redux-saga"
import {
  isSuccess,
} from "services/Utils"
import root from "window-or-global"
import cookie from "services/CookieStorage"
import uuid from "uuid"
import { toast as createToast } from "react-toastify"
import * as TeamAPI from "./teamApis"

import * as RealtyActions from "./actions"
import * as RoomActions from "../Rooms/actions"
import Realty from "./index"
import customToast from "dumbComponents/OffersV3/components/Toast/customToast"
import { message } from "antd";
const toastConfig = {
  position: "top-center",
  autoClose: 2500,
  hideProgressBar: true,
}

function* handleImageUploadToS3(action) {
  try {
    const {
      file,
      type,
      name,
    } = action.data
    customToast("Uploading")

    const signedRes = yield fetch(`${FILE_UPLOAD_API}filename=${`${uuid()}_${name}` || "image_file_.png"}`)
    const signedResJson = yield signedRes.json()
    const myHeaders = new Headers({ "Content-Type": "image/png" })

    const res = yield fetch(signedResJson.url, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    })

    if (res.status === 200 && signedResJson.fileUrlAfterUpload) {
      yield put(RealtyActions.setImageBlocksAction.call({
        type,
        image: signedResJson.fileUrlAfterUpload,
      }))
      yield put(RealtyActions.setCurrentImageToBeCroppedAction.call({
        type: null,
        image: null,
      }))
      yield put(RealtyActions.uploadImagesToS3Action.success(signedResJson.fileUrlAfterUpload))
      customToast("Image Uploaded")
      yield put(RoomActions.setImageUrlAction.call(signedResJson.fileUrlAfterUpload))
    }
  } catch (e) {
    customToast("Error Uploading Image", "error")
    yield put(RealtyActions.uploadImagesToS3Action.failure(e))
  }
}

function* handleTeamDetailUpload(action) {
  try {
    const {
      payload,
      profile,
      history,
    } = action.data

    const id = cookie.load("brokerageTeamId") || ""

    const res = yield call(TeamAPI.submitTeamDetails, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      customToast("Changes saved")
      yield put(RealtyActions.submitTeamDetailsAction.success(response))
      yield put(RealtyActions.setRemovedImagesArrayAction.call({
        typeRemoved: "teamLogoRemoved",
        bool: false,
      }))
      yield put(RealtyActions.setRemovedImagesArrayAction.call({
        typeRemoved: "teamBannerRemoved",
        bool: false,
      }))
      yield put(RealtyActions.setRemovedImagesArrayAction.call({
        typeRemoved: "teamExtraImageOneRemoved",
        bool: false,
      }))
      yield put(RealtyActions.setRemovedImagesArrayAction.call({
        typeRemoved: "teamExtraImageTwoRemoved",
        bool: false,
      }))
      yield put(RealtyActions.setRemovedImagesArrayAction.call({
        typeRemoved: "teamExtraImageThreeRemoved",
        bool: false,
      }))
      yield put(RealtyActions.setCurrentImageToBeCroppedAction.call({
        type: null,
        image: null,
      }))
      yield put(RealtyActions.getTeamDetailsAction.request({
        id,
      }))
      // root.location.href = `/realty/team-profile/${profile}`
    }
  } catch (e) {
    customToast("Something went wrong", "error")
    yield put(RealtyActions.submitTeamDetailsAction.failure(e))
  }
}

function* handeFetchTeamDetails(action) {
  try {
    const {
      id,
    } = action.data

    const res = yield call(TeamAPI.getTeamDetailsAPI, id)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(RealtyActions.getTeamDetailsAction.success(response))
    }
  } catch (e) {
    customToast("Something went wrong", "error")
    yield put(RealtyActions.getTeamDetailsAction.failure(e))
  }
}

function* handeFetchTeamDetailsForLogo(action) {
  try {
    const {
      id,
    } = action.data

    if (!id) {
      return
    }

    const res = yield call(TeamAPI.getTeamDetailsAPI, id)

    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      yield put(RealtyActions.getTeamDetailsForLogoAction.success(response))
    }
  } catch (e) {
    customToast("Something went wrong", "error")
    yield put(RealtyActions.getTeamDetailsForLogoAction.failure(e))
  }
}

function* handleFetchOtherTeamDetails(action) {
  try {
    const {
      id,
    } = action.data

    const res = yield call(TeamAPI.getTeamDetailsAPI, id)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(RealtyActions.getOtherTeamDetailsAction.success(response))
    }
  } catch (e) {
    customToast("Something went wrong", "error")
    yield put(RealtyActions.getOtherTeamDetailsAction.failure(e))
  }
}

function* handleFetchTeamDetailsViaName(action) {
  try {
    const {
      name,
    } = action.data

    const res = yield call(TeamAPI.getTeamDetailsViaNameAPI, name)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(RealtyActions.getTeamDetailsViaNameAction.success(response))
    }
  } catch (e) {
    customToast("Something went wrong", "error")
    yield put(RealtyActions.getTeamDetailsViaNameAction.failure(e))
  }
}

function* handleFetchTCAgentAssigned(action) {
  try {
    const { agentId } = action.data
    const res = yield call(TeamAPI.getTCAgentAssignedAPI, agentId)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(RealtyActions.getTCAgentAssignedAction.success(response))
    }
  } catch (e) {
    // createToast.error("Something went wrong..", toastConfig)
    yield put(RealtyActions.getTCAgentAssignedAction.failure(e))
  }
}

function* handleGetTeamTCs(action) {
  try {
    const { teamId } = action.data
    const res = yield call(TeamAPI.getTeamTCsAPI, teamId)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(RealtyActions.getTeamTCs.success(response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.error.message") || "Something went wrong, Please try again later!"
    customToast(errorMessage || "Something went wrong", "error")
    yield put(RealtyActions.getTeamTCs.failure(e))
  }
}

function* handleInviteTeamTCs(action) {
  try {
    const { payload } = action.data
    const res = yield call(TeamAPI.inviteTeamTCsAPI, payload)
    const teamDetails = yield select(state => state.teamProfileReducer.getTeamDetailsResponse?.data)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(RealtyActions.inviteTeamTCs.success(response))
      yield put(RealtyActions.toggleCreateTCInvitation.call(false))
      yield put(RealtyActions.getTeamTCs.request({ teamId: teamDetails.id }))
      yield put(RealtyActions.getTeamDetailsAction.request({ id: teamDetails.id }))
    }
  } catch (e) {
    const errorMessage = get(e, "data.error.message") || "Something went wrong, Please try again later!"
    customToast(errorMessage || "Something went wrong", "error")
    yield put(RealtyActions.inviteTeamTCs.failure(e))
  }
}

function* removeTeamTCsSaga(action) {
  try {
    const {payload, callback} = action.data
    const res = yield call(TeamAPI.removeTeamTCsAPI, payload.agentId)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(RealtyActions.removeTeamTCAction.success(response))
      message.success('Removed successfully')
      if (callback) {
        callback();
      }
    }
  } catch (e) {
    message.error('Something went wrong')
    yield put(RealtyActions.removeTeamTCAction.failure(''))
  }
}


function* saveTeamMembersSaga(action) {
  try {
    const { payload, callback } = action.data
    console.log(action, 'data')
    const res = yield call(TeamAPI.saveTeamMembersAPI, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(RealtyActions.saveTeamMembersAction.success(response))
      if (callback) {
        callback();
      }
      message.success('Removed successfully')
      // yield put(RealtyActions.toggleCreateTCInvitation.call(false))
      // yield put(RealtyActions.getTeamTCs.request({ teamId: teamDetails.id }))
      // yield put(RealtyActions.getTeamDetailsAction.request({ id: teamDetails.id }))
    }
  } catch (e) {
  //       const { team_id, payload, callback } = action.data
  //  if (callback) {
  //       callback();
  //     }
    const errorMessage = get(e, "data.error.message") || "Something went wrong, Please try again later!"
    message.error(errorMessage);
    yield put(RealtyActions.saveTeamMembersAction.failure(e))
  }
}


function* fetchTeamMembersListSaga(action) {
  try {
    const brokerage_team_id = action.data
    const res = yield call(TeamAPI.fetchTeamMembersListAPI, brokerage_team_id)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(RealtyActions.fetchTeamMembersListAction.success(response))
    }
  } catch (e) {
    // createToast.error("Something went wrong..", toastConfig)
    yield put(RealtyActions.fetchTeamMembersListAction.failure(e))
  }
}

export default function* main() {
  yield takeEvery(RealtyActions.uploadImagesToS3Action.REQUEST, handleImageUploadToS3)
  yield takeLatest(RealtyActions.submitTeamDetailsAction.REQUEST, handleTeamDetailUpload)
  yield takeLatest(RealtyActions.getTeamDetailsAction.REQUEST, handeFetchTeamDetails)
  yield takeLatest(RealtyActions.getTeamDetailsForLogoAction.REQUEST, handeFetchTeamDetailsForLogo)
  yield takeLatest(RealtyActions.getTeamDetailsViaNameAction.REQUEST, handleFetchTeamDetailsViaName)
  yield takeLatest(RealtyActions.getOtherTeamDetailsAction.REQUEST, handleFetchOtherTeamDetails)
  yield takeLatest(RealtyActions.getTCAgentAssignedAction.REQUEST, handleFetchTCAgentAssigned)
  yield takeLatest(RealtyActions.getTeamTCs.REQUEST, handleGetTeamTCs)
  yield takeLatest(RealtyActions.inviteTeamTCs.REQUEST, handleInviteTeamTCs)
  yield takeLatest(RealtyActions.removeTeamTCAction.REQUEST, removeTeamTCsSaga)
  yield takeLatest(RealtyActions.saveTeamMembersAction.REQUEST, saveTeamMembersSaga)
  yield takeLatest(RealtyActions.fetchTeamMembersListAction.REQUEST, fetchTeamMembersListSaga)
}
