import React from "react"
import styled from "styled-components"
import { RadioGroup } from "@ui/Form"
import { reduxForm, Field } from "redux-form"

const Wrap = styled.div``

const FilterComponent = ({
  activeCategory,
  setActiveCategoryValue,
}) => (
  <Wrap>
    <Field
      name="sale_type"
      component={RadioGroup}
      fd="column"
      mt="12px"
      clr="#5A5FF2"
      options={[
        { label: "Closed Deals", value: "closed_deals" },
        { label: "Agents Referred", value: "agents_referred" },
        { label: "Lead Conversion Percentage", value: "lead_conversion" },
        { label: "Leads Claimed", value: "leads_claimed" },
        { label: "Total Commission Earned", value: "commission" },
        { label: "Referrals Sent", value: "leads_submitted" },
      ]}
      input={{
        onChange: setActiveCategoryValue,
        value: activeCategory,
      }}
    />
  </Wrap>
)

export default reduxForm({
  form: "LEADERBOARD_FILTER",
})(FilterComponent)
