/* eslint-disable linebreak-style */
import React from "react";
import styled from "styled-components";
import colors from "@colors";
import { withRouter, Redirect, Link } from "react-router-dom";
import { get, isEmpty } from "lodash";
import query from "query-string";
import app from "container/App";
import { handleDataLayerPush } from "services/dataLayerUtil";
import Footer from "dumbComponents/common/FooterNew";
import CircularImage from "dumbComponents/common/CircularImage";
import moment from "moment";
import CookiesStorage from "services/CookieStorage";
import { Helmet } from "react-helmet";
import ShareModal from "dumbComponents/common/ShareModal";
import PostInRadiusFeed from "dumbComponents/PostInRadiusFeed";
import Button from "@ui/Button";
import ThreeDotLoader from "@ui/ThreeDotLoader";
import icons from "@icons";
import { authCheck } from "services/auth";
/**Image Files */
import { ATC } from "@fonts";

import CreateEditPostContainer from "container/Network/CreateEditPost";
import AddIcon from "images/Graphics/ScheduledPageAssets/Add.png";
import PlusIcon from "images/Graphics/ScheduledPageAssets/Checkmark.png";
import {
  unicodeToChar,
  TimeZone,
  pstTimeZone,
  customeTimeZone,
  unixToDate,
} from "services/Utils";
import { google } from "calendar-link";
import AddToCalenderUI from "dumbComponents/EventRooms/AddToCalender";
/**Image Files */
import {
  preTabletWidth,
  minDesktopWidth,
  minTabletWidth,
  preDesktopWidth,
} from "shared/consts";
import Loader from "@ui/Loader";
import root from "window-or-global";
import container from "container/EventRoom";
import Rooms from "container/Rooms/index";
import DeleteRoomModal from "dumbComponents/Network/MiddleContainer/RoomsSection/DeleteRoomModal";
import EditRoom from "dumbComponents/Network/MiddleContainer/RoomsSection/CreateRoomV2";
import CancelScheduledRoomsModal from "./ConfirmRoomCancel";
import { filterBasedOnRole, ROLES } from "./DetailsWrap";
import ScheduleRoomRegisterUI from "./ScheduleRoomRegisterUI";
import ShareRoomModal from "./ShareRoomModal";
import RoomsModal from "../VideoRooms/Modal/RoomsModal";
import { EVENT_DATE_FORMAT } from "../Leaderboard/Leaderboard";

const FREQUENCY_OPTIONS = [
  { value: 1, label: "Daily" },
  { value: 7, label: "Weekly" },
  { value: 30, label: "Monthly" },
  { value: 0, label: "Do not repeat" },
];

const ParentWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1500px;
  margin: 53px auto;
`;

const AttendeesWrap = styled.div``;

const AttendeeInner = styled.div`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 10px;

  width: 343px;
  height: 128px;

  /* Brand Colors/Radius Agent/Supporting Greys/Grey 2 */

  border: 1px solid #bebebe;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;

const RegistedCountText = styled.p`
  font-family: ${ATC};
  font-size: ${(props) => props.fs || "22px"};
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
`;

const Event = styled.div`
  padding: 20px;

  @media (min-width: 768px) {
    padding: 20px 50px 130px 50px;
  }

  @media (min-width: 1099px) {
    padding: 20px 50px 130px 50px;
    width: 75%;
    max-width: 1280px;
  }

  ${preTabletWidth`
    padding: 30px 30px 100px 30px;
  `}
`;

const BackButton = styled(Button)`
  color: ${colors.new_primary};
  background-color: white;
  border: none;
  align-items: baseline;
  margin: 10px 0px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  width: 150px;
  display: flex;
  padding: 0px;
  i {
    display: inline-block;
    top: 1px;
    position: relative;
    transform: rotate(180deg);
  }
  span {
    margin-left: 15px;
  }
`;

const Wrap = styled.div`
  margin-bottom: 10px;
  h1 {
    font-size: 40px;
    text-align: center;
  }
`;

const RegisteredOrRsVpMob = styled.button`
  display: none;
  @media (max-width: 767px) {
    position: fixed;
    bottom: 0px;
    display: block;
    height: 50px;
    width: 100%;
    cursor: pointer;
    background-color: ${colors.new_primary};
    color: white;
    border: none;
    font-size: 18px;

    ${(props) =>
      props.disabled &&
      `
      pointer: not-allowed;
      background-color: grey;
      opacity: 0.4;
    `}
  }
`;

const RedirectToCommunity = styled(Link)`
  text-decoration: none;

  @media (max-width: 425px) {
    display: none;
  }
`;

const ContentWrap = styled.div`
  margin-bottom: 53px;
`;
const ContainerWrapper = styled.div``;

const ShareWrap = styled.div``;

const DateTextDiv = styled.div`
  p {
    font-family: ${ATC};
    font-size: 24px;
    font-weight: 300;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.black};
  }
`;

const TitleCardDiv = styled.div`
  h1 {
    font-family: Butler;
    font-size: 54px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0.005em;
    text-align: left;
    color: ${colors.black};

    @media (max-width: 1099px) {
      font-size: 3rem;
    }
  }
`;

const CTAActionDiv = styled.div`
  display: flex;
  align-items: end;
  justify-content: flex-start;
  gap: 15px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    max-width: 160px;
  }
`;

const DescriptionDiv = styled.div`
  margin-top: 65px;
  display: flex;
  gap: 100px;
  align-items: flex-start;
  justify-content: space-between;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 57px;
  }
`;

const SpeakerDescriptionDiv = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Description = styled.div`
  p {
    font-family: ${ATC};
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }

  h2 {
    font-family: ${ATC};
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
  }

  @media (min-width: 768px) {
    max-width: 500px;
  }

  @media (min-width: 1099px) {
    max-width: 787px;
  }
`;

const AttendeeCircleWrap = styled.div`
  display: flex;
`;

const AboutSpeakerWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  max-width: 335px;
`;

const SpeakerContainerWrap = styled.div`
  margin-bottom: 57px;
`;

const AboutSpeakerTitle = styled.h1`
  font-family: ${ATC};
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 18px;
`;

const SpeakerHostWrap = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: row;
`;

const ImageSection = styled.div``;

const AboutSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const HostedBy = styled.p`
  font-family: ${ATC};
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
  margin: 0;
`;

const SpeakerNameWrap = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
  position: relative;
`;

const SpeakerName = styled.h1`
  font-family: ${ATC};
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
  margin: 0;
  width: max-content;
`;

const SpeakerProfession = styled.h2`
  font-family: ${ATC};
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #8c8c8c;
  margin: -10px 0px;
`;

const SpeakerContentDiv = styled.div``;

const SpeakerContent = styled.p`
  font-family: ${ATC};
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
`;

const AttendeeCircle = styled.div`
  height: 42px;
  width: 42px;
  background: red;
  border-radius: 330.812744140625px;
`;

const RegisterButton = styled.button`
  /* Buttons */


  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 10px;
  border: none;

  cursor: pointer;

  width: max-content;
  height: 40px;

  /* Brand Colors/Radius Agent/Primary/Sapphire */

  background: #5A5FF2;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  font-family: ${ATC};
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  color: white;

  ${(props) =>
    props.disabled &&
    `
      pointer: not-allowed;
      background-color: grey;
  `}

  ${(props) =>
    props.inverted &&
    `
    border: 1px solid #5A5FF2;
    background: white;
    color: #5A5FF2;
  `}

  ${(props) =>
    props.registered &&
    `
    border: none;
    padding: 20px 0px 20px 0px;

    @media(max-width: 767px) {
      padding: 12px 20px 20px 0px;
    }
  `}

  img {
    height: 16px;
    width: 16px;
  }
`;

const SpeakerFlexWrap = styled.div`
  display: flex;

  @media (min-width: 768px) {
    flex-wrap: wrap;
    gap: 38px;
    flex-direction: column;
  }

  @media (min-width: 1099px) {
    gap: 50px;
    flex-wrap: wrap;
    flex-direction: row;
  }

  @media (min-width: 1366px) {
    gap: 56px;
    flex-wrap: wrap;
    flex-direction: row;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 50px;
  }
`;

const Follow = styled.button`
  display: flex;
  align-items: flex-start;
  position: relative;
  top: -2px;
  gap: 8px;
  cursor: pointer;
  background: transparent;
  text-align: center;
  border: none;

  p {
    font-family: ${ATC};
    font-size: 15px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
    color: #849df8;
  }
  img {
    height: 16px;
    width: 16px;
  }
`;

const StyledCalendarButton = styled(Button)`
  @media (max-width: 767px) {
    width: max-content;
  }
`;

const FirstRegister = styled.p`
  font-family: ${ATC};
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  -webkit-letter-spacing: 0em;
  -moz-letter-spacing: 0em;
  -ms-letter-spacing: 0em;
  letter-spacing: 0em;
  text-align: center;
`;

class ScheduledOnboardPage extends React.Component {
  state = {
    isCancelRoomModalOpen: false,
    agentId: "",
  };

  componentDidMount() {
    if (root.HubSpotConversations) {
      root.HubSpotConversations.widget.remove();
    }

    const {
      getScheduledRoomsDetails,
      switchRegister,
      scheduledRoomPublicResponse,
    } = this.props;
    const eventRoomId = get(this.props, "match.params.roomId");
    const { search } = root.location;
    const parsedQuery = query.parse(search);

    const eventData =
      scheduledRoomPublicResponse &&
      scheduledRoomPublicResponse.length > 0 &&
      scheduledRoomPublicResponse[0];

    if (parsedQuery.redirectToRSVP) {
      switchRegister(true);
    }

    const agentId = CookiesStorage.load("agentId");

    if (agentId) {
      this.setState({
        agentId,
      });
    }

    this.setState({
      roomId: parseInt(eventRoomId, 10),
      isForMeta: parsedQuery && parsedQuery.formeta,
    });
    if (getScheduledRoomsDetails && eventRoomId && !eventData) {
      getScheduledRoomsDetails({
        roomId: eventRoomId,
        callback: this.joinRoom,
      });
    }
  }

  getFrequency = (freq) =>
    FREQUENCY_OPTIONS.filter((item) => item.value === freq)[0].label;

  handleUserAction = (isHost, imageUrl, eventData = {}, date = "") => {
    const {
      switchRegister,
      toggleCreateRoomModal,
      createRoomPageSwitch,
      setImage,
      getRoomsMetaDataConfig,
      user,
    } = this.props;

    //GTM: REQUIRES FIX
    const payload = {
      room_date: date,
      hosted_by: "Radius Host User",
      speakers: "",
      room_type: "upcoming",
      room_name: eventData.name,
    };

    const speakerFilter =
      (eventData.invited_attendees &&
        eventData.invited_attendees
          .filter((speakersRole) => speakersRole.Role === "speaker")
          .map((speakers) => `${speakers.firstname} ${speakers.lastname}`)) ||
      null;

    if (speakerFilter && speakerFilter.length > 0) {
      payload.speakers = speakerFilter.join(",");
    }

    //room_register_clicked

    if (isHost) {
      // edit room flow
      getRoomsMetaDataConfig();
      setImage(imageUrl);
      createRoomPageSwitch({
        page: "room-details",
        type: "scheduled",
      });
      toggleCreateRoomModal(true);
    } else {
      handleDataLayerPush(
        user,
        "room_register_clicked",
        "room_register",
        payload
      );
      switchRegister(true);
    }
  };

  getEventDuration = (duration) => {
    if (duration == "15m") {
      return 900;
    } else if (duration == "30m") {
      return 1800;
    } else if (duration == "45m") {
      return 2700;
    } else {
      return 3600;
    }
  };

  getAddToCalendarLink = (event) => {
    const liveRoomName = event.name
      .replace(
        /([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF]|[^a-zA-Z ])/g,
        ""
      )
      .split(" ")
      .join("-")
      .substr(0, 50);
    const calenderEventDescription = `Please join the conversation "${event.name}" at <a href="www.radiusagent.com/scheduledrooms/${event.id}/${liveRoomName}">www.radiusagent.com/scheduledrooms/${event.id}/${liveRoomName}</a>`;
    const start = unixToDate(event.scheduled_at);

    const durationInSeconds = this.getEventDuration(event.event_duration);
    const end = new Date(start.getTime() + durationInSeconds * 1000);
    const payload = {
      title: event.name,
      start: start.toISOString(),
      end: end.toISOString(),
      description: calenderEventDescription,
    };
    return google(payload);
  };

  getFontSize = (title) => (title.length > 40 ? "55px" : "70px");

  startRoom = (eventData) => {
    const { createRoom, history } = this.props;

    const {
      name,
      tags,
      description,
      invited_agent_ids,
      is_public,
      image_url,
      external_url,
    } = eventData || {};

    const eventRoomId = get(this.props, "match.params.roomId");

    const payload = {
      invited_agent_ids: invited_agent_ids ? [...invited_agent_ids] : null,
      name,
      is_public,
      is_video_room: 1,
      tags,
      description,
      scheduled_room_id: parseInt(eventRoomId, 10),
      image_url,
    };

    if (
      external_url &&
      external_url !== null &&
      external_url !== undefined &&
      external_url.length > 0
    ) {
      payload.external_url = external_url;
    }

    createRoom({
      payload,
      isRedirect: true,
      history,
      external_url,
    });
  };

  handleCancelScheduledRoomsEnd() {
    const { cancelScheduledRooms, history } = this.props;

    const eventRoomId = get(this.props, "match.params.roomId");

    cancelScheduledRooms({
      roomId: parseInt(eventRoomId, 10),
      history,
    });
  }

  render() {
    const {
      scheduledRoomPublicResponse,
      switchToRegisterUI,
      isShareRoomModal,
      toggleShareLinkModal,
      isShowCreateRoomModal,
      toggleCreateRoomModal,
      toggleConfirmSignInModal,
      isConfirmSignInModalOpen,
      createRoomResponse,
      isShowPostInRadiusFeed,
      togglePostInRadiusFeed,
      isShowDeleteConfirmation,
      toggleDeleteRoomModal,
      user: userData,
    } = this.props || {};

    const { isCancelRoomModalOpen, agentId } = this.state || {};

    const { isFetching: fetchingRoomData } = scheduledRoomPublicResponse || {};

    const { isFetching: createRoomFetching } = createRoomResponse || {};

    const eventRoomId = get(this.props, "match.params.roomId");

    const eventData =
      scheduledRoomPublicResponse &&
      (scheduledRoomPublicResponse.length
        ? scheduledRoomPublicResponse[0]
        : get(scheduledRoomPublicResponse, "data[0]"));
    const { search } = root.location;
    const parsedQuery = query.parse(search);
    const isForMetaData = parsedQuery && parsedQuery.formeta;
    const speakerCount = filterBasedOnRole(
      get(eventData, "attendees"),
      ROLES.SPEAKER
    );
    const listenerCount = filterBasedOnRole(
      get(eventData, "attendees"),
      ROLES.ATTENDEE
    );
    const totalCount = speakerCount.length + listenerCount.length;
    const currentAgent = CookiesStorage.load("agentId");
    const isHost =
      !isEmpty(eventData) && eventData.host_id === parseInt(currentAgent, 10);
    const isLive = !isEmpty(eventData) && eventData.is_live;
    const liveRoomId = !isEmpty(eventData) && eventData.live_room_id;
    const liveRoomName =
      !isEmpty(eventData) &&
      eventData.name
        .replace(
          /([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF]|[^a-zA-Z ])/g,
          ""
        )
        .split(" ")
        .join("-")
        .substr(0, 50);
    const isVideoRooms = !isEmpty(eventData) && eventData.is_video_room;
    const ogImage = !isEmpty(eventData) && eventData.image_url;
    const calcRemainingTime =
      !isEmpty(eventData) &&
      (eventData.scheduled_at * 1000 - Date.now()) / 1000;
    const startRoomVisible =
      calcRemainingTime &&
      calcRemainingTime <= 1800 &&
      eventData.host_id === parseInt(currentAgent, 10);
    const isRegistered =
      !isEmpty(eventData) &&
      eventData.invited_attendees &&
      !isEmpty(eventData.invited_attendees) &&
      eventData.invited_attendees.filter(
        (attendee) => attendee.id === parseInt(currentAgent, 10)
      );
    const eventTime = !isEmpty(eventData) && eventData.scheduled_at;
    const timeZone = !isEmpty(eventData) && eventData.time_zone;
    const meta = {
      title: "Don't miss this event!",
      description:
        "Radius Rooms is a live audio/video chat room created with the sole purpose of educating and sharing experiences by real estate professionals. It's a great way to network with other real estate agents and brokers",
      keywords: "Radius Live Rooms",
      type: "website",
      url: `${WEB_URL}scheduledrooms`,
      image: "",
    };

    const systemTime = TimeZone(eventTime);
    const { localeTimeString, localeDateString } = systemTime;
    const PSTTime = pstTimeZone(eventTime);
    const { pstTimeString, pstDateString } = PSTTime;
    const customeTime = customeTimeZone(eventTime, timeZone, true);
    const { customTimeString, customDateString } = customeTime;

    if (eventData && eventData.name) {
      meta.title = ` Don't miss this event on ${pstDateString} at ${pstTimeString} PST - ${unicodeToChar(
        eventData.name
      )} `;
      meta.image = ogImage;
    }
    if (eventData && eventData.host && eventData.host.firstname) {
      meta.title += `hosted by ${eventData.host.firstname} ${eventData.host.lastname} `;
      meta.url += `/${eventData.id}`;
    }

    const redirectToRoom = eventData && isLive;

    if (redirectToRoom) {
      return <Redirect to={`/rooms/${liveRoomId}/live/${liveRoomName}`} />;
    }

    return (
      <>
        <Helmet>
          <title>{meta.title}</title>
          <meta property="og:title" content={meta.title} />
          <meta property="title" content={meta.title} />
          <meta property="og:url" content={meta.url} />
          <meta property="url" content={meta.url} />
          <meta property="og:description" content={meta.description} />
          <meta property="description" content={meta.description} />
          <meta property="og:image" content={meta.image} />
          <meta property="image" content={meta.image} />
          <meta property="keywords" content={meta.keywords} />
        </Helmet>
        {fetchingRoomData && isEmpty(eventData) ? (
          <Loader />
        ) : !isEmpty(eventData) ? (
          <>
            {switchToRegisterUI ? (
              <ScheduleRoomRegisterUI
                id={eventRoomId}
                getAddToCalendarLink={this.getAddToCalendarLink}
                eventData={eventData}
                toggleConfirmSignInModal={toggleConfirmSignInModal}
                isConfirmSignInModalOpen={isConfirmSignInModalOpen}
                agentId={agentId}
              />
            ) : (
              <ParentWrap>
                <Event>
                  <ContentWrap>
                    <RedirectToCommunity to="/rooms">
                      <BackButton>
                        <i className={icons.arrowsArrowRight}></i>
                        <span>Back to rooms</span>
                      </BackButton>
                    </RedirectToCommunity>
                  </ContentWrap>
                  <ContainerWrapper>
                    <DateTextDiv>
                      <p>{`${localeTimeString} | ${localeDateString}`}</p>
                    </DateTextDiv>
                    <TitleCardDiv>
                      <h1>{unicodeToChar(eventData.name)}</h1>
                    </TitleCardDiv>
                    {isHost ? (
                      <CTAActionDiv>
                        {startRoomVisible ? (
                          <RegisterButton
                            type="button"
                            onClick={() => this.startRoom(eventData)}
                            disabled={createRoomFetching}
                          >
                            {createRoomFetching ? (
                              <ThreeDotLoader color="#fff" />
                            ) : (
                              "Start Room"
                            )}
                          </RegisterButton>
                        ) : (
                          <RegisterButton
                            type="button"
                            onClick={() =>
                              this.handleUserAction(isHost, eventData.image_url)
                            }
                          >
                            Edit Room
                          </RegisterButton>
                        )}
                        <AddToCalenderUI event={eventData} noPosRepos>
                          <StyledCalendarButton
                            type="submit"
                            value="Add to Calendar"
                            bsStyle="marineBLueOutlined"
                            style={{
                              borderRadius: "4px",
                              width: "max-content",
                              height: "40px",
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                              border: "1px solid #5A5FF2",
                              fontWeight: "300",
                            }}
                            onClick={(e) => {
                              root.open(
                                this.getAddToCalendarLink(eventData || {})
                              );
                            }}
                          >
                            Add To Calendar
                          </StyledCalendarButton>
                        </AddToCalenderUI>
                        <RegisterButton
                          inverted
                          type="button"
                          onClick={() => {
                            toggleShareLinkModal(true);
                          }}
                        >
                          Share
                        </RegisterButton>
                      </CTAActionDiv>
                    ) : (
                      !isHost && (
                        <CTAActionDiv>
                          {!isEmpty(isRegistered) ? (
                            <>
                              <RegisterButton
                                type="button"
                                inverted
                                registered
                                onClick={() => {}}
                              >
                                Registered
                                <img src={PlusIcon} alt="plus.png" />
                              </RegisterButton>
                              <AddToCalenderUI event={eventData} noPosRepos>
                                <StyledCalendarButton
                                  type="submit"
                                  value="Add to Calendar"
                                  bsStyle="marineBLueOutlined"
                                  style={{
                                    borderRadius: "4px",
                                    width: "max-content",
                                    height: "40px",
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                    border: "1px solid #5A5FF2",
                                    fontWeight: "300",
                                  }}
                                  onClick={() => {
                                    root.open(
                                      this.getAddToCalendarLink(eventData || {})
                                    );
                                  }}
                                >
                                  Add To Calendar
                                </StyledCalendarButton>
                              </AddToCalenderUI>
                              <RegisterButton
                                inverted
                                type="button"
                                onClick={() => {
                                  toggleShareLinkModal(true);
                                }}
                              >
                                Share
                              </RegisterButton>
                            </>
                          ) : (
                            <RegisterButton
                              type="button"
                              className="rooms_register"
                              onClick={() =>
                                this.handleUserAction(
                                  isHost,
                                  eventData.image_url,
                                  eventData,
                                  `${pstDateString} ${pstTimeString}`
                                )
                              }
                            >
                              Register
                            </RegisterButton>
                          )}
                        </CTAActionDiv>
                      )
                    )}
                    <DescriptionDiv>
                      <Description>
                        <p>About the Room</p>
                        <h2>{eventData.description}</h2>
                      </Description>
                      <AttendeesWrap>
                        {isHost ? (
                          <AttendeeInner>
                            {eventData.attendees_count !== null &&
                            eventData.attendees_count > 0 ? (
                              <>
                                <RegistedCountText>{`Registered (${eventData.attendees_count})`}</RegistedCountText>
                                <AttendeeCircleWrap>
                                  {eventData.registered_attendees &&
                                    eventData.registered_attendees.map(
                                      (item, index) => {
                                        if (index > 4) {
                                          return;
                                        }
                                        return (
                                          <CircularImage
                                            size={42}
                                            charSize="16"
                                            image={item.image_url}
                                            name={`${item.firstname ||
                                              ""} ${item.lastname || ""}`}
                                          />
                                        );
                                      }
                                    )}
                                </AttendeeCircleWrap>
                              </>
                            ) : (
                              <>
                                <FirstRegister>
                                  Build audience for your event.
                                </FirstRegister>
                                <RegisterButton
                                  inverted
                                  type="button"
                                  onClick={() => {
                                    toggleShareLinkModal(true);
                                  }}
                                >
                                  Create Invites
                                </RegisterButton>
                              </>
                            )}
                          </AttendeeInner>
                        ) : (
                          <AttendeeInner>
                            {eventData.attendees_count !== null &&
                            eventData.attendees_count > 0 ? (
                              <>
                                <RegistedCountText>{`Registered (${eventData.attendees_count})`}</RegistedCountText>
                                <AttendeeCircleWrap>
                                  {eventData.registered_attendees &&
                                    eventData.registered_attendees.map(
                                      (item, index) => {
                                        if (index > 4) {
                                          return;
                                        }
                                        return (
                                          <CircularImage
                                            size={42}
                                            charSize="16"
                                            image={item.image_url}
                                            name={`${item.firstname ||
                                              ""} ${item.lastname || ""}`}
                                          />
                                        );
                                      }
                                    )}
                                </AttendeeCircleWrap>
                              </>
                            ) : (
                              <>
                                <FirstRegister>Join this event.</FirstRegister>
                                <RegisterButton
                                  type="button"
                                  onClick={() =>
                                    this.handleUserAction(
                                      isHost,
                                      eventData.image_url,
                                      eventData,
                                      `${pstDateString} ${pstTimeString}`
                                    )
                                  }
                                >
                                  Register
                                </RegisterButton>
                              </>
                            )}
                          </AttendeeInner>
                        )}
                      </AttendeesWrap>
                    </DescriptionDiv>
                    <SpeakerContainerWrap>
                      <AboutSpeakerTitle>Speaker Details</AboutSpeakerTitle>
                      <SpeakerFlexWrap>
                        <SpeakerDescriptionDiv>
                          <AboutSpeakerWrap>
                            <SpeakerHostWrap>
                              <ImageSection>
                                <CircularImage
                                  size={71}
                                  charSize="16"
                                  image={eventData.host.image_url}
                                  name={`${eventData.host.firstname ||
                                    ""} ${eventData.host.lastname || ""}`}
                                />
                              </ImageSection>
                              <AboutSection>
                                <HostedBy>Hosted by:</HostedBy>
                                <SpeakerNameWrap>
                                  <SpeakerName>{`${
                                    eventData.host.firstname
                                  } ${eventData.host.lastname ||
                                    ""}`}</SpeakerName>
                                  <Follow
                                    onClick={() => {
                                      if (eventData.host.profile_url) {
                                        const url = eventData.host.profile_url;
                                        root.open(url, "_blank");
                                      }
                                    }}
                                  >
                                    <p>Follow</p>
                                    <img src={AddIcon} alt="add.png" />
                                  </Follow>
                                </SpeakerNameWrap>
                                <SpeakerProfession>
                                  {eventData.host.agency_name}
                                </SpeakerProfession>
                              </AboutSection>
                            </SpeakerHostWrap>
                            <SpeakerContentDiv>
                              <SpeakerContent>
                                {eventData.host.about_agent}
                              </SpeakerContent>
                            </SpeakerContentDiv>
                          </AboutSpeakerWrap>
                        </SpeakerDescriptionDiv>
                        {eventData.invited_attendees &&
                          eventData.invited_attendees.map((item, count) => {
                            if (count > 1 || item.Role === "listener") {
                              return;
                            }

                            return (
                              <SpeakerDescriptionDiv>
                                <AboutSpeakerWrap>
                                  <SpeakerHostWrap>
                                    <ImageSection>
                                      <CircularImage
                                        size={71}
                                        charSize="16"
                                        image={item.image_url}
                                        name={`${item.firstname ||
                                          ""} ${item.lastname || ""}`}
                                      />
                                    </ImageSection>
                                    <AboutSection>
                                      <HostedBy>Speaker</HostedBy>
                                      <SpeakerNameWrap>
                                        <SpeakerName>{`${
                                          item.firstname
                                        } ${item.lastname || ""}`}</SpeakerName>
                                        <Follow
                                          onClick={() => {
                                            if (item.profile_url) {
                                              const url = item.profile_url;
                                              root.open(url, "_blank");
                                            }
                                          }}
                                        >
                                          <p>Follow</p>
                                          <img src={AddIcon} alt="add.png" />
                                        </Follow>
                                      </SpeakerNameWrap>
                                      <SpeakerProfession>
                                        {item.agency_name}
                                      </SpeakerProfession>
                                    </AboutSection>
                                  </SpeakerHostWrap>
                                  <SpeakerContentDiv>
                                    <SpeakerContent>
                                      {item.about_agent}
                                    </SpeakerContent>
                                  </SpeakerContentDiv>
                                </AboutSpeakerWrap>
                              </SpeakerDescriptionDiv>
                            );
                          })}
                      </SpeakerFlexWrap>
                    </SpeakerContainerWrap>
                    <ShareWrap>
                      <AboutSpeakerTitle>Share</AboutSpeakerTitle>
                      <CTAActionDiv>
                        <RegisterButton
                          inverted
                          type="button"
                          onClick={() => {
                            toggleShareLinkModal(true);
                            const payload = {
                              room_date: `${pstDateString} ${pstTimeString}`,
                              hosted_by:
                                eventData && eventData.host
                                  ? `${eventData.host.firstname} ${eventData
                                      .host.lastname || ""}`
                                  : "",
                              speakers: "",
                              room_type: "upcoming",
                              room_name: eventData.name,
                            };

                            const speakerFilter =
                              (eventData.invited_attendees &&
                                eventData.invited_attendees
                                  .filter(
                                    (speakersRole) =>
                                      speakersRole.Role === "speaker"
                                  )
                                  .map(
                                    (speakers) =>
                                      `${speakers.firstname} ${speakers.lastname}`
                                  )) ||
                              null;

                            if (speakerFilter && speakerFilter.length > 0) {
                              payload.speakers = speakerFilter.join(",");
                            }

                            handleDataLayerPush(
                              userData,
                              "room_share_with_others",
                              eventData.name,
                              payload
                            );
                          }}
                        >
                          Share on Radius Feed
                        </RegisterButton>
                      </CTAActionDiv>
                    </ShareWrap>
                  </ContainerWrapper>
                </Event>
                {isEmpty(isRegistered) && !isHost && (
                  <RegisteredOrRsVpMob
                    type="button"
                    onClick={() =>
                      this.handleUserAction(
                        isHost,
                        eventData.image_url,
                        eventData,
                        `${pstDateString} ${pstTimeString}`
                      )
                    }
                    style={{
                      borderRadius: "0px",
                    }}
                  >
                    RSVP
                  </RegisteredOrRsVpMob>
                )}
              </ParentWrap>
            )}
          </>
        ) : (
          <Wrap>
            <h1>Rooms has already ended.</h1>
          </Wrap>
        )}
        {isShareRoomModal && (
          <ShareRoomModal
            show
            toClose={() => {
              toggleShareLinkModal(false);
            }}
          >
            <ShareModal
              data={eventData}
              roomId={eventRoomId}
              scheduleRooms
              name={eventData.name}
              toClose={() => toggleShareLinkModal(false)}
              date={`${pstDateString} ${pstTimeString}`}
            />
          </ShareRoomModal>
        )}
        {isCancelRoomModalOpen && (
          <ShareRoomModal
            show
            toClose={() => {
              this.setState({
                isCancelRoomModalOpen: false,
              });
            }}
          >
            <CancelScheduledRoomsModal
              // eslint-disable-next-line react/jsx-no-bind
              endRoom={this.handleCancelScheduledRoomsEnd.bind(this)}
              onCancel={() => {
                this.setState({
                  isCancelRoomModalOpen: false,
                });
              }}
            />
          </ShareRoomModal>
        )}
        {isShowCreateRoomModal && (
          <EditRoom
            toClose={() => toggleCreateRoomModal(false)}
            eventData={eventData}
          />
        )}
        {isShowPostInRadiusFeed && (
          <RoomsModal
            show
            toClose={() => {
              togglePostInRadiusFeed(false);
            }}
          >
            <PostInRadiusFeed />
          </RoomsModal>
        )}
        {isHost && isShowDeleteConfirmation && (
          <DeleteRoomModal
            data={eventData}
            toClose={() => {
              toggleDeleteRoomModal(false);
            }}
          />
        )}
        <Footer />
      </>
    );
  }
}

export default app(
  Rooms(container(CreateEditPostContainer(withRouter(ScheduledOnboardPage))))
);
