import React from "react"
import styled from "styled-components"
import Icons from "@icons"
import colors from "@colors"

const Label = styled.label`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 20px;
  ${props => props.disabled && `
    opacity: 0.4;
    cursor: not-allowed;
  `};
`

const Checkmark = styled.span`
  position: absolute;
  font-family: 'icomoon' !important;
  line-height: 1;
  top: 0;
  left: 0;
  color: transparent;
  border: 1px solid ${colors.contentText};
  border-radius: 6px;
  i {
    font-size: 21px;
  }
  &:after {
    position: absolute;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ ${Checkmark} {
    color: white;
    display: block;
    background: #5A5FF2;
    border: 1px solid white;
    height: 24px;
    i {
      margin-left: -1px;
      font-size: 24px;
    }
  }
`

const Span = styled.span`
  padding-left: 35px;
`

const Checkbox = ({
  label, checked, input, disabled = false, ...props
}) => (
  <Label disabled={disabled}>
    <Input
      checked={checked}
      disabled={disabled}
      type="checkbox"
      {...input}
    />
    <Span>{label}</Span>
    <Checkmark>
      <i className={Icons.genericCheckMark}></i>
    </Checkmark>
  </Label>
)

export default Checkbox
