import React, { PureComponent } from "react"
import styled from "styled-components"
import icons from "@icons"
import SearchIcon from "dumbComponents/RealtyV3/RARRecruitment/images/Search.svg"
import CRMV2Container from "container/CRM"
import TechAssetsContainer from "container/TechAssets"
import cookie from "services/CookieStorage"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import TCContainer from "container/Realty/TransactionFormContainer"
import colors from "@colors"
import { ATC } from "@fonts"
import { withRouter } from "react-router-dom"
import {StyledButton} from "dumbComponents/CRMV2/Filters/styles.js";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const DroDownArea = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  border-radius: 4px;
  right: 0px;
  min-width: 50px;
  box-shadow: 0px 0px 4px 0px #c7bebe;
  padding-bottom: 16px;
  background: #fff;
  display: none;
  // display: block;
  width: 240px;
  z-index: 999999;

  ${props => props.activeDropDown && `
    display: block;
  `}
`

const DropDownContainer = styled.div`
  padding: 10px 10px 0 7px;
  font-size: 18px;
  h4 {
    padding: 12px 18px;
    margin: 0px;
    cursor: pointer;
    font-weight: 200;
    font-family: ${ATC};
    color: ${colors.black};
    &:hover {
      background: ${colors.fadedBlueSea};
    }
  }
`

const Divider = styled.div`
  border: 1px solid #e5e5e5;
  width: 98%;
  margin: 7px 0 4px 1px;
`

const TeamMemberContainer = styled.div``

const TeamMemberWrap = styled.div`
  overflow-y: scroll;
  height: 180px;
  // margin-top: 20px;
`

const SearchWrap = styled.div`
  margin: -5px 0px 7px -20px;
  z-index: 999999;
  input {
    font-family: ${ATC};
    font-weight: 200;
    border-radius: 0px;
    height: 42px;
    font-size: 16px;
    padding: 10px 38px;
    border: 1px solid ${colors.moonGrey};
    width: 210px;
    margin-left: 30px;
    border-radius: 5px;
    z-index: 999999;
  }
  input::placeholder {
    color: #C5C5C5;
  }
  img {
    position: relative;
    left: 36px;
    top: 34px;
    height: 18px;
  }
  label {
    display: none;
  }
`

const DropdownIcon = styled.i`
  position: relative;
  transition-duration: 0.1s;
  color: ${colors.new_primary};
  display: inline-block;
  ${props => props.isActive && `
    transform: rotate(180deg);
  `}
  font-size: 18px;
  margin-left: 8px;
  top: 1px;
  color: ${colors.black};
`

const TeamFilterHeader = styled.div`
  position: relative;

  h2 {
    margin: 0px;
    cursor: pointer;
    font-weight: 400;
    font-size: 22px;
    font-family: ${ATC};
    color: ${colors.black};
  }
`

class TeamFilterComponent extends PureComponent {

  state = {
    selectedMenu: "My leads",
    activeDropDown: false,
    searchTextTeamMember: "",
  }

  componentDidMount() {
    const { defaultInitialName } = this.props
    this.setState({
      selectedMenu: defaultInitialName,
    })
    document.addEventListener("click", (e) => {
      if (!e.target.classList.contains("other_property")) {
        this.setState({ activeDropDown: false })
      }
    })
  }

  handleFilterChange = (e, changedOption) => {
    try {
      this.setState({
        selectedMenu: changedOption.label,
      })
      const {
        currentFilters,
        updateFilterQueries,
        getClientTransactions,
        onFilterChange, 
      } = this.props
      const selectedMenu = changedOption.label
      const selectedValue = changedOption.value
      const teamLeadId = changedOption.teamLeadId
      const updated = currentFilters
      updated["team_member_id"] = selectedValue

      updated.view_type = selectedValue !== "view_all"
      && selectedValue !== "view_mine"
      && selectedValue !== "view_all_team_members"
        ? "view_specific_team_members" : selectedValue

      updated.view_type_label = selectedMenu

      updateFilterQueries({
        filters: updated,
      })

      getClientTransactions({
        currentFilters,
      })

      if (onFilterChange) {
        onFilterChange(selectedValue);
      }
    } catch (error) {
      console.log(error)
    }
  }

  handleMouseOver = (activeDropDown) => {
    this.setState({
      activeDropDown,
    })
  }

  searchTeamMember = (event) => {
    this.setState({
      searchTextTeamMember: event.target.value,
    })
  }

  getFiltersOptions = () => {
    const { getTeamDetailsResponse } = this.props
    const {
      data: teamDetails,
    } = getTeamDetailsResponse || {}
    const user = cookie.load("user") || {}
    const teamNames = (teamDetails && teamDetails.team_members) ? teamDetails.team_members.filter(td => td.agent_id !== user.id).map(tm => ({
      key: `${tm.agent_id}`,
      label: `${tm.firstname} ${tm.lastname || ""}`,
      value: tm.agent_id,
    })) : []

    return [
      ...teamNames,
    ]
    // { value: "view_specific_team_member", label: `${td.firstname || ""}
  }

  render() {
    const { selectedMenu, activeDropDown, searchTextTeamMember } = this.state
    const {
      firstValueOption,
      secondValueOption,
      firstValueLabel,
      secondValueLabel,
      currentFilters,
      getTeamDetailsResponse,
    } = this.props

    const filterTitle = currentFilters && currentFilters.view_type_label === "My leads"
      ? "My active clients" : currentFilters.view_type_label

    return (
      <TeamFilterHeader onMouseEnter={() => this.handleMouseOver(true)}>
        <StyledButton style={{width: "200px"}}>
          <span>{filterTitle}</span>
          {/* <DropdownIcon
            className={icons.arrowsExpandDown}
            isActive={activeDropDown}
          /> */}
          {activeDropDown ? <UpOutlined style={{marginLeft: "10px"}}/> : <DownOutlined style={{marginLeft: "10px"}}/>}
        </StyledButton>
        <DroDownArea activeDropDown={activeDropDown} onMouseLeave={() => this.handleMouseOver(false)}>
          <DropDownContainer>
            <h4 onClick={(event) => this.handleFilterChange(event, firstValueOption)}>{firstValueLabel}</h4>
            <h4 onClick={(event) => this.handleFilterChange(event, secondValueOption)}>{secondValueLabel}</h4>
            <Divider />
            <TeamMemberContainer>
              <SearchWrap>
                <img src={SearchIcon} alt="search.svg" />
                <input
                  className="other_property"
                  label=""
                  placeholder="Search Member"
                  value={searchTextTeamMember}
                  onChange={this.searchTeamMember}
                />
              </SearchWrap>
              <TeamMemberWrap>
                {this.getFiltersOptions() && this.getFiltersOptions().filter(td => td.label.toLowerCase().includes(searchTextTeamMember.toLocaleLowerCase())).map(td => (
                  <h4 onClick={(event) => this.handleFilterChange(event, td)}>{td.label}</h4>
                ))}
              </TeamMemberWrap>
            </TeamMemberContainer>
          </DropDownContainer>
        </DroDownArea>
      </TeamFilterHeader>
    )
  }
}

export default CRMV2Container(withRouter(TeamProfileContainer(TeamFilterComponent)))
