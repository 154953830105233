export const PROPERTY_TYPE = [{
  value: "residential_1_4",
  label: "Standard Purchase",
}, {
  value: "multifamily",
  label: "Multifamily",
}, {
  value: "commercial",
  label: "Commercial",
}, {
  value: "new_construction",
  label: "New Construction",
}, {
  value: "vacant_land",
  label: "Vacant Land",
}]

export const TYPE_OF_OWNERSHIP = [{
  value: "individual",
  label: "Individual",
}, {
  value: "trust",
  label: "Trust",
}, {
  value: "entity",
  label: "Entity(LLC, Corp, Partnership, Other)",
}, {
  value: "power_of_attorney",
  label: "Power of Attorney",
}]

export const FINANCING_TYPES_CO = [{
  value: "conventional",
  label: "Conventional",
}, {
  value: "cash",
  label: "Cash",
}, {
  value: "va",
  label: "VA",
}, {
  value: "fha",
  label: "FHA",
}, {
  value: "seller_financing",
  label: "Seller Financing",
},{
  value: "bond",
  label: "Bond",
}]

export const FINANCING_TYPES_OR = [{
  value: "conventional",
  label: "Conventional",
}, {
  value: "cash",
  label: "Cash",
}, {
  value: "va",
  label: "VA",
}, {
  value: "fha",
  label: "FHA",
}, {
  value: "usda",
  label: "USDA",
}, {
  value: "odva",
  label: "ODVA",
}]

export const FINANCING_TYPES_WA = [{
  value: "conventional",
  label: "Conventional",
}, {
  value: "cash",
  label: "Cash",
}, {
  value: "bridge",
  label: "Bridge",
},{
  value: "va",
  label: "VA",
}, {
  value: "fha",
  label: "FHA",
}, {
  value: "usda",
  label: "USDA",
}]

export const FINANCING_TYPES_GA = [{
  value: "conventional",
  label: "Conventional",
}, {
  value: "cash",
  label: "Cash",
}, {
  value: "bridge",
  label: "Bridge",
}, {
  value: "va",
  label: "VA",
}, {
  value: "fha",
  label: "FHA",
}, {
  value: "loan_assumption",
  label: "Loan Assumption",
}, {
  value: "usda_rd",
  label: "USDA-RD",
}]

export const FINANCING_TYPES_TX = [{
  value: "third_party_financing",
  label: "Third Party Financing",
}, {
  value: "cash",
  label: "Cash",
}, {
  value: "loan_assumption",
  label: "Loan Assumption",
}, {
  value: "seller_financing",
  label: "Seller Financing",
}]

export const FINANCING_TYPES_FL = [{
  value: "conventional",
  label: "Conventional",
}, {
  value: "cash",
  label: "Cash",
}, {
  value: "bridge",
  label: "Bridge",
}, {
  value: "va",
  label: "VA",
}, {
  value: "fha",
  label: "FHA",
}, {
  value: "assumption_of_existing_mortgage",
  label: "Assumption of Existing Mortgage",
}, {
  value: "purchase_money_note_and_mortgage_to_seller",
  label: "Purchase Money Note & Mortgage to Seller",
}]

export const FINANCING_TYPES_CA = [{
  value: "conventional",
  label: "Conventional",
}, {
  value: "cash",
  label: "Cash",
}, {
  value: "va",
  label: "VA",
}, {
  value: "fha",
  label: "FHA",
}, {
  value: "seller_financing",
  label: "Seller Financing",
}]

export const getFinancingTypeOptions = (state = "") => {
  switch(state) {
    case "california":
    case "CA":
      return FINANCING_TYPES_CA
    case "texas":
    case "TX":
      return FINANCING_TYPES_TX
    case "colorado":
    case "CO":
      return FINANCING_TYPES_CO
    case "florida":
    case "FL":
      return FINANCING_TYPES_FL
    case "georgia":
    case "GA":
      return FINANCING_TYPES_GA
    case "oregon":
    case "OR":
      return FINANCING_TYPES_OR
    case "washington":
    case "WA":
      return FINANCING_TYPES_WA
    default:
      return FINANCING_TYPES_CA
  }
}

/**MAJOR TABS*/
export const COVERSHEET = "cover_sheet"
export const DOCUMENTS = "documents"
export const CONTRACT_DETAILS = "contract_details"
export const COMPLIANCE = "compliance"

/**COVERSHEET SUB TABS*/
export const BUYER_AGENT_INFORMATION = "buyer_information"
export const PROPERTY_INFORMATION = "property_information"
export const DEAL_TERMS = "deal_terms"

/**DOCUMENTS SUB TABS*/
export const TEMPLATES = "templates"
export const ACTIVE = "active"
export const OFFER_PACKAGE = "offer_package"

/**STEPS*/
export const BUYER_INFORMATION_STEP = "buyers_information_mobile"
export const PROPERTY_INFORMATION_STEP = "property_information_mobile"
export const DEAL_TERMS_STEP = "deal_information_mobile"

/**DOCUMENTS SUB TABS*/
export const OFFER_DOCS = "offer_docs"
export const LISTING_DOCS = "listing_docs"
export const UPLOADS = "uploads"

export const DEAL_TERMS_COMMON = {
  price: "",
  loan_amount_percentage: "",
  loan_amount: "",
  earnest_money_deposit: "",
  balance_of_down_payment: "",
  initial_deposit_amount_percentage: "",
  initial_deposit_amount: "",
  increased_deposit_amount_percentage: "",
  increased_deposit_amount: "",
  additional_financial_amount_percentage: "",
  additional_financial_amount: "",
}

export const DEAL_TERMS_FORMS = {
  california: {
    ...DEAL_TERMS_COMMON,
    additional_earnest_money: "",
  },
  texas: {
    ...DEAL_TERMS_COMMON,
  },
  florida: {
    ...DEAL_TERMS_COMMON,
    other: "",
    additional_earnest_money: "",
    additional_deposit: "",
  },
  /**ADD OTHER STATES HERE*/
}

export const PROPERTY_INFORMATION_FORMS = {
  california: {
    state: "",
    street: "",
    apt: "",
    city: "",
    county: "",
    zipcode: "",
    apn_number: "",
  },
  texas: {
    price: "",
    apn_number: "",
    street: "",
    apt: "",
    city: "",
    zipcode: "",
    lot: "",
    block: "",
    county: "",
    listing_type: "",
  },
  florida: {
    price: "",
    apn_number: "",
    street: "",
    apt: "",
    city: "",
    zipcode: "",
    county: "",
    tax_id_number: "",
    legal_description: "",
    listing_type: "",
  },
  washington: {
    price: "",
    apn_number: "",
    street: "",
    apt: "",
    city: "",
    zipcode: "",
    listing_type: "",
  },
  oregon: {
    price: "",
    apn_number: "",
    street: "",
    apt: "",
    city: "",
    zipcode: "",
    lot: "",
    block: "",
    county: "",
    listing_type: "",
  },
  colorado: {
    price: "",
    apn_number: "",
    street: "",
    apt: "",
    city: "",
    zipcode: "",
    county: "",
    tax_id_number: "",
    legal_description: "",
    listing_type: "",
  },
  georgia: {
    price: "",
    apn_number: "",
    street: "",
    apt: "",
    city: "",
    zipcode: "",
    county: "",
    tax_parcel_id_number: "",
    land_lots: "",
    district: "",
    section_gmd: "",
    lot: "",
    block: "",
    unit: "",
    phase: "",
    subdivision: "",
    plat_book: "",
    page: "",
    listing_type: "",
  },
}

export const STATES = []
  /**
   * {
  "CA": "California",
  "CO": "Colorado",
  "FL": "Florida",
  "GA": "Georgia",
  "OR": "Oregon",
  "TX": "Texas",
  "WA": "Washington"
}
   */

export const RADIUS_STATES = [
  {
    value: "California",
    label: "California",
  },
  {
    value: "Florida",
    label: "Florida",
  },
  {
    value: "Georgia",
    label: "Georgia",
  },
  {
    value: "Oregon",
    label: "Oregon",
  },
  {
    value: "Texas",
    label: "Texas",
  },
  {
    value: "Washington",
    label: "Washington",
  },

  {
    value: "Colorado",
    label: "Colorado",
  },
]

export const getState = (state = "") => {
  switch (state) {
    case "california":
    case "CA":
      return "California"
    case "texas":
    case "TX":
      return "Texas"
    case "colorado":
    case "CO":
      return "Colorado"
    case "florida":
    case "FL":
      return "Florida"
    case "georgia":
    case "GA":
      return "Georgia"
    case "oregon":
    case "OR":
      return "Oregon"
    case "washington":
    case "WA":
    case "Washington":
      return "Washington"
    default:
      return "others"
  }
}

export const getStateLowerCase = (state = "") => {
  switch (state) {
    case "California":
    case "CA":
      return "california"
    case "Texas":
    case "TX":
      return "texas"
    case "Colorado":
    case "CO":
      return "colorado"
    case "Florida":
    case "FL":
      return "florida"
    case "Georgia":
    case "GA":
      return "georgia"
    case "Oregon":
    case "OR":
      return "oregon"
    case "Washington":
    case "WA":
      return "washington"
    default:
      return ""
  }
}

export const CLIENT_SOURCE = [
  {
    value: "radius_marketplace",
    label: "Radius Marketplace",
  },
  {
    value: "zillow_agent_personal_account",
    label: "Zillow (Agent's Personal Account) ",
  }, {
    value: "zillow_radius_provided",
    label: "Zillow (Radius Provided)",
  }, {
    value: "zillow_flex",
    label: "Zillow Flex",
  }, {
    value: "opcity_realtor_com",
    label: "OpCity/Realtor.com",
  }, {
    value: "team_mentor_lead",
    label: "Team/Mentor Lead",
  }, {
    value: "self",
    label: "Self",
  }, {
    value: "sphere_of_influence",
    label: "Sphere of Influence/Personal",
  }, 
  {
    label: "My mobile app",
    value: "my_mobile_app",
  },
  {
    value: "referrals_agent_Lender",
    label: "Referrals (Agent/Lender)",
  }, {
    value: "other",
    label: "Other",
  },
  {
    label: "FSBO",
    value: "fsbo",
  }, {
    label: "Mail",
    value: "mail",
  }, {
    label: "Networking",
    value: "networking",
  }, {
    label: "New Construction",
    value: "new_construction",
  }, {
    label: "Open House",
    value: "open_house",
  }, {
    label: "Other - Agency",
    value: "other_agency",
  }, {
    label: "Other - Builder",
    value: "other_builder",
  }, {
    label: "Paid Marketing - Farming/Direct",
    value: "paid_marketing_farming_direct",
  }, {
    label: "Paid Marketing - Other",
    value: "paid_marketing_other",
  }, {
    label: "Paid Marketing - Zillow",
    value: "paid_marketing_zillow",
  }, {
    label: "Personal Transaction",
    value: "personal_transaction",
  }, {
    label: "Property Management",
    value: "property_management",
  }, {
    label: "Floor Call",
    value: "floor_call",
  }, {
    label: "Referral - Attorney",
    value: "referral_attorney",
  }, {
    label: "Referral - From Past Client",
    value: "referral_from_past_client",
  }, {
    label: "Referral - Lendor",
    value: "referral_lendor",
  }, {
    label: "Referral - Other",
    value: "referral_other",
  }, {
    label: "Referral - Real Estate Agent(External)",
    value: "referral_real_estate_agent_external",
  }, {
    label: "Referral - Sphere of Influence",
    value: "referral_sphere_of_influence",
  }, 
  {
    label: "My mobile app",
    value: "my_mobile_app",
  },
  {
    label: "Referral - Title",
    value: "referral_title",
  }, {
    label: "Sign Call",
    value: "sign_call",
  }, {
    label: "Social Profile - Facebook",
    value: "social_profile_facebook",
  }, {
    label: "Social Profile - Instagram",
    value: "social_profile_instagram",
  }, {
    label: "Social Profile - Other",
    value: "social_profile_other",
  }, {
    label: "Events - Other",
    value: "events_other",
  },
]

/**
 * <Select.Option value="trust">Trust </Select.Option>
                            <Select.Option value="entity"> </Select.Option>
                            <Select.Option value="power_of_attorney">Power of Attorney </Select.Option>
                            <Select.Option
                              value="individual"
                            >
                              Individual
                            </Select.Option>
 */
export const SELLER_TYPE = [
  {
    value: "Trust",
    label: "Trust",
  },
  {
    value: "entity",
    label: "Entity (LLC, Corp, Partnership,Other)",
  }, {
    value: "individual",
    label: "Individual",
  }, {
    value: "power_of_attorney",
    label: "Power of Attorney",
  }
]

//type of brokerage transaction for compliance
export const OFFERS = "offers"
export const LISTINGS = "listing"
export const PURCHASE = "purchase"