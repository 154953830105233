import React from "react"
import styled from "styled-components"
import { Link, withRouter } from "react-router-dom"
import root from "window-or-global"
import colors from "@colors"
import Button from "@ui/Button"
import icons from "@icons"
import RealtyContainer from "container/Realty"
import CircleNav from "images/navimages/circleNav.svg"
import { mobile, preTabletWidth, preDesktopWidth } from "@consts"
import { authCheck } from "services/auth"

const MOBILE_BREAK_POINT = "1080px"

const Toggle = styled.button`
  display: none;
  background-color: white;
  padding: 9px 10px;;
  border: none;
  border-radius: 4px;
  margin: 10px 0;
  cursor: pointer;
  ${preDesktopWidth(`
    display: inline-block;
  `)}
  }
`

const CircleNavSVG = styled.img`
  display: none;
  position: absolute;
  right: 3px;
  bottom: 2px;
  z-index: -1;
  display: none;

  @media (max-width: ${MOBILE_BREAK_POINT}) {
    display: block;
  }
`
const LinkStyles = `
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: ${colors.contentText};
  cursor: pointer;
  padding-left: 10px;
  padding-top: 16px;
  padding-bottom: 16px;

  &:last-child {
    border-bottom: none;
  }

  p {
    margin: 0px;
  }
`

const SubCategory = styled.li`
  height: 32px;
  padding-left: 8px;
  border-bottom: 1px solid #D0D0D0;

  ${props => props.hideBorder && `
    border-bottom: none;
    p {
      color: ${colors.primaryColor};
    }
    `}
`

const ListLink = styled(Link)`
  ${LinkStyles}
  &:hover {
    border-radius: 8px;
    background: #E9F3FB;
  }
`

const DropdownIcon = styled.i`
  position: relative;
  float: right;
  bottom: 18px;
  color: ${colors.primaryColor};
  transition-duration: 0.1s;
  ${props => props.isActive && `
    transform: rotate(180deg);
  `}
`

const IconBar = styled.div`
  background-color: #303030;
  display: block;
  width: 22px;
  height: 1px;
  margin-top: 7px;
  margin-right: 4px;
  border-radius: 1px;
  transition-duration: 0.3s;

  &:first-child {
    margin-top: 0;
  }

  ${props => props.isOpen && `
    position: relative;
    &:nth-child(1) {
      transform: rotate(45deg);
      top: 5px;
    }

     &:nth-child(2) {
      opacity: 0;
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
      top: -7px;
    }
  `}
`

const DropdownMenu = styled.div`
  display: none;
  background: ${colors.white};
  box-shadow: rgba(0,0,0,0.25) 0px 1px 2.5px 0px;
  position: absolute;
  overflow: scroll;
  top: 71px;
  width: 100%;
  right: -258px;
  opacity: 0;
  transition-duration: 0.3s;
  height: 86vh;
  ${props => props.isOpen && `
    opacity: 1;
    right: 0;
  `}

  ul {
    padding: 12px 10px;
    li {
     font-family: 'DM Sans', sans-serif;
     font-size: 20px;
     line-height: 1;
     color: #354052;
     margin-top: 10px;
     p {
       padding: 20px 20px 0px 20px;
       margin: 0;
     }
      a {
        display: block;
        color: ${colors.contentText};
        text-decoration: none;
      }
    }
  }

  animation: show 0.5s forwards;

  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: ${MOBILE_BREAK_POINT}) {
    display: block;

    ${props => !props.isOpen && `
        display: none;
    `}
  }
`

const SubCategoryWrap = styled.ul`
  margin-bottom: -20px;
`

const StyledLink = styled(Link)`
 ${props => props.button && `
   border-radius: 4px;
   border: solid 1.2px #979797;
   background: ${colors.new_primary};
   color: ${colors.white};
   margin: 0 auto;
   top: 90%;
   width: 93%;
   border: none;
   border-radius: 20px;
   display: block;
   padding: 12px;
   text-align: center;
   text-decoration: none;
   max-width: 329px;
   font-size: 16px;
   position: fixed;

   @media(max-width: 1099px) {
     top: 85%;
   }

   @media(max-width: 425px) {
      font-size: 12px;
    }
`}

   ${props => !props.isBrokerageMenuOpen && `
      background: ${colors.white};
      color: ${colors.black};
      position: static;
      width: 150px;
  `}

  ${props => props.max && `
    width: max-content;
    `}

  transition: background 0.25s linear;

  @media(max-width: 560px) {
    display: none;
  }
`

const Title = styled.p`
  padding: 0px !important;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  &:hover {
    text-decoration: underline;
  }
`

const Description = styled.p`
  padding: 0px !important;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 4px;
`

const SubMenu = styled.div`
  height: max-content;
  width: 100%;
  border-bottom: 1px solid #D0D0D0;

  ul {
    padding: 8px;
    margin-top: -8px;
  }

  li {
    padding-bottom: 24px;
  }
`

const SignUpButton = styled.button`
  display: none;
  height: 36px;
  width: 200px;
  border-radius: 100px;
  cursor: pointer;
  background: ${colors.new_primary};
  color: ${colors.white};
  border: none;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  margin-right: 18px;

  @media(min-width:768px) {
    display: block;
    ${props => props.hide && `
      display: none;
    `}
  }

  @media(min-width: 1099px) {
    display: none;
  }

  transition: display 0.5s ease-in;
`

class NavbarToggle extends React.Component {
  state = {
    isOpen: false,
    isBrokerageMenuOpen: false,
    isResourcesMenuOpen: false,
  }

  showDropDown = (type) => {
    const {
      isServicesVisible,
      isLearnVisible,
    } = this.state

    if (type === "services") {
      this.setState({
        isServicesVisible: !isServicesVisible,
        isLearnVisible: false,
      })
    }

    if (type === "learn") {
      this.setState({
        isServicesVisible: false,
        isLearnVisible: !isLearnVisible,
      })
    }
  }

  toggleMenu = ({ heapTrack = null, hubspotTrack }) => {
    if (hubspotTrack) {
      this.handleHubspotTrack(hubspotTrack)
    }
    if (heapTrack && root.heap) {
      root.heap.track(heapTrack)
    }
    const { isOpen } = this.state
    this.setState({
      isOpen: !isOpen,
    })
  }

  handleBrokerageMenu = () => {
    const { RARTracker, history } = this.props
    const { isBrokerageMenuOpen } = this.state
    //isOpen
    const isLogged = authCheck()
    if (isLogged) {
      RARTracker({
        payload: {
          source: "community-navigation-droddown",
        },
      })
    }
    this.setState({
      isBrokerageMenuOpen: !isBrokerageMenuOpen,
    })
    // history.push("/realty")
  }

  handleHubspotTrack = (event) => {
    const { RARTracker } = this.props
    const isLogged = authCheck()
    if (isLogged) {
      RARTracker({
        payload: {
          source: event,
        },
      })
    }
  }

  handleSectionScroll = (section) => {
    const { setActiveNavbarSection, scrollToElement } = this.props
    setActiveNavbarSection(section)
    setTimeout(() => {
      scrollToElement({
        id: `#realty-v2-${section}-mobile`,
        offset: -20,
      })
    }, 1000)
  }

  handleResourcesClick = () => {
    const { isResourcesMenuOpen } = this.state
    this.setState({
      isResourcesMenuOpen: !isResourcesMenuOpen,
    })
  }

  render() {
    const {
      isOpen,
      isBrokerageMenuOpen,
      isResourcesMenuOpen,
    } = this.state
    const {
      isLogged,
      history,
    } = this.props
    return (
      <React.Fragment>
        {/* <SignUpButton
          hide={isOpen}
          onClick={() => {
            if (root.heap) {
              root.heap.track("Mobile_Navbar_Click_SignUp")
            }
            history.push("/signup")
          }}
        >
          Apply
        </SignUpButton> */}
        {!isBrokerageMenuOpen && (
          <React.Fragment>
            <Toggle onClick={this.toggleMenu}>
              <IconBar isOpen={isOpen}></IconBar>
              <IconBar isOpen={isOpen}></IconBar>
              <IconBar isOpen={isOpen}></IconBar>
            </Toggle>
          </React.Fragment>
        )}
        <DropdownMenu isOpen={isOpen}>
          <ul>

            <SubCategoryWrap>
              <SubCategory hideBorder={isBrokerageMenuOpen} onClick={this.handleBrokerageMenu}>
                <p
                  style={{
                    padding: 0,
                    lineHeight: 1,
                  }}
                >
                  Our Brokerage
                </p>
                <DropdownIcon
                  className={icons.arrowsExpandDown}
                  isActive={isBrokerageMenuOpen}
                />
              </SubCategory>
              {isBrokerageMenuOpen && (
                <SubMenu>
                  <ul>
                    {/*<ListLink
                      onClick={() => {
                        this.toggleMenu()
                        this.handleHubspotTrack("navbar-our-brokerage-overview")
                        this.handleSectionScroll("overview", e)
                      }}
                      to="/realty?section=overview"
                    >
                      <Title>Brokerage Overview</Title>
                      <Description>Earn more with Radius Agent</Description>
                    </ListLink>*/}
                    <ListLink
                      onClick={(e) => {
                        this.toggleMenu()
                        this.handleHubspotTrack("navbar-meet-our-agents")
                      }}
                      to="/realty/agents"
                    >
                      <Title>Meet Our Agents</Title>
                      <Description>Grow your Business with Radius</Description>
                    </ListLink>
                    <ListLink
                      onClick={() => {
                        this.toggleMenu()
                        this.handleHubspotTrack("navbar-agents")
                        this.handleSectionScroll("testimonials", e)
                      }}
                      to="/realty/for-agents"
                    >
                      <Title>For Agents</Title>
                      <Description>How we help agents succeed</Description>
                    </ListLink>
                    <ListLink
                      onClick={(e) => {
                        this.toggleMenu()
                        this.handleHubspotTrack("navbar-teams")
                        this.handleSectionScroll("our-team", e)
                      }}
                      to="/realty/teams"
                    >
                      <Title>For Teams</Title>
                      <Description>How we help teams succeed</Description>
                    </ListLink>
                    <ListLink
                      onClick={() => {
                        this.toggleMenu()
                        this.handleHubspotTrack("navbar-team-jobs")
                      }}
                      to="/teams/jobs"
                    >
                      <Title>Jobs</Title>
                      <Description>Your future with real estate starts here</Description>
                    </ListLink>
                    
                    {/*<ListLink
                      onClick={() => {
                        this.toggleMenu()
                        this.handleHubspotTrack("navbar-royale")
                      }}
                      to="/realty/elite"
                    >
                      <Title>Royale</Title>
                      <Description>Luxury services</Description>
                    </ListLink>*/}
                    <ListLink
                      onClick={() => {
                        this.toggleMenu()
                        this.handleHubspotTrack("navbar-edge")
                      }}
                      to="/realty/radius-financing"
                    >
                      <Title>Radius Financing</Title>
                      <Description>White glove solutions</Description>
                    </ListLink>
                    {/* <ListLink
                      onClick={() => {
                        this.toggleMenu()
                        this.handleHubspotTrack("navbar-rar-pricing")
                      }}
                      to="/realty/pricing"
                    >
                      <Title>Pricing</Title>
                      <Description>100% Commissions</Description>
                    </ListLink> */}
                    {/* <ListLink
                      onClick={() => {
                        this.toggleMenu()
                        this.handleHubspotTrack("navbar-rar-isa")
                      }}
                      to="/radius-assist"
                    >
                      <Title>ISA</Title>
                      <Description>Lead Concierge</Description>
                    </ListLink>
                    <ListLink
                      onClick={() => {
                        this.toggleMenu()
                        this.handleHubspotTrack("navbar-our-agents")
                      }}
                      to="/realty/agents"
                    >
                      <Title>Our Agents</Title>
                      <Description>Free 1:1 Introduction</Description>
                    </ListLink>
                    {/*
                      <ListLink
                        to="/realty/agents"
                      >
                        <Title>Pricing</Title>
                        <Description>Pricing Explained</Description>
                      </ListLink>
                      */}
                  </ul>
                </SubMenu>
              )}
            </SubCategoryWrap>
            {/*<SubCategoryWrap>
              <SubCategory>
                <StyledLink to="/realty/pricing" onClick={this.toggleMenu}>
                  Pricing
                </StyledLink>
              </SubCategory>
            </SubCategoryWrap>*/}
            <SubCategoryWrap>
              <SubCategory>
                <StyledLink to="/network" onClick={this.toggleMenu}>
                  Our Community
                </StyledLink>
              </SubCategory>
            </SubCategoryWrap>
            <SubCategoryWrap>
              <SubCategory>
                <StyledLink to="/rooms" onClick={this.toggleMenu}>
                  Rooms
                </StyledLink>
              </SubCategory>
            </SubCategoryWrap>
            {/* <SubCategoryWrap>
              <SubCategory>
                <StyledLink to="/realty/agents" onClick={this.toggleMenu}>
                  Our Agents
                </StyledLink>
              </SubCategory>
            </SubCategoryWrap> */}
            <SubCategoryWrap>
              <SubCategory>
                <StyledLink
                  to="/about"
                  onClick={() => this.toggleMenu({
                    heapTrack: "click_aboutus_navbar_mobileweb",
                    hubspotTrack: "navbar-about-us",
                  })}>
                  About Us
                </StyledLink>
              </SubCategory>
            </SubCategoryWrap>
            {/* <SubCategoryWrap>
              <SubCategory hideBorder>
                <StyledLink max to="/" onClick={this.toggleMenu}>
                  Resources
                </StyledLink>
              </SubCategory>
            </SubCategoryWrap> */}
            {/* <SubCategoryWrap>
              <SubCategory hideBorder={isBrokerageMenuOpen} onClick={this.handleResourcesClick}>
                <p
                  style={{
                    padding: 0,
                    lineHeight: 1,
                  }}
                >
                  Resources
                </p>
                <DropdownIcon
                  className={icons.arrowsExpandDown}
                  isActive={isResourcesMenuOpen}
                />
              </SubCategory>
              {isResourcesMenuOpen && (
                <SubMenu>
                  <ul>
                    <ListLink
                      onClick={() => {
                        root.location.href = "https://blog.radiusagent.com"
                        this.toggleMenu()
                      }}
                      to="/realty?section=testimonials"
                    >
                      <Title>Blog</Title>
                      <Description>Real estate topics, trends, and reports</Description>
                    </ListLink>
                    <ListLink
                      onClick={() => {
                        this.toggleMenu()
                      }}
                      to="/academy"
                    >
                      <Title>Radius Academy</Title>
                      <Description>Curated real estate courses and trainings</Description>
                    </ListLink>
                  </ul>
                </SubMenu>
              )}
            </SubCategoryWrap> */}

          </ul>
        </DropdownMenu>
        {!isLogged && isOpen && (
          <StyledLink isBrokerageMenuOpen={!isBrokerageMenuOpen} button to="/signup">
            Learn More
          </StyledLink>
        )}
        {isBrokerageMenuOpen && (
          <Toggle onClick={this.toggleMenu}>
            <IconBar isOpen={isOpen}></IconBar>
            <IconBar isOpen={isOpen}></IconBar>
            <IconBar isOpen={isOpen}></IconBar>
          </Toggle>
        )}
      </React.Fragment>
    )
  }
}

export default withRouter(RealtyContainer(NavbarToggle))
