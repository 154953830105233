import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils";
import * as OnboardingActions from "./actions";

const initState = {
  currentOnboardingStep: "",
  ...createDeltaReducer("getAppStatusResponse"),
  ...createDeltaReducer("addPersonalInfoResponse"),
  ...createDeltaReducer("addOnboardingInfoResponse"),
  ...createDeltaReducer("addOnboardingStepResponse"),
  ...createDeltaReducer("getLicenseDetailResponse"),
  ...createDeltaReducer("getMLSResponse"),
  ...createDeltaReducer("getOfficeAddressResponse"),
  ...createDeltaReducer("getDbaInfoResponse"),
  ...createDeltaReducer("getLicenseStateResponse"),
  ...createDeltaReducer("uploadAppAssetsResponse"),
  ...createDeltaReducer("getStepsCompletedResponse"),
  ...createDeltaReducer("getPersonalInfoResponse"),
  ...createDeltaReducer("getAssociationsResponse"),
  ...createDeltaReducer("addAppBrandingResponse"),
  ...createDeltaReducer("getAppBrandingResponse"),
  ...createDeltaReducer("getStepCompletedForSaasResponse"),
  ...createDeltaReducer("getMLSListResponse"),
};

export default function onboardingV2Reducer(state = initState, action) {
  switch (action.type) {
    case OnboardingActions.toggleOnboardingStep.type: {
      return {
        ...state,
        currentOnboardingStep: action.data,
      };
    }

    case OnboardingActions.getAppStatusAction.REQUEST:
    case OnboardingActions.getAppStatusAction.SUCCESS:
    case OnboardingActions.getAppStatusAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          OnboardingActions.getAppStatusAction,
          "getAppStatusResponse"
        ),
      };
    }

    case OnboardingActions.addPersonalInfoAction.REQUEST:
    case OnboardingActions.addPersonalInfoAction.SUCCESS:
    case OnboardingActions.addPersonalInfoAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          OnboardingActions.addPersonalInfoAction,
          "addPersonalInfoResponse"
        ),
      };
    }

    case OnboardingActions.addOnboardingInfoAction.REQUEST:
    case OnboardingActions.addOnboardingInfoAction.SUCCESS:
    case OnboardingActions.addOnboardingInfoAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          OnboardingActions.addOnboardingInfoAction,
          "addOnboardingInfoResponse"
        ),
      };
    }

    case OnboardingActions.addOnboardingStepAction.REQUEST:
    case OnboardingActions.addOnboardingStepAction.SUCCESS:
    case OnboardingActions.addOnboardingStepAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          OnboardingActions.addOnboardingStepAction,
          "addOnboardingStepResponse"
        ),
      };
    }

    case OnboardingActions.getStepsCompletedAction.REQUEST:
    case OnboardingActions.getStepsCompletedAction.SUCCESS:
    case OnboardingActions.getStepsCompletedAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          OnboardingActions.getStepsCompletedAction,
          "getStepsCompletedResponse"
        ),
      };
    }

    case OnboardingActions.getLicenseDetailsAction.REQUEST:
    case OnboardingActions.getLicenseDetailsAction.SUCCESS:
    case OnboardingActions.getLicenseDetailsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          OnboardingActions.getLicenseDetailsAction,
          "getLicenseDetailResponse"
        ),
      };
    }

    case OnboardingActions.getMLSAction.REQUEST:
    case OnboardingActions.getMLSAction.SUCCESS:
    case OnboardingActions.getMLSAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          OnboardingActions.getMLSAction,
          "getMLSResponse"
        ),
      };
    }

    case OnboardingActions.getOfficeAddressAction.REQUEST:
    case OnboardingActions.getOfficeAddressAction.SUCCESS:
    case OnboardingActions.getOfficeAddressAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          OnboardingActions.getOfficeAddressAction,
          "getOfficeAddressResponse"
        ),
      };
    }

    case OnboardingActions.getDbaInfoAction.REQUEST:
    case OnboardingActions.getDbaInfoAction.SUCCESS:
    case OnboardingActions.getDbaInfoAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          OnboardingActions.getDbaInfoAction,
          "getDbaInfoResponse"
        ),
      };
    }

    case OnboardingActions.getLicenseStateAction.REQUEST:
    case OnboardingActions.getLicenseStateAction.SUCCESS:
    case OnboardingActions.getLicenseStateAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          OnboardingActions.getLicenseStateAction,
          "getLicenseStateResponse"
        ),
      };
    }

    case OnboardingActions.addAppDataAction.REQUEST:
    case OnboardingActions.addAppDataAction.SUCCESS:
    case OnboardingActions.addAppDataAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          OnboardingActions.addAppDataAction,
          "addAppDataResponse"
        ),
      };
    }

    case OnboardingActions.uploadAppAssetsAction.REQUEST:
    case OnboardingActions.uploadAppAssetsAction.SUCCESS:
    case OnboardingActions.uploadAppAssetsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          OnboardingActions.uploadAppAssetsAction,
          "uploadAppAssetsResponse"
        ),
      };
    }

    case OnboardingActions.getPersonalInfoAction.REQUEST:
    case OnboardingActions.getPersonalInfoAction.SUCCESS:
    case OnboardingActions.getPersonalInfoAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          OnboardingActions.getPersonalInfoAction,
          "getPersonalInfoResponse"
        ),
      };
    }

    case OnboardingActions.getAssociationsAction.REQUEST:
    case OnboardingActions.getAssociationsAction.SUCCESS:
    case OnboardingActions.getAssociationsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          OnboardingActions.getAssociationsAction,
          "getAssociationsResponse"
        ),
      };
    }

    case OnboardingActions.addAppBrandingAction.REQUEST:
    case OnboardingActions.addAppBrandingAction.SUCCESS:
    case OnboardingActions.addAppBrandingAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          OnboardingActions.addAppBrandingAction,
          "addAppBrandingResponse"
        ),
      };
    }

    case OnboardingActions.getAppBrandingAction.REQUEST:
    case OnboardingActions.getAppBrandingAction.SUCCESS:
    case OnboardingActions.getAppBrandingAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          OnboardingActions.getAppBrandingAction,
          "getAppBrandingResponse"
        ),
      };
    }

    case OnboardingActions.getStepCompletedForSaasAction.REQUEST:
    case OnboardingActions.getStepCompletedForSaasAction.SUCCESS:
    case OnboardingActions.getStepCompletedForSaasAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          OnboardingActions.getStepCompletedForSaasAction,
          "getStepCompletedForSaasResponse"
        ),
      };
    }

    case OnboardingActions.getMLSListAction.REQUEST:
    case OnboardingActions.getMLSListAction.SUCCESS:
    case OnboardingActions.getMLSListAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          OnboardingActions.getMLSListAction,
          "getMLSListResponse"
        ),
      };
    }

    default: {
      return state;
    }
  }
}
