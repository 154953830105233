import styled from "styled-components"
import { ATC, INTER } from "@fonts"

export const BannerWrap = styled.div`
  background-color: ${props => props.backgroundColor || "transparent"};
  width: 100%;
  height: max-content;
  position: sticky;
  top: 81px;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 10px;
  padding-top: 10px;
  z-index: 100;
  
  animation: pop 0.5s cubic-bezier(0,1,.6,1) forwards;

  @keyframes pop {
    0% {
      transform: scale(0);
    }

    100% { 
      transform: scale(1);
    }
  }
`

export const BannerText = styled.p`
  color: #2B2B2B;
  font-family: ${INTER};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */

  span {
    margin-left: 5px;

    a {
      color: #06F;
      font-family: ${INTER};
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      text-decoration-line: underline;
    }
  }
  
`

export const BannerIcon = styled.div`
  width: 17px;
  height: 17px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 3px;

  }
`
