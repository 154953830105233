import React, { useState } from "react";
import { Modal, Table, Select, Checkbox, Button, Typography } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import styled from "styled-components";
const { Title, Text } = Typography;
import OnboardingSetupContainer from "container/OnboardingSetup/index";

const JoinBtn = styled.button`
border-radius: 27px;
border: 1px solid var(--primary-500-main, #6366F1);
background: var(--primary-500-main, #6366F1);
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
height:40px;
padding:0px 16px;
 opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
color:white;`

const CancelButton = styled.button`
border-radius: 27px;
border: 1px solid var(--Neutral-200, #E5E7EB);
background: var(--Generic-White, #FFF);
padding:0px 16px;
height:40px;
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);`

const FreeTrialExpiryModal = ({ visible, onClose, hubspotSubscriptionRequest, hubspotSubscriptionRequestResponse  }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [selectedProduction, setSelectedProduction] = useState(null);
  const [selectedDescribes, setSelectedDescribes] = useState(null);
  const [selectedInterested, setSelectedInterested] = useState(null);

  const production = [
    "Less than $3M",
    "$3M to $10M",
    "$10M to $25M",
    "$25M to $50M",
    "$50 and above",
  ];

  const describes = [
    "I am an agent",
    "We are a team",
    "We are a brokerage",
  ];

  const interested = [
    "A branded mobile app to engage and nurture my leads",
    "An AI-powered CRM for managing client relationships (mobile & desktop)",
    "Automated transaction & document management (BRBCs, listing agreements, offers, etc.)",
    "Partnering with Radius to build and run my own branded brokerage",
    "All of the above",
  ];

  const handleJoinBrokerage = () => {
    const payload = {
      your_annual_production: selectedProduction,
      what_best_describes_you_: selectedDescribes,
      what_are_you_interested_in_: selectedInterested,
    };
      hubspotSubscriptionRequest(payload);
      console.log("Payload:", payload);
      onClose();
  };

  const columns = [
    {
      title: <Text strong style={{ color: "#6941C6" }}>Feature</Text>,
      dataIndex: "feature",
      key: "feature",
    },
    {
      title: "Non brokerage",
      dataIndex: "softwareOnly",
      key: "softwareOnly",
      align: "center",
    },
    {
      title: "Radius brokerage",
      dataIndex: "brokerage",
      key: "brokerage",
      align: "center",
    },
  ];

const data = [
  { 
    key: "1", 
    feature: "Compliance support", 
    softwareOnly: (
      <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <CloseOutlined style={{ color: "red" }} /> No
      </span>
    ), 
    brokerage: (
      <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <CheckOutlined style={{ color: "green" }} /> Yes
      </span>
    ) 
  },
  { 
    key: "2", 
    feature: "Contract checklists", 
    softwareOnly: (
      <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <CloseOutlined style={{ color: "red" }} /> No
      </span>
    ), 
    brokerage: (
      <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <CheckOutlined style={{ color: "green" }} /> Yes
      </span>
    ) 
  },
  { 
    key: "3", 
    feature: "BRBC", 
    softwareOnly: (
      <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <CloseOutlined style={{ color: "red" }} /> No
      </span>
    ), 
    brokerage: (
      <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <CheckOutlined style={{ color: "green" }} /> Yes
      </span>
    ) 
  },
  { 
    key: "4", 
    feature: "Document automation", 
    softwareOnly: (
      <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <CloseOutlined style={{ color: "red" }} /> No
      </span>
    ), 
    brokerage: (
      <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <CheckOutlined style={{ color: "green" }} /> Yes
      </span>
    ) 
  },
  { 
    key: "5", 
    feature: "Compliance alerts", 
    softwareOnly: (
      <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <CloseOutlined style={{ color: "red" }} /> No
      </span>
    ), 
    brokerage: (
      <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <CheckOutlined style={{ color: "green" }} /> Yes
      </span>
    ) 
  },
];

  return (
    <Modal open={visible} onCancel={onClose} footer={null} width={700} centered>
      <Title level={4} style={{ marginBottom: 20 }}>
      Stay compliant, stay ahead: Join brokerage
      </Title>

      <Table columns={columns} dataSource={data} pagination={false} bordered style={{ marginBottom: 20 }} />

      <Text>Your annual production</Text>
      <Select placeholder="Select production" style={{ width: "100%", marginBottom: 12 }} options={production.map(item => ({ label: item, value: item }))} onChange={setSelectedProduction} />

      <Text>What best describes you?</Text>
      <Select placeholder="Select describes" style={{ width: "100%", marginBottom: 12 }} options={describes.map(item => ({ label: item, value: item }))} onChange={setSelectedDescribes} />

      <Text>What are you interested in?</Text>
      <Select placeholder="Select" style={{ width: "100%", marginBottom: 12 }} options={interested.map(item => ({ label: item, value: item }))} onChange={setSelectedInterested} />

      <Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} style={{ marginTop: 10, fontSize:'12px !important' }}>
        I agree to the Radius <a href="https://www.radiusagent.com/terms-of-use" style={{ color: "#6941C6" }}>Terms and Conditions</a> and <a href="https://www.radiusagent.com/privacy-policy"  style={{ color: "#6941C6" }}>Privacy Policy</a>
      </Checkbox>

      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
        <CancelButton onClick={onClose} style={{ marginRight: 8 }}>Cancel</CancelButton>
              <JoinBtn type="primary" disabled={!isChecked|| !selectedProduction || !selectedDescribes || !selectedInterested}  onClick={handleJoinBrokerage}>
         {hubspotSubscriptionRequestResponse.isFetching ? 'Requesting..' :'Join our brokerage'} 
        </JoinBtn>
      </div>
    </Modal>
  );
};

export default OnboardingSetupContainer(FreeTrialExpiryModal);
