import React, { useEffect } from "react"
import OffersV2 from "container/OffersV2"
import TechAssets from "container/TechAssets"
import {
  Envelopes,
  FillAndSign,
} from "./Components"
import {
  WrapContainer,
} from "./styles"

const RecommendedDocs = ({
  getOfferStatus,
  dealToView,
}) => {
  useEffect(() => {
    getOfferStatus({ id: dealToView })
  }, [])
  return (
    <WrapContainer style={{userSelect: 'none', poniterEvent: 'none', filter: 'blur(5px);'}}>
      <Envelopes />
      <FillAndSign />
    </WrapContainer>
  )
}

export default OffersV2(TechAssets(RecommendedDocs))
