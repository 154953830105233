import React from "react"
import ReferralContainer from "container/Referral"
import Navbar from "container/Navbar"
import { InnerTab, NavLink } from "./commonStyles"
import CRM from "../Icons/NewIcons/CRM"

const CRMTab = ({ routeArray, isBroker, isSubscriptionExist }) => (
  <>
    { (
    // {isSubscriptionExist && (
      <InnerTab isActive={routeArray[0] === "crm"}>
        <div className="icon-block-new">
          <CRM />
        </div>
        <div className="text-block">
          <NavLink to="/crm" isActive={routeArray[0] === "crm"}>
            My Active Clients
          </NavLink>
        </div>
      </InnerTab>
    )}
  </>
)

export default Navbar(ReferralContainer(CRMTab))
