import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import styled from "styled-components"
import root from "window-or-global"
import Transition from "react-transition-group/Transition"
import { isBrowser } from "services/Utils"
import Paragraph from "dumbComponents/common/Typography/Paragraph"
import ModalCloseBtnV2 from "images/Graphics/CommonIcons/Close_2.svg"
import { MOBILE_BREAK_POINT } from "shared/consts"
import Loader from "@ui/Loader"
import icons from "@icons"
import colors from "@colors"

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10000;
  left: 0;
  display: flex;
  justify-content: center;
  overflow: scroll;
  top: 0;
  background: ${props => props.overlayColor};
  ${props => props.modalVersion == 2 ? `
    align-items: center;
  ` : `
    align-items: flex-start;
  `}
  ${props => props.zIndex && `
    z-index: ${props.zIndex};
  `}
  ${props => props.position && `
    align-items: ${props.position};
  `}
`

const Dialog = styled.div`
  width: ${props => (props.width ? props.width : "500px")};
  background-color:${props => (props.customDialogColor ? props.customDialogColor : "white")};
  margin: ${props => (props.mm ? props.mm : "80px 0")};
  ${props => props.responsive && `
     @media (max-width: 1500px){
      width: ${props.responsiveWidth};
      height: ${props.responsiveHeight};
     }
  `}
  
  @media(min-width: ${MOBILE_BREAK_POINT}){
   background-color: ${props => (props.customDialogColor ? props.customDialogColor : "transparent")};
  }

  @media(min-width: 1099px) {
    background-color: ${props => (props.customDialogColor ? props.customDialogColor : "white")};
  }
  ${props => props.customRight && `
    @media(max-width: 1280px) {
      right: 138px !important;
    }
  `}
  .ant-input-group-addon {
    background: white;
  }
`

const IconContainer = styled.div`
  position: relative;
`

const StyledFontAwesome = styled.i`
  font-size: 18px;
  color: ${colors.milkyWhite};
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 99;

  ${props => props.modalVersion == 2 && `
    top: -40px;
    right: 8px;
  `}
`

const Content = styled.div`
  padding: 40px;
  ${props => props.responsive && `
     @media (max-width: 1500px){
      width: ${props.responsiveWidth};
      height: ${props.responsiveHeight}
     }
  `}

  ${props => props.isLargeHeader && `
    padding: 0px;
  `}
  .slick-list{
   height: 800px;
  }

  ${props => props.isBlank && `
    padding: 0px;
  `}
`

const StyledTitle = Paragraph.extend`
  position: absolute;
  top: 16px;
  left: 16px;
  color: #354052;
  text-transform: ${props => props.textTransform};
  line-height: 1;
`

const HorizontalBar = styled.div`
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: ${colors.borderColor};
`

const TitleWrap = styled.div`
  ${props => props.isLargeHeader && `
    padding: 40px 32px;
    background: ${colors.bgGrey};
    display: flex;

    p {
      font-size: 38px;
      font-family: 'DM Sans', sans-serif;
      color: ${colors.contentText};
      position: relative;
      top: 0px;
      left: 0px;
    }
  `}
`

const CloseBtn = styled.img`
  position: absolute;
  top: -50px;
  width: 35px;
  right: 10px;
  cursor: pointer;

  ${props => props.modalVersion === 3 && `
    top: 10px;
    width: 30px;
    z-index: 1000;
  `}

  ${props => props.customCloseCSS && `
    top: 8px;
    width: 20px;
    right: 11px;
    `}

    ${props => props.customIconStyle && `
      position: absolute;
      top: 6px;
      width: 35px;
      right: 154px;
      cursor: pointer;
    `}

    @media(max-width: 1023px) {
      ${props => props.customMobileIconStyle && `
        position: absolute;
        top: -44px;
        width: 35px;
        right: 38px;
        cursor: pointer;
      `}
    }
`

const CloseText = styled.div`
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #EAF5FF;
  position: absolute;
  top: 8px;
  right: 20px;
  margin: 15px;
  cursor: pointer;
`

const defaultStyle = {
  transition: "opacity 150ms ease-in-out",
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exited: { opacity: 0 },
}

class Modal extends Component {
  constructor(props) {
    super(props)
    this.modalRef = React.createRef()
  }

  componentDidMount() {
    const { show, onMount, transparentBG } = this.props
    if (isBrowser()) {
      this.isBrowser = true
      this.ele = root.document.getElementById("index")
      this.blurEle = root.document.getElementById("index-layout")
    }
    if (show) {
      if (this.ele) {
        this.ele.focus()
        const top = `-${root.pageYOffset}px`
        this.top = root.pageYOffset
        this.ele.classList.add("noScroll")
        this.ele.style.top = top
      }

      if (this.blurEle && !transparentBG) {
        this.blurEle.classList.add("blur-it")
      }
    }

    if (onMount) {
      onMount()
    }
  }

  componentWillUnmount() {
    const { closeModalOnEscape, transparentBG } = this.props
    if (this.isBrowser) {
      if (this.ele) {
        this.ele.classList.remove("noScroll")
        root.scrollTo(0, this.top)
      }
      if (this.blurEle && !transparentBG) {
        this.blurEle.classList.remove("blur-it")
      }
      if (closeModalOnEscape) {
        root.document.removeEventListener("keydown", this.handleKeydown, false)
      }
    }
  }

  handleKeydown = (e) => {
    const { toClose } = this.props
    if (e.keyCode === 27) {
      toClose()
    }
  }

  handleOutsideClick = () => {
    const { closeModalOnOutsideClick, toClose } = this.props
    if (closeModalOnOutsideClick) {
      toClose()
    }
  }

  handleModalClick = (e) => {
    e.stopPropagation()
  }

  render() {
    const {
      toClose,
      showCloseButton,
      children,
      show,
      width,
      height,
      title,
      loading,
      modalStyles,
      textTransform,
      overlayColor,
      showHorizontalBar,
      dialogStyles,
      mm,
      responsive,
      responsiveHeight,
      responsiveWidth,
      iconStyles,
      isLargeHeader,
      isBlank,
      customDialogColor,
      overlayStyles,
      modalVersion = 1,
      customRight,
      dialogCustomPosition,
      customCloseCSS,
      customIconStyle,
      customMobileIconStyle,
      showCloseText,
      closeIcon,
      zIndex,
      position,
      disableOutSideClose,
      className,
    } = this.props || {}

    return (
      <Transition in={show} timeout={150} mountOnEnter unmountOnExit>
        {state => (
          <Overlay
            onClick={() => {
              if (!disableOutSideClose) {
                this.handleOutsideClick()
              }
            }}
            className={className}
            overlayColor={overlayColor}
            modalVersion={modalVersion}
            position={position}
            zIndex={zIndex}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
              ...overlayStyles,

            }}
            ref={this.modalRef}
          >
            <Dialog
              responsive={responsive}
              responsiveHeight={responsiveHeight}
              responsiveWidth={responsiveWidth}
              customRight={customRight}
              dialogCustomPosition={dialogCustomPosition}
              onClick={this.handleModalClick}
              width={width}
              height={height}
              mm={mm}
              style={{ ...dialogStyles }}
              isBlank={isBlank}
              customDialogColor={customDialogColor}
            >
              {showCloseButton
                && (
                  <IconContainer
                    onClick={() => {
                      if (toClose) {
                        toClose()
                      }
                    }}
                  >
                    {title
                      && (
                        <TitleWrap isLargeHeader={isLargeHeader}>
                          <StyledTitle size="18" fontWeight={500} textTransform={textTransform}>
                            {title}
                          </StyledTitle>
                          { showHorizontalBar && <HorizontalBar />}
                        </TitleWrap>
                      )
                    }

                    {(!modalVersion || modalVersion === 1) && (
                      <StyledFontAwesome
                        modalVersion={modalVersion}
                        className={icons.arrowsClose}
                        onClick={toClose}
                        style={{ ...iconStyles }}
                      />
                    )}

                    {modalVersion === 2 && (
                      <CloseBtn
                        customCloseCSS={customCloseCSS}
                        customMobileIconStyle={customMobileIconStyle}
                        onClick={toClose}
                        src={closeIcon || ModalCloseBtnV2}
                        alt="Modal Close Button"
                        customIconStyle={customIconStyle}
                        style={{
                          ...iconStyles,
                        }}
                      />
                    )}

                    {modalVersion === 3 && (
                      <CloseBtn
                        customCloseCSS={customCloseCSS}
                        customMobileIconStyle={customMobileIconStyle}
                        modalVersion={modalVersion}
                        onClick={toClose}
                        src={closeIcon || ModalCloseBtnV2}
                        alt="Modal Close Button"
                        customIconStyle={customIconStyle}
                        style={{
                          ...iconStyles,
                        }}
                      />
                    )}
                   
                    {showCloseText && (<CloseText onClick={toClose}>{showCloseText}</CloseText>)}
                  </IconContainer>
                )}
              <Content
                responsive={responsive}
                responsiveHeight={responsiveHeight}
                responsiveWidth={responsiveWidth}
                isLargeHeader={isLargeHeader}
                isBlank={isBlank}
                style={{
                  borderRadius: 10,
                  ...modalStyles,
                }}
              >
                {loading ? <Loader top={10} bottom={0} /> : children}
              </Content>
            </Dialog>
          </Overlay>
        )}
      </Transition>
    )
  }
}

Modal.propTypes = {
  closeModalOnOutsideClick: PropTypes.bool,
  closeModalOnEscape: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  overlayColor: PropTypes.string,
  showHorizontalBar: PropTypes.bool,
  isBlank: PropTypes.bool,
  dialogStyles: PropTypes.object,
  modalStyles: PropTypes.object,
}

Modal.defaultProps = {
  closeModalOnOutsideClick: true,
  closeModalOnEscape: true,
  showCloseButton: true,
  modalStyles: {
    borderRadius: 4,
  },
  overlayColor: "rgba(0,0,0,0.3)",
  showHorizontalBar: true,
  isBlank: false,
  dialogStyles: {},
}

// eslint-disable-next-line import/no-mutable-exports
let ModalPortal = null
if (isBrowser()) {
  const ele = root.document.getElementById("modal_wrap")
  ModalPortal = (props) => {
    const { show } = props
    if (!show) {
      return null
    }
    return (
      ReactDOM.createPortal(
        <Modal {...props} />,
        ele,
      )
    )
  }
} else {
  ModalPortal = Modal
}

export default ModalPortal
