import React from "react"
import OffersV2 from "container/ListingsV2"
import {
  Wrap,
  StyledCheckbox,
  AttachmentText,
} from "./styles"
import DynamicMenu from "../DynamicMenu"

const CheckboxList = ({
  list,
  onClick,
  mainList,
  dropdownList,
  currentSearchText,
  customFilterText,
  hideCheckBox,
  img,
  isUploadModal,
  handleSelectedCheck = () => {},
  envelopeId,
}) => {
  const currentText = currentSearchText || customFilterText
  
  // const shouldRender = !currentText ||  list.name.toLowerCase().includes(currentText.toLowerCase())
  // console.log(currentText,"  currentText", list)
  // if (!shouldRender) {
  //   return null;
  // }
  return (
    <>

      {Array.isArray(list) && list.length>0 && list.filter((item) => {
        if (!item.id) {
          return false
        }
        if (currentText === "" || !currentText) {
          return item
        // eslint-disable-next-line no-else-return
        } else if (item && item.name && item.name.toLowerCase().includes(currentText.toLowerCase())) {
          return item
        }
      }).map(item => (
        <Wrap style={{ display: '-webkit-box',cursor: 'pointer', paddingBottom:'10px', paddingTop:'10px'} }>
          {!hideCheckBox ? (
            <StyledCheckbox
              checked={item.isSelected}
              value={item.id}
              onChange={e => handleSelectedCheck(e, item.id,item.template_id)}
            >
              {item.name}
            </StyledCheckbox>
          ) : (
            <AttachmentText style={{width:'520px'}}>
              {img && (
                <img src={img} alt="paperclip" />
              )}
              <p>{item.name}</p>
            </AttachmentText>
          )}
          <DynamicMenu
            dropdownList={dropdownList}
            mainList={mainList}
            id={isUploadModal ? item.id : item.envelope_id || item.template_id || item.id || item.url}
            isCompletedFlow={true}
            envelopeId={envelopeId}
            documentName={item.name}
          />
        </Wrap>
      ))}
      {!Array.isArray(list) && (
        <Wrap style={{ display: '-webkit-box',cursor: 'pointer', paddingBottom:'10px', paddingTop:'10px'} }>
          {!hideCheckBox ? (
            <StyledCheckbox
              checked={list.isSelected}
              value={list.id}
              onChange={e => handleSelectedCheck(e, list.id,list.template_id)}
            >
              {list.name}
            </StyledCheckbox>
          ) : (
            <AttachmentText style={{width:'520px'}}>
              {img && (
                <img src={img} alt="paperclip" />
              )}
              <p style={{overflow: "hidden", wordWrap: "break-word"}}>{list.name}</p>
            </AttachmentText>
          )}
          <DynamicMenu
            dropdownList={dropdownList}
            mainList={mainList}
            id={isUploadModal ? list.id : list.envelope_id || list.template_id || list.id || list.url}
            isCompletedFlow={true}
            envelopeId={envelopeId}
            documentName={list.name}
          />
        </Wrap>
      )}



      
    </>
  )
}

export default OffersV2(CheckboxList)
