import React, { useRef, useState } from "react"
import styled from "styled-components"
import LeftArrow from "images/rooms/icons/leftArrow_33.svg"
import CRMV2Container from "container/CRM"
import { CloseIcon } from "dumbComponents/CRMV2/CRMDashboard/Components/Icons"
import colors from "@colors"
import { get } from "lodash"
import { formatAmount } from "services/formUtils"
import {
  Form,
  Input,
  Select,
  Button,
  Space,
  Radio,
  GoogleAutoComplete,
  Upload,
  InputNumber,
} from "@ui/antd"
import {
  LabelComponent,
  RadioButton,
  TransAndBedWrap,
  TransactionTypeWrap,
  H2,
} from "../../../common"
import { ATC } from "@fonts"

const { Item } = Form
const { Group } = Radio

const UploadImage = styled(Upload)`
  padding-top: 25px;
  .ant-upload-select-picture-card {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 21px 24px;
    gap: 10px;
    width: 626px !important;
    height: 150px !important;

    .ant-upload {
      align-items: center;
      padding: 8px 14px;
      width: 119px;
      height: 32px !important;
      background: #5A5FF2;
      color: ${colors.white};
    }
  }
`

const Wrap = styled.div`
  padding: 20px;
`

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
`
const CloseWrap = styled.div`
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;

  p {
    font-family: ${ATC};
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
`

const CloseIconWrap = styled.div`
  cursor: pointer; 
`

const Header = styled.h1`
  font-family: ${ATC};
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
`

const FormWrap = styled.div``

const AddPropertyInfo = ({
  handleClose,
  uploadImage,
  isUploadingFile,
  addPropertyDetails,
  currentClientTransactionID,
}) => {
  const [count, setCount] = useState(0)
  const [fileList, setFileList] = useState([])
  const [transactionType, setTransactionType] = useState("")

  const formRef = useRef(null)

  const [metric, setMetric] = useState("")
  const onMetricChange = (value) => {
    setMetric(value)
    formRef.current.setFieldsValue({ metric: value })
  }

  const onFinish = (values) => {
    const finalPropertyDetailsPayload = {
      ...values,
      client_id: currentClientTransactionID,
    }

    addPropertyDetails({
      finalPropertyDetailsPayload,
    })
  }

  const validateMessages = () => {

  }

  const uploadImages = async (file) => {
    setCount(count + 1)
    await uploadImage({
      file,
      objName: `image${count}`,
      fileName: file.name,
    })
  }

  const onTransactionTypeChange = () => {

  }

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList)
  }

  const setStreet = (location) => {
    const locationDetails = {
      address: get(location, "address"),
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
      streetNumber: get(location, "street_number.long_name"),
      route: get(location, "route.long_name"),
    }

    const addressString = locationDetails.streetNumber
    && locationDetails
      .route ? `${locationDetails.streetNumber}${locationDetails.route ? ` ${locationDetails.route}` : ""}` : null
    
    formRef.current.setFieldsValue({
      listing_address: addressString || locationDetails.address,
    })
  }

  return (
    <Wrap>
      <HeaderWrap>
        <CloseWrap
          onClick={() => handleClose()}
        >
          <img src={LeftArrow} alt="Left Arrow" height={15} />
          <p>
            Back to CRM
          </p>
        </CloseWrap>
        <CloseIconWrap
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </CloseIconWrap>
      </HeaderWrap>
      <FormWrap>
        <Form
          name="add_property"
          preserve
          colon={false}
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
          validateMessages={validateMessages}
          ref={formRef}
        >
          <Header>Add Listing</Header>
          <UploadImage
            action={uploadImages}
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            // onPreview={onPreview}
            disabled={isUploadingFile === true}
          >
            {fileList.length < 20 && "+ Upload"}
          </UploadImage>
          <TransAndBedWrap>
            <TransactionTypeWrap>
              <H2>
                Transaction Type
              </H2>
              <Item name="rent_resale" rules={[{ required: true }]}>
                <Group onChange={onTransactionTypeChange} value={transactionType}>
                  <Space
                    style={{
                      display: "flex", marginBottom: 8, marginTop: 20,
                    }}
                    align="baseline"
                    direction="horizontal"
                  >
                    <RadioButton value="resale">Sale</RadioButton>
                    <RadioButton value="rent">Rent</RadioButton>
                  </Space>
                </Group>
              </Item>
            </TransactionTypeWrap>
          </TransAndBedWrap>
          <Space style={{ display: "block", marginBottom: 8, marginTop: 20 }} align="baseline">
            <Item name="listing_address" label={<LabelComponent text="Listing Address" />} rules={[{ required: true }]}>
              <GoogleAutoComplete
                id="cities"
                types={["address"]}
                name="cities"
                // formValue={cityFormValue}
                onChange={(e) => { }}
                location={(loc) => { setStreet(loc) }}
                placeholder=""
                styles={{
                  height: "49px",
                  borderRadius: "0px",
                  background: "white",
                  fontSize: "16px",
                  marginTop: "10px",
                }}
                restrict={{
                  lat: 37.09024,
                  lng: -95.712891,
                  south_west: {
                    lat: 25.82,
                    lng: -124.39,
                  },
                  north_east: {
                    lat: 49.38,
                    lng: -66.94,
                  },
                  address: "United States",
                  country: {
                    long_name: "United States",
                    short_name: "US",
                    types: [
                      "country",
                      "political",
                    ],
                  },
                }}
              />
            </Item>
          </Space>
          <Space style={{ display: "block", marginBottom: 8, marginTop: 20 }} align="baseline">
            <Item name="apt" label={<LabelComponent text="Apartment, unit or suite number" />} rules={[{ required: true }]}>
              <Input />
            </Item>
          </Space>
          <Space style={{ display: "block", marginBottom: 8, marginTop: 20 }} align="baseline">
            <Item
              name="price"
              label={<LabelComponent text="Estimated Price" />}
              rules={[{ required: true, pattern: new RegExp(/^[$-,-0-9]+$/), message: "Only numbers allowed" }]}
              format={formatAmount}
            >
              <Input />
            </Item>
          </Space>
          <Space style={{ display: "block", marginBottom: 8, width: 300 }} align="baseline">
            <Item
              name="bedroom"
              rules={[{ required: true, message: "Missing", type: "number" }]}
              label={<LabelComponent text="Bed" />}
            >
              <InputNumber />
            </Item>
            <Item
              name="bathroom"
              rules={[{ required: true, message: "Missing", type: "number" }]}
              label={<LabelComponent text="Bath" />}
            >
              <InputNumber />
            </Item>
          </Space>
          <Space style={{ display: "block", marginBottom: 8 }} align="baseline">
            <Item
              name="property_size"
              rules={[{ required: true, pattern: new RegExp(/^[0-9]+$/), message: "Only numbers allowed" }]}
              label={<LabelComponent text="Property Size" />}
            >
              <Input />
            </Item>
          </Space>
          <Item name="area_coverage" rules={[{ required: true }]}>
            <Group onChange={onMetricChange} value={metric}>
              <Space direction="horizontal">
                <RadioButton value="Sq. ft">Sq. ft</RadioButton>
                <RadioButton value="Sq. m">Sq.mt</RadioButton>
                <RadioButton value="Acres">Acres</RadioButton>
              </Space>
            </Group>
          </Item>
          <Item>
            <Button
              style={{
                background: "#5A5FF2",
                float: "right",
                borderColor: "white",
              }}
              type="primary"
              shape="round"
              size="large"
              htmlType="submit"
            >
              Add Listing
            </Button>
          </Item>
        </Form>
      </FormWrap>
    </Wrap>
  )
}

export default CRMV2Container(AddPropertyInfo)
