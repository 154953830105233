import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import { ga4DataLayerPush } from "services/Utils"
import flowContainer from "container/App/applyFlowContainer"
import TCContainer from "container/Realty/TransactionFormContainer"
import ContractDetailsForm from "dumbComponents/common/ContractDetailsForm/index"
import BasicInfo from "dumbComponents/TransactionCoordinationV2/FormsV2/pages/CoverSheet/BasicInfo"
import ClientInfo from "dumbComponents/TransactionCoordinationV2/FormsV2/pages/CoverSheet/ClientInfo"
import TransactionDetails from "dumbComponents/TransactionCoordinationV2/FormsV2/pages/CoverSheet/TransactionDetails"
import AskTCInformation from "dumbComponents/TransactionCoordinationV2/FormsV2/pages/CoverSheet/AskTCInformation"
import EscrowInformation from "dumbComponents/TransactionCoordinationV2/FormsV2/pages/CoverSheet/EscrowInformation"
import Integrations from "dumbComponents/TransactionCoordinationV2/FormsV2/pages/CoverSheet/Integrations"
import Vendors from "dumbComponents/TransactionCoordinationV2/FormsV2/pages/CoverSheet/Vendors"

import UploadFiles from "dumbComponents/TransactionCoordinationV2/FormsV2/pages/CoverSheet/UploadFiles"
import TimelineChecklist from "dumbComponents/TransactionCoordinationV2/FileListingMenu/Listing/TimelineChecklist"
import styled from "styled-components"
import ErrorModal from "dumbComponents/common/GlobalModals/ErrorModal"
import AppErrorBoundary from "dumbComponents/common/ErrorBoundary"
import CommissionsPopup from "dumbComponents/TransactionCoordinationV2/CommissionsPopup"
import Contract from "dumbComponents/TransactionCoordinationV2/FormsV2"
import ContractsTabs from "dumbComponents/common/ContractWrapperTabs"
import ListingTabs from "dumbComponents/ListingsV2/Components/ListingTabs"
import Navbar from "./Navbar"
import FileMenu from "./FileListingMenu"
import Forms from "./Forms"
import SuccessPage from "./SuccessPage"

import {
  BASIC_FORM_ROUTE,
  DETAILS_FORM_ROUTE,
  COORDINATION_FORM_ROUTE,
  ESCROW_FORM_ROUTE,
  SIGN_POST_FORM_ROUTE,
  UPLOAD_FILE_FORM_ROUTE,
  SUCCESS_ROUTE,
  TCAGENT_FORM_ROUTE,
} from "./Routes"

const Wrap = styled.div`
  padding: 73px 40px 50px 40px;
`

const TransactionCoordination = ({
  props: {
    fetchTransactionCoordinationDocumentsResponse,
    history,
    match,
  },
}) => (
  <Wrap>
    <Navbar
      history={history}
      isFormPage={history.location.pathname.includes("/contract/")}
      isLastPage={history.location.pathname.includes("/successfully-filed")}
      fetchTransactionCoordinationDocumentsResponse={fetchTransactionCoordinationDocumentsResponse}
    />
    <Switch>
      <Route
        exact
        path="/realty/transaction-coordination"
        //render={props => <FileMenu {...props} />}
        render={() => <Redirect to="/realty/transaction-dashboard" />}
      />

      <Route
        exact
        path="/realty/transaction-coordination/contract"
        component={Contract}
      />
      <Route path="/realty/transaction-coordination/contract/integrations" exact component={Integrations} />

      {/* <Route path="/realty/transaction-coordination/contract/form" exact component={ContractDetailsForm} /> */}
      <Route path="/realty/transaction-coordination/contract/form" exact component={ContractsTabs} />

      <Route path="/realty/transaction-coordination/contract/form/:listingId" exact component={ContractsTabs} />

      <Route path="/realty/transaction-coordination/contract/basic-info" exact component={BasicInfo} />

      <Route path="/realty/transaction-coordination/contract/client-info" exact component={ClientInfo} />

      <Route path="/realty/transaction-coordination/contract/transaction-details" exact component={TransactionDetails} />

      <Route path="/realty/transaction-coordination/contract/tc-info" exact component={AskTCInformation} />

      <Route path="/realty/transaction-coordination/contract/escrow-info" exact component={EscrowInformation} />

      <Route path="/realty/transaction-coordination/contract/vendors" exact component={Vendors} />

      <Route path="/realty/transaction-coordination/contract/upload" exact component={UploadFiles} />

      <Route path="/realty/transaction-coordination/contract/:dealId/timeline-checklist" component={TimelineChecklist} />
      
      <Route path="/listings/form" exact component={ListingTabs} />

      <Route path="/listings/form/:id" exact component={ListingTabs} />
      <Route
        exact
        path={SUCCESS_ROUTE}
        render={props => <SuccessPage {...props} />}
      />

    </Switch>
  </Wrap>
)

class TransactionCoordinationComponent extends React.Component {
  componentDidMount() {
    //
    const { getOnboardingCommission, user: userData } = this.props
    // toggleCommissionsPopup(1)
    const noUserData = !userData || (userData && Object.keys(userData).length <= 0)

    if (!noUserData) {
      ga4DataLayerPush(userData)
    }
    getOnboardingCommission()
  }

  render() {
    const {
      isErrorModalOpen,
      toggleErrorModal,
      toggleCommissionsPopupStep,
    } = this.props
    return (
      <>
        <AppErrorBoundary>
          <TransactionCoordination props={this.props} />
          {isErrorModalOpen && (
            <ErrorModal
              toClose={toggleErrorModal}
              redirectTo="/realty/transaction-coordination?filter=active"
            />
          )}
          {toggleCommissionsPopupStep > 0 && toggleCommissionsPopupStep < 5 && (
            <CommissionsPopup />
          )}

        </AppErrorBoundary>
      </>
    )
  }
}

export default TCContainer(flowContainer(TransactionCoordinationComponent))
