import {
  createSignalAction,
  createSimpleCreator,
} from "shared/reduxUtils"

const BASE = "ONBOARDING"

export const fetchUserInfoAction = createSignalAction(BASE, "FETCH_USER_INFO_ONBOARDING")
export const fetchUserOrientationInfoAction = createSignalAction(BASE, "FETCH_ORIENTATION_INFO_ONBOARDING")
export const createUserOrientationInfoAction = createSignalAction(BASE, "CREATE_ORIENTATION_INFO_ONBOARDING")
export const updateUserOrientationInfoAction = createSignalAction(BASE, "UPDATE_ORIENTATION_INFO_ONBOARDING")
export const uploadDocsToBucketAction = createSignalAction(BASE, "UPLOAD_ORIENTATION_DOCS_TO_BUCKET")
export const checkURLDataPopulatedAction = createSimpleCreator(BASE, "CHECK_IF_URL_POPULATED")
export const setFormStage = createSimpleCreator(BASE, "SET_FORM_STAGE")
export const setFileUploading = createSimpleCreator(BASE, "SET_FILE_UPLOADING")

// Setup Form
export const toggleSetupPopup = createSimpleCreator(BASE, "TOGGLE_SETUP_POPUP")
export const getOnboardingSetup = createSignalAction(BASE, "GET_ONBOARDING_SETUP")
export const createOnboardingSetup = createSignalAction(BASE, "CREATE_ONBOARDING_SETUP")
export const updateOnboardingSetup = createSignalAction(BASE, "UPDATENBOARDING_SETUP")
export const getStripeSecretKeyAction = createSignalAction(BASE, "GET_STRIPE_SECRET")

export const getFeeScheduleAction = createSignalAction(BASE, "GET_FEE_SCHEDULE")
export const sendVerifyEmailLinkAction = createSignalAction(BASE, "SEND_VERFY_LINK")
export const verifyEmailLinkTokenAction = createSignalAction(BASE, "VERIFY_EMAIL_TOKEN")
export const getDisplayScreenAction = createSignalAction(BASE, "GET_DISPLAY_SCREEN")
export const getReceiverViewICAAction = createSignalAction(BASE, "GET_ICA_VIEW")
export const getAllICADocListAction = createSignalAction(BASE, "GET_ALL_DOCS")
export const updateEnvelopeStatusAction = createSignalAction(BASE, "UPDATE_ENVELOPE_STATUS")
export const updateAgentEmailAction = createSignalAction(BASE, "UPDATE_AGENT_EMAIL")
export const hubspotSubscriptionAction = createSignalAction(BASE, "HUBSPOT_SUBS_REQ")

//banner action
export const triggerPaymentBannerAction = createSimpleCreator(BASE, "TOGGLE_PAYMENT_BANNER")

export const checkPaymentBannerAction = createSignalAction(BASE, "CHECK_PAYMENT_BANNER")
