import React from "react"
import styled from "styled-components"

const Wrap = styled.div`
  position: relative;
`

const Input = styled.input`
  outline: none;
  background-color: white;
  font: inherit;
  padding: 6px 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  height: 40px;
  box-shadow: none;
  overflow: visible;
  margin: 0;
  color: inherit;
  cursor: text;


  &:focus,
  &:active {
    box-shadow: none;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${props => props.isErrored ? "#ED1616" : "#ccc"};
    font-weight: 400;
    font-size: 12px;
  }

  // :-ms-input-placeholder {
  //   /* Internet Explorer 10-11 */
  //   color: #ccc;
  //   font-weight: 400;

  //   ${props => props.isShowError && `
  //       color: #ED1616 !important;
  //     `}
  // }

  // ::-ms-input-placeholder {
  //   /* Microsoft Edge */
  //   color: #ccc;
  //   font-weight: 400;

  //   ${props => props.isShowError && `
  //       color: #ED1616 !important;
  //     `}
  // }

  :disabled {
    color: #CCC;
  }

  ${props => props.bottomForm && `
     ::placeholder {
        font-family: Arial !important;
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 35px !important;
        letter-spacing: 0px !important;
        text-align: left !important;
        color: #727272;
     }
  `}
`

const IconWrap = styled.div`
  position: absolute;
  height: 40px;
  bottom: 0px;
  right: 0px;
  background: #f7f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #dcdcdc;
`

const Textbox = (props) => {
  // if (props.withoutBorder) {
  //   Input = styled(Input)`
  //     border: none;

  //   `
  // }

  const {
    meta,
  } = props || {}

  const isErrored = meta && meta.error && meta.touched && meta.error === "* Required"

  return (
    <React.Fragment>
      <Input
        style={{
          ...props.customInputStyle || {}
        }}
        innerRef={props.reference}
        onKeyPress={props.onKeyPress}
        className={props.className}
        readOnly={props.readOnly}
        onBlur={props.onBlur}
        onChange={props.update}
        meta={props.meta}
        isErrored={isErrored}
        {...props}
        value={props.value || props.defaultValue}
      />
      {props.icon && (
        <IconWrap>
          <i className={props.icon}></i>
        </IconWrap>
      )}
    </React.Fragment>
  )
}

export default Textbox
