import React, {useEffect} from "react"
import createToast from "dumbComponents/common/Toast/customToast"
import Listings from "container/Listings"
import {
  HeaderWrap,
  TitleAndSelectWrap,
  Title,
} from "./styles"
import ListingStatus from "../../../ListingStatus/Improved"
import ListingsBreadCrumb from "../../../BreadCrumb"
import TransactionBreadCrumb from "dumbComponents/TransactionDashboard/components/BreadCrumbTransaction"
import ErrorsList from "dumbComponents/OffersV3/CoverSheet/Components/ErrorsList.js"

const HeaderSectionCommon = ({
  listingData,
  viewContractForListing,
  updateCurrentListingData,
  toggleMoveToMarket,
  toggleStatusChangeModal,
  getBrokerageListingsStatuses,
  getListingMappings
}) => {
  const {
    propertyData,
    dealId,
  } = listingData || {}


  useEffect(() => {
    getBrokerageListingsStatuses()
    //getListingMappings()
  },[])
  const headerText = propertyData && propertyData?.street || "New Listing"

  const handleRowListingStatusChange = (value, id) => {
    const {
      mls_id,
    } = propertyData
    //depending on value perform action
    switch (value) {
      case "pending":
        //contracts flow
        if (listingData.listingStatus !== "incomplete") {
          viewContractForListing(true)
        } else {
          createToast("The coversheet is incomplete.", "error")
        }
        break
      case "active_listing":
        //market modal with mls
        updateCurrentListingData({
          ...listingData,
          dealId: id,
        })
        // toggleMoveToMarket(true)
        if (!mls_id || mls_id === null || mls_id === undefined) {
          toggleMoveToMarket(true)
        } else {
          toggleStatusChangeModal({
            bool: true,
            statusChangedTo: value,
            listingID: id,
          })
        }
        break
      case "coming_soon":
      case "listing_canceled":
      case "cancelled":
      case "withdrawn":
      case "expired":
      case "listing_expired":
        // note modal
        toggleStatusChangeModal({
          bool: true,
          statusChangedTo: value,
          listingID: id,
        })
        break
      default:
        //
    }
  }
  return (
    <HeaderWrap>
      {/* <ListingsBreadCrumb /> */}
      <TransactionBreadCrumb />
      <ErrorsList/>
      <TitleAndSelectWrap>
        <Title>
          <p>{headerText}</p>
        </Title>
        <ListingStatus
          defaultValue="incomplete"
          currentStatus={listingData.listingStatus || "incomplete"}
          disabledStatuses={["incomplete"]}
          handleStatusChange={(value) => {
            handleRowListingStatusChange(value, dealId, listingData)
          }}
        />
      </TitleAndSelectWrap>
    </HeaderWrap>
  )
}

export default Listings(HeaderSectionCommon)

