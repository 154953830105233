import React, { useState, useEffect } from "react";
import CRMV2Container from "container/CRM";
import OffersV2 from "container/OffersV2"
import { withRouter } from "react-router-dom";
import ReferralContainer from "container/Referral";
import TechAssetsContainer from "container/TechAssets";
import BrbcContainer from "container/BRBC";
import { DownOutlined } from "@ant-design/icons";
import BrbcDocIcon from "../../../../../../images/icons/brbDocIcon";
import BrbcSignPendingFromAgent from "../../../../../../images/icons/brbcPendingSignIcon";
import BrbcTickIcon from "../../../../../../images/icons/brbcTickIcon";
import ThreeDotMenu from "../../../../../../images/icons/threedotMenu";
import BrbcSignPending from "../../../../../../images/icons/brbcSignPendingIcon";
import { Dropdown, Space, Spin, Icon } from "antd";

import {
  BrbcCard,
  CardContainer,
  CardHeadingDiv,
  CardHeadingBold,
  CardHeadingLight,
  GetStartedButton,
  OnSuccessContainer,
  ReminderText,
} from "./styles";
import BrbcModal from "./BrbcModal";
import PubNubForBrbc from "./PubNubForBrbc/index";
import Cookies from "react-cookies";
import FreeTrialExpiryModal from "@ui/FreeTrialExpiryModal";

const BrbcBanner = ({
  getCtIdResponse,
  setCurrentDrawerPage,
  sendBrbcReminder,
  sendBrbcReminderResponse,
  fetchBrbcStatus,
  fetchBrbcStatusResponse,
  getSenderView,
  getReceiverView,
  receiverViewResponse,
  senderViewResponse,
  fetchBrbcCoversheet,
  getAgentDetailsFromState,
  getCrmClientResponse,
  downloadEnvelopeDocOffer,
}) => {
  const { data: clientTransactionData } = getCtIdResponse || {};
  const { data: brbcReminderReponse, isFetching: isFetchingBrbcReminder } =
    sendBrbcReminderResponse || {};
  const { data: brbcData, isFetching: isFetchingBrbcData } =
    fetchBrbcStatusResponse || {};
  const { data: signViewUrl, isFetching: isFetchingSignView } =
    receiverViewResponse || {};
  const { data: docViewUrl, isFetching: isFetchingDocView } =
    senderViewResponse || {};
  const { isFetching: fetchingUpdatedValues, data: clientData } =
    getCrmClientResponse || {};
    const isBrokerPlan = Cookies.load("isSubscribedToBrokerPlan")
    const isSubscribedToBrokerPlan = isBrokerPlan =="false" ? false : true
      const [modalVisible, setModalVisible] = useState(false);
  const tcInformation = Cookies.load("tcInformation")
    const EOIsubscriptionStatus = Cookies.load("EOIstatusForSubscription")
    const subscriptionStatus = Cookies.load("subscriptionStatus")
  const negativeStatuses = ["incomplete", "past_due", "paused", "incomplete_expired", "overdue", "canceled", "unpaid"];
const isEOIOrSubsStatusNegative = 
    negativeStatuses.includes(EOIsubscriptionStatus) || 
    negativeStatuses.includes(subscriptionStatus);
  const agentId = clientData?.agent_id;
  const clientId = clientData?.id;
  const brbcStatus = brbcData?.status;
  const envelopeId = brbcData?.docusign[0]?.envelope_id;

  const { type } = clientTransactionData || {};
  const [uploadDocBrbcModal, setUploadDocBrbcModal] = useState(false);
  const [deleteBrbcModal, setDeleteBrbcModal] = useState(false);
  const [voidBrbcModal, setVoidBrbcModal] = useState(false);
  const [voidAndDuplicateBrbcModal, setVoidAndDuplicateBrbcModal] = useState(
    false
  );
  const [fillAndSignBrbcModal, setFillAndSignBrbcModal] = useState(false);

  const brbcReminder = async () => {
    const payload = {
      envelopeId: envelopeId,
    };
    sendBrbcReminder(payload);
  };

  const handleMenuClick = (e) => {
    if (e.key === "fillAndSign") {
      // fetchBrbcCoversheet({
      //   agentId: agentId,
      //   clientId: clientId,
      // });
      const searchCriteriaState =
        clientData?.clientTransactions.length > 0
          ? clientData?.clientTransactions?.BUYING[0]?.requirement?.locations[0]
              ?.state
          : "California";
      getAgentDetailsFromState({
        agentId: agentId,
        state: searchCriteriaState,
      });
      setCurrentDrawerPage("brbc-form-drawer");
    } else if (e.key === "uploadFromDevice") {
      setUploadDocBrbcModal(true);
    } else if (e.key === "deleteBrbc" || e.key === "delete") {
      setDeleteBrbcModal(true);
    } else if (e.key === "void") {
      setVoidBrbcModal(true);
    } else if (e.key === "voidAndDuplicate") {
      setVoidAndDuplicateBrbcModal(true);
    } else if (e.key === "view") {
      // window.open(docViewUrl?.url, "_blank");
      getSenderView({
        envelopeId: envelopeId,
      });
    }
    else if (e.key === "download") {
      downloadEnvelopeDocOffer({
        payload: {
          envelopeId: envelopeId,
        },
        documentDetail: {
          name: `${brbcData?.docusign[0]?.title}`,
        },
      });
    }
  };

  const brbcMenuItems = [
    {
      label: "Fill & sign",
      key: "fillAndSign",
    },
    {
      label: "Upload from device",
      key: "uploadFromDevice",
    },
  ];

  const brbcUploadOnSuccessMenuItems = [
    {
      label: "Delete",
      key: "deleteBrbc",
    },
  ];

  const brbcFillOnSuccessMenuItems = [
    {
      label: "Void",
      key: "void",
    },
    {
      label: "Void & Duplicate",
      key: "voidAndDuplicate",
    },
  ];

  const brbcDismisSignModal = [
    {
      label: "View",
      key: "view",
    },
    {
      label: "Delete",
      key: "delete",
    },
  ];

  const brbcAgentSignPending = [
    {
      label: "Void",
      key: "void",
    },
    {
      label: "Download",
      key: "download",
    }
  ];

  const brcbMenuProps = {
    items: brbcMenuItems,
    onClick: handleMenuClick,
  };

  const brcbUploadOnSuccessMenuProps = {
    items: brbcUploadOnSuccessMenuItems,
    onClick: handleMenuClick,
  };

  const brcbFillOnSuccessMenuProps = {
    items: brbcFillOnSuccessMenuItems,
    onClick: handleMenuClick,
  };

  const brcbDismissSignModalMenuProps = {
    items: brbcDismisSignModal,
    onClick: handleMenuClick,
  };

  const brbcAgentSignPendingMenuProps = {
    items: brbcAgentSignPending,
    onClick: handleMenuClick,
  };

  return (
    <>
    <div>
      {type && type.toLowerCase() === "buyer" && (
        <BrbcCard bannerStatus={brbcStatus}>
          {uploadDocBrbcModal && (
            <BrbcModal
              brbcModalType="upload-doc"
              toggleModal={uploadDocBrbcModal}
              onClose={() => setUploadDocBrbcModal(false)}
            />
          )}
          {deleteBrbcModal && (
            <BrbcModal
              brbcModalType="delete-brbc"
              toggleModal={deleteBrbcModal}
              onClose={() => setDeleteBrbcModal(false)}
            />
          )}
          {voidBrbcModal && (
            <BrbcModal
              brbcModalType="void-brbc"
              toggleModal={voidBrbcModal}
              onClose={() => setVoidBrbcModal(false)}
            />
          )}
          {voidAndDuplicateBrbcModal && (
            <BrbcModal
              brbcModalType="void-duplicate-brbc"
              toggleModal={voidAndDuplicateBrbcModal}
              onClose={() => setVoidAndDuplicateBrbcModal(false)}
            />
          )}
          {fillAndSignBrbcModal && (
            <BrbcModal
              brbcModalType="sign-brbc"
              toggleModal={fillAndSignBrbcModal}
              onClose={() => setFillAndSignBrbcModal(false)}
            />
          )}
          {!isSubscribedToBrokerPlan && (tcInformation?.is_tc === 0) && (
            <>
              <BrbcDocIcon width="25px" style={{ marginRight: "8px" }} />

              <div>
                <CardHeadingBold>
                  Unlock buyer representation tools
                </CardHeadingBold>
                <CardHeadingLight>
                  Upgrade to Brokerage Subscription to create compliant buyer
                  agreements and BRBC workflows.
                </CardHeadingLight>
              </div>
              <GetStartedButton onClick={() => setModalVisible(true)}>
                <Space style={{ cursor: "pointer", fontSize:'14px', marginLeft:'20px' }}>UPGRADE</Space>
              </GetStartedButton>
            </>
          )}
          {!isFetchingBrbcData && ((isSubscribedToBrokerPlan || tcInformation?.is_tc == 1) && !isEOIOrSubsStatusNegative) && (
            <CardContainer>
              <CardHeadingDiv>
                {/* processing  */}
                {brbcStatus === "processing" && (
                  <>
                    <Spin width="25px" style={{ marginRight: "8px" }} />
                    <div>
                      <CardHeadingBold>
                        Processing your buyer representation
                      </CardHeadingBold>
                      <CardHeadingLight>
                        This will only take a moment.
                      </CardHeadingLight>
                    </div>
                  </>
                )}

                {/* default view  */}
                {brbcStatus === "get_started" && (
                  <>
                    <BrbcDocIcon width="25px" style={{ marginRight: "8px" }} />

                    <div>
                      <CardHeadingBold>
                        Complete your buyer representation
                      </CardHeadingBold>
                      <CardHeadingLight>
                        Finalize your details to secure your representation.
                      </CardHeadingLight>
                    </div>
                  </>
                )}

                {/* completed view  */}
                {brbcStatus === "completed" && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <BrbcTickIcon width="25px" style={{ marginRight: "8px" }} />
                    <div>
                      <CardHeadingBold>
                        Buyer representation completed.
                      </CardHeadingBold>
                    </div>
                  </div>
                )}

                {/* sign pending from client  */}
                {brbcStatus === "buyer_signature_pending" && (
                  <>
                    <BrbcSignPending
                      width="25px"
                      style={{ marginRight: "8px" }}
                    />
                    <div>
                      <CardHeadingBold>
                        Client signature pending for buyer representation
                      </CardHeadingBold>
                      <CardHeadingLight>
                        Your part is done! Awaiting your client's signature to
                        finalize the representation.
                      </CardHeadingLight>
                    </div>
                  </>
                )}

                {/* dismiss sign modal  */}
                {brbcStatus === "add_details" && (
                  <>
                    <BrbcSignPendingFromAgent
                      width="25px"
                      style={{ marginRight: "8px" }}
                    />
                    <div>
                      <CardHeadingBold>
                        Your buyer representation is almost ready
                      </CardHeadingBold>
                      <CardHeadingLight>
                        Complete the details to proceed with signing.
                      </CardHeadingLight>
                    </div>
                  </>
                )}

                {/* agent sign is pending  */}
                {brbcStatus === "your_signature_pending" && (
                  <>
                    <BrbcSignPendingFromAgent
                      width="25px"
                      style={{ marginRight: "8px" }}
                    />
                    <div>
                      <CardHeadingBold>
                        Your signature is still pending
                      </CardHeadingBold>
                      <CardHeadingLight>
                        Complete the signing process to finalize the buyer
                        representation.
                      </CardHeadingLight>
                    </div>
                  </>
                )}
              </CardHeadingDiv>

              {/* default view  */}
              {brbcStatus === "get_started" && (
                <Dropdown menu={brcbMenuProps}>
                  <GetStartedButton>
                    <Space style={{ cursor: "pointer" }}>
                      GET STARTED
                      <DownOutlined
                        style={{ color: "#5A5FF2", paddingBottom: "8px" }}
                      />
                    </Space>
                  </GetStartedButton>
                </Dropdown>
              )}

              {/* completed view  */}
              {brbcStatus === "completed" && (
                <OnSuccessContainer>
                  <a
                    onClick={() =>
                      brbcData?.presignedDocuments.length > 0
                        ? brbcData?.presignedDocuments[0]?.download_url &&
                          window.open(
                            brbcData?.presignedDocuments[0]?.download_url,
                            "_blank"
                          )
                        : getReceiverView({
                            envelopeId: envelopeId,
                          })
                    }
                    style={{ cursor: "pointer" }}
                  >
                    VIEW
                  </a>
                  <Dropdown
                    menu={
                      brbcData?.presignedDocuments.length > 0
                        ? brcbUploadOnSuccessMenuProps
                        : brcbFillOnSuccessMenuProps
                    }
                  >
                    <Space style={{ cursor: "pointer" }}>
                      <ThreeDotMenu />
                    </Space>
                  </Dropdown>
                </OnSuccessContainer>
              )}

              {/* dismiss sign modal  */}
              {brbcStatus === "add_details" && (
                <OnSuccessContainer>
                  <a
                    onClick={() => setFillAndSignBrbcModal(true)}
                    style={{ cursor: "pointer" }}
                  >
                    ADD DETAILS
                  </a>
                  <Dropdown menu={brcbDismissSignModalMenuProps}>
                    <Space style={{ cursor: "pointer" }}>
                      <ThreeDotMenu />
                    </Space>
                  </Dropdown>
                </OnSuccessContainer>
              )}

              {/* agent sign is pending  */}
              {brbcStatus === "your_signature_pending" && (
                <OnSuccessContainer>
                  <a
                    onClick={() =>
                      // signViewUrl?.url &&
                      // window.open(signViewUrl?.url, "_blank")
                      getReceiverView({
                        envelopeId: envelopeId,
                      })
                    }
                    style={{
                      textAlign: "center",
                      width: "150px",
                      cursor: "pointer",
                    }}
                    // disabled={!signViewUrl}
                  >
                    CONTINUE TO SIGN
                  </a>
                  <Dropdown menu={brbcAgentSignPendingMenuProps}>
                    <Space style={{ cursor: "pointer" }}>
                      <ThreeDotMenu />
                    </Space>
                  </Dropdown>
                </OnSuccessContainer>
              )}

              {/* sign pending from client  */}
              {brbcStatus === "buyer_signature_pending" && (
                <>
                  <ReminderText
                    onClick={brbcReminder}
                    style={{ cursor: "pointer" }}
                  >
                    {isFetchingBrbcReminder ? "Sending..." : "SEND REMINDER"}
                  </ReminderText>
                  <Dropdown menu={brbcAgentSignPendingMenuProps}>
                    <Space style={{ cursor: "pointer" }}>
                      <ThreeDotMenu />
                    </Space>
                  </Dropdown>
                </>
              )}
            </CardContainer>
          )}
        </BrbcCard>
      )}
    </div>
      {
             modalVisible && <FreeTrialExpiryModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
  }
  </>
  );
};

export default withRouter(OffersV2(
  ReferralContainer(
    TechAssetsContainer(CRMV2Container(BrbcContainer(BrbcBanner))))
  )
);