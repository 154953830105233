import React from "react"
import styled from "styled-components"
import colors from "@colors"
import { ATC } from "@fonts"
import CloseIcon from "images/Close_7.svg"
import CircularImage from "dumbComponents/common/CircularImage"
import {
  FormTitle,
  TagsSpan,
  TagButton,
} from "../common"

const Wrap = styled.div``

const InviteWrap = styled.div`
  margin-top: 10px;
`

const InviteCTA = styled.button`
  height: 41px;
  width: 153px;
  border-radius: 4px;

  background: white;
  cursor: pointer;
  border: 1px solid rgba(229, 229, 229, 1);

  p {
    text-align: center;
    font-family: ${ATC};
    font-size: 14px;
    font-weight: 300;
    margin: 0;
    line-height: 16px;
    letter-spacing: 0px;
    color: ${colors.new_primary};
  }
`

const Name = styled.p`
font-family: ATC;
font-size: 14px;
font-weight: 300;
line-height: 18px;
letter-spacing: 0px;
text-align: left;
margin: 0;
`

const UserCard = styled.div``

const InviteSpeakers = ({
  locations,
  handleRemoveTag,
  toggleInviteAttendeesModal,
}) => (
  <Wrap>
    <FormTitle>
      <p>Invite Attendees</p>
    </FormTitle>
    {/**TAG*/}
    <TagsSpan>
      {locations && (
        locations.map(location => (
          <UserCard>
            <TagButton
              type="button"
              onClick={(e) => {
                e.stopPropagation()
                handleRemoveTag(location)
              }}
            >
              <Name>
                {location.city ? (
                  <>
                    {`${location.city}, ${location.state}`}
                  </>
                ) : (
                  <>
                    {`${location.state}`}
                  </>
                )}
              </Name>
              <img src={CloseIcon} alt="close" />
            </TagButton>
          </UserCard>
        ))
      )}
    </TagsSpan>
    <InviteWrap>
      <InviteCTA
        onClick={() => toggleInviteAttendeesModal(true)}
        type="button"
      >
        <p>+ Invite Attendees</p>
      </InviteCTA>
    </InviteWrap>
  </Wrap>
)

export default InviteSpeakers
