import React, { useState, useEffect } from "react"
import {
  File,
} from "@ui/Form"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import { toast } from "react-toastify"
import colors from "@colors"
import root from "window-or-global"
import query from "query-string"
import { required } from "services/formUtils"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
import TransactionContainer from "container/Realty/TransactionFormContainer"
import { reduxForm, Field } from "redux-form"
import {
  // Wrap,
  FormWrap,
} from "./styles"
import {
  FetchFileIfIDExists,
  getIsEdit,
} from "./utils"
import { ATC, INTER, MONTSERRAT } from "@fonts"

const Wrap = styled.div`
`

const FieldWrap = styled.div`
  & input[type="file"] {
    display: none;
  }
`

const CustomLabel = styled.label`
  height: 36px;
  width: 138px;
  border-radius: 100px;
  padding: 10px 14px 10px 14px;
  background: ${colors.new_primary};
  position: relative;
  top: 0;
  cursor: pointer;
  display: block;
  margin: auto;
  ${props => props.disabled && `
    background: grey;
    cursor: not-allowed;
  `}

  p {
    color: #F9F9F9;
    font-family: ${MONTSERRAT};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }
`

const FileUploadIndicator = styled.p`
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: black;
  margin-top: 17px;
  position: relative;
  visibility: hidden;
  height: 36px;
  margin-bottom: -36px;
  left: 4px;

  ${props => props.toggleVisibility && `
    visibility: visible;
   `}
`

const FileUploadFormUI = ({
  handleFileUploader,
  currentDeal,
  id,
  transactionFormUpdateStageResponse,
  uploadTCFilesToDbResponse,
  uploadTCFilesResponse,
  handleSubmit,
  fetchTransactionCoordinationDocumentsResponse,
  combinedTCValues,
  disabled,
}) => {
  const {
    isFetching: isUploadingToS3,
  } = uploadTCFilesResponse || {}

  const {
    isFetching: isUploadingToDB,
  } = uploadTCFilesToDbResponse || {}

  const {
    data: deals,
  } = fetchTransactionCoordinationDocumentsResponse

  const {
    isFetching: isUpdatingStage,
  } = transactionFormUpdateStageResponse || {}

  let isNewListing = combinedTCValues && combinedTCValues.file_type === "new_listing"

  let hasDoneUploadFileStage = false

  let hasAlreadyCompletedDeal = false

  let isCoListed = false

  let isUsingDealTC = false

  let disableField = false

  if (id && deals) {
    isNewListing = currentDeal && currentDeal.file_type === "new_listing"
    const currentSteps = currentDeal && currentDeal.completed_steps
    hasDoneUploadFileStage = currentSteps && currentSteps.length > 0
      && currentSteps.includes("upload_files")

    hasAlreadyCompletedDeal = currentSteps && currentSteps.length > 0
      && (currentSteps.includes("completed")
        || currentSteps.includes("tc_verified")
        || currentSteps.includes("commission_verified")
        || currentSteps.includes("verified"))
      && hasDoneUploadFileStage
    isCoListed = currentDeal && currentDeal.is_co_listed_deal === 1
    isUsingDealTC = currentDeal && currentDeal.is_using_deal_tc
    disableField = isCoListed || getIsEdit(currentDeal)
  }

  return (
    <FormWrap disabled={disabled} onSubmit={handleSubmit}>
      <FieldWrap>
        {disabled ? (
          <p>Upload disabled</p>
        ) : (
          <>
            <CustomLabel
              disabled={disabled}
              for="tc-file-upload"
            >
              <p>
                Upload files
              </p>
            </CustomLabel>
            <Field
              name="tc_file_upload"
              component={File}
              multiple
              version2
              disabled={disabled}
              validate={[required]}
              id="tc-file-upload"
              customOnChange={(file) => {
                handleFileUploader(file)
              }}
            />
          </>
        )}

      </FieldWrap>
      <FileUploadIndicator
        toggleVisibility={isUploadingToS3 || isUploadingToDB}
      >
        <ThreeDotLoader color={colors.newPrimaryColor} />
      </FileUploadIndicator>
    </FormWrap>
  )
}

const FileUploadFormComponent = ({
  invalid,
  history,
  transactionFormUpdateStageResponse,
  uploadTCFilesToDbResponse,
  uploadTCFilesResponse,
  fetchTransactionCoordinationDocumentsResponse,
  uploadTCFiles,
  tcStageUpdate,
  combinedTCValues,
  fetchTCDocumentsFromS3Response,
  fetchFilesFromS3,
  deleteFileFromS3,
  deleteTCDocumentsFromS3Response,
  fetchTCFiles,
  dealId,
  handleFileUpload,
  disabled,
}) => {
  const { search } = root.location
  const parsedQuery = query.parse(search)

  const toastConfig = {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: true,
  }

  useEffect(() => {
    if (dealId) {
      fetchFilesFromS3({ id: dealId })
    }
  }, [])

  try {
    FetchFileIfIDExists(dealId, currentDeal, fetchTCFiles)
  } catch (e) {
    //fail silently
  }

  const {
    data: fetchedFiles,
    isFetching: isFetchingFiles,
  } = fetchTCDocumentsFromS3Response || {}

  const {
    isFetching: isDeletingFiles,
  } = deleteTCDocumentsFromS3Response || {}

  const handleFileUploader = (file) => {
    const fileArr = []
    Object.values(file).forEach((item) => {
      fileArr.push(item)
    })
    let errorFlag = { remove: "", error: false }
    if (fetchedFiles) {
      fetchedFiles.forEach((itemFetched) => {
        const curr = fileArr[0].name
        if (itemFetched.title === curr) {
          errorFlag = { remove: curr, error: true }
        }
      })
    }
    if (errorFlag.error) {
      toast.error(`${errorFlag.remove} is already submitted`, toastConfig)
    } else {
      fileArr.forEach((fi) => {
        uploadTCFiles({
          file: fi,
          id: dealId,
        })
      })
    }
  }

  const {
    data: deals,
  } = fetchTransactionCoordinationDocumentsResponse || {}
  const currentId = dealId
  const currentDeal = deals && deals.length > 0 && deals.filter(deal => deal.id === parseInt(currentId, 10))[0]
  const isCoListed = currentDeal && currentDeal.is_co_listed_deal === 1
  const disableField = isCoListed || getIsEdit(currentDeal)

  return (
    <Wrap>
      {disabled ? (
        <p>Upload Disabled</p>
      ) : (
        <FileUploadForm
          isFetchingFiles={isFetchingFiles}
          isInvalid={invalid}
          history={history}
          handleFileUploader={disabled ? () => {} : handleFileUploader}
          fetchedFilesLength={fetchedFiles && fetchedFiles.length > 0 || 0}
          uploadTCFiles={uploadTCFiles}
          currentDeal={currentDeal}
          id={dealId}
          tcStageUpdate={tcStageUpdate}
          fetchTransactionCoordinationDocumentsResponse={fetchTransactionCoordinationDocumentsResponse}
          transactionFormUpdateStageResponse={transactionFormUpdateStageResponse}
          uploadTCFilesToDbResponse={uploadTCFilesToDbResponse}
          uploadTCFilesResponse={uploadTCFilesResponse}
          combinedTCValues={combinedTCValues}
          isDeletingFiles={isDeletingFiles}
          disabled={disabled}
        />
      )}
    </Wrap>
  )
}

const FileUploadForm = reduxForm({
  form: "TC_TRANSACTION_FILE_UPLOAD_FORM",
})(FileUploadFormUI)

export default TransactionContainer(withRouter(FileUploadFormComponent))
