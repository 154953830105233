import React, { useState } from "react"
import styled from "styled-components"
import { ATC } from "@fonts"
import colors from "@colors"
import SuccessImage from "dumbComponents/FinancingDashboard/images/Success.png"
import GreyCard from "dumbComponents/FinancingDashboard/images/GreyClose.svg"

const Wrap = styled.div`
  position: relative;
  margin-bottom: 35px;
  margin-top: 20px;
  width: 900px;

  ${props => props.isHide && `
    display: none;
  `}
`
const ImageContainer = styled.div`
  display: flex;
  background: ${colors.new_primary};
`
const Icon = styled.img`
  position: absolute;
  right: -15px;
  top: -15px;
  cursor: pointer;
`

const HeaderText = styled.h1`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 28px;
  line-height: 32px;
  margin: 0px;
`

const SubText = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  margin: 6px 0 0;
`

const Image = styled.img`
  height: 106px;
  background-position: left;
  background-size: cover;
`
const Content = styled.div`
  background: ${colors.dreamWhite};
  padding: 24px;
  height: 106px;
  flex: 1;
  background: ${colors.new_primary};
  color: ${colors.dreamWhite};
`

const CRMRevampBanner = () => {
  const [isHide, setHide] = useState(false)
  return (
    <Wrap
      isHide={isHide}
    >
      <ImageContainer>
        <Content>
          <HeaderText>New upgrade coming soon!</HeaderText>
          <SubText>Stay tuned for enriched CRM experience.</SubText>
        </Content>
        <Image src={SuccessImage} />
      </ImageContainer>
      <Icon src={GreyCard} onClick={() => setHide(true)}/>
    </Wrap>
  )
}

export default CRMRevampBanner
