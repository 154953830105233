import styled from "styled-components";
import colors from "@colors";
import { MONTSERRAT, INTER } from "@fonts";
import {
  Button,
  Tag,
} from "antd";
export const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  gap:8px;`
;

export const OuterWrap = styled.div`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 24px;
  position: fixed;
  width:400px;
  border-radius: 40px;
border: 1px solid ${colors.new_border_outline};
  background: ${colors.white};
  box-shadow: 0px 4px 10px 0px rgba(165, 168, 255, 0.30);
  transform: translate(-50%);
  bottom: 20px;
  left: 79%;
  z-index: 9;
  justify-content: space-between;
`
export const InnerText = styled.div`
  p {
    color: ${colors.new_grey400};
    /* leading-tight/subtitle-2/semi-bold */
    /* font-family: ${MONTSERRAT};*/
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%; /* 18.9px */
    letter-spacing: 0.5px;
    margin: 0;
  }
`

export const GradientText = styled.span`
  font-family: ${MONTSERRAT};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 185%;
  letter-spacing: 0.667px;
  background: var(
    --Mel-Linear-Text,
    linear-gradient(
      142deg,
      #5a5ff2 -24.07%,
      #b6b8ff 5.49%,
      #f08068 47.53%,
      #f0a468 68.31%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;


export const FilterButton = styled(Button)`
  border-radius: 16px !important;
  background: ${colors.white} 16px !important;
  border: 1px solid ${colors.new_borderLight} 16px !important;
  color: ${colors.new_buttonColor} 16px !important;
  display: flex;
  align-items: center;
  height: 32px;

  &:hover {
    border-color: ${colors.new_primary} 16px !important;
    color: ${colors.new_primary} 16px !important;
  }

  .anticon {
    margin-left: 8px;
  }`
;

export const ApplyButton = styled(Button)`
  border-radius: 8px;
  background: ${colors.new_primary};
  color: ${colors.white};
  width: 100%;`
;

export const StatusTag = styled(Tag)`
  border-radius: 16px !important;
  font-size: 12px !important;
  padding: 4px 12px !important;
  border: none !important;
  color: ${(props) => (props.status === "Shortlisted" ? `${colors.new_text_purple_600}` : "#F05152")} !important;
  background: ${(props) =>
    props.status === "Shortlisted" ? `${colors.new_tab_color }` : `${colors.new_redTextColor}`} !important;`
;

export const ModalTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.new_text_purple_600};`
;

export const TimelineContainer = styled.div`
  padding: 16px;
  background-color: ${colors.new_buttonText};
  border-radius: 8px;
  margin-top: 16px;
  max-height: 300px;
  overflow-y: auto;`
;

export const TimelineItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  background-color: ${(props) => (props.index % 2 === 0 ? `${colors.new_timeline_color}` : `${colors.white}`)};
  border-radius: 4px;
  margin-bottom: 8px;`
;

export const TimelineDate = styled.div`
  font-size: 12px;
  color: ${colors.new_timeline_Date_color};`
;

export const TimelineStatus = styled.div`
  font-size: 14px;
  color: ${(props) => (props.status === "Shortlisted" ? `${colors.new_primary}` : `${colors.new_timeline_status_color}`)};
  font-weight: 500;`
;
export const BedNumber = styled.p`
color:${colors.new_text_purple_600};
font-family: ${INTER};
font-size: 14px;
font-weight: 600;
letter-spacing: 0.5px;`

export const Beds = styled.p`
color: ${colors.new_levelColor};
font-family: ${INTER};
font-size: 14px;
font-weight: 400;
letter-spacing: 0.5px;`

export const Dot = styled.div`
height:6px;
width:6px;
background:${colors.new_border_outline};
border-radius:50%;
margin-top:8px;
margin-left:8px;
margin-right:8px;

`

// Styled components
 export const ModalHeader = styled.div`
  text-align: center;
  margin-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
`;

 export const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #0073ff;
  margin-bottom: 8px;
`;

 export const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 4px;
  text-align:left;
`;

 export const SubTitle = styled.p`
  font-size: 14px;
  color: #7d7da9;
  margin-bottom: 32px;
    text-align:left;
`;

 export const FeaturesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 16px;
  padding-right: 16px;
`;

 export const Feature = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

export const IconWrapper = styled.div`
  font-size: 24px;
  color: #ff9900;
  .points-icon {
    height: 24px;
    width: 24px;
    margin-top: 20px;
  }
`;

export const FeatureDetails = styled.div`
  font-size: 14px;
  color: #555;

  .feature-title {
    font-weight: bold;
    color: #333;
  }

  .feature-description {
    margin-top: 4px;
    font-size: 14px;
    color: #7d7da9;
  }
`;

export const FooterButton = styled(Button)`
  border-radius: 32px;
  background: var(--Color-Primary-Primary-500, #5a5ff2);
  display: flex;
  height: 48px;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 32px;
  color: white;
  width: 100%;
  align-self: stretch;
`;

export const WaitlistWrap = styled.div`
  margin: 0 auto;
`

export const InviteClientButton = styled.a`
  border-radius: 32px;
  background: var(--Color-Primary-Primary-500, #5a5ff2);
  display: flex;
  height: 48px;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 32px;
  color: white;
  width: 100%;
  align-self: stretch;
  &:hover {
    opacity: 0.8;
    color: white;
  }
`;

export const NewContentContainer = styled.div`
  text-align: center;
  padding: 16px;

  .image-container {
    margin-bottom: 16px;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 8px;
  }

  .description {
    font-size: 14px;
    color: #7d7da9;
    margin-bottom: 16px;
  }

  .highlight {
    font-weight: bold;
    color: #a3a3c2;
    margin-top: 16px;
    font-weight: 500;
    margin-bottom:30px;
  }

  .questions-link {
    font-size: 14px;
    font-family: Inter;
    color: #5a5ff2;
    margin-top: 16px;
  }
`;