import React, { useState, useEffect, useRef } from "react";
import { Dropdown, Input, Menu, Modal, message, Tooltip } from "antd";
import ConsumerAppContainer from "container/ConsumerApp/index";
import styled from "styled-components";
import { ATC, MONTSERRAT, INTER } from "@fonts";
import {
  DownOutlined,
  SearchOutlined,
  UpOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import CRMV2Container from "container/CRM";

const AgentDropdownWrap = styled.a`
  border-radius: 4px;
  border: 1px solid #e5e6ff;
  display: flex;
  justify-content: space-between;
  height: 28px;
  padding: 6px 6px 6px 8px;
  align-items: center;
  color: #292a37;
  font-weight: 600;
  text-transform: uppercase;
  font-family: ${MONTSERRAT};
  font-size: 11px;
  width: 300px;
  .agent-name {
    display: inline-block;
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  svg {
    font-size: 14px;
  }
  span {
    font-size: 14px;
  }
  &:hover {
    color: #292a37;
  }
`;

const DropdownMenu = styled.div`
  border-radius: 12px;
  border: 1px solid var(--Color-Gray-Gray-100, #e0e0eb);
  background: var(--Color-White-White, #fdfdfd);
  padding: 8px;
  width: 352px;
  /* shadow */
  box-shadow: 0px 29px 8px 0px rgba(0, 0, 0, 0),
    0px 19px 7px 0px rgba(0, 0, 0, 0.01), 0px 11px 6px 0px rgba(0, 0, 0, 0.05),
    0px 5px 5px 0px rgba(0, 0, 0, 0.09), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  .ant-dropdown-menu-item {
    border-bottom: 1px solid #e0e0eb;
  }
  .ant-dropdown-menu {
    box-shadow: none;
    background-color: none;
  }
`;

const CustomModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ConfirmButton = styled.button`
  border-radius: 32px;
  background: #5a5ff2;
  width: 112px;
  color: #fff;
  display: flex;
  height: 40px;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  margin-right: 10px;
  border: none;
`;

const CancelButton = styled.button`
  border-radius: 32px;
  border: 1px solid #e0e0eb;
  background: #fff;
  display: flex;
  height: 40px;
  width: 112px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`;

const NoImageWrap = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #20212d;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #fff;
  font-size: 14px;
`;

const AgentImage = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
`;
const ModalConfirmation = styled(Modal)`
  .ant-modal-footer {
    border-radius: 0 0 16px 16px !important;
  }
`;
const Flex = styled.div`
  display: flex;
`;

const AgentInfoWrapper = styled.div`
  margin-left: 10px;
  .agent-name-drop {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
    max-width: 140px;
  }
  p {
    color: var(--Color-Gray-Gray-900, #232338);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    letter-spacing: 0.5px;
    margin: 0;
    display: flex;
  }
  span {
    color: var(--Color-Gray-Gray-400, #7d7da9);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    letter-spacing: 0.5px;
    word-break: break-word;
  }
`;

const AgentType = styled.span`
  border-radius: 8px;
  background: var(--Color-Secondary-Secondary, #9094f6);
  display: flex;
  padding: 2px 8px;
  align-items: center;
  gap: 8px;
  color: #fff !important;
  margin-left: 10px;
`;

const ModalBodyContent = styled.div`
  p {
    color: #232338;
    font-family: ${INTER};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%;
    letter-spacing: 0.5px;
  }
  span {
    color: #373758;
    font-family: ${INTER};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    letter-spacing: 0.5px;
  }
`;

const DropdownSearch = styled.input`
  outline: none;
  width: 100%;
  background: var(--Global-Mapping-Gray-800, #efeff4);
  border: none;
  margin-top: -3px;
  margin-left: 10px;
`;

const InputWrapper = styled.div`
  display: flex;
  padding: 12px;
  font-size: 14px;
  height: 40px;
  border-radius: 8px;
  background: var(--Global-Mapping-Gray-800, #efeff4);
`;

const AgentAssignment = ({
  fetchOptions,
  onConfirm,
  transferAgentForAssignment,
  getAllAgentsForAssignment,
  getAllAgentsForAssignmentResponse,
  transferAgentsResponse,
  clientTransactionData,
  getClientTransactions,
  currentFilters,
}) => {
  const [visible, setVisible] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentAgent, setCurrentAgent] = useState(null);
  const debounceTimeout = useRef(null);
  const pendingTransfer = useRef(false);

  useEffect(() => {
    const payload = {
      citiesSearch: "",
      agentSearch: "",
    };
    getAllAgentsForAssignment(payload);
  }, []);

  useEffect(() => {
    if (transferAgentsResponse?.data) {
      pendingTransfer.current = true;
      setCurrentAgent(selectedOption);
      setSearchTerm("");
      setModalVisible(false);

      getClientTransactions({
        currentFilters,
      });
    }
  }, [transferAgentsResponse]);

  useEffect(() => {
    if (
      getAllAgentsForAssignmentResponse?.data?.data &&
      clientTransactionData?.agent_id
    ) {
      const agentFromTransaction = getAllAgentsForAssignmentResponse.data.data.find(
        (agent) => agent.agent_id === clientTransactionData.agent_id
      );

      if (agentFromTransaction && !pendingTransfer.current) {
        setCurrentAgent(agentFromTransaction);
      }

      pendingTransfer.current = false;
    }
  }, [getAllAgentsForAssignmentResponse, clientTransactionData.agent_id]);

  useEffect(() => {
    return () => {
      pendingTransfer.current = false;
    };
  }, []);

  useEffect(() => {
    if (getAllAgentsForAssignmentResponse?.data) {
      setOptions(getAllAgentsForAssignmentResponse.data.data);
    }
  }, [getAllAgentsForAssignmentResponse]);

  const handleSearch = async (value) => {
    setSearchTerm(value);
    setLoading(true);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(async () => {
      const payload = {
        citiesSearch: "",
        agentSearch: value,
      };

      try {
        await getAllAgentsForAssignment(payload);
      } catch (error) {
        message.error("Failed to fetch options.");
      } finally {
        setLoading(false);
      }
    }, 500);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setModalVisible(true);
    setVisible(false);
  };

  const confirmAction = async () => {
    const payload = {
      clientId: clientTransactionData.client_id,
      newAgentId: selectedOption.agent_id,
      approve: 1,
    };
    transferAgentForAssignment(payload);
  };

  const menu = (
    <DropdownMenu>
      <InputWrapper>
        <SearchOutlined />
        <DropdownSearch
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
          allowClear
        />
      </InputWrapper>

      <Menu>
        {getAllAgentsForAssignmentResponse?.isFetching ? (
          <Menu.Item disabled>Loading...</Menu.Item>
        ) : getAllAgentsForAssignmentResponse?.data?.data?.length === 0 ? (
          <Menu.Item disabled>
            Your team is empty
            <br />
            Add agents to your team to transfer client
          </Menu.Item>
        ) : (
          options.map((option) => (
            <Menu.Item
              key={option.id}
              onClick={() => handleOptionClick(option)}
            >
              <Flex style={{ justifyContent: "space-between" }}>
                <Flex>
                  {option.user_image ? (
                    <AgentImage src={option.user_image} alt="agent image" />
                  ) : (
                    <NoImageWrap>
                      {option.full_name
                        .split(" ")
                        .map((word) => word[0]?.toUpperCase())
                        .join("")}
                    </NoImageWrap>
                  )}
                  <AgentInfoWrapper>
                    <p>
                      <Tooltip
                        title={option?.full_name || "-"}
                        overlayStyle={{
                          borderRadius: "100px",
                          color: "#303030",
                        }}
                      >
                        <span className="agent-name-drop">
                          {option?.full_name || "-"}
                        </span>
                      </Tooltip>
                      {option.agent_type === "Team Lead" && (
                        <AgentType>{option?.agent_type || "-"}</AgentType>
                      )}
                    </p>
                    <span>{option?.email || "-"}</span>
                  </AgentInfoWrapper>
                </Flex>

                {currentAgent?.agent_id === option?.agent_id && (
                  <CheckOutlined
                    style={{
                      marginTop: "15px",
                      width: "20px",
                      height: "20px",
                      color: "#5A5FF2",
                    }}
                  />
                )}
              </Flex>
            </Menu.Item>
          ))
        )}
      </Menu>
    </DropdownMenu>
  );

  return (
    <>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        onVisibleChange={setVisible}
        visible={visible}
      >
        <AgentDropdownWrap onClick={(e) => e.preventDefault()}>
          <span className="agent-name">
            Agent: {currentAgent ? currentAgent.full_name : "-"}
          </span>
          {visible ? <UpOutlined /> : <DownOutlined />}
        </AgentDropdownWrap>
      </Dropdown>

      <ModalConfirmation
        title=""
        visible={modalVisible}
        width={300}
        className="modal-confirmation"
        onCancel={() => setModalVisible(false)}
        footer={
          <CustomModalFooter>
            <ConfirmButton
              onClick={confirmAction}
              style={{
                opacity: transferAgentsResponse?.isFetching ? 0.8 : 1,
                pointerEvents: transferAgentsResponse?.isFetching
                  ? "none"
                  : "auto",
              }}
            >
              {transferAgentsResponse?.isFetching ? "Processing.." : "Yes"}
            </ConfirmButton>

            <CancelButton onClick={() => setModalVisible(false)}>
              Cancel
            </CancelButton>
          </CustomModalFooter>
        }
      >
        <ModalBodyContent>
          <p>Confirm transfer</p>
          <span>
            Are you sure you want to transfer this client to the selected agent?
          </span>
        </ModalBodyContent>
      </ModalConfirmation>
    </>
  );
};

export default CRMV2Container(ConsumerAppContainer(AgentAssignment));
