import React, { useEffect, useState } from "react"
import styled from "styled-components"
import App from "container/App"
import { LeftOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import CRMV2Container from "container/CRM"
import TechAssetsContainer from "container/TechAssets"
import ReferralContainer from "container/Referral"
import { withRouter } from "react-router-dom"
import moment from "moment"
import icons from "@icons"
import Box from "@ui/Box"
import DropdownMenu from "@ui/NewTooltip"
import moreMenuSVG from "images/icons/more_menu.svg"
import binSymbol from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/Delete.svg"
import { formatAmount, commaSeperatedNumberUS } from "services/formUtils"
import { USCurrencyFormat, moneyFormatter } from "shared/currencyUtils"
import ArchiveIcon from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/archiveCRM.svg"
import colors from "@colors"
import { ATC, MONTSERRAT, INTER } from "@fonts"

import {
  NewSelect,
  Tabs,
  NewButton,
  Modal,
  Spin,
} from "@ui/antd"
import Cookies from "react-cookies";

import {
  Li,
} from "@ui/DragDropColumn/common"
import LeftArrow from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/LeftArrow"
import Loader from "@ui/Loader"
import {
  HeaderWrap, AgentInfoWrap, AgentInfo,
  AgentWrap, ListSubtextHeader,
  PriceWrap, TabsWrap, LeadInfo, StatusButton,
  StatusWrap, SelectWrap, IN_CONTRACT, CLOSED, CONTRACT, ARCHIVE,
  LISTING, SELLER, BUYER, SENDING_RECEIVING_OFFERS, SHOWING_TOURS, OFFER,
  CLOSED_STATUS,
} from "../common"
import {
  UpdatePropertyRequirementForm,
  NotesTab,
  Financing,
  Reminders,
  Timeline,
  Offers,
  Uploads,
} from "./Tabs"
import LeadDetails from "./Tabs/LeadDetails"
import {
  HeaderContainer,
  CloseWrap,
  StyledMenuIcon,
  AgentName,
  Subtext,
  Separator,
  PriceContainer,
  TabContentContainer,
  StatusColors,
  StatusIndicator,
  StatusSelectContainer,
} from "./styles"
import { formatAdressWithoutStateAndCountry } from "../utils"

const { TabPane } = Tabs

const Wrap = styled.div`
  height: 100%;
  background: ${colors.new_lightBackground};
`

const ListingButton = styled.div`
  margin: 20px;
  // width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

const RightWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-end;
`

const Container = styled.div`
  display: flex;
  align-items: center;
`

const PrimaryTag = styled.div`
  display: flex;
  align-items: center;
`

const Tag = styled.p`
  background: #FFFFFF;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  padding: 6px;
  line-height: 8px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5A5FF2;
  box-shadow: 0px 1px 3px rgb(180, 180, 180);
  border-radius: 4px;
`

const CloseContainer = styled.div`
  position: relative;
`

const PropertyRequirementDetailsInfo = ({
  handleClose,
  getBrokerageTransactionStatusResponse,
  setModalOpenConfirm,
  setModalOpenView,
  history,
  updateClientTransaction,
  currentTab,
  getCrmClientResponse,
  updatePropertyDetailsResponse,
  currentFilters,
  getCtIdResponse,
  setCurrentDrawerPage,
  toggleGoToContract,
  toggleArchiveLeadModal,
  deleteClientTransaction,
  setIsViewListing,
  setIsViewContract,
  setStatusValue,
  setIsViewOffer,
  setIsViewOfferToContract,
  setIsClosed,
  setIsAddListing,
  isAddListing,
  setIsViewListingToContract,
  setIsEndContract,
  setIsAddOffer,
  setClientID,
  toggleShowInContract,
  toggleUserOverview,
  setIsCreateWarning,
  user,
}) => {
  const {
    data,
    isFetching: fetchingData,
  } = getCtIdResponse || {}
  const {
    property,
    type,
    client_id,
    id,
    agent_id,
    status,
    prop_id,
    coClients,
    radius_transaction_type,
    searchCriteria,
  } = data || {}


  

  const [currentStatusForIndicator, setCurrentStatusForIndicator] = useState(status)

  const {
    isFetching,
    data: brokerageTransactionStatus,
  } = getBrokerageTransactionStatusResponse || {}
  const {
    transactionType,
    initiateTransaction,
    status: transactionStatus,
    id: transId,
  } = brokerageTransactionStatus || {}
  const {
    price, no_of_bed_room, no_of_bath_room, area_coverage,
    property_type, updated_at, covered_area, address, sale_price,
    rent_resale,city,state
  } = property || {}

  const isBrokerPlan = Cookies.load("isSubscribedToBrokerPlan")
  const isSubscribedToBrokerPlan = isBrokerPlan =="false" ? false : true
  const tcInformation = Cookies.load("tcInformation")
  const EOIsubscriptionStatus = Cookies.load("EOIstatusForSubscription")
  const subscriptionStatus = Cookies.load("subscriptionStatus")
  const negativeStatuses = ["incomplete", "past_due", "paused", "incomplete_expired", "overdue", "canceled", "unpaid"];
const isEOIOrSubsStatusNegative = 
    negativeStatuses.includes(EOIsubscriptionStatus) || 
    negativeStatuses.includes(subscriptionStatus);

  const getColor = currentStatus => StatusColors.find(cl => cl.label === currentStatus)?.value

  const handleChange = (value) => {
    if (value === ARCHIVE) {
      toggleArchiveLeadModal(true)
    } else {
      const payload = {
        status: value,
        client_id,
        type,
      }
      updateClientTransaction({
        transactionId: id,
        payload,
        currentFilters,
      })
    }
  }

  const confirmDeleteLead = () => {
    Modal.confirm({
      title: "Deleting Lead!",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete ${address}, ${city}, ${state}, USA?`,
      okText: "Yes",
      cancelText: "Not Now",
      onOk() {
        history.push("/crm")
        deleteClientTransaction({
          currentFilters,
          transactionId: id,
          soft_delete: 1,
        })
        toggleUserOverview({ isUserOverviewDrawerOpen: false })
        setClientID({
          id: "",
          deal_id: "",
        })
      },
    })
  }

  const {
    isFetching: fetchingUpdatedValues,
    data: clientData,
  } = getCrmClientResponse || {}

  const { name } = clientData || {}

  const {
    data: updatedData,
    isFetching: isUpdating,
  } = updatePropertyDetailsResponse || {}

  const {
    address: updatedAddress,
    price: updatedPrice,
    no_of_bed_room: updatedBed,
    no_of_bath_room: updatedBath,
    area_coverage: updatedUnit,
    property_type: updatedType,
    updated_at: updateTime,
    covered_area: updatedArea,
    sale_price: updatedSale,
  } = updatedData || {}

  const currentLocation = updatedAddress || address
  const currentPrice = updatedPrice || price
  const currentBedRoom = updatedBed || no_of_bed_room
  const currentBathRoom = updatedBath || no_of_bath_room
  const currentCoverage = updatedUnit || area_coverage
  const currentType = updatedType || property_type
  const currentUpdateTime = updateTime || updated_at
  const currentArea = updatedArea || covered_area
  const currentSale = updatedSale || sale_price

  if (!fetchingData && prop_id === null) {
    setCurrentDrawerPage("drawer-header")
  }

  return (
    <>
      {fetchingData ? <Loader /> : (
        <Wrap>
          <HeaderContainer>
            <CloseContainer>
              <CloseWrap
                onClick={() => handleClose()}
              >
                <LeftArrow color={colors.new_light} />
                <p>
                  Back to Profile
                </p>
              </CloseWrap>
              {((status !== IN_CONTRACT || status !== CLOSED) && transactionType !== "Contract") && (
                <Box position="absolute" t="5" r="15">
                  <DropdownMenu
                    position="bottom"
                    component={<StyledMenuIcon src={moreMenuSVG} />}
                    top={20}
                    padding={10}
                    left={-190}
                    width={200}
                    borderColor="#FFF"
                    eventType="hover"
                    borderRadius={4}
                    showShadow
                    arrowPosition="right"
                  >
                    <React.Fragment>
                      {/* <Li
                        onClick={(e) => {
                          e.stopPropagation()
                          toggleArchiveLeadModal(true)
                          setClientID({
                            id: client_id,
                            deal_id: id,
                          })
                        }}
                      >
                        <span>Archive</span>
                        <img src={ArchiveIcon} alt="Refer out icon" style={{ width: "20px", height: "20px" }} />
                      </Li> */}
                      <Li
                        onClick={(e) => {
                          e.stopPropagation()
                          confirmDeleteLead()
                        }}
                      >
                        <span>Delete</span>
                        <img src={binSymbol} alt="bin" />
                      </Li>
                    </React.Fragment>
                  </DropdownMenu>
                </Box>
              )}
            </CloseContainer>
            {fetchingUpdatedValues || fetchingData ? (
              <p>Loading</p>
            ) : (
              <AgentInfoWrap style={{ alignItems: "baseline" }}>
                <AgentInfo>
                  <AgentWrap>
                    <Container>
                      <AgentName
                        width="400px"
                        height="max-content"
                        fontSize="28px"
                      >
                        {name}
                      </AgentName>
                      <PrimaryTag>
                        <Tag>
                          {rent_resale === "rent" ? "RENTER" : "SELLER"}
                        </Tag>
                      </PrimaryTag>
                    </Container>
                    <AgentName
                     fontSize="18px" fontFamily={INTER}
                    >
                      {`${address}, ${state}, USA`}
                    </AgentName>
                    {/* <AddressSubHeader>
                      {address}
                    </AddressSubHeader> */}
                    <div>
                      <Subtext>
                        {currentType === "Other" ? " " : currentType}
                      </Subtext>
                      {currentBedRoom > 0 && (
                        <>
                          <Separator> | </Separator>
                          <Subtext>
                            {currentBedRoom}
                            {" "}
                            Beds
                          </Subtext>
                        </>
                      )}
                      {currentBathRoom > 0 && (
                        <>
                          <Separator> | </Separator>
                          <Subtext>
                            {currentBathRoom}
                            {" "}
                            Baths
                          </Subtext>
                        </>
                      )}
                      {currentArea > 0 && currentCoverage && (
                        <>
                          <Separator> | </Separator>
                          <Subtext>
                            {`${commaSeperatedNumberUS(covered_area)} ${area_coverage}`}
                          </Subtext>
                        </>
                      )}
                    </div>
                  </AgentWrap>
                </AgentInfo>
                <RightWrap>
                  <PriceContainer>
                    {currentPrice || currentSale ? (
                      `$${moneyFormatter(currentSale || currentPrice, 3)}`
                    ) : ""}
                  </PriceContainer>
                  <StatusWrap>
                    <StatusSelectContainer isLight>
                      <StatusIndicator bg={getColor(currentStatusForIndicator || status)} />
                      <NewSelect
                      // value={listingData.displayDealStatus}
                        defaultValue={status}
                        onChange={(value) => {
                          setStatusValue(value)
                          setCurrentStatusForIndicator(value)
  
                          if (value === ARCHIVE) {
                            handleChange(value)
                          } else if (initiateTransaction) {
                            if (SELLER.includes(type)) {
                              if (value === CLOSED) {
                                setStatusValue(IN_CONTRACT)
                                setIsClosed(true)
                                return
                              }
                              if (value === IN_CONTRACT) {
                                setIsEndContract(true)
                                toggleGoToContract({ isShowGoToContract: true })
                                return
                              }
                              setIsAddListing(true)
                              setIsCreateWarning(true)
                              handleChange(value)
                            } else {
                              if (value === IN_CONTRACT) {
                                setIsEndContract(true)
                                toggleGoToContract({ isShowGoToContract: true })
                                return
                              }
                              setIsAddOffer(true)
                              handleChange(value)
                            }
                          } else if (!initiateTransaction && transactionType === CONTRACT && ((value !== IN_CONTRACT && value !== CLOSED) || transactionStatus === CLOSED_STATUS)) {
                            if (SELLER.includes(type)) {
                              setIsViewListingToContract(true)
                            } else {
                              setIsViewOfferToContract(true)
                            }
                          } else if (!initiateTransaction && value === IN_CONTRACT) {
                            setIsEndContract(true)
                            toggleGoToContract({ isShowGoToContract: true })
                          } else if (!initiateTransaction && transactionType !== CONTRACT && value === CLOSED) {
                            setStatusValue(IN_CONTRACT)
                            setIsClosed(true)
                          } else if (transactionType === CONTRACT && value === CLOSED) {
                            toggleShowInContract(true)
                            handleChange(value)
                          } else {
                            handleChange(value)
                          }
                        }}
                        // onChange={value => handleChange(value)}
                        // onChange={this.handleChange}
                        bordered={false}
                        // disabled={listingData.displayDealStatus === "Incomplete"}
                        options={[
                          {
                            value: "New Client",
                            label: "New Client",
                          },
                          {
                            value: "Met with Client",
                            label: "Met with Client",
                          },
                          {
                            value: "Pre-approved/Listing Prepped",
                            label: "Pre-approved/Listing Prepped",
                          },
                          {
                            value: "Showings/Tours",
                            label: "Showings/Tours",
                          },
                          {
                            value: "Sending/Receiving Offers",
                            label: "Sending/Receiving Offers",
                          },
                          {
                            value: "In Contract",
                            label: "In Contract",
                          },
                          {
                            value: "Closed",
                            label: "Closed",
                          },
                          {
                            value: "Archive",
                            label: "Archive",
                          },
                        ]}
                        placement="bottomRight"
                        dropdownMatchSelectWidth={false}
                      />
                    </StatusSelectContainer>
                    <Subtext fontSize="14px" fontFamily={INTER} margin="8px 0 0 0" textAlign="right">
                      Last Updated At:
                      {" "}
                      {moment(currentUpdateTime * 1000).format("MM/DD/YYYY")}
                      <br />
                      {(radius_transaction_type === null && status === CLOSED) ? "Pending compliance approval" : ""}
                    </Subtext>
                  </StatusWrap>
                </RightWrap>
              </AgentInfoWrap>
            )}
          </HeaderContainer>
          <TabsWrap>
            <LeadInfo defaultActiveKey={currentTab}>
              <TabPane tab="OVERVIEW" key="OVERVIEW" style = {{padding : "24px" }}>
                {isFetching || isUpdating === true ? (
                  <Spin />
                ) : (
                  <>
                    <>
                      {
                        ((isSubscribedToBrokerPlan || tcInformation?.is_tc == 1) && !isEOIOrSubsStatusNegative) &&  
                  
                      (SELLER.includes(type)) && (isSubscribedToBrokerPlan || (tcInformation?.is_tc === 1 || !isEOIOrSubsStatusNegative) ) && currentFilters && currentFilters.view_type === "view_mine" && (
                      <ListingButton>
                        {(initiateTransaction && (isSubscribedToBrokerPlan || (tcInformation?.is_tc === 1 || !isEOIOrSubsStatusNegative))) ? (
                              <NewButton
                              type="primary"
                              // onClick={createTransaction}
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setIsAddListing(true)
                                setIsCreateWarning(true)
                                setModalOpenConfirm(true)
                                setClientID({
                                  deal_id: id,
                                })
                              }}
                            >
                                Add listing
                            </NewButton>
                          ) : (
                            <>
                              {transId && transactionType === LISTING && ((isSubscribedToBrokerPlan || tcInformation?.is_tc == 1) && !isEOIOrSubsStatusNegative) && (
                                <>
                                  <NewButton
                                    type="primary"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      setIsViewListing(true)
                                      setModalOpenView(true)
                                      setClientID({
                                        deal_id: id,
                                      })
                                    }}
                                  >
                                    View listing
                                  </NewButton>
                                </>
                              )}
                            </>
                          )}
                        </ListingButton>
                          )
                          }
                    </>
                    <>
                      {(BUYER.includes(type)) && currentFilters && currentFilters.view_type === "view_mine" && ((isSubscribedToBrokerPlan || tcInformation?.is_tc == 1) && !isEOIOrSubsStatusNegative)
                        && ((status === SHOWING_TOURS || status === SENDING_RECEIVING_OFFERS)) ? (
                          <ListingButton>
                            {initiateTransaction ? (
                              <NewButton
                                type="primary"
                                // onClick={createTransaction}
                                onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  setIsAddOffer(true)
                                  setIsCreateWarning(true)
                                  setModalOpenConfirm(true)
                                  setClientID({
                                    deal_id: id,
                                  })
                                }}
                              >
                                Add Offer
                              </NewButton>
                            ) : (
                              <>
                                {transId && transactionType === OFFER && ((isSubscribedToBrokerPlan || tcInformation?.is_tc == 1) && !isEOIOrSubsStatusNegative) && (
                                  <NewButton
                                    type="primary"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      setIsViewOffer(true)
                                      setModalOpenView(true)
                                      setClientID({
                                        deal_id: id,
                                      })
                                    }}
                                  >
                                    View Offer
                                  </NewButton>
                                )}
                              </>
                            )}
                          </ListingButton>
                        ) : null}
                    </>
                    <>
                      {(currentFilters && currentFilters.view_type === "view_mine") && (
                        <ListingButton>
                          {transId && transactionType === CONTRACT && ((isSubscribedToBrokerPlan || tcInformation?.is_tc == 1) && !isEOIOrSubsStatusNegative) && (
                            <NewButton
                              type="primary"
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setIsViewContract(true)
                                setModalOpenView(true)
                                setClientID({
                                  deal_id: id,
                                })
                              }}
                            >
                              View Contract
                            </NewButton>
                          )}
                        </ListingButton>
                      )}
                    </>
                  </>
                )}
                {/* <BuyerLeadOverview
                  is_working_with_agent={is_working_with_agent}
                  is_pre_approved={is_pre_approved}
                  transactionId={id}
                  client_id={client_id}
                  type={type}
                  timeline={timeline}
                /> */}
                <TabContentContainer>
                  <LeadDetails
                    isSeller
                    coClients={coClients}
                  />

                  {fetchingData ? <Loader /> : (
                    <UpdatePropertyRequirementForm
                      initialValues={property}
                      transactionId={id}
                      agent_id={agent_id}
                      clientId={client_id}
                    />
                  )}
                </TabContentContainer>
              </TabPane>
              <TabPane tab="NOTES" key="NOTES" style = {{padding : "24px" }}>
                <NotesTab />
              </TabPane>
              {/* <TabPane tab="FINANCING" key="FINANCING">
                <Financing />
              </TabPane> */}
              <TabPane tab="REMINDERS" key="REMINDERS" style = {{padding : "24px" }}>
                <Reminders />
              </TabPane>
              {/* <TabPane tab="TIMELINE" key="TIMELINE">
                <Timeline />
              </TabPane> */}
              <TabPane tab="UPLOADS" key="UPLOADS" style = {{padding : "24px" }}>
                <Uploads />
              </TabPane>
            </LeadInfo>
          </TabsWrap>
        </Wrap>
      )}
    </>
  )
}

export default withRouter(ReferralContainer(TechAssetsContainer(CRMV2Container(App(PropertyRequirementDetailsInfo)))))
