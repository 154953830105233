import React from "react"
import styled, { css } from "styled-components"
import removeIcon from "images/icons/cancel.png"
import Button from "@ui/Button"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import { formatNextStep } from "ssr/Profile/HelperComponents"

// ONLY WRITE STYLES WHICH ARE COMMON FOR ALL MODALS
const PADDING = "20px"

export const Wrapper = styled.div`
  border: 1px solid  #e7ebed;
  border-radius: 4px;
`
export const SearchInputWrap = styled.form`
  flex: 1;
  display: flex;
  margin-right: 10px;
  border-bottom: solid 2px #e7ebed;
  > img {
    background: #ffffff;
    padding: 0px 2px 0px 15px;
    margin-right: 15px;
    width: 30px;
    height: auto;
  }
  > input {
    flex: 1;
    padding: 10px;
    display: block;
    border: none;
    outline: none;
    font-size: 14px;

    &::placeholder {
      color: #dadada;
      font-size: 14px;
    }
  }
`

export const HelpingText = styled.p`
  font-size: 11px;
  font-style: italic;
  color: #a5a8ad;
  font-weight: 300;
  margin-bottom: 16px;
  padding: 0px 20px;
`

export const TagsWrap = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: ${PADDING};
`

export const EditButton = styled.button`
  background: transparent;
  position: absolute;
  font-size: 14px;
  top: 10px;
  right: 10px;
  padding: 0px;
  color: #5A5FF2;
  border: none;
  cursor: pointer;
`

const TagWrap = styled.li`
  display: inline-block;
  padding: 10px;
  background: #e9f7fd;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
  ${props => props.addable && css`
    background: #FFF;
    border: #868b90 1px solid;
  `}

  ${props => props.showMoreBtn && css`
    border: none;
    color: #5A5FF2;
  `}
`

export const StyledButton = styled(Button)`
  width: ${props => (props.width ? `${props.width}px` : "auto")};
  font-weight: 400;
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
`
export const Footer = styled.div`
  display: flex;
  padding: 10px ${PADDING};
  justify-content: flex-end;
  border-top: 1px solid #e7ebed;
`

export const Header = styled.div`
  border-bottom: 1px solid #e7ebed;
  padding: 15px 20px;
  text-transform: uppercase;
  h4 {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    padding: 0px;
    margin: 0px;
  }
`

const Img = styled.img`
  max-width: 10px;
  margin-left: 10px;
  cursor: pointer;
  
  ${props => props.addable && css`
    transform: rotate(45deg);
  `}
`

export const Tag = ({
  title,
  handleClick,
  addable,
  showMoreBtn,
}) => (
  <TagWrap
    addable={addable}
    showMoreBtn={showMoreBtn}
    onClick={() => { if (showMoreBtn) { handleClick(title) } }}
  >
    <span>{title}</span>
    {!showMoreBtn && (
      <Img
        src={removeIcon}
        alt="remove icon"
        addable={addable}
        onClick={() => { handleClick(title) }}
      />
    )}
  </TagWrap>
)

export const BlankButton = styled.button.attrs({
  type: "button",
})`
  border: none;
  background: transparent;
  color: #5A5FF2;

  ${props => props.normal && css`
    font-size: 16px;
  `}
`

export const InputFile = styled.label`
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none;
  cursor: pointer;
  width: auto;
  line-height: normal;
  padding: 8px 12px;
  font-size: 16px;
  color: #5A5FF2;
  border: 1px solid #5A5FF2;
  border-radius: 25px;
  background-color: #ffffff;
  box-shadow: none;
  margin-right: 20px;
  input[type="file"] {
    display: none;
  }
`

export const DeleteButton = styled.button`
  border: none;
  background: transparent;
  color: #f96d72;
  font-size: 16px;
`

export const Separator = styled.div`
  flex: 1;
`

export const SaveButton = ({ children, isShowLoader, ...props }) => (
  <Button {...props}>
    { isShowLoader
      ? <ThreeDotLoader color="#FFF" />
      : children
    }
  </Button>
)

const NoImageFoundWrapper = styled.div`
  padding: 20px;
  min-height: 400px;
  background: #D8D8D8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  > i {
    font-size: 60px;
    color: #FFF;
  }
`
export const NoImageFound = () => (
  <NoImageFoundWrapper>
    <i className="icon-generic-Camera"></i>
  </NoImageFoundWrapper>
)

export const H5 = styled.h5`
  color: #354052;
  font-size: 16px;

  ${props => props.isLite && css`
    font-size: 14px;
  `}

  ${props => props.label && css`
    color: #d4d4d4;
    font-size: 16px;
    font-weight: 300;
  `}
`

export const ErrorText = styled.p`
  font-size: 12px;
  color: #EB5A46;
`
// -------- Profile Completion butoon

const ProfileCompletionButtonWrap = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;

  .next-step-text {
    margin-right: 20px;
    text-transform: capitalize;
    > span {
      &:nth-child(1) {
        font-size: 14px;
        margin-right: 10px;
      }
      &:nth-child(2) {
        font-size: 12px;
        color: #999;
      }
    }
  }
`

export const ProfileCompletionButton = ({ handleClick, nextToNextStep = "", isShowLoader }) => (
  <ProfileCompletionButtonWrap>
    {isShowLoader ? (
      <ThreeDotLoader />
    ) : (
      <div style={{ alignSelf: "flex-end" }}>
        {nextToNextStep && false && (
          <span className="next-step-text">
            <span>Next Step:</span>
            <span>{formatNextStep(nextToNextStep)}</span>
          </span>
        )}
        <Button onClick={handleClick} type="button" normal>
          { !nextToNextStep ? "Done" : "Proceed"}
        </Button>
      </div>
    )}
  </ProfileCompletionButtonWrap>
)
