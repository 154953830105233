import React, { useEffect, useState, useCallback } from "react";
import TeamProfileContainer from "container/Realty/TeamProfileContainer";
import CRMV2Container from "container/CRM";
import CookiesStorage from "services/CookieStorage";
import { debounce } from "lodash";
import { Input, Select, Button, Dropdown, Space, Checkbox } from "antd";
import { DownOutlined, UpOutlined, SearchOutlined } from "@ant-design/icons";
import { StyledInput, StyledButton, StyledSelect } from "./styles";
import TeamFilterComponent from "dumbComponents/CRMV2/CRMDashboard/Components/TeamFilterComponent/index.js";
import AppContainer from "container/App";

const { Option } = Select;
const tcInformation = CookiesStorage.load("tcInformation")
const is_tc=tcInformation.is_tc;
const FilterComponent = ({
  getClientFiltersResponse,
  getTeamDetailsResponse,
  currentFilters,
  updateFilterQueries,
  getClientTransactions,
  user,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [teamValue, setTeamValue] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [isFirstLaunch, setIsFirstLaunch] = useState(true);
  const tcInformation = CookiesStorage.load("tcInformation")
  const isFilterActive = () => currentFilters
    && (currentFilters.statuses.length > 0
    || currentFilters.types.length > 0
    || currentFilters.sortBy
    || currentFilters.isFavourites
    || currentFilters.team_member_id
    || currentFilters.text
    )

    const { data: teamDetails } = getTeamDetailsResponse || {};
    const teamLeadId = teamDetails?.team_members?.length>0 && teamDetails?.team_members?.filter((member) => member.member_type === "Team Lead").map((lead) => lead.agent_id)
  const getFiltersOptions = () => {
    const { data: teamDetails } = getTeamDetailsResponse || {};
    const user = CookiesStorage.load("user") || {};
    
    const teamNames = [];

    teamNames.push({
      value: "view_mine",
      label: "View my clients",
    });

    teamNames.push({
      value: "view_all",
      label: "View all clients",
    });

    teamNames.push({
      value: "view_all_team_members",
      label: "View associate's clients",
    });

    if (teamDetails && teamDetails.team_members) {
      teamDetails.team_members
        .filter((td) => td.agent_id !== user.id)
        .forEach((tm) => {
          console.log(tm, "here");
          teamNames.push({
            key: `${tm.agent_id}::${tm.firstname}`,
            label: `${tm.firstname} ${tm.lastname} clients`,
            value: tm.agent_id,
          });
        });
    }
    return teamNames || [];
  };

  const teamMemberSelection = (e, key) => {
    const getLabel = (type) => {
      const isAgentId = type != "view_mine"
      && type !== "view_all"
      && type !== "view_all_team_members"
      let temp = type
      let agentId = null

      if(isAgentId) {
        type = "id"
        agentId = parseInt(temp, 10)
      }
      
      switch(type) {
        case "view_mine":
          return "My leads"
        case "view_all":
          return "All leads"
        case "view_all_team_members":
          return "All associate's leads"
        case "id": 
          const label = getFiltersOptions()
            .filter((item) => item.value === agentId)[0].label
          return label
        default:
          return "My leads"
      }
    }

    const selectedValue = e
    const updated = currentFilters
    updated[key] = selectedValue
    updated.view_type_label = getLabel(selectedValue)
    updated.view_type = selectedValue !== "view_all" 
    && selectedValue !== "view_mine" 
    && selectedValue !== "view_all_team_members" 
    ? "view_specific_team_members" : selectedValue

    updateFilterQueries({
      filters: updated,
    })
    setTeamValue(selectedValue)
  }

  const sortOnChange = (e, key) => {
    const selectedValue = e
    const updated = currentFilters
    updated[key] = selectedValue

    if(selectedValue === "view_all")
    updateFilterQueries({
      filters: updated,
    })
    setSortValue(selectedValue)
  }

  const getOptions = (options) =>
    options &&
    options.map((item) => ({
      value: item.value,
      label: item.display_name || item.value,
    }));
  const { data: filterSkeleton } = getClientFiltersResponse || {};

  const onChange = (checkedValues, key) => {
    const updated = {
      ...currentFilters,
      [key]: [checkedValues],
    }

    updateFilterQueries({
      filters: updated,
    })
  }

  const handleSearchClear = () => {
    const updated = {
      ...currentFilters,
      statuses: [],
    }

    updateFilterQueries({
      filters: updated,
    })
  }

  const handleClear = () => {
    if (is_tc) {
      applyAllClientsFilter(1); 
    }
    else {
      const cleared = {
        types: [],
        sortBy: null,
        statuses: [],
        isFavourite: null,
        team_member_id: null,
        view_type_label: "My leads",
        view_type: "view_mine",
      };
      setTeamValue("");
      setSortValue("");
      setSearchValue("");
      updateFilterQueries({
        filters: cleared,
      })
      getClientTransactions()
      toggleFilterDrawer(false)
    }
  }

  const applyAllClientsFilter = (value = 0) => {
    if (is_tc) {
      const { data: teamDetails } = getTeamDetailsResponse || {};
      const teamLeadId = teamDetails?.team_members
        .filter((member) => member.member_type === "Team Lead")
        .map((lead) => lead.agent_id)[0];

      const initialFilters = (value === 0) ? {
        ...currentFilters,
        view_type: "view_all",
        view_type_label: "All clients",
        team_member_id: "view_all",
        agentId: teamLeadId,
      } : {
        types: [],
        sortBy: null,
        statuses: [],
        isFavourite: null,
        team_member_id: "view_all",
        view_type_label: "All clients",
        view_type: "view_all",
        agentId: teamLeadId,
      }

      setTeamValue("");
      setSortValue("");
      setSearchValue("");
  
      updateFilterQueries({ filters: initialFilters });
      getClientTransactions({ currentFilters: initialFilters });
  
      if (isFirstLaunch) {
        setIsFirstLaunch(false);
      }
    }
  };
  

  const debouncedClientSearch = useCallback(
    debounce((value, key) => {
      const updated = {
        ...currentFilters,
        [key]: value,
      }
      updateFilterQueries({
        filters: updated,
      })
    }, 500),
    [currentFilters]
  );

  useEffect(() => {
    applyAllClientsFilter();
  }, [is_tc, getTeamDetailsResponse, isFirstLaunch]);

  const handleClientSearch = (e) => {
    setSearchValue(e.target.value);
    debouncedClientSearch(e.target.value, "text");
  };

  useEffect(() => {
    getClientTransactions({
      currentFilters,
    })
  }, [currentFilters, teamValue, sortValue]);

  return (
    <div>
      <div style={{ display: "flex", gap: "8px", marginBottom: "8px" }}>
        {/* Search by Clients */}
        <StyledInput prefix={<SearchOutlined style={{marginLeft: "15px", fontSize: "18px"}}/>} placeholder="Search by clients" allowClear onChange={(e) => handleClientSearch(e)} value={searchValue}/>

        {/* Dropdown: Team & personal clients */}
        {/* {((user?.brokerageTeamName && user?.isBrokerageTeamLead) || (tcInformation?.is_tc)) ? (
          <TeamFilterComponent
            firstValueOption={{
              key: "My leads",
              label: "My active clients",
              value: "view_mine",
            }}
            secondValueOption={{
              key: "Team leads",
              label: "Associate clients",
              value: "view_all_team_members",
            }}
            firstValueLabel="My active clients"
            secondValueLabel="Associate clients"
            defaultInitialName="Team & personal clients"
          />
        ) : (
          <></>
        )} */}

        {is_tc ? <TeamFilterComponent firstValueOption={{
          key: "All leads",
          label: "All clients",
          value: "view_all",
          teamLeadId: Number(teamLeadId)
        }}
        onFilterChange = {(selectedValue) => setSelectedFilter(selectedValue)}
        firstValueLabel="All clients"/>: 
        <></>
        }
    
        {/* Filters Toggle Button */}
        <StyledButton onClick={() => setShowFilters(!showFilters)} style={{width: "150px", color: "#373758"}}>
          Filters {showFilters ? <UpOutlined style={{marginLeft: "10px"}}/> : <DownOutlined style={{marginLeft: "10px"}}/>}
        </StyledButton>
      </div>
      {/* Filters Section */}
      {showFilters && (
        <div style={{ display: "flex", gap: "8px" }}>
          {/* Select Status */}
          <StyledSelect
            mode="multiple"
            showArrow
            placeholder="Select status"
            allowClear
            value={currentFilters ? currentFilters.statuses[0] : null}
            options={getOptions(filterSkeleton.clientTransactionFilters)}
            onChange={e => {
              if (e.length === 0) {
                handleSearchClear();
              }
              else {
                onChange(e, "statuses");
              }
            }}
            maxTagCount={1}
          ></StyledSelect>

          {/* Type */}
          <StyledSelect
            placeholder="Type"
            style={{ flex: 1 }}
            value={currentFilters.types}
            options={getOptions(filterSkeleton.leadTypes)}
            onChange={e => onChange(e, "types")}
          ></StyledSelect>

          {/* Sort By */}
          <StyledSelect
            placeholder="Sort by"
            style={{ flex: 1 }}
            options={filterSkeleton && filterSkeleton.clientTransactionSort}
            onChange={e => sortOnChange(e, "sortBy")}
            value={currentFilters.sortBy}
          ></StyledSelect>

          {/* Select Teams */}
          {user?.brokerageTeamName && (user?.isBrokerageTeamLead || user?.isBrokerageCoTeamLead) && (
            <StyledSelect
              placeholder="Select teams"
              style={{ flex: 1 }}
              options={getFiltersOptions()}
              value={currentFilters.team_member_id}
              onChange={e => teamMemberSelection(e, "team_member_id")}
            ></StyledSelect>
          )}

          {/* buttons */}
          {isFilterActive() && <StyledButton onClick={handleClear} style={{color: "#F05152", backgroundColor: "transparent", border: "none", fontWeight: "600"}}>Clear</StyledButton>}
        </div>
      )}
    </div>
  );
};

export default AppContainer(TeamProfileContainer(CRMV2Container(FilterComponent)));
