import React, { useState, useEffect } from "react";
import UploadBrbc from "./UploadBrbc";
import CRMV2Container from "container/CRM";
import { withRouter } from "react-router-dom";
import ReferralContainer from "container/Referral";
import TechAssetsContainer from "container/TechAssets";
import queryString from "query-string";
import BrbcContainer from "container/BRBC";
import ThreeDotLoader from "@ui/ThreeDotLoader";
import {
  StyledBrbcModal,
  ModalHeading,
  ModalSubHeading,
  FooterContainer,
  ModalHeadingContainer,
  ModalFillBtn,
  ModalLightBtn,
  BrbcFileContainer,
  ReasonHeading,
  ReasonSubheading,
  FormDiv,
  DontAskBtn,
} from "./styles";
import cookie from "services/CookieStorage";
import { Form } from "@ui/antd";
import { CountTracker } from "dumbComponents/ListingsV2/Components/CreateEnvelopeModal/components/commonStyles";
import { InputField } from "dumbComponents/common/InputFields";
import { InputArea } from "dumbComponents/ListingsV2/Components/InputFields";
import Listings from "container/Listings/index";

const BrbcModal = ({
  brbcModalType,
  setCurrentDrawerPage,
  toggleModal,
  onClose,
  uploadBrbcDocResponse,
  uploadBrbcDoc,
  fetchPresignedBrbcDocs,
  fetchBrbcCoversheet,
  fetchBrbcStatus,
  voidBrbcCoversheet,
  voidBrbcReponse,
  voidRestartBrbcReponse,
  voidDuplicateBrbcCoversheet,
  newBrbcCoversheetReponse,
  sendBrbcEnvelope,
  sendBrbcEnvelopeResponse,
  fetchBrbcStatusResponse,
  getCrmClientResponse,
  getAgentDetailsFromState,
  deleteBrbcEnvelope,
  deleteBrbcEnvelopeResponse,
  deleteBrbcDoc,
  deleteBrbcDocResponse,
  getCtIdResponse,
  getClientTransactionsById,
}) => {
  const { isFetching: fetchingUpdatedValues, data: clientData } =
    getCrmClientResponse || {};
  const buyerName = clientData?.name;
  const agentId = clientData?.agent_id;
  const clientId = clientData?.id;
  const [reasonForm] = Form.useForm();
  const [brbcSignForm] = Form.useForm();
  const parsedQuery = queryString.parse(location.search);
  const [modalState, setIsModalState] = useState(brbcModalType);
  const [fromVoidDuplicate, setFromVoidDuplicate] = useState(false);
  const [primaryBtnTitle, setPrimaryBtnTitle] = useState("Fill & Sign");
  const [secBtnTitle, setSecBtnTitle] = useState("Upload from files");
  const [modalTitle, setModalTitle] = useState("");
  const [reasonMessage, setReasonMessage] = useState("");
  const [thirdBtnTitle, setThirdBtnTitle] = useState("");
  const [brbcSubject, setbrbcSubject] = useState(
    "Please review and sign the Buyer Representation Agreement"
  );
  const [brbcTitle, setbrbcTitle] = useState("Buyer Representation Agreement");
  const [brbcNote, setbrbcNote] = useState(
    `Dear ${buyerName},\n\nAttached is your Buyer Representation Agreement for your review. Kindly take a moment to go through it and sign at your earliest convenience. Should you have any questions or require further clarification, please do not hesitate to reach out.`
  );
  const [uploadedFileInfo, setUploadedFileInfo] = useState(null);
  const [newReq, setNewReq] = useState(false);

  const { data: brbcUploadResponse, isFetching: isFetchingBrbcResponse } =
    uploadBrbcDocResponse || {};

  const { isFetching: fetchingCoClientData, data: tidData } =
    getCtIdResponse || {};

  const {
    data: brbcCoversheetDeleteData,
    isFetching: isFetchingDeleteBrbcCoversheet,
  } = deleteBrbcEnvelopeResponse || {};

  const { data: brbcDocDeleteData, isFetching: isFetchingDeleteBrbcDoc } =
    deleteBrbcDocResponse || {};

  const { data: brbcData, isFetching: isFetchingBrbcData } =
    fetchBrbcStatusResponse || {};

  const { data: voidBrbcResponseData, isFetching: isFetchingVoidBrbc } =
    voidBrbcReponse || {};

  const {
    data: voidRestartBrbcResponseData,
    isFetching: isFetchingVoidRestartBrbc,
  } = voidRestartBrbcReponse || {};

  const { isFetching: postingBrbcData, data: brbcCoversheetData } =
    newBrbcCoversheetReponse || {};

  const { isFetching: sendingBrbcEnvelope, data: brbcEnvelopeData } =
    sendBrbcEnvelopeResponse || {};

  const handleFileAttached = (info) => {
    setUploadedFileInfo(info);
  };

  // different types of title's
  const autoModalTitle = (
    <>
      <ModalHeadingContainer>
        <ModalHeading>Complete your buyer representation</ModalHeading>
        <ModalSubHeading>Select an option to proceed</ModalSubHeading>
      </ModalHeadingContainer>
    </>
  );

  const sendBuyerRepresentation = (
    <>
      <ModalHeadingContainer style={{ borderBottom: "1px solid #E0E0EB" }}>
        <ModalHeading>Finalize buyer representation</ModalHeading>
        <ModalSubHeading>
          Add a subject and personalized note for the buyer representation
          before sending it to your client. The document will only be sent to
          your seller for a digital signature after you've fully executed it.
        </ModalSubHeading>
        <br></br>
      </ModalHeadingContainer>
    </>
  );

  const uploadModalTitle = (
    <>
      <ModalHeadingContainer>
        <ModalHeading>Upload your buyer representation</ModalHeading>
      </ModalHeadingContainer>
    </>
  );

  const deleteBrbcTitle = (
    <>
      <ModalHeadingContainer>
        <ModalHeading>Delete buyer representation</ModalHeading>
        <ModalSubHeading>
          Are you sure you want to delete this buyer representation? This action
          cannot be undone.
        </ModalSubHeading>
      </ModalHeadingContainer>
    </>
  );

  const deleteCoBuyerInfo = (
    <>
      <ModalHeadingContainer>
        <ModalHeading>Remove Co-Buyer Information</ModalHeading>
        <ModalSubHeading>
          Are you sure you want to remove the co-buyer information? This action
          cannot be undone, and any details entered will be lost.
        </ModalSubHeading>
      </ModalHeadingContainer>
    </>
  );

  const voidBRBC = (
    <>
      <ModalHeadingContainer>
        <ModalHeading>Void buyer representation</ModalHeading>
        <ModalSubHeading>
          Are you sure you want to void this buyer representation? This action
          cannot be undone.
        </ModalSubHeading>
        <ReasonHeading>Reason for voiding*</ReasonHeading>
        <ReasonSubheading>
          Please provide a reason for voiding this representation.
        </ReasonSubheading>
      </ModalHeadingContainer>
    </>
  );

  const voidAndDuplicateBRBC = (
    <>
      <ModalHeadingContainer>
        <ModalHeading>Void & duplicate buyer representation</ModalHeading>
        <ModalSubHeading>
          Are you sure you want to void this buyer representation and create a
          duplicate? The original representation will be voided, and a copy will
          be created.
        </ModalSubHeading>
        <ReasonHeading>Reason for voiding*</ReasonHeading>
        <ReasonSubheading>
          Please provide a reason for voiding this representation.
        </ReasonSubheading>
      </ModalHeadingContainer>
    </>
  );

  // modal button states
  const getModalDetails = () => {
    if (modalState === "upload-doc") {
      setPrimaryBtnTitle("Confirm");
      setSecBtnTitle("Cancel");
      return uploadModalTitle;
    } else if (modalState === "delete-brbc") {
      setPrimaryBtnTitle("Delete");
      setSecBtnTitle("Cancel");
      return deleteBrbcTitle;
    } else if (modalState === "delete-brbc-cobuyer") {
      setPrimaryBtnTitle("Remove");
      setSecBtnTitle("Cancel");
      return deleteCoBuyerInfo;
    } else if (modalState === "void-brbc") {
      setPrimaryBtnTitle("Void");
      setSecBtnTitle("Cancel");
      return voidBRBC;
    } else if (modalState === "void-duplicate-brbc") {
      setPrimaryBtnTitle("Void & Duplicate");
      setSecBtnTitle("Cancel");
      return voidAndDuplicateBRBC;
    } else if (modalState === "sign-brbc") {
      setPrimaryBtnTitle("Save and sign");
      setSecBtnTitle("Cancel");
      return sendBuyerRepresentation;
    } else {
      setPrimaryBtnTitle("Fill & Sign");
      setSecBtnTitle("Upload from files");
      setThirdBtnTitle("Don't ask again");
      return autoModalTitle;
    }
  };

  // to set modal titles
  useEffect(() => {
    const title = getModalDetails();
    setModalTitle(title);
  }, [modalState]);

  // console.log("check state", brbcData?.docusign[0]?.envelope_id);
  const mergedEnvId = brbcData && brbcData?.docusign[0]?.envelope_id;
  const prefilledEnvId =
    brbcCoversheetData && brbcCoversheetData[0]?.envelope_id;

  // const [latestEnvId, setlatestEnvId] = useState("")

  // useEffect(() => {
  //   const envid2 = brbcCoversheetData
  //   ? brbcCoversheetData[0]?.envelope_id
  //   : brbcData?.docusign[0]?.envelope_id;
  //   setlatestEnvId(envid2);
  //   console.log("here", brbcData);
  // }, [brbcData, brbcCoversheetData]);

  // to close modals when event is completed
  useEffect(() => {
    if (
      brbcUploadResponse?.success === true &&
      modalState === "upload-doc" &&
      newReq == true
    ) {
      onClose();
      setNewReq(false);
    } else if (
      voidBrbcResponseData?.success === true &&
      modalState === "void-brbc" &&
      newReq == true
    ) {
      onClose();
      reasonForm.resetFields();
      setNewReq(false);
    } else if (
      voidRestartBrbcResponseData?.success === true &&
      modalState === "void-duplicate-brbc" &&
      newReq == true
    ) {
      setIsModalState("sign-brbc");
      reasonForm.resetFields();
      setNewReq(false);
    } else if (
      brbcEnvelopeData &&
      modalState === "sign-brbc" &&
      newReq == true &&
      !isFetchingBrbcData
    ) {
      onClose();
      setCurrentDrawerPage("drawer-header");
      setNewReq(false);
    } else if (
      brbcCoversheetDeleteData?.success === true &&
      modalState === "delete-brbc" &&
      newReq == true
    ) {
      onClose();
      setNewReq(false);
    }
  }, [
    brbcUploadResponse,
    voidBrbcResponseData,
    voidRestartBrbcResponseData,
    brbcEnvelopeData,
    isFetchingBrbcData,
  ]);

  // handle modal button CTAs
  const handleBrbcModalCTA = (clickType) => {
    if (modalState === "auto-modal") {
      if (clickType === "primary") {
        // fetchBrbcCoversheet({
        //   agentId: agentId,
        //   clientId: clientId,
        // });
        const searchCriteriaState =
          clientData?.clientTransactions.length > 0
            ? clientData?.clientTransactions?.BUYING[0]?.requirement
                ?.locations[0]?.state
            : "California";
        getAgentDetailsFromState({
          agentId: agentId,
          state: searchCriteriaState,
        });
        setCurrentDrawerPage("brbc-form-drawer");
      } else {
        setIsModalState("upload-doc");
      }
    } else if (modalState === "upload-doc") {
      if (clickType === "primary") {
        uploadBrbcDoc({
          file: uploadedFileInfo.file,
          bucketName:
            ENV === "staging" || ENV === "local" ? "s-brbc" : "p-brbc",
          agentId: agentId,
          clientId: clientId,
          callback: () => {
            fetchPresignedBrbcDocs({
              agentId: agentId,
              clientId: clientId,
            });
            fetchBrbcStatus({
              agentId: agentId,
              clientId: clientId,
            });
          },
        });
        setNewReq(true);
      } else {
        setIsModalState("auto-modal");
      }
    } else if (modalState === "delete-brbc") {
      const envId = brbcCoversheetData
        ? brbcCoversheetData[0]?.envelope_id
        : brbcData?.docusign[0]?.envelope_id;
      if (clickType === "primary") {
        if (brbcData?.docusign.length > 0) {
          deleteBrbcEnvelope({
            client_id: clientId,
            agent_id: agentId,
            envelopeIds:
              brbcData?.presignedDocuments.length > 0
                ? [prefilledEnvId]
                : [mergedEnvId],
            callback: () => {
              fetchBrbcStatus({
                agentId: agentId,
                clientId: clientId,
              });
            },
          });
        } else {
          deleteBrbcDoc({
            clientId: clientId,
            agentId: agentId,
            documentId: brbcData?.presignedDocuments[0]?.id,
            callback: () => {
              fetchBrbcStatus({
                agentId: agentId,
                clientId: clientId,
              });
            },
          });
        }
        setNewReq(false);
      } else {
        onClose();
      }
    } else if (modalState === "void-brbc") {
      if (clickType === "primary") {
        const envId = brbcCoversheetData
          ? brbcCoversheetData[0]?.envelope_id
          : brbcData?.docusign[0]?.envelope_id;
        voidBrbcCoversheet({
          envelopeId:
            brbcData?.presignedDocuments.length > 0
              ? prefilledEnvId
              : mergedEnvId,
          reason: reasonMessage,
          callback: () => {
            fetchBrbcStatus({
              agentId: agentId,
              clientId: clientId,
            });
          },
        });
        setNewReq(true);
      } else {
        onClose();
      }
    } else if (modalState === "void-duplicate-brbc") {
      if (clickType === "primary") {
        const envId = brbcCoversheetData
          ? brbcCoversheetData[0]?.envelope_id
          : brbcData?.docusign[0]?.envelope_id;
        voidDuplicateBrbcCoversheet({
          envelopeId:
            brbcData?.presignedDocuments.length > 0
              ? prefilledEnvId
              : mergedEnvId,
          reason: reasonMessage,
          callback: () => {
            fetchBrbcStatus({
              agentId: agentId,
              clientId: clientId,
            });
          },
        });
        setNewReq(true);
      } else {
        onClose();
      }
    } else if (modalState === "sign-brbc") {
      if (clickType === "primary") {
        const envId = brbcCoversheetData
          ? brbcCoversheetData[0]?.envelope_id
          : brbcData?.docusign[0]?.envelope_id;
        if (brbcSubject && brbcTitle && brbcNote) {
          sendBrbcEnvelope({
            title: brbcTitle,
            emailSubject: brbcSubject,
            emailBody: brbcNote,
            envelopeIds:
              brbcData?.presignedDocuments.length > 0
                ? [prefilledEnvId]
                : [mergedEnvId],
            callback: () => {
              fetchBrbcStatus({
                agentId: agentId,
                clientId: clientId,
              });
            },
          });
          brbcSignForm.resetFields();
          setNewReq(true);
        }
      } else {
        getClientTransactionsById({ transactionId: tidData.id });
        onClose();
        // setCurrentDrawerPage("drawer-header");
      }
    }
  };

  const handleDontAskAgain = () => {
    cookie.save("askBRBC", false);
    onClose();
  };

  return (
    <StyledBrbcModal
      title={modalTitle}
      visible={toggleModal || fromVoidDuplicate}
      footer={null}
      onCancel={onClose}
    >
      {modalState === "upload-doc" && (
        <BrbcFileContainer>
          <UploadBrbc onFileAttach={handleFileAttached} />
        </BrbcFileContainer>
      )}
      {(modalState === "void-brbc" || modalState === "void-duplicate-brbc") && (
        <FormDiv>
          <Form
            form={reasonForm}
            layout="vertical"
            initialValues={{
              reason: "",
            }}
          >
            <InputArea
              name="void-reason"
              inputType="text"
              maxLength={500}
              onChange={(e) => {
                const validText = e.target.value;
                setReasonMessage(validText);
              }}
              type="custom"
              customRules={[{ required: true, message: "Reason is required." }]}
            />
          </Form>
          <CountTracker>
            <p>{`${reasonMessage.length} / 500`}</p>
          </CountTracker>
        </FormDiv>
      )}
      {modalState === "sign-brbc" && (
        <FormDiv>
          <Form
            form={brbcSignForm}
            layout="vertical"
            requiredMark={false}
            initialValues={{
              brbc_sign_title: brbcTitle,
              brbc_sign_subject: brbcSubject,
              brbc_sign_note: brbcNote,
            }}
          >
            <InputField
              name="brbc_sign_title"
              inputType="text"
              text="Title"
              required={true}
              requiredMessage="Title is required."
              value={brbcTitle}
              onChange={(e) => {
                const validText = e.target.value;
                setbrbcTitle(validText);
              }}
            />
            <CountTracker>
              <p>{`${brbcTitle.length} / 100`}</p>
            </CountTracker>

            <InputField
              name="brbc_sign_subject"
              inputType="text"
              text="Subject"
              required={true}
              requiredMessage="Subject is required."
              onChange={(e) => {
                const validText = e.target.value;
                setbrbcSubject(validText);
              }}
            />
            <CountTracker>
              <p>{`${brbcSubject.length} / 100`}</p>
            </CountTracker>

            <InputArea
              name="brbc_sign_note"
              inputType="text"
              text="Personalized Note"
              maxLength={500}
              onChange={(e) => {
                const validText = e.target.value;
                setbrbcNote(validText);
              }}
              required={true}
              requiredMessage="Personalized note is required"
              // type="custom"
              // customRules={[{ required: true, message: "Note is required." }]}
            />
            <CountTracker>
              <p>{`${brbcNote.length} / 500`}</p>
            </CountTracker>
          </Form>
        </FormDiv>
      )}
      <FooterContainer>
        <div style={{ display: "flex", gap: "8px" }}>
          {
            <ModalFillBtn
              onClick={() => handleBrbcModalCTA("primary")}
              btnColor={modalState}
              disabled={
                isFetchingBrbcResponse ||
                isFetchingVoidBrbc ||
                sendingBrbcEnvelope ||
                isFetchingVoidRestartBrbc ||
                isFetchingDeleteBrbcCoversheet ||
                isFetchingDeleteBrbcDoc
              }
            >
              {isFetchingBrbcResponse ||
              isFetchingVoidBrbc ||
              sendingBrbcEnvelope ||
              isFetchingVoidRestartBrbc ||
              isFetchingDeleteBrbcCoversheet ||
              isFetchingDeleteBrbcDoc ? (
                <ThreeDotLoader color="white" />
              ) : (
                primaryBtnTitle
              )}
            </ModalFillBtn>
          }
          <ModalLightBtn onClick={() => handleBrbcModalCTA("secondary")}>
            {secBtnTitle}
          </ModalLightBtn>
        </div>
        {thirdBtnTitle && (
            <DontAskBtn onClick={handleDontAskAgain}>
              Don't ask again
            </DontAskBtn>
        )}
      </FooterContainer>
    </StyledBrbcModal>
  );
};

export default withRouter(
  Listings(
    ReferralContainer(
      TechAssetsContainer(BrbcContainer(CRMV2Container(BrbcModal)))
    )
  )
);
