/* eslint-disable max-len */
import React from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import Modal from "@ui/Modal"
import queryString from "query-string"
import colors from "@colors"
import { withRouter } from "react-router-dom"
import { ATC } from "@fonts"
import TechAssetsContainer from "container/TechAssets"
import CRMV2Container from "container/CRM"
import CloseIcon from "dumbComponents/FinancingDashboard/images/Close_5.svg"
import {
  CONTRACT, LISTING, OFFER,
} from "../../common"
import Cookies from "react-cookies";

const Wrap = styled.div`
  background: white;
  border-radius: 12px;
`
const Container = styled.div``
const HeaderContainer = styled.div``
const Header = styled.div`
  display: flex;
  align-items: baseline;
  font-family: ${ATC};
  font-style: normal;
  margin-left: 30px;
  text-align: left;
  h1 {  
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    color: ${colors.black};
    margin-bottom: -5px;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: ${colors.black};
    display: inline;
    text-align: left;
    font-weight: 200;
  }
  b {
    font-size: 14px;
    line-height: 17px;
    color: #F53F3F;
    text-align: left;
  }
`

const Content = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${colors.black};
  text-align: left;
  font-weight: 200;
`

const ContentContainer = styled.div`
  padding: 20px 20px 0px 40px;
`

const ButtonWrap = styled.div`
  display: flex;
  padding: 20px 20px 30px 20px;
  flex-direction: row-reverse;
`

const AnimationWrap = styled.div`
  position: relative;
  top: 14px;

  img {
    width: 49px;
    height: 49px;
  }
`

const ConfirmToGoToContracts = ({
  showGoToContract,
  currentFilters,
  toggleGoToContract,
  isClosed,
  setIsClosed,
  updateClientTransaction,
  statusValue,
  location,
  updateContractCRM,
  createTransaction,
  history,
  dealId,
  setDealToView,
  getBrokerageTransactionStatusResponse,
  changeCardStage,
  getClientTransactionsById,
  getCtIdResponse,
  getClient,
  setIsEndContract,
  setClientID,
  toggleUserOverview,
  currentCRMDealId,
  getContractById,
  saveListingSteps,
  addBrokerageTransaction,
  crmClientData,
  confirmMoveToCreateContract,
  confirmMoveToCreateContractResponse,
  viewContractForListing,
  performNecessaryActionsBeforeLoadingListing,
}) => {
  const parsedQuery = queryString.parse(location.search)
  //const { client_id: clientID, transactionId, type: clientType } = parsedQuery
  const isSubscribedToNetworkPlan = Cookies.load("isSubscribedToNetworkPlan")
  const {
    data: clientTransactionById,
    isFetching: fetchingData,
  } = getCtIdResponse || {}

  const {
    isFetching: isCreatingContractForm,
  } = confirmMoveToCreateContractResponse || {}

  const {
    id: cardId,
    client_id,
    type,
  } = clientTransactionById || {}

  const {
    isFetching,
    data: brokerageTransactionStatus,
  } = getBrokerageTransactionStatusResponse || {}

  const {
    initiateTransaction,
    id,
    transactionType,
    status: transactionStatus
  } = brokerageTransactionStatus || {}

  const { result } = showGoToContract || {}
  const {
    draggableId,
    source,
    destination,
  } = result || {}

  const handleChange = () => {
    const payload = {
      status: statusValue,
      client_id,
      type,
    }
    updateClientTransaction({
      transactionId: cardId,
      payload,
      currentFilters,
    })
  }

  return (
    <Wrap>
      <>
        <Header>
          <AnimationWrap>
            <img src={`${CLOUDFRONT}/LISTING_V2/Success.gif`} alt="animation_contracts_success.gif" />
          </AnimationWrap>
          <h1>
            Congratulations!
          </h1>
        </Header>
        <ContentContainer>
          <Content>
            {isClosed
              ? "Please provide contract details before closing the transaction"
              : "Please provide details of the contract"
            }
          </Content>
        </ContentContainer>
        <ButtonWrap>
          
   <LoaderButton
  bsStyle="marineBLueFill"
  padding="16px"
  margin="0 5px"
  width="50%"
  borderRadius="50px"
  isShowLoader={isCreatingContractForm}
  loaderOption={{
    color: "white",
  }}
    onClick={() => {
    if (isSubscribedToNetworkPlan == "false") {
      confirmMoveToCreateContract({
        clientId: client_id,
        transactionId: cardId || draggableId,
        history,
        isSeller: type === "seller",
        id,
        transactionType,
        transactionStatus,
      });
      toggleUserOverview({ showUserOverviewDrawer: false });
      setClientID({
        id: "",
        deal_id: "",
      });
    } else {
      toggleGoToContract({ isShowGoToContract: false });
      getClientTransactionsById({ transactionId: cardId });
      getClient({ clientId: client_id });
      setIsEndContract(false);
      setIsClosed(false);
      handleChange();
      history.push("/realty/promos?type=offers")
    }
  }}
>
  Add Contract Details
</LoaderButton>

          <LoaderButton
            bsStyle="marineBLueOutlined"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            //isShowLoader={isCreatingContractForm}
            loaderOption={{
              color: "blue",
            }}
            onClick={() => {
              toggleGoToContract({ isShowGoToContract: false })
              getClientTransactionsById({ transactionId: cardId })
              getClient({ clientId: client_id })
              setIsEndContract(false)
              setIsClosed(false)
              handleChange(); 
              // setEditingDealTeamMemberId(null)
            }}
          >
            Skip
          </LoaderButton>
        </ButtonWrap>
      </>
    </Wrap>
  )
}

export default withRouter(CRMV2Container(withRouter(TechAssetsContainer(ConfirmToGoToContracts))))
