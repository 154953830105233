import styled from "styled-components"
import { ONBOARDING_BANNER_HEIGHT } from "@consts"
import { ATC, INTER, MONTSERRAT } from "@fonts"
import { Input } from 'antd';
import {
  CardCvcElement,
} from "react-stripe-elements"

export const ModalWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;
`
export const Heading = styled.div`
align-self: stretch;
color: var(--Neutral-900, #111827);
font-family: ${INTER};
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 28px;
`
export const PricingWrap = styled.div`
display: flex;
align-items: flex-start;
gap: 32px;
align-self: stretch;

@media (max-width: 480px) {
  flex-direction: column;
}
`
export const PricingBlock = styled.div`
border-radius: 12px;
width: 50%
// border: 3px solid var(--primary-500-main, #6366F1);
border: 2px solid  ${props => props.border || "#6366F1"};
background: var(--Generic-White, #FFF);
box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.05), 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
display: flex;
padding: 20px;
gap: 24px;
flex-direction: column;
align-items: flex-start;
flex: 1 0 0;

@media (max-width: 480px) {
  width: 100%;
}
`

export const PricingDiv = styled.div`
display: flex;
align-items: flex-start;
gap: 16px;
align-self: stretch;
`
export const PricingRight = styled.div`
border-radius: 24px;
background: var(--Primary-50, #EEF2FF);
display: flex;
padding: 6px 12px;
justify-content: center;
align-items: center;
p {
margin: 0 0 0px !important;
color: var(--primary-500-main, #6366F1);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px;
}
`

export const PricingLeft = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 8px;
flex: 1 0 0;
`
export const PriceHeader = styled.div`
color: var(--Primary-700, #4338CA);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
`

export const PriceValueDiv = styled.div`
display: flex;
align-items: flex-end;
gap: 8px;
align-self: stretch;
`

export const PriceValue = styled.div`
color: var(--Neutral-900, #111827);
font-family: Inter;
font-size: 36px;
font-style: normal;
font-weight: 700;
line-height: 44px; /* 122.222% */
letter-spacing: -0.72px;
`

export const PriceTime = styled.div`
color: var(--Neutral-400, #9CA3AF);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
margin-bottom: 5.5px;
@media (max-width: 480px) {
  width: 100%;
}
`

export const PriceIntro = styled.div`
color: var(--Neutral-500, #6B7280);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px;
`
export const HorizontalBar = styled.div`
  width: 100%;
  height: 1.5px;
  background-color: #DFDFE7;
`

export const BenefitsItems = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;
`

export const BenefitsDiv = styled.div`
display: flex;
align-items: flex-start;
gap: 12px;
align-self: stretch;
`

export const Benefit = styled.div`
color: var(--Neutral-700, #374151);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
`

export const StyledButton = styled.button`
display: flex;
padding: 14px 20px;
width: 100%;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 1px solid var(--primary-500-main, #6366F1);
background: var(--primary-500-main, #6366F1);
box-shadow: 0px 2px 6px 0px rgba(16, 24, 40, 0.06);
color: var(--Generic-White, #FFF);
text-align: center;
font-family: ${INTER};
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 18px;
margin-top: 36px;
`

export const StyledButtonCall = styled.button`
display: flex;
padding: 14px 20px;
width: 100%;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 1px solid var(--Neutral-200, #E5E7EB);
background: var(--Generic-White, #FFF);
box-shadow: 0px 2px 6px 0px rgba(16, 24, 40, 0.06);
margin-top: 36px;
p {
color: var(--Neutral-700, #374151);
text-align: center;
font-family: ${INTER};
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 18px;
margin : 0 0 0px !important;
}
`

export const CreditHeadingWrap = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 8px;
align-self: stretch;
${props => props.center && `
  align-items: center;
`}
`
export const CreditHeading = styled.div`
color: var(--Neutral-900, #111827);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 28px;
`

export const CreditImage = styled.div`
display: flex;
width: 48px;
height: 48px;
padding: 8px;
justify-content: center;
align-items: center;
border-radius: 24px;
background: var(--Primary-50, #EEF2FF);
`
export const CreditContent = styled.div`
color:${props => props.color || "#373758"};
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
`
export const CreditBoxWrap = styled.div`
display: flex;
padding: 16px 24px;
justify-content: space-between;
align-items: center;
align-self: stretch;
border-radius: 8px;
border: 1px solid var(--Global-Mapping-Gray-300, #A3A3C2);
background: var(--Global-Mapping-Gray-100, #E0E0EB);
`
export const CreditBoxContent = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 4px;
flex: 1 0 0;
`

//can have font and color props for this
export const CreditBoxHeader = styled.div` 
color: var(--Global-Mapping-Gray-900, #232338);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
display: flex;
align-items: center;
gap: 8px;
`

export const CreditCardWrap = styled.div`
display: flex;
padding: 10px 16px;
justify-content: center;
align-items: center;
gap: 6px;
border-radius: 8px;
border: 1px solid var(--Global-Mapping-Primary, #5A5FF2);
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
p{
color: var(--Global-Mapping-Primary, #5A5FF2);
text-align: center;
/* Label/Medium/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px;
margin: 0px;
}
`
export const CreditDetailsWrap = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 8px;
align-self: stretch;
width: ${props => props.width || "100%"}
`
export const CreditDetailsLabel = styled.div`
color: var(--Neutral-900, #111827);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 16px; /* 114.286% */
`
export const CreditExpCVVWrap = styled.div`
display: flex;
justify-content: space-between;
gap: 20px;
align-items: flex-start;
align-self: stretch;
`
export const CreditPlanWrap = styled.div`
display: flex;
padding: 16px 24px;
justify-content: space-between;
align-items: center;
align-self: stretch;
border-radius: 8px;
border: 1px solid var(--Global-Mapping-Gray-100, #E0E0EB);
background: var(--Global-Mapping-White, #FDFDFD);
`
export const CreditPlanDiv = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 4px;
flex: 1 0 0;
`
export const CreditDetailsLabelDiv = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
align-self: stretch;
`
export const CreditDetailsLabelDiv2 = styled.div`
display: flex;
justify-content: space-between;
flex-direction: column;
gap: 8px;
`

export const FreeTrialWrap = styled.div`
display: flex;
padding: 2px 10px;
justify-content: center;
align-items: center;
display: flex;
border-radius: 24px;
background: var(--Color-Green-Green-500, #0C9F6E);
padding: 2px 10px;
justify-content: center;
align-items: center;
color: var(--Generic-White, #FFF);
text-align: center;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 20px; 
`

export const CreditPlanButtonWrap = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
gap: 12px;
align-self: stretch;
`
export const CreditCancelButton = styled.button`
display: flex;
padding: 10px 16px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 1px solid var(--Neutral-200, #E5E7EB);
background: var(--Generic-White, #FFF);
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
p {
color: var(--Neutral-700, #374151);
text-align: center;
/* Label/Medium/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px;
margin: 0px
}
`
export const CreditProceedButton = styled.button`
display: flex;
padding: 10px 16px;
justify-content: center;
align-items: center;
gap: 6px;
border-radius: 8px;
border: 1px solid var(--primary-500-main, #6366F1);
background: var(--primary-500-main, #6366F1);
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
${props => props.width && `
  width: 100%
`}
p {
color: var(--Generic-White, #FFF);
text-align: justify;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px; 
margin: 0px
}
  &:disabled {
    background: var(--primary-300, #a5b4fc); 
    border-color: var(--primary-300, #a5b4fc); 
    color: var(--Generic-White, #fff); 
    cursor: not-allowed; 
    opacity: 0.7;
  }
`

//use CreditHeadingWrap
export const SuccessHeader = styled.div`
color: var(--Neutral-900, #111827);
text-align: center;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 28px; /* 155.556% */
`

export const SuccessDetailsWrapper = styled.div`
display: flex;
padding: 16px 24px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;
border-radius: 8px;
border: 1px solid var(--Global-Mapping-Gray-100, #E0E0EB);
background: var(--Global-Mapping-White, #FDFDFD);
`

export const SuccessContent = styled.div`
color:${props => props.color || "#373758"};
font-family: Inter;
text-align: center;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
`
export const SuccessBlock = styled.div`
display: flex;
align-items: center;
gap: 4px;
align-self: stretch;
${props => props.space && `
  justify-content: space-between
`}
`

export const CreditFormWrap = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 20px;
align-self: stretch;
width:100%;
`
export const FormWrap = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
`
export const CardCVInputWrap = styled(CardCvcElement)`
 height: 47px;
align-items: flex-start;
align-self: stretch;
border-radius: 8px;
display: block;
padding: 8px 12px;
flex: 1 0 0;
border: 1px solid var(--Neutral-200, #E5E7EB);
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04)
`

export const TermsCondWrap = styled.div`
display: flex;
align-items: flex-start;
gap: 12px;
align-self: stretch;
p {
color: var(--Neutral-500, #6B7280);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
`
export const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  flex-direction: column;
`
export const InvalidWrap = styled.p`
  margin: 0;
  color: red;
`
export const RadioDiv = styled.div`
display: flex;
padding: 4px;
align-items: center;
gap: 16px;
border-radius: 6px;
background: #F4F4F4;
`
export const ToggleButtonWrap = styled.button`
display: flex;
padding: 8px 24px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
background: #F4F4F4;
color: var(--Neutral-700, #374151);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
border: none;
outline: none;
line-height: 16px; /* 114.286% */
`
export const ActiveButton = styled.button`
display: flex;
padding: 8px 24px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 1px solid var(--Global-Mapping-Primary, #5A5FF2);
background: var(--Generic-White, #FFF);
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
color: var(--Primary-600, #4F46E5);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px; /* 114.286% */
`

export const ACHWrapDiv = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 5px;
align-self: stretch;
`

export const ACHInputDiv = styled(Input)`
display: flex;
height: 40px;
padding: 8px 12px;
align-items: center;
align-self: stretch;
border-radius: 8px;
border: 1px solid var(--Neutral-200, #E5E7EB);
background: var(--Generic-White, #FFF);
`
export const CardLabel = styled.div`
align-self: stretch;
color: var(--Neutral-900, #111827);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px; /* 114.286% */
`
