import styled from "styled-components";
import colors from "@colors";

const Container = styled.div`
  max-width: 1420px;
  margin: 0 auto;
  display: flex;
  margin-top: 5px;
  @media (max-width: 1024px) {
    // padding-top: 50px;
    margin: 0;
    overflow: hidden;
    display: block;
    width; 100vw!important;
    padding-bottom: 100px;
    margin-bottom: 0px;

    .ant-carousel {
  //height: 291px;
  border-radius: 12px;
  @media (max-width: 1024px) {
    border-radius: 0px;
  }
}
.slick-slide slick-active slick-current {
  width: 842px !important;
  //height: 291px !important;
  img {
    width: 842px;
    //height: 291px;
  }
}
.slick-slide img {
  @media (max-width: 1024px) {
    width: 100vw;
  }
}

.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  //height: 291px !important;
  border-radius: 12px;
  @media (max-width: 1024px) {
    border-radius: 0px;
  }
}

.ant-carousel .slick-list .slick-slide.slick-active {
  @media (max-width: 1024px) {
    width: auto !important;
  }
}
.ant-carousel .slick-next::before {
  display: none !important;
}
.ant-carousel .slick-next {
  display: none !important;
}
.ant-carousel .slick-prev::before {
  display: none !important;
}
.ant-carousel .slick-dots li button {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.ant-carousel .slick-dots li {
  height: 10px;
}

.slick-dots li button:before {
  color: transparent !important;
}

.ant-carousel .slick-dots-bottom {
  width: auto !important;
  overflow: hidden;
}

}
`;
const ImagesContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    padding-top: 50px;
    display: none;
    padding-bottom: 100px;
    margin-bottom: 0px;
  }
`;
const RightPane = styled.div`
  width: 528px;
  padding: 34px 24px;
  @media (max-width: 1024px) {
    width: auto;
    background: ${colors.white};
    padding: 12px 20px;
  }
`;
const Wrap = styled.div`
  background: ${colors.white};
  padding: 24px 18px;
  margin-top: 24px;
  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 0px;
    padding: 0px 18px;
    margin-bottom: 0px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    padding-left: 40px; // Add padding to the left
    padding-right: 40px; // Add padding to the right
  }
`;

const StickyWrap = styled.div`
  background: ${colors.white};
  padding: 24px 18px;
  margin-top: 24px;
  position: sticky !important;
  top: 180px;
  border-radius: 16px;
  border: 1px solid #e0e0eb;
  @media (max-width: 1024px) {
    border: none;
    width: 100%;
    margin-top: 0px;
    padding: 0px 18px;
    margin-bottom: 0px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    border: none;
    padding-left: 40px; // Add padding to the left
    padding-right: 40px; // Add padding to the right
  }
`;

const ColLeft = styled.div`
  max-width: 900px;
  width: 900px;
  margin-left: 24px;
  margin-right: 24px;
  @media (max-width: 1024px) {
    width: 100vw;
    margin-left: 0;
    margin-right: 0;
    max-width: 100vw;
  }
`;

const ColRight = styled.div`
  width: 440px;
  margin-right: 24px;
  @media (max-width: 1024px) {
  width: 90vw;
  margin: 0;
  z-index: 100;
  // display: none;
    // background: ${colors.white};
    // width: 100vw;
  }
`;

const TabWrapper = styled.div`
  position: fixed;
  width: 100%;
  font-family: Inter;
  margin: 0 5px;
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 200;
  .ant-tabs-nav {
    margin: 0px;
  }
  @media (max-width: 1024px) {
    display: none;
  }
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 0 !important;
  }
`;

const TabBtns = styled.button`
  border: 1px solid #e0e0eb;
  cursor: pointer;
  border-radius: 100px;
  background-color: white;
  margin: 0 5px;
  color: #232338;
  font-size: 14px;
  font-weight: 600;
  width: 200px;
  height: 48px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #efeffe;
  }
`;

const NoData = styled.div`
  margin: 0 auto;
  font-size: 16px;
  margin-top: 100px;
`;

const MobileCarousel = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: block;
    width: 100vw;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 0px 150px;
    display: block;
  }
`;

const DatesCardWrapper = styled.div`
  width: 98%;
  margin-bottom: 48px;
  @media (max-width: 1024px) {
    align-items: center;
    width: 85%;
    margin-left: 10px;
  }
`;
const OpenHouseDesc = styled.p`
  font-size: 16px;
  font-weight: 300;
  color: ${colors.new_text_purple_600};
  margin-bottom: 24px;
`;

const OpenHouseHeader = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-top: 48px;
  color: ${colors.new_text_purple_600};
  margin-bottom: 24px;
  letter-spacing: 0.5px;
  @media (max-width: 1024px) {
    margin-top: 32px;
  }
`;

const ShimmerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const WebOnlyOpenHouse = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;

export {
  Wrap,
  RightPane,
  Container,
  ColLeft,
  ColRight,
  NoData,
  ImagesContainer,
  TabWrapper,
  TabBtns,
  MobileCarousel,
  DatesCardWrapper,
  OpenHouseDesc,
  OpenHouseHeader,
  ShimmerContainer,
  WebOnlyOpenHouse,
  StickyWrap,
};
