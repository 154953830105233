import React from "react"
import {
  DropdownWrap,
  MLSImage,
  MLSWrap,
  MLSAddress,
  MLSNumber,
} from "./googleLocationSearchStyles"
import { EditButton } from "../../AgentCard/styles"
import { getState } from "dumbComponents/ListingsV2/Components/ListingTabs/consts"

const FilledMLSTab = ({
  selectedOption,
  setSelectedOption,
  setDisplay,
}) => {
  const handleClick = () => {
    setDisplay("block")
    setSelectedOption()
  }
  return (
    <DropdownWrap
      isDropdown
    >
      <MLSImage src={selectedOption.image} alt="mls" />
      <MLSWrap>
        <MLSNumber>
          {`MLS# ${selectedOption.id}`}
        </MLSNumber>
        <MLSAddress
          fontSize="18px"
        >
          {selectedOption.address}, {selectedOption && getState(selectedOption.StateOrProvince)}
        </MLSAddress>
      </MLSWrap>
      <EditButton
        onClick={() => handleClick()}
      >
        EDIT
      </EditButton>
    </DropdownWrap>
  )
}

export default FilledMLSTab
