import styled from "styled-components";
import React, {useState} from "react";
import FreeTrialExpiryModal from "./FreeTrialExpiryModal";

const UpgradePopContainer = styled.div`
  border-radius: 8px;
  margin-top:20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 12px 20px;
  gap: 8px;
  border-radius: 8px;
  background: var(--Color-Gray-Gray-50, #efeff4);
  margin-bottom: 26px;
  width: 100%;
`;

const Message = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
  color: var(--Global-Mapping-Gray-800, #2b2b45);
  img {
    height: 14px;
    width: 14px;
    margin-right: 10px;
  }
`;

const Description = styled.span`
  display: block;
  font-size: 14px;
  color: #555;
  margin-bottom: 15px;
  color: var(--Global-Mapping-Gray-700, #373758);
  margin-left: 23px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  cursor: pointer;
display: flex;
padding: 14px 20px;
justify-content: center;
align-items: center;
    border-radius: 26px;

gap: 8px;
  &.learn-more {
    color:white
border: 1px solid var(--Global-Mapping-Primary, #5A5FF2);
background: var(--Global-Mapping-Primary, #5A5FF2);
box-shadow: 0px 2px 6px 0px rgba(16, 24, 40, 0.06);
  }

`;

const ContactSales = styled.a`
  cursor: pointer;
display: flex;
padding: 14px 20px;
justify-content: center;
align-items: center;
    border-radius: 26px;
&.contact-sales {
  border-radius: 26px;
border: 1px solid var(--Global-Mapping-Gray-200, #C1C1D7);
background: var(--Global-Mapping-White, #FDFDFD);
color:black;
box-shadow: 0px 2px 6px 0px rgba(16, 24, 40, 0.06);
  }

  &:hover {
    opacity: 0.8;
  }
}`;
const UpgradeBlock = () => {
      const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <UpgradePopContainer>
        <Message>
          {" "}
          <img src={`${CLOUDFRONT}/Consumer-app/star.svg`} alt="star" />
          Join our brokerage to access this feature
        </Message>
        <Description>
        Access to compliance tools is exclusive to Radius agents.
        </Description>
        <ButtonContainer>
          <Button className="learn-more" onClick={() => setModalVisible(true)}> Learn more</Button>
          <ContactSales className="contact-sales" id="custom_intercom">
            Chat with us
          </ContactSales>
        </ButtonContainer>
      </UpgradePopContainer>
          {
             modalVisible && <FreeTrialExpiryModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
}
      
    </>
  );
};

export default UpgradeBlock;
