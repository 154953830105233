import React, { PureComponent } from "react"
import styled from "styled-components"
import { ATC } from "@fonts"
import ListingList from "dumbComponents/Listings/components/ListingList"
import colors from "@colors"
import Loader from "@ui/Loader"
import { get } from "lodash"
import root from "window-or-global"
import queryString from "query-string"
import AccountOverviewContainer from "container/Settings/AccountOverviewContainer"
import TechAssetsContainer from "container/TechAssets"
import PlusIcon from "images/icons/WhiteIconPlus.png"
import GmailIntegrationImage from "dumbComponents/Listings/images/gmail-integration.png"
import GoogleSignIn from "@ui/GoogleSignIn"
import {
  Input, InputNumber, Select, Form, Checkbox,
  Divider, Button, Radio, Space,
} from "@ui/antd"
import Sidebar from "dumbComponents/Listings/components/Sidebar"
import ProgressBarComponent from "dumbComponents/Listings/components/ProgressBar"
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
  .ant-form-item-label {    
    > label {
      color: #303030;
      font-family: ${ATC};
      font-style: normal;
      font-weight: 200;
      font-size: 18px;
      line-height: 22px;
    }
  }
  .ant-radio-checked .ant-radio-inner {
    
  }
`

const CheckboxContainer = styled.div`
  display: flex;
`

const HeaderNav = styled.div`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: ${colors.black};
  h1 {
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    margin: 0;
  }
  p {
    font-weight: 200;
    font-size: 16px;
    line-height: 22px;
    margin: 10px 0px;
  }
`

const ProgressBarContainer = styled.div`
  padding: 30px 0px 50px 0;
  display: flex;
`

const ProgressBar = styled.div`
  background: #E5E5E5;
  ${props => (props.completed || props.ongoing) && `
    background: #5A5FF2;
  `}
  height: 6px;
  margin: 0 4px;
  width: 100%;
`

const Container = styled.div`
  display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 100%;
`

const LabelText = styled.h2`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
  color: ${colors.black};
`

const Required = styled.span`
  color: #E34C4C
`

const LocalCheckBox = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid #303030;
  border-radius: 4px;
  margin-right: 12px;
  ${props => props.disabled && `
    border: 1px solid #C7C7C7;
  `}
`

const CheckboxLabelText = styled.h2`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  color: ${colors.black};
  text-transform: uppercase;
  ${props => props.disabled && `
    color: #C7C7C7;
  `}
`

const FlexBox = styled.div`
  display: flex;
  ${props => props.wd && `
    width: ${props.wd};
  `}
  ${props => props.mb && `
    margin-bottom: ${props.mb}
  `}
  ${props => props.jc && `
    justify-content: ${props.jc}
  `}
  ${props => props.ai && `
    align-items: ${props.ai}
  `}
`

const CheckboxWrap = styled.div`
  padding: 0 20px 0 10px;
`

const StyledDivider = styled(Divider)`
  min-width: 180px !important;
  width: 180px !important;
  margin: 18px 0 !important;
`

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)

const Wrapper = styled.div`
  ${props => props.mt && `
    margin-top: ${props.mt};
  `}
  padding: 0px 20px 0 0;

`
const GmailWrapper = styled.div`
  p {
    font-weight: 200;
    font-size: 14px;
    line-height: 22px;
    margin: 6px 0px;
    color: #8C8C8C;
  }
`
const Image = styled.img``

class CoverSheet extends PureComponent {
  state = {
    showEscrowOption: null,
    notTCOption: null,
  }

  formRef = React.createRef();

  activateGmail = (auth) => {
    const { syncGmail, history } = this.props
    const search = get(root, "location.search", {})
    const parsedQuery = queryString.parse(search)
    const fromHeader = parsedQuery?.from_header
    syncGmail({
      payload: auth,
      callback: () => {
        if (fromHeader) {
          history.push("/realty/transaction-dashboard")
        } else {
          history.push("/realty/transaction-coordination/contract/form")
        }
      },
    })
  }

  onFinish = (fieldValues, isDraft) => {
    const {
      updateListingCoverSheet, history, dealToView, getListingCoverSheetResponse, updateDealStatus,
    } = this.props
    Object.keys(fieldValues).forEach((key) => {
      if (fieldValues[key] === undefined) {
        // eslint-disable-next-line no-param-reassign
        fieldValues[key] = null
      }
    })
    const selectVendor = getListingCoverSheetResponse.data?.selectVendor
    let nextRoute = "/realty/listings"
    if (fieldValues.is_using_deal_tc) {
      nextRoute = isDraft ? "/realty/listings" : "/realty/listings/coversheet/sign-and-post-installation"
    }

    updateListingCoverSheet({
      payload: {
        is_using_deal_tc: fieldValues.is_using_deal_tc,
        is_radius_tc_to_open_escrow: fieldValues.is_radius_tc_to_open_escrow,
        tc_name: fieldValues.tc_name,
        tc_email: fieldValues.tc_email,
        tc_phone: fieldValues.tc_phone,
        current_listing_step: "tc_info",
        draft: isDraft ? "Y" : "N",
      },
      listing_id: dealToView,
      callAPI: ["tc_info", "sign_post", "select_vendor"],
      nextRoute,
      history,
      callback: () => {
        if (!fieldValues.is_radius_tc_to_open_escrow) {
          updateDealStatus({
            dealId: dealToView,
            step: "coming_soon",
          })
        }
      },
    })
  }

  onSaveAsDraft = (values) => {
    console.log("===this.formRef.current.getFieldsValue===", this.formRef.current.getFieldsValue())
    this.onFinish(this.formRef.current.getFieldsValue(), true)
    // this.formRef.current.setFieldsValue({
    //   "name-0": "Hello world!",
    // })
    // alert("onFinishFailed")
  }

  setShowEscrowOption = (value) => {
    this.setState({
      showEscrowOption: value === 1,
      notTCOption: value === 0,
    })
  }

  getInitialValues = () => {
    const { getListingCoverSheetResponse } = this.props
    const { showEscrowOption, notTCOption } = this.state
    const tcInfo = getListingCoverSheetResponse.data?.tcInfo
    const result = {}
    if (tcInfo) {
      if (showEscrowOption === null && notTCOption === null) {
        this.setState({
          showEscrowOption: tcInfo.is_using_deal_tc === 1,
          notTCOption: tcInfo.is_using_deal_tc === 0,
        })
      }
      return {
        ...tcInfo,
      }
    }
    return result
  }

  render() {
    const { showEscrowOption, notTCOption } = this.state
    const { history, getListingCoverSheetResponse, updateListingCoverSheetResponse, isSyncingGmail, googleAuthToken, resetGoogleContacts } = this.props
    const commonRules = [
      {
        required: true,
        message: "Required",
      },
    ]
    const search = get(root, "location.search", {})
    const parsedQuery = queryString.parse(search)
    const fromHeader = parsedQuery?.from_header
    return (
      <Wrap>
        <HeaderNav>
          <h1>
            Automate your Process
          </h1>
          <p>
            Sync these accounts to use this feature. You only need to do this once.
          </p>
        </HeaderNav>
        <Container>
          <RightSideBar>
            <Wrapper mt="40px">
              <FlexBox jc="space-between" mb="45px" ai="center">
                <GmailWrapper>
                  <Image src={GmailIntegrationImage} alt="gmail-integration" />
                  <p>Sync your Gmail account to easily auto-populate contact information of agents, clients and vendors in all cover sheets</p>
                </GmailWrapper>
                <GoogleSignIn type="theme-1" sync={this.activateGmail} text="Connect" mr="4%" loading={isSyncingGmail} googleAuthToken={googleAuthToken?.data?.access_token} resetGoogleContacts={resetGoogleContacts} />
              </FlexBox>
            </Wrapper>
            <FlexBox>
              <FlexBox wd="100%">
              </FlexBox>
              {!fromHeader && (
                <FlexBox>
                  <Button
                    style={{
                      width: 119,
                      height: 48,
                      background: "#5A5FF2",
                      float: "right",
                      borderColor: "white",
                    }}
                    type="primary"
                    shape="round"
                    size="large"
                    htmlType="submit"
                    loading={updateListingCoverSheetResponse.isFetching || getListingCoverSheetResponse.isFetching}
                    onClick={() => { history.push("/realty/transaction-coordination/contract/form") }}
                  >
                    Skip
                  </Button>
                </FlexBox>
              )}
            </FlexBox>
          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}

export default AccountOverviewContainer(TechAssetsContainer(CoverSheet))

