import { fork } from "redux-saga/effects"
//need to handle this
import referralSaga from "container/Referral/saga"
import teamSaga from "container/Referral/teamSaga"
import clientSaga from "container/Client/saga"
import appSaga from "container/App/saga"
import profileSaga from "container/Profile/saga"
import navbarSaga from "container/Navbar/saga"
import settingsSaga from "container/Settings/saga"
import contactUsSaga from "container/StandAlone/ContactUs/saga"
import networkSaga from "container/Network/saga"
import searchSaga from "container/Search/saga"
import clientProfileSaga from "container/ClientProfile/saga"
import marketplaceSaga from "container/Marketplace/saga"
import submitReferral from "container/StandAlone/SubmitReferral/saga"
import nonAssistSaga from "container/NonAssist/saga"
import authSaga from "container/Auth/saga"
import academySaga from "container/Academy/saga"
import propertyStatusSaga from "container/PropertyStatus/saga"
import productInterestSaga from "container/ProductInterestModal/saga"
import realtySaga from "container/Realty/saga"
import propertySaga from "container/PropertyDetails/saga"
import mentoringSaga from "container/Mentoring/saga"
import techAssetsSaga from "container/TechAssets/saga"
import leaderboardSaga from "container/Leaderboard/saga"
import recruitAndEarnSaga from "container/Referral/recruitAndEarnSaga"
import eventRoomSaga from "container/EventRoom/saga"
import transactionFormSaga from "container/Realty/TransactionFormSaga"
import teamProfileSaga from "container/Realty/TeamProfileSaga"
import BrokerJobsSaga from "container/BrokerJobs/saga"
import RoomsSaga from "container/Rooms/saga"
import OnboardingSetupSaga from "container/OnboardingSetup/saga"
import CRMV2Saga from "container/CRM/saga"
import ListingPageSaga from "container/ListingPage/saga"
import ListingV2Saga from "container/Listings/saga"
import OnboardingV2Saga from "container/OnboardingV2/saga"
import OffersV2Saga from "container/OffersV2/saga"
import BrbcSaga from "container/BRBC/saga"
import GoToAppSaga from "container/StandAlone/GoToApp/saga"
import ConsumerAppSaga from "container/ConsumerApp/saga"

export default function* main() {
  yield fork(appSaga)
  yield fork(referralSaga)
  yield fork(teamSaga)
  yield fork(clientSaga)
  yield fork(profileSaga)
  yield fork(navbarSaga)
  yield fork(settingsSaga)
  yield fork(contactUsSaga)
  yield fork(networkSaga)
  yield fork(searchSaga)
  yield fork(submitReferral)
  yield fork(clientProfileSaga)
  yield fork(marketplaceSaga)
  yield fork(nonAssistSaga)
  yield fork(authSaga)
  yield fork(academySaga)
  yield fork(propertyStatusSaga)
  yield fork(productInterestSaga)
  yield fork(realtySaga)
  yield fork(propertySaga)
  yield fork(mentoringSaga)
  yield fork(techAssetsSaga)
  yield fork(leaderboardSaga)
  yield fork(recruitAndEarnSaga)
  yield fork(eventRoomSaga)
  yield fork(transactionFormSaga)
  yield fork(teamProfileSaga)
  yield fork(BrokerJobsSaga)
  yield fork(RoomsSaga)
  yield fork(OnboardingSetupSaga)
  yield fork(CRMV2Saga)
  yield fork(ListingPageSaga)
  yield fork(ListingV2Saga)
  yield fork(OffersV2Saga)
  yield fork(BrbcSaga)
  yield fork(GoToAppSaga)
  yield fork(ConsumerAppSaga)
  yield fork(OnboardingV2Saga)
}
