import React, { useState, useEffect } from "react";
import {
  Table,
  Dropdown,
  Menu,
  Checkbox,
  Button,
  Space,
  Pagination,
  Tag,
  Tooltip,
  Modal,
  DatePicker,
  Empty,
  Typography,
  Timeline,
  Tabs,
  Badge,
} from "antd";
import Lottie from "react-lottie";
import animationData from "./lottie/mel_icon";
import {
  DownOutlined,
  DeleteOutlined,
  HistoryOutlined,
  HeartOutlined,
  RightOutlined,
} from "@ant-design/icons";
import ConsumerAppContainer from "container/ConsumerApp/index";
import CRMV2Container from "container/CRM";

import moment from "moment";
import "./styles.css";
import {
  TimelineItem,
  TimelineStatus,
  TimelineDate,
  TimelineContainer,
  ModalTitle,
  StatusTag,
  ApplyButton,
  FilterButton,
  FiltersContainer,
  BedNumber,
  Beds,
  Dot,
  OuterWrap,
  InnerText,
  GradientText,
  NewContentContainer,
  InviteClientButton,
  FooterButton,
  FeatureDetails,
  IconWrapper,
  Feature,
  FeaturesList,
  SubTitle,
  Title,
  Logo,
  ModalHeader,
  WaitlistWrap,
} from "./styles";

const RecommendedPropertiesTable = ({
  clientId,
  reqId,
  fetchRecommendedProperties,
  fetchRecommendedPropertiesResponse,
  rejectPropertyStatus,
  turnOnMelRecommendation,
  rejectPropertyStatusResponse,
  turnOnMelRecommendationResponse,
  getCrmClientResponse,
  removeBulkProperty,
  removeBulkPropertyResponse,
  isMelRecommendedOn,
  isMelRecommendedData,
  getAppStatusResponse,
  joinWaitlist,
  joinWaitlistResponse,
}) => {
  const [filters, setFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [likeStatus, setLikeStatus] = useState([-1, 1, 0]);
  const [sortOrder, setSortOrder] = useState("dateLatest");
  const [dataSource, setDataSource] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(
    false
  );
  const [menuVisible, setMenuVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    if (getAppStatusResponse?.data?.agent_id) {
      const payload = {
        clientId: clientId,
        reqId: reqId,
        likeStatus: likeStatus.join(","),
        date: sortOrder,
        startDate: fromDate ? fromDate.format("YYYY-MM-DD") : null,
        endDate: toDate ? toDate.format("YYYY-MM-DD") : null,
        limit: 3,
        skip: (currentPage - 1) * 3,
      };
      fetchRecommendedProperties(payload);
    }
  }, [
    clientId,
    reqId,
    currentPage,
    sortOrder,
    likeStatus,
    getAppStatusResponse,
  ]);

  useEffect(() => {
    if (getAppStatusResponse?.data?.agent_id) {
    }
  }, [getAppStatusResponse]);

  useEffect(() => {
    if (
      getCrmClientResponse?.data?.clientTransactions?.BUYING[0]?.requirement?.
        is_match_percentage_active
    ) {
      isMelRecommendedOn(
        getCrmClientResponse?.data?.clientTransactions?.BUYING[0]?.requirement
          .is_match_percentage_active === 0
          ? false
          : true
      );
    }
  }, [getCrmClientResponse]);

  useEffect(() => {
    if (fetchRecommendedPropertiesResponse?.data) {
      setDataSource(fetchRecommendedPropertiesResponse.data.recommendations);
    }
  }, [fetchRecommendedPropertiesResponse]);

  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters);
    if (newFilters.includes("Shortlisted") && newFilters.includes("Rejected")) {
      setLikeStatus([-1, 1]);
    } else if (newFilters.includes("Shortlisted")) {
      setLikeStatus([1]);
    } else if (newFilters.includes("Rejected")) {
      setLikeStatus([-1]);
    } else {
      setFilters(["Shortlisted", "Rejected"]);
      setLikeStatus([-1, 1, 0]);
    }
    setMenuVisible(false);
  };

  const handleMenuClick = ({ key }) => {
    const newFilters = filters.includes(key)
      ? filters.filter((f) => f !== key)
      : [...filters, key];
    setFilters(newFilters);
  };

  const handleDateFilterApply = () => {
    const payload = {
      clientId: clientId,
      reqId: reqId,
      likeStatus: likeStatus.join(","),
      date: sortOrder,
      startDate: fromDate ? fromDate.format("YYYY-MM-DD") : null,
      endDate: toDate ? toDate.format("YYYY-MM-DD") : null,
      limit: 3,
      skip: 0,
    };
    setCurrentPage(1);

    fetchRecommendedProperties(payload);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "-";
    const date = new Date(timestamp * 1000);
    const options = { month: "short", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleSortChange = () => {
    setSortOrder((prevOrder) =>
      prevOrder === "dateLatest" ? "dateOldest" : "dateLatest"
    );
  };

  const handleBulkRemoveProperty = () => {
    const payload = {
      recommendation_ids: selectedRowKeys,
    };
    removeBulkProperty({
      payload,
      callback: () => {
        setSelectedRowKeys([]);
        const payload = {
          clientId: clientId,
          reqId: reqId,
          likeStatus: likeStatus.join(","),
          date: sortOrder,
          startDate: fromDate ? fromDate.format("YYYY-MM-DD") : null,
          endDate: toDate ? toDate.format("YYYY-MM-DD") : null,
          limit: 3,
          skip: (currentPage - 1) * 3,
        };
        fetchRecommendedProperties(payload);
      },
    });
  };
  const handleAnalyticsClick = (record) => {
    setModalData(record);
    setIsModalVisible(true);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleRemoveFromList = () => {
    setIsConfirmationModalVisible(true);
  };

  const confirmRemoveFromList = async (data) => {
    const payload = {
      recommendation_ids: [data.id],
      // reason: [],
    };
    // rejectPropertyStatus({
    //   payload,
    //   callback: () => {
    //     const payload = {
    //       clientId: clientId,
    //       reqId: reqId,
    //       likeStatus: likeStatus.join(","),
    //       date: sortOrder,
    //       from: fromDate ? fromDate.format("YYYY-MM-DD") : undefined,
    //       to: toDate ? toDate.format("YYYY-MM-DD") : undefined,
    //       limit: 3,
    //       skip: (currentPage - 1) * 3,
    //     };
    //     setCurrentPage(1);
    //     setIsConfirmationModalVisible(false);
    //     setIsModalVisible(false);
    //     fetchRecommendedProperties(payload);
    //   },
    // });
    removeBulkProperty({
      payload,
      callback: () => {
        setSelectedRowKeys([]);
        const payload = {
          clientId: clientId,
          reqId: reqId,
          likeStatus: likeStatus.join(","),
          date: sortOrder,
          startDate: fromDate ? fromDate.format("YYYY-MM-DD") : null,
          endDate: toDate ? toDate.format("YYYY-MM-DD") : null,
          limit: 3,
          skip: (currentPage - 1) * 3,
        };
        setCurrentPage(1);
        setIsConfirmationModalVisible(false);
        setIsModalVisible(false);
        fetchRecommendedProperties(payload);
      },
    });
  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const renderStatus = (likeStatus) => {
    if (likeStatus === 1) {
      return "Shortlisted";
    } else if (likeStatus === -1) {
      return "Rejected";
    } else if (likeStatus === 0) {
      return "-";
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const columns = [
    {
      title: "Property",
      dataIndex: "address",
      key: "property",
      render: (_, record) => (
        <Space>
          <Tooltip
            title={`${record.address}, ${record.city}, ${record.state} ${record.zipcode}`}
          >
            <div className="property-details">
              <a
                href={record?.property_url || "#"}
                target="_blank"
                rel="noopener noreferrer"
                className="property-link"
                style={record?.property_url ? { } : {
                  cursor: "not-allowed",
                  pointerEvents: "none",
                }}
              >
                {[record.address, record.city, record.state, record.zipcode]
                  .filter(Boolean)
                  .join(", ")}
              </a>
              <div>
                {formatCurrencyWithoutDecimals(record?.list_price) || "-"}
              </div>
              <Typography.Text>
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex" }}>
                    <BedNumber>{record?.bedrooms || "-"}&nbsp;</BedNumber>
                    <Beds>bds</Beds>
                    <Dot></Dot>
                  </div>
                  <div style={{ display: "flex" }}>
                    <BedNumber>{record?.bathrooms || "-"}&nbsp;</BedNumber>
                    <Beds>ba</Beds>
                    <Dot></Dot>
                  </div>
                  <div style={{ display: "flex" }}>
                    <BedNumber>
                      {formatNumber(record?.area) || "-"}&nbsp;
                    </BedNumber>
                    <Beds>{record?.area_units || "-"}</Beds>
                  </div>
                </div>
              </Typography.Text>
            </div>
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "like_status",
      key: "status",
      render: (likeStatus) => (
        <StatusTag status={renderStatus(likeStatus)}>
          {renderStatus(likeStatus)}
        </StatusTag>
      ),
    },
    {
      title: <div className="sort-column">Recommended on</div>,
      dataIndex: "created_at",
      key: "recommended",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: () => ({
        onClick: handleSortChange,
      }),
      render: (timestamp) =>
        timestamp ? moment(timestamp * 1000).format("MMM D, YYYY") : "N/A",
    },
    {
      title: "Analytics",
      dataIndex: "analytics",
      key: "analytics",
      render: (_, record) => (
        <Button
          style={{ backgroundColor: "#5A5FF2", borderColor: "#5A5FF2" }}
          type="primary"
          icon={
            <img src={`${CLOUDFRONT}/Consumer-app/analytics.svg`} alt="error" />
          }
          onClick={() => handleAnalyticsClick(record)}
        />
      ),
    },
  ];

  const handleJoinWaitlist = () => {
    const payload = { app_type: "Individual" };
    joinWaitlist(payload);
  };

  //   useEffect(() => {
  //   if (joinWaitlistResponse?.data) {
  //     setIsWaitlistJoined(true);
  //   }
  // }, [joinWaitlistResponse]);

  const clearAllFilters = () => {
    const payload = {
      clientId: clientId,
      reqId: reqId,
      likeStatus: [-1, 1, 0].join(","),
      date: "dateLatest",
      startDate: null,
      endDate: null,
      limit: 3,
      skip: 0,
    };
    setCurrentPage(1);
    setFilters([]);
    setLikeStatus([-1, 1, 0]);
    setSortOrder("dateLatest");
    setFromDate(null);
    setToDate(null);
    setSelectedRowKeys([]);
    fetchRecommendedProperties(payload);
  };
  const formatCurrencyWithoutDecimals = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(amount);
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };
  const clubTimelineData = (timelineData) => {
    return timelineData.reduce((acc, item) => {
      const createdAt = moment(item.created_at * 1000).format("MMM D, YYYY"); // Group by formatted date
      if (!acc[createdAt]) {
        acc[createdAt] = [];
      }
      acc[createdAt].push(item);
      return acc;
    }, {});
  };

  const renderTimeline = (timelineData) => {
    const groupedData =
      modalData?.timeline?.length > 0
        ? clubTimelineData(modalData.timeline)
        : {};

    const handleTurnOnMelRec = () => {
      const payload = {
        clientId: clientId,
        reqId: reqId,
        deactivate: false,
      };
      isMelRecommendedOn(true);
      turnOnMelRecommendation(payload);
    };

    return (
      <>
        {Object.keys(getAppStatusResponse?.data || {}).length === 0 &&
          !joinWaitlistResponse?.data && (
            <WaitlistWrap>
              <ModalHeader>
                <Title>
                  Want to receive{" "}
                  <span style={{ color: "#ff9900" }}>personalized</span> property recommendations ?
                </Title>
                <SubTitle>Join the waitlist for early access</SubTitle>
              </ModalHeader>
              <FeaturesList>
                {/* Features */}
                <Feature>
                  <IconWrapper>
                    <img
                      className="points-icon"
                      src={`${CLOUDFRONT}/Consumer-app/paint.svg`}
                      alt=""
                    />
                  </IconWrapper>
                  <FeatureDetails>
                    <div className="feature-title">Custom branding</div>
                    <div className="feature-description">
                      Elevate your client experience with a private labeled app
                      tailored specifically for your clients.
                    </div>
                  </FeatureDetails>
                </Feature>
                <Feature>
                  <IconWrapper>
                    <img
                      className="points-icon"
                      src={`${CLOUDFRONT}/Consumer-app/Property-Search.svg`}
                      alt=""
                    />
                  </IconWrapper>
                  <FeatureDetails>
                    <div className="feature-title">
                      Collaborative search boards
                    </div>
                    <div className="feature-description">
                      Work together seamlessly with interactive boards to find
                      the perfect property faster.
                    </div>
                  </FeatureDetails>
                </Feature>
                <Feature>
                  <IconWrapper>
                    <img
                      className="points-icon"
                      src={`${CLOUDFRONT}/Consumer-app/Default.svg`}
                      alt=""
                    />
                  </IconWrapper>
                  <FeatureDetails>
                    <div className="feature-title">In-app communication</div>
                    <div className="feature-description">
                      Streamline your interactions with clients through
                      real-time messaging right in the app.
                    </div>
                  </FeatureDetails>
                </Feature>
                <Feature>
                  <IconWrapper>
                    <img
                      className="points-icon"
                      src={`${CLOUDFRONT}/Consumer-app/Vector.svg`}
                      alt=""
                    />
                  </IconWrapper>
                  <FeatureDetails>
                    <div className="feature-title">AI Assistance</div>
                    <div className="feature-description">
                      Leverage AI to receive personalized property
                      recommendations and insights.
                    </div>
                  </FeatureDetails>
                </Feature>
              </FeaturesList>
              <InviteClientButton onClick={handleJoinWaitlist}>
                Join the waitlist
              </InviteClientButton>
            </WaitlistWrap>
          )}
        {(joinWaitlistResponse?.data || getAppStatusResponse?.data?.status === 'Request Received') && (
          <NewContentContainer>
            <div className="image-container">
              <img
                src={`${CLOUDFRONT}/Consumer-app/graphics-waitlist.svg`}
                alt="New Design"
              />
            </div>
            <div className="title">You’re on the list!</div>
            <div className="description">
              Get ready to revolutionize your real estate business with your own
              private labeled, AI-powered app.
            </div>
            <div className="highlight">
              Currently #
              {joinWaitlistResponse?.data
                ? joinWaitlistResponse?.data?.wait_list
                : getAppStatusResponse?.data?.wait_list}{" "}
              in line
            </div>
            <a id="custom_intercom" className="questions-link">
              HAVE QUESTIONS? CONTACT US
            </a>
          </NewContentContainer>

        )}
        {getAppStatusResponse?.data?.status === "App Live" && (
          <>
            {!turnOnMelRecommendationResponse?.isFetching &&
              !isMelRecommendedData && (
                <div className="mel-rec-wrapper">
                  <div className="">
                    <div className="" style={{ display: "flex" }}>
                      <img
                        src={`${CLOUDFRONT}/Consumer-app/24px.svg`}
                        alt="mel-icon"
                      />
                      <GradientText>Mel</GradientText>
                    </div>
                    <p className="turn-on-title">
                      Turn on personalized recommendations
                    </p>
                    <p className="turn-on-desc">
                      Help clients find their perfect property faster.
                    </p>
                    <a className="turn-on-btn" onClick={handleTurnOnMelRec}>
                      Turn On
                    </a>
                  </div>
                  <div className="">
                    <img
                      src={`${CLOUDFRONT}/Consumer-app/house.svg`}
                      alt="house-icon"
                    />
                  </div>
                </div>
              )}
            {turnOnMelRecommendationResponse?.isFetching && (
              <div>
                <Lottie options={defaultOptions} height={56} width={56} />
                <p className="curating-recc">
                  {isMelRecommendedData
                    ? "Curating Recommendations..."
                    : "Turning off recommendations"}
                </p>
                <p className="curating-rec-desc">
                  {isMelRecommendedData
                    ? " Recommended properties will appear here shortly."
                    : "You can turn on recommendation later"}
                </p>
              </div>
            )}

            <div>
              {fetchRecommendedPropertiesResponse?.data && (
                <FiltersContainer>
                  <div
                    className="filter-separator"
                    style={{ fontSize: "16px" }}
                  >
                    {fetchRecommendedPropertiesResponse?.data
                      ?.total_properties || 0}{" "}
                    properties
                  </div>
                  <div className="filter-separator">
                    Filters: &nbsp;
                    <Space style={{ marginRight: "8px" }}>
                      <Dropdown
                        overlay={
                          <Menu onClick={handleMenuClick}>
                            <Menu.Item key="Shortlisted">
                              <Checkbox
                                style={{ fontSize: "14px" }}
                                checked={filters.includes("Shortlisted")}
                              >
                                Shortlisted
                              </Checkbox>
                            </Menu.Item>
                            <Menu.Item key="Rejected">
                              <Checkbox
                                style={{ fontSize: "14px" }}
                                checked={filters.includes("Rejected")}
                              >
                                Rejected
                              </Checkbox>
                            </Menu.Item>
                            <Menu.Divider />
                            <Menu.Item key="apply-status">
                              <Button
                                type="primary"
                                className="apply-btn"
                                onClick={({ key }) => {
                                  const newFilters = filters.includes(key)
                                    ? filters.filter((f) => f !== key)
                                    : [...filters, key];
                                  handleApplyFilters(newFilters);
                                }}
                              >
                                Apply
                              </Button>
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={["click"]}
                        visible={menuVisible}
                        onVisibleChange={(visible) => setMenuVisible(visible)}
                      >
                        <Badge
                          dot={["[-1]", "[1]", "[0]", "[-1,1]"].includes(
                            JSON.stringify(likeStatus)
                          )}
                          style={{
                            backgroundColor: "#f5222d",
                            marginTop: "7px",
                          }}
                        >
                          <FilterButton>
                            Status <DownOutlined />
                          </FilterButton>
                        </Badge>
                      </Dropdown>
                    </Space>
                    <Space>
                      <Dropdown
                        overlay={
                          <>
                            <div className="date-wrapper">
                              <DatePicker
                                placeholder="From"
                                value={fromDate}
                                onChange={(date) => setFromDate(date)}
                                style={{
                                  width: "180px",
                                  marginBottom: "8px",
                                  cursor: "pointer",
                                }}
                              />
                              <DatePicker
                                placeholder="To"
                                value={toDate}
                                onChange={(date) => setToDate(date)}
                                style={{
                                  width: "180px",
                                  marginBottom: "8px",
                                  cursor: "pointer",
                                }}
                              />
                              <Button
                                type="primary"
                                className="apply-btn"
                                onClick={handleDateFilterApply}
                              >
                                Apply
                              </Button>
                            </div>
                          </>
                        }
                        trigger={["click"]}
                      >
                        <Badge
                          dot={fromDate && toDate}
                          style={{
                            backgroundColor: "#f5222d",
                            marginTop: "7px",
                          }}
                        >
                          <FilterButton>
                            Date <DownOutlined />
                          </FilterButton>
                        </Badge>
                      </Dropdown>
                    </Space>
                    <a className="clear-btn" onClick={clearAllFilters}>
                      Clear
                    </a>
                  </div>
                </FiltersContainer>
              )}

              {dataSource.length > 0 ||
                fetchRecommendedPropertiesResponse?.isFetching ? (
                <Table
                  columns={columns}
                  rowSelection={rowSelection}
                  rowKey="id"
                  style={{ marginBottom: "35px" }}
                  dataSource={dataSource}
                  loading={fetchRecommendedPropertiesResponse?.isFetching}
                  pagination={{
                    position: ["bottomCenter"],
                    defaultPageSize: 3,
                    hideOnSinglePage: true,
                    showSizeChanger: false,
                    total:
                      fetchRecommendedPropertiesResponse?.data
                        ?.total_properties || 0,
                    onChange: handlePageChange,
                  }}
                />
              ) : (
                <img
                  className="empty"
                  src={`${CLOUDFRONT}/Consumer-app/no-properties.svg`}
                  alt="no-properties"
                />
              )}
              {selectedRowKeys.length > 0 && (
                <OuterWrap>
                  <InnerText>
                    <p>{selectedRowKeys.length} properties selected</p>
                  </InnerText>
                  <FilterButton
                    disabled={false}
                    className=""
                    onClick={handleBulkRemoveProperty}
                    loading={false}
                  >
                    <DeleteOutlined />
                    {removeBulkPropertyResponse?.isFetching
                      ? "Processing.. "
                      : "Remove from list"}
                  </FilterButton>
                </OuterWrap>
              )}
              <Modal
                title={
                  modalData && (
                    <Space
                      style={{
                        borderBottom: "2px solid #EFEFEF",
                        paddingTop: "10px",
                      }}
                    >
                      {modalData?.media_urls &&
                        modalData?.media_urls.length > 0 ? (
                        <img
                          src={modalData.media_urls[0]}
                          alt="Property"
                          className="modal-image"
                        />
                      ) : (
                        <div className="no-image">
                          <Typography.Text type="secondary">
                            No Image
                          </Typography.Text>
                        </div>
                      )}
                      <div>
                        <Typography.Title
                          level={5}
                          className="modal-custom-title"
                          onClick={() => {
                            if (modalData?.property_url) {
                              window.open(modalData.property_url, "_blank");
                            }
                          }}
                        >
                          {[
                            modalData.address,
                            modalData.city,
                            modalData.state,
                            modalData.zipcode,
                          ]
                            .filter(Boolean)
                            .join(", ")}
                        </Typography.Title>
                        <Typography.Text>
                          {formatCurrencyWithoutDecimals(
                            modalData?.list_price
                          ) || "-"}
                        </Typography.Text>
                        <Typography.Text>
                          <div style={{ display: "flex", marginTop: "5px" }}>
                            <div style={{ display: "flex" }}>
                              <BedNumber>
                                {modalData?.bedrooms || "-"}&nbsp;
                              </BedNumber>
                              <Beds>bds</Beds>
                              <Dot></Dot>
                            </div>
                            <div style={{ display: "flex" }}>
                              <BedNumber>
                                {modalData?.bathrooms || "-"}&nbsp;
                              </BedNumber>
                              <Beds>ba</Beds>
                              <Dot></Dot>
                            </div>
                            <div style={{ display: "flex" }}>
                              <BedNumber>
                                {formatNumber(modalData?.area) || "-"}&nbsp;
                              </BedNumber>
                              <Beds>{modalData?.area_units || "-"}</Beds>
                            </div>
                          </div>
                        </Typography.Text>
                      </div>
                      <RightOutlined
                        onClick={() => {
                          if (modalData?.property_url) {
                            window.open(modalData.property_url, "_blank");
                          }
                        }}
                        style={{ color: "#7D7DA9" }}
                      />
                    </Space>
                  )
                }
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={
                  modalData ? (
                    <Button
                      className="remove-btn"
                      key="remove"
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => handleRemoveFromList(modalData)}
                    >
                      Remove from list
                    </Button>
                  ) : null
                }
                width={480}
              >
                <Tabs
                  defaultActiveKey="overview"
                  style={{ marginTop: "-40px" }}
                >
                  <Tabs.TabPane tab="OVERVIEW" key="overview">
                    <div className="overview-wrapper">
                      <div className="">
                        <div className="overview-block">
                          <p>STATUS</p>
                          <span>{renderStatus(modalData?.like_status)}</span>
                        </div>
                        <div className="overview-block">
                          <p>VIEWS</p>
                          <span>{modalData?.views || "-"}</span>
                        </div>
                      </div>
                      <div className="">
                        <div className="overview-block">
                          <p>SHORTLISTED ON</p>
                          <span>
                            {" "}
                            {formatTimestamp(
                              modalData?.last_status_update_on
                                ? modalData.last_status_update_on
                                : null
                            ) || "-"}
                          </span>
                        </div>
                        <div className="overview-block">
                          <p>RECOMMENDED ON</p>
                          <span>
                            {" "}
                            {formatTimestamp(modalData?.created_at) || "-"}
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                    {modalData?.remarks?.length > 0 && (
                      <div className="">
                        <div className="overview-block">
                          <p>Reason</p>
                          <ul
                            style={{
                              listStyleType: "disc",
                              paddingLeft: "20px",
                            }}
                          >
                            {modalData.remarks.map((remark, index) => (
                              <li key={index}>{remark}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="TIMELINE" key="timeline">
                    {modalData &&
                      modalData?.timeline &&
                      modalData?.timeline?.length > 0 ? (
                      <Timeline mode="left">
                        {Object.keys(groupedData).map((createdAt, index) => {
                          const items = groupedData[createdAt];

                          return (
                            <Timeline.Item
                              key={index}
                              label={
                                <span className="timeline-item-label">
                                  {createdAt}
                                </span>
                              }
                            >
                              {items.map((item, itemIndex) => (
                                <div
                                  key={itemIndex}
                                  className={
                                    item.text === "Added to Shortlist"
                                      ? "timeline-shortlist"
                                      : "timeline-item-content"
                                  }
                                >
                                  {item.text === "Added to Shortlist" && (
                                    <div className="shortlisted">
                                      <HeartOutlined
                                        style={{ marginLeft: "6px" }}
                                      />
                                    </div>
                                  )}
                                  <span style={{ width: "200px" }}>
                                    {item.text}
                                  </span>
                                  <span className="timeline-time">
                                    {moment(item.created_at * 1000)
                                      .format("h:mmA")
                                      .toLowerCase()}
                                  </span>
                                </div>
                              ))}
                            </Timeline.Item>
                          );
                        })}
                      </Timeline>
                    ) : (
                      <>
                        <div className="no-timeline-data">
                          <HistoryOutlined
                            style={{
                              color: "#7F82AB",
                              height: "25px",
                              width: "25px",
                            }}
                          />
                          <p className="">No client activity yet</p>
                          <p className="">
                            Client interactions will appear here as soon as they
                            engage with the property.
                          </p>
                        </div>
                      </>
                    )}
                  </Tabs.TabPane>
                </Tabs>
              </Modal>
              <Modal
                title=""
                width={420}
                visible={isConfirmationModalVisible}
                onOk={() => confirmRemoveFromList(modalData)}
                onCancel={() => setIsConfirmationModalVisible(false)}
                okText="Yes"
                okType="danger"
                cancelText="Cancel"
                centered
                okButtonProps={{
                  style: {
                    backgroundColor: "#F05152",
                    height: "48px",
                    fontSize: "16px",
                    borderColor: "#F05152",
                    color: "#fff",
                    flex: 1,
                    borderRadius: "32px",
                  },
                }}
                cancelButtonProps={{
                  style: {
                    backgroundColor: "#fff",
                    height: "48px",
                    fontSize: "16px",
                    borderColor: "#E0E0EB",
                    color: "#232338",
                    flex: 1,
                    borderRadius: "32px",
                  },
                }}
              >
                <p className="remove-property-title">
                  Remove Property Recommendation
                </p>
                <p className="remove-desc">
                  Are you sure you want to remove this property recommendation?
                  This action cannot be undone.
                </p>
              </Modal>
            </div>
          </>
        )}
      </>
    );
  };

  return <>{renderTimeline(modalData ? modalData.timeline : [])}</>;
};

export default CRMV2Container(ConsumerAppContainer(RecommendedPropertiesTable));
