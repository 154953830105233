import React, { useState, useRef, useEffect } from "react"
import CRMV2Container from "container/CRM"
import { get } from "lodash"
import {
  Form,
  Space,
  NewGoogleAutocompleteSingle,
  NewInputNumber,
  NewRadioButton,
  NewRadioGroup,
  NewButton,
  NewSelect,
  Select,
} from "@ui/antd"
import styled from "styled-components"
import { LeadSourceWrap, StyledLabel } from "../../../common"
import { SectionHeading } from "../../styles"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"

const { Item } = Form
const { Option } = NewSelect

const UpdatePropertyRequirementForm = ({
  getPropertyTypeResponse,
  initialValues,
  transactionId,
  updatePropertyDetails,
  agent_id,
  toggleUserOverview,
  clientId,
  currentFilters,
}) => {
  const [cityFormValue, setCityFormValue] = useState([])
  const {
    resale_price, no_of_bed_room, no_of_bath_room, area_coverage, rent_resale,
    property_type, updated_at, covered_area, city, state, country, basic_id,
    prop_id, u_cust_id, address, sale_price,
  } = initialValues || {}
  const propertyAddress = `${address}, ${city}, ${state}, USA`
  const { data: propertyTypes, isFetching: propertyTypeFetching } = getPropertyTypeResponse || {}
  const { types } = propertyTypes || []

  const formRef = useRef(null)
  const [isDisabled, setDisabled] = useState(true)
  const [transactionType, setTransactionType] = useState("resale")
  const onTransactionTypeChange = () => {
    setDisabled(false)
  }

  const [metric, setMetric] = useState("Sq. ft")
  const onMetricChange = () => {
    setDisabled(false)
  }

  const onValueChange = () => {
    setDisabled(false)
  }

  const setStreet = (location, key) => {
    setDisabled(false)
    const locationDetails = {
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      // street: get(location, "street_number.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
      // address: get(location, "address"),
      streetName: get(location, "street_number.short_name", ""),
      route: get(location, "route.long_name", ""),
      locality: get(location, "locality.long_name"),
      zipcode: get(location, "postal_code.long_name")
    }
    locationDetails.address = `${locationDetails.streetName} ${locationDetails.route}`
    const currentFieldValues = formRef.current.getFieldsValue();
    const shortCountry = get(location, "country.short_name")
    const shortState = get(location, "administrative_area_level_1.short_name")
    
    let neighborhood = null
    if (location.neighborhood) {
      neighborhood = location.neighborhood.long_name
    } else if (location.route) {
      neighborhood = location.route.long_name
    } else if (location.postal_code) {
      neighborhood = location.postal_code.long_name
    } else if (location.sublocality_level_1) {
      neighborhood = location.sublocality_level_1.long_name
    }

    if (locationDetails.address) {
      currentFieldValues.listing_address = `${locationDetails.streetName} ${locationDetails.route}, ${locationDetails.locality}, ${shortState}, ${shortCountry}`
    } else if (neighborhood) {
      currentFieldValues.listing_address = `${neighborhood}, ${locationDetails.city}, ${locationDetails.state}, ${shortCountry}`
    } else {
      currentFieldValues.listing_address = `${locationDetails.city}, ${locationDetails.state}, ${shortCountry}`
    }
    setCityFormValue(locationDetails)
    formRef.current.setFieldsValue({ ...currentFieldValues })
  }

  const onFinish = fieldValues => {
    updatePropertyDetails({
      propertyId: basic_id,
      payload: fieldValues,
      agent_id,
      cityFormValue,
      id: prop_id,
      clientId,
      currentFilters,
    })
    setDisabled(true)
  }
  return (
    <>
      <SectionHeading>Listing Details</SectionHeading>

      <Form
        name="update_property_req"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        ref={formRef}
      >
        <LeadSourceWrap width="100%" style={{ marginBottom: 0 }}>
          <Space direction="vertical" size={16} style={{ width: "100%" }}>
            {/* <UploadImage
              action={uploadImages}
              listType="picture-card"
              fileList={fileList}
              onChange={onChange}
              // onPreview={onPreview}
              disabled={isUploadingFile === true}
            >
              {fileList.length < 20 && "+ Upload"}
            </UploadImage> */}
            <div style={{ display: "flex", width: "100%", alignItems: "center", gap: "8px" }}>
              <StyledLabel>Transaction Type</StyledLabel>
              <Item
                name="sale_type"
                rules={[{ required: true }]}
                initialValue={rent_resale}
                style={{ marginBottom: 0, width: "auto" }}
              >
                <NewRadioGroup
                  onChange={onTransactionTypeChange}
                  value={transactionType}
                  size="small"
                >
                  <NewRadioButton value="resale">Sale</NewRadioButton>
                  <NewRadioButton value="rent">Rent</NewRadioButton>
                </NewRadioGroup>
              </Item>
            </div>

            <Space align="baseline" style={{ width: "100%" }}>
              <Item
                name="no_of_bed_room"
                rules={[{ required: true, message: "Missing", type: "number" }]}
                initialValue={no_of_bed_room}
                onChange={onValueChange}
                style={{ marginBottom: 0 }}
                label={<LabelComponent text="Beds" />}
              >
                <Select
                        placeholder="Beds"
                        style={{ border: "1px solid #D0D5DD", borderRadius: "8px", boxShadow: "none" }}
                        defaultValue={0}
                        options={[
                          {value: 0, label: 'Any'},
                          { value: -1, label: 'Studio' },
                          { value: 1, label: '1' },
                          { value: 2, label: '2' },
                          { value: 3, label: '3' },
                          { value: 4, label: '4' },
                          { value: 5, label: '5' },
                        ]}
                      />
                {/* <NewInputNumber onChange={onValueChange} min={1} placeholder="Beds" /> */}
              </Item>
              <Item
                name="no_of_bath_room"
                rules={[{ required: true, message: "Missing", type: "number" }]}
                initialValue={no_of_bath_room}
                onChange={onValueChange}
                style={{ marginBottom: 0 }}
                label={<LabelComponent text="Baths" />}

              >
                {/* <NewInputNumber onChange={onValueChange} min={1} placeholder="Baths" /> */}
                <Select
                        placeholder="Baths"
                        style={{ border: "1px solid #D0D5DD", borderRadius: "8px", boxShadow: "none" }}
                        defaultValue={0}
                        options={[
                          {value: 0, label: 'Any'},
                          { value: 1, label: '1' },
                          { value: 1.5, label: '1.5' },
                          { value: 2, label: '2' },
                          { value: 3, label: '3' },
                          { value: 4, label: '4' },
                          { value: 5, label: '5' },
                        ]}
                      />
              </Item>
            </Space>
            <Space align="baseline" style={{ width: "100%" }}>
              <Item
                name="listing_address"
                rules={[{ required: true, message: 'Missing', }]}
                initialValue={propertyAddress}
                style={{ marginBottom: 0 }}
                label={<LabelComponent text="Listing Address" />}
              >
                <NewGoogleAutocompleteSingle
                  id="cities"
                  types={["address"]}
                  name="cities"
                  // formValue={cityFormValue}
                  onChange={e => {}}
                  location={loc => {
                    setStreet(loc)
                  }}
                  placeholder="Listing Address"
                  styles={{
                    height: "49px",
                    borderRadius: "0px",
                    background: "white",
                    fontSize: "16px",
                    marginTop: "10px",
                  }}
                  restrict={{
                    lat: 37.09024,
                    lng: -95.712891,
                    south_west: {
                      lat: 25.82,
                      lng: -124.39,
                    },
                    north_east: {
                      lat: 49.38,
                      lng: -66.94,
                    },
                    address: "United States",
                    country: {
                      long_name: "United States",
                      short_name: "US",
                      types: ["country", "political"],
                    },
                  }}
                />
              </Item>
            </Space>
            {sale_price && sale_price > 0 ? (
              <Space align="baseline" style={{ width: "100%" }}>
                <Item
                  name="sale_price"
                  rules={[{ required: true, message: "Only numbers allowed" }]}
                  initialValue={sale_price}
                  onChange={onValueChange}
                  style={{ marginBottom: 0 }}
                  label={<LabelComponent text="Sale Price" />}
                >
                  <NewInputNumber
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    onChange={onValueChange}
                    placeholder="Sale Price"
                  />
                </Item>
              </Space>
            ) : (
              <Space align="baseline" style={{ width: "100%" }}>
                <Item
                  name="resale_price"
                  rules={[{ required: true, message: "Only numbers allowed" }]}
                  initialValue={resale_price}
                  onChange={onValueChange}
                    style={{ marginBottom: 0 }}
                    label={<LabelComponent text="List Price" />}
                  >
                  <NewInputNumber
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    onChange={onValueChange}
                    placeholder="List Price"
                  />
                </Item>
              </Space>
            )}
            <Space align="baseline" style={{ width: "100%" }}>
              <Item
                name="covered_area"
                rules={[{ required: true, message: "Only numbers allowed" }]}
                initialValue={covered_area}
                onChange={onValueChange}
                style={{ marginBottom: 0 }}
                label={<LabelComponent text="Property Size" />}
              >
                <NewInputNumber
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  onChange={onValueChange}
                  placeholder="Property Size"
                />
              </Item>
            </Space>
            <div style={{ display: "flex", width: "100%", alignItems: "center", gap: "8px" }}>
            <Item
              name="area_coverage"
              rules={[{ required: true }]}
              initialValue={area_coverage}
              style={{ marginBottom: 0, width: "auto" }}
            >
              <NewRadioGroup onChange={onMetricChange} value={metric} size="small">
                <NewRadioButton value="Sq.ft">Sq. ft</NewRadioButton>
                <NewRadioButton value="Sq.mt">Sq.mt</NewRadioButton>
                <NewRadioButton value="Acres">Acres</NewRadioButton>
              </NewRadioGroup>
            </Item>
            </div>
            

            <Space align="baseline" style={{ width: "100%", marginBottom: 8 }}>
              {!propertyTypeFetching ? (
                <Item
                  name="property_type"
                  rules={[{ required: true }]}
                  initialValue={property_type}
                  label={<LabelComponent text="Property Type" />}
                  style={{ marginBottom: 0 }}
                >
                  <NewSelect placeholder="Property Type..." onChange={onValueChange}>
                    {types && types.map(type => <Option value={type.value}>{type.value}</Option>)}
                  </NewSelect>
                </Item>
              ) : (
                <Item
                  name="property_type"
                  rules={[{ required: true }]}
                  initialValue={property_type}
                    style={{ marginBottom: 0 }}
                    label={<LabelComponent text="Property Type" />}
                >
                  <NewSelect placeholder="Property Type..." onChange={onValueChange}>
                    Options Loading...
                  </NewSelect>
                </Item>
              )}
            </Space>

            <Item style={{ marginBottom: 0 }}>
              <NewButton type="primary" htmlType="submit" disabled={isDisabled}>
                Save Changes
              </NewButton>
            </Item>
          </Space>
        </LeadSourceWrap>
      </Form>
    </>
  )
}

export default CRMV2Container(UpdatePropertyRequirementForm)
