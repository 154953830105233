import { handleDeltaReducer, createDeltaReducer } from "shared/reduxUtils"
import * as NavbarActions from "./actions"
import cookie from "services/CookieStorage"

const NOTIFICATIONVIEWS = "notificationsViewedResponse"

const initState = {
  isNotificationFetched: false,
  isStartNavbarSpotlightFlow: false,
  isOpenPressReleaseBanner: false,
  planAvailableObject: [],
  paymentObject: [],
  isLoadingCard: false,
  ...createDeltaReducer(NOTIFICATIONVIEWS),
  ...createDeltaReducer("getSubscriptionPlansResponse"),
  ...createDeltaReducer("addSubscriptionPlanResponse"),
  ...createDeltaReducer("fetchPaymentResponse"),
  ...createDeltaReducer("editPaymentResponse"),
  ...createDeltaReducer("deletePaymentResponse"),
  ...createDeltaReducer("defaultPaymentResponse"),
  ...createDeltaReducer("fetchAddOnsResponse"),
  ...createDeltaReducer("updateSubscriptionResponse"),
  ...createDeltaReducer("fetchInvoiceDataResponse"),
  ...createDeltaReducer("makeDefaultMethodResponse"),
}

export default function navbarReducer(state = initState, action) {
  switch (action.type) {
    // Notification
    case NavbarActions.fetchNotificationAction.REQUEST: {
      return {
        ...state,
        isFetchingNotifications: true,
      }
    }
    case NavbarActions.fetchNotificationAction.FAILURE:
    case NavbarActions.fetchNotificationAction.SUCCESS: {
      const { notification } = action.data
      return {
        ...state,
        notifications: notification,
        isFetchingNotifications: false,
      }
    }

    case NavbarActions.notificationViewedAction.REQUEST:
    case NavbarActions.notificationViewedAction.SUCCESS:
    case NavbarActions.notificationViewedAction.CLEAR:
    case NavbarActions.notificationViewedAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, NavbarActions.notificationViewedAction, NOTIFICATIONVIEWS),
      }
    }

    // Unread Notification
    case NavbarActions.fetchNotificationUnreadCountAction.REQUEST: {
      return {
        ...state,
        isFetchingUnreadNotification: true,
      }
    }
    case NavbarActions.fetchNotificationUnreadCountAction.FAILURE:
    case NavbarActions.fetchNotificationUnreadCountAction.SUCCESS: {
      const { unread } = action.data
      return {
        ...state,
        unreadCount: unread,
        isFetchingUnreadNotification: false,
      }
    }

    case NavbarActions.startTabsSpotlightFlowAction.type: {
      return {
        ...state,
        isStartNavbarSpotlightFlow: true,
      }
    }

    case NavbarActions.togglePressReleaseBannerAction.type: {
      const { isOpen } = action.data
      return {
        ...state,
        isOpenPressReleaseBanner: isOpen,
      }
    }

    case NavbarActions.getSubscriptionPlansAction.REQUEST:
    case NavbarActions.getSubscriptionPlansAction.SUCCESS:
    case NavbarActions.getSubscriptionPlansAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, NavbarActions.getSubscriptionPlansAction, "getSubscriptionPlansResponse"),
      }
    }

    case NavbarActions.fetchPaymentAction.REQUEST:
    case NavbarActions.fetchPaymentAction.SUCCESS:
    case NavbarActions.fetchPaymentAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, NavbarActions.fetchPaymentAction, "fetchPaymentResponse"),
      }
    }

    case NavbarActions.editPaymentAction.REQUEST:
    case NavbarActions.editPaymentAction.SUCCESS:
    case NavbarActions.editPaymentAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, NavbarActions.editPaymentAction, "editPaymentResponse"),
      }
    }

    case NavbarActions.deletePaymentAction.REQUEST:
    case NavbarActions.deletePaymentAction.SUCCESS:
    case NavbarActions.deletePaymentAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, NavbarActions.deletePaymentAction, "deletePaymentResponse"),
      }
    }

    case NavbarActions.defaultPaymentAction.REQUEST:
    case NavbarActions.defaultPaymentAction.SUCCESS:
    case NavbarActions.defaultPaymentAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, NavbarActions.defaultPaymentAction, "defaultPaymentResponse"),
      }
    }

    case NavbarActions.fetchAddOnsAction.REQUEST:
    case NavbarActions.fetchAddOnsAction.SUCCESS:
    case NavbarActions.fetchAddOnsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, NavbarActions.fetchAddOnsAction, "fetchAddOnsResponse"),
      }
    }

    case NavbarActions.updateSubscriptionAction.REQUEST:
    case NavbarActions.updateSubscriptionAction.SUCCESS:
    case NavbarActions.updateSubscriptionAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, NavbarActions.updateSubscriptionAction, "updateSubscriptionResponse"),
      }
    }

    case NavbarActions.fetchInvoiceDataAction.REQUEST:
    case NavbarActions.fetchInvoiceDataAction.SUCCESS:
    case NavbarActions.fetchInvoiceDataAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, NavbarActions.fetchInvoiceDataAction, "fetchInvoiceDataResponse"),
      }
    }

    case NavbarActions.addSubscriptionPlanAction.REQUEST:
    case NavbarActions.addSubscriptionPlanAction.SUCCESS:
    case NavbarActions.addSubscriptionPlanAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, NavbarActions.addSubscriptionPlanAction, "addSubscriptionPlanResponse"),
      }
    }

    case NavbarActions.makeDefaultMethodAction.REQUEST:
    case NavbarActions.makeDefaultMethodAction.SUCCESS:
    case NavbarActions.makeDefaultMethodAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, NavbarActions.makeDefaultMethodAction, "makeDefaultMethodResponse"),
      }
    }

    case NavbarActions.setPlanAvailableObjectAction.type: {
      return {
        ...state,
        planAvailableObject: action.data,
      }
    }



    case NavbarActions.setPaymentObjectAction.type: {
      return {
        ...state,
        paymentObject: action.data,
      }
    }

    case NavbarActions.setLoadingCardAction.type: {
      return {
        ...state,
        isLoadingCard: action.data,
      }
    }

    default:
      return { ...state }
  }
}
