import React from "react"
import Button from "@ui/Button"
import styled from "styled-components"

const ModalWrap = styled.div`
  height: 117px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  h3 {
    font-family: Butler, sans-serif;
    font-size: 28px;
    font-weight: 400;
    text-align: left;
  }
`

const ModalButtons = styled.div`
  display: flex;
  flex-direction: column;
  flex-direction: row;
  gap: 15px;
`

const CancelScheduledRoomsModal = ({
  onCancel,
  endRoom,
}) => {
  const title = "Do you want to delete this room?"
  const buttonText = "Delete Room"

  return (
    <ModalWrap>
      <h3>
        {title}
      </h3>
      <ModalButtons>
        <Button
          bsStyle="attention"
          style={{
            borderRadius: "18px",
            fontWeight: "300",
          }}
          onClick={endRoom}
        >
          {buttonText}
        </Button>
        <Button
          bsStyle="marineBLueOutlined"
          style={{
            borderRadius: "18px",
            width: "200px",
            fontWeight: "300",
            border: "1.5px solid #5A5FF2",
          }}
          onClick={onCancel}
        >
          Exit
        </Button>
      </ModalButtons>
    </ModalWrap>
  )
}

export default CancelScheduledRoomsModal
