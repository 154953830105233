import styled from "styled-components"
import { ONBOARDING_BANNER_HEIGHT } from "@consts"
import { ATC, INTER, MONTSERRAT } from "@fonts"
import { Input } from 'antd'
import { InfoCircleOutlined } from "@ant-design/icons"
export const ModalWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;
`
export const CreditHeadingWrap = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 8px;
align-self: stretch;
${props => props.width && `
    width: 50%; 
`}
`
export const SuccessHeadingWrap = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 8px;
align-self: stretch;
`

export const CreditHeading = styled.div`
color: var(--Neutral-900, #111827);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 28px;
`

export const CreditImage = styled.div`
display: flex;
width: 48px;
height: 48px;
padding: 8px;
justify-content: center;
align-items: center;
border-radius: 24px;
background: var(--Primary-50, #EEF2FF);
`
export const CancelledCardImage = styled.div`
display: flex;
width: 36px;
height: 36px;
padding: 6px;
justify-content: center;
align-items: center;
border-radius: 30px;
background: var(--Primary-50, #EEF2FF);
`
export const SuccessImage = styled.div`
display: flex;
width: 48px;
height: 48px;
padding: 8px;
justify-content: center;
align-items: center;
border-radius: 24px;
background: var(--Primary-50, #E7F5F1);
`
export const BillingWrapper = styled.div`
display: flex;
padding: 16px 24px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;
border-radius: 8px;
border: 1px solid var(--Global-Mapping-Gray-100, #E0E0EB);
background: var(--Global-Mapping-White, #FDFDFD);
`
export const CreditDetailsWrap = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 8px;
align-self: stretch;
width: ${props => props.width || "100%"}
`
export const CreditDetailsLabel = styled.div`
color: var(--Neutral-900, #111827);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px; /* 114.286% */
`
export const CreditExpCVVWrap = styled.div`
display: flex;
justify-content: space-between;
gap: 20px;
align-items: flex-start;
align-self: stretch;
`

export const CommonText = styled.div`
color: ${props => props.color ? props.color : "#6B7280"}
align-self: stretch;
font-family: Inter;
font-size: ${props => props.fontSize ? props.fontSize : "14px"}
font-style: normal;
font-weight: ${props => props.weight ? props.weight : 400}
${props => props.lineHeigth && `
    line-height: 24px; 
`}
${props => props.marginLeft && `
   margin-left: auto;
`}
`
export const ButtonWrap = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
gap: 12px;
align-self: stretch;
margin-top: 32px;
`
export const CancelButton = styled.button`
${props => props.width && `
    width: 100%
`}
display: flex;
padding: 10px 16px;
justify-content: center;
align-items: center;
border-radius: 8px;
border: 1px solid var(--Neutral-200, #E5E7EB);
background: var(--Generic-White, #FFF);
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
color: var(--Neutral-700, #374151);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px; 
`

export const DeleteButton = styled.button`
display: flex;
padding: 10px 16px;
justify-content: center;
align-items: center;
gap: 6px;
border-radius: 8px;
border: 1px solid var(--Color-Red-Red-500, #F05152);
background: var(--Color-Red-Red-500, #F05152);
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
color: var(--Generic-White, #FFF);
text-align: justify;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px; /* 114.286% */
`
export const CreditProceedButton = styled.button`
${props => props.width && `
    width: 50%
`}
display: flex;
cursor: pointer;
padding: 10px 16px;
justify-content: center;
align-items: center;
gap: 6px;
border-radius: 8px;
border: 1px solid var(--primary-500-main, #6366F1);
background: var(--primary-500-main, #6366F1);
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
p {
color: var(--Generic-White, #FFF);
text-align: justify;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px; 
margin: 0px
}
`
export const CreditCancelButton = styled.button`
${props => props.width && `
    width: 50%
`}
display: flex;
padding: 10px 16px;
justify-content: center;
align-items: center;
gap: 6px;
border-radius: 8px;
border: 1px solid var(--Destructive-200, #FECACA);
background: var(--Generic-White, #FFF);
box-shadow: 0px 2px 6px 0px rgba(16, 24, 40, 0.06);
p {
color: #EF4444
text-align: justify;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px; 
margin: 0px
}
`

export const RadioDiv = styled.div`
display: flex;
padding: 4px;
align-items: center;
gap: 16px;
border-radius: 6px;
background: #F4F4F4;

@media (max-width: 480px) {
  flex-direction: column;
  width: 100%;
  gap: 5px;
}
`
export const ToggleButtonWrap = styled.button`
display: flex;
padding: 8px 24px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
background: #F4F4F4;
color: var(--Neutral-700, #374151);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
border: none;
outline: none;
line-height: 16px; /* 114.286% */

@media (max-width: 480px) {
  width: 100%;
}
`
export const ActiveButton = styled.button`
display: flex;
padding: 8px 24px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 1px solid var(--Global-Mapping-Primary, #5A5FF2);
background: var(--Generic-White, #FFF);
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
color: var(--Primary-600, #4F46E5);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px; /* 114.286% */

@media (max-width: 480px) {
  width: 100%;
}
`
export const InfoWrap = styled.div`
display: flex;
padding: 16px 24px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;
border-radius: 8px;
border: 1px solid var(--Global-Mapping-Gray-100, #E0E0EB);
background: var(--Global-Mapping-White, #FDFDFD);
`
export const AnalyticCard = styled.div`
display: flex;
width: 150px;
padding: 16px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 16px;
border-radius: 8px;
border: 1px solid var(--Global-Mapping-Gray-50, #EFEFF4);
background: var(--Generic-White, #FFF);
box-shadow: 0px 2px 6px 0px rgba(16, 24, 40, 0.06);
`

export const YellowInfo = styled.div`
display: flex;
padding: 16px;
justify-content: space-between;
align-items: center;
align-self: stretch;
border-radius: 8px;
background: var(--Warning-100, #FEF3C7);
`

export const YellowInfoElement = styled(InfoCircleOutlined)`
  position: relative;
  margin-top: 6px;
  width: 20px;
  color: #92400E;
`