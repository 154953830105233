import React from "react"
import styled from "styled-components"
import LazyLoad from "react-lazyload"
import root from "window-or-global"
import Button from "@ui/Button"
import Webinar from "dumbComponents/NewLandingPage/images/webinar.png"
import colors from "@colors"
import { HIGHLIGHT_FONT, ATC } from "@fonts"

const RadiusInfoWrapper = styled.div`
  display: flex;
  background: ${colors.dreamWhite};
  padding: 60px;
  justify-content: space-evenly;
  position: relative;
  max-width: 1440px;
  margin: 100px 80px;
  @media(max-width: 1109px) {
    padding: 30px 10px;
    flex-direction: column-reverse;
    margin: 85px 50px;
  }

  @media(min-width: 1512px) {
    margin: 100px auto;
  }

  @media(max-width: 425px) {
    margin: 85px 15px;
  }
`
const RadiusInfo = styled.div`
  width: 50%;
  @media(max-width: 1109px) {
    width: 100%;
    padding: 20px;
  }

  @media(max-width: 425px) {
    padding: 0px;
  }
`

const InfoHeader = styled.h1`
  font-family: ${HIGHLIGHT_FONT};
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 49px;
  letter-spacing: 0.005em;
  color: ${colors.black};
  @media(max-width: 1109px) {
    text-align: center;
  }
  @media(max-width: 767px) {
    font-size: 36px;
    line-height: 36px;
  }
`
const InfoPara = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 22px;
  line-height: 30px;
  color: ${colors.black};
  margin-bottom: 22px;
  @media(max-width: 1109px) {
    text-align: center;
  }
  @media(max-width: 767px) {
    font-size: 20px;
    line-height: 22px;
  }
  @media(max-width: 425px) {
    font-size: 18px;
  }
`
const InfoDetails = styled.li`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 22px;
  line-height: 30px;
  color: ${colors.black};
  @media(max-width: 767px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media(max-width: 425px) {
    font-size: 18px;
  }

`

const Colored = styled.span`
  color: ${colors.new_primary};
  white-space: nowrap;
`

const Image = styled.img`
  display: block;
  width: 530px;
  height: 300px;
  margin: auto;
  cursor: pointer;
  @media(max-width: 1109px) {
    width: 100%;
    height: auto;
  }
`

const InfoDetailsWrap = styled.div`
  @media(max-width: 1109px) {
    display: flex;
    justify-content: center;
  }
  @media(max-width: 725px) {
    display: block;
    max-width: 450px;
    margin: auto;
  }
`

const InfoDetailsContainer = styled.ul`
  list-style: disc;
  margin-left: 20px;
  @media(max-width: 1109px) {
    margin-left: 40px;
  }
  @media(max-width: 725px) {
    margin-left: 20px;
  }
`

const ButtonWrap = styled.div`
  margin-top: 26px;
  @media(max-width: 1109px) {
    text-align: center;
  }
  ${props => props.section === "3" && `
    text-align: center;
  `}
`

const WebinarInfo = () => {
  return (
    <RadiusInfoWrapper>
      <RadiusInfo>
        <InfoHeader>
          Learn more about Radius in&nbsp;
          <Colored>10-Minutes</Colored>
        </InfoHeader>
        <InfoPara>
          We'll discuss the benefits of our brokerage, including:
        </InfoPara>
        <InfoDetailsWrap>
          <InfoDetailsContainer>
            <InfoDetails>
              Brand Development & Marketing Support
            </InfoDetails>
            <InfoDetails>
              Team Recruiting
            </InfoDetails>
            <InfoDetails>
              Lead Nurturing
            </InfoDetails>
          </InfoDetailsContainer>
          <InfoDetailsContainer>
            <InfoDetails>
              Financing Programs
            </InfoDetails>
            <InfoDetails>
              Sensible Pricing
            </InfoDetails>
          </InfoDetailsContainer>
        </InfoDetailsWrap>
        <ButtonWrap>
          <Button
            bsStyle="marineBLueFill"
            padding="16px"
            width="180px"
            height="47px"
            borderRadius="0px"
            loaderOption={{
              color: colors.new_primary,
            }}
            onClick={() => {
              root.open("https://app.ewebinar.com/webinar/radius-brokerage-overview-6293")
              if (root.heap) {
                root.heap.track("Click_Register_Webinar_Recruit_And_Earn")
              }
            }}
          >
            Join our Webinar
          </Button>
        </ButtonWrap>
      </RadiusInfo>
      <LazyLoad offset={100}>
        <Image
          src={Webinar}
          alt="radius brokerage overview"
          width={600}
          height={300}
          onClick={() => {
            root.open("https://app.ewebinar.com/webinar/radius-brokerage-overview-6293")
            if (root.heap) {
              root.heap.track("Click_Register_Webinar_Recruit_And_Earn")
            }
          }}
        />
      </LazyLoad>
    </RadiusInfoWrapper>
  )
}

export default WebinarInfo
