import {
  createSignalAction,
  createActionCreator,
  createBaseActionCreator,
  createSimpleCreator,
} from "shared/reduxUtils"

const BASE = "REFERRAL"
export const OPEN_FILTER_PANEL = "OPEN_FILTER_PANEL"
export const CLOSE_FILER_PANEL = "CLOSE_FILER_PANEL"
export const SELECT_FILTER = "SELECT_FILTER"
export const SAVE_APPLIED_FILTERS = "SAVE_APPLIED_FILTERS"
export const CLEAR_FILTERS = "CLEAR_FILTERS"
export const REMOVE_FILTER = "REMOVE_FILTER"
export const GET_STARRED_REFERRALS = "GET_STARRED_REFERRALS"
export const SEARCH_REFERRAL_BY_NAME = "SEARCH_REFERRAL_BY_NAME"
export const FETCH_FILTERED_REFERRALS = "FETCH_FILTERED_REFERRALS"
export const OPEN_CHECKLIST_MODAL = "OPEN_CHECKLIST_MODAL"
export const CLOSE_CHECKLIST_MODAL = "CLOSE_CHECKLIST_MODAL"
export const UPDATE_CHECKLIST_IN_REFERRAL = "UPDATE_CHECKLIST_IN_REFERRAL"
export const SET_CHECKLIST_IN_REFERRAL = "SET_CHECKLIST_IN_REFERRAL"
export const UPDATE_DRAG_DROP_COLUMNS = "UPDATE_DRAG_DROP_COLUMNS"
export const OPEN_ADD_REMINDER_MODAL = "OPEN_ADD_REMINDER_MODAL"
export const CLOSE_ADD_REMINDER_MODAL = "CLOSE_ADD_REMINDER_MODAL"
export const SEARCH_REFERRALS_TYPE_EVENT = "SEARCH_REFERRALS_TYPE_EVENT"
export const GET_MORE_REFERRALS = "GET_MORE_REFERRALS"
export const TOGGLE_ARCHIVE_MODAL = "TOGGLE_ARCHIVE_MODAL"
export const UPDATE_REFERRAL_NOTIFY_TIME = "UPDATE_REFERRAL_NOTIFY_TIME"
export const OPEN_ARCHIVE_LEADS_MODAL = "OPEN_ARCHIVE_LEADS_MODAL"
export const CLOSE_ARCHIVE_LEADS_MODAL = "CLOSE_ARCHIVE_LEADS_MODAL"
export const WATCH_ARCHIVE_REFERRAL_TYPE = "WATCH_ARCHIVE_REFERRAL_TYPE"
export const OPEN_PAYMENT_SETTINGS_MODAL = "OPEN_PAYMENT_SETTINGS_MODAL"
export const CLOSE_PAYMENT_SETTINGS_MODAL = "CLOSE_PAYMENT_SETTINGS_MODAL"
export const UPDATE_CURRENT_PROSPECT = "UPDATE_CURRENT_PROSPECT"
export const UPDATE_SINGLE_PROSPECT = "UPDATE_SINGLE_PROSPECT"
export const UPDATE_PROSPECT_MODAL = "UPDATE_PROSPECT_MODAL"
export const UPDATE_SUBSCRIPTION_STATUS = "UPDATE_SUBSCRIPTION_STATUS"
export const MLS_STATE_CHANGE = "MLS_STATE_CHANGE"
export const TOGGLE_LEAD_MODAL = "TOGGLE_LEAD_MODAL"
export const LEAD_EXPIRED = "LEAD_EXPIRED"
export const CLOSE_LEAD_EXPIRED = "CLOSE_LEAD_EXPIRED"
export const SHOW_LEAD_MODAL_BY_ID = "SHOW_LEAD_MODAL_BY_ID"
export const LAST_ACCEPTED_REFERRAL = "LAST_ACCEPTED_REFERRAL"

//Inbound Referral - Drag and Drop Actions
export const CHANGE_INBOUND_REFERRAL_ORDER = "CHANGE_INBOUND_REFERRAL_ORDER"
export const CHANGE_INBOUND_REFERRAL_STAGE = "CHANGE_INBOUND_REFERRAL_STAGE"

// Api Actions
export const fetchSignedAgreementAction = createSignalAction(BASE, "FETCH_SIGNED_AGREEMENT")
export const fetchFilterSkeletonAction = createSignalAction(BASE, "FETCH_FILTER_SKELETON")
export const fetchReferalMetricAction = createSignalAction(BASE, "FETCH_REFERRAL_MATRIC")
export const fetchStarReferralAction = createSignalAction(BASE, "STAR_REFERRAL")
export const fetchReferralsAction = createSignalAction(BASE, "FETCH_REFERRALS")
export const fetchArchivedReferralsAction = createSignalAction(BASE, "FETCH_ARCHIVED_REFERRALS")
export const changeInboundReferralStageAction = createSignalAction(BASE, CHANGE_INBOUND_REFERRAL_STAGE)
export const signCRSAgreementAction = createSignalAction(BASE, "SIGN_CRS_AGREEMENT")
export const subscribeAction = createSignalAction(BASE, "SUBSCRIBE")
export const unsubscribeAction = createSignalAction(BASE, "UNSUBSCRIBE")
export const fetchInvoicesAction = createSignalAction(BASE, "FETCH_INVOICES")
export const fetchPlansAction = createSignalAction(BASE, "FETCH_PLANS")
export const fetchSubscriptionsAction = createSignalAction(BASE, "FETCH_SUBSCRIPTIONS")
export const fetchDefaultCardAction = createSignalAction(BASE, "FETCH_DEFAULT_CARD_ACTION")
export const updateCardAction = createSignalAction(BASE, "UPDATE_CARD")
export const fetchProspectsAction = createSignalAction(BASE, "FETCH_PROSPECTS")
export const updateProspectAction = createSignalAction(BASE, UPDATE_SINGLE_PROSPECT)
export const referLeadAction = createSignalAction(BASE, "REFER_LEAD")
export const getMLSServingListsAction = createSignalAction(BASE, "GET_MLS_SERVING_LISTS")
export const syncMLSAction = createSignalAction(BASE, "SYNC_MLS")
export const removeSyncedMlsAction = createSignalAction(BASE, "REMOVE_SYNCED_MLS")
export const saveReferralClientAction = createSignalAction(BASE, "SAVE_REFERRAL_CLIENT")
export const fetchLeadAction = createSignalAction(BASE, "FETCH_LEAD_ACTION")
export const updateLeadReferralStateAction = createSignalAction(BASE, "UPDATE_LEAD_REFERRAL_STATE")
export const applyPricingCoupon = createSignalAction(BASE, "APPLY_PRICING_COUPON")
export const checkoutPricingCart = createSignalAction(BASE, "CHECKOUT_PRICING_CART")
export const fetchSingleProspectAction = createSignalAction(BASE, "FETCH_SINGLE_PROSPECT")
export const checkPartnerSubscriptionInterestsAction = createSignalAction(BASE, "CHECK_PARTNER_SUBSCRIPTION_INTERESTS")
export const submitInterestFormAction = createSignalAction(BASE, "SUBMIT_INTEREST_FORM_ACTION")
export const getISAFormDropdownValuesAction = createSignalAction(BASE, "GET_ISA_FORM_DROPDOWN_VALUES")
export const sendMetricSMSAction = createSignalAction(BASE, "SEND_METRIC_SMS")
export const fetchPricingPlansAction = createSignalAction(BASE, "FETCH_PRICING_PLANS")
export const fetchSubmittedMetricsAction = createSignalAction(BASE, "FETCH_SUBMITTED_METRICS")
export const fetchSourcesAction = createSignalAction(BASE, "FETCH_SOURCES")
export const fetchPlanDurationAction = createSignalAction(BASE, "FETCH_PLAN_DURATION")
export const exportToExcelAction = createSignalAction(BASE, "EXPORT_TO_EXCEL")
export const FSISAPageVisitedAction = createSignalAction(BASE, "FS_ISA_PAGE_VISITED")

// Recruit and Earn
export const inviteBrokerAction = createSignalAction(BASE, "INVITE_BROKER_ACTION")
export const getBrokerInvitesAction = createSignalAction(BASE, "GET_BROKER_INVITES")
export const getBorkerEarningsAction = createSignalAction(BASE, "GET_BROKER_EARNINGS")

//Non API but Sequential Actions
export const changeInboundReferralOrderAction = createSignalAction(BASE, CHANGE_INBOUND_REFERRAL_ORDER)

// Non API Actions
export const selectFilter = createActionCreator(SELECT_FILTER)
export const getStarredReferrals = createActionCreator(GET_STARRED_REFERRALS)
export const openFilterPanel = createActionCreator(OPEN_FILTER_PANEL)
export const closeFilterPanel = createActionCreator(CLOSE_FILER_PANEL)
export const saveAppliedFilters = createActionCreator(SAVE_APPLIED_FILTERS)
export const clearFilters = createActionCreator(CLEAR_FILTERS)
export const removeFilter = createActionCreator(REMOVE_FILTER)
export const searchReferralByName = createActionCreator(SEARCH_REFERRAL_BY_NAME)
export const fetchFilteredReferralsAction = createActionCreator(FETCH_FILTERED_REFERRALS)
export const openChecklistModal = createActionCreator(OPEN_CHECKLIST_MODAL)
export const closeChecklistModal = createActionCreator(CLOSE_CHECKLIST_MODAL)
export const openAddReminderModalAction = createActionCreator(OPEN_ADD_REMINDER_MODAL)
export const closeAddReminderModalAction = createActionCreator(CLOSE_ADD_REMINDER_MODAL)
export const searchReferralTypeEventAction = createActionCreator(SEARCH_REFERRALS_TYPE_EVENT)
export const getMoreReferrals = createActionCreator(GET_MORE_REFERRALS)
export const toggleArchiveModalAction = createActionCreator(TOGGLE_ARCHIVE_MODAL)
export const updateReferralNotifyTimeAction = createActionCreator(UPDATE_REFERRAL_NOTIFY_TIME)
export const openArchiveLeadModalAction = createActionCreator(OPEN_ARCHIVE_LEADS_MODAL)
export const closeArchiveLeadModalAction = createActionCreator(CLOSE_ARCHIVE_LEADS_MODAL)
export const watchArchiveReferralTypeAction = createActionCreator(WATCH_ARCHIVE_REFERRAL_TYPE)
export const openPaymentSettingsAction = createActionCreator(OPEN_PAYMENT_SETTINGS_MODAL)
export const closePaymentSettingsAction = createActionCreator(CLOSE_PAYMENT_SETTINGS_MODAL)
export const updateCurrentProspectAction = createActionCreator(UPDATE_CURRENT_PROSPECT)
export const updateProspectModalAction = createActionCreator(UPDATE_PROSPECT_MODAL)
export const updateSubscriptionStatusAction = createActionCreator(UPDATE_SUBSCRIPTION_STATUS)
export const mlsStateChangeAction = createActionCreator(MLS_STATE_CHANGE)
export const toggleLeadModalAction = createActionCreator(TOGGLE_LEAD_MODAL)
export const leadExpiredAction = createActionCreator(LEAD_EXPIRED)
export const closeLeadExpiredAction = createActionCreator(CLOSE_LEAD_EXPIRED)
export const showLeadModalByIdAction = createActionCreator(SHOW_LEAD_MODAL_BY_ID)
export const lastAcceptedReferralAction = createSimpleCreator(BASE, "LAST_ACCEPTED_REFERRAL")
export const toggleAssignModalAction = createSimpleCreator(BASE, "TOGGLE_ASSIGN_MODAL")
export const toggleTeamInviteModalAction = createSimpleCreator(BASE, "TOGGLE_TEAM_INVITE_MODAL")
export const clearCurrentProspectsAction = createSimpleCreator(BASE, "CLEAR_CURRENT_PROSPECTS")
export const addPlanToCart = createBaseActionCreator(BASE, "ADD_PLAN_TO_CART")
export const removePlanFromCart = createBaseActionCreator(BASE, "REMOVE_PLAN_FROM_CART")
export const clearPricingCart = createBaseActionCreator(BASE, "CLEAR_PRICING_CART")
export const clearCouponData = createBaseActionCreator(BASE, "CLEAR_COUPON_DATA")
export const updatePaymentPricingModal = createBaseActionCreator(BASE, "UPDATE_PAYMENT_PRICING_MODAL")
export const toggleUpdateCardModal = createBaseActionCreator(BASE, "TOGGLE_UPDATE_CARD_MODAL")
export const toggleInterestConfirmationModalAction = createSimpleCreator(BASE, "TOGGLE_INTEREST_CONFIRMATION_MODAL")
export const setCurrentSourcesFilterAction = createSimpleCreator(BASE, "SET_CURRENT_SOURCES_FILTER")
export const createPricingMapAction = createSimpleCreator(BASE, "CREATE_PRICING_MAP")
export const saveQueryParamForReferralsAction = createSimpleCreator(BASE, "SAVE_ QUERY_PARAMS")

// Sales State Change forms
export const toggleSalesStageChangeFormAction = createSimpleCreator(BASE, "TOGGLE_SALE_STAGE_CHANGE_FORM")
export const updateSalesStageFormAction = createSignalAction(BASE, "UPDATE_SALES_UPDATE_FORM")
export const getClosingStageInfoAction = createSignalAction(BASE, "GET_CLOSING_STATGE_INFO")

// Shar the love invite flow
export const toggleInviteModalAction = createSimpleCreator(BASE, "TOGGLE_INVITE_POPUP")
export const toggleInviteBannerAction = createSimpleCreator(BASE, "TOGGLE_INVITE_BANNER")
export const startShareTheLoveInviteFlowAction = createSimpleCreator(BASE, "START_SHARE_THE_LOVE_FLOW")
export const getInviteeForSTLAction = createSignalAction(BASE, "GET_INVITEE_FOR_STL")

export const forceCloseProspectModalAction = createSimpleCreator(BASE, "FORCE_TOGGLE_PROSPECT_MODAL")

// Team plan Actions API
export const fetchAcceptLeadDropdownValuesAction = createSignalAction(BASE, "FETCH_ACCEPT_LEAD_DROPDOWN_VALUES")
export const setPermissionValueAction = createSignalAction(BASE, "SET_PERMISSION_VALUE")
export const fetchTeamMembersAction = createSignalAction(BASE, "FETCH_TEAM_MEMBERS")
export const fetchTeamFilterValuesAction = createSignalAction(BASE, "FETCH_TEAM_FILTER_VALUES")
export const getTeamMembersAction = createSignalAction(BASE, "GET_TEAM_MEMEBERS")
export const assignTeamMemeberAction = createSignalAction(BASE, "ASSIGN_MEMBER")
export const referOutAction = createSignalAction(BASE, "REFER_OUT")
export const assignAgentAction = createSignalAction(BASE, "ASSIGN_AGENT")
export const handleInviteResponseAction = createSignalAction(BASE, "MEMBER_ACCEPTED_OR_REJECTED")
export const inviteTeamMemberAction = createSignalAction(BASE, "INVITE_TEAM_MEMBER")
export const removeMemberAction = createSignalAction(BASE, "REMOVE_MEMBER")
export const fetchAddASeatAmountAction = createSignalAction(BASE, "ADD_SEAT_AMOUNT")
export const handleAddASeatAction = createSignalAction(BASE, "ADD_A_SEAT")
export const getTeamInfoAction = createSignalAction(BASE, "GET_TEAM_INFO")
export const updateTeamImageAction = createSignalAction(BASE, "UPDATE_TEAM_IMAGE")
export const changeTeamRoleAction = createSignalAction(BASE, "CHANGE_TEAM_ROLE")

// Team plan Action Non API
export const toggleReferOutAction = createSimpleCreator(BASE, "TOGGLE_REFER_OUT")
export const referOutModalAction = createSimpleCreator(BASE, "REFER_OUT_MODAL")
export const referConfirmedAction = createSimpleCreator(BASE, "REFER_CONFIRMED")
export const changePlanTypeAction = createSimpleCreator(BASE, "CHANGE_PLAN_TYPE")
export const modifyMemberQuantityAction = createSimpleCreator(BASE, "MODIFY_MEMBER_QUANTITY")
export const setAmountForCheckoutAction = createSimpleCreator(BASE, "SET_AMOUNT_FOR_CHECKOUT")
export const configureAcceptPermissionAction = createSimpleCreator(BASE, "CONFIGURE_ACCEPT_PERMISSION")
export const configureAssignPermissionAction = createSimpleCreator(BASE, "CONFIGURE_ASSIGN_PERMISSION")
export const setCurrentTeamFilterAction = createSimpleCreator(BASE, "SET_CURRENT_TEAM_FILTER")
export const toggleRemoveTeamMemberModalAction = createSimpleCreator(BASE, "TOGGLE_REMOVE_MEMBER_MODAL")
export const removeTeamMemberModal = createSimpleCreator(BASE, "REMOVE_TEAM_MEMBER_MODAL")
export const confirmRemoveMemberAction = createSimpleCreator(BASE, "CONFIRM_REMOVE_MEMBER")
export const hideRemoveMemberSuccessModalAction = createSimpleCreator(BASE, "HIDE_REMOVE_MEMBER_SUCCESS_MODAL")
export const showRemoveMemberSuccessModalAction = createSimpleCreator(BASE, "SHOW_REMOVE_MEMBER_SUCCESS_MODAL")
export const toggleRemoveMemberSuccessModalAction = createSimpleCreator(BASE, "TOGGLE_REMOVE_MEMBER_SUCCESS_MODAL")
export const updateTeamSizeAction = createSimpleCreator(BASE, "UPDATE_TEAM_SIZE")
export const setCurrentReInviteMemberIDAction = createSimpleCreator(BASE, "CURRENT_REINVITE_MEMBER_ID")
export const toggleAddTeamLogoAction = createSimpleCreator(BASE, "TOGGLE_TEAM_LOGO_MODAL")
export const toggleChangeRoleLoaderAction = createSimpleCreator(BASE, "TOGGLE_CHANGE_TEAM_ROLE")
export const toggleUpgradeButton = createSimpleCreator(BASE, "TOGGLE_UPGRADE_BUTTON")
export const toggleChangeRoleModalAction = createSimpleCreator(BASE, "TOGGLE_CHANGE_ROLE_MODAL")

export const fetchAgentSearchAction = createSignalAction(BASE, "FETCH_AGENT_ACTION")
export const setCurrentAgentNameAction = createSimpleCreator(BASE, "SET_CURRENT_AGENT_ACTION")
export const setCurrentLocationAction = createSimpleCreator(BASE, "SET_CURRENT_LOCATION_ACTION")
export const setCurrentExpertiseFilterAction = createSimpleCreator(BASE, "SET_CURRENT_EXPERTISE")
export const setCurrentLanguageFilterAction = createSimpleCreator(BASE, "SET_CURRENT_LANGUAGE")
export const setCurrentCompaniesFilterAction = createSimpleCreator(BASE, "SET_CURRENT_COMPANIES")
export const setCurrentWCRFilterAction = createSimpleCreator(BASE, "SET_CURRENT_WCR_FILTER")
export const setCurrentRPACFilterAction = createSimpleCreator(BASE, "SET_CURRENT_RPAC_FILTER")
export const setCurrentWCRPMNFilterAction = createSimpleCreator(BASE, "SET_CURRENT_WCRPMN_FILTER")
//INBOUND PENDING
export const getInboundPendingReferralDetailsAction = createSignalAction(BASE, "GET_INBOUND_DETAILS")
export const acceptOrRejectLeadAction = createSignalAction(BASE, "HANDLE_ACCEPT_REJECT_LEAD")
export const toggleSendToMarketPlaceModalAction = createSimpleCreator(BASE, "TOGGLE_SEND_TO_MARKETPLACE")
export const handleUserConfirmationAction = createSimpleCreator(BASE, "HANDLE_USER_CONFIRMATION")
export const closeLMPModalAction = createSimpleCreator(BASE, "CLOSE_LMP_MODAL")

//FEATURE viewed
export const findAnAgentViewedAction = createSignalAction(BASE, "FIND_AN_AGENT_VIEWED")

//RAR DASHBOARD || RECRUIT AND Earn
export const getCurrentUserRecruitAndEarnDetailsAction = createSignalAction(BASE, "RECRUIT_AND_EARN_DETAIL_ACTION")
export const getCurrentRecruitAndEarnLeaderboardDetailsAction = createSignalAction(BASE, "RECRUIT_AND_EARN_LEADERBOARD")
export const setCurrentLimitForLeaderboardAction = createSimpleCreator(BASE, "LEADERBOARD_LIMIT")

//lmp
export const psuedoDocuSignAction = createSignalAction(BASE, "PSUEDO_DOCU_SIGN")
export const fetchSubscriptionStatusAction = createSignalAction(BASE, "FETCH_SUBSCRIPTION_STATUS")
export const fetchSubscriptionStatusEOIAction = createSignalAction(BASE, "FETCH_SUBSCRIPTION_STATUS_EOI")

export const setSubscriptionStatusAction = createSimpleCreator(BASE, "SET_SUBSCRIPTION_STATUS")
export const setSubscriptionEndDateAction = createSimpleCreator(BASE, "SET_SUBSCRIPTION_END_DATE")
export const setSubcriptionStatusObjectAction = createSimpleCreator(BASE, "SET_SUBSCRIPTION_STATUS_OBJECT")
export const setSubscriptionExistAction = createSimpleCreator(BASE, "SUBSCRIPTION_EXIST")