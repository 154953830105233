import { takeLatest, call, put, takeEvery, select } from "redux-saga/effects";
// import { get } from "lodash";
// import * as TechAssetsActions from "container/TechAssets/actions";
// import customToast from "dumbComponents/OffersV3/components/Toast/customToast";
import { isSuccess, MoneyFormatter } from "services/Utils";
import { addAppDataAction, addOnboardingInfoAction, addOnboardingStepAction, addPersonalInfoAction, getAppStatusAction, getDbaInfoAction, getLicenseDetailsAction, getLicenseStateAction, getMLSAction, getOfficeAddressAction, getStepsCompletedAction, uploadAppAssetsAction, toggleOnboardingStep, getPersonalInfoAction, getAssociationsAction, addAppBrandingAction, getAppBrandingAction, currentOnboardingStep, getStepCompletedForSaasAction, getMLSListAction } from "./actions";
import { addAppBrandingAPI, addAppDataAPI, addOnboardingInfoAPI, addOnboardingStepAPI, addPersonalInfoAPI, getAppBrandingAPI, getAppStatusAPI, getAssociationsAPI, getDbaInfoAPI, getLicenseDetailsAPI, getLicenseStateAPI, getMLSAPI, getOfficeAddressAPI, getPersonalInfoAPI, getStepsCompletedAPI, uploadAppAssetsAPI, getStepsCompletedForSaasAPI, getMLSListAPI } from "./api";
import { message } from "antd";

function* getAppStatusSaga(action) {
  try {
    const payload = action.data;
    const data = yield call(getAppStatusAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(getAppStatusAction.success(response));
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);

    yield put(getAppStatusAction.failure(error.message));
  }
}

function* addPersonalInfoSaga(action) {
  try {
    const {payload, callback} = action.data;
    const data = yield call(addPersonalInfoAPI, payload);
    // yield put(toggleOnboardingStep.call("fetching_step"));
    if (isSuccess(data)) {
      const { response } = data.data;
      // yield put(toggleOnboardingStep.call("license_info"));
      yield put(addPersonalInfoAction.success(response));
      const currentStep = yield select((state) => state.onboardingV2.currentOnboardingStep);
      if (callback) {
        yield put(toggleOnboardingStep.call(currentStep));
        callback();
        return;
      }
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(error?.data?.error?.message || errorMessage);
    yield put(addPersonalInfoAction.failure(error.message));
  }
}

function* addOnboardingInfoSaga(action) {
  try {
    const payload = action.data;
    const data = yield call(addOnboardingInfoAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(addOnboardingInfoAction.success(response));
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);
    yield put(addOnboardingInfoAction.failure(error.message));
  }
}

function* addOnboardingStepSaga(action) {
  try {
    const payload = action.data;
    const data = yield call(addOnboardingStepAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(addOnboardingStepAction.success(response));
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);

    yield put(addOnboardingStepAction.failure(error.message));
  }
}

function* getStepsCompletedSaga(action) {
  try {
    const payload = action.data;
    const data = yield call(getStepsCompletedAPI, payload);
    // yield put(toggleOnboardingStep.call("fetching_step"));
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(getStepsCompletedAction.success(response));
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);

    yield put(getStepsCompletedAction.failure(error.message));
  }
}

function* getStepsCompletedForSaasSaga(action) {
  try {
    const payload = action.data;
    const data = yield call(getStepsCompletedForSaasAPI, payload);
    // yield put(toggleOnboardingStep.call("fetching_step"));
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(getStepCompletedForSaasAction.success(response));
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);

    yield put(getStepCompletedForSaasAction.failure(error.message));
  }
}

function* getLicenseDetailsSaga(action) {
  try {
    const payload = action.data;
    const data = yield call(getLicenseDetailsAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(getLicenseDetailsAction.success(response));
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);

    yield put(getLicenseDetailsAction.failure(error.message));
  }
}

function* getMLSSaga(action) {
  try {
    const payload = action.data;
    const data = yield call(getMLSAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(getMLSAction.success(response));
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);

    yield put(getMLSAction.failure(error.message));
  }
}

function* getOfficeAddressSaga(action) {
  try {
    const payload = action.data;
    const data = yield call(getOfficeAddressAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(getOfficeAddressAction.success(response));
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);

    yield put(getOfficeAddressAction.failure(error.message));
  }
}

function* getDbaInfoSaga(action) {
  try {
    const payload = action.data;
    const data = yield call(getDbaInfoAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(getDbaInfoAction.success(response));
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);

    yield put(getDbaInfoAction.failure(error.message));
  }
}

function* getLicenseStateSaga(action) {
  try {
    const payload = action.data;
    const data = yield call(getLicenseStateAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(getLicenseStateAction.success(response));
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);

    yield put(getLicenseStateAction.failure(error.message));
  }
}

function* addAppDataSaga(action) {
  try {
    const payload = action.data;
    const data = yield call(addAppDataAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(addAppDataAction.success(response));
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);

    yield put(addAppDataAction.failure(error.message));
  }
}

function* uploadAppAssetsSaga(action) {
  try {
    const payload = action.data;
    const data = yield call(uploadAppAssetsAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(uploadAppAssetsAction.success(data.data));
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);

    yield put(uploadAppAssetsAction.failure(error.message));
  }
}

function* getPersonalInfoSaga(action) {
  try {
    const step_completed = action.data;
    const data = yield call(getPersonalInfoAPI, step_completed);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(getPersonalInfoAction.success(response));
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);
    yield put(getPersonalInfoAction.failure(error.message));
  }
}

function* getAssociationsSaga(action) {
  try {
    const {skip, limit, searchText} = action.data;
    const data = yield call(getAssociationsAPI, skip, limit, searchText);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(getAssociationsAction.success(response));
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);
    yield put(getAssociationsAction.failure(error.message));
  }
}

function* getMLSListSaga(action) {
  try {
    const {searchText} = action.data;
    console.log("coming here in saga")
    const data = yield call(getMLSListAPI, searchText);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(getMLSListAction.success(response));
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);
    yield put(getMLSListAction.failure(error.message));
  }
}

function* addAppBrandingSaga(action) {
  try {
    const {payload, callback} = action.data;
    const data = yield call(addAppBrandingAPI, payload);
    // yield put(toggleOnboardingStep.call("fetching_step"));
    if (isSuccess(data)) {
      const { response } = data.data;
      const currentStep = yield select((state) => state.onboardingV2.currentOnboardingStep);
      yield put(addAppBrandingAction.success(response));
      if (callback) {
        yield put(toggleOnboardingStep.call(currentStep));
        callback();
        return;
      }
    }
  } catch (error) {
    const errorMessage = "Something went wrong, Please try again later!";
    message.error(errorMessage);
    yield put(addAppBrandingAction.failure(error.message));
  }
}

function* getAppBrandingSaga(action) {
  try {
    const payload = action.data;
    const data = yield call(getAppBrandingAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(getAppBrandingAction.success(response));
    }
  } catch (error) {
    // const errorMessage = "Something went wrong, Please try again later!";
    // message.error(errorMessage);
    yield put(getAppBrandingAction.failure(error.message));
  }
}

export default function* main() {
  yield takeLatest(getAppStatusAction.REQUEST, getAppStatusSaga);
  yield takeLatest(addPersonalInfoAction.REQUEST, addPersonalInfoSaga);
  yield takeLatest(addOnboardingInfoAction.REQUEST, addOnboardingInfoSaga);
  yield takeLatest(addOnboardingStepAction.REQUEST, addOnboardingStepSaga);
  yield takeLatest(getStepsCompletedAction.REQUEST, getStepsCompletedSaga);
  yield takeLatest(getLicenseDetailsAction.REQUEST, getLicenseDetailsSaga);
  yield takeLatest(getMLSAction.REQUEST, getMLSSaga);
  yield takeLatest(getOfficeAddressAction.REQUEST, getOfficeAddressSaga);
  yield takeLatest(getDbaInfoAction.REQUEST, getDbaInfoSaga);
  yield takeLatest(getLicenseStateAction.REQUEST, getLicenseStateSaga);
  yield takeLatest(addAppDataAction.REQUEST, addAppDataSaga);
  yield takeLatest(uploadAppAssetsAction.REQUEST, uploadAppAssetsSaga);
  yield takeLatest(getPersonalInfoAction.REQUEST, getPersonalInfoSaga);
  yield takeLatest(getAssociationsAction.REQUEST, getAssociationsSaga);
  yield takeLatest(addAppBrandingAction.REQUEST, addAppBrandingSaga);
  yield takeLatest(getAppBrandingAction.REQUEST, getAppBrandingSaga);
  yield takeLatest(getStepCompletedForSaasAction.REQUEST, getStepsCompletedForSaasSaga);
  yield takeLatest(getMLSListAction.REQUEST, getMLSListSaga);
}
