import axios from "axios";

export const getAppStatusAPI = (payload) =>
  axios.get(`${API_URL}consumerapp/get-app-info`);

export const addPersonalInfoAPI = (payload) =>
  axios.post(`${API_URL}onboarding/setup-profile`, payload);

export const addOnboardingInfoAPI = (payload) =>
  axios.post(`${API_URL}new-onboarding-setup`, payload);

export const addOnboardingStepAPI = (payload) =>
  axios.post(`${API_URL}onboarding-steps-completed`, payload);

export const addAppDataAPI = (payload) => axios.post(`${API_URL}app-branding`);

export const uploadAppAssetsAPI = (payload) =>
  axios.post(`${API_URL}consumer-app/uploads/app-assets`, payload);

export const addAppBrandingAPI = (payload) =>
  axios.post(`${API_URL}consumerapp/update-app-customizations/93`, payload);

// GET APIs
export const getStepsCompletedAPI = (payload) =>
  axios.get(`${API_URL}onboarding/steps-completed`);

export const getStepsCompletedForSaasAPI = (payload) => 
  axios.get(`${API_URL}onboarding/steps-completed?is_saas_user=true`);

export const getLicenseDetailsAPI = (agentId) =>
  axios.get(`${API_URL}user-license-info?agentId=${agentId}`);

export const getMLSAPI = (skip, limit, search_text) =>
  axios.get(
    `${API_URL}onboarding/mls?skip=${skip}&limit=${limit}&search_text=${search_text}`
  );

export const getOfficeAddressAPI = (payload) =>
  axios.get(`${API_URL}office-address`);

export const getDbaInfoAPI = (payload) => axios.get(`${API_URL}get-dba-info`);

export const getLicenseStateAPI = (payload) =>
  axios.get(`${API_URL}onboarding/setup-profile?step_completed=agent_license_state`);

export const getPersonalInfoAPI = (step_completed) => axios.get(`${API_URL}onboarding/setup-profile?step_completed=${step_completed}`)

export const getAssociationsAPI = (skip, limit, searchText) => axios.get(`${API_URL}onboarding/associations?skip=${skip}&limit=${limit}&search_text=${searchText}`)

export const getAppBrandingAPI = (payload) => axios.get(`${API_URL}consumerapp/soul/get-app-settings/1`)

export const getMLSListAPI = (searchText) => axios.get(`${API_URL}fetch-mls-info?searchText=${searchText}`)
