import axios from "axios"

const ACADEMY_ENDPOINT = `${API_URL}shiksha`
const LOCAL_URL = "http://localhost:8000/v1.0/"

export const submitNewLetterAPI = payload => axios
  .post(`${API_URL}newsletter-product-interest`, payload)

export const submitScheduleACallAPI = payload => axios
  .post(`${API_URL}broker-product-interest`, payload)

export const pageTrackingAPI = payload => axios
  .post(`${API_V2_URL}page-loads`, payload)

export const brokerageFeatureViewedAPI = payload => axios
  .post(`${API_URL}side-menu-updates/feature-viewed`, payload)

export const RARTrackAPI = payload => axios
  .post(`${API_V2_URL}rar-clicks`, payload)

export const commmunityEventListingAPI =
  () => axios.get(`${ACADEMY_ENDPOINT}/events?upcoming=true&skip=0&limit=3`)

export const getQuotesAPI = () => axios
.get(`${API_URL}user-quotes`)

export const getAgentsForSupportAPI = () => axios
.get(`${API_URL}agent-support`)

export const getBrokerageSupportAPI = () => axios
.get(`${API_URL}brokerage-support`)

export const postSupportClickAPI = body => axios
.post(`${API_URL}rar-support-notification`, body)

export const postUpgradeClickAPI = () => axios
.post(`${API_URL}upgrade-plan-notification`, {})

export const postTCClickAPI = () => axios
.post(`${API_URL}transaction-form-notification`, {})

export const getCurrentPlanDetailsAPI = () => axios
.get(`${API_URL}current-rar-plan`)

export const getResourceFiltersAPI = () => axios
.get(`${API_URL}fetch-rar-documents-filters`)

export const fetchResourceDocumentsAPI = payload => axios
.post(`${API_URL}fetch-rar-documents`, payload)

export const createTCFormDealAPI = payload => axios
.post(`${API_URL}brokerage-deals`, payload)

export const updateTCFormDealAPI = (payload, id) => axios
.put(`${API_URL}brokerage-deals/${id}`, payload)

export const updateCreateDealFormStageAPI = payload => axios
.post(`${API_URL}brokerage-deals-steps`, payload)

export const fileUploadAPI = (name, payload) => axios
.post(`${FILE_UPLOAD_API}filename=${name}`, payload)

export const filesUploadToDBAPI = payload => axios
.post(`${API_URL}brokerage-deals-documents`, payload)

export const fetchTCDocumentsAPI = query => axios
.get(`${API_URL}brokerage-deals?${query}`)

export const fetchTCDocumentsFromS3API = id => axios
.get(`${API_URL}brokerage-deals-documents/${id}`)

export const deleteTCDocumentsFromS3API = id => axios
.delete(`${API_URL}brokerage-deals-documents/${id}`)

export const fetchClientDetailsAPI = payload => axios
.post(`${API_V2_URL}clients/search`, payload)

export const triggerSlackNotificationAPI = (dealId, agentId) => axios
.get(`${API_URL}brokerage-deals-notification?dealId=${dealId}&agentId=${agentId}`)

export const getRequestsListAPI = (query = "") => axios.get(`${API_URL}get-rar-resources${query}`)
export const getRoomsCalendarEventAPI = () => axios.get(`${API_URL}radius-club/events-happening-today`)

export const getAllInviteesAPI = (skip, limit) => axios
  .get(`${API_URL}brokerage-invitee-details?skip=${skip || "0"}&limit=${limit || "10"}`)

export const getCashbackProgramAPI = query => axios
  .get(`${API_URL}cashback-detail?${query}`)

export const getOnboardingCommissionAPI = () => axios
  .get(`${API_URL}onboarding-commission-setup`)

export const createOnboardingCommissionAPI = payload => axios
  .post(`${API_URL}onboarding-commission-setup`, payload)

export const updateOnboardingCommissionAPI = payload => axios
  .put(`${API_URL}onboarding-commission-setup`, payload)

export const getTCBannerAPI = agentId => axios
  .get(`${API_URL}brokerage-tc-banner`, agentId)

export const postTCBannerAPI = agentId => axios
  .post(`${API_URL}brokerage-tc-banner`, agentId)

export const fetchTCDocumentsPostAPI = payload => axios
  .post(`${API_V2_URL}brokerage-deals`, payload)

