import React from "react";
import {
  InnerTab,
  NavLink,
  NotificationIndicator,
  InfoTag,
} from "./commonStyles";
import { SettingOutlined } from "@ant-design/icons";

const SettingsTab = ({
  routeArray,
  homeRedDot,
  handleHomeClick,
  showLiveTag,
}) => (
  <>

    <InnerTab
      isActive={routeArray[0] === "settings"}
      className="onboading-nav-home"
    >
         <div className="icon-block-new">
        <SettingOutlined style={{ color: 'white' }} />
    </div>
      <div className="text-block">
        <NavLink
          to="/settings"
          isActive={routeArray[0] === "settings"}
          onClick={handleHomeClick}
        >
          Settings
        </NavLink>
      </div>
    </InnerTab>
  </>
);

export default SettingsTab;
