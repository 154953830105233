import React, { useEffect, useState } from "react"

import CRMV2Container from "container/CRM"
import styled from "styled-components"
import { UserOutlined, HistoryOutlined, DollarOutlined, ProfileOutlined, HomeOutlined, FileTextOutlined, ArrowRightOutlined, CalendarOutlined } from "@ant-design/icons"
import Loader from "@ui/Loader"; 
import { Select, Space } from 'antd';

export const ActivityContainer = styled.div``;

const MainActivityHeading = styled.div`
    display: flex; 
    justify-content: flex-end; 
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    padding: 12px 32px; 
    color: var(--Neutral-900, #111827);
    background-color: #FDFDFD; 
`;

const ActivityDateHeading = styled.div`
    display: flex;
    padding: 16px 32px;
    align-items: center;
    align-self: stretch;
    background: var(--Global-Mapping-Gray-50, #EFEFF4);
    border-radius: 6px; 
    align-items: center; 
    height: 44px;
`;

const ActivityDateHeadingText = styled.p`
    margin-top: 10px; 
    color: var(--Global-Mapping-Gray-400, #7D7DA9);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
`;

const ActivityRow = styled.div`
    padding: 12px 32px;
    background: #FDFDFD; 
    border-bottom: 1px solid #EFEFF4;
    padding-bottom: 2px; 
`;

const ActivityRowHeading = styled.div`
    display: flex; 
    align-items: center; 
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: var(--Neutral-900, #111827);
    margin-bottom: 8px; 
    gap: 16px; 
`;

const ActivityRowText = styled.p`
    display: flex; 
    align-items: center; 
    margin-top: 16px; 
    gap: 6px; 
    color: var(--Global-Mapping-Gray-400, #7D7DA9);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;

const CardContainer = styled.div`
  display: flex;
  overflow: hidden; 
  background-color: #545487; 
  border-radius: 6px;
  border: 1px solid var(--Global-Mapping-Gray-100, #E0E0EB);
  background: var(--Global-Mapping-Gray-50, #EFEFF4);
  padding: 12px 16px; 
  margin-top: 8px; 
`;

const ImageContainer = styled.div`
  width: 150px;
  height: 100px;
  overflow: hidden;
`;

const PropertyImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px; 
`;

const PropertyDetails = styled.div`
  padding: 10px;
`;

const PropertyTitle = styled.h2`
  margin: 0 0 5px 0;
  font-size: 16px;
`;

const PropertyInfo = styled.p`
  margin: 0 0 5px 0;
  font-size: 14px;
  color: #666;
`;

const RecommendedDate = styled.p`
  margin: 0;
  font-size: 12px;
  color: #999;
`;

const ArrowIcon = styled.span`
  margin-left: auto; 
  padding: 10px;
  font-size: 20px;
  color: #5A5FF2; 
  cursor: pointer; 
  margin-top: 20px; 
  &:hover {
    background-color: #f0f0f0;
  }
`;

const ClearFilterButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;

  p {
    color: var(--Color-Red-Red-500, #F05152);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
    letter-spacing: 0.5px;
  }
`; 

const NoteText = styled.span`
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400; 
  color: #545487;
`;

const ReminderText = styled.span`
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  color: #545487;
`;

const ArrowComponent = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M9 18L15 12L9 6"
        stroke="#5A5FF2"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
);

const Activity = ({ 
    getActivityData, 
    getActivityDataActionResponse, 
    getCtIdResponse,
    dataLoader
 }) => {
    const [selectedActivity, setSelectedActivity] = useState("All activities");
    const [timelineFilters, setTimelineFilters] = useState([]); 
    const { data } = getCtIdResponse || {};
    const {data: activityResponse, isFetching} = getActivityDataActionResponse || {}; 

    const {
        searchCriteria,
        type,
        client_id,
        id,
    } = data || {};

    useEffect(() => {
        getActivityData({ 
            clientId: `${client_id}`, 
            transactionId: `${id}`, 
            limit: 100, 
            skip: 0, 
            filterType: `${type}`, 
        });
    }, []);

    useEffect(() => {
        if (activityResponse?.timelineFilters) {
            const filterOptions = activityResponse.timelineFilters
              .filter(filter => filter.active === 1)
              .map(filter => ({
                value: filter.filter, 
                label: filter.display_name, 
              }));
            setTimelineFilters(filterOptions);
        }
    }, [activityResponse]); 

    const handleChange = (value) => {
        setSelectedActivity(value);
        getActivityData({ 
            clientId: `${client_id}`, 
            transactionId: `${id}`, 
            limit: 50,
            skip: 0, 
            filterType: `${type}`, 
            filter: value
        });
    };

    const clearActivityFilters = () => {
        setSelectedActivity("All activities");
        getActivityData({ 
            clientId: `${client_id}`, 
            transactionId: `${id}`, 
            limit: 50, 
            skip: 0, 
            filterType: `${type}`, 
        });
    };

    const hasRelevantData = () => {
        if (activityResponse?.timeline) {
            const events = Object.values(activityResponse.timeline).flat();
            if (events.length > 0) {
                if (dataLoader === "searchCritirea") {
                    return !events.some(event => /BRBC|agent|client added family member to search board|agent added a client/i.test(event.description));
                } else {
                    return events.some(event => /BRBC|agent|client added family member to search board|agent added a client/i.test(event.description));
                }
            }
        }
        return false;
    };

    const getTimeDifference = (eventTime) => {
        const currentTime = Date.now() / 1000;
        const timeDifferenceInSeconds = currentTime - eventTime;
      
        if (timeDifferenceInSeconds <= 60) {
          return `${Math.floor(timeDifferenceInSeconds)} seconds ago`;
        }
    
        const timeDifferenceInMinutes = Math.floor(timeDifferenceInSeconds / 60);
        if (timeDifferenceInMinutes <= 60) { 
          return `${timeDifferenceInMinutes} minute${timeDifferenceInMinutes > 1 ? 's' : ''} ago`;
        }
        
        const timeDifferenceInHours = Math.floor(timeDifferenceInMinutes / 60);
        if (timeDifferenceInHours < 24) {
            return `${timeDifferenceInHours} hour${timeDifferenceInHours > 1 ? 's' : ''} ago`;
        }
      
        const timeDifferenceInDays = Math.round(timeDifferenceInHours / 24);
        if (timeDifferenceInDays >= 1 && timeDifferenceInDays <= 7) {
            return `${timeDifferenceInDays} day${timeDifferenceInDays > 1 ? 's' : ''} ago`;
        }

        if (timeDifferenceInDays > 7) {
            const eventDate = new Date(eventTime * 1000); 
            const day = String(eventDate.getDate()).padStart(2, '0');
            const month = String(eventDate.getMonth() + 1).padStart(2, '0');
            const year = eventDate.getFullYear();
            const hours = String(eventDate.getHours()).padStart(2, '0');
            const minutes = String(eventDate.getMinutes()).padStart(2, '0');    
            return `${day}-${month}-${year} at ${hours}:${minutes}`;
        }
    };

    const formatEventDate = (eventDate) => {
        const [year, month, day] = eventDate.split('-');
        const eventDateObj = new Date(`${year}-${month}-${day}`);
        
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1); 
      
        const eventDateFormatted = new Date(eventDateObj.setFullYear(today.getFullYear()));
      
        if (eventDateFormatted.toDateString() === today.toDateString()) {
          return `Today ${eventDateObj.getDate()} ${eventDateObj.toLocaleString('default', { month: 'short' })} ${eventDateObj.getFullYear()}`;
        } else if (eventDateFormatted.toDateString() === yesterday.toDateString()) {
          return `Yesterday ${eventDateObj.getDate()} ${eventDateObj.toLocaleString('default', { month: 'short' })} ${eventDateObj.getFullYear()}`;
        } else {
          return `${eventDateObj.getDate()} ${eventDateObj.toLocaleString('default', { month: 'short' })} ${eventDateObj.getFullYear()}`;
        }
    };

    const formatRecommendedOnDate = (epochTime) => {
        const date = new Date(epochTime * 1000);

        const day = date.getDate();
        const month = date.toLocaleString('en-US', { month: 'long' });
        const year = date.getFullYear();

        const getOrdinalSuffix = (num) => {
            if (num > 3 && num < 21) return "th";
            switch (num % 10) {
                case 1: return "st";
                case 2: return "nd";
                case 3: return "rd";
                default: return "th";
            }
        };

        return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
    };

    if (!isFetching) {
        console.log("here is data, ", activityResponse);
    }
    
    return (
        <ActivityContainer>
            <MainActivityHeading>
                
                {/* <div style={{ marginTop: "10px" }}>
                    Activity
                </div> */}
                <div>
                    <Space wrap>
                        <Select
                            value={selectedActivity}
                            className="custom-select" 
                            onChange={handleChange}
                            style={{ 
                                width: "220px", 
                                fontSize: "14px", 
                                fontFamily: "Inter", 
                                fontStyle: "normal", 
                                fontWeight: "400", 
                                marginBottom: "10px", 
                            }}
                            options={timelineFilters}
                            placeholder="All activities"
                        />
                    </Space>
                    <ClearFilterButton
                        onClick={clearActivityFilters}
                        style={{ position: "relative", left: "5px" }}
                    >
                        <p>Clear</p>
                    </ClearFilterButton>
                </div>
            </MainActivityHeading>
            {isFetching ? (
                <Loader />
            ) : (
                (activityResponse?.timeline && hasRelevantData()) ? ( 
                    <>
                        {Object.entries(activityResponse.timeline).map(([eventDate, events]) => (
                            <div key={eventDate}>
                                <ActivityDateHeading>
                                    <ActivityDateHeadingText>
                                        {formatEventDate(eventDate)}
                                    </ActivityDateHeadingText>
                                </ActivityDateHeading>

                                {events.map((event, index) => {
                                    const { description, time, metadata, type } = event;

                                    let address = '';
                                    let list_price = '';
                                    let beds = '';
                                    let baths = '';
                                    let area = '';
                                    let recommended_on = '';
                                    let image_url = '';
                                    let property_url = ''; 

                                    if (metadata?.property) {
                                        const { property } = metadata;
                                        address = property.address;
                                        list_price = property.list_price;
                                        beds = property.beds;
                                        baths = property.baths;
                                        area = property.area;
                                        recommended_on = property.recommended_on;
                                        image_url = property.image_url;
                                        property_url = property.property_url; 
                                    }

                                    const minutesAgo = getTimeDifference(time);

                                    return (
                                        <ActivityRow key={`${eventDate}-${index}`}>
                                            <ActivityRowHeading>
                                                {description}
                                            </ActivityRowHeading>

                                            {metadata?.property && (
                                                <CardContainer style={{ marginLeft: "30px" }}>
                                                    <ImageContainer>
                                                        <PropertyImage src={image_url} alt="Property image" />
                                                    </ImageContainer>
                                                    <PropertyDetails>
                                                        <PropertyTitle>{address}</PropertyTitle>
                                                        <PropertyInfo>
                                                            ${list_price}, {beds} beds, {baths} baths, {area} sqft
                                                        </PropertyInfo>
                                                        <RecommendedDate>Recommended on {formatRecommendedOnDate(recommended_on)}</RecommendedDate>
                                                    </PropertyDetails>
                                                    <ArrowIcon>
                                                        <a href={property_url} target="_blank" rel="noopener noreferrer">
                                                            <ArrowComponent />
                                                        </a>
                                                    </ArrowIcon>
                                                </CardContainer>
                                            )}

                                            {metadata?.reminder && (
                                                <ReminderText>
                                                    {metadata.reminder.note}
                                                </ReminderText>
                                            )}

                                            {metadata?.note && (
                                                <NoteText>
                                                    Note: {metadata.note.note}
                                                </NoteText>
                                            )}

                                            <ActivityRowText>
                                                <HistoryOutlined />
                                                {minutesAgo}
                                            </ActivityRowText>
                                        </ActivityRow>
                                    );
                                })}
                            </div>
                        ))}
                    </>
                ) : (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <CalendarOutlined style={{
                            color: "#6B6D8F",
                            fontSize: "66.5px",
                            marginTop: "180px"
                        }} />
                        <h1 style={{
                            fontFamily: "Inter",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "28px",
                        }}>No Events</h1>
                        <p style={{
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "28px",
                        }}>Your activities with this client will be listed here</p>
                    </div>
                )
            )}   
        </ActivityContainer>
    );
}

export default CRMV2Container(Activity);