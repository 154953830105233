import React from "react"
import styled from "styled-components"

const LoaderImage = styled.div`
  margin: ${props => props.margin || "0 auto"};
  width: ${props => props.size ? `${props.size}px` : "fit-content"};
  position: relative;
  top: ${props => props.top}px;
  bottom: ${props => props.bottom}px;
`

const StyledImage = styled.img`

  ${props => props.invert && `
    filter: brightness(0);
  `}
  ${props => props.size && `width: ${props.size}px;`}
`

const Loader = ({
  top,
  bottom,
  size,
  margin,
  style,
  invert,
}) => (
  <LoaderImage top={top} bottom={bottom} size={size} margin={margin} style={style}>
    <StyledImage
      // src="https://d2fedz0by71ckz.cloudfront.net/images/rds-logo-blue.gif"
      // src="https://s3.us-east-1.amazonaws.com/cdn.agentdesks.com/images/new_loader_100.gif"
      src="https://s3.us-east-1.amazonaws.com/cdn.agentdesks.com/images/Loader+Animation.gif"
      alt="Radius loader"
      size={size || 100}
      invert={invert}
    />
  </LoaderImage>
)

Loader.defaultProps = {
  top: 45,
  bottom: 45,
}

export default Loader
