import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toggleMessagesPanelAction, toggleHeaderBannerAction, promotionalPopUpCTAClckedAction } from "container/App/actions"
import {
  toggleInviteBannerAction,
  fetchSubscriptionsAction,
  fetchPricingPlansAction,
} from "container/Referral/actions"
import {
  getIsUserATeamMember,
  getIsUserATeamAdmin,
  getIsSubscribedToAnyISAPlan,
  getIsUserABroker,
} from "container/App/selector"
import * as AppActions from "container/App/actions"
import * as ReferralSelectors from "container/Referral/selector"
import {
  getFeedByHomeClickAction,
} from "container/Network/actions"
import * as NavbarActions from "./actions"

const mapStateToProps = (state, props) => {
  const {
    navbar,
    app = {},
    network,
    referral,
  } = state
  const {
    sideMenuUpdateresponse,
  } = referral
  const {
    profileImage,
    isNewFeeds,
    user,
    isIncompleteProfileBannerVisible,
    isHeaderBannerVisible,
    isAppMounted,
    scheduleCall,
  } = app
  const { isCardInactive } = user
  const { isOnboardingComplete, isFetchingFeeds } = network
  const { isShowInviteeBanner, shareTheLoveInviteeResponse } = referral
  const isUserATeamAdmin = getIsUserATeamAdmin(state)
  const isUserATeamMember = getIsUserATeamMember(state)
  const isSubscribedToAnyISAPlan = getIsSubscribedToAnyISAPlan(state)
  const isBroker = getIsUserABroker(state)
  return {
    ...navbar,
    ...props,
    isCardInactive,
    profileImage,
    isIncompleteProfileBannerVisible,
    isShowInviteeBanner,
    shareTheLoveInviteeResponse,
    pricingPlansMap: ReferralSelectors.pricingPlansMap(state),
    categorizedSubscriptions: ReferralSelectors.getCategorizedSubscriptions(state),
    finishedFetchingSubscriptions: ReferralSelectors.getFinishedFetchingSubscriptions(state),
    isOnboardingComplete,
    isNewFeeds,
    isFetchingFeeds,
    isHeaderBannerVisible,
    isUserATeamAdmin,
    isUserATeamMember,
    isAppMounted,
    isBroker,
    scheduleCall,
    user,
    isSubscribedToAnyISAPlan,
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      fetchNotification: NavbarActions.fetchNotificationAction.request,
      fetchNotificationUnreadCount: NavbarActions.fetchNotificationUnreadCountAction.request,
      startTabsSpotlightFlow: NavbarActions.startTabsSpotlightFlowAction.call,
      togglePressReleaseBanner: NavbarActions.togglePressReleaseBannerAction.call,
      toggleMessagesPanel: toggleMessagesPanelAction,
      toggleHeaderBanner: toggleHeaderBannerAction.call,
      fetchSubscriptions: fetchSubscriptionsAction.request,
      fetchPricingPlans: fetchPricingPlansAction.request,
      toggleInviteBanner: toggleInviteBannerAction.call,
      triggerInterest: promotionalPopUpCTAClckedAction.request,
      notificationViewed: NavbarActions.notificationViewedAction.request,
      getFeedByHomeClick: getFeedByHomeClickAction.call,
      toggleIncompleteProfileBanner: AppActions.toggleIncompleteProfileBannerAction.call,
      updateIncompleteProfile: AppActions.updateIncompleteProfileAction.request,
      getSubscriptionPlans: NavbarActions.getSubscriptionPlansAction.request,
      fetchPayment: NavbarActions.fetchPaymentAction.request,
      editPayment: NavbarActions.editPaymentAction.request,
      deletePayment: NavbarActions.deletePaymentAction.request,
      defaultPayment: NavbarActions.defaultPaymentAction.request,
      setPlanAvailableObject: NavbarActions.setPlanAvailableObjectAction.call,
      addSubscriptionPlan: NavbarActions.addSubscriptionPlanAction.request,
      fetchAddOns: NavbarActions.fetchAddOnsAction.request,
      updateSubscription: NavbarActions.updateSubscriptionAction.request,
      fetchInvoiceData: NavbarActions.fetchInvoiceDataAction.request,
      setPaymentObject: NavbarActions.setPaymentObjectAction.call,
      setLoadingCard: NavbarActions.setLoadingCardAction.call,
      makeDefaultMethod: NavbarActions.makeDefaultMethodAction.request,
    }, dispatch),
  }
)

export default connect(mapStateToProps, mapDispatchToProps)
