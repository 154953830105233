import React from "react"
import styled from "styled-components"
import { ATC } from "@fonts"
import OnboardingContainer from "container/OnboardingSetup"
import Button from "@ui/LoaderButton"
// import ChecklistImage from "../../Images/checklist-img1.png"

const FormWrap = styled.div`
  margin-left: 31px;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar { 
    display: none;
  }
`
const FirstItem = styled.div`
  display: flex;
`
const ContinueButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  color: white;
  gap: 10px;
  width: 449.43px;
  height: 48px;
  background: #5A5FF2;
  border-radius: 36px;
  margin-top: 24.5px;
  margin-bottom: 16px;
  border: 0px;
`
const GoBackButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 10px;
  width: 449.43px;
  margin-bottom: 16px;
  height: 48px;
  border: 1.25px solid #5A5FF2;
  border-radius: 36px;
  color: #5A5FF2;
`

class FifthFormDetails extends React.PureComponent {
  state = {
    form: {
      step_completed: "checklist_screen_info",
    },
  }

  updateOrientationInfoFunction= () => {
    const { updateOrientationInfo, setFormStage } = this.props
    const { form } = this.state
    const payload = { ...form }
    this.setState({
      isLoading: true,
    })
    updateOrientationInfo({ payload, setStage: "0" })
    // setFormStage("0")
  }

  goBackFunction= () => {
    const { setFormStage, setIsGoBack } = this.props
    setIsGoBack(true)
    setFormStage("4")
  }

  render() {
    const { isLoading } = this.state
    return (
      <FormWrap>
        <FirstItem>
          <img src={`${CLOUDFRONT}/checklist-img1.png`} alt="Checklist" width="460px"/>
        </FirstItem>
        <ContinueButton isShowLoader={isLoading} onClick={this.updateOrientationInfoFunction}>Finish</ContinueButton>
        <GoBackButton onClick={this.goBackFunction}>Go back</GoBackButton>
      </FormWrap>
    )
  }
}

export default OnboardingContainer(FifthFormDetails)
