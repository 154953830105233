import Modal from "@ui/Modal"
import React from "react"
import styled from "styled-components"
import colors from "@colors"
import { withRouter } from "react-router-dom"
import Rooms from "container/Rooms"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import {
  RoomsButton,
  CTAWrap,
  RoomsDeleteButton,
  RoomsButtonInverted,
} from "../CreateRoomV2/common"
import { ATC } from "@fonts"

const Question = styled.div`
  font-family: Butler;
  font-size: 30px;
  font-weight: 700;
  line-height: 37px;
  -webkit-letter-spacing: 0.015em;
  -moz-letter-spacing: 0.015em;
  -ms-letter-spacing: 0.015em;
  text-align: left;
  color: #303030;

  letter-spacing: 0.009em;
  text-align: left;
  color: #303030;
  word-spacing: -1px;
  width: 60%;
`

const Warning = styled.p`
  font-family: ${ATC};
  font-size: 18px;
  font-weight: 300;
  line-height: 37px;
  -webkit-letter-spacing: 0.015em;
  -moz-letter-spacing: 0.015em;
  -ms-letter-spacing: 0.015em;
  text-align: left;
  letter-spacing: 0.009em;
  text-align: left;
  color: #303030;
  margin-top: 20px;
`

const DeleteRoom = ({
  toClose,
  data,
  deleteScheduledRoom,
  history,
  deleteScheduledRoomsResponse,
}) => {
  const {
    isFetching: deletingRoom,
  } = deleteScheduledRoomsResponse || {}

  return (
    <Modal
      show
      toClose={toClose}
      modalVersion={3}
      modalStyles={{
        backgroundColor: colors.dreamWhite,
        padding: "50px",
        height: "max-content",
        minHeight: "230px",
      }}
      dialogStyles={{
        width: "max-content",
        borderRadius: "6px",
      }}
      overlayStyles={{
      }}
    >
      <Question>
        Are you sure you want to delete this scheduled room?
      </Question>
      <Warning>
        It is an irreversible action, but you can always schedule a new room again.
      </Warning>
      <CTAWrap
        style={{
          marginTop: "40px",
        }}
      >
        <RoomsButtonInverted
          type="button"
          onClick={() => {
            toClose()
          }}
        >
          {deletingRoom ? (
            <ThreeDotLoader color={colors.new_primary} />
          ) : (
            <p>No</p>
          )}
        </RoomsButtonInverted>
        <RoomsDeleteButton
          type="button"
          onClick={() => {
            deleteScheduledRoom({
              id: data.id,
              data,
              history,
            })
          }}
        >
          {deletingRoom ? (
            <ThreeDotLoader color={colors.errorDanger} />
          ) : (
            <p>Yes</p>
          )}
        </RoomsDeleteButton>
      </CTAWrap>
    </Modal>
  )
}

export default Rooms(withRouter(DeleteRoom))
