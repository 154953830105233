import React from "react"
import styled, { css } from "styled-components"
import { isEmpty, get } from "lodash"
import Button from "@ui/Button"
import { removeCommaFromNumber, MoneyFormatter } from "services/Utils"
import Box from "@ui/Box"
import {
  Wrapper,
  Footer,
  Header,
  BlankButton,
  ProfileCompletionButton,
  ErrorText,
} from "./commonStyles"
import SyncMLSForm from "./SyncMLSForm"
import AddListing from "./AddListing"

const Tabs = styled.ul`
  display: flex;
  border-bottom: 1px solid #c6c9cb;
  margin: 10px;
`

const Tab = styled.li`
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.2);
  padding: 8px 16px;
  border-bottom: 3px solid transparent;
  cursor: pointer;

  ${props => props.isActive
    && css`
      color: #5A5FF2;
      border-bottom: 3px solid #5A5FF2;
    `}
`

const ListingList = styled.ul`
  > li {
    border: 1px solid #e6e8e9;
    padding: 12px;
    display: flex;
    margin: 10px;
    border-radius: 4px;
    background: #fbfcfc;
    align-items: center;

    h6 {
      font-weight: 400;
      flex: 1;
      margin: 0px;
      padding-right: 10px;
    }

    span {
      color: #eaeaea;
      flex: 1;
      font-weight: 400;
    }
  }
`

const FormWrap = styled(Box)``

const AddAnother = styled.div`
  background: #f3f9fb;
  border: 1px solid #eaeaea;
  padding: 14px 12px;
  border-radius: 4px;
  color: #5A5FF2;
  margin: 10px;
  cursor:pointer;
`

class SyncMLS extends React.Component {
  constructor(props) {
    super(props)
    const TABS = ["Add Listing Manually"]
    const {
      initialValues: { mlsInfo },
      activeTab,
    } = props
    this.state = {
      isShowAddMLSForm: mlsInfo && mlsInfo.length === 0,
      tabs: ["Add Listing Manually"],
      activeTab: "Add Listing Manually",
    }
  }

  selectATab = (tab) => {
    this.setState({
      activeTab: tab,
    })
  }

  toggleAddMLS = () => {
    const { isShowAddMLSForm } = this.state
    this.setState({
      isShowAddMLSForm: !isShowAddMLSForm,
    })
  }

  handleAddListing = (values = {}) => {
    if (!isEmpty(values)) {
      const { syncMLSPRops } = this.props
      const { saveAddListing, propertyImages } = syncMLSPRops
      const {
        property_type: { value: propertyType = "" } = {},
        property_status: { value: propertyStatus = "" } = {},
        resale_price,
        covered_area,
      } = values

      let resalePrice = resale_price
      if (typeof resalePrice === "number") {
        resalePrice = resale_price.toString()
      }

      const parsed_covered_area = removeCommaFromNumber(covered_area)
      const parsed_resale_price = MoneyFormatter(resalePrice)
      const payload = {
        ...values,
        sqft_price: parseFloat(parsed_resale_price / parsed_covered_area).toFixed(2) || 0,
        covered_area: parsed_covered_area,
        property_type: propertyType.toLowerCase(),
        resale_price: parsed_resale_price,
        property_status: propertyStatus.toLowerCase(),
        image_urls: (propertyImages && propertyImages.length > 0 && propertyImages.map(item => item.image_url)) || null,
      }
      saveAddListing(payload)
    }
  }

  handleSave = () => {
    const { syncMLSPRops } = this.props
    const { syncMLS, syncMlsValues } = syncMLSPRops
    const payload = {
      ...syncMlsValues,
      license_state: get(syncMlsValues, "serving_id.license_state"),
      serving_id: syncMlsValues.serving_id.value,
      state: syncMlsValues.state.value,
    }
    syncMLS({ payload })
  }

  componentDidMount() {
    const { syncMLSPRops } = this.props
    const { getMlsServingLists } = syncMLSPRops
    getMlsServingLists()
  }

  onRemoveMls = (mlsId) => {
    const { syncMLSPRops } = this.props
    const { removeSyncedMLS } = syncMLSPRops
    removeSyncedMLS({ mlsId })
  }

  render() {
    const {
      syncMLSPRops,
      skipProfileCompletionStep,
      nextStep,
      nextToNextStep,
      isProfileCompletion,
      getPropertyStatus,
      MLSSyncError,
    } = this.props
    const {
      isSavingUpdates,
      agent: {
        mlsInfo = [],
        mlsLicense = "",
        mlsLicenseState = "",
        mlsPropertiesCount = 0,
      } = {},
      getPropertyTypes,
      preFetchedTypes,
      preFetchedStatus,
      uploadPropertyImages,
      agentId,
      isUploadingPropertyImages,
      propertyImages,
      isRemovingMls,
    } = syncMLSPRops
    const { tabs, activeTab, isShowAddMLSForm } = this.state
    return (
      <Wrapper style={{ margin: 0 }}>
        <Header style={{ margin: 0 }}>
          <h4>Add Listings</h4>
        </Header>
        <Tabs>
          {tabs.map(item => (
            <Tab
              key={item}
              isActive={item === activeTab}
              onClick={() => {
                this.selectATab(item)
              }}
            >
              {item}
            </Tab>
          ))}
        </Tabs>
        {/* {activeTab === tabs[0] && (
          <div style={{ margin: 20 }}>
            <FormWrap padding="20px 10px 25px 10px">
              <ListingList>
                {mlsInfo.map(item => (
                  <li key={item.id}>
                    <h6>{item.name || "-"}</h6>
                    <span>{`ID#${item.license || ""}`}</span>
                    <Button bsStyle="secondary" onClick={() => this.onRemoveMls(item.id)}>
                      {isRemovingMls === item.id ? "Removing..." : "Remove"}
                    </Button>
                  </li>
                ))}
              </ListingList>
            </FormWrap>
            <AddAnother
              onClick={() => {
                this.toggleAddMLS()
              }}
            >
              Add another MLS
            </AddAnother>
            {isShowAddMLSForm && (
              <SyncMLSForm
                syncMLSProps={syncMLSPRops}
                isSavingUpdates={isSavingUpdates}
                onSubmit={this.handleSave}
                MLSSyncError={MLSSyncError}
              />
            )}
          </div>
        )} */}
        {console.log(activeTab, "activeTab")}
        {console.log(tabs)}
        {activeTab === tabs[0] && (
          <AddListing
            agentId={agentId}
            isSavingUpdates={isSavingUpdates}
            getPropertyStatus={getPropertyStatus}
            getPropertyTypes={getPropertyTypes}
            preFetchedStatus={preFetchedStatus}
            preFetchedTypes={preFetchedTypes}
            uploadPropertyImages={uploadPropertyImages}
            isUploadingPropertyImages={isUploadingPropertyImages}
            propertyImages={propertyImages}
            onSubmit={this.handleAddListing}
          />
        )}
        {isProfileCompletion && (
          <Footer
            d="flex"
            style={{
              justifyContent: nextToNextStep ? "space-between" : "flex-end",
              padding: 15,
            }}
          >
            { nextToNextStep && (
              <BlankButton
                onClick={() => { skipProfileCompletionStep(nextStep) }}
                normal
              >
                Skip
              </BlankButton>
            )}
            <ProfileCompletionButton
              handleClick={this.handleSave}
              nextToNextStep={nextToNextStep}
              isShowLoader={isSavingUpdates}
            />
          </Footer>
        )}
      </Wrapper>
    )
  }
}

export default SyncMLS
