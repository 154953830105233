import React, { PureComponent } from "react"
import styled from "styled-components"
import icons from "@icons"
import SearchIcon from "dumbComponents/RealtyV3/RARRecruitment/images/Search.svg"
import TechAssetsContainer from "container/TechAssets"
import cookie from "services/CookieStorage"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import TCContainer from "container/Realty/TransactionFormContainer"
import colors from "@colors"
import { ATC } from "@fonts"
import { withRouter } from "react-router-dom"
import { CheckOutlined } from "@ant-design/icons"

const DroDownArea = styled.div`
  position: absolute;
  border-radius: 4px;
  top: 40px;
  left: 0;
  right: 0px;
  min-width: 50px;
  box-shadow: 0px 0px 4px 0px #c7bebe;
  padding-bottom: 16px;
  background: #fff;
  display: none;
  // display: block;
  width: 240px;
  z-index: 999999;

  ${props => props.activeDropDown && `
    display: block;
  `}
`

const DropDownContainer = styled.div`
  padding: 10px 10px 0 7px;
  font-size: 18px;
  h4 {
    padding: 12px 18px;
    margin: 0px;
    cursor: pointer;
    font-weight: 200;
    font-family: ${ATC};
    color: ${colors.black};
    &:hover {
      background: ${colors.fadedBlueSea};
    }
  }
`

const Divider = styled.div`
  border: 1px solid #e5e5e5;
  width: 98%;
  margin: 7px 0 4px 1px;
`

const TeamMemberContainer = styled.div``

const TeamMemberWrap = styled.div`
  overflow-y: scroll;
  height: 180px;
  // margin-top: 20px;
`

const FitlerText = styled.h4`
  cursor: pointer;
  display: flex;
  font-weight: 200;
  font-family: ${ATC};
  color: ${colors.black};
  align-items: center;
  justify-content: space-between;
  ${props => props.selected && `
    background: #eff7fc;
  `} 
  h4 {
    padding: 0px 0px;
  }
`

const SearchWrap = styled.div`
  margin: -5px 0px 7px -20px;
  z-index: 999999;
  input {
    font-family: ${ATC};
    font-weight: 200;
    border-radius: 0px;
    height: 42px;
    font-size: 16px;
    padding: 10px 38px;
    border: 1px solid ${colors.moonGrey};
    width: 210px;
    margin-left: 30px;
    border-radius: 5px;
    z-index: 999999;
  }
  input::placeholder {
    color: #C5C5C5;
  }
  img {
    position: relative;
    left: 36px;
    top: 34px;
    height: 18px;
  }
  label {
    display: none;
  }
`

const DropdownIcon = styled.i`
  position: relative;
  transition-duration: 0.1s;
  color: ${colors.new_primary};
  display: inline-block;
  ${props => props.isActive && `
    transform: rotate(180deg);
  `}
  font-size: 18px;
  margin-left: 8px;
  top: 1px;
  color: ${colors.black};
`

const TeamFilterHeader = styled.div`
  position: relative;

  h2 {
    margin: 0px;
    cursor: pointer;
    font-weight: 400;
    font-size: 22px;
    font-family: ${ATC};
    color: ${colors.black};
  }
`

class TeamFilterComponent extends PureComponent {

  state = {
    selectedMenu: "My Listings",
    activeDropDown: false,
    searchTextTeamMember: "",
    selectedMembers: [],
  }

  componentDidMount() {
    const { defaultInitialName, secondValueOption } = this.props
    const tcInformation = cookie.load("tcInformation")
    this.setState({
      selectedMenu: defaultInitialName,
    })
    if (tcInformation?.is_tc) {
      setTimeout(() => {
        this.handleFilterChange(secondValueOption)
      }, 500)
      
    }
    document.addEventListener("click", (e) => {
      if (!e.target.classList.contains("other_property")) {
        this.setState({ activeDropDown: false })
      }
    })
  }

  handleFilterChange = (changedOption, memberSelected) => {
    const { handleMenuClick, multiselect, defaultInitialName } = this.props
    const { selectedMembers } = this.state
    let newSelectedMembers = []
    if (memberSelected) {
      if (selectedMembers.map(dt => dt.label)?.includes(changedOption.label)) {
        newSelectedMembers = selectedMembers.filter(member => member.label !== changedOption.label)
        this.setState({
          selectedMembers: [...newSelectedMembers],
        })
      } else {
        newSelectedMembers = [...selectedMembers, changedOption]
        this.setState({
          selectedMembers: [...newSelectedMembers],
        })
      }
      if (newSelectedMembers?.length) {
        this.setState({
          selectedMenu: changedOption.label,
        })
      } else {
        this.setState({
          selectedMenu: defaultInitialName,
        })
      }
    } else {
      this.setState({
        selectedMembers: [],
        selectedMenu: changedOption.label,
      })
    }
    if (memberSelected && multiselect && newSelectedMembers?.length) {
      handleMenuClick(newSelectedMembers, multiselect)
    } else {
      handleMenuClick(changedOption)
    }
  }

  handleMouseOver = (activeDropDown) => {
    this.setState({
      activeDropDown,
    })
  }

  searchTeamMember = (event) => {
    this.setState({
      searchTextTeamMember: event.target.value,
    })
  }

  getFiltersOptions = () => {
    const { getTeamDetailsResponse } = this.props
    const {
      data: teamDetails,
    } = getTeamDetailsResponse || {}
    const user = cookie.load("user") || {}
    const teamNames = (teamDetails && teamDetails.team_members) ? teamDetails.team_members.filter(td => td.agent_id !== user.id).map(tm => ({
      key: `${tm.agent_id}::${tm.firstname}`,
      label: `${tm.firstname} ${tm.lastname || ""}`,
    })) : []

    return [
      ...teamNames,
    ]
    // { value: "view_specific_team_member", label: `${td.firstname || ""}
  }

  render() {
    const { selectedMenu, activeDropDown, searchTextTeamMember, selectedMembers } = this.state
    const tcInformation = cookie.load("tcInformation")
    const { handleMenuClick, firstValueOption, secondValueOption, firstValueLabel, secondValueLabel, multiselect } = this.props

    return (
      <TeamFilterHeader onMouseEnter={() => this.handleMouseOver(true)}>
        <h2>
          {multiselect ? (<span>{selectedMembers.length > 1 ? "Teams" : selectedMenu}</span>) : (<span>{selectedMenu}</span>)}
          <DropdownIcon
            className={icons.arrowsExpandDown}
            isActive={activeDropDown}
          />
        </h2>
        <DroDownArea activeDropDown={activeDropDown} onMouseLeave={() => this.handleMouseOver(false)}>
          <DropDownContainer>
            {!(tcInformation?.is_tc) && (<h4 onClick={() => this.handleFilterChange(firstValueOption)}>{firstValueLabel}</h4>)}
            <h4 onClick={() => this.handleFilterChange(secondValueOption)}>{secondValueLabel}</h4>
            <Divider />
            <TeamMemberContainer>
              <SearchWrap>
                <img src={SearchIcon} alt="search.svg" />
                <input
                  className="other_property"
                  label=""
                  placeholder="Search Member"
                  value={searchTextTeamMember}
                  onChange={this.searchTeamMember}
                />
              </SearchWrap>
              <TeamMemberWrap className="other_property">
                {this.getFiltersOptions() && this.getFiltersOptions().filter(td => td.label.toLowerCase().includes(searchTextTeamMember.toLocaleLowerCase())).map(td => (
                  <FitlerText className="other_property" selected={multiselect ? selectedMembers?.map(member => member.label)?.includes(td.label) : false}>
                    <h4 className="other_property" onClick={() => this.handleFilterChange(td, true)}>{td.label}</h4>
                    {multiselect && selectedMembers?.map(member => member.label)?.includes(td.label) && (<CheckOutlined style={{ fontSize: "16px", color: "#08c" }} />)}
                  </FitlerText>
                ))}
              </TeamMemberWrap>
            </TeamMemberContainer>
          </DropDownContainer>
        </DroDownArea>
      </TeamFilterHeader>
    )
  }
}

export default TCContainer(withRouter(TechAssetsContainer(TeamProfileContainer(TeamFilterComponent))))
