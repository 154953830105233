import styled from "styled-components"
import colors from "@colors"
import { ATC } from "@fonts"

export const Header = styled.div`
  p {
    //styleName: H3/Desktop;
    font-family: Butler;
    font-size: 30px;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: 0.015em;
    text-align: left;
    color: ${colors.black};
  }
`

export const SubText = styled.div`
  p {
    font-family: ${ATC};
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.black};
  }
`

export const FormTitle = styled.div`
  p {
    font-family: ${ATC}, sans-serif;
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: ${colors.black};
  }
`

export const UserInput = styled.input`
  width: 100%;
  height: 54px;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: none;
  margin-top: 25px;
  padding-left: 20px;
`

export const TagsSpan = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 10px;
`

export const TagCardDiv = styled.div`
  padding: 4px 12px;
  gap: 4px;
  background: #F1F3F8;
  border-radius: 36px;
  display: flex;
  width: max-content;

  > p {
    margin: 0px;
  }    
`

export const TagButton = styled.button`
  border: none;
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  cursor: pointer;
  img {
    width: 15px;
    height: 20px;
  }
`

export const FormWrap = styled.div`
  display: flex;
  flex-direction: row;
`

export const InnerFormWrap = styled.div`

`

export const FormTopic = styled.div`
 p {
  font-family: ${ATC};
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
 }
`
export const RoomsButton = styled.button`
  height: 32px;
  width: 206px;
  border-radius: 0px;
  background: ${colors.new_primary};
  color: ${colors.white};
  border: none;
  cursor: pointer;

  p {
    font-family: ${ATC};
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    margin: 0;
  }
`

export const RoomsDeleteButton = styled.button`
  height: 32px;
  width: 206px;
  border-radius: 0px;
  background: transparent;
  border: 1px solid ${colors.errorDanger};
  cursor: pointer;

  p {
    font-family: ${ATC};
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    margin: 0;
    color: ${colors.errorDanger};
  }
`

export const RoomsButtonInverted = styled.button`
  height: 32px;
  width: 206px;
  border-radius: 0px;
  background: transparent;
  border: 1px solid ${colors.new_primary};
  cursor: pointer;

  p {
    font-family: ${ATC};
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    margin: 0;
    color: ${colors.new_primary};
  }
`

export const CTAWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
`
