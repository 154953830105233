/* eslint-disable react/jsx-boolean-value */
import React, { useRef, useState, useEffect } from "react"
import moment from "moment"
import {
  Form,
} from "@ui/antd"
import Listings from "container/Listings/index"
import {
  SelectField,
  FloatingCTA,
  InputDatePicker,
  InputRadio,
} from "dumbComponents/common/InputFields"
import {
  FormWrap,
  ExtraWrap,
  SubmitWrap,
  ValidationNumberCircle,
  StateWrap,
  GreyState,
  State,
} from "./commonStyles"
import {
  CLIENT_SOURCE,
  YES_OR_NO,
} from "./formConfig"

const ListingTermsForm = ({
  onFinishFailed,
  handleSubmit,
  prefilledData,
  onValueChange,
  saving,
  disableForm,
  key,
  listingData,
  form,
  state,
}) => {
  const formRef = useRef(null)
  const [isPublicView, setPublicStatus] = useState(0)
  const [isSelectionDisabled, setIsSelectionDisabled] = useState(false)

  const {
    dealId,
  } = listingData || {}

  const getInitialValues = () => {
    let initValues = {}

    if (prefilledData && Object.keys(prefilledData).length > 0) {
      initValues = { ...prefilledData }
      initValues.listing_contract_date = prefilledData.listing_contract_date ? moment(prefilledData.listing_contract_date * 1000) : null
      initValues.listing_on_market_date = prefilledData.listing_on_market_date ? moment(prefilledData.listing_on_market_date * 1000) : null
      initValues.listing_expiration_date = prefilledData.listing_expiration_date ? moment(prefilledData.listing_expiration_date * 1000) : null
      initValues.property_marked_public = prefilledData.property_marked_public ? 1 : 0
    }
    return initValues
  }

  useEffect(() => {
    if (prefilledData && prefilledData.property_marked_public) {
      const currValue = prefilledData.property_marked_public ? 1 : 0
      setPublicStatus(currValue)
    }
  }, [prefilledData])

  useEffect(() => {
    const formValues = form.getFieldsValue(['listing_contract_date', 'listing_on_market_date']);
    const listingDate = formValues.listing_contract_date;
    const marketDate = formValues.listing_on_market_date;

    if (listingDate && marketDate) {
      const daysDifference = marketDate.diff(listingDate, 'days');
      
      if (daysDifference > 2) {
        setPublicStatus(1); 
        setIsSelectionDisabled(true);
      } else {
        setPublicStatus(0); 
        setIsSelectionDisabled(false);
      }
    } else {
      setPublicStatus(0); 
      setIsSelectionDisabled(false);
    }
  }, [form.getFieldValue('listing_contract_date'), form.getFieldValue('listing_on_market_date')]);

  const handleRadioChange = (e) => {
    if (!isSelectionDisabled) {
      const { value } = e.target;
      setPublicStatus(value !== 1 ? 0 : 1);
    }
  }

  return (
    <ExtraWrap>
      <StateWrap>
        <GreyState>
          <p>State</p>
        </GreyState>
        <State>
          <h2>{state}</h2>
        </State>
      </StateWrap>
      <FormWrap
        id={`${key}_wrap`}
        key={key}
      >
        <Form
          name="listing_terms"
          preserve
          colon={false}
          layout="vertical"
          requiredMark={false}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          initialValues={getInitialValues()}
          onKeyDown={(e) => { e.stopPropagation() }}
          onValuesChange={onValueChange}
          ref={formRef}
          disabled={disableForm}
          id={`${key}_form`}
          key={key}
          form={form}
        >
          {/* <SelectField
            isFormField
            name="source"
            options={CLIENT_SOURCE}
            placeholder="Lead Source"
            text="Lead Source"
            required={true}
            requiredMessage="Required."
          /> */}

          <InputDatePicker
            name="listing_contract_date"
            text="Listing Start Date"
            required={true}
            requiredMessage="Listing start date is required"
          />

          <InputDatePicker
            name="listing_on_market_date"
            text="On Market Date"
            required={true}
            requiredMessage="On market date is required"
          />

          <InputDatePicker
            name="listing_expiration_date"
            text="Listing Expiration Date"
            required={true}
            requiredMessage="Listing expiration date is required"
          />

          {state && state === "California" && (
            <>
              <InputRadio
                name="property_marked_public"
                question="Would you like to include a seller instruction to Exclude Listings from the MLS(SELM) with your listing?"
                required={true}
                requiredMessage="Required."
                options={YES_OR_NO}
                value={isPublicView}
                defaultValue={isPublicView}
                onChange={handleRadioChange}
                text="Please choose an option"
                disabled={isSelectionDisabled}
              />
            </>
          )}

          {!dealId && (
            <FloatingCTA customText="Create Listing" htmlType="submit" disabled={saving} loading={saving} />
          )}
        </Form>
      </FormWrap>
    </ExtraWrap>

  )
}

export default Listings(ListingTermsForm)

