import styled from "styled-components"
import { ONBOARDING_BANNER_HEIGHT } from "@consts"
import { ATC, INTER, MONTSERRAT } from "@fonts"
import { InfoCircleOutlined } from "@ant-design/icons"

export const DashboardWrap = styled.div`
  ${props => props.isOnboardingBannerVisible && `
    padding-top: ${ONBOARDING_BANNER_HEIGHT}px;
  `}
  padding-bottom: 3rem;
`
export const HeaderWrap = styled.div`
display: flex;
padding: 50px 38px 40px 38px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 40px;
align-self: stretch;
border-bottom: 1px solid var(--Global-Mapping-Gray-50, #EFEFF4);
`
export const Heading = styled.div`
display: flex;
align-items: center;
gap: 40px;
align-self: stretch;
color: var(--Global-Mapping-Gray-700, #373758);
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 135%
letter-spacing: -0.48px;
`

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
export const OverviewWrap = styled.div`
display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;
border-radius: 8px;
border: 1px solid var(--Color-Gray-Gray-100, #E0E0EB);
`
export const OverviewComponent = styled.div`
display: flex;
padding-top: 8px;
align-items: flex-start;
gap: 120px;
align-self: stretch;
`
export const TabsWrapper = styled.div`
display: flex;
padding: 0px 32px;
justify-content: space-between;
align-items: flex-start;
align-self: stretch;
border-bottom: 1px solid var(--Global-Mapping-Gray-50, #EFEFF4);
`
export const DocumentLibWrapper = styled.div`
justify-content: space-between;
align-items: flex-start;
display: flex;
padding: 16px 0px 16px 24px;
align-items: center;
gap: 8px;
border-radius: 32px;
white-space: nowrap;

p {
    color: var(--Global-Mapping-Gray-700, #373758);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%; /* 21.6px */
    letter-spacing: 0.5px;
    white-space: nowrap;
  }
`

export const AddCTAWrap = styled.div`
display: flex;
align-items: center;
gap: 16px;`

export const AlertDiv = styled.div`
display: flex;
width: 100%;
margin-top: 12px;
padding: 12px 24px;
justify-content: space-between;
align-items: center;
background: var(--Color-Red-Red-100, #FAC9C9);
`
export const InfoDiv = styled(InfoCircleOutlined)`
  position: relative;
  margin-top: 4px;
  width: 20px;
  color: #AA3A3A;
`
export const CommonText = styled.div`
color: ${props => props.color ? props.color : "#6B7280"}
align-self: stretch;
font-family: Inter;
font-size: ${props => props.fontSize ? props.fontSize : "14px"}
font-style: normal;
font-weight: ${props => props.weight ? props.weight : 400}
${props => props.marginTop && `
  margin-top: 10px
`}
`
export const AlertButton = styled.button`
${props => props.width && `
    width: 50%
`}
display: flex;
padding: 8px 12px;
justify-content: center;
align-items: center;
border-radius: 8px;
border: 1px solid var(--Color-Red-Red-500, #F05152);
background: var(--Color-Red-Red-500, #F05152);
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
p {
color: var(--Global-Mapping-White, #FDFDFD);
text-align: justify;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 14px;
margin: 0px
}`
