import React from "react"
import styled from "styled-components"
import CRMV2Container from "container/CRM"
import colors from "@colors"
import root from "window-or-global"
import SettingIcon from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/settings.svg"
import TechAssets from "container/TechAssets/index"
import PlusIcon from "images/icons/WhiteIconPlus.png"
import Plus from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/Plus.js";
import { ATC } from "@fonts"

const HeaderWrap = styled.div`
  border-bottom: 1px solid #D3DAE8;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  ${props => props.noborder && `
  border: none;
`}
`

const H1 = styled.h1`
  font-family: ${ATC};
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;  
`

const CTAButton = styled.div`
  cursor: pointer;
`

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 32px;
  border: 1px solid var(--Global-Mapping-Gray-100, #E0E0EB);
  padding: 5px 12px;
`

const Title = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: var(--Global-Mapping-Gray-700, #373758);
  font-family: ${ATC};
  font-size: 16px;
`
const Wrap = styled.div`
    display: flex;

`

const Header = ({setCurrentDrawerPage, toggleAddNewLeadDrawer, crmClietClear}) => (
  <HeaderWrap>
    <H1>My Active Clients</H1>
    <Wrap>
      <CTAButton
        className="zapier_integration"
        onClick={() => {
          root.open("/settings/integrations/apigeneration", "_blank")
        }}
      >
        <ButtonWrap>
          <img src={SettingIcon} alt="setting" />
          <Title>
            Zapier Integrations
          </Title>
        </ButtonWrap>
      </CTAButton>
      <CTAButton
        className="zapier_integration"
        onClick={() => {
          setCurrentDrawerPage("add-lead")
          toggleAddNewLeadDrawer(true)
          crmClietClear()
        }}
        style={{marginLeft: "10px"}}
      >
        <ButtonWrap style={{paddingRight: "25px", paddingLeft: "25px"}}>
          <Title>
          <Plus color="black" style={{marginRight: "5px"}}/>Client
          </Title>
        </ButtonWrap>
      </CTAButton>
    </Wrap>
  </HeaderWrap>
)

export default CRMV2Container(TechAssets(Header))
