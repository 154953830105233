export const MONTH_DATE = "MMMM DD"

export const DEFAULT_REFERRAL_MANAGER = {
  firstname: "Craig Carle",
  phone: "+1415792013",
  id: 7857161,
}

export const YOUTUBE = "youtu.be"
export const YOUTUBECOM = "www.youtube.com"
export const VIMEO = "vimeo.com"
export const VIMEOCOM = "player.vimeo.com"
export const LOOM = "www.loom.com"
export const MIN_DESKTOP_BREAKPOINT_WIDTH_LIMIT = 1100
export const L_MAX_MOBILE_BREAK_POINT_LIMIT = 450
export const TABLET_BREAK_POINT_768 = "768px"

export const SM_DESKTOP_BREAK_POINT = "1100px"
export const XS_MAX_MOBILE_BREAK_POINT = "375px"
export const M_MIN_MOBILE_BREAK_POINT = "376px"
export const L_PRE_MOBILE_BREAK_POINT = "424px"
export const L_MIN_MOBILE_BREAK_POINT = "425px"
export const L_MAX_MOBILE_BREAK_POINT = "450px"
export const PRE_TABLET_BREAKPOINT_WIDTH = "767px"
export const MIN_TABLET_BREAKPOINT_WIDTH = "768px"
export const PRE_DESKTOP_BREAKPOINT_WIDTH = "1099px"
export const MIN_DESKTOP_BREAKPOINT_WIDTH = "1100px"
export const MIN_MEDIUM_DESKTOP_BREAKPOINT_WIDTH = "1280px"
export const MIN_LARGE_DESKTOP_BREAKPOINT_WIDTH = "1440px"
export const X_LARGE_DESKTOP_BREAKPOINT_WIDTH = "1768px"
export const XX_LARGE_DESKTOP_BREAKPOINT_WIDTH = "1792px"

//NEW MEDIA QUERIES
// export const PRE_MEDIUM_DESKTOP_MEDIA_QUERY = "1099px"
// export const MIN_MEDIUM_DESKTOP_MEDIA_QUERY = "1100px"
// export const LARGE_DESKTOP_MEDIA_QUERY = "1280px"

export const MOBILE_BREAK_POINT = "800px"
export const XS_MOBILE_BREAK_POINT = "500px"
export const MD_DESKTOP_BREAK_POINT = "1120px"

export const ISA_PAGE_MOBILE_BREAKPOINT = 768

export const REFER_TEXT = "You’re about to refer this lead out to our marketplace." +
"You’ll receive 35% upon closing and can track the progress in outbound referrals."

export const ADD_REMINDERS_EVENTS = [
  { value: "call", label: "Call" },
  { value: "property showing", label: "Property Showing" },
  { value: "meeting", label: "Meeting" },
  { value: "sale", label: "Sale" },
  { value: "email", label: "Email" },
  { value: "follow up", label: "Follow up" },
]

export const SIGN_UP_FORM_AGENT_TYPE = [
  { value: "real_estate_agent", label: "Real Estate Agent" },
  { value: "loan_officer", label: "Loan Officer" },
  { value: "buyer_seller", label: "Buyer/Seller" },
  { value: "other", label: "Other" },
]

export const ADD_REMINDERS_TYPES = [{
  label: "At the time of event",
  value: "0m",
}, {
  label: "5 minutes before",
  value: "5m",
}, {
  label: "15 minutes before",
  value: "15m",
}, {
  label: "30 minutes before",
  value: "30m",
}, {
  label: "1 hour before",
  value: "1h",
}, {
  label: "2 hours before",
  value: "2h",
}, {
  label: "1 day before",
  value: "1d",
}, {
  label: "1 week before",
  value: "1w",
}]

export const SUPPORTED_COUNTRY_PHONENUMBER_CODES = [
  "+1", // USA
  "+91", // INDIA
]

export const PHONE_REGEX = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/

export const mobile = styles => `
   @media (max-width: ${MOBILE_BREAK_POINT}){
     ${styles}
   }
 `

 export const xsMobile = styles => `
  @media (max-width: ${XS_MOBILE_BREAK_POINT}){
    ${styles}
  }
 `

 export const mdDesktop = styles => `
 @media (max-width: ${MD_DESKTOP_BREAK_POINT}){
   ${styles}
 }
 `

export const smallMobileMaxWidth = styles => `
 @media(max-width: ${XS_MAX_MOBILE_BREAK_POINT}){
   ${styles}
 }
`

export const minMediumMobileWidth = styles => `
 @media(min-width: ${M_MIN_MOBILE_BREAK_POINT}){
   ${styles}
 }
`
export const minLargeMobileWidth = styles => `
 @media(min-width: ${L_MIN_MOBILE_BREAK_POINT}){
   ${styles}
 }
`

export const preLargeMobileWidth = styles => `
 @media(min-width: ${L_PRE_MOBILE_BREAK_POINT}){
   ${styles}
 }
`

export const smallMobileMax = styles => `
 @media(max-width: ${XS_MAX_MOBILE_BREAK_POINT}){
   ${styles}
 }
`

 export const tablet = styles => `
 @media (max-width: ${TABLET_BREAK_POINT_768}){
   ${styles}
 }
`

export const preTabletWidth = styles => `
@media (max-width: ${PRE_TABLET_BREAKPOINT_WIDTH}){
  ${styles}
}
`

export const minTabletWidth = styles => `
@media (min-width: ${MIN_TABLET_BREAKPOINT_WIDTH}){
  ${styles}
}
`
export const maxTabletWidth = styles => `
@media (max-width: ${PRE_DESKTOP_BREAKPOINT_WIDTH}){
  ${styles}
}
`
export const maxLargeMobile = styles => `
  @media (max-width: ${L_MAX_MOBILE_BREAK_POINT}){
    ${styles}
  }
`

export const minDesktopWidth = styles => `
@media (min-width: ${MIN_DESKTOP_BREAKPOINT_WIDTH}){
  ${styles}
}
`

export const maxDesktopWidth = styles => `
@media (max-width: ${MIN_DESKTOP_BREAKPOINT_WIDTH}){
  ${styles}
}
`

export const preDesktopWidth = styles => `
@media (max-width: ${PRE_DESKTOP_BREAKPOINT_WIDTH}){
  ${styles}
}
`

export const minMediumDesktopWidth = styles => `
@media (min-width: ${MIN_MEDIUM_DESKTOP_BREAKPOINT_WIDTH}){
  ${styles}
}
`

export const maxMediumDesktopWidth = styles => `
  @media (max-width: ${MIN_MEDIUM_DESKTOP_BREAKPOINT_WIDTH}){
    ${styles}
  }
`

export const minLargeDesktopWidth = styles => `
@media (min-width: ${MIN_LARGE_DESKTOP_BREAKPOINT_WIDTH}){
  ${styles}
}
`
export const maxLargeDesktopWidth = styles => `
@media (max-width: ${MIN_LARGE_DESKTOP_BREAKPOINT_WIDTH}){
  ${styles}
}
`
export const minExtraLargeDesktopWidth = styles => `
  @media (min-width: ${X_LARGE_DESKTOP_BREAKPOINT_WIDTH}){
    ${styles}
  }
`

export const minExtraExtraLargeDesktopWidth = styles => `
  @media (min-width: ${XX_LARGE_DESKTOP_BREAKPOINT_WIDTH}){
    ${styles}
  }
`

export const smDesktop = styles => `
 @media (max-width: ${SM_DESKTOP_BREAK_POINT}){
   ${styles}
 }
 `

export const MONTH_OPTIONS = [
  { value: -1, label: "All Months" },
  { value: 0, label: "Jan" },
  { value: 1, label: "Feb" },
  { value: 2, label: "Mar" },
  { value: 3, label: "Apr" },
  { value: 4, label: "May" },
  { value: 5, label: "Jun" },
  { value: 6, label: "Jul" },
  { value: 7, label: "Aug" },
  { value: 8, label: "Sep" },
  { value: 9, label: "Oct" },
  { value: 10, label: "Nov" },
  { value: 11, label: "Dec" },
]

export const YEAR_OPTIONS = [
  { value: 2020, label: "2020" },
  { value: 2021, label: "2021" },
  { value: 2022, label: "2022" },
  { value: 2023, label: "2023" },
  { value: 2024, label: "2024" },
  { value: 2025, label: "2025" },
]

export const CLIENT_TYPE = [
  { value: "all", label: "All" },
  { value: "Buyers", label: "Buyers" },
  { value: "Sellers", label: "Sellers" },
  { value: "Renter", label: "Tenant" },
  { value: "Rental Owners", label: "Landlord" },
]

export const APP_STORE_URL = "https://itunes.apple.com/us/app/agentdesks-real-estate-agents/id974478110?mt=8"
export const PLAY_STORE_URL = "https://play.google.com/store/apps/details?id=com.agentdesks.android.crm&hl=en"

export const NAVBAR_HEIGHT = 81
export const NAVBAR_LEFT_RIGHT_SPACING = 48
export const SIDEBAR_WIDTH = 296
export const ONBOARDING_BANNER_HEIGHT = 50
