import React from "react"
import Listings from "container/Listings"
import TechAssetsContainer from "container/TechAssets"
import { Space, Table, Spin } from "antd"
import cookie from "services/CookieStorage"
import { withRouter } from "react-router-dom"
import CircularImage from "dumbComponents/common/CircularImage"
import {
  InputPopover,
} from "dumbComponents/common/InputFields"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import Loader from "@ui/Loader"
import {
  TableWrap,
} from "./style"
import {
  dataGenerator,
} from "./utils"
import AddressInformationCard from "./AddresInformationCard"
import ListingStatus from "../ListingStatus/Improved"
import ActionPopOver from "./ActionPopOver"

const ListingTable = ({
  getListingsResponse,
  getPaginationCountResponse,
  query,
  constructQuery,
  toggleStatusChangeModal,
  toggleMoveToMarket,
  updateCurrentListingData,
  listingData,
  viewContractForListing,
  performNecessaryActionsBeforeLoadingListing,
  performNecessaryActionsBeforeLoadingListingResponse,
  clearExistingData,
  history,
  setDealToView,
  getTeamDetailsResponse,
}) => {
  const teamExists = cookie.load("brokerageTeamId") || ""


  const {
    data: listings,
    isFetching: gettingListings,
  } = getListingsResponse || {}

  const {
    isFetching: processing,
  } = performNecessaryActionsBeforeLoadingListingResponse || {}

  const {
    data: paginationData,
    isFetching: gettingPaginationCount,
  } = getPaginationCountResponse || {}
  const { isFetching: fetchingTeams, data: teamData } = getTeamDetailsResponse || {}
  const handlePaginationChange = (page) => {
    const currentQuery = {
      ...query,
    }
    if (page === 1) {
      currentQuery.skip = 0
      constructQuery({
        query: currentQuery,
      })
      return false
    }

    // const calculatedSkip = Math.abs((page * 5) - 5)
    const calculatedSkip = Math.abs((page - 1) * 4)
    currentQuery.skip = calculatedSkip
    constructQuery({
      query: currentQuery,
    })
  }

  const getTotalCount = () => {
    if (paginationData && listings) {
      return paginationData.total_listing_count || 0
    }

    return 0
  }

  const handleRowListingStatusChange = (value, id, data) => {
    //depending on value perform action
    switch (value) {
      case "pending":
        //contracts flow
        performNecessaryActionsBeforeLoadingListing({
          dealId: id,
          record: data,
          suppressNotifications: true,
        })
        viewContractForListing(true)
        break
      case "active_listing":
      case "back_on_market":
        //market modal with mls
        updateCurrentListingData({
          ...listingData,
          dealId: id,
        })
        if (!data.mls_number || data.mls_number === null || data.mls_number === undefined) {
          toggleMoveToMarket(true)
        } else {
          toggleStatusChangeModal({
            bool: true,
            statusChangedTo: value,
            listingID: id,
          })
        }
        break
      case "coming_soon":
      case "listing_canceled":
      case "cancelled":
      case "withdrawn":
      case "expired":
      case "listing_expired":
        toggleStatusChangeModal({
          bool: true,
          statusChangedTo: value,
          listingID: id,
        })
        break
      default:
        //
    }
  }

  const soloAgentOrMemberColumns = [
    {
      title: "Listing Names",
      dataIndex: "listing_address",
      key: "listing_address",
      render: (_, record) => (
        <AddressInformationCard
          data={record}
        />
      ),
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            //MUST CLEAN EXISTING DATA
            clearExistingData()
            setDealToView(null)

            const listingId = record.id
            performNecessaryActionsBeforeLoadingListing({
              dealId: listingId,
              record,
              history,
            })
          },
        }
      },
    },
    {
      title: "Listing Start",
      dataIndex: "date_of_listing_submission",
      key: "date_of_listing_submission",
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            //MUST CLEAN EXISTING DATA
            clearExistingData()
            setDealToView(null)

            const listingId = record.id
            performNecessaryActionsBeforeLoadingListing({
              dealId: listingId,
              record,
              history,
            })
          },
        }
      },
    },
    {
      title: "On Market",
      dataIndex: "listing_on_market_date",
      key: "listing_on_market_date",
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            //MUST CLEAN EXISTING DATA
            clearExistingData()
            setDealToView(null)

            const listingId = record.id
            performNecessaryActionsBeforeLoadingListing({
              dealId: listingId,
              record,
              history,
            })
          },
        }
      },
    },
    {
      title: "Listing End",
      dataIndex: "listing_expiration_date",
      key: "listing_expiration_date",
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            //MUST CLEAN EXISTING DATA
            clearExistingData()
            setDealToView(null)

            const listingId = record.id
            performNecessaryActionsBeforeLoadingListing({
              dealId: listingId,
              record,
              history,
            })
          },
        }
      },
    },
    {
      title: "Status",
      key: "list_status",
      render: (_, record) => (
        <ListingStatus
          defaultValue={record.listing_status}
          currentStatus={record.listing_status}
          disabledStatuses={["incomplete"]}
          handleStatusChange={(value) => {
            handleRowListingStatusChange(value, record.id, record)
          }}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <InputPopover
            trigger="hover"
            content={(<ActionPopOver record={record} />)}
          />
        </Space>
      ),
    },
  ]

  const columns = [
    {
      title: "Listing Names",
      dataIndex: "listing_address",
      key: "listing_address",
      render: (_, record) => (
        <AddressInformationCard
          data={record}
        />
      ),
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            //MUST CLEAN EXISTING DATA
            clearExistingData()
            setDealToView(null)

            const listingId = record.id
            performNecessaryActionsBeforeLoadingListing({
              dealId: listingId,
              record,
              history,
            })
          },
        }
      },
    },
    {
      title: "Listing Start",
      dataIndex: "date_of_listing_submission",
      key: "date_of_listing_submission",
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            //MUST CLEAN EXISTING DATA
            clearExistingData()
            setDealToView(null)

            const listingId = record.id
            performNecessaryActionsBeforeLoadingListing({
              dealId: listingId,
              record,
              history,
            })
          },
        }
      },
    },
    {
      title: "On Market",
      dataIndex: "listing_on_market_date",
      key: "listing_on_market_date",
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            //MUST CLEAN EXISTING DATA
            clearExistingData()
            setDealToView(null)

            const listingId = record.id
            performNecessaryActionsBeforeLoadingListing({
              dealId: listingId,
              record,
              history,
            })
          },
        }
      },
    },
    {
      title: "Listing End",
      dataIndex: "listing_expiration_date",
      key: "listing_expiration_date",
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            //MUST CLEAN EXISTING DATA
            clearExistingData()
            setDealToView(null)

            const listingId = record.id
            performNecessaryActionsBeforeLoadingListing({
              dealId: listingId,
              record,
              history,
            })
          },
        }
      },
    },
    {
      title: "Agent",
      key: "agent_image",
      render: (_, record) => (
        <CircularImage
          name={`${record.agent_firstname} ${record.agent_lastname}`}
          size={44}
          image={record.agent_image || ""}
        />
      ),
    },
    {
      title: "Status",
      key: "list_status",
      render: (_, record) => (
        <ListingStatus
          defaultValue={record.listing_status}
          currentStatus={record.listing_status}
          disabledStatuses={["incomplete"]}
          handleStatusChange={(value) => {
            handleRowListingStatusChange(value, record.id, record)
          }}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <InputPopover
            trigger="hover"
            content={(<ActionPopOver record={record} />)}
          />
        </Space>
      ),
    },
  ]

  return (
    <TableWrap>
      <Table
        loading={
          gettingPaginationCount || gettingListings || processing
            ? {
              indicator: <Loader />,
            }
            : false
        }
        columns={teamExists && teamExists !== "null" ? columns : soloAgentOrMemberColumns}
        dataSource={dataGenerator(listings)}
        pagination={{
          position: ["bottomCenter"],
          defaultPageSize: 4,
          hideOnSinglePage: true,
          showSizeChanger: false,
          total: getTotalCount(),
          onChange: handlePaginationChange,
        }}
      />
    </TableWrap>
  )
}

export default withRouter(TechAssetsContainer(TeamProfileContainer(Listings(ListingTable))))
