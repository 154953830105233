import React, { useEffect, useState } from "react"
import styled from "styled-components"
import OnboardingContainer from "container/OnboardingSetup"
import { ATC } from "@fonts"
import { ONBOARDING_BANNER_HEIGHT } from "@consts"
import Flags from "./Images/Flags.png"
import Arrow from "./Images/Arrow_2.svg"

const Wrap = styled.div`
`

const MainWrapper = styled.div`
  width: 100vw;
  height: ${ONBOARDING_BANNER_HEIGHT}px;
  background: #DFDAC2;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 81px;
  z-index: 2000;
  left: 0;
  cursor: pointer;
`
const FirstDiv = styled.div`
  display: flex;
  align-items: center;
`

const FlagsImage = styled.img`
  margin-left: 24px;
  height: 18.51px;
  width: 32.12px;
`
const WelcomeText = styled.div`
  margin-left: 18px;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #303030;
`
const SmallerText = styled.div`
  margin-left: 10px;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #303030;
`
const PercentageBar = styled.div`
  margin-right: 10.8px;
`
const Percentage = styled.div`
  display: flex;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #303030;
  margin-top: 2px;
`
const ProgressBar = styled.div`
  width: 231px;
  height: 4.63px;
  background: #FFFFFF;
  border-radius: 34px;
`
const ProgressPercentage = styled.div`
  height: 4.63px;
  background: #5A5FF2;
  border-radius: 34px;

  ${props => props.percentage && `
    width: ${props.percentage}%;
  `}

`
const ArrowImage = styled.img`
  margin-right: 24px;
  height: 16px;
  width: 16px;
  cursor: pointer;
`

const Banner = ({ setFormStage, response, isSetupProfile, toggleSetupPopup }) => {
  const formToShow = () => {
    const count = response?.completed_steps ? response?.completed_steps?.length : 0
    if (count !== 0 && (response.completed_steps[count - 1] === "get_started")) {
      setFormStage("1")
    }
    if (count !== 0 && (response.completed_steps[count - 1] === "basic_license_information")) {
      setFormStage("2")
    }
    if (count !== 0 && response.completed_steps[count - 1] === "upload_files") {
      setFormStage("3")
    }
    if (count !== 0 && response.completed_steps[count - 1] === "assistant_info") {
      setFormStage("4")
    }
    if (count !== 0 && response.completed_steps[count - 1] === "referral_info") {
      setFormStage("5")
    }
    if (count === 0) {
      setFormStage("1")
    }
  }
  const showSetupForm = () => {
    if (response && response.completed_steps && response.completed_steps.length) {
      if (response.completed_steps.includes("basic_info")) {
        toggleSetupPopup(2)
      }
      if (response.completed_steps.includes("office_details")) {
        toggleSetupPopup(3)
      }
      if (response.completed_steps.includes("social_media_details")) {
        toggleSetupPopup(4)
      }
      if (response.completed_steps.includes("agent_bio_details")) {
        toggleSetupPopup(6)
      }
    } else {
      toggleSetupPopup(1)
    }
  }
  return (
    <Wrap onClick={showSetupForm}>
      {isSetupProfile ? (
        <MainWrapper id="onboarding-banner">
          <FirstDiv>
            <FlagsImage src={Flags} alt="Flags" />
            <WelcomeText>
              Welcome to Radius!
            </WelcomeText>
            <SmallerText>
              Getting started is easy —  complete setting up your profile.
            </SmallerText>
          </FirstDiv>
          <FirstDiv>
            <PercentageBar>
              <ProgressBar>
                <ProgressPercentage percentage={response?.form_completion_percentage || "0"} />
              </ProgressBar>
              <Percentage>
                {response?.form_completion_percentage || "0"}
                % COMPLETED
              </Percentage>
            </PercentageBar>
            <ArrowImage src={Arrow} alt="arrow" onClick={showSetupForm} />
          </FirstDiv>
        </MainWrapper>
      ) : (
        <MainWrapper id="onboarding-banner" onClick={formToShow}>
          <FirstDiv>
            <FlagsImage src={Flags} alt="Flags" />
            <WelcomeText>
              Welcome to Radius!
            </WelcomeText>
            <SmallerText>
              Getting started is easy —  complete a brief orientation here.
            </SmallerText>
          </FirstDiv>
          <FirstDiv>
            <PercentageBar>
              <ProgressBar>
                <ProgressPercentage percentage={response?.form_completion_percentage || "0"} />
              </ProgressBar>
              <Percentage>
                {response?.form_completion_percentage || "0"}
                % COMPLETED
              </Percentage>
            </PercentageBar>
            <ArrowImage src={Arrow} alt="arrow" onClick={formToShow} />
          </FirstDiv>
        </MainWrapper>
      )}
    </Wrap>
  )
}

export default OnboardingContainer(Banner)
