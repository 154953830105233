import React from "react"
import styled from "styled-components"
import Modal from "@ui/Modal"
import colors from "@colors"
import root from "window-or-global"
import cookie from "services/CookieStorage"
import inviteContainer from "container/App/inviteFeatureContainer"
import CloseButton from "images/icons/CloseSvg.svg"
import Box from "@ui/BuilderComponents/BoxV2/index"
import Paragraph from "@ui/BuilderComponents/Paragraph/index"
import StateBanner from "images/Graphics/StateBannerAssets/Georgia.png"
import { ATC } from "@fonts"

const Button = styled.button`
  height: 32px;
  min-width: 140px;
  width: max-content;
  text-align: center;
  border-radius: 0px;
  background-color: ${colors.new_primary};
  color: #fff;
  cursor: pointer;
  padding: 2px;
  border: none;
  font-family: ${ATC};
`

const GlobalStateAnnouncementModal = (props) => {
  const {
    toggleStateAnnouncement,
    isAnnouncementGlobalModalOpen,
    stateBanner,
    RARTracker,
    toggleApplyForm,
  } = props

  return (
    <Modal
      show={stateBanner.state_name && isAnnouncementGlobalModalOpen}
      toClose={() => {
        toggleStateAnnouncement(false)
        cookie.save("stateBannerViewedUpdated", stateBanner.id)
      }}
      width="530px"
      overlayColor="#000000b3"
      modalStyles={{
        padding: 0,
      }}
      customDialogColor="transparent"
      dialogStyles={{
        background: "#fff",
        overflow: "hidden",
        position: "relative",
        top: "31px",
      }}
      closeIcon={CloseButton}
      showCloseButton
      iconStyles={{
        position: "absolute",
        width: "24px",
        right: 0,
        cursor: "pointer",
        top: 0,
        marginRight: "20px",
        marginTop: "20px",
      }}
      modalVersion={2}
    >
      <Box
        displayStyle="flex"
        flexDirection="column"
        height="360px"
        width="530px"
        isImage
        image={stateBanner && stateBanner.image_url || StateBanner}
        backgroundImageSize={stateBanner.state_name === "Oregon" ? "cover" : "contain"}
        backgroundImageRepeat="no-repeat"
        customDivStyling={{
          padding: "30px 20px 20px 25px",
          backgroundPositionY: stateBanner.state_name === "Oregon"
          || stateBanner.state_name === "Colorado"
          || stateBanner.state_name === "California"
            ? "0px" : stateBanner.state_name === "Washington"
              ? "20px" : "81px",
        }}
      >
        <Paragraph
          color={colors.black}
          minLargeDesktopParaSize="42px"
          minDesktopParaSize="42px"
          preDesktopParaSize="42px"
          minTabletParaSize="42px"
          paraFamily="Butler"
          customParaStyling={{
            lineHeight: "49px",
            letterSpacing: "0.5%",
            maxWidth: "416px",
            fontWeight: "600",
            color: stateBanner.state_name === "Colorado" ? "white" : "#303030",
          }}
        >
          {stateBanner.title || "Our Radius is expanding into new states!"}
        </Paragraph>
      </Box>
      <Box
        elementPosition="absolute"
        height="80px"
        width="100%"
        customDivStyling={{
          background: "linear-gradient(rgb(59 55 55 / 27%) 13%, rgb(15 15 15 / 40%) 49%)",
          bottom: "-10px",
          paddingLeft: "25px",
          paddingRight: "25px",
          paddingTop: "20px",
          borderRadius: 0,
        }}
      >
        <Box
          displayStyle="flex"
          height="max-content"
          width="100%"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Paragraph
            color={colors.white}
            minLargeDesktopParaSize="14px"
            minDesktopParaSize="14px"
            preDesktopParaSize="14px"
            minTabletParaSize="14px"
            paraFamily={ATC}
            paraWeight="300"
            customParaStyling={{
              lineHeight: "18px",
              maxWidth: "161px",
            }}
          >
            {stateBanner.subtitle || "Earn more, learn more and grow with Radius!"}
          </Paragraph>
          <Button
            onClick={() => {
              if (root.heap) {
                root.heap.track("Community_State_Banner_Brokerage_Interest_click")
              }
              cookie.save("stateBannerViewedUpdated", stateBanner.id)
              if (stateBanner && stateBanner.state_name) {
                RARTracker({
                  payload: {
                    source: `Radius_Brokerage_Expansion_${stateBanner.state_name}_interest`,
                  },
                })
              }
              root.history.pushState("", "", "/community?interestSource=State Banner Popup")
              toggleStateAnnouncement(false)
              toggleApplyForm({
                bool: true,
                accountType: null,
                headerText: "Nice to meet you!",
                subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
              })
            }}
          >
            {stateBanner.cta_text || "Learn More"}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default inviteContainer(GlobalStateAnnouncementModal)
