import axios from "axios"

export const fetchSignedAgreementAPI = () => axios.get(
  `${API_URL}refer/signLeadAgreement?loadUrl=${encodeURIComponent(APP_URL)}&returnUrl=${encodeURIComponent(
    APP_URL
  )}/agreement/redirect?docuSignStatus=success`
)

export const fetchReferralAPI = (params = "", baseUrl) => axios
  .get(`${baseUrl || API_V2_URL}refer/referrals-list?${params}`)

export const fetchPartnerSubscriptionInterestAPI = () => axios
  .get(`${API_URL}partnerSubscriptionInterests`)

export const getISAFormDropdownValuesAPI = () => axios
  .get(`${API_URL}partnerSubscriptionInterests-dropdown-values`)

export const sendSMSMetricsAPI = () => axios
  .get(`${API_V2_URL}unsubscribed-agent-visited-radius-assist`)

export const submitInterestFormAPI = data => axios
  .post(`${API_URL}partnerSubscriptionInterests`, data)

export const fetchInboundReferralAPI = (type, params = "") => axios
  .get(`${API_V3_URL}refer/referrals-list?referralType=incoming${params}`)

export const fetchFilterSkeletonAPI = () => axios
  .get(`${API_V2_URL}refer/filter-skeleton`)

export const fetchReferalMetricAPI = type => axios
  .get(`${API_V2_URL}refer/referral-metrics?referralType=${type}`)

export const fetchStarReferralAPI = (referralId, payload) => axios
  .put(`${API_V2_URL}consumers/referral/${referralId}`, payload)

export const signCRSAgreementAPI = (agentId, envelopeId, returnUrl) => axios.get(
  `${API_URL}crs/refer/receiver-sign-view/${agentId}/envelope/${envelopeId}?loadUrl=${encodeURIComponent(
    APP_URL
  )}&returnUrl=${encodeURIComponent(returnUrl)}`
)

export const signCRSSenderAgreementAPI = (envelopeId, returnUrl) => axios.get(
  `${API_URL}crs/refer/sender-sign-view/${envelopeId}?loadUrl=${encodeURIComponent(
    APP_URL
  )}&returnUrl=${encodeURIComponent(returnUrl)}`
)
export const subscribeAPI = payload => axios.post(`${API_V2_URL}stripe/subscribe`, payload)

export const unsubscribeAPI = payload => axios.post(`${API_V2_URL}stripe/unsubscribe`, payload)

export const fetchInvoicesAPI = () => axios.get(`${API_V2_URL}stripe/invoices`)

export const fetchPlansAPI = () => axios.get(`${API_V2_URL}stripe/plans`)

export const fetchSubscriptionsAPI = () => axios.get(`${API_V2_URL}stripe/subscriptions`)

export const fetchDefaultCardAPI = () => axios.get(`${API_V2_URL}stripe/card`)

export const updateCardAPI = payload => axios.put(`${API_V2_URL}stripe/card/update`, payload)

export const fetchProspectsAPI = (agentId, referralType) => axios
  .get(`${API_V2_URL}prospects/${agentId}?referralType=${referralType}`)

export const updateProspectAPI = (agentId, payload) => axios.put(`${API_V2_URL}prospects/${agentId}`, payload)

export const referLeadAPI = payload => axios.post(`${API_URL}refer/lead`, payload)

export const updateLeadReferralStateAPI = (agentId, payload) => axios.put(`${API_V2_URL}prospects/${agentId}`, payload)

export const fetchCallStatusAPI = (referralId, referralType) => axios
  .get(`${API_V2_URL}refer/referrals/${referralId}?referralType=${referralType}`)

export const applyPricingCouponAPI = payload => axios.post(`${API_V2_URL}stripe/applyCoupon`, payload)

export const checkoutPricingAPI = payload => axios.post(`${API_V2_URL}stripe/checkout`, payload)

export const getSingleProspectAPI = (agentId, referralId) => axios
  .get(`${API_V2_URL}prospects/${agentId}/${referralId}?referralType=incoming`)

export const fetchPricingPlansAPI = () => axios
  .get(`${API_V2_URL}stripe/plans`)

export const fetchAcceptLeadDropdownValuesAPI = () => axios
  .get(`${API_URL}user/referral-settings`)

export const setTeamPermissionsAPI = payload => axios
  .put(`${API_URL}user/referral-settings`, payload)

export const fetchTeamFilterValuesAPI = () => axios
  .get(`${API_URL}radius-assist/overview/filter-skeleton`)

export const fetchTeamMembersAPI = days => axios
  .get(`${API_URL}teams/overview?teamOverviewFilter=${days}`)

export const getTeamMemberAPI = (agentId, referralReceiverId) => axios
  .get(`${API_URL}team/${agentId}/members${referralReceiverId ? `?referral_receiver_id=${referralReceiverId}` : ""}`)

export const assignAgentAPI = (clientId, payload) => axios
  .post(`${API_URL}assign-isa-to-agent/${clientId}`, payload)

export const referOutAPI = (agentId, payload) => axios
  .put(`${API_V2_URL}prospects/${agentId}`, payload)

export const inviteAgentForTeamPlanAPI = payload => axios
  .post(`${API_URL}teams/members`, payload)

export const handleInviteResponseAPI = (ownerId, payload) => axios
  .put(`${API_URL}teams/members/${ownerId}`, payload)

export const handleRemoveMemberAPI = id => axios
  .delete(`${API_URL}teams/members/${id}`)

export const fetchSourcesAPI = () => axios
  .get(`${API_URL}crs/referral-sources?agentSpecific=1`)

export const fetchSubmittedMetricsAPI = (days, sourceString) => axios
  .get(`${API_V2_URL}refer/isa-referral-metrics?daysSinceSubmission=${days}&referralSources=${sourceString}`)

export const getTeamInfoAPI = () => axios
  .get(`${API_URL}teams`)

export const addTeamLogoAPI = (ownerId, payload) => axios
  .put(`${API_URL}teams/${ownerId}`, payload)

export const changeTeamRoleAPI = (ownerId, payload) => axios
  .put(`${API_URL}teams/members/${ownerId}`, payload)

export const fetchPlanDurationsAPI = type => axios
  .get(`${API_V2_URL}stripe/plans-checkout?type=${type}`)

export const exportToExcelAPI = query => axios
  .get(`${API_V2_URL}refer/referrals-list/excel?${query}`)

export const trigglerISAPageVisitAPI = payload => axios
  .post(`${API_V2_URL}user/update-freshsales-fields`, payload)

export const inviteBrokerAPI = payload => axios
  .post(`${API_URL}broker-invites`, payload)

export const getBrokerInvitesAPI = () => axios
  .get(`${API_URL}broker-invites`)

export const getBrokerEarningsAPI = createdAt => axios
  .get(`${API_URL}broker-invite/earnings?createdAt=${createdAt}`)

export const getClosingStageInfoAPI = referralId => axios
  .get(`${API_URL}common/referral_status_update_details?referral_id=${referralId}`)

export const updateSalesStageChangeFormAPI = payload => axios
  .post(`${API_URL}common/referral_status_update_details`, payload)

export const getInboundPendingReferralDetailsAPI = id => axios
  .get(`${API_URL}direct-lead/${id}`)

export const sendToMarketPlaceAPI = refId => axios
  .put(`${API_URL}direct-lead/${refId}/add-to-lmp`)

export const acceptOrRejectLeadAPI = (referralId, val) => axios
  .put(`${API_URL}direct-lead/${referralId}/update`, { status: val })

export const getAgentSearchAPI = payload => axios
  .post(`${API_URL}agent/elastic/query`, payload)

// View API
export const findAnAgentViewedAPI = payload => axios
  .post(`${API_URL}side-menu-updates/feature-viewed`, payload)

export const getUserInviteDetailsAPI = () => axios
  .get(`${API_URL}user-invite-details`)

export const getLeaderboardForRecruitAndEarnAPI = (skip, limit) => axios
  .get(`${API_URL}broker-invite-leaderboard?skip=${skip || 0}&limit=${limit}`)

export const fakeSignDocusignAPI = payload => axios
  .post(`${API_URL}refer/uploadLeadAgreement`, payload)

export const fetchSubscriptionStatusAPI = payload => axios.get(`${API_V2_URL}stripe/subscriptions/status?${payload}`)
