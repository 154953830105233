import { handleDeltaReducer, createDeltaReducer } from "shared/reduxUtils"
import root from "window-or-global"
import { create, get } from "lodash"
import * as RealtyActions from "./actions"

const initState = {
  someValue: false,
  aboutTeam: "",
  teamBenefitsProvided: "",
  teamLogo: "",
  teamBanner: "",
  imageTwo: "",
  imageThree: "",
  imageFour: "",
  currentCroppedType: "",
  currentImageToBeCropped: null,
  teamBannerRemoved: false,
  teamLogoRemoved: false,
  teamExtraImageOneRemoved: false,
  teamExtraImageTwoRemoved: false,
  teamExtraImageThreeRemoved: false,
  showFileUploadErrorMessage: false,
  deleteMode: false,
  currentImageToBeDeletedType: "",
  isDeleteConfirmationModalOpen: false,
  isColorProfileModalOpen: false,
  isTeamProfileViewed: true,
  showCreateTCInvitation: false,
  ...createDeltaReducer("uploadTeamImageToS3Response"),
  ...createDeltaReducer("submitTeamDetailsResponse"),
  ...createDeltaReducer("getTeamDetailsResponse"),
  ...createDeltaReducer("getTeamDetailsViaNameResponse"),
  ...createDeltaReducer("getOtherTeamDetailsResponse"),
  ...createDeltaReducer("getTCAgentAssignedResponse"),
  ...createDeltaReducer("getTeamTCsResponse"),
  ...createDeltaReducer("inviteTeamTCsResponse"),
  ...createDeltaReducer("removeTeamTCsResponse"),
  ...createDeltaReducer("saveTeamMembersResponse"),
  ...createDeltaReducer("fetchTeamMembersListResponse"),
  ...get(root, "__STATE__.teamDetails", {}),
}

export default function teamProfileReducer(state = initState, action) {
  switch (action.type) {
    case RealtyActions.uploadImagesToS3Action.REQUEST:
    case RealtyActions.uploadImagesToS3Action.FAILURE:
    case RealtyActions.uploadImagesToS3Action.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.uploadImagesToS3Action,
          "uploadTeamImageToS3Response"
        ),
      }
    }

    case RealtyActions.getTeamDetailsViaNameAction.REQUEST:
    case RealtyActions.getTeamDetailsViaNameAction.FAILURE:
    case RealtyActions.getTeamDetailsViaNameAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.getTeamDetailsViaNameAction,
          "getTeamDetailsViaNameResponse"
        ),
      }
    }

    case RealtyActions.submitTeamDetailsAction.REQUEST:
    case RealtyActions.submitTeamDetailsAction.FAILURE:
    case RealtyActions.submitTeamDetailsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.submitTeamDetailsAction,
          "submitTeamDetailsResponse"
        ),
      }
    }

    case RealtyActions.getTeamDetailsAction.REQUEST:
    case RealtyActions.getTeamDetailsAction.FAILURE:
    case RealtyActions.getTeamDetailsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.getTeamDetailsAction,
          "getTeamDetailsResponse"
        ),
      }
    }

    case RealtyActions.getTeamDetailsForLogoAction.REQUEST:
    case RealtyActions.getTeamDetailsForLogoAction.FAILURE:
    case RealtyActions.getTeamDetailsForLogoAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.getTeamDetailsForLogoAction,
          "getTeamDetailsForLogoResponse"
        ),
      }
    }

    case RealtyActions.getOtherTeamDetailsAction.REQUEST:
    case RealtyActions.getOtherTeamDetailsAction.FAILURE:
    case RealtyActions.getOtherTeamDetailsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.getOtherTeamDetailsAction,
          "getOtherTeamDetailsResponse"
        ),
      }
    }

    case RealtyActions.getTCAgentAssignedAction.REQUEST:
    case RealtyActions.getTCAgentAssignedAction.FAILURE:
    case RealtyActions.getTCAgentAssignedAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.getTCAgentAssignedAction,
          "getTCAgentAssignedResponse"
        ),
      }
    }

    case RealtyActions.setRTFContentAction.type: {
      const {
        type,
        content,
      } = action.data
      return {
        ...state,
        [type]: content,
      }
    }

    case RealtyActions.setImageBlocksAction.type: {
      const {
        image,
        type,
      } = action.data
      return {
        ...state,
        [type]: image,
      }
    }

    case RealtyActions.setCurrentImageToBeCroppedAction.type: {
      const {
        image,
        type,
      } = action.data
      return {
        ...state,
        currentImageToBeCropped: image,
        currentCroppedType: type,
      }
    }

    case RealtyActions.setRemovedImagesArrayAction.type: {
      const {
        typeRemoved,
        bool,
      } = action.data
      return {
        ...state,
        [typeRemoved]: bool,
      }
    }

    case RealtyActions.fileSizeErrorToggleAction.type: {
      return {
        ...state,
        showFileUploadErrorMessage: action.data,
      }
    }

    case RealtyActions.toggleDeleteModeAction.type: {
      return {
        ...state,
        deleteMode: action.data,
      }
    }

    case RealtyActions.setCurrentImageTypeToBeDeletedAction.type: {
      return {
        ...state,
        currentImageToBeDeletedType: action.data,
      }
    }

    case RealtyActions.toggleDeleteConfirmationAction.type: {
      return {
        ...state,
        isDeleteConfirmationModalOpen: action.data,
      }
    }

    case RealtyActions.toggleColorProfileModalAction.type: {
      return {
        ...state,
        isColorProfileModalOpen: action.data,
      }
    }

    case RealtyActions.toggleTeamProfileEditModeAction.type: {
      return {
        ...state,
        isTeamProfileViewed: action.data,
      }
    }

    case RealtyActions.toggleCreateTCInvitation.type: {
      return {
        ...state,
        showCreateTCInvitation: action.data,
      }
    }

    case RealtyActions.getTeamTCs.REQUEST:
    case RealtyActions.getTeamTCs.FAILURE:
    case RealtyActions.getTeamTCs.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.getTeamTCs,
          "getTeamTCsResponse"
        ),
      }
    }

    case RealtyActions.inviteTeamTCs.REQUEST:
    case RealtyActions.inviteTeamTCs.FAILURE:
    case RealtyActions.inviteTeamTCs.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.inviteTeamTCs,
          "inviteTeamTCsResponse"
        ),
      }
    }

    case RealtyActions.removeTeamTCAction.REQUEST:
    case RealtyActions.removeTeamTCAction.FAILURE:
    case RealtyActions.removeTeamTCAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.removeTeamTCAction,
          "removeTeamTCsResponse"
        ),
      }
    }

    case RealtyActions.saveTeamMembersAction.REQUEST:
    case RealtyActions.saveTeamMembersAction.FAILURE:
    case RealtyActions.saveTeamMembersAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.saveTeamMembersAction,
          "saveTeamMembersResponse"
        ),
      }
    }
      
    case RealtyActions.fetchTeamMembersListAction.REQUEST:
    case RealtyActions.fetchTeamMembersListAction.FAILURE:
    case RealtyActions.fetchTeamMembersListAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.fetchTeamMembersListAction,
          "fetchTeamMembersListResponse"
        ),
      }
    }
    default:
      return state
  }
}
