import React, { useRef, useState, useEffect } from "react";
import CRMV2Container from "container/CRM";
import { withRouter } from "react-router-dom";
import SearchContainer from "container/Search/SearchContainer";
import TechAssetsContainer from "container/TechAssets";
import OnboardingContainer from "container/OnboardingSetup";
import BrbcContainer from "container/BRBC";
import ReferralContainer from "container/Referral";
import TeamProfileContainer from "container/Realty/TeamProfileContainer";
import ThreeDotLoader from "@ui/ThreeDotLoader";
import { checkForUserObject } from "services/Utils";
import App from "container/App";
import { Form, Row, Col, Select, Space } from "antd";
import { normalizePhone } from "dumbComponents/TransactionCoordinationV2/Forms/Common/Utils";
import BrbcModal from "./BrbcModal";
import Listings from "container/Listings/index";
import {EditOutlined} from "@ant-design/icons";
import {
  Subheading,
  SubheadingContainer,
  StyledRow,
  CobuyerBtn,
  SignBtn,
} from "./styles";
import CoBuyerForm from "./CoBuyerForm";
import {
  InputLocation,
  SelectField,
  InputField,
  InputNumber,
} from "dumbComponents/common/InputFields";
import { validatePhoneNumber } from "dumbComponents/ListingsV2/Components/ListingCoversheet/components/ListingsForms/Forms/utils.js";
import Loader from "@ui/Loader";

const { Option } = Select;

const STATES = [
  {
    label: "California",
    value: "California",
  },
  {
    label: "Colorado",
    value: "Colorado",
  },
  {
    label: "Florida",
    value: "Florida",
  },
  {
    label: "Georgia",
    value: "Georgia",
  },
  {
    label: "Oregon",
    value: "Oregon",
  },
  {
    label: "Texas",
    value: "Texas",
  },
  {
    label: "Washington",
    value: "Washington",
  },
];

const REPRESENTATION = [
  {
    label: "Non-exclusive",
    value: "non-exclusive",
  },
  {
    label: "Exclusive",
    value: "exclusive",
  },
];

const BrbcForm = ({
  getCrmClientResponse,
  isUserOverviewDrawerOpen,
  uploadNewBrbcCoversheet,
  newBrbcCoversheetReponse,
  getCtIdResponse,
  fetchBrbcStatus,
  fetchBrbcStatusResponse,
  fetchBrbcCoversheetReponse,
  agentDetailsResponse,
  setCurrentDrawerPage,
  getClient,
}) => {
  const { Item } = Form;
  const [form] = Form.useForm();

  const {
    isFetching: fetchingBrbcCoversheetData,
    data: initialCoversheetData,
  } = fetchBrbcCoversheetReponse || {};

  const {
    isFetching: fetchingBrbcStatus,
  } = fetchBrbcStatusResponse || {};


  const { isFetching: fetchingAgentData, data: agentData } =
    agentDetailsResponse || {};

  const user = checkForUserObject("user");
  const { firstname, lastname, email } = user || {};
  const formRef = useRef(null);
  const [signBrbcModal, setSignBrbcModal] = useState(false);
  const { isFetching: fetchingUpdatedValues, data: clientData } =
    getCrmClientResponse || {};
  const { isFetching: postingBrbcData, data: brbcCoversheetData } =
    newBrbcCoversheetReponse || {};
  const { isFetching: fetchingCoClientData, data: coClientData } =
    getCtIdResponse || {};
  const { coClients } = coClientData || {};

  const onFinish = (values) => {
    const agentId = clientData?.agent_id;
    const clientId = clientData?.id;
    const coBuyers = values.coBuyers || [];

    const coClients = coBuyers.map((coBuyer) => ({
      name: `${coBuyer.firstName} ${coBuyer.lastName}`,
      emails: coBuyer.email ? [{ email: coBuyer.email }] : [],
      phones: coBuyer.phone ? [{ phone: coBuyer.phone }] : [],
      client_type: "buyer",
      deal_id: null,
      offer_id: null,
      profile_url: null,
      client_id: coBuyer.clientId,
      id: coBuyer.id,
      address: coBuyer.address || "",
    }));
    const client = {
      name: values.primary_buyer_first_name + " " + values.primary_buyer_last_name,
      emails: [
        {
          email: values.primary_buyer_email,
        }
      ],
      phones: [
        {
          phone: values.primary_buyer_phone,
        }
      ]
    }
    uploadNewBrbcCoversheet({
      agent_id: agentId,
      client_id: clientId,
      draft: 0,
      state: values.brbc_state,
      type_of_representation: values.representation,
      agent_firstname: values.agent_first_name,
      agent_lastname: values.agent_last_name,
      agent_license_number: values.agent_license_number,
      brokerage_name: values.brokerage_name,
      brokerage_license_number: values.brokerage_license_number,
      coClients,
      client,
      callback: () => {
        fetchBrbcStatus({
          agentId: agentId,
          clientId: clientId,
          callback: () => {
            getClient({
              clientId: clientId,
            })
          }
        });
      },
    });
    // form.resetFields();
  };

  const handleSignCTA = () => {
    setSignBrbcModal(true);
  };

  const getFirstAndLastName = (fullName) => {
    const nameParts = fullName.trim().split(" ");
    const firstName = nameParts[0] || "";
    const lastName = nameParts.slice(1).join(" ") || "";
    return { firstName, lastName };
  };

  useEffect(() => {
    if (clientData) {
      console.log("clientData", clientData)
      const brbcState =
        clientData?.clientTransactions?.length > 0
          ? clientData?.clientTransactions?.BUYING[0]?.requirement?.locations[0]
              ?.state
          : agentData
          ? agentData?.state
          : "California";
      const { firstName, lastName } = getFirstAndLastName(clientData.name);
      form.setFieldsValue({
        primary_buyer_first_name: firstName,
        primary_buyer_last_name: lastName,
        primary_buyer_phone:
          clientData?.phones?.length > 0
            ? normalizePhone(clientData.phones[0]?.phone)
            : "",
        primary_buyer_email:
          clientData.emails.length > 0 ? clientData.emails[0].email : "",
        brbc_state: brbcState,
      });
    }
    if (agentData) {
      const nameParts = agentData.name ? agentData.name.trim().split(/\s+/) : [];
  const agentFirstName = nameParts[0] || "";
  const agentLastName = nameParts.slice(1).join(" ") || ""; // Handles multi-part last names

      form.setFieldsValue({
        agent_first_name: agentData ? agentFirstName : firstname,
        agent_last_name: agentData ? agentLastName : lastname,
      });
    }

    if (agentData
      && !form.getFieldValue("agent_license_number")
      && !form.getFieldValue("brokerage_name")
      && !form.getFieldValue("brokerage_license_number")
    ) {
      form.setFieldsValue({
        agent_license_number: agentData?.agent_license_number,
        brokerage_name: agentData?.brokerage,
        brokerage_license_number: agentData?.brokerage_license_number,
      });
    }

    if (coClients && coClients.length > 0 && !form.getFieldValue("coBuyers")) {
      const coBuyers = coClients.map((client) => {
        const { firstName, lastName } = getFirstAndLastName(client.name);
        return {
          firstName,
          lastName,
          phone: client?.phone ? normalizePhone(client?.phone) : "",
          email: client.email || "",
          clientId: client.u_cust_id || null,
          id: client.id || null,
        };
      });

      form.setFieldsValue({
        coBuyers,
      });
    }
  }, [clientData, coClients, form, agentData]);

  const [validateValues, setValidateValues] = useState(false);

  useEffect(() => {
    const allValues = form.getFieldsValue();
    validateFieldValues(allValues)
  }, [form, agentData, clientData]);

  const validateFieldValues=(allValues)=>{
    const mainFieldsValid = 
    allValues.brbc_state &&
    allValues.representation &&
    allValues.primary_buyer_first_name &&
    allValues.primary_buyer_last_name &&
    allValues.primary_buyer_email &&
    allValues.agent_first_name &&
    allValues.agent_last_name &&
    allValues.agent_license_number &&
    allValues.brokerage_name &&
    allValues.brokerage_license_number;

  // Validate co-buyers if they exist
  const coBuyersValid = !allValues.coBuyers || !allValues.coBuyers?.length || allValues.coBuyers.every(buyer => 
    buyer?.firstName &&
    buyer?.lastName &&
    buyer?.email
  );

  setValidateValues(mainFieldsValid && coBuyersValid);
  }

  return (
    <>
      {!postingBrbcData && brbcCoversheetData?.length > 0 && !fetchingBrbcStatus && (
        <BrbcModal
          brbcModalType="sign-brbc"
          toggleModal={signBrbcModal}
          onClose={() => setSignBrbcModal(false)}
        />
      )}
      {agentData && clientData ? (
        <Form
          form={form}
          ref={formRef}
          layout="vertical"
          onFinish={onFinish}
          requiredMark={false}
          initialValues={
            {
              // primary_buyer_first_name: clientData?.name
              //   ? getFirstAndLastName(clientData.name).firstName
              //   : "",
              // primary_buyer_last_name: clientData?.name
              //   ? getFirstAndLastName(clientData.name).lastName
              //   : "",
              // primary_buyer_phone:
              //   clientData?.phones?.length > 0 ? clientData[0]?.phone : "",
              // primary_buyer_email:
              //   clientData?.emails?.length > 0 ? clientData.emails[0].email : "",
              // agent_first_name: firstname,
              // agent_last_name: lastname,
              // agent_license_number: agentData?.agent_license_number,
              // brokerage_name: agentData?.brokerage,
              // brokerage_license_number: agentData?.brokerage_license_number,
              // representation: "non-exclusive",
            }
          }
          onValuesChange={(changedValues, allValues) => {
            validateFieldValues(allValues)
          }}
        >
          {/* State and Type of Representation */}
          <StyledRow gutter={16}>
            <Col span={12}>
              <SelectField
                isFormField
                name="brbc_state"
                options={STATES}
                placeholder="State"
                text="State"
                onChange={() => {}}
                required={true}
                requiredMessage="State is required"
              />
            </Col>
            <Col span={12}>
              <SelectField
                isFormField
                name="representation"
                options={REPRESENTATION}
                placeholder="Type of representation"
                text="Type of representation"
                onChange={() => {}}
                required={true}
                requiredMessage="Type of representation is required"
              />
            </Col>
          </StyledRow>

          {/* Buyer Information */}
          <Row gutter={16}>
            <SubheadingContainer>
              <Subheading>Buyer Information</Subheading>
            </SubheadingContainer>
            <Col span={12}>
              <InputField
                name="primary_buyer_first_name"
                inputType="text"
                placeholder="First Name"
                text="First Name"
                required={true}
                requiredMessage="First Name is required."
              />
            </Col>
            <Col span={12}>
              <InputField
                name="primary_buyer_last_name"
                inputType="text"
                placeholder="Last Name"
                text="Last Name"
                required={true}
                requiredMessage="Last Name is required."
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <InputNumber
                name="primary_buyer_phone"
                inputType="phone"
                type="phone"
                placeholder="Phone"
                text="Phone"
                customRules={{
                  validator: validatePhoneNumber,
                }}
              />
            </Col>
            <Col span={12}>
              <InputField
                name="primary_buyer_email"
                inputType="email"
                placeholder="Email"
                text="Email"
                type="email"
                required={true}
                requiredMessage="Email is required."
              />
            </Col>
          </Row>
          {/* <InputLocation
            name="primary_buyer_address"
            inputType="text"
            type="text"
            placeholder="Address"
            formRef={formRef}
            required={false}
            rules={[
              {
                required: false,
              },
            ]}
            fullAddress={true}
            text="Address"
          /> */}
          <Form.List name="coBuyers">
            {(fields, { add, remove }) => (
              <>
                <Row gutter={16}>
                  <CobuyerBtn type="button" onClick={() => add()}>
                    <span style={{ color: "#5A5FF2", marginBottom: "5px" }}>+</span> Add Co-Buyer
                  </CobuyerBtn>
                </Row>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    direction="vertical"
                    style={{ display: "block" }}
                  >
                    <CoBuyerForm
                      name={name}
                      remove={remove}
                      formRef={formRef}
                      coBuyerId={coClients[key]?.id}
                      transactionId={coClientData?.id}
                    />
                  </Space>
                ))}
              </>
            )}
          </Form.List>

          {/* Agent Information */}
          <Row gutter={16}>
            <SubheadingContainer>
              <Subheading>Agent Information</Subheading>
            </SubheadingContainer>
            <Col span={12}>
              <InputField
                name="agent_first_name"
                inputType="text"
                placeholder="First name"
                text="First name"
                required={true}
                requiredMessage="First name is required."
              />
            </Col>
            <Col span={12}>
              <InputField
                name="agent_last_name"
                inputType="text"
                placeholder="Last name"
                text="Last name"
                required={true}
                requiredMessage="Last name is required."
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <InputField
                name="agent_license_number"
                inputType="text"
                placeholder="Agent license number"
                text="Agent license number"
                required={true}
                requiredMessage="License number is required."
              />
            </Col>
          </Row>
          {/* Brokerage Information */}
          <Row gutter={16}>
            <SubheadingContainer>
              <Subheading>Brokerage Information</Subheading>
            </SubheadingContainer>
            <Col span={12}>
              <InputField
                name="brokerage_name"
                inputType="text"
                placeholder="Brokerage name"
                text="Brokerage name"
                required={true}
                requiredMessage="Brokerage name is required."
              />
            </Col>
            <Col span={12}>
              <InputField
                name="brokerage_license_number"
                inputType="text"
                placeholder="Brokerage license number"
                text="Brokerage license number"
                required={true}
                requiredMessage="Brokerage license number is required."
              />
            </Col>
          </Row>

          <Form.Item>
            <SignBtn
              type="primary"
              htmlType="submit"
              onClick={handleSignCTA}
              disabled={postingBrbcData || !validateValues || fetchingBrbcStatus}
            >
              {postingBrbcData || fetchingBrbcStatus ? (
                <ThreeDotLoader color="white" />
              ) : (
                "Save and Continue"
              )}
            </SignBtn>
          </Form.Item>
        </Form>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default SearchContainer(
  Listings(
    withRouter(
      OnboardingContainer(
        TeamProfileContainer(
          ReferralContainer(
            TechAssetsContainer(CRMV2Container(App(BrbcContainer(BrbcForm))))
          )
        )
      )
    )
  )
);
