import React, { PureComponent } from "react"
import RealtyContainer from "container/Realty"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import Button from "@ui/Button"
import colors from "@colors"
import root from "window-or-global"
import {
  preTabletWidth,
  preDesktopWidth,
  minDesktopWidth,
  minLargeDesktopWidth,
} from "@consts"
import cookie from "services/CookieStorage"
import Board from "dumbComponents/RealtyV3/Common/Board"
import { authCheck } from "services/auth"

const Heading = styled.h1`
  font-family: Butler;
  font-weight: 500;
  font-size: 42px;
  line-height: 46px;
  text-align: center;
  width: 509px;
  margin: auto;
  color: ${colors.black};

  ${minDesktopWidth`
    font-style: normal;
    font-weight: 700;
    line-height: 49px;
    letter-spacing: 0.005em;
    text-align: center;
    width: 509px;
  `}

  ${minLargeDesktopWidth`
    width: 647px;
  `}

  ${preDesktopWidth`
    font-family: Butler;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0.01em;
    text-align: center;
    width: 399px;
  `}
  ${preTabletWidth`
    font-family: Butler;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0.005em;
    text-align: center;
    width: 228px;
  `}
`

const Actions = styled.div`
  font-family: ATC;
  display: flex;
  margin-top: 32px;
  justify-content: center;

  ${Button} {
    font-weight: 500;
    border-radius: 0 !important;
    background: ${colors.new_primary};
    color: ${colors.white};
  }
`
const isLogged = authCheck()

class ApplyNow extends PureComponent {
  handleApplyNow = () => {
    const {
      heap,
      RARTracker,
      hubspotKey,
      toggleRARModal,
    } = this.props
    if (root.heap) {
      root.heap.track(heap)
    }
    cookie.save("rar_promotions_source_page", "realty_teams_page_bottom_apply_now")
    if (isLogged) {
      RARTracker({
        payload: {
          source: hubspotKey,
        },
      })
    }
    //history.push(isIosBrowser ? "/realty/choose-your-city?platform=ios" : "/realty/choose-your-city")
    toggleRARModal(true)
  }

  handleApplyNowVersion2 = () => {
    const {
      hubspotKey,
      RARTracker,
      toggleApplyModal,
      heap,
    } = this.props
    if (root.heap) {
      root.heap.track(heap)
    }

    if (isLogged) {
      RARTracker({
        payload: {
          source: hubspotKey,
        },
      })
    }

    toggleApplyModal({
      bool: true,
      accountType: "owner",
      headerText: "Nice to meet you!",
      subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
    })
  }

  render() {
    const {
      customBackground,
    } = this.props
    return (
      <Board
        customBorderRadius="0px"
        customBackground={customBackground}
      >
        <Heading>
          Apply now to start or bring your team to Radius Agent.
        </Heading>
        <Actions>
          <Button
            bsStyle="blueFadedBG"
            padding="16px"
            width="auto"
            onClick={this.handleApplyNowVersion2}
          >
            Learn More
          </Button>
        </Actions>
      </Board>
    )
  }
}

export default withRouter(RealtyContainer(ApplyNow))
