import React, { useState } from 'react';
import { Select, Tag, Tooltip, Form } from 'antd';
import styled from "styled-components"
import { PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"

const StyledSelect = styled(Select)`
    .ant-select-dropdown-menu{
        display: none;
    }
        .ant-select-selector {
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
  }

  .ant-select-selection-overflow {
    flex-wrap: wrap !important;
  }
`;

const EmailSelect = ({ value = [], onChange, text }) => {
  const [emails, setEmails] = useState([]);

  const isValidEmail = (email) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (value) => {
    const lastValue = value[value.length - 1];

    if (isValidEmail(lastValue)) {
      setEmails(value);
      onChange(value);
    } else {
      setEmails(value.slice(0, -1));
      onChange(value.slice(0, -1)); 
    }
  };

  const handleBlur = () => {
    // Optionally, you can add more validation or trimming logic here
  };

  return (
<StyledSelect
      mode="tags"
      style={{ width: '100%' }}
      placeholder="Enter email addresses"
      onChange={handleChange}
      value={emails}
      tokenSeparators={[',', ' ']}
      dropdownStyle={{display: "none"}}
    >
    </StyledSelect>
  );
};

export default EmailSelect;
