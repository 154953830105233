import React, { useEffect, useState } from "react"
import root, { console } from "window-or-global"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import queryString from "query-string"
import Loader from "@ui/Loader"
import { Route, withRouter, Redirect } from "react-router-dom"
import { authCheck } from "services/auth"
import Navbar from "dumbComponents/common/Navbar"
import container from "container/App/headerLayoutContainer"
import techAssets from "container/TechAssets"
import { mobile, SIDEBAR_WIDTH } from "@consts"
import RadiusBanners from "dumbComponents/common/RadiusBanners"
import Sidebar from "@ui/Sidebar"
import ScrollToTop from "@ui/ScrollToTop"
import cookie from "services/CookieStorage"
import { UTMExtract } from "services/Utils"
import PubNubForUpload from "dumbComponents/Listings/pages/Docusign/PubNubForUploads"
import CRMModal from "dumbComponents/CRMV2/CRMDashboard/Components/DragAndDropArea/Modals/CRMModal"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import { getItemFromLocalStorage, removeItemFromLocalStorage, setItemInLocalStorage } from "shared/utils"
import UploadUpdateModal from "./UploadUpdateModal"
import { getAppColorScheme } from "./utils"
import OnboardingContainer from "container/OnboardingSetup"
import { PaymentPendingBanner } from "dumbComponents/common/GlobalBanners/index"


const TC_PARENT_ROUTES = [
  "/realty/listings",
  "/realty/offers",
  "/realty/transaction-coordination",
  "/listings",
  "/realty/offers-v3/:id",
  "/realty/offers-v3",
  "/realty/transaction-dashboard",
  "/crm"
]

// const Navbar = Loadable({
//   loader: () => import("dumbComponents/common/Navbar/index.js"),
//   loading: <div></div>,
// })

// const RadiusBanners = Loadable({
//   loader: () => import("dumbComponents/common/RadiusBanners/index.js"),
//   loading: <></>,
// })

// const Sidebar = Loadable({
//   loader: () => import("@ui/Sidebar"),
//   loading: <></>,
// })

const LayoutWrap = styled.div`
  padding-top: 120px;
  transition-duration: 0.3s;
  width: calc(100% - ${SIDEBAR_WIDTH}px);
  left: ${SIDEBAR_WIDTH}px;
  position: relative;

  ${mobile`
    left: 0px;
    width: calc(100%);
  `}

  // Loading variables
  --sidebar-background: transparent;
  
  ${(props) => {
    // After mount variables - can be different for a team and a non-team user
    const declarations = `
      --brand-primary: ${props.appColorScheme?.primary};
      --brand-background: ${props.appColorScheme?.background};
      --sidebar-background: ${props.appColorScheme?.cssVariables?.sidebarBackground};
      --sidebar-item-text-color: ${props.appColorScheme?.cssVariables?.sidebarItemTextColor};
      --sidebar-item-active-background: ${props.appColorScheme?.cssVariables?.sidebarItemActiveBackground};
      --sidebar-item-active-text-color: ${props.appColorScheme?.cssVariables?.sidebarItemActiveTextColor};
      --sidebar-border: ${props.appColorScheme?.cssVariables?.sidebarBorder};
    `

    if (
      !props.showLoaderUntilTeamDetailsAreFetched
    ) {
      return declarations
    }

    return ""
  }}
`

const ChildRoot = styled.div`
  max-width: ${props => props.layoutMaxWidth};
  margin: 0px auto;
`

const handleLogout = () => {
  // this will failed on SSR
  try {
    root.location.href = `${WEB_URL}login`
  } catch (e) {
    // ignore error
  }
}

const Layout = ({
  children,
  location,
  globalPaddingTop,
  layoutMaxWidth,
  isShowUpdate,
  toggleShowUpdate,
  setContentForUpdate,
  getTeamDetailsForLogoResponse,
  paymentBannerVisible,
}) => {
  const { search } = root.location
  const parsedQuery = queryString.parse(search)
  const isIosBrowser = parsedQuery && parsedQuery.platform === "ios"
  const [showLoaderUntilTeamDetailsAreFetched, setShowLoaderUntilTeamDetailsAreFetched] = useState(
    true
  )
  const { data: teamDetailsForLogo } = getTeamDetailsForLogoResponse || {}
  const appColorScheme = getAppColorScheme(teamDetailsForLogo)
  const user = cookie.load("user")

  useEffect(() => {
    // check in local storage is special colors are enabled
    const enableSpecialColors = getItemFromLocalStorage("enableSpecialColors")

    // check if the user is not a part of a team
    // if yes, then fetch the team details
    // once done, hide the radius logo shimmer/loader
    if (user?.brokerageTeamIdForLogo) {
      if (teamDetailsForLogo && teamDetailsForLogo?.id) {
        if (
          teamDetailsForLogo?.brand_primary_color?.length > 0
          && teamDetailsForLogo?.brand_background_color?.length > 0
        ) {
          setItemInLocalStorage("enableSpecialColors", true)
        } else {
          removeItemFromLocalStorage("enableSpecialColors")
        }

        setShowLoaderUntilTeamDetailsAreFetched(false)
      } else {
          setShowLoaderUntilTeamDetailsAreFetched(false)
      }
    } else {
      // if the user is not a part of a team, show the Radius logo always
      setShowLoaderUntilTeamDetailsAreFetched(false)
      removeItemFromLocalStorage("enableSpecialColors")
    }
  }, [teamDetailsForLogo, user?.brokerageTeamIdForLogo])

  return (
    <LayoutWrap
      style={{ paddingTop: globalPaddingTop }}
      showLoaderUntilTeamDetailsAreFetched={showLoaderUntilTeamDetailsAreFetched}
      appColorScheme={appColorScheme}
    >
      {isShowUpdate && (
        <CRMModal
          toClose={() => {
            toggleShowUpdate(false)
            setContentForUpdate(null)
          }}
        >
          <UploadUpdateModal />
        </CRMModal>
      )}
      <PubNubForUpload />
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {/* {!isIosBrowser && (
        <RadiusBanners />
      )} */}
      <Navbar
        location={location}
        showLoaderUntilTeamDetailsAreFetched={showLoaderUntilTeamDetailsAreFetched}
      />
      <Sidebar showLoaderUntilTeamDetailsAreFetched={showLoaderUntilTeamDetailsAreFetched} />
      <ScrollToTop />
      {/* {paymentBannerVisible && (
        <PaymentPendingBanner />
      )} */}
      <ChildRoot layoutMaxWidth={layoutMaxWidth}>
        <div>{children}</div>
      </ChildRoot>
    </LayoutWrap>
  )
}

const HeaderRoute = ({
  component: Component,
  location,
  requireAuth,
  isOnlyPublic,
  globalPaddingTop,
  history,
  toggleShowUpdate,
  setContentForUpdate,
  isShowUpdate,
  layoutMaxWidth = "1440px",
  getTeamDetailsForLogoResponse,
  paymentBannerVisible,
  ...rest
}) => {
  return (
    <>
    <Route
      {...rest}
      render={(props) => {
        const isLogged = authCheck()
        const tcInformation = cookie.load("tcInformation")
        if (tcInformation?.is_tc && isLogged && !TC_PARENT_ROUTES.includes(props?.match?.path)) {
          //return (<Redirect to="/realty/listings" />)
          return (<Redirect to="/realty/transaction-dashboard" />)
        }
        if (requireAuth) {
          if (isLogged) {
            return (
              <Layout
                style={{ paddingTop: globalPaddingTop }}
                location={location}
                globalPaddingTop={globalPaddingTop}
                layoutMaxWidth={layoutMaxWidth}
                isShowUpdate={isShowUpdate}
                toggleShowUpdate={toggleShowUpdate}
                setContentForUpdate={setContentForUpdate}
                getTeamDetailsForLogoResponse={getTeamDetailsForLogoResponse}
                paymentBannerVisible={paymentBannerVisible}
              >
         
                <Component {...props} />
              </Layout>
            )
          }
          handleLogout()
        }
        if (isOnlyPublic && isLogged) {
          const redirectParams = UTMExtract()
          if (redirectParams) {
            history.push(`/community/${redirectParams}`)
          } else {
            history.push("/community")
          }
        }
  
        return (
          <> 
            <Layout
            style={{ paddingTop: globalPaddingTop }}
            location={location}
            globalPaddingTop={globalPaddingTop}
            layoutMaxWidth={layoutMaxWidth}
            isShowUpdate={isShowUpdate}
            toggleShowUpdate={toggleShowUpdate}
            setContentForUpdate={setContentForUpdate}
            getTeamDetailsForLogoResponse={getTeamDetailsForLogoResponse}
            paymentBannerVisible={paymentBannerVisible && isLogged}
          >
            {!isLogged ? (
              <Redirect to="/signup" />
            ) : (
                
                <Component {...props} />
            )}
          </Layout>
          </>
        )
      }}
    />
    </>
    
  )
}

export default TeamProfileContainer(container(techAssets(withRouter(OnboardingContainer(HeaderRoute)))))
