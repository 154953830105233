import styled from "styled-components";
import { Input, Select, Button, Dropdown, Space } from "antd";

export const StyledInput = styled(Input)`
  // flex: 1;
  border-radius: 8px !important;
  border: 1px solid var(--Global-Mapping-Gray-100, #e0e0eb);
  height: 40px !important;
  padding-left: 0px;

  .ant-input {
    height: 30px !important;
    box-shadow: none;
    padding-left: 0px;
  }
`;

export const StyledButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--Global-Mapping-Gray-100, #e0e0eb);
  color: var(--Global-Mapping-Gray-300, #a3a3c2);
  background-color: white;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.5px;
`;

export const StyledSelect = styled(Select) `
  flex: 1;
  // border-radius: 8px;
  // border: 1px solid var(--Global-Mapping-Gray-100, #e0e0eb);
  height: 40px;

  .ant-select-selector {
    cursor: pointer !important;
    height: 40px !important;
    border-radius: 8px !important;
    border: 1px solid var(--Global-Mapping-Gray-100, #e0e0eb) !important;

    // flex-wrap: nowrap !important;
  // overflow-x: auto !important;
  // overflow-y: auto !important;
  // white-space: nowrap !important;
  }

  .ant-select-selector .ant-select-selection-overflow {
    flex-wrap: nowrap !important;
    height: 30px !important;
  }
`;
