import React, { useState, useEffect } from "react"
import { checkIfOnboardingBannerIsVisible } from "@ui/Sidebar/index"
import ListingsContainer from "container/Listings"
import ReferralContainer from "container/Referral"
import TechAssetsContainer from "container/TechAssets"
import TCContainer from "container/Realty/TransactionFormContainer"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import OffersV2 from "container/OffersV2"
import moment from "moment"
import cookie from "services/CookieStorage"
import PaywallBlocker from "dumbComponents/common/SettingsTab/Billings/PaywallBlocker"
import {
  ListingModal,
  ViewContract,
  GoToContract,
  MLSModal,
  StatusChange,
  ViewListing,
  ViewCompliance,
} from "dumbComponents/ListingsV2/Components/Modals"
import ExpiredBanner from "dumbComponents/common/ExpiredBanner"
import CRMModal from "dumbComponents/CRMV2/CRMDashboard/Components/DragAndDropArea/Modals/CRMModal"
import {
  StatusChange as StatusChangeOffer,
  OfferConversionToContract,
} from "dumbComponents/CRMV2/CRMDashboard/Components/DragAndDropArea/Modals"
import Header from "./components/Header"
import TransactionTabs from "./components/TransactionTabs/index"
import {
  TransactionModal,
  TeamLeadModalOffer,
} from "./components/TransactionTabs/components/Modals"
import {
  OFFER, OFFER_CONTRACT, OFFER_LEASE, ADD_BUYER, LISTING_CONTRACT,
  LISTING_LEASE, LISTING,
} from "./const"
import {
  DashboardWrap,
} from "./commonStyles"
const TransactionDashboard = ({
  isTransactionCreationForOffer,
  toggleTransactionCreationForOffer,
  setCreateTransactionalFlow,
  createTransactionalFlow,
  history,
  resetForm,
  setDealToView,
  setOfferIdToView,
  toggleBasicOfferDetailsModal,
  toggleActiveTab,
  setMLSData,
  updateOffersDocumentsObject,
  updateUploadedDocsObject,
  updateEnvelopesObject,
  clearValidateCoverSheet,
  updatePropertyInformationObject,
  updateBuyerInformationObject,
  clearOffersRelatedData,
  editingDealTeamMemberId,
  setListingId,
  setLoaderOnAgentInfo,
  resetContractsForm,
  resetListingsForm,
  togglePropertyFormOrCardView,
  updateCurrentListingData,
  clearExistingData,
  clearExistingTransactionData,
  currentTransactionDashboardTab,
  fetchTransactionOverview,
  isViewListingContractModalOpen,
  viewContractForListing,
  setCurrentListingTab,
  isOnMarketModalOn,
  toggleMoveToMarket,
  setMLSIdForOnMarket,
  isStatusChangeModalOpen,
  toggleStatusChangeModal,
  isStatusChangeModalOpenListing,
  isOfferToContractRedirectionModalVisible,
  toggleOfferToContractConversion,
  toggleStatusChangeModalOffer,
  getTeamDetailsResponse,
  transactionQuery,
  constructTransaction,
  setEditingDealTeamMemberId,
  handleCollapseValues,
  setFilterTabOpen,
  subscriptionStatus,
  fetchSubscriptionStatusEOIResponse,
  subscriptionEndDate,
  isSubscriptionExist,
}) => {
  const {
    bool: showStatusChangeModal,
    statusChangedTo,
    listingID,
  } = isStatusChangeModalOpenListing || {}
  const { isFetching: fetchingTeams, data: teamData } = getTeamDetailsResponse || {}
  const [isOnboardingBannerVisible, setIsOnboardingBannerVisible] = useState(false)
  const tcInformation = cookie.load("tcInformation")
  const teamLead = teamData?.team_members?.find(member => member.member_type === "Team Lead")
  const teamLeadId = teamLead ? teamLead.agent_id : null
  const EOIsubscriptionStatus = fetchSubscriptionStatusEOIResponse?.data?.response?.status
  const [shouldShowEOIExpiredBanner, setShouldShowEOIExpiredBanner] = useState('')
  
    useEffect(() => {
     const EOIsubscriptionStatus = fetchSubscriptionStatusEOIResponse?.data?.response?.status
      setShouldShowEOIExpiredBanner(["incomplete", "past_due", "paused", "trialing", "incomplete_expired", "overdue",  "canceled", "unpaid"].includes(EOIsubscriptionStatus) ? true : false);
  
    }, [fetchSubscriptionStatusEOIResponse])
  
  useEffect(() => {
    if (tcInformation?.is_tc && teamLeadId) {
      checkIfOnboardingBannerIsVisible((isVisible) => {
        setIsOnboardingBannerVisible(isVisible)
      })
      setCreateTransactionalFlow(null)
      toggleTransactionCreationForOffer(false)
      setOfferIdToView(null)
      setDealToView(null)
      setListingId(null)
      clearExistingData()
      //setFilterTabOpen(false)
      clearExistingTransactionData()

      const curr = {
        ...transactionQuery,
      }
      curr.agentId = teamLeadId
      curr.clientTransactionType = currentTransactionDashboardTab
      constructTransaction({
        query: curr,
      })

      const toDate = moment().unix()
      const fromDate = moment().startOf("year").unix()

      const payload = {
        agentId: teamLeadId,
        clientTransactionType: currentTransactionDashboardTab,
        toDate,
        fromDate,
      }
      fetchTransactionOverview({
        payload,
      })
    }
  }, [teamLeadId])
  useEffect(() => {
    if (!tcInformation?.is_tc) {
      checkIfOnboardingBannerIsVisible((isVisible) => {
        setIsOnboardingBannerVisible(isVisible)
      })
      setCreateTransactionalFlow(null)
      toggleTransactionCreationForOffer(false)
      setOfferIdToView(null)
      setDealToView(null)
      setListingId(null)
      clearExistingData()
      //setFilterTabOpen(false)
      clearExistingTransactionData()

      const toDate = moment().unix()
      const fromDate = moment().startOf("year").unix()

      const payload = {
        clientTransactionType: currentTransactionDashboardTab,
        toDate,
        fromDate,
      }
      fetchTransactionOverview({
        payload,
      })
    }
  }, [])
  const handleCreateFlow = (agentIdValue, tranactionFlow = null) => {
    setOfferIdToView(null)
    setDealToView(null)
    setListingId(null)
    if (createTransactionalFlow == OFFER || tranactionFlow == OFFER) {
      resetForm()
      toggleActiveTab("cover_sheet")
      setMLSData({})
      updateOffersDocumentsObject([])
      updateUploadedDocsObject([])
      updateEnvelopesObject([])
      clearValidateCoverSheet()
      updatePropertyInformationObject({
        addressRepresentation: false,
        currentState: "california",
        form: {},
        listing_agents: [],
        sellers: [],
        mls_number: "",
      })
      updateBuyerInformationObject({
        agents: [],
        buyers: [],
        lenders: [],
        selectedLender: null,
        selected_lender_id: null,
        vendors: [],
        selectedVendor: null,
        selected_vendor_id: null,
        financingType: null,
        source: "",
        other_source: "",
      })
      clearOffersRelatedData()
      toggleBasicOfferDetailsModal(true)
      const nullValue = null
      toggleTransactionCreationForOffer(false)
      const url = `/realty/offers-v3?transaction_id=${nullValue}&req_id=${nullValue}&client_id=${nullValue}&client_source=${nullValue}&agent_id=${agentIdValue}`
      history.push(url)
    } else if (createTransactionalFlow == OFFER_CONTRACT || tranactionFlow == OFFER_CONTRACT) {
      setLoaderOnAgentInfo(true)
      resetContractsForm()
      resetListingsForm()
      toggleTransactionCreationForOffer(false)
      history.push("/realty/transaction-coordination/contract/form?clientType=buyer")
    } else if (createTransactionalFlow == OFFER_LEASE || tranactionFlow == OFFER_LEASE) {
      setLoaderOnAgentInfo(true)
      resetContractsForm()
      resetListingsForm()
      toggleTransactionCreationForOffer(false)
      history.push("/realty/transaction-coordination/contract/form?clientType=tenant")
    } else if (createTransactionalFlow == LISTING_CONTRACT || tranactionFlow == LISTING_CONTRACT) {
      setLoaderOnAgentInfo(true)
      resetContractsForm()
      resetListingsForm()
      toggleTransactionCreationForOffer(false)
      history.push("/realty/transaction-coordination/contract/form?clientType=seller")
    } else if (createTransactionalFlow == LISTING_LEASE || tranactionFlow == LISTING_LEASE) {
      setLoaderOnAgentInfo(true)
      resetContractsForm()
      resetListingsForm()
      toggleTransactionCreationForOffer(false)
      history.push("/realty/transaction-coordination/contract/form?clientType=landlord")
    } else if (createTransactionalFlow == LISTING || tranactionFlow == LISTING) {
      updateCurrentListingData({
        dealId: null,
        editingAgentId: agentIdValue,
        transactionId: null,
        listingStatus: "incomplete",
        propertyData: null,
        agents: null,
        sellers: null,
        listingTerms: null,
        propertyDataErrorCount: null,
        agentsDataErrorCount: null,
        sellersDataErrorCount: null,
        listingTermsErrorCount: null,
      })
      handleCollapseValues(["property_information"])
      togglePropertyFormOrCardView(false)
      toggleTransactionCreationForOffer(false)
      history.push("/listings/form")
    }
  }
  const isExpiringSoon = moment.unix(subscriptionEndDate).diff(moment(), "days") <= 3
  const shouldShowExpiredBanner = ["incomplete", "past_due", "paused", "incomplete_expired", "overdue" , "canceled", "unpaid"].includes(subscriptionStatus)
  const shouldShowPaywallBlocker = ["canceled", "unpaid"].includes(subscriptionStatus)
  return (
    <DashboardWrap isOnboardingBannerVisible={isOnboardingBannerVisible}>
      {isSubscriptionExist && (shouldShowExpiredBanner || isExpiringSoon || shouldShowEOIExpiredBanner) ? (<ExpiredBanner />) : (<></>)}
      {shouldShowPaywallBlocker ? (<PaywallBlocker />) : (<></>)}
      {isTransactionCreationForOffer && (
        <TransactionModal
          height="210px"
          toClose={() => {
            setCreateTransactionalFlow(null)
            toggleTransactionCreationForOffer(false)
          }}
        >
          <TeamLeadModalOffer
            handleCreateFlow={handleCreateFlow}
            //loading={deletingOffer}
            toClose={() => {
              setCreateTransactionalFlow(null)
              toggleTransactionCreationForOffer(false)
            }}
          />
        </TransactionModal>
      )}
      {isViewListingContractModalOpen && (
        <ListingModal
          height="210px"
          toClose={() => {
            viewContractForListing(false)
            setCurrentListingTab("cover_sheet")
            history.push("/realty/transaction-dashboard")
          }}
        >
          <ViewContract
            listingView
            toClose={() => {
              viewContractForListing(false)
              setCurrentListingTab("cover_sheet")
              history.push("/realty/transaction-dashboard")
            }}
          />
        </ListingModal>
      )}
      {isOnMarketModalOn && (
        <ListingModal
          toClose={() => {
            toggleMoveToMarket(false)
            setMLSIdForOnMarket({
              mlsId: null,
              uniqueId: null,
            })
          }}
        >
          <MLSModal
            onClose={() => {
              toggleMoveToMarket(false)
              setMLSIdForOnMarket({
                mlsId: null,
                uniqueId: null,
              })
            }}
          />
        </ListingModal>
      )}
      {showStatusChangeModal && (
        <ListingModal
          toClose={() => {
            toggleStatusChangeModal({
              bool: false,
              statusChangedTo: null,
            })
          }}
        >
          <StatusChange
            statusChangedTo={statusChangedTo}
            id={listingID}
          />
        </ListingModal>
      )}
      {isOfferToContractRedirectionModalVisible.bool && (
        <CRMModal
          toClose={() => {
            setEditingDealTeamMemberId(null)
            toggleOfferToContractConversion({
              bool: false,
              url: "",
            })
          }}
        >
          <OfferConversionToContract
            toClose={() => {
              setEditingDealTeamMemberId(null)
              toggleOfferToContractConversion({
                bool: false,
                url: "",
              })
            }}
          />
        </CRMModal>
      )}
      {isStatusChangeModalOpen.bool && (
        <CRMModal
          toClose={() => {
            toggleStatusChangeModalOffer({
              bool: false,
              id: "",
            })
          }}
        >
          <StatusChangeOffer
            toClose={() => toggleStatusChangeModalOffer({
              bool: false,
              id: "",
            })}
          />
        </CRMModal>
      )}
      <Header handleCreateFlow={handleCreateFlow} />
      <TransactionTabs teamData={teamData} />
    </DashboardWrap>
  )
}

export default ListingsContainer(TechAssetsContainer(TCContainer(TeamProfileContainer(OffersV2(ReferralContainer(TransactionDashboard))))))
