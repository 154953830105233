import React from "react"
import { ATC } from "@fonts"
import { withRouter } from "react-router-dom"
import { get } from "lodash"
import Cookies from "services/CookieStorage"
import styled from "styled-components"
import colors from "@colors"
import { Button } from "@ui/antd"
import CRMV2Container from "container/CRM"

const Wrap = styled.div`
  margin-top: 20px;
`

const Container = styled.div`
  background: #E2E8FF;
  padding: 18px 20px;
  border-radius: 4px;
`

const Title = styled.h1`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: ${colors.black};
  margin: 0;
`

const Description = styled.p`
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  color: ${colors.black};
  font-family: ${ATC};
`

const ButtonWrap = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 10px;
`

const DuplicateTransaction = ({
  title,
  description,
  type,
  fieldValues,
  addSearchCriteria,
  toggleAddNewLeadDrawer,
  addPropertyDetails,
  cityFormValue,
  history,
  addCrmClientResponse,
  addPropertyDetailsResponse,
  addBuyerReqResponse,
  toggleUserOverview,
  setCurrentDrawerPage,
  getCrmClientResponse,
  getClientTransactionsById,
  getClient,
  buyerReqClear,
  propListClear,
  resetForm,
}) => {
  const submitText = type === "buyer" ? "Create Search Criteria" : "Create Listing Profile"
  const secureId = Cookies.load("secureId")
  const { status } = fieldValues || {}

  const { data: clientData } = addCrmClientResponse || {}
  const { id: clientId } = clientData || {}

  const { data: listingData } = addPropertyDetailsResponse || {}
  const { transaction_id: listingTransactionId } = listingData || {}

  const { data: requirementData } = addBuyerReqResponse || {}
  const { transaction_id: reqTransactionId } = requirementData || {}

  const BUYING = get(getCrmClientResponse, "data.clientTransactions")
  const SELLING = get(getCrmClientResponse, "data.clientTransactions")

  const handleClick = () => {
    const transactionPayload = {
      source: "crm",
      transaction_referral_type: "crm",
      type,
      secure_id: secureId,
      status,
    }
    if (type === "buyer") {
      const searchCriteriaPayload = fieldValues.search_criteria
      for (let index = 0; index < searchCriteriaPayload.length; index += 1) {
        const {
          area_unit,
          bathroom,
          bedroom,
          max_budget,
          max_size,
          min_budget,
          min_size,
          sale_type,
        } = searchCriteriaPayload[index]
        const finalSearchCriteriaPayload = {
          area_unit,
          bathroom,
          bedroom,
          locations: [cityFormValue[index]],
          max_budget,
          max_size,
          min_budget,
          min_size,
          sale_type,
          u_cust_id: clientId,
        }
        addSearchCriteria({
          finalSearchCriteriaPayload, finalTransactionPayload: { ...transactionPayload, client_id: clientId }, history,
        })
      }
      toggleAddNewLeadDrawer(false)
    } else if (type === "seller") {
      const propertyDetailsPayload = fieldValues.property_details
      for (let index = 0; index < propertyDetailsPayload.length; index += 1) {
        const {
          area_coverage,
          no_of_bath_room,
          no_of_bed_room,
          sale_type,
          property_status,
          property_type,
          resale_price,
          covered_area,
        } = propertyDetailsPayload[index]
        const {
          city, state, country, latitude, longitude, address, zipcode,
        } = cityFormValue[index]
        const finalPropertyDetailsPayload = {
          area_coverage,
          no_of_bath_room,
          no_of_bed_room,
          address,
          sale_type,
          rent_resale: sale_type,
          u_cust_id: clientId,
          property_status,
          property_type,
          city,
          state,
          country,
          latitude,
          longitude,
          resale_price,
          covered_area,
          zipcode,
        }
        addPropertyDetails({
          finalPropertyDetailsPayload, finalTransactionPayload: { ...transactionPayload, client_id: clientId }, history,
        })
        toggleAddNewLeadDrawer(false)
      }
    }
  }

  const handleRedirection = () => {
    getClientTransactionsById({ transactionId: listingTransactionId || reqTransactionId })
    getClient({ clientId, type })
    history.push(`?transactionId=${listingTransactionId || reqTransactionId}&client_id=${clientId}&type=${type}`)
    toggleAddNewLeadDrawer(false)
    toggleUserOverview({ showUserOverviewDrawer: true, agentInfo: clientId })
    if ((type === "buyer" && BUYING && BUYING.length !== 1)
      || (type === "seller" && SELLING && SELLING.length !== 1)) {
      setCurrentDrawerPage("drawer-header")
    } else if (type === "buyer") {
      setCurrentDrawerPage("search-criteria")
    } else if (type === "seller") {
      setCurrentDrawerPage("property-listing")
    }
    buyerReqClear()
    propListClear()
    resetForm()
  }

  return (
    <Wrap>
      <Container>
        <Title>
          {title}
        </Title>
        <Description>
          {description}
        </Description>
        <ButtonWrap>
          <Button
            style={{
              background: `${colors.new_primary}`,
              borderColor: `${colors.new_primary}`,
              borderRadius: "4px",
            }}
            type="primary"
            htmlType="submit"
            onClick={handleClick}
          >
            {submitText}
          </Button>
          <Button
            style={{
              background: `${colors.white}`,
              borderColor: `${colors.new_primary}`,
              color: `${colors.new_primary}`,
              borderRadius: "4px",
            }}
            type="primary"
            htmlType="submit"
            onClick={handleRedirection}
          >
            Go to Existing Profile
          </Button>
        </ButtonWrap>
      </Container>
    </Wrap>
  )
}

export default withRouter(CRMV2Container(DuplicateTransaction))
