import React from "react"
import styled from "styled-components"
import { isEmpty } from "lodash"
import ProfileDropdown from "./ProfileDropdown"
import CookiesStorage from "services/CookieStorage"
import { NavLink } from "./index"
import uuid from "uuid"
import LoginButton from "./LoginButton"
import MainDropdownNav from "./MainDropDownNav"

const NavWrap = styled.div`
  background: #FFF;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2.5px 0px;
  z-index: 1000;
  align-items: center;
`

const ContainerWrap = styled.div.attrs({
  className: "container",
})`
  display: flex;
`

const LogoWrap = styled.div`
  img {
    height: 50px;
    padding: 3px;
  }
`

const Seperator = styled.div`
  flex: 1;
`

const OptionsWrap = styled.div`
  display: flex;
  align-items: center;
`

const AREAALogo = "//s3.amazonaws.com/icons.agentdesks.com/mail_api/areaa_logo.png"
const CRSLogo = "//d2fedz0by71ckz.cloudfront.net/images/rrc-logo.png"
const WCRLogo = "//s3.amazonaws.com/cdn.agentdesks.com/images/wcr_logo.jpg"

const getLogo = (type) => {
  switch (type) {
    case "isCrs":
      return CRSLogo
    case "isAreaa": {
      return AREAALogo
    }
    case "isWcr":
      return WCRLogo
    default:
      return "//d2fedz0by71ckz.cloudfront.net/images/temp-logo%402x.png"
  }
}

class ThirdPartyNavbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const user = CookiesStorage.load("user")
    this.setState({
      user,
    })
  }

  render() {
    const {
      thirdParty = {},
      profileImage,
      user,
      isAdvanceUser,
    } = this.props
    return (
      <NavWrap key={uuid()}>
        <ContainerWrap>
          <LogoWrap id="third-party-logo">
            <img src={getLogo(Object.keys(thirdParty)[0])} alt="Logo" id="third-party-logo-img" style={{ height: 50 }}/>
          </LogoWrap>
          <Seperator />
          {/* <MainDropdownNav /> */}
          <OptionsWrap>
            {user && !isEmpty(user) && !user.isUnauthorised ? (
              <div style={{ display: "flex" }}>
                {/* <NavLink href={`${APP_URL}/community?viewType=all&openchat=true`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="22"
                    viewBox="0 0 23 22"
                    style={{ marginBottom: 1 }}
                  >
                    <path
                      fill="#626262"
                      fillRule="evenodd"
                      d="M17.22 8.773a1.345 1.345 0 1 1-2.69-.001 1.345 1.345 0 0 1 2.69 0zm-4.476 0a1.344 1.344 0 1 1-2.688 0 1.344 1.344 0 0 1 2.688 0zm-4.475 0a1.345 1.345 0 1 1-2.69-.001 1.345 1.345 0 0 1 2.69 0zm13.24 3.696a3.791 3.791 0 0 1-3.786 3.788H14.69c-.543 0-1.064.257-1.394.69l-1.901 2.491-1.892-2.49a1.764 1.764 0 0 0-1.396-.691h-3.03a3.792 3.792 0 0 1-3.787-3.788V5.077C1.29 2.99 2.99 1.29 5.078 1.29h12.645c2.088 0 3.786 1.7 3.786 3.788v7.392zM17.723 0H5.078A5.084 5.084 0 0 0 0 5.077v7.392c0 2.8 2.278 5.077 5.078 5.077h3.03c.144 0 .282.07.369.183l2.404 3.165a.65.65 0 0 0 1.027 0l2.415-3.165a.462.462 0 0 1 .368-.183h3.032c2.8 0 5.077-2.278 5.077-5.077V5.077C22.8 2.277 20.522 0 17.723 0z"
                    />
                  </svg>
                  <p style={{ transform: "translateY(2px)" }}>Messages</p>
                </NavLink> */}
                <ProfileDropdown
                  profileImage={profileImage}
                  userProp={user}
                  isAdvanceUser={isAdvanceUser}
                />
              </div>
            ) : (
              <LoginButton />
            )}
          </OptionsWrap>
        </ContainerWrap>
      </NavWrap>
    )
  }
}

export default ThirdPartyNavbar
