import React from "react"
import root from "window-or-global"

// Polyfill TODO: Make it conditionally
import "classlist-polyfill"
import Loadable from "@react-loadable/revised"
import bugsnag from "bugsnag-js"
import ReactDOM from "react-dom"
import createPlugin from "bugsnag-react"
import {preloadReady} from '@react-loadable/revised' // to load the pre-loaded components, used in client
import cookie from "services/CookieStorage"
import { ToastContainer, toast } from "react-toastify"
import icons from "@icons"
import { isBrowser } from "services/Utils"
// import { messagingProd } from "./init-fcm-prod"
import { messaging } from "./init-fcm"

import App from "./App"
root.bugsnagClient = bugsnag(BUGSNAG_KEY)

const ErrorBoundary = root.bugsnagClient.use(createPlugin(React))

if (process.env.NODE_ENV === "production") {
  console.log = () => {}
  console.debug = () => {}
  console.info = () => {}
  // Optional: uncomment below if you want to suppress warnings/errors too
  // console.warn = () => {}
  // console.error = () => {}
}

if (!root.fetch) {
  require("whatwg-fetch")
}

const messagingLib = messaging

const filePath = "/firebase-messaging-sw.js"

try {
  if (root.isSecureContext) {
    navigator.serviceWorker
      .register(filePath)
      .then((registration) => {
        console.log("Registration successful")
        registration.update()
        messagingLib.useServiceWorker(registration)
        const token = messagingLib.getToken()
        token.then((data) => {
          cookie.save("FCMToken", data)
        })
      })
      .catch((err) => {
        console.log("Service worker registration failed, error:", err)
      })
  } else {
    console.warn("Notifications are denied, service worker registration is denied")
  }
} catch (error) {
  console.log("Failed to load service worker.");
}

const toastWrap = root.document.getElementById("toast-wrap")

const CloseButton = ({ closeToast }) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  <i onClick={() => closeToast()} className={icons.genericClose}></i>
)

preloadReady().then(() => {
  ReactDOM.hydrate(
    <ErrorBoundary>
      <App />
      {isBrowser() && ReactDOM.createPortal(
        <ToastContainer position={toast.POSITION.BOTTOM_LEFT} closeButton={<CloseButton />} />,
        toastWrap
      )}
    </ErrorBoundary>,
    document.getElementById("index")
  )
})

