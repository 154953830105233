import { get } from "lodash"
import {getStateShortName} from "dumbComponents/ListingsV2/Components/ListingTabs/consts.js";

export const validateEmail = value => (
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    false : true
)

export const validateFields = (fieldValues) => {
  let phoneValid = false
  let emailValid = false
  const { name, email: emailId, phone } = fieldValues
  if (typeof phone === "string" && phone.length === 12) {
    phoneValid = true
  } else if (phone.toString().length === 10) {
    phoneValid = true
  }
  if (validateEmail(emailId)) {
    emailValid = true
  }
  const isOverallTrue = phoneValid && emailValid
  return { phoneValid, emailValid, isOverallTrue }
}

export const checkIsBroker = (status, type, history) => {
  if (type === "seller" || type === "Seller") {
    switch (status) {
      case "New Client":
      case "Met with Client":
      case "Pre-approved/Listing Prepped":
      case "Showings/Tours":
      case "Sending/Receiving Offers":
        history.push("/realty/promos?type=listings")
        return
      case "In Contract":
        history.push("/realty/promos?type=contracts")
        return
      default:
        history.push("/realty/promos?type=listings")
    }
  } else {
    switch (status) {
      case "Showings/Tours":
      case "Sending/Receiving Offers":
        history.push("/realty/promos?type=offers")
        return
      case "In Contract":
        history.push("/realty/promos?type=contracts")
        return
      default:
        history.push("/realty/promos?type=offers")
    }
  }
}

export const getFormattedLocationObject = (location, withPlaceId) => {
  const result = {
    city: get(location, "locality.long_name"),
    state: get(location, "administrative_area_level_1.long_name"),
    stateShortName: get(location, "administrative_area_level_1.short_name"),
    country: get(location, "country.long_name"),
    c_locality_latitude: get(location, "lat"),
    c_locality_longitude: get(location, "lng"),
    address: get(location, "address"),
    streetName: get(location, "street_number.short_name", ""),
    c_locality_name: get(location, "neighborhood.long_name"),
    route: get(location, "route.long_name", ""),
    zipcode: get(location, "postal_code.long_name"),
    is_zipcode_search: get(location, "isZipCodeSearch"),
  }

  if (withPlaceId) {
    result.place_id = get(location, "place_id")
  }

  return result
}

export const getReadableLocationFromGoogleLocation = (location) => {
  let result = ""

  const locationDetails = getFormattedLocationObject(location, true)
  const stateShort = getStateShortName(locationDetails.state);

  let neighborhood = null

  if (location.neighborhood) {
    neighborhood = location.neighborhood.long_name
  } else if (location.route) {
    neighborhood = location.route.long_name
  } else if (location.postal_code) {
    neighborhood = location.postal_code.long_name
  } else if (location.sublocality_level_1) {
    neighborhood = location.sublocality_level_1.long_name
  }

  if(location.neighborhood) {
    result = `${locationDetails.c_locality_name}, ${locationDetails.city}, ${stateShort}`
  }
  else if(location.isZipCodeSearch) {
    result = `${locationDetails.zipcode} (${stateShort})`
  }
  else {
    result = `${locationDetails.city}, ${stateShort}`
  }


  // if (locationDetails.address) {
  //   result = `${locationDetails.address}`
  // } else if (neighborhood) {
  //   result = `${neighborhood}, ${locationDetails.city}, ${locationDetails.state}`
  // } else {
  //   result = `${locationDetails.city}, ${locationDetails.state}`
  // }

  return result
}

export const getReadableLocationFromRadiusApi = (location) => {
  let locationString = ""
  const stateShort = getStateShortName(location.state);

  if(location && location.is_zipcode_search == true) {
    locationString = `${location.zipcode} (${stateShort})`
  }
  else{
    if (location && location.c_locality_name !== null) {
      if (location.c_locality_name.includes(',')) {
        // locationString = location.c_locality_name;
        locationString = location.c_locality_name.replace(', USA', '');
      } else {
        locationString = `${location.c_locality_name}, ${location.city}, ${stateShort}`;
      }
    }
    else {
      if (location && location.city !== null) {
        locationString += `${location.city}, ${stateShort}`
      }

      // locationString += `, ${location && location.state}`
    }
  }

  return locationString
}

export const formatAdressWithoutStateAndCountry = (location) => {
  const addressString = typeof location === "string" ? location : get(location, "c_locality_name")
  const splittedAddress = addressString.split(",")

  if (splittedAddress.length >= 4) {
    splittedAddress.splice(splittedAddress.length - 2, 2)

    if (splittedAddress.length >= 2) {
      return splittedAddress.join(", ")
    }

    return splittedAddress.join(" ")
  }

  return addressString
}
