import React from "react"
import styled from "styled-components"
import colors from "@colors"
import { get } from "lodash"
import root from "window-or-global"
import CircularImage from "dumbComponents/common/CircularImage"
import AddIcon from "images/Graphics/ScheduledPageAssets/Add.png"
import Rooms from "container/Rooms/index"
import RTViewer from "@ui/RTViewer"
import App from "container/App"
import { ATC } from "@fonts"

const Wrap = styled.div`
  margin-top: 53px;
  max-width: 1039px;
`

const Title = styled.h1`
  font-family: Butler;
  letter-spacing: 0.005em;
  font-weight: 700;
  text-align: left;
  color: ${colors.black};

  @media(min-width: 768px) {
    font-size: 48px;
    line-height: 54px;
  }

  @media(min-width: 1024px) {
    font-size: 54px;
    line-height: 60px;
  }

  @media(min-width: 1366px) {
    font-size: 54px;
    line-height: 60px;
  }

  @media(max-width: 767px) {
    font-size: 44px;
    line-height: 52px;
  }
`

const About = styled.div`
  margin-top: 53px;
`

const SubHeader = styled.h2`
  font-family: ${ATC};
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  colors: ${colors.black};

  @media(min-width: 768px) {
    font-size: 22px;
    line-height: 28px;
  }

  @media(min-width: 1024px) {
    font-size: 22px;
    line-height: 28px;
  }

  @media(min-width: 1366px) {
    font-size: 22px;
    line-height: 28px;
  }

  @media(max-width: 767px) {
    font-size: 18px;
    line-height: 22px;
  }
`

const Description = styled.p`
  font-family: ${ATC};
  font-weight: 300;
  letter-spacing: 0em;
  text-align: left;

  @media(min-width: 768px) {
    font-size: 16px;
    line-height: 23px;
  }

  @media(min-width: 1024px) {
    font-size: 18px;
    line-height: 25px;
  }

  @media(min-width: 1366px) {
    font-size: 18px;
    line-height: 25px;
  }

  @media(max-width: 767px) {
    font-size: 14px;
    line-height: 23px;
  }
`

const SpeakerDescriptionDiv = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const SpeakerFlexWrap = styled.div`
  display: flex;

  @media(min-width: 768px) {
    flex-wrap: wrap;
    gap: 38px;
    flex-direction: column;
  }

  @media(min-width: 1099px) {
    gap: 50px;
    flex-wrap: wrap;
    flex-direction: row;
  }

  @media(min-width: 1366px) {
    gap: 56px;
    flex-wrap: wrap;
    flex-direction: row;
  }

  @media(max-width: 767px) {
    flex-wrap: wrap;
    gap: 50px;
  }
`

const AboutSpeakerWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  max-width: 335px;
`

const SpeakerHostWrap = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: row;
`

const ImageSection = styled.div``

const AboutSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const HostedBy = styled.p`
  font-family: ${ATC};
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
  margin: 0;
`

const SpeakerNameWrap = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
  position: relative;
`

const SpeakerName = styled.h1`
  font-family: ${ATC};
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
  margin: 0;
  width: max-content;
`

const Follow = styled.button`
  display: flex;
  align-items: flex-start;
  position: relative;
  top: -2px;
  gap: 8px;
  cursor: pointer;
  background: transparent;
  text-align: center;
  border: none;

  p {
    font-family: ${ATC};
    font-size: 15px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
    color: #849DF8;
  }
  img {
    height: 16px;
    width: 16px;
  }
`

const SpeakerProfession = styled.h2`
  font-family: ${ATC};
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #8C8C8C;
  margin: -10px 0px;
`

const SpeakerContentDiv = styled.div``

const SpeakerContent = styled.p`
  font-family: ${ATC};
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
`

const SpeakerSection = styled.div`
  margin-top: 57px;
`

const ShareWrap = styled.div``

const CTAActionDiv = styled.div`
  display: flex;
  align-items: end;
  justify-content: flex-start;
  gap: 15px;

  @media(max-width: 767px) {
    flex-wrap: wrap;
    max-width: 160px;
  }
`

const RegisterButton = styled.button`
  /* Buttons */


  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 10px;
  border: none;

  cursor: pointer;

  width: max-content;
  height: 40px;

  /* Brand Colors/Radius Agent/Primary/Sapphire */

  background: #5A5FF2;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  font-family: ${ATC};
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  color: white;

  ${props => props.disabled && `
      pointer: not-allowed;
      background-color: grey;
  `}

  ${props => props.inverted && `
    border: 1px solid #5A5FF2;
    background: white;
    color: #5A5FF2;
  `}

  ${props => props.registered && `
    border: none;
    padding: 20px 0px 20px 0px;

    @media(max-width: 767px) {
      padding: 12px 20px 20px 0px;
    }
  `}

  img {
    height: 16px;
    width: 16px;
  }
`

const getBase64ConvertedData = (block) => {
  try {
    const convertedBlocks = decodeURIComponent(escape(atob(block)))
    return JSON.parse(convertedBlocks)
  } catch (e) {
    //
  }
}

const Details = ({
  toggleShareLinkModal,
  getPreRecordedDetailsResponse,
  isAppMounted,
}) => {
  const {
    data: video,
  } = getPreRecordedDetailsResponse || {}

  const {
    Instructors,
  } = video || {}

  const getInstructorDetails = (instructor) => {
    const instructorName = instructor && get(instructor, "name", "Instructor") || ""
    const instructorImage = instructor && get(instructor, "image_url", "") || ""
    const instructorFirm = instructor && get(instructor, "firm", "Real Estate Agent") || ""
    const instructorProfile = instructor && get(instructor, "radius_agent_handle", "") || ""
    const instructorAboutBase64 = instructor && get(instructor, "bio", "") || ""
    const instructorAbout = instructor && get(instructor, "bio_RTF") || ""

    return {
      instructorName,
      instructorImage,
      instructorFirm,
      instructorProfile,
      instructorAboutBase64,
      instructorAbout,
    }
  }

  return (
    <Wrap>
      <Title>
        {video.title || "Radius Rooms Content"}
      </Title>
      <About>
        <SubHeader>
          About this room
        </SubHeader>
        {isAppMounted && (
          <>
            {video.description_RTF ? (
              <RTViewer data={video.description_RTF} />
            ) : (
              <RTViewer data={getBase64ConvertedData(video.description)} />
            )}
          </>
        )}
      </About>
      <SpeakerSection>
        <SubHeader>
          Speaker Details
        </SubHeader>
        <SpeakerFlexWrap>
          {isAppMounted && video && Instructors && Instructors.map(instructor => (
            <SpeakerDescriptionDiv>
              <AboutSpeakerWrap>
                <SpeakerHostWrap>
                  <ImageSection>
                    <CircularImage
                      image={getInstructorDetails(instructor).instructorImage}
                      size={71}
                      charSize="16"
                      name={getInstructorDetails(instructor).instructorName}
                    />
                  </ImageSection>
                  <AboutSection>
                    <HostedBy>Hosted by:</HostedBy>
                    <SpeakerNameWrap>
                      <SpeakerName>{getInstructorDetails(instructor).instructorName}</SpeakerName>
                      <Follow
                        onClick={() => {
                          if (getInstructorDetails(instructor).instructorProfile) {
                            root.open(getInstructorDetails(instructor).instructorProfile, "_blank")
                          }
                        }}
                      >
                        <p>Follow</p>
                        <img src={AddIcon} alt="add.png" />
                      </Follow>
                    </SpeakerNameWrap>
                    <SpeakerProfession>
                      {getInstructorDetails(instructor).instructorFirm}
                    </SpeakerProfession>
                  </AboutSection>
                </SpeakerHostWrap>
                <SpeakerContentDiv>
                  {isAppMounted && (
                    <>
                      {getInstructorDetails(instructor).instructorAbout ? (
                        <RTViewer data={getInstructorDetails(instructor).instructorAbout} />
                      ) : (
                        <RTViewer
                          data={getBase64ConvertedData(getInstructorDetails(instructor).instructorAboutBase64)}
                        />
                      )}
                    </>
                  )}
                </SpeakerContentDiv>
              </AboutSpeakerWrap>
            </SpeakerDescriptionDiv>
          ))}
        </SpeakerFlexWrap>
      </SpeakerSection>
      <ShareWrap>
        <SubHeader>
          Share
        </SubHeader>
        <CTAActionDiv>
          <RegisterButton
            inverted
            type="button"
            onClick={() => {
              toggleShareLinkModal(true)
            }}
          >
            Share with others
          </RegisterButton>
        </CTAActionDiv>
      </ShareWrap>
    </Wrap>
  )
}

export default App(Rooms(Details))
