import React, { useRef } from "react"
import styled from "styled-components"
import CRMV2Container from "container/CRM"
import moment from "moment"
import RemoveIcon from "images/realty-v2/pricing/Minus.svg"
import {
  Form,
  Select,
  Button,
  Space,
  StyledTextArea,
  DatePicker,
} from "@ui/antd"
import {
  LabelComponent, Icon,
} from "../../../common"
import { ATC } from "@fonts"

const { Item } = Form
const { Option } = Select

const Wrap = styled.div`
  padding: 20px;
`

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CloseWrap = styled.div`
  display: flex;
  cursor: pointer;
`

const Header = styled.h1`
  font-family: ${ATC};
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
`

const FormWrap = styled.div``

const ADD_REMINDERS_EVENTS = [
  { value: "call", label: "Call" },
  { value: "property showing", label: "Property Showing" },
  { value: "meeting", label: "Meeting" },
  { value: "sale", label: "Sale" },
  { value: "email", label: "Email" },
  { value: "follow up", label: "Follow up" },
]

const ADD_REMINDERS_TYPES = [{
  label: "At the time of event",
  value: "0m",
}, {
  label: "5 minutes before",
  value: "5m",
}, {
  label: "15 minutes before",
  value: "15m",
}, {
  label: "30 minutes before",
  value: "30m",
}, {
  label: "1 hour before",
  value: "1h",
}, {
  label: "2 hours before",
  value: "2h",
}, {
  label: "1 day before",
  value: "1d",
}, {
  label: "1 week before",
  value: "1w",
}]

const DURATION_OPTIONS = [
  { value: 15, label: "15 minutes" },
  { value: 30, label: "30 minutes" },
  { value: 45, label: "45 minutes" },
  { value: 60, label: "1 hour" },
]

const AddReminders = ({
  setReminderActionResponse,
  createReminder,
  setClicked,
  getCtIdResponse,
  setEditClicked,
  isEditClicked,
  updateReminder,
}) => {
  const formRef = useRef(null)

  const { data: transactionData } = getCtIdResponse || {}
  const { id, client_id } = transactionData || {}

  const {
    isFetching: creatingReminder,
  } = setReminderActionResponse || {}

  const onFinish = (values) => {
    setClicked(false)
    const {
      time,
      type,
      note,
      notify_time,
      event_duration,
    } = values

    if (isEditClicked) {
      const payload = {
        clientId: client_id,
        client_transaction_id: id,
        date: time.unix(),
        time: time.format(),
        event_type: ADD_REMINDERS_EVENTS.filter(item => item.value === type)[0],
        note,
        notify_time,
        type,
        event_duration,
      }

      updateReminder({
        payload,
        client_id,
        reminder_id: isEditClicked?.activity_id,
      })
      setEditClicked(false)
    } else {
      const payload = {
        clientId: client_id,
        client_transaction_id: id,
        date: time.unix(),
        time: time.format(),
        event_type: ADD_REMINDERS_EVENTS.filter(item => item.value === type)[0],
        note,
        notify_time,
        type,
        event_duration,
      }

      createReminder({
        payload,
        clientId: client_id,
      })
    }
  }

  const validateMessages = () => {

  }

  const commonRules = [
    {
      required: true,
      message: "Required",
    },
  ]

  const getInitialValues = () => {
    let values = {}
    values = {
      type: isEditClicked?.type,
      note: isEditClicked?.note,
      time: isEditClicked ? moment(new Date(isEditClicked?.date * 1000)) : moment(new Date()),
      notify_time: isEditClicked?.notify_time,
      event_duration: isEditClicked?.event_duration,
    }
    return values
  }

  return (
    <Wrap>
      <FormWrap>
        <Form
          name="add_reminder"
          preserve
          colon={false}
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
          validateMessages={validateMessages}
          ref={formRef}
          initialValues={getInitialValues()}
        >
          <HeaderWrap>
            <Header>Add/Edit Reminders</Header>
            <CloseWrap
              onClick={() => {
                setClicked(false)
                setEditClicked(false)
              }}
            >
              <p>Remove</p>
              <Icon src={RemoveIcon} height={15} />
            </CloseWrap>
          </HeaderWrap>
          {/*Reminder Type*/}
          <Space style={{ display: "block", marginBottom: 8, marginTop: 20 }} align="baseline">
            <Item name="type" label={<LabelComponent text="Type of reminder" />} rules={[{ required: true, message: "Event Type required" }]}>
              <Select placeholder="Select..." alignTextLeft>
                {ADD_REMINDERS_EVENTS && ADD_REMINDERS_EVENTS.map(type => (
                  <Option value={type.value}>
                    {type.label}
                  </Option>
                ))}
              </Select>
            </Item>
          </Space>

          {/*Details/Description*/}
          <Space style={{ display: "block", marginBottom: 8, marginTop: 20 }} align="baseline">
            <Item name="note" label={<LabelComponent text="Description for Reminder*" />} rules={commonRules}>
              <StyledTextArea />
            </Item>
          </Space>

          {/*Date*/}
          <Space style={{ display: "block", marginBottom: 8, marginTop: 20 }} align="baseline">
            <Item name="time" label={<LabelComponent text="When is the event" />} rules={commonRules}>
              <DatePicker
                format="MMMM Do YYYY, h:mm a"
                showTime
                disabledDate={current => current < (moment().toDate().getTime() / 1000 - (24 * 60 * 60)) * 1000}
              />
            </Item>
          </Space>

          {/*Remind before*/}
          <Space style={{ display: "block", marginBottom: 8, marginTop: 20 }} align="baseline">
            <Item name="notify_time" label={<LabelComponent text="Remind before*" />} rules={[{ required: true, message: "Notify Time required" }]}>
              <Select placeholder="Select..." alignTextLeft>
                {ADD_REMINDERS_TYPES && ADD_REMINDERS_TYPES.map(type => (
                  <Option value={type.value}>
                    {type.label}
                  </Option>
                ))}
              </Select>
            </Item>
          </Space>

          {/*Reminder duration*/}
          <Space style={{ display: "block", marginBottom: 8, marginTop: 20 }} align="baseline">
            <Item name="event_duration" label={<LabelComponent text="Reminder Duration*" />} rules={[{ required: true, message: "Reminder Duration required" }]}>
              <Select placeholder="Select..." alignTextLeft>
                {DURATION_OPTIONS && DURATION_OPTIONS.map(type => (
                  <Option value={type.value}>
                    {type.label}
                  </Option>
                ))}
              </Select>
            </Item>
          </Space>

          <Item>
            <Button
              style={{
                background: "#5A5FF2",
                float: "right",
                borderColor: "white",
              }}
              type="primary"
              shape="round"
              size="large"
              loading={creatingReminder}
              htmlType="submit"
            >
              {isEditClicked ? "Update Reminder" : "Add Reminder"}
            </Button>
          </Item>
        </Form>
      </FormWrap>
    </Wrap>
  )
}

export default CRMV2Container(AddReminders)
