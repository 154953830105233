import React from "react"
import styled from "styled-components"
import flatfee from "dumbComponents/NewLandingPage/images/flatfee.png"
import colors from "@colors"
import Checkmark from "images/Graphics/RadiusEdgeAssets/Checkmark_1.svg"
import Button from "@ui/Button"
import { HIGHLIGHT_FONT, ATC } from "@fonts"

const Wrap = styled.div`
  margin: auto;
  padding: 60px;
  display: flex;
  justify-content: space-around;
  gap: 22px;

  @media(max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  @media(max-width: 425px) {
    padding: 10px;
  }
`

const ImageWrap = styled.div`
  position: relative;

  > img {
    width: 100%;
  }

  > div {
    position: absolute;
    bottom: 0;
    padding: 15px 15px 0 0;
    background-color: ${colors.white};

    > p {
      font-size: 16px;
      margin: 0;
      font-family: ${ATC};
    }

    > h1 {
      font-size: 83px;
      margin: 0;
      color: ${colors.new_primary};
      font-family: ${ATC};

      @media(max-width: 425px) {
        font-size: 82px;
      }
    }
  }
`

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 180px;
  gap: 25px;
  align-items: center;

  @media(max-width: 768px) {
    max-width: unset;
  }

  > a {
    color: ${colors.new_primary};
    font-family: ${ATC};
  }
  
  @media(max-width: 1099px) {
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }
`

const ContentWrap = styled.div`
  > div {
    > h1 {
      max-width: 633px;
      width: 100%;
      font-size: 42px;
      font-family: ${HIGHLIGHT_FONT};

      @media(max-width: 768px) {
        max-width: 645px;
        font-size: 36px;
      }

      @media(max-width: 425px) {
        font-size: 32px;
      }
    }

    > p {
      font-size: 22px;
      font-family: ${ATC};
      font-weight: 200;

      @media(max-width: 768px) {
        margin-top: 30px;
        font-size: 20px;
      }

      @media(max-width: 425px) {
        font-size: 18px;
      }
    }
  }
`

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  > div {
    align-items: flex-start;
    gap: 10px;
    display: flex;

    > div {
      > p {
        margin: 0;
        font-size: 22px;
        font-family: ${ATC};
        font-weight: 200;
        color: #303030;

        @media(max-width: 768px) {
          font-size: 20px;
        }

        @media(max-width: 425px) {
          font-size: 18px;
        }
      }
      > small {
        font-size: 16px;
        color: #303030;
        font-family: ${ATC};
        font-weight: 200;
      }
    }
  }
`

const FlatFee = ({
  toggleApplyModal,
}) => {
  return (
    <Wrap>
      <ImageWrap>
        <img src={flatfee} alt="Radius Flat Fee" />
        <div>
          <p>
            Flat fee starting at
          </p>
          <h1>
            $800
          </h1>
        </div>
      </ImageWrap>
      <ContentWrap>
        <div>
          <h1>
            Zero split. Keep your commission.
          </h1>
          <p>
            Transparent and fair pricing:
          </p>
          <TextWrap>
            <div>
              <img src={Checkmark} alt="Radius Checkmark" />
              <div>
                <p>
                  Transaction flat fee starting at $800
                </p>
                <small>
                  (Including transaction coordination services)
                </small>
              </div>
            </div>
            <div>
              <img src={Checkmark} alt="Radius Checkmark" />
              <div>
                <p>
                  Tailored subscription plans to help grow each stage of your business
                </p>
              </div>
            </div>
          </TextWrap>
        </div>
        <ButtonWrap>
          <Button
            style={{
              width: "180px",
              padding: 10,
              background: `${colors.new_primary}`,
              border: "none",
              borderRadius: "0px",
              marginTop: "30px",
            }}
            onClick={() => {
              toggleApplyModal({
                bool: true,
                accountType: "owner",
                headerText: "Nice to meet you!",
                subHeaderText: "Tell us a little bit about yourself — we’d love to provide you with more information about the Radius Brokerage Platform.",
              })
            }}
          >
            Grow with Radius
          </Button>
          <a href="https://meetings.hubspot.com/mari23/radius-agent-realty-product-all-pages?embed=true">
            Book a meeting
          </a>
        </ButtonWrap>
      </ContentWrap>
    </Wrap>
  )
}

export default FlatFee
