import React, { useEffect, useState } from "react"
import queryString from "query-string"
import OfferV2 from "container/OffersV2"
import root from "window-or-global"
import { withRouter } from "react-router-dom"
import cookie from "services/CookieStorage"
import CloseIcon from "images/home-v7/modal-close-icon.svg"
import {
  PROPERTY_TYPE,
  getState,
} from "dumbComponents/OffersV2/consts"
import Modal from "@ui/Modal"
import { DropdownIcon } from "dumbComponents/OffersV2/icons"
import {
  Wrap,
  SectionCircle,
  SectionLabelWrap,
  SectionText,
  ModalTitle,
  InputWrap,
  CTAWrap,
} from "./style"
import {
  ProTip,
  MLSTabs,
} from "./components"
import {
  SelectField,
} from "../InputFields"
import {
  WhiteButton,
  BlueButton,
} from "../../commonStyles"


const customSelectStyles = {
  // width: "497px",
  height: "48px",
  overflow: "hidden",
}

const MLSModal = ({
  toClose,
  createOfferFromPropertyType,
  mlsOrPropertyData,
  setMLSData,
  history,
  stateForOffer,
}) => {
  const [data, setData] = useState({})
  const [propertyType, setPropertyType] = useState("residential_1_4")

  useEffect(() => {
    const { search } = root.location
    const parsedQuery = queryString.parse(search)
    const {
      transaction_id,
      req_id,
      client_id,
      client_source,
      state,
    } = parsedQuery || {}

    const parsedData = {
      ...parsedQuery,
    }

    setData(parsedData)
  }, [])

  const handleChange = (val) => {
    setPropertyType(val)
    setMLSData({
      ...mlsOrPropertyData,
      purchaseType: val,
    })
  }

  const handleSubmit = () => {
    const payload = {
      agent_id: parseInt(cookie.load("agentId"), 10),
      draft: "N",
      transation_id: data.transactionId,
      type_of_transaction: propertyType,
      ...data,
    }

    let propertyPayload = null

    console.log("mlsOrPropertyData", mlsOrPropertyData)

    if (mlsOrPropertyData && Object.keys(mlsOrPropertyData).length > 0) {
      propertyPayload = {
        draft: 1,
        current_offer_step: "property_information_mobile",
      }
      const {
        id,
        Id,
        typedMLSId,
        address,
        City,
        StateOrProvince,
        Zipcode,
        ListAgentFullName,
        ListAgentMlsId,
        ListAgentEmail,
        ListAgentPreferredPhone,
        ListOfficeName,
        ListOfficeMlsId,
      } = mlsOrPropertyData || {}
      if (typedMLSId) {
        propertyPayload.mls_number = typedMLSId || id || Id
      }

      if (address) {
        propertyPayload.street = address
      }

      if (City) {
        propertyPayload.city = City
      }

      if (StateOrProvince) {
        propertyPayload.state = getState(StateOrProvince)
      }

      if (Zipcode) {
        propertyPayload.zipcode = Zipcode
      }

      if (ListAgentFullName) {
        const listingAgent = {
          is_primary: 1,
          agent_id: null,
          is_radius_user: null,
          name: ListAgentFullName || "",
          email: ListAgentEmail || "",
          agent_license_number: mlsOrPropertyData?.Member?.length>0 ? mlsOrPropertyData?.Member[0]?.MemberStateLicense : ListAgentMlsId,
          landline: "",
          phone: ListAgentPreferredPhone || "",
          brokerage: ListOfficeName || "",
          brokerage_license_number: "",
          brokerage_address: "",
          apt: null,
          city: "",
          state: "",
          zipcode: "",
        }
        propertyPayload.agents = [listingAgent]
      }
    }

    createOfferFromPropertyType({
      payload,
      history,
      propertyPayload,
    })
  }

  return (
    <Modal
      toClose={() => {
        handleSubmit()
        toClose()
      }}
      width="533px"
      show
      modalVersion={3}
      // closeIcon={CloseIcon}
      overlayStyles={{
        alignItems: "flex-start",
        borderRadius: "9px",
        zIndex: 1,
      }}
      modalStyles={{
        padding: "40px 25px 40px 25px",
      }}
      dialogStyles={{
        borderRadius: "8px",
      }}
    >
      <Wrap>
        <ModalTitle>
          <p>Lets get some basic details about your offer.</p>
        </ModalTitle>
        <SectionLabelWrap>
          <SectionCircle>
            <p>1</p>
          </SectionCircle>
          <SectionText>
            <p>Select a Purchase type</p>
          </SectionText>
        </SectionLabelWrap>
        <InputWrap>
          <SelectField
            options={PROPERTY_TYPE}
            style={customSelectStyles}
            arrowIcon={<DropdownIcon />}
            onChange={handleChange}
            defaultValue="residential_1_4"
          />
        </InputWrap>
        <ProTip />
        <SectionLabelWrap>
          <SectionCircle>
            <p>2</p>
          </SectionCircle>
          <SectionText>
            <p>Search using MLS ID or Property address</p>
          </SectionText>
        </SectionLabelWrap>
        <MLSTabs />
        <CTAWrap>
          <WhiteButton
            onClick={toClose}
          >
            <p>Close</p>
          </WhiteButton>
          <BlueButton
            onClick={handleSubmit}
          >
            <p>Next</p>
          </BlueButton>
        </CTAWrap>
      </Wrap>
    </Modal>
  )
}

export default withRouter(OfferV2(MLSModal))
