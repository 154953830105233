import React from "react"
import styled from "styled-components"
import colors from "@colors"
import { ATC } from "@fonts"
import CloseIcon from "images/Close_7.svg"
import CircularImage from "dumbComponents/common/CircularImage"
import {
  FormTitle,
  TagsSpan,
  TagButton,
} from "../common"

const Wrap = styled.div``

const InviteWrap = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  > span {
    font-weight: 100;
    font-size: 12px;
  }
`

const InviteCTA = styled.button`
  height: 41px;
  width: 153px;
  border-radius: 4px;

  background: white;
  cursor: pointer;
  border: 1px solid rgba(229, 229, 229, 1);

  p {
    text-align: center;
    font-family: ${ATC};
    font-size: 14px;
    font-weight: 300;
    margin: 0;
    line-height: 16px;
    letter-spacing: 0px;
    color: ${colors.new_primary};
  }
`

const Name = styled.p`
  font-family: ${ATC};
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0;
`

const UserCard = styled.div``

const InviteSpeakers = ({
  speakers,
  handleRemoveTag,
  toggleInviteSpeakersModal,
}) => (
  <Wrap>
    <FormTitle>
      <p>Invite Speakers</p>
    </FormTitle>
    <TagsSpan>
      {speakers && (
        speakers.map(speaker => (
          <UserCard>
            <TagButton
              type="button"
              onClick={(e) => {
                e.stopPropagation()
                handleRemoveTag(speaker)
              }}
            >
              <CircularImage
                name={`${speaker.firstname} ${speaker.lastname}`}
                size={35}
                image={speaker.agent_image || ""}
              />
              <Name>
                {`${speaker.firstname} ${speaker.lastname}`}
              </Name>
              <img src={CloseIcon} alt="close" />
            </TagButton>
          </UserCard>
        ))
      )}
    </TagsSpan>
    <InviteWrap>
      <InviteCTA
        onClick={() => toggleInviteSpeakersModal(true)}
        type="button"
      >
        <p>+ Invite Speakers</p>
      </InviteCTA>
      <span>Note: you can invite upto 2 speakers only</span>
    </InviteWrap>
  </Wrap>
)

export default InviteSpeakers
