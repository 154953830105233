import React from 'react'
import styled from 'styled-components'
import { ATC, INTER, MONTSERRAT } from '@fonts'
import colors from '@colors'
import { Radio, Tabs } from '@ui/antd'

const LabelText = styled.h2`
  font-family: ${INTER};
  font-style: normal;
  font-weight: ${props => props.fontWeight || 200};
  font-size: ${props => props.fontSize || "16px"};
  line-height: 22px;
  margin: 0px;
  color: ${colors.black};
`
const YesNoLabel = styled.p`
  color: var(--Neutral-900, #111827);

  /* Label/Medium/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
`;

const Required = styled.span`
  color: #E34C4C
`

export const LabelComponent = ({ text, required, fontSize, fontWeight, newStyling = false, labelStyle }) => (
  <>
    {newStyling ? <YesNoLabel fontSize={fontSize} fontWeight={fontWeight} labelStyle={labelStyle}>{text}</YesNoLabel> : <LabelText fontSize={fontSize} fontWeight={fontWeight} labelStyle={labelStyle}>{text}</LabelText>}
    {required && (<Required>*</Required>)}
  </>
)

export const RadioButton = styled(Radio)`
  width: max-content;
  span {
    font-size: 16px;
    color: ${colors.black};
    font-family: ${ATC};
    font-weight: 300;
  }
`

export const TransAndBedWrap = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
`

export const TransactionTypeWrap = styled.div`
`

export const H2 = styled.h2`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: ${colors.black};
  margin: 0;
`

export const H1 = styled.h1`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.black};
  padding: 10px 0px 12px 15px;
  margin: 0px;
`
export const Wrap = styled.div`
  display: flex;
  background: ${props => props.background || "#F9F9F7"};
  box-shadow: 0px 1px 4px 2px rgb(204 204 204 / 25%);
  border-radius: 11px;
  flex-direction: column;
  height: max-content;
  padding: 10px 0px;
  margin: ${props => props.margin || "25px 0px"};

  ${props => props.noBoxShadow && `
    box-shadow: unset;
  `}
`

export const SearchCriteriaHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  h2 {
    font-family: ${INTER};
    font-weight: 600;
    font-size: 14px;
    margin: 0px;
    color: ${colors.new_grey};
  }
`

export const LeadSourceWrap = styled.div`
  width: ${props => props.width || '642px'};
  padding: ${props => props.padding || '12px'};
  border-radius: 8px;
  border: 1px solid ${colors.new_primary};
  // background: ${colors.new_colorNeutral100};
  margin-bottom: 28px;

  .ant-space-item {
    width: 100%;
  }
`

export const StyledSearchCriteriaHeading = styled.p`
  color: #303030;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.5px;
`

export const NewLightButton = styled.button`
display: flex;
padding: 10px 16px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 28px;
border: 1px solid var(--Neutral-200, #E5E7EB);
background: var(--Generic-White, #FFF);
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
color: var(--Global-Mapping-Gray-900, #232338);
text-align: center;

font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px;
position: absolute;
top: 200px;
right: 1px;
`

export const StyledLabel = styled.label`
  color: ${colors.new_grey};
  font-family: ${INTER};
  font-size: 14px;
  font-weight: 400;
  text-wrap: nowrap;
`

export const AgentName = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: ${props => props.fontSize || '28px'};
  line-height: 32px;
  color: ${props => props.fontColor || colors.dreamWhite};
  max-width: ${props => props.width || 'max-content'};
  height: ${props => props.height || '32px'};
  margin-right: 10px;
`

export const HeaderWrap = styled.div`
  display: flex;
  color: ${props => props.fontColor || colors.dreamWhite};
  padding: 20px;
  flex-direction: column;
  background: ${props => props.background || colors.moonGrey};
  background-repeat: no-repeat;
  background-size: cover;
  height: max-content;
  background-position-x: center !important;
  background-position-y: bottom !important;
  gap: 20px;

  ${props => props.image && `
    background: url(${props.image});
  `}
`

export const AgentInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PriceWrap = styled.div`
  h1 {
    margin: 0;
    font-family: ${ATC};
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    color: ${props => props.fontColor || colors.dreamWhite};
  }
`

export const TabsWrap = styled.div`
  // padding: 24px;
  background: ${colors.new_lightBackground};
`

export const AgentInfo = styled.div`
  margin-top: 20px;
`

export const AgentWrap = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

export const CloseWrap = styled.div`
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    color: ${props => props.fontColor || colors.dreamWhite};
  }
`

export const Subtext = styled.h3`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 100;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: ${props => props.fontColor || colors.dreamWhite};
  margin-top: 5px;
`

export const Separator = styled.h2`
  color: #8c8c8c;
  font-weight: 100;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
`

export const ListSubtextHeader = styled.div`
  display: flex;
  // margin-bottom: 10px;
  justify-content: center;
  align-items: baseline;
  gap: 7px;
`

export const LeadInfo = styled(Tabs)`
  .ant-tabs-tab-btn {
    font-family: ${MONTSERRAT} !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: ${colors.new_primary} !important;
    letter-spacing: 2px !important;
    text-transform: uppercase !important;
  }

  .ant-tabs-ink-bar {
    background: ${colors.new_primary} !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 14px !important;
  }

  .ant-tabs-nav::before {
    border-bottom: 0;
  }
`
export const Title = styled.h1`
  font-weight: 500;
  font-size: ${props => `${props.fontSize}px`};
  color: #303030;
${props => props.sub && `
  font-size: 15px;
`}
  margin: 0px;
`

export const AddButton = styled.button`
  display: flex;
  cursor: pointer;
  border: none;
  background: none;
`

export const Icon = styled.img`
  margin-left: 8px;
  position: relative;
  top: 3px;
`

export const NotesHeaderWrap = styled.div`
  margin: 10px 15px 12px 15px;
  padding-bottom: 20px;
  &:not(:last-child) {
    border-bottom: 1px solid #D3DAE8;
  }
`

export const NotesWrap = styled.div`
  display: flex;
  position: relative;
  gap: ${props => `${props.gap}px`};

  &:not(:first-child) {
    border-top: 1px solid #E5E5E5;
    padding-top: 15px;
  }
`

export const AgentNotesInfo = styled.div`
  width: ${props => props.width};
  small {
    color: ${colors.suvaGray};
    font-size: 14px;
  }

  p {
    width: 483px;
    font-family: ${ATC};
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 22px;
    color: ${colors.black};
    margin-top: 10px;
    margin: 0px;
  }
`

export const AgentTitle = styled.h1`
  margin: 0px;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.black};
`

export const AgentPic = styled.div`
  img {
    height: 60px;
    width: 60px;
    border-radius: 60px;
  }
`

export const FormHeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.silentWhite};
`

export const StatusWrap = styled.div`
  display: flex;
  align-items: flex-end;
  padding-left: 10px;
  flex-direction: column;
  h4 {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  color: #849DF8;
    margin: 10px 0 0;
  }
`

export const SelectWrap = styled.div`
  display: flex;
  align-items: center;
  background: #FFFFFF;
  padding-left: 10px;
`

export const StatusButton = styled.div`
  width: 12.23px;
  height: 12.23px;
background: #B3E4C7;
${props => props.bg && `
  background: ${props.bg}
`}
  border-radius: 50%;
`

export const IN_CONTRACT = "In Contract"
export const CLOSED = "Closed"
export const CONTRACT = "Contract"
export const ARCHIVE = "Archive"
export const LISTING = "Listing"
export const SELLER = ["seller", "Seller"]
export const BUYER = ["buyer", "Buyer"]
export const SHOWING_TOURS = "Showings/Tours"
export const SENDING_RECEIVING_OFFERS = "Sending/Receiving Offers"
export const OFFER = "Offer"
export const INCOMPLETE = "incomplete"
export const CLOSED_STATUS = "closed"
