import React from "react"
import { reduxForm, Field } from "redux-form"
import styled from "styled-components"
import colors from "@colors"
import { get } from "lodash"
import { authCheck } from "services/auth"
import US_STATES from "shared/US_STATES.json"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import {
  Input,
  PhoneInput,
  Select,
} from "@ui/Form"
import {
  required,
  email,
  normalizePhone,
  minLength,
} from "services/formUtils"
import Button from "@ui/Button"
import CookiesStorage from "services/CookieStorage"

const minLength10 = minLength(10)
const STATE_OPTIONS = Object.keys(US_STATES).map(key => ({ label: US_STATES[key], value: US_STATES[key] }))

const FormWrap = styled.form``

const Footer = styled.div`
  display: flex;
  padding: 10px 0px;
  justify-content: flex-end;

  button {
    height: 48px;
    width: 327px;
    padding: 16px;
    border: none;
    cursor: pointer;
    background: #5A5FF2;
    color: ${colors.white};

    ${props => props.inPage && `
      color: ${colors.white};
      margin: 0 auto;
      background: ${colors.darkPurple};
    `}


    &:disabled {
      cursor: not-allowed;
      color: #fcfcfc47;
      background: #8080807a;
    }

    transition: background 0.3s linear;
  }
`

const InputWrap = styled.div`
  margin-bottom: 20px;
    label {
     input {
      border: 1px solid #96A3BD;
    }
    > span {
      font-size: 16px;
      color: ${colors.black};
      font-weight: 700;
      background: none;
      margin-bottom: 10px;

      ${props => props.inPage && `
        color: ${colors.black};
        `}
    }
    // & ~ span {
    //   position: absolute;
    //   bottom: -20px;
    //   left: 0px;
    // }
  }

  > div {
    /* For Location */
    > label {
      font-size: 16px;
      color: ${colors.black};
      font-weight: 700;
      background: none;
      margin-bottom: 10px;

      ${props => props.inPage && `
        color: ${colors.black};
        `}
    }
    > div {
      border: 1px solid #96A3BD;
      border-radius: 0px;

      div[class$="-menu"] {
        background: ${colors.white};
      }
      input {
        border: none;
        background: transparent;
        & ~ span {
          border: none;
        }
      }

      > div {
        background: none;
        border-color: transparent;
      }
    }
  }
  /* For Location */
  .pac-target-input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background: white !important;
  }

  input {
    background: white !important;
  }
  /* For Location */

`

const FormDiv = styled.div`
  padding: 10px;
  z-index: 1000;
  height: 0px;
  opacity: 0;
  width: 375px;
  padding: 0px 24px;
  position: fixed;
  background: ${colors.white};
  right: 20px;
  bottom: 80px;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
  text-align: left;

  @media(max-width: 375px) {
    right: 0px;
  }


  ${props => props.isOpenForm && `
    opacity: 1;
    height: 515px;
    padding: 24px;
  `}

  ${props => !props.isOpenForm && `
    height: 0px;
  `}

  ${props => props.inPage && `
    position: static;
    background: ${colors.white};
    height: max-content;
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;

    @media(max-width: 767px) {
      width: 85vw;
      max-width: 496px;
    }

    @media(min-width: 768px) {
      width: 496px;
    }
  `}

  transition: height 0.5s;
`

export const SaveButton = ({ children, isShowLoader, ...props }) => (
  <Button {...props}>
    {isShowLoader
      ? <ThreeDotLoader color="#FFF" />
      : children
    }
  </Button>
)

const ErrorText = styled.span`
  color: red;
  font-size: 12px;
  position: relative;
  bottom: 12px;
`

const HeaderText = styled.h1`
  font-family: "Bagnard";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: ${colors.black};
  max-width: 250px;
  margin: 20px auto 20px auto;
`
const HeadingText = styled.h1`
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  color: ${colors.black};
  max-width: 250px;
  margin: 0px 0px 20px 0px;
`
const ThankYouUI = styled.div`
    font-family: Butler;
    font-size: 30px;
    line-height: 37px;
    letter-spacing: 1.5%;
    padding: 165px 30px;
    text-align: center
    p{
    }
`

class RealtyLPForm extends React.Component {
  componentDidMount() {
    const {
      initialize,
      user,
      isPlugIn,
    } = this.props
    const isLogged = authCheck()

    if (!isPlugIn && isLogged && user && Object.keys(user).length > 0) {
      const fullName = `${user.firstname} ${user.lastname || ""}`
      initialize({
        name: fullName || "",
        email: user.email || user.primary_email || "",
        phone: user.phone && normalizePhone(user.phone) || "",
      })
    }
  }

  handleAddressChange = (address) => {
    const {
      change,
      setCurrentLocationForRealty,
    } = this.props
    change("city", address.value)
    change("state", address.value)
    setCurrentLocationForRealty({
      currentCity: address.value || "",
      currentState: address.value || "",
    })
  }

  render() {
    const {
      handleSubmit,
      isOpenForm,
      formError,
      inPage,
      scheduleACallResponse,
    } = this.props

    const {
      isFetching,
    } = scheduleACallResponse || {}

    return (
      <FormDiv isOpenForm={isOpenForm} inPage={inPage}>
        {isFetching ? (
          <ThankYouUI>
            <p>Thank you for your submission. We’ll be in touch shortly!</p>
            <>
              {(() => {
                setTimeout(() => {
                  CookiesStorage.save("interestViaPopUp", true)
                }, 1000)
              })()}
            </>
          </ThankYouUI>
        ) : (
          <React.Fragment>
            {inPage && (
              <HeaderText>
                Join a growing team of Luxury Agents
              </HeaderText>
            )}
            <HeadingText>
              Request More Info
            </HeadingText>
            <FormWrap p="0px 25px 25px 25px" onSubmit={handleSubmit}>
              <InputWrap inPage={inPage}>
                <Field
                  name="name"
                  component={Input}
                  label="Name *"
                  placeholder="Name"
                  validate={[required]}
                />
              </InputWrap>
              <InputWrap inPage={inPage}>
                <Field
                  component={Select}
                  validate={[required]}
                  options={STATE_OPTIONS}
                  name="state"
                  label="State"
                  placeholder="State"
                  onChange={this.handleAddressChange}
                  labelStyles={{
                    margin: "0px",
                  }}
                />
              </InputWrap>
              {formError && (
                <ErrorText>Invalid Location</ErrorText>
              )}
              <InputWrap inPage={inPage}>
                <Field
                  name="email"
                  component={Input}
                  label="Email *"
                  placeholder="Email"
                  validate={[required, email]}
                />
              </InputWrap>
              <InputWrap inPage={inPage}>
                <Field
                  name="phone"
                  component={PhoneInput}
                  isMaterial={false}
                  isShowAreaCode={false}
                  placeholder="Phone"
                  label="Phone *"
                  normalize={normalizePhone}
                  validate={[required, minLength10]}
                />
              </InputWrap>
              <Footer inPage={inPage} d="flex" jc="flex-end">
                <button
                  width="100px"
                  type="submit"
                >
                  {inPage ? "Apply for Royale" : "Send"}
                </button>
              </Footer>
            </FormWrap>
          </React.Fragment>
        )}

      </FormDiv>
    )
  }
}

export default reduxForm({
  form: "REALTY_LP_FORM",
})(RealtyLPForm)
