import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { get } from "lodash"
import {
  fetchClientProfileAction,
  updateClientProfileAction,
  fetchAndUpdateClientProfileAction,
  fetchAndUpdateSalesInClientAction,
  updateCurrentSalesStageAction,
  updateSalesChecklistAction,
  setReminderAction,
  archiveReferralAction,
  updateChecklistForClientAction,
  updateArchivedLeadAction,
  addISAClientAction,
  openISAAddClientModalAction,
} from "container/Client/actions"
import {
  getTeamDetailsAction,
} from "container/Realty/actions"
import {
  fetchAllLeadIntegrationSourcesAction,
  openLeadIntegrationModalAction,
  getUniqueEmailAction,
} from "container/Settings/actions"
import {
  profileSubmitReferralAction,
} from "container/StandAlone/SubmitReferral/actions"
import {
  pullRedirectAction, updateReferralAgreementAction, fetchUserInfoAction,
  toggleSignAgreementModalAction,
  fakeSignDocusignAction,
} from "container/App/actions"
import {
  getIsUserATeamOwner,
  getIsUserATeamMember,
  getIsUserATeamAdmin,
  getIsSubscribedToAnyISAPlan,
  getIsUserABroker,
} from "container/App/selector"
import { getMappedInbounds, getCategorizedSubscriptions } from "./selector"
import * as ReferralsActions from "./actions"

function mapStateToProps(state) {
  const {
    referral,
    client,
    settings,
    app,
  } = state
  // getMappedOutbounds() will map outbounds refs with filters
  // We don't need mapping as of now
  //const outboundReferrals = getMappedOutbounds(state)
  const inboundReferrals = getMappedInbounds(state)
  
  const isUserObjectLoaded = !!get(app, "user", false)
  const { isShowSignAgreementModal, user } = app
  const {
    isReferralCardBusy,
    isFetchingClient,
    isChecklistUpdating,
    isUpdatingArchivedLead,
    isISAAddClientModalOpened,
    isAddingISAClient,
  } = client
  const isBroker = getIsUserABroker(state)

  const { isLeadIntegrationModalOpen } = settings
  const isUserATeamOwner = getIsUserATeamOwner(state)
  const isUserATeamMember = getIsUserATeamMember(state)
  const isUserATeamAdmin = getIsUserATeamAdmin(state)
  const isSubscribedToAnyISAPlan = getIsSubscribedToAnyISAPlan(state)

  const {
    isFetchingReferrals,
    filterSkeleton,
    selectedFilters,
    isFilterPanelOpen,
    appliedFilters,
    isStarred,
    isChecklistModalOpen,
    isFetchingFilterSkeleton,
    isShowAddReminderModal,
    outboundReferrals,
    isFetchingShowMore,
    isFetchingMetric,
    referralMetric,
    isStarringReferral,
    isLoadingLeadAgreement,
    isShowArchiveModal,
    isArchivedLeadModalOpen,
    archivedReferrals,
    isFetchingArchivedReferrals,
    isPaymentSettingsModalOpen,
    referral: prospectReferralSubmitted,
    isSubscribing,
    isUnsubscribing,
    isFetchingInvoices,
    isFetchingPlan,
    isFetchingSubscription,
    subscriptions,
    isFetchingProspects,
    prospects,
    outboundProspects,
    plans,
    showProspectsModal,
    lastAcceptedReferral,
    isShowTeamLogoModal,
    teamInfoResponse,
    uploadTeamImageResponse,
    exportToExcelResponse,
    assignAgentKeys,
    teamMembers,
    assigningAgent,
    pendingClientInboundResponse,
    fetchAgentSearchResponse,
    currentExpertise,
    currentLanguages,
    currentCompanies,
    currentAgentName,
    currentWcrFilterActive,
    currentWCRPMNActive,
    currentRPACActive,
    addressTextBoxValue,
    currentCity,
    currentCountry,
    currentState,
    isModalForMarketplaceOpen,
    subscriptionStatus,
    subscriptionStatusObject,
    isSubscriptionExist,
    subscriptionEndDate,
    fetchSubscriptionStatusResponse
  } = referral

  return {
    isFetchingReferrals,
    isStarred,
    isUserATeamOwner,
    isShowAddReminderModal,
    appliedFilters,
    isFilterPanelOpen,
    inboundReferrals,
    outboundReferrals,
    filterSkeleton,
    selectedFilters,
    isChecklistModalOpen,
    isFetchingFilterSkeleton,
    isReferralCardBusy,
    isFetchingShowMore,
    isFetchingMetric,
    referralMetric,
    isStarringReferral,
    isLoadingLeadAgreement,
    isFetchingClient,
    isShowArchiveModal,
    prospectReferralSubmitted,
    isChecklistUpdating,
    isArchivedLeadModalOpen,
    isFetchingArchivedReferrals,
    isUpdatingArchivedLead,
    archivedReferrals,
    isPaymentSettingsModalOpen,
    isLeadIntegrationModalOpen,
    fetchAgentSearchResponse,
    isSubscribing,
    isUnsubscribing,
    isFetchingInvoices,
    isFetchingPlan,
    isFetchingSubscription,
    subscriptions,
    isFetchingProspects,
    prospects,
    isISAAddClientModalOpened,
    isAddingISAClient,
    plans,
    outboundProspects,
    showProspectsModal,
    lastAcceptedReferral,
    isUserATeamMember,
    isTeamMember: isUserATeamMember || isUserATeamAdmin || isUserATeamOwner,
    isUserATeamAdmin,
    isShowTeamLogoModal,
    teamInfoResponse,
    uploadTeamImageResponse,
    isSubscribedToAnyISAPlan,
    isUserObjectLoaded,
    exportToExcelResponse,
    categorizedSubscriptions: getCategorizedSubscriptions(state),
    isAppMounted: app.isAppMounted,
    assignAgentKeys,
    teamMembers,
    assigningAgent,
    pendingClientInboundResponse,
    currentExpertise,
    currentLanguages,
    currentCompanies,
    currentAgentName,
    currentWcrFilterActive,
    currentWCRPMNActive,
    currentRPACActive,
    addressTextBoxValue,
    currentCity,
    currentCountry,
    currentState,
    isModalForMarketplaceOpen,
    isShowSignAgreementModal,
    user,
    isBroker,
    subscriptionStatus,
    subscriptionStatusObject,
    isSubscriptionExist,
    subscriptionEndDate,
    fetchSubscriptionStatusResponse
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        fetchSignedAgreement: ReferralsActions.fetchSignedAgreementAction.request,
        fetchReferrals: ReferralsActions.fetchReferralsAction.request,
        fetchArchivedReferrals: ReferralsActions.fetchArchivedReferralsAction.request,
        fetchFilterSkeleton: ReferralsActions.fetchFilterSkeletonAction.request,
        fetchReferalMetric: ReferralsActions.fetchReferalMetricAction.request,
        fetchStarReferral: ReferralsActions.fetchStarReferralAction.request,
        changeInboundReferralStage: ReferralsActions.changeInboundReferralStageAction.request,
        changeInboundReferralOrder: ReferralsActions.changeInboundReferralOrderAction.request,
        signCRSAgreement: ReferralsActions.signCRSAgreementAction.request,
        subscribe: ReferralsActions.subscribeAction.request,
        unsubscribe: ReferralsActions.unsubscribeAction.request,
        fetchInvoices: ReferralsActions.fetchInvoicesAction.request,
        fetchSubscriptions: ReferralsActions.fetchSubscriptionsAction.request,
        fetchPlans: ReferralsActions.fetchPlansAction.request,
        fetchDefaultCard: ReferralsActions.fetchDefaultCardAction.request,
        updateCard: ReferralsActions.updateCardAction.request,
        fetchProspects: ReferralsActions.fetchProspectsAction.request,
        updateProspect: ReferralsActions.updateProspectAction.request,
        searchReferralByName: ReferralsActions.searchReferralByName,
        searchReferralTypeEvent: ReferralsActions.searchReferralTypeEventAction,
        selectFilter: ReferralsActions.selectFilter,
        closeFilterPanel: ReferralsActions.closeFilterPanel,
        openFilterPanel: ReferralsActions.openFilterPanel,
        fetchFilteredReferrals: ReferralsActions.fetchFilteredReferralsAction,
        clearFilters: ReferralsActions.clearFilters,
        removeFilter: ReferralsActions.removeFilter,
        getStarredReferrals: ReferralsActions.getStarredReferrals,
        openChecklistModal: ReferralsActions.openChecklistModal,
        closeChecklistModal: ReferralsActions.closeChecklistModal,
        toggleArchiveModal: ReferralsActions.toggleArchiveModalAction,
        openAddReminderModal: ReferralsActions.openAddReminderModalAction,
        closeAddReminderModal: ReferralsActions.closeAddReminderModalAction,
        getMoreReferrals: ReferralsActions.getMoreReferrals,
        openArchiveLeadModal: ReferralsActions.openArchiveLeadModalAction,
        closedArchiveLeadModal: ReferralsActions.closeArchiveLeadModalAction,
        watchArchiveReferralType: ReferralsActions.watchArchiveReferralTypeAction,
        openPaymentSettings: ReferralsActions.openPaymentSettingsAction,
        closePaymentSettings: ReferralsActions.closePaymentSettingsAction,
        updateProspectModal: ReferralsActions.updateProspectModalAction,
        updateSubscriptionStatus: ReferralsActions.updateSubscriptionStatusAction,
        exportToExcel: ReferralsActions.exportToExcelAction.request,
        fetchAndUpdateClientProfile: fetchAndUpdateClientProfileAction.request,
        fetchAndUpdateSalesInClient: fetchAndUpdateSalesInClientAction.request,
        fetchClientProfile: fetchClientProfileAction.request,
        updateClientProfile: updateClientProfileAction.request,
        addISAClient: addISAClientAction.request,
        setReminder: setReminderAction.request,
        updateArchivedLead: updateArchivedLeadAction.request,
        fetchAllLeadIntegrationSources: fetchAllLeadIntegrationSourcesAction.request,
        getUniqueEmail: getUniqueEmailAction.request,
        updateCurrentSalesStage: updateCurrentSalesStageAction,
        updateSalesChecklist: updateSalesChecklistAction,
        updateChecklistForClient: updateChecklistForClientAction,
        archiveReferral: archiveReferralAction,
        openLeadIntegrationModal: openLeadIntegrationModalAction,
        openISAAddClientModal: openISAAddClientModalAction,
        pullRedirect: pullRedirectAction.call,
        updateReferralAgreement: updateReferralAgreementAction.request,
        clearCurrentProspects: ReferralsActions.clearCurrentProspectsAction.call,
        checkPartnerSubscriptionInterest: ReferralsActions.checkPartnerSubscriptionInterestsAction.call,
        getTeamMembers: ReferralsActions.getTeamMembersAction.request,
        toggleAssignModal: ReferralsActions.toggleAssignModalAction.call,
        getTeamInfo: ReferralsActions.getTeamInfoAction.request,
        assignAgent: ReferralsActions.assignAgentAction.request,
        toggleAddTeamLogo: ReferralsActions.toggleAddTeamLogoAction.call,
        updateTeamImage: ReferralsActions.updateTeamImageAction.request,
        getInboundPendingClientDetails: ReferralsActions.getInboundPendingReferralDetailsAction.request,
        fetchUserInfo: fetchUserInfoAction.request,
        acceptOrRejectLead: ReferralsActions.acceptOrRejectLeadAction.request,
        fetchAgentSearch: ReferralsActions.fetchAgentSearchAction.request,
        setCurrentAgentName: ReferralsActions.setCurrentAgentNameAction.call,
        setCurrentLocation: ReferralsActions.setCurrentLocationAction.call,
        setCurrentExpertise: ReferralsActions.setCurrentExpertiseFilterAction.call,
        setCurrentLanguage: ReferralsActions.setCurrentLanguageFilterAction.call,
        setCurrentCompanies: ReferralsActions.setCurrentCompaniesFilterAction.call,
        setCurrentWCRFilter: ReferralsActions.setCurrentWCRFilterAction.call,
        setCurrentRPACFilter: ReferralsActions.setCurrentRPACFilterAction.call,
        setCurrentWCRPMNFilter: ReferralsActions.setCurrentWCRPMNFilterAction.call,
        toggleSendToMarketPlaceModal: ReferralsActions.toggleSendToMarketPlaceModalAction.call,
        handleUserConfirmation: ReferralsActions.handleUserConfirmationAction.call,
        findAnAgentViewed: ReferralsActions.findAnAgentViewedAction.request,
        profileSubmitReferral: profileSubmitReferralAction.call,
        toggleSignAgreementModal: toggleSignAgreementModalAction.call,
        fakeSignDocusign: fakeSignDocusignAction.request,
        getTeamDetails: getTeamDetailsAction.request,
        psuedoDocuSign: ReferralsActions.psuedoDocuSignAction.request,
        fetchSubscriptionStatus: ReferralsActions.fetchSubscriptionStatusAction.request,
        fetchSubscriptionStatusEOI: ReferralsActions.fetchSubscriptionStatusEOIAction.request,
        setSubscriptionStatus: ReferralsActions.setSubscriptionStatusAction.call,
        setSubcriptionStatusObject: ReferralsActions.setSubcriptionStatusObjectAction.call,
        setSubscriptionExist: ReferralsActions.setSubscriptionExistAction.call,
        setSubscriptionEndDate: ReferralsActions.setSubscriptionEndDateAction.call,
      },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
