import React, { useEffect, useState } from "react"
import OffersV2 from "container/OffersV2"
import TechAssets from "container/TechAssets"
import { Checkbox, Modal } from "antd"
import { ShimmerBox } from "shared/styles/animation"
import Loader from "@ui/Loader"
// import customToast from "dumbComponents/OffersV3/components/Toast/customToast"
import { AddButtonCTA } from "dumbComponents/OffersV3/components"
import { BlueButton } from "dumbComponents/OffersV3/commonStyles"
import { BlueButtonInverse } from "dumbComponents/ListingsV2/commonStyles"
import { EyeIcon } from "dumbComponents/OffersV3/icons"
import customToast from "../../Toast/customToast"
import LibraryModal from "./LibraryModal"
import EmptyState from "./EmptyState"
import { EyeIconEnvelope } from "dumbComponents/ListingsV2/icons"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import { DeleteOutlined } from "@ant-design/icons"
import {
  Wrap,
  HeaderWrap,
  Header,
  ButtonWrap,
  ShimmerWrap,
  CheckboxWrap,
  CreateButtonWrap,
} from "../styles"
import CheckboxListV2 from "../../CheckboxListV2/index"

const Envelopes = ({
  docList,
  updateRecommendedDocsObject,
  toggleCreateEnvelopeModal,
  getOfferDocumentsResponse,
  createSendeViewOffer,
  dealToView,
  getOfferDocuments,
  deleteCompletedOffer,
  deleteCompletedOfferResponse,
}) => {
  const [isDocusignOpen, setIsDocsignOpen] = useState(localStorage.getItem('isDocusignOpen'));
  const {
    isFetching: deletingEnvelope,
  } = deleteCompletedOfferResponse || {}
  useEffect(() => {
    getOfferDocuments({
      payload: {
        offerId: dealToView,
      },
    })
  },[dealToView])

  useEffect(() => {
    const handleStorageChange = () => {
      setIsDocsignOpen(localStorage.getItem('isDocusignOpen'));
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };

  }, []);

  useEffect(() => {
    if (isDocusignOpen == 2 || localStorage.getItem('isDocusignOpen') == 2) {
      customToast("Envelope updated, auto-refreshing in 6 seconds.")
      setTimeout(() => {
        customToast("Fetching updated envelopes")
        getOfferDocuments({
          payload: {
            offerId: dealToView,
          },
        })
      }, 6000)
      localStorage.setItem('isDocusignOpen', 1)
    }
  }, [isDocusignOpen])
  
  const { isFetching: fetchingDocs } = getOfferDocumentsResponse || {}
  const [showModal, setModal] = useState(false)

  const [deleteModal, setDeleteModal] = useState(false)

  const OptionList2 = []

  const mainList = [

    {
      label: "VIEW & FILL",
      image: <EyeIconEnvelope />,
      onClick: (id) => {
        customToast("Viewing document")
        const payload = {
          envelopeId: id,
        }
        createSendeViewOffer(
          payload,
        )
      },
      showDivide: false,
    },
  ]
  const activeDocList = docList && docList.filter(item => item.status === "active").map(item => item)

  const handleSelectedState = (e, eID) => {
    const checked = e.target.checked
    const currentDocs = [
      ...docList,
    ]

    currentDocs.forEach((item) => {
      if (item.id === eID) {
        item.isSelected = checked
      }
    })

    updateRecommendedDocsObject(currentDocs)
  }

  const selectAll = () => {
    const currentDocs = [
      ...activeDocList,
    ]
    currentDocs.forEach((item) => {
      const curr = item
      curr.isSelected = true
    })

    updateRecommendedDocsObject(currentDocs)
  }

  const handleEnvelopeDelete = () => {
    const payload = {
      envelopeIds: [],
    }
    console.log("doclist --> ", docList)
    const filtered = docList.filter(item => item.isSelected).map(item => item.envelope_id)

    payload.envelopeIds = [...filtered]
    console.log("payload delete", payload)
    deleteCompletedOffer({
      payload,
      callback: () => {
        getOfferDocuments({
          payload: {
            offerId: dealToView,
          },
        })
      },
    })
    setDeleteModal(false)
  }
  const deselectAll = () => {
    const currentDocs = [
      ...activeDocList,
    ]

    currentDocs.forEach((item) => {
      const curr = item
      curr.isSelected = false
    })

    updateRecommendedDocsObject(currentDocs)
  }

  const handleSelectAllToggle = (e) => {
    const checked = e.target.checked
    if (checked) {
      selectAll()
    } else {
      deselectAll()
    }
  }

  const checkSelected = () => {
    const isSelected = docList && docList.filter(item => item.isSelected).map(item => item)
    if (isSelected.length > 0) {
      return false
    }
    return true
  }
  return (

    <Wrap>

      <Modal
        title="Confirm Deletion"
        open={deleteModal}
        onOk={handleEnvelopeDelete}
        onCancel={() => setDeleteModal(false)}
        okText="Delete"
        okButtonProps={{
          style: {
            borderRadius: "33.5px",
            background: "#5A5FF2",
            width: "200px",
          },
        }}
        cancelButtonProps={{
          style: {
            borderRadius: "33.5px",
            border: "1px solid #5A6CF2",
          },
        }}
      >
        <p>You are about to delete the document. This action will remove all document from your envelope. Do you wish to proceed?</p>
      </Modal>
      <HeaderWrap>
        <Header>
          RECOMMENDED FORMS
        </Header>
        <AddButtonCTA name="Add from library" onClick={() => setModal(true)} />
      </HeaderWrap>
      {fetchingDocs ? (
        <ShimmerWrap>
          <ShimmerBox w="100%" h="100px" />
          <ShimmerBox w="100%" h="100px" />
          <ShimmerBox w="100%" h="100px" />
          <ShimmerBox w="100%" h="100px" />
        </ShimmerWrap>
      ) : (
        <>
          {activeDocList && activeDocList.length > 0 ? (
            <>
              <CheckboxWrap>
                <Checkbox onChange={handleSelectAllToggle}>Select all</Checkbox>
              </CheckboxWrap>
              <CheckboxListV2
                list={docList}
                onClick={() => { console.log("clicked") }}
                handleSelectedCheck={handleSelectedState}
                dropdownList={OptionList2}
                mainList={mainList}
                isActiveFilter
              />
              <CreateButtonWrap>
                <BlueButton disabled={checkSelected()} maxWidth onClick={() => toggleCreateEnvelopeModal(true)}>
                  <p>Create and send envelope</p>
                </BlueButton>
                <BlueButtonInverse
                  disabled={checkSelected()}
                  maxWidth
                  onClick={() => setDeleteModal(true)}
                >
                  {deletingEnvelope ? (
                    <ThreeDotLoader color="#5A5FF2" />
                  ) : (
                    <p>
                      <DeleteOutlined />
                      Delete
                    </p>
                  )}
                </BlueButtonInverse>
              </CreateButtonWrap>
            </>
          ) : (
            <EmptyState
              emptyHeader="No active documents yet"
              emptyDescription="Add documents from library"
            />
          )}
        </>
      )}
      {showModal && <LibraryModal showModal={showModal} setModal={setModal} />}
    </Wrap>
  )
}

export default OffersV2(TechAssets(Envelopes))
