import React, { useState } from "react"
import styled from "styled-components"
import { SwitchToggleV2 } from "@ui/Form"
import colors from "@colors"

const Wrap = styled.div``

const ToggleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    margin: 12px 0px 0px 0px;
  }
`

const SwitchPlans = ({
  isDisabled,
  savePreference,
  submitType,
}) => {
  const [changeToggle, setToggle] = useState(submitType === "assist")

  const handleToggle = () => {
    setToggle(!changeToggle)
    handleToggleSwitchSubscription()
  }

  const handleToggleSwitchSubscription = () => {
    if (submitType === null || submitType === "crm") {
      const payload = {
        submit_type: "assist",
      }
      savePreference({ payload })
    } else {
      const payload = {
        submit_type: "crm",
      }
      savePreference({ payload })
    }
  }

  return (
    <Wrap>
      {isDisabled && (
        <ToggleWrap>
          <p>
            Subscription Off
          </p>
          <SwitchToggleV2
            isSelected={changeToggle}
            onClick={handleToggle}
            bg={changeToggle ? "#169C00" : colors.new_primary}
            circleColor="#fff"
            xl
          />
          <p>
            On
          </p>
        </ToggleWrap>
      )}
    </Wrap>
  )
}

export default SwitchPlans
