import React from "react"
import { DownOutlined, PlusOutlined } from "@ant-design/icons"
import { Dropdown, Space } from "antd"
import cookie from "services/CookieStorage"
import TechAssetsContainer from "container/TechAssets"
import {
  DropdownWrap,
} from "./styles"
import {
  LISTING, LISTING_CONTRACT, LISTING_LEASE,
} from "../../const"

const AddOffer = ({
  isTransactionCreationForOffer,
  toggleTransactionCreationForOffer,
  setCreateTransactionalFlow,
  setEditingDealTeamMemberId,
  handleCreateFlow
}) => {
  const handleMenuClick = (e) => {
    const selectedKey = e.key
    const teamExists = cookie.load("brokerageTeamId") || ""
    const agentId = cookie.load("agentId")
    setCreateTransactionalFlow(selectedKey)
    if (teamExists && teamExists !== "null" && teamExists !== undefined) {
      toggleTransactionCreationForOffer(true)
    } else {
      setEditingDealTeamMemberId(agentId)
      handleCreateFlow(agentId, selectedKey)
    }
  }
  const items = [
    {
      label: "Listing",
      key: LISTING,
      onClick: handleMenuClick,
    },
    {
      label: "Listing under contract",
      key: LISTING_CONTRACT,
      onClick: handleMenuClick,
    },
    {
      label: "Lease (Landlord)",
      key: LISTING_LEASE,
      onClick: handleMenuClick,
    },
  ]
  return (
    <Dropdown
      menu={{
        items,
      }}
      trigger={["click"]}
    >
      <DropdownWrap>
        <PlusOutlined />
        <Space>
          Listing
          <DownOutlined />
        </Space>
      </DropdownWrap>
    </Dropdown>
  )
}

export default TechAssetsContainer(AddOffer)
