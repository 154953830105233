import React, { useEffect, useState } from "react"
import Listings from "container/Listings"
import OffersV2 from "container/OffersV2"
import TechAssetsContainer from "container/TechAssets"
import {
  SelectField,
} from "dumbComponents/common/InputFields"
import StatusShimmer from "./StatusShimmer"
import {
  getStatusOptions,
} from "../../utils"

const SelectStatusFilter = ({
  currentStatus,
  handleStatusChange,
  defaultValue,
  optionList,
  loading,
  borderColor,
  placeholder,
  width,
  style
}) => {
  const [statusList, setStatusList] = useState([])

  useEffect(() => {
    setStatusList(() => {
      if (optionList) {
        const getStatusOptionsResult = getStatusOptions(optionList)

        const newStatuses = getStatusOptionsResult.map((item) => {
          const newItem = item
          if (item.value === "new_offer") {
            newItem.label = "New Offer"
          }

          newItem.disabled = false

          return newItem
        })

        return newStatuses
      }

      return []
    })
  }, [])

  return (
    <>
      {loading && !optionList ? (
        <StatusShimmer />
      ) : (
        <SelectField
          placeholder={placeholder}
          options={statusList}
          onChange={handleStatusChange}
          defaultValue={defaultValue}
          style={ style? style : {
            width: "300px !important",
          }}
          value={currentStatus}
          width={width? width : "300px"}
          borderColor={borderColor}
        />
      )}
    </>
  )
}

export default (TechAssetsContainer(OffersV2(Listings(SelectStatusFilter))))
