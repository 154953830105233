import React from "react"
import root from "window-or-global"
import { LoadScript, checkForUserObject } from "services/Utils"
import { withRouter } from "react-router-dom"
import Container from "container/TechAssets"
import ListingsV2 from "container/ListingsV2"
import CRMContainer from "container/CRM"
import Listings from "container/Listings"

//getClientTransactionsById({ transactionId: transactionId || currentCRMDealId })


const user = checkForUserObject("user")

const agentId = user && user.id

class PubnubForDocusign extends React.Component {
  componentDidMount() {
    this.loadScript()
  }

  loadScript = () => {
    const {
      refreshOfferDocuments, 
      dealToView, 
      setOfferDocumentDownloadStatus, 
      id, 
      fetchActiveEnvelopesFiles, 
      getOfferStatus, 
      history,
      transactionId, 
      getClientTransactionsById, 
      refreshListingsV2Documents,
      updateDocusignEnvelopeStatus,
      toggleListingsDocumentProcessing, 
      listingIdToView,
      fetchMergedEnvelopes,
      setEnvelopeId,
    } = this.props
    const currentDealId = id
    const docusignOfferChecklistChannel = `docusign_offer_checklist_${currentDealId}`
    const docusignListingChannel = `docusign_listing_${currentDealId}`
    const offerDocumentStatusChannel = `offer_all_documents_status_${currentDealId}`
    LoadScript("https://cdn.pubnub.com/sdk/javascript/pubnub.4.29.8.min.js", () => {
      root.pubNubListingDocusign = new root.PubNub({
        publishKey: `${PUBNUB_FEED_PUBLISH_KEY}`,
        subscribeKey: `${PUBNUB_FEED_SUBSCRIBE_KEY}`,
        uuid: agentId,
      })

      root.pubNubListingDocusign.subscribe({
        channels: [offerDocumentStatusChannel, docusignListingChannel, "docusign_return",  `docusign_listing_status_${agentId}`,docusignOfferChecklistChannel],
      })

      root.pubNubListingDocusign.addListener({
        message: (data) => {
          console.log("Listening to..", docusignListingChannel, data)
          console.log("Listening to offer..", docusignOfferChecklistChannel, data)
          try {
            const { message, channel } = data
            const { event_type } = message
            console.log(message)
            console.log(event_type)

            if (channel === "docusign_return" && message.listingId && parseInt(message?.listingId, 10) === currentDealId && message?.agentId === agentId) {
              const docusignData = message
              if (docusignData && docusignData.docusignEnvelopeId) {
                toggleListingsDocumentProcessing(true)
                setEnvelopeId(docusignData.docusignEnvelopeId)
                if (docusignData.docusignIntermediateStatus) {
                  updateDocusignEnvelopeStatus({
                    payload: {
                      envelopeId: docusignData.docusignEnvelopeId,
                      status: docusignData.docusignIntermediateStatus,
                    },
                    callback: () => {
                      refreshListingsV2Documents({
                        listingId: currentDealId,
                      })
                      // getOfferStatus({ id: currentDealId })
                    },
                  })
                } else {
                  refreshListingsV2Documents({
                    listingId: currentDealId,
                  })
                  // getOfferStatus({ id: currentDealId })
                  setEnvelopeId(null)
                  toggleListingsDocumentProcessing(false)
                }
              }
              return
            }

            if (currentDealId && (channel === docusignListingChannel)) {
              refreshListingsV2Documents({
                listingId: currentDealId,
              })
              // getOfferStatus({ id: currentDealId })
              setEnvelopeId(null)
              toggleListingsDocumentProcessing(false)
            }

            if (currentDealId && (channel === docusignOfferChecklistChannel)) {
              refreshListingsV2Documents({
                offerId: currentDealId,
              })
              // getOfferStatus({ id: currentDealId })
              setEnvelopeId(null)
              toggleListingsDocumentProcessing(false)
            }

            if (currentDealId && (channel === offerDocumentStatusChannel)) {
              // to get url
              submitAndSendAllOffersFile({
                payload: {
                  offerId: currentDealId,
                },
                callback: () => {
                  setOfferDocumentDownloadStatus("done")
                },
              })
              // getOfferStatus({ id: currentDealId })
            }
          } catch (e) {
            console.log("exception", e)
          }
        },
      })
    })
  }

  componentWillUnmount() {
    const { listingIdToView, id } = this.props
    const currentDealId =  id
    const docusignListingChannel = `docusign_listing_${currentDealId}`
    const docusignOfferChecklistChannel = `docusign_offer_checklist_${currentDealId}`
    const offerDocumentStatusChannel = `offer_all_documents_status_${currentDealId}`
    if (root.pubNubListingDocusign) {
      root.pubNubListingDocusign.unsubscribe({
        channels: [docusignListingChannel, "docusign_return",docusignOfferChecklistChannel, offerDocumentStatusChannel],
      })
    }
  }

  render() {
    return null
  }
}

export default withRouter(Listings(CRMContainer(ListingsV2(Container(PubnubForDocusign)))))

