import React from "react"
import styled from "styled-components"

let Input = styled.input`
  outline: none;
  background-color: white;
  font: inherit;
  padding: 0px 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border: 1px solid #949494;
  border-radius: 8px;
  height: 40px;
  box-shadow: none;
  overflow: visible;
  margin-top: 10px;
  color: inherit;
  cursor: text;
  &:focus,
  &:active {
    box-shadow: none;
    //border: solid 0.5px #5A5FF2;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc !important;
    font-weight: 400;

    
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ccc !important;
    font-weight: 400;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ccc !important;
    font-weight: 400;
  }

  &:disabled {
   color: #4e4e4e;
  }
`

const Textbox = (props) => {
  const {
    withoutBorder,
    reference,
    onKeyPress,
    className,
    values,
    onBlur,
    update,
    maxlength,
    readOnly,
  } = props

  if (withoutBorder) {
    Input = styled(Input)`
      border: none;
    `
  }

  return (
    <Input
      innerRef={reference}
      onKeyPress={onKeyPress}
      className={`${className} ${values ? "fg-input-valid" : ""}`}
      readOnly={readOnly}
      onBlur={onBlur}
      onChange={update}
      maxLength={maxlength}
      {...props}
    />
  )
}

export default Textbox
