import React from "react"
import Box from "dumbComponents/common/UI/BuilderComponents/BoxV2"
import HubspotForm from "dumbComponents/common/HubspotForm"
import colors from "@colors"
import styled from "styled-components"
import { useWindowSize } from "services/Utils"
import { ATC } from "@fonts"
import root from "window-or-global"
import { get } from "lodash"
import queryString from "query-string"
import cookie from "services/CookieStorage"
import LargeText from "@ui/BuilderComponents/LargeText/index"
import Paragraph from "@ui/BuilderComponents/Paragraph/index"
import RecruitForm from "dumbComponents/RecruitEarnLanding/Form"
import RequestSubmitted from "dumbComponents/RecruitEarnLanding/RequestSubmitted"
import RealtyContainer from "container/Realty"
import interestContainer from "container/App/applyFlowContainer"
import BrokerInterestContainer from "container/App/brokersInterestContainer"
import HoriLine from "dumbComponents/NewTeamsPages/images/seperator.png"

const HeaderWrap = styled.div`
> div {
  @media(max-width: 768px) {
    padding: 100px 20px !important;
  }
}
`

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 85px;
  margin-right: 85px;
  margin-top: 75px;
  gap: 20px;
  align-items: flex-start;

  > iframe {
    width: 804px;

    @media(max-width: 1024px) {
      width: 100%;
    }

    @media(max-width: 425px) {
      height: 260px;
    }
  }

  @media(max-width: 1280px) {
    width: 100%;
    flex-direction: column;
    gap: 15px;
  }

  @media(max-width: 425px) {
    padding: 5px;
  }
`
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 60px

  @media(max-width: 1024px) {
    padding: 20px;
  }

  @media(max-width: 768px) {
    width: 100%;
  }
`
const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 21px;

  > a {
    color: ${colors.new_primary};
    font-family: ${ATC};
  }

  @media(max-width: 425px) {
    > button {
      width: 100% !important;
    }
  }
`

const HeaderForm = ({
  isSubmittedReferralInterestForm,
  isFetching,
  isAgentLP,
  history,
  applyInterestsResponse,
  submitInterestForm,
  signUpFormValues,
  ...props
}) => {
  const size = useWindowSize()

  const {
    account_type: productionType,
  } = signUpFormValues || {}

  const handleSubmit = (values) => {
    const nameArray = values.firstname.split(" ")
    const firstname = nameArray.shift()
    const lastname = nameArray.join(" ")
    const payload = {
      ...values,
      lastname,
      firstname,
      state: values.state.value,
      number_of_transactions_last_year: 0,
      account_type: "",
      agency_name: "",
      source: `${isAgentLP ? "Marketing - For Agents Page" : "Marketing - For Teams Page"}`,
    }

    if (productionType && productionType.production === "annual") {
      delete payload.group_annual_production
      payload.annual_production = values.annual_production.value
    }

    if (productionType && productionType.production === "group") {
      delete payload.annual_production
      payload.group_annual_production = values.group_annual_production.value
    }

    submitInterestForm({
      payload,
      history,
      webClassName: "teams_page_form",
      logInModalClassName: "team_page_login_form",
    })
  }

  return (

    <HeaderWrap>
      <Box
        backgroundColor="#F9F9F7"
        height="892px"
        backgroundImageSize="cover"
        elementPosition="relative"
        overflow="hidden"
        backgroundSize="cover"
        backgroundPosition="initial"
        customDivStyling={{
          backgroundPositionY: "initial",
          backgroundPositionX: "initial",
          transition: "background-position 0.2s ease-in",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "max-content",
          paddingTop: "90px",
          paddingBottom: "150px",
          paddingRight: "60px",
          paddingLeft: "60px",
        }}
      >
        <LargeText
          content="Super charge your real estate business"
          largeTextFamily="Butler"
          width="100%"
          color={colors.black}
          fontWeight="700"
          minDesktopLargeTextSize="54px"
          minLargeDesktopLargeTextSize="54px"
          preDesktopLargeTextSize="48px"
          maxMobilelargeTextWidth="100%"
          maxMobilelargeTextSize="44px"
          minTabletLargeTextSize="44px"
          alignLargeText="center"
          preTabletlargeTextWidth="601px"
          letterSpacing="1px"
          customLargeTextStyling={{
            position: size.width < 1099 ? "relative" : "static",
            width: "fit-content",
            fontWeight: "700",
          }}
        />
        {(size.width > 425) ? (
          <Paragraph
            content={`
            Premiere brokerage for performing agents ${isAgentLP ? "" : "| Perfect partner for teams"} | Zero split
            `}
            color={colors.black}
            minLargeDesktopParaSize="24px"
            minDesktopParaSize="24px"
            preDesktopParaSize="24px"
            minTabletParaSize="18px"
            paraWeight="300"
            /*TEXT WIDTHS*/
            minLargeDesktopParaWidth="895px"
            minDesktopParaWidth="739px"
            preDesktopParaWidth="500px"
            preTabletParaWidth="358px"
            alignPara="center"
            paraFamily={ATC}
            margin="0px 0px 26px 0px"
          />
        ) : (
          <React.Fragment>
            <Paragraph
              content={`
              Premiere brokerage for performing agents
              `}
              color={colors.black}
              minLargeDesktopParaSize="22px"
              minDesktopParaSize="22px"
              preDesktopParaSize="20px"
              minTabletParaSize="18px"
              maxMobileParaSize="23px"
              paraWeight="300"
              /*TEXT WIDTHS*/
              minLargeDesktopParaWidth="895px"
              minDesktopParaWidth="739px"
              preDesktopParaWidth="739px"
              preTabletParaWidth="358px"
              alignPara="center"
              paraFamily={ATC}
              margin="0px 0px 10px 0px"
            />
            <img
              src={HoriLine}
              alt="Radius hori line"
              style={{
                margin: "10px",
              }}
            />
            {isAgentLP ? "" : (
              <>
                <Paragraph
                  content={`
                  Perfect partner for teams
                  `}
                  color={colors.black}
                  minLargeDesktopParaSize="22px"
                  minDesktopParaSize="22px"
                  preDesktopParaSize="20px"
                  minTabletParaSize="18px"
                  maxMobileParaSize="23px"
                  paraWeight="300"
                  /*TEXT WIDTHS*/
                  minLargeDesktopParaWidth="895px"
                  minDesktopParaWidth="739px"
                  preDesktopParaWidth="739px"
                  preTabletParaWidth="358px"
                  alignPara="center"
                  paraFamily={ATC}
                  margin="0px 0px 10px 0px"
                />
                <img
                  src={HoriLine}
                  alt="Radius hori line"
                  style={{
                    margin: "10px",
                  }}
                />
              </>
            )}
            <Paragraph
              content={`
              Zero split
              `}
              color={colors.black}
              minLargeDesktopParaSize="22px"
              minDesktopParaSize="22px"
              preDesktopParaSize="20px"
              minTabletParaSize="18px"
              maxMobileParaSize="23px"
              paraWeight="300"
              /*TEXT WIDTHS*/
              minLargeDesktopParaWidth="895px"
              minDesktopParaWidth="739px"
              preDesktopParaWidth="739px"
              preTabletParaWidth="358px"
              alignPara="center"
              paraFamily={ATC}
              margin="0px 0px 26px 0px"
            />
          </React.Fragment>
        )}
        <Wrap>
          <iframe
            height={460}
            src="https://www.youtube.com/embed/MYdPSZSh7ZY"
            title="YouTube video player"
            frameBorder="0"
            allowFullScreen
          >
          </iframe>
          <FormContainer>
            {/* <RecruitForm
              onSubmit={handleSubmit}
              isSubmitting={isFetching}
              isAgentsLP
              signUpFormValues={signUpFormValues}
            /> */}
            <HubspotForm
              region="na1"
              portalId="7498349"
              formId="80767e3a-3a65-4245-b481-e96845e1dcf2"
            />
            <ButtonWrap>
              <a href="https://meetings.hubspot.com/mari23/radius-agent-realty-product-all-pages?embed=true">
                {isAgentLP ? "Get Started" : "Discover your next brokerage"}
              </a>
            </ButtonWrap>
          </FormContainer>
        </Wrap>

      </Box>
    </HeaderWrap>
  )
}

export default BrokerInterestContainer(interestContainer(RealtyContainer(HeaderForm)))
