import axios from "axios";

export const getFollowersAndFollowingAPI = (agentId) =>
  axios.get(`${API_V2_URL}network/followers-following?otherAgentId=${agentId}`);

export const getOwnFollowerAndFollowingAPI = () =>
  axios.get(`${API_V2_URL}network/followers-following`);

export const saveProfileInfoAPI = (payload) =>
  axios.put(`${API_V2_URL}user/info/update`, payload);

export const getGoalsAPI = () => axios.get(`${API_URL}sign-up/goals`);

export const sendGoalsAPI = (payload) =>
  axios.post(`${API_URL}user/sign-up/goals`, payload);

export const fetchSpecializationsAPI = () =>
  axios.get(`${API_V2_URL}user/nar-designations`);

export const getNeighbourhoodByCityAPI = (params) =>
  axios.get(`${API_URL}getNeighbourhoods?${params}`);

export const getProprtyTypesAPI = () => axios.get(`${API_URL}property/types`);

export const getProprtyStatusAPI = () =>
  axios.get(`${API_URL}statuses?type=property`);

export const saveAddListingAPI = (payload) =>
  axios.post(`${API_URL}user/property`, payload);

export const getProfilecompletionAPI = (query) =>
  axios.get(`${API_V3_URL}user/profile${query}`);

export const getUserProfileAPI = (query) =>
  axios.get(`${API_V3_URL}user/profile${query}`);

export const getNeighbourhoodsAPI = (query) =>
  axios.get(`${API_URL}user/cities-neighbourhoods-served${query}`);

export const getMlsServingListsAPI = () => axios.get(`${API_URL}mls-info`);

export const syncMLSAPI = (data) => axios.post(`${API_URL}mls-info`, data);

export const removeSyncedMlsAPI = (mlsId) =>
  axios.delete(`${API_URL}mls-info/${mlsId}`);

export const getUserActivityAPI = (query) =>
  axios.get(`${API_V2_URL}network/publicActivitiesForAgent${query}`);

export const followAgentAPI = (payload) =>
  axios.post(`${API_V2_URL}network/follow`, payload);

export const unfollowAgentAPI = (agentId) =>
  axios.delete(`${API_V2_URL}network/follow/${agentId}`);

export const fetchBuyerNeedsAPI = (query) =>
  axios.get(`${API_URL}public-requirements${query}`);

export const saveCityServedAPI = (payload) =>
  axios.post(`${API_URL}user/neighbourhoods-served`, payload);

export const addCityAPI = (payload) =>
  axios.post(`${API_URL}user/cities-neighbourhoods-served`, payload);

export const addBuyerNeedsAPI = (payload) =>
  axios.post(`${API_V2_URL}requirements`, payload);

export const editBuyerNeedsAPI = (id, payload) =>
  axios.put(`${API_V2_URL}requirements/${id}`, payload);

export const fetchCityNeighborhoodServedAPI = (query) =>
  axios.get(`${API_URL}user/cities-neighbourhoods-served${query}`);

export const fetchLicenseNumbersAPI = (agentId) =>
  axios.get(`${API_V3_URL}user-license-info?agentId=${agentId}`);

export const saveLicenseNumberAPI = (payload) => 
    axios.post(`${API_V3_URL}user-license-info`, payload);

export const removeCityAPI = (id) =>
  axios.delete(`${API_URL}user/city-served?cityServedId=${id}`);

export const fetchMoreFeedsAPI = (payload) =>
  axios.post(`${API_V2_URL}network/publicActivitiesForAgent`, payload);

export const removeCityServedCityAPI = (cityServedId) =>
  axios.delete(`${API_URL}user/city-served?cityServedId=${cityServedId}`);

export const removeCityServedNeighbourhoodAPI = (payload) =>
  axios.put(`${API_URL}user/neighbourhoods-served`, payload);

export const removeBuyerNeedsAPI = (id) =>
  axios.delete(`${API_V2_URL}requirements/${id}`);

export const editPropertyAPI = (properyId, payload) =>
  axios.put(`${API_URL}user/property/${properyId}`, payload);

export const submitOnboardingForm = (payload) =>
  axios.post(`${API_URL}partnerSubscriptionInterests`, payload);

export const getAgentsfeedsAPI = (agentId) =>
  axios.get(`${API_URL}community/agent/feeds?agentId=${agentId}`);

export const profileViewedAPI = (payload) =>
  axios.post(`${API_URL}side-menu-updates/feature-viewed`, payload);

export const uploadProfileViewAPI = (agentId) =>
  axios.post(`${API_V2_URL}user/${agentId}/view`);

export const getDocusignIntegrationStatusAPI = (agentId) => axios.get(`${API_V3_URL}user-docusign-integration?agentId=${agentId}`)

export const docusignIntegrationAPI = (payload) => axios.post(`${API_V3_URL}user-docusign-integration`, payload)

export const saveDBAInformationAPI = (payload) => axios.post(`${API_URL}save-dba-info`, payload)

export const fetchDBAInformationAPI = ()=>axios.get(`${API_URL}get-dba-info`)