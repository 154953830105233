import React, { useState, useEffect } from "react"
import {
  Modal, Input, Button, Select,
} from "antd"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import moment from "moment"
import { ShimmerBox } from "shared/styles/animation"
import {
  ModalWrapper,
  CreditHeadingWrap,
  CreditHeading,
  CreditImage,
  CancelledCardImage,
  InfoWrap,
  CommonText,
  CancelButton,
  CreditProceedButton,
  CreditCancelButton,
  RadioDiv,
  ToggleButtonWrap,
  ActiveButton,
  AnalyticCard,
} from "dumbComponents/common/SettingsTab/Billings/Modals/styles"
import InjectedACHForm from "dumbComponents/common/SettingsTab/Billings/Modals/InjectedACHForm"
import InjectedCreditCardForm from "dumbComponents/common/SettingsTab/Billings/Modals/InjectedCreditCardForm"
import {
  HorizontalBar,
} from "../../styles"
const stripePromise = loadStripe(STRIPE_PUBLISH_KEY)

const CancelledAddPayment = ({
  toClose,
  isOpen,
  defaultPaymentObject,
  planDetail,
  initialBillingData,
  handleProceed,
  loading = false,
}) => {
  if (loading) {
    return (
      <Modal open={isOpen} onCancel={toClose} footer={null} width={530}>
        <ModalWrapper>
          <CreditHeadingWrap>
            <CreditImage>
              <img src={`${CLOUDFRONT}/brokeragePage/settingsModeule.svg`} width="19px" />
            </CreditImage>
          </CreditHeadingWrap>
          <ShimmerBox w="100%" h="500px" />
        </ModalWrapper>
      </Modal>
    )
  }
  const billingDate = moment.unix(initialBillingData).format("MMMM D, YYYY")
  const startDate = moment()
  const endDate = planDetail?.billing_cycle === "MONTHLY"
    ? startDate.clone().add(1, "month") // Add 1 month for monthly billing
    : startDate.clone().add(1, "year") // Add 1 year for yearly billing
  const [selectedPaymentMethod, setPaymentMethod] = useState("card")
  const [isEditPayment, setEditPayment] = useState(false)
  const onClickPaymentMethod = (value) => {
    setPaymentMethod(value)
  }
  const handClickForCloseModal = () => {
    handleProceed(true)
  }
  console.log("defaultPaymentObject cancell", defaultPaymentObject)
  const handleClick = () => {
    handleProceed(false)
  }
  const formattedBillingPeriod = `${startDate.format("MMMM D, YYYY")} - ${endDate.format("MMMM D, YYYY")}`
  return (
    <Modal open={isOpen} onCancel={toClose} footer={null} width={530}>
      <ModalWrapper>
        <CreditHeadingWrap>
          <CreditImage>
            <img src={`${CLOUDFRONT}/brokeragePage/settingsModeule.svg`} width="19px" />
          </CreditImage>
        </CreditHeadingWrap>
        <>
          <CreditHeadingWrap>
            <CreditHeading>
              Don't fall behind your competition!
            </CreditHeading>
            <CommonText
              color="#6B7280"
              fontSize="14px"
              weight="400"
              lineHeigth
            >
              Your basic subscription has expired while other agents are leveraging our premium features to close more deals.
            </CommonText>
          </CreditHeadingWrap>
        </>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            gap: "16px",
            width: "100%",
          }}
        >
          <AnalyticCard>
            <CancelledCardImage>
              <img src={`${CLOUDFRONT}/brokeragePage/peopls.svg`} width="24px" />
            </CancelledCardImage>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CommonText
                color="#111827"
                fontSize="24px"
                weight="600"
                lineHeigth
              >
                5,234
              </CommonText>
              <CommonText
                color="#6B7280"
                fontSize="12px"
                weight="400"
              >
                agents growing their business
              </CommonText>
            </div>
          </AnalyticCard>
          <AnalyticCard>
            <CancelledCardImage>
              <img src={`${CLOUDFRONT}/brokeragePage/Analytics.svg`} width="24px" />
            </CancelledCardImage>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CommonText
                color="#111827"
                fontSize="24px"
                weight="600"
                lineHeigth
              >
                147%
              </CommonText>
              <CommonText
                color="#6B7280"
                fontSize="12px"
                weight="400"
              >
                average revenue increase
              </CommonText>
            </div>
          </AnalyticCard>
          <AnalyticCard>
            <CancelledCardImage>
              <img src={`${CLOUDFRONT}/brokeragePage/key.svg `} width="24px" />
            </CancelledCardImage>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CommonText
                color="#111827"
                fontSize="24px"
                weight="600"
                lineHeigth
              >
                Top 1%
              </CommonText>
              <CommonText
                color="#6B7280"
                fontSize="12px"
                weight="400"
              >
                of agents use our analytics dashboard
              </CommonText>
            </div>
          </AnalyticCard>
        </div>
        {
          ((defaultPaymentObject && defaultPaymentObject.length > 0 && !isEditPayment)) ? (
            <InfoWrap>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <CommonText
                  color="#232338"
                  fontSize="14px"
                  weight="500"
                  lineHeigth
                >
                  {planDetail.name ? planDetail.name : "Individual Plan" }
                </CommonText>
                <CommonText
                  color="#373758"
                  fontSize="14px"
                  weight="500"
                  lineHeigth
                >
                  First payment on
                  {" "}
                  {billingDate}
                </CommonText>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <CommonText
                  color="#232338"
                  fontSize="14px"
                  weight="500"
                  lineHeigth
                >
                  Payment Method
                </CommonText>
                <CommonText
                  color="#373758"
                  fontSize="14px"
                  weight="500"
                  lineHeigth
                >
                  {defaultPaymentObject[0].bank_name ? (
                    <>
                      {defaultPaymentObject[0].bank_name}
                      {" "}
                      ACH
                    </>
                  ) : (
                    <>
                      **** **** ****
                      {" "}
                      {defaultPaymentObject[0].last_four_digits}
                      {" "}
                      {defaultPaymentObject[0].brand.charAt(0).toUpperCase() + defaultPaymentObject[0].brand.slice(1)}
                    </>
                  )
                  }
                </CommonText>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <CommonText
                  color="#232338"
                  fontSize="14px"
                  weight="500"
                  lineHeigth
                >
                  Billing Period
                </CommonText>
                <CommonText
                  color="#373758"
                  fontSize="14px"
                  weight="500"
                  lineHeigth
                >
                  {formattedBillingPeriod}
                </CommonText>
              </div>
            </InfoWrap>
          ) : (
            <>
              <RadioDiv>
                {selectedPaymentMethod == "card" ? (
                  <>
                    <ToggleButtonWrap onClick={() => { onClickPaymentMethod("ach") }}>
                      {" "}
                      <img
                        src={`${CLOUDFRONT}/brokeragePage/ach.svg`}
                        width="19px"
                      />
                      {" "}
                      ACH transfer
                    </ToggleButtonWrap>
                    <ActiveButton onClick={() => { onClickPaymentMethod("card") }}>
                      {" "}
                      <img
                        src={`${CLOUDFRONT}/brokeragePage/creditCard.svg`}
                        width="19px"
                      />
                      {" "}
                      Credit card
                    </ActiveButton>
                  </>
                ) : (
                  <>
                    <ActiveButton onClick={() => { onClickPaymentMethod("ach") }}>
                      <img
                        src={`${CLOUDFRONT}/brokeragePage/activeAch.svg`}
                        width="19px"
                      />
                      ACH transfer
                    </ActiveButton>
                    <ToggleButtonWrap onClick={() => { onClickPaymentMethod("card") }}>
                      <img
                        src={`${CLOUDFRONT}/brokeragePage/greyCreditCard.svg`}
                        width="19px"
                      />
                      Credit card
                    </ToggleButtonWrap>
                  </>
                )}
              </RadioDiv>
              <Elements stripe={stripePromise}>
                {selectedPaymentMethod === "card" ? (
                  <InjectedCreditCardForm custom planDetail={planDetail} handleClick={handClickForCloseModal} handleClickForCancel={toClose} />
                //<InjectedCreditCardFormV2 handleClick={handleProceed} handleClickForCancel={handleClickForCancel} />
                ) : (
                  <InjectedACHForm handleClick={handleProceed} handleClickForCancel={toClose} />
                )}
              </Elements>
            </>
          )
        }
        {(defaultPaymentObject && defaultPaymentObject.length > 0 && !isEditPayment) && (
        <>
          <HorizontalBar />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              alignSelf: "stretch",
            }}
          >
            <CommonText
              color="#232338"
              fontSize="14px"
              weight="400"
              lineHeigth
            >
              Total Amount:
            </CommonText>
            <CommonText
              color="#0C9F6E"
              fontSize="14px"
              weight="400"
              lineHeigth
            >
              $
              {planDetail?.unit_price ? (planDetail.unit_price) : "N/A"}
              /
              {planDetail.billing_cycle === "MONTHLY" ? "month" : "year"}
            </CommonText>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              gap: "14px",
              flexDirection: "column",
            }}
          >
            {!isEditPayment && <CancelButton onClick={() => setEditPayment(true)}> Edit Payment Method</CancelButton>}
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                alignSelf: "stretch",
                gap: "32px",
              }}
            >
              <CreditCancelButton width onClick={toClose}><p>Cancel</p></CreditCancelButton>
              <CreditProceedButton width onClick={handleClick}><p>Confirm and Pay</p></CreditProceedButton>

            </div>
          </div>
        </>
        ) }
      </ModalWrapper>
    </Modal>
  )
}

export default (CancelledAddPayment)
