import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import {
  useStripe, useElements,
} from "@stripe/react-stripe-js"
import {
  Form,
} from "@ui/antd"
import {
  InputField,
} from "dumbComponents/common/InputFields"
import { Checkbox } from "antd"
import queryString from "query-string"
import SettingsContainer from "container/Settings"
import OnboardingContainer from "container/OnboardingSetup"
import Container from "container/Settings/SubscriptionsContainer"
import { withRouter } from "react-router-dom"
import Navbar from "container/Navbar"
import { get, isEmpty } from "lodash"
import AppContainer from "container/App"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import {
  ACHWrapDiv,
  ButtonWrap,
  CreditPlanButtonWrap,
  ACHInputDiv,
  CreditCancelButton,
  CreditFormWrap,
  CreditProceedButton,
  CardLabel,
} from "../../../../JoinClientApp/Components/Modals/styles"
import {
  HorizontalBar,
} from "../styles"
import {
  CommonText,
  CreditCancelButton as CancelButtom,
  CreditProceedButton as ProceedButtom,
} from "./styles"

const InjectedACHForm = ({
  verifyAuthToken,
  history,
  getStripeSecretKey,
  getStripeSecretResponse,
  verifyAuthTokenResponse,
  saveStripeSubscription,
  getCardDetails,
  saveStripeSubscriptionResponse,
  fetchUserInfo,
  handleClickForCancel,
  handleClick,
  getClientSecret,
  getClientSecretResponse,
  custom = false,
  isLoadingCard,
  setLoadingCard,
  planDetail,
  addOns=null,
  ...props
}) => {
  const [clientSecret, setClientSecret] = useState("sk_test_tR3PYbcVNZZ796tH88S4VQ2u")
  const [error, setError] = useState(null)
  const stripe = useStripe()
  const elements = useElements()
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const { isFetching } = saveStripeSubscriptionResponse || {}
  const parsedQuery = queryString.parse(history.location.search)
  const { authcode } = parsedQuery
  const payload = {
    authCode: authcode,
  }
  const formRef = useRef(null)
  const { data, isFetching: clientSecretFetching } = getClientSecretResponse || {}
  const { client_secret } = data || {}
  useEffect(() => {
    setClientSecret(client_secret)
  }, [getClientSecretResponse])
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!name.trim() || !email.trim()) {
      setError("Both Name and Email are required.")
      return
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.")
      return
    }

    setError(false)

    if (!stripe || !elements) {
      console.error("Stripe.js has not loaded yet.")
      return
    }

    const clientSecretPayload = {
      intent_type: "ach",
    }
    setLoadingCard(true)

    getClientSecret({
      payload: clientSecretPayload,
      callback: async (response) => {
        const clientSecretkey = response?.client_secret
        if (!clientSecretkey) {
          console.error("Client secret not returned from API.")
          return
        }
        handleClickForCancel()
        stripe.collectBankAccountForSetup({
          clientSecret: clientSecretkey,
          params: {
            payment_method_type: "us_bank_account",
            payment_method_data: {
              billing_details: {
                name,
                email,
              },
            },
          },
          expand: ["payment_method"],
        })
          .then(({ setupIntent, error }) => {
            console.log("setupIntent", setupIntent)
            if (error) {
              console.log("error", error)
              handleClick("generic_declined")
            } else if (setupIntent.status === "requires_confirmation") {
              stripe.confirmUsBankAccountSetup(
                `${clientSecretkey}`,
              ).then(({ setupIntent }) => {
                console.log(setupIntent.status, "here1234")
                handleClick(setupIntent.status)
              })
            } else if (setupIntent.status === "succeeded") {
              handleClick(setupIntent.status)
              console.log(setupIntent, "succeeded wala")
            } else if (setupIntent.next_action?.type === "verify_with_microdeposits") {
              handleClick()
              console.log(setupIntent.status)
            }
          })
      },

    })
  }
  return (
    <CreditFormWrap>
      <form
        id="payment-form"
        onSubmit={handleSubmit}
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            marginTop: "16px", marginBottom: "16px", gap: "18px", display: "flex", flexDirection: "column",
          }}
        >
          <ACHWrapDiv>
            <CardLabel>
              Account holder name
            </CardLabel>
            <ACHInputDiv value={name} onChange={e => setName(e.target.value)} />
          </ACHWrapDiv>
          <ACHWrapDiv>
            <CardLabel>
              Account holder email
            </CardLabel>
            <ACHInputDiv value={email} onChange={e => setEmail(e.target.value)} />
          </ACHWrapDiv>
          {error && <p style={{ color: "red", marginTop: "8px" }}>{error}</p>}
        </div>
        {
          custom ? (
            <>
              <HorizontalBar />
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  alignSelf: "stretch",
                  marginTop: "16px",
                }}
              >
                <CommonText
                  color="#232338"
                  fontSize="14px"
                  weight="400"
                  lineHeigth
                >
                  Total amount:
                </CommonText>
                <CommonText
                  color="#0C9F6E"
                  fontSize="14px"
                  weight="400"
                  lineHeigth
                >
                  $
                  {planDetail?.unit_price ? (planDetail.unit_price) : "N/A"}
                  /
                  {planDetail.billing_cycle === "MONTHLY" ? "month" : "year"}
                </CommonText>
              </div> */}
              {addOns && addOns.map((addOn, index) => (
          <div
            key={addOn.id || index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              alignSelf: "stretch",
            }}
          >
          <CommonText
            color="#232338"
            fontSize="14px"
            weight="400"
            lineHeigth
          >
            {addOn.name}
          </CommonText>
          <CommonText
            color="#0C9F6E"
            fontSize="14px"
            weight="400"
            lineHeigth
          >
            $
            {addOn?.pricing?.amount ? addOn.pricing.amount : "N/A"}
            /
            {addOn?.pricing?.billing_cycle === "MONTHLY" ? "month" : "year"}
          </CommonText>
        </div>
    ))}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                alignSelf: "stretch",
              }}
            >
              <CommonText
                color="#232338"
                fontSize="14px"
                weight="400"
                lineHeigth
              >
                {planDetail?.name || "Radius Plan"}
              </CommonText>
              <CommonText
                color="#0C9F6E"
                fontSize="14px"
                weight="400"
                lineHeigth
              >
                $
                {(planDetail.unit_price !== undefined || planDetail?.unit_price !== null) ? planDetail.unit_price : 'N/A'}
                /
                {planDetail.billing_cycle === "MONTHLY" ? "month" : "year"}
              </CommonText>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                alignSelf: "stretch",
              }}
            >
              <CommonText
                color="#232338"
                fontSize="14px"
                weight="600"
                lineHeigth
              >
                Total amount
              </CommonText>
              <CommonText
                color="#0C9F6E"
                fontSize="14px"
                weight="600"
                lineHeigth
              >
                ${(
                (planDetail?.unit_price || 0) + 
                (addOns ? addOns.reduce((sum, addOn) => sum + (addOn?.pricing?.amount || 0), 0) : 0)
                )}/
                {planDetail?.billing_cycle === "MONTHLY" ? "month" : "year"}
              </CommonText>
            </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  alignSelf: "stretch",
                  gap: "32px",
                  marginTop: "30px",
                }}
              >
                <CancelButtom onClick={handleClickForCancel} width><p>Cancel</p></CancelButtom>
                <ProceedButtom width>
                  {isLoadingCard ? (
                    <ThreeDotLoader color="#fff" />
                  ) : (
                    <p>Proceed</p>
                  )}
                </ProceedButtom>

              </div>
            </>
          ) : (
            <>
              <ButtonWrap>
                <CreditPlanButtonWrap>
                  <CreditCancelButton onClick={handleClickForCancel} type="button">
                    <p>
                      Cancel
                    </p>
                  </CreditCancelButton>
                  <CreditProceedButton type="submit">
                    {isLoadingCard ? (
                      <ThreeDotLoader color="#fff" />
                    ) : (
                      <p>Proceed</p>
                    )}
                  </CreditProceedButton>
                </CreditPlanButtonWrap>
              </ButtonWrap>
            </>
          )
        }
      </form>
    </CreditFormWrap>

  )
}
export default (withRouter(AppContainer(Container(OnboardingContainer(SettingsContainer(Navbar(InjectedACHForm)))))))
