import React, { useEffect, useState } from "react"
import styled from "styled-components"
import ClientProfileContainer from "container/ClientProfile"
import CRMV2 from "container/CRM"
import AddIcon from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/AddIcon.svg"
import colors from "@colors"
import AccountOverviewContainer from "container/Settings/AccountOverviewContainer"
import TechAssetsContainer from "container/TechAssets"
import Loader from "@ui/Loader"
import GoogleSignIn from "@ui/GoogleSignIn"
import createToast from "@ui/Toast"
import root from "window-or-global"
import queryString from "query-string"
import { get } from "lodash"
import {
  H1, Wrap, Title, AddButton, Icon,
} from "../../../common"
import ReminderCard from "./ReminderCard"
import AddReminder from "./AddReminder"
import { TabContentContainer } from "../../styles"

const toastConfig = {
  position: "top-left",
  autoClose: 3000,
  hideProgressBar: true,
  className: "blue-background ",
}

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.silentWhite};
  margin-right: 15px;
  padding: 8px;
`

const RemindersWrap = styled.ul`
  list-style: none;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const CardWrap = styled.li`
  padding: 10px 10px 10px 10px;
  box-shadow: 2px 2px 5px rgb(0 0 0 / 12%);
  border-radius: 6px 5px 5px 7px;
  border-left: 2.5px solid ${colors.new_primary};
  background: ${colors.white};
`

const ZeroStateWrap = styled.div`
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const RemindersTab = ({
  fetchClientReminder,
  currentClientTransactionID,
  reminders,
  toggleReminderModal,
  isReminderModalOpen,
  syncGmail,
  isSyncingGmail,
  googleAuthToken,
  resetGoogleContacts,
  history,
  getGoogleAuthToken,
  getReminder,
  currentTransactionDetails,
  fetchClientReminderResponse,
  getCtIdResponse,
}) => {
  const { data: transactionData } = getCtIdResponse || {}
  const { id } = transactionData || {}
  const [isAddClicked, setClicked] = useState(false)
  const [isEditClicked, setEditClicked] = useState(false)
  useEffect(() => {
    getGoogleAuthToken()
    getReminder({ transactionId: id })
  }, [])

  // const {
  //   data: reminderData,
  //   isFetching: fetchingReminders,
  // } = reminders || {}

  const {
    data: reminderData,
    isFetching: fetchingReminders,
  } = fetchClientReminderResponse || {}

  const activateGmail = (auth) => {
    const search = get(root, "location.search", {})
    const parsedQuery = queryString.parse(search)
    const fromHeader = parsedQuery?.from_header
    syncGmail({
      payload: auth,
      callback: () => {
        createToast("Google Calendar Integrated", toastConfig)
      },
    })
  }

  return (
    <>
      <TabContentContainer>
        <HeaderWrap>
          <div>
            <GoogleSignIn
              type="theme-1" sync={activateGmail}
              text="Connect Google Calendar"
              mr="4%"
              disconnectText="Disconnect Google Calendar"
              loading={isSyncingGmail}
              googleAuthToken={googleAuthToken?.data?.access_token}
              resetGoogleContacts={resetGoogleContacts}
            />
          </div>
          <AddButton
            onClick={() => {
              setClicked(true)
            }}
          >
            <Title>
              Add Reminder
            </Title>
            <Icon src={AddIcon} />
          </AddButton>
        </HeaderWrap>

        {fetchingReminders ? (
          <Loader size={60} invert top={10} />
        ) : (
          <RemindersWrap>
            {reminderData?.map(item => (
              <CardWrap>
                <ReminderCard
                  data={item}
                  setEditClicked={setEditClicked}
                />
              </CardWrap>
            ))}
          </RemindersWrap>
        )}
        {reminderData && reminderData.length === 0 && !isAddClicked && (
          <ZeroStateWrap>
            <Title>
              No reminders yet.
            </Title>
          </ZeroStateWrap>
        )}
        {(isAddClicked || isEditClicked) && (
          <AddReminder
            setClicked={setClicked}
            setEditClicked={setEditClicked}
            isEditClicked={isEditClicked}
          />
        )}
      </TabContentContainer>
    </>
  )
}

export default TechAssetsContainer(
  AccountOverviewContainer(
    CRMV2(
      ClientProfileContainer(
        RemindersTab
      )
    )
  )
)
