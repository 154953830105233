import React from "react"
import {
  CompanyProfileSVGIcon,
} from "dumbComponents/common/UI/Sidebar/NewSidebarIcons"
import CookiesStorage from "services/CookieStorage"
import root from "window-or-global"
import NewFeature from "images/newFeature.png"
import {
  InnerTab,
  IconEle,
  NavLink,
  ImageSpan,
} from "./commonStyles"

const TeamProfileTab = ({
  routeArray,
  checkIfTeamUpdatedByLead,
  teamDetails,
  toggleEditMode,
  thisAgentHasATeam,
  isBroker,
}) => (
  <InnerTab
    pt="4px"
    isActive={routeArray[1] === "team-profile" || routeArray[1] === "team_development"}
  >
    <div className="icon-block-new">
      <IconEle
        src={CompanyProfileSVGIcon}
        alt="RARProducts"
        isActive={routeArray[1] === "team-profile" || routeArray[1] === "team_development"}
      />
    </div>
    <div className="text-block">
      <NavLink
        to={
          thisAgentHasATeam
            ? (checkIfTeamUpdatedByLead(teamDetails)
          || CookiesStorage.load("teamProfileWelcomeDone"))
              ? "/settings" : "/settings"
            : "/realty/promos?type=team_development"
        }
        // to="/realty/team-profile"
        isActive={routeArray[1] === "team-profile"}
        onClick={() => {
          root.scrollTo(0, 0)
          if (isBroker && thisAgentHasATeam) {
            if (root.heap) {
              root.heap.track("click_sidebar_teamProfileRAR_web")
            }
  
            toggleEditMode(true)
          }
        }}
      >
        {isBroker && thisAgentHasATeam ? "Team Profile" : "Team Development"}
      </NavLink>
    </div>
  </InnerTab>
)

export default TeamProfileTab
