import React from "react"
import styled from "styled-components"
import colors from "@colors"
import { HIGHLIGHT_FONT } from "@fonts"
import {
  preDesktopWidth,
  maxTabletWidth,
  xsMobile,
  preTabletWidth,
} from "@consts"
import BGImage from "images/Graphics/HomeV6/Footer.png?q=60&h=400&w=1200&fm=webp"
import ArrowIcon from "images/Graphics/Button/ArrowRightWhite_2.svg"
import { ATC } from "@fonts"

const Wrap = styled.div`
  background: #CCC no-repeat;
  background-image: url(${BGImage});
  background-size: cover;
  min-height: 403px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-position: 0px -250px;
  margin-top: 60px;
  ${preDesktopWidth(`
    min-height: 420px;
    background-position: center;
  `)}
`

const InnerWrap = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  width: 639px;;
  margin: 50px;

  ${xsMobile(`
    padding: 0px 30px;
    width: fit-content;
    margin: initial;
  `)}
  h2 {
    font-family: ${HIGHLIGHT_FONT};
    font-size: 42px;
    line-height: 46px;
    color: ${colors.dreamWhite};
    margin-bottom: 0px;
    ${preDesktopWidth(`
      font-size: 36px;
      line-height: 47px;
    `)}
  }

  p {
    font-family: ${ATC};
    color: #F9F9F7;
    position: relative;
    top: 13px;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
    ${preDesktopWidth(`
      font-family: ${ATC};
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 23px;
      letter-spacing: 0em;
      text-align: left;
    `)}

    ${preTabletWidth`
      font-family: ${ATC};
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    `}
`

const ApplyButton = styled.button`
  font-family: ${ATC};
  margin-top: 20px;
  width: max-content;
  padding: 12px 17px;
  height: 60px;
  cursor: pointer;
  border: none;
  background: ${colors.new_primary};
  color: ${colors.teethWhite};
  font-size: 21px;
  ${maxTabletWidth(`
    font-size: 21px;
  `)}
  display: flex;
  align-items: baseline;
  gap: 9px;
`

const ArrowSpan = styled.span`
  font-size: 25px;
  position: relative;
  right: -2px;
  top: 4px;
`

const UpgradeToRadius = ({ onApply }) => (
  <Wrap>
    <InnerWrap className="container">
      <h2>
        Ready to upgrade your professional network?
      </h2>
      <p>
        Join a people-driven community operating in a technology-driven world.
      </p>
      <ApplyButton onClick={onApply}>
        Create a Free Account
        <ArrowSpan>
          <img src={ArrowIcon} alt="arrow" />
        </ArrowSpan>
      </ApplyButton>
    </InnerWrap>
  </Wrap>
)

export default UpgradeToRadius
