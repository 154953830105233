import React from "react"
import styled from "styled-components"
import { get, isArray } from "lodash"
import root from "window-or-global"
import { Link, withRouter } from "react-router-dom"
import copyToClipboard from "copy-to-clipboard"
import icons from "@icons"
import createToast from "@ui/Toast"
import RoundedTag from "dumbComponents/common/StyledComponents/RoundedTag"
import BoxHolder from "dumbComponents/common/StyledComponents/BoxHolder"
import Paragraph from "dumbComponents/common/Typography/Paragraph"
import CircularImage from "dumbComponents/common/CircularImage"
import FeedAction from "dumbComponents/Network/MiddleContainer/FeedAction"
import ArrowImgRight from "images/academy/event-arrow-right.svg"
import DropdownMenu from "@ui/FeedOptions"
import Popover from "@ui/NewTooltip"
import Button from "@ui/Button"
import Box from "@ui/Box"
import Slider from "react-slick"
import {
  checkForUserObject,
  unixToDate,
  getProfileUrl,
  convertUnicode,
} from "services/Utils"
import colors from "@colors"
import { convertToRichText } from "container/Academy/selector"
import distanceInWordsToNow from "date-fns/distance_in_words_to_now"
import LikedAgents from "./LikedAgents"
import Snippet from "./Snippet"
import CongratulatorFeed from "./CongratulatorFeed"
import SeeMoreTextWrapper from "./SeeMoreTextWrapper"
import CourseFeed from "./CourseFeed"
import CommunityEvent from "./CommunityEvent"
import AcademyInstructors from "./AcademyInstructors"
import TopComment from "./TopComment"
import VideoFeed from "./VideoFeed"

const linkify = require("linkify-it")()

const user = checkForUserObject("user") || {}

const NextArrowWrap = styled.div`
  img {
    position: absolute;
    width: 75px;
    height: 75px;
    ${props => props.dir === "right" && `
      top: -25px;
      left: -25px;
    `}
    ${props => props.dir === "left" && `
      transform: rotate(180deg);
      top: -30px;
      left: -30px;
    `}
  }
`

const DirectionArrow = ({ dir, onClick, className, style }) => (
  <NextArrowWrap
    onClick={(e) => {
      e.stopPropagation()
      onClick()
    }}
    className={className}
    style={{ ...style }}
    dir={dir}
  >
    <img src={ArrowImgRight} alt="Arrow Icon" />
  </NextArrowWrap>
)

const SLIDER_SETTINGS = {
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  nextArrow: <DirectionArrow dir="right" key="right" />,
  prevArrow: <DirectionArrow dir="left" key="left" />,
  centerMode: false,
}

const FeedItemWrapper = styled.div`
  display: flex;
  background: #ffffff;
  border: 1px solid #e6e8e9;
  padding: 15px 20px;
  flex-flow: column;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  animation: fade-in-fwd 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  position: relative;
  z-index: 2;
  ${props => props.isFeedDetailView
    && `
      border: none;
      border-radius: 4px;
    `}
  @keyframes fade-in-fwd {
    0% {
      -webkit-transform: translateY(20px);
      transform: translateY(80px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  ${props => (props.isAcademyFeed || props.isCongratularyFeed) && `
    background: ${colors.feedHighlight};
  `}
`

const ImageContainer = styled.div`
  flex: 1 auto;
  margin-right: 10px;
  position: relative;

  a {
    text-decoration: none;
  }
`

const Info = styled.div`
  margin-right: 10px;
  flex: 1 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Bullet = styled.div`
  margin: 0 5px;
  height: 3px;
  display: inline-block;
  width: 3px;
  background: ${props => (props.color ? props.color : "#354052")};
  border-radius: 50%;
`

const AgentInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  font-weight: 300;
  color: ${colors.contentText};
`

const FeedInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Span = styled.span`
  color: #a5a8ad;
`

const FeedContent = styled.div`
  padding: 10px 0 15px 0px;
  margin: 0 20px;
  cursor: pointer;

  ${props => props.isStandAlone && `
    cursor: inherit;
  `}
`

const FeedStatus = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  left: 72px;
`

const CityTag = styled.div`
  font-size: 12px;
  color: #a5a8ad;
  border-radius: 4px;
  background-color: #f8f9fa;
  border: 1px solid #f8f9fa;
  text-transform: uppercase;
  padding: 3px;
  margin-right: 8px;
  margin-top: 8px;
`

const TagsList = styled.div`
  display: flex;
  flex-flow: row wrap;
`

const FeedImage = styled.img`
  padding: 15px 0;
  cursor: pointer;
  margin: 0px auto;
  display: block;
  width: 100%;
  height: 100%;
`

const FeedHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const ImageBox = styled.div`
  width: 300px;
  height: 400px;
  margin-right: 10px;
  background: #000c17;
  margin-top: 10px;
`

const FeedOptions = styled.div``

const DropdownIcon = styled.i`
  color: #5A5FF2;
  cursor: pointer;
  font-size: 30px;
  top: 10px;
  right: 15px;
  position: absolute;
`
const StyledBox = styled.div`
  font-size: 14px;
  color: ${colors.contentText};
  font-weight: 300;
  line-height: 1.75;
`

const StyledParagraph = styled(Paragraph)`
  font-size: 12px;
  line-height: 1.13;
  font-weight: 500;
  cursor: pointer;
  word-break: all;
  white-space: pre-line;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StyledTextBox = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  white-space: pre-wrap;
  line-height: 1.5;
  word-break: break-word;
  position: relative;
`

const LinkSpan = styled.a`
  color: ${colors.primaryColor};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const ImagePreviewBox = styled.div``

const AgencyName = styled.p`
  margin-bottom: 2px;
  font-weight: 300;
  font-size: 12px;
  max-width: ${props => props.maxWidth};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${colors.contentText};
  cursor: pointer;
`

const CityTagContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const VideoWrap = styled.video`

`
const ImageElement = styled.div`
  height:100%;
  width:100%;
  background-image: url(${props => props.img});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 20;
`
const feedItemTypeLabelMap = {
  post: {
    comment: "Comment",
    allowEdit: true,
  },
  question: {
    comment: "Answer",
    allowEdit: true,
  },
  intro: {
    comment: "Comment",
    allowEdit: false,
  },
}

const AgentInfoComponent = ({ agent, createdAt }) => (
  <AgentInfo size={12}>
    {agent.office_city ? agent.office_city : ""}
    {agent.office_state ? `, ${agent.office_state}` : ""}
    {(agent.office_state || agent.office_city) && <Bullet />}
    <Span>{`${distanceInWordsToNow(unixToDate(createdAt))} ago`}</Span>
  </AgentInfo>
)

const FeedItem = ({
  feed,
  feedsData,
  openFeedDetailView,
  isFeedDetailView,
  toggleEditFeedItemModal,
  openLikers,
  isLikingFeed,
  updateFeedLike,
  isLogged,
  followAgent,
  requestingAgentToFollow,
  unfollowAgent,
  agentsFollowees = "",
  togglePersonalizeFeedModal,
  updateAutoFocus,
  openImagePreviewModal,
  isStandAlone,
  reportOrDelete,
  isOnProfile,
  history,
  blockAndUnBlock,
  toggleReportModal,
  mutePost,
  mutedFeeds,
}) => {
  const {
    tags,
    agent = {},
    imageUrls,
    likedAgents: { agentsToDisplay, otherLikedAgents } = {},
    feed_url = "",
    feed_type,
    course,
    topComment,
    content_type,
    videos,
    id,
    snippet,
  } = feed
  const profileUrl = getProfileUrl(agent)

  const isMuted = feed.muted

  const triggerFeedDetailView = (selectedFeed, focus) => {
    openFeedDetailView({
      selectedFeed,
      feedSource: "networkFeeds",
      shouldAutoFocus: focus || false,
    })
  }

  const triggerImagePreviewModal = (url) => {
    openImagePreviewModal({
      url,
    })
  }

  const getShortLink = (text) => {
    if (text.length <= 37) {
      return text
    }
    return `${text.substring(0, 37)}...`
  }

  const handleCopy = () => {
    copyToClipboard(feed_url)
    createToast("Copied to Clipboard")
  }

  const handleClick = (isLogged, feed, isStandAlone, openFeedDetailView, ele) => {
    try {
      const clickPaths = get(ele, "nativeEvent.path")
      if (clickPaths && isArray(clickPaths) && clickPaths.find(x => x.className && x.className.includes("congratulatory-post-wrapper"))) {
        return
      }
    } catch (error) {
      // ignore error
      console.log(error);
    }
    if (isLogged) {
      root.localStorage.setItem("community-last-scroll", root.scrollY)
      triggerFeedDetailViewForComment(feed, true, openFeedDetailView)
    } else {
      handleLogin()
    }
    // Disable Auto focus for comment section
    // if (isStandAlone) {
    //   root.document.getElementById("standalone-feed-text-area").focus()
    // }
  }


  const handleLogin = () => {
    saveUserObjectToCookie({ redirectUrl: root.location.href })
    root.location.href = `${WEB_URL}login`
  }

  const triggerFeedDetailViewForComment = (selectedFeed, focus = false, openFeedDetailView) => {
    try {
      // const { openFeedDetailView, history } = this.props
      const agentName = get(selectedFeed, "agent.firstname")
      const urlTitle = selectedFeed.title && selectedFeed.title.substr(0, 50).replace(/%/g, '')
      const redirectURL = root.encodeURI(`/${agentName}/feed/${selectedFeed.id}/${urlTitle}`)
      openFeedDetailView({
        selectedFeed,
        feedSource: "networkFeeds",
        shouldAutoFocus: focus,
      })
      history.push(redirectURL)
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteFeed = (flag) => {
    reportOrDelete({
      feedId: id,
      isQuestion: flag,
    })
  }

  const handleMuting = (isPostMuted, feedId, mute) => {
    mute({
      isPostMuted,
      feedId,
    })
  }

  const handleChildClick = (e) => {
    e.stopPropagation()
  }

  const getCompleteJSX = (rawText) => {
    const splitLines = rawText.split("\n")
    const wholeJSX = []
    splitLines.forEach((text, outerIdx) => {
      const match = linkify.match(text)
      const parts = []
      let stringPointer = 0
      if (match) {
        match.forEach((matchItem, idx) => {
          const { index, lastIndex } = matchItem
          const textPart = convertUnicode(text.substring(stringPointer, index))
          parts.push(textPart)

          const linkPart = getShortLink(text.substring(index, lastIndex))
          const linkJSX = (
            <LinkSpan key={`link-${idx}`} href={matchItem.url} target="_blank">
              {linkPart}
            </LinkSpan>
          )
          parts.push(linkJSX)
          stringPointer = lastIndex
        })
      }
      const textPart = convertUnicode(text.substring(stringPointer, text.length))
      parts.push(textPart || <br key={`br-${outerIdx}`} />)
      wholeJSX.push(<div key={`div-${outerIdx}`}>{parts}</div>)
    })
    return wholeJSX
  }

  const toRender = getCompleteJSX(feed.title || "")
  const isAcademyFeed = (feed_type === "academy" || feed.type === "referral")
  return (
    <React.Fragment>
      <FeedItemWrapper
        isFeedDetailView={isFeedDetailView}
        isAcademyFeed={isAcademyFeed}
        isStandAlone={isStandAlone}
      >
        <FeedHeader>
          <TagsList>
            {tags && tags.slice(0, 2).map(tag => <RoundedTag key={`${tag.id}${feed.id}`}>{tag.title}</RoundedTag>)}
          </TagsList>
          {(
            isLogged
            && (
              feed.feed_type === "post" || feed.feed_type === "question" || feed.feed_type === "academy" || feed.feed_type === "referral"
            )) && (
            <FeedOptions>
              {/* <i className={icons.genericBookmark} /> */}
              <DropdownMenu overlay={<DropdownIcon className={icons.genericMoreMenu} />}>
                {user
                && user.id === agent.id
                && feedItemTypeLabelMap[feed.type]
                && feedItemTypeLabelMap[feed.type].allowEdit && (
                  <li onClick={toggleEditFeedItemModal}>
                    <Box flex="1 auto" w="30">
                      <i className={icons.genericEdit} />
                    </Box>
                    <Box flex="1 100%">
                      { `Edit this ${feed.type}`}
                    </Box>
                  </li>
                )}
                {/* <li onClick={() => togglePersonalizeFeedModal({ show: true })}>
                  <Box flex="1 auto" w="30">
                    <i className={icons.genericCopy} />
                  </Box>
                  <Box flex="1 100%">Personalize feed</Box>
                </li> */}
                <li onClick={() => handleCopy()}>
                  <Box flex="1 auto" w="30">
                    <i className={icons.filesCopyToClipboard} />
                  </Box>
                  <Box flex="1 100%">Copy to Clipboard</Box>
                </li>

                {isLogged && (mutedFeeds && mutedFeeds.indexOf(feed.id)) <= -1 && isMuted === 0 && (
                  <li onClick={() => handleMuting(false, feed.id, mutePost)}>
                    <Box flex="1 auto" w="30" c="#f96d72">
                      <i className={icons.genericClose} />
                    </Box>
                    <Box flex="1 100%" c="#f96d72">Mute Post</Box>
                  </li>
                )}

                {isLogged && ((mutedFeeds && mutedFeeds.indexOf(feed.id) > -1) || isMuted === 1) && (
                  <li onClick={() => handleMuting(true, feed.id, mutePost)}>
                    <Box flex="1 auto" w="30">
                      <i className={icons.genericClose} />
                    </Box>
                    <Box flex="1 100%">Unmute Post</Box>
                  </li>
                )}

                {user && user.id === agent.id ? (
                  <li onClick={() => handleDeleteFeed(feed.feed_type === "question")}>
                    <Box flex="1 auto" w="30" c="#f96d72">
                      <i className={icons.genericClose} />
                    </Box>
                    <Box flex="1 100%" c="#f96d72">
                      Delete this post
                    </Box>
                  </li>
                ) : (
                  <React.Fragment>
                    {(feed.feed_type === "post" || feed.feed_type === "question") && !isAcademyFeed && (
                      <React.Fragment>
                        <li onClick={() => toggleReportModal(feed.id)}>
                          <Box flex="1 auto" w="30" c="#f96d72">
                            <i className={icons.genericClose} />
                          </Box>
                          <Box flex="1 100%" c="#f96d72">
                            Report this post
                          </Box>
                      </li>
                      <li onClick={() => blockAndUnBlock({ payload: { agent_id: agent.id, is_blocked: 1 }, feedId: feed.id })}>
                          <Box flex="1 auto" w="30" c="#f96d72">
                            <i className={icons.genericClose} />
                          </Box>
                          <Box flex="1 100%" c="#f96d72">
                            Block User
                          </Box>
                      </li>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </DropdownMenu>
            </FeedOptions>
          )}
        </FeedHeader>
        <BoxHolder bgColor="transparent" border="none" h="auto" p="5px 0">
          <ImageContainer>
            <div>
              <Popover
                position="top"
                bottom={70}
                left={0}
                hoverDelay={1000}
                component={(
                  <Box d="flex" ai="center">
                    <CircularImage
                      image={agent.agent_image}
                      size={60}
                      name={`${agent.firstname || ""} ${agent.lastname || ""}`}
                      style={{ position: "relative", cursor: "pointer" }}
                      onClick={() => {
                        history.push(profileUrl)
                      }}
                    />
                    <Info zi="9999" style={{ flex: 1, marginLeft: 20 }}>
                      <StyledLink to={profileUrl}>
                        <StyledParagraph>{`${agent.firstname} ${agent.lastname ? agent.lastname : ""}`}</StyledParagraph>
                      </StyledLink>
                      {isAcademyFeed ? (
                        <AcademyInstructors
                          {...feed}
                          contentType={feed.content_type}
                        />
                      ) : (
                        <StyledLink to={profileUrl}>
                          <AgencyName maxWidth="345px">{agent.agency_name}</AgencyName>
                        </StyledLink>
                      )}
                      {!isAcademyFeed && (
                        <StyledLink to={profileUrl}>
                          <AgentInfoComponent
                            agent={agent}
                            createdAt={feed.created_at}
                          />
                        </StyledLink>
                      )}
                    </Info>
                  </Box>
                )}
                hideTooltip={(user && user.id === agent.id) || isStandAlone}
              >
                <React.Fragment>
                  <BoxHolder
                    bgColor="transparent"
                    border="none"
                    h="120px"
                    p="10px"
                    minWidth={200}
                    alignItems="flex-start"
                  >
                    <ImageContainer>
                      <Link to={profileUrl}>
                        <CircularImage
                          image={agent.agent_image}
                          size={50}
                          name={`${agent.firstname || ""} ${agent.lastname || ""}`}
                          fontSize="28px"
                        />
                      </Link>
                    </ImageContainer>
                    <Info>
                      <StyledLink to={profileUrl}>
                        <Paragraph size={12} lineHeight={1.5} fontWeight={500}>
                          {`${agent.firstname} ${agent.lastname ? agent.lastname : ""}`}
                        </Paragraph>
                        <AgencyName maxWidth="270px">{agent.agency_name}</AgencyName>
                      </StyledLink>
                      {/* <AgentInfoComponent
                        agent={agent}
                        createdAt={feed.created_at}
                      /> */}
                      {user.id !== agent.id && (
                        <div>
                          {agentsFollowees.indexOf(agent.id) > -1 ? (
                            <Button
                              bsStyle="primary"
                              width="auto"
                              fontSize="12"
                              padding="2px 8px"
                              margin="10px 0 0 0"
                              onClick={() => unfollowAgent(agent.id)}
                            >
                              {requestingAgentToFollow === agent.id ? "Unfollowing..." : "Following"}
                            </Button>
                          ) : (
                            <Button
                              bsStyle="secondary"
                              width="auto"
                              fontSize="12"
                              padding="2px 8px"
                              margin="10px 0 0 0"
                              onClick={() => followAgent({ agent, shouldFollowOnly: true })}
                            >
                              {requestingAgentToFollow === agent.id ? "Following..." : "Follow"}
                            </Button>
                          )}
                        </div>
                      )}
                    </Info>
                  </BoxHolder>
                </React.Fragment>
              </Popover>
            </div>
          </ImageContainer>
        </BoxHolder>
        <FeedContent
          onClick={(ele) => handleClick(isLogged, feed, isStandAlone, openFeedDetailView, ele)}
          isStandAlone={isStandAlone}
        >
          {feed.title && feed.feed_type !== "academy" && feed.content_type !== "course" && (
            <SeeMoreTextWrapper
              to={`/${agent.firstname}/feed/${id}/#post`}
              isEnabled={!isStandAlone}
              height={84}
              contentId={`feed-content-${feed.id}`}
            >
              <StyledTextBox
                id={`feed-content-${feed.id}`}
                key={`feed-content-${feed.id}`}
                onClick={e => handleChildClick(e)}
              >
                {toRender}
              </StyledTextBox>
            </SeeMoreTextWrapper>
          )}

          { feed.feed_type === "referral" && feed.content_type === "referral_closed" && (
            <CongratulatorFeed
              {...feed.receiver_agent}
            />
          )}
          {imageUrls && imageUrls.length > 0 && (
            // <ImagePreviewBox>
              // {imageUrls.map(img => (
              //   <FeedImage
              //     onClick={() => triggerImagePreviewModal(img)}
              //     src={img}
              //     alt="radius agent community feed"
              //   />
              // ))}
            // </ImagePreviewBox>

            <Slider {...SLIDER_SETTINGS}>
              {imageUrls.map(img => (
                <ImageBox
                onClick = {(ele) => ele.stopPropagation()}
                >
                  <ImageElement
                      img={img}
                      onClick={() => triggerImagePreviewModal(img)}
                  >
                  </ImageElement>

                  {/*<FeedImage*/}
                  {/*  */}
                  {/*  src={img}*/}
                  {/*  alt="radius agent community feed"*/}
                  {/*/>*/}
                </ImageBox>
              ))}
            </Slider>
          )}

          {feed_type === "academy" && feed.content_type === "course" && (
            <CourseFeed
              {...course}
            />
          )}

          {feed_type === "academy"
          && (
            feed.content_type === "event_feedback"
            || feed.content_type === "event"
          ) && (
            <CommunityEvent
              contentType={content_type}
              {...convertToRichText(feed.event)}
            />
          )}

          {/* Snippet Support */}
          {feed.snippet && <Snippet shared snippet={feed.snippet} />}

          {/* Intro Post */}
          {feed.type === "intro"
            ? feed.introLocations
              ? (
                <React.Fragment>
                  <StyledTextBox>Here are the cities I serve:</StyledTextBox>
                  <CityTagContainer>
                    {feed.introLocations.map(cityTag => <CityTag key={cityTag}>{cityTag}</CityTag>)}
                  </CityTagContainer>
                </React.Fragment>
              )
              : null
            : <CityTagContainer><CityTag>{feed.city}</CityTag></CityTagContainer>
          }

          {content_type === "video" && videos && videos.length > 0 && (
            <VideoFeed videos={videos} id={id} />
          )}

          {!isStandAlone && topComment && (
            <TopComment {...topComment} />
          )}
        </FeedContent>
        <FeedStatus>
          <FeedInfo size={12} color="#a5a8ad">
            <StyledBox>
              <span style={{ cursor: "pointer" }} onClick={() => openLikers({ id: feed.id, likersViewType: feed.type })}>
                {feed.likesCount
                  ? `${feed.likesCount} Like${feed.likesCount > 1 ? "s" : ""}`
                  : null}
              </span>
              {/* {feed.likesCount && feed.commentsCount ? <Bullet color="#a5a8ad" /> : null} */}
              {/* <span style={{ cursor: "pointer" }} onClick={() => triggerFeedDetailView(feed, true)}>
                {feed.commentsCount
                  ? `${feed.commentsCount} ${feedItemTypeLabelMap[feed.type].comment}${
                    feed.commentsCount > 1 ? "s" : ""
                  }`
                  : null}
              </span> */}
            </StyledBox>
          </FeedInfo>
          {/* <div onClick={() => openLikers({ id: feed.id, likersViewType: "feed" })}>
            <LikedAgents
              agentsToDisplay={agentsToDisplay}
              otherLikedAgents={otherLikedAgents && otherLikedAgents.length}
            />
          </div> */}
        </FeedStatus>
      </FeedItemWrapper>
      <FeedAction
        feed={feed}
        openFeedDetailView={openFeedDetailView}
        isFeedDetailView={isFeedDetailView}
        isLikingFeed={isLikingFeed}
        feedsData={feedsData}
        updateFeedLike={updateFeedLike}
        isLogged={isLogged}
        updateAutoFocus={updateAutoFocus}
        isAcademyFeed={isAcademyFeed}
        isStandAlone={isStandAlone}
        isOnProfile={isOnProfile}
      />
    </React.Fragment>
  )
}

FeedItem.defaultProps = {
  isLogged: true,
}

export default withRouter(FeedItem)
