import React, { PureComponent } from "react"
import styled from "styled-components"
import { ATC } from "@fonts"
import { get, isEmpty } from "lodash"
import SearchContainer from "container/Search/SearchContainer"
import TechAssetsContainer from "container/TechAssets"
import TCContainer from "container/Realty/TransactionFormContainer"
import root, { setTimeout } from "window-or-global"
import ListingList from "dumbComponents/Listings/components/ListingList"
import colors from "@colors"
import Loader from "@ui/Loader"
import { withRouter } from "react-router-dom"
import cookie from "services/CookieStorage"
import queryString from "query-string"
import PlusIcon from "images/icons/WhiteIconPlus.png"
// import GoogleContacts from "react-google-contacts"
// import { GoogleLogin } from 'react-google-login'
// import { GoogleLogin, GoogleLogout } from "react-google-login"
// import { useGoogleLogin } from "@react-oauth/google"
import { PlusOutlined, PlusCircleOutlined, LoadingOutlined } from "@ant-design/icons"
// import { Form } from "@ant-design/compatible"
// import "@ant-design/compatible/assets/index.css"
// import { gapi } from "gapi-script"
import { capitalizeFirstLetter } from "services/Utils"
import US_STATES from "shared/US_STATES.json"
import dynamic from "next/dynamic"
const loadGapi = dynamic(() => import("gapi-script").then(mod => mod.loadGapiInsideDOM), { ssr: false })

import {
  Input, InputNumber, Select, Checkbox, Form,
  AutoComplete,
  Divider,
  Button,
  GoogleAutoComplete,
  Spin,
} from "@ui/antd"
// import "antd/dist/antd.css"
// try {
//   const [form] = Form.useFormInstance()
// } catch(err){
//   console.log("eerorroro====", err)
// }

const Wrap = styled.div`
  margin-top: 52px;
  ${props => props.mt && `
    margin-top: ${props.mt};
  `}
  background: #F9F9F7;
  padding: 24px;
  min-height: 400px;
`

const HeaderNav = styled.div`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: ${colors.black};
`

const ProgressBarContainer = styled.div`
  padding: 30px 0px;
  display: flex;
`

const ProgressBar = styled.div`
  background: #E5E5E5;
  ${props => (props.completed || props.ongoing) && `
    background: #5A5FF2;
  `}
  height: 6px;
  margin: 0 4px;
  width: 100%;
`

const Container = styled.div`
  margin-top: 20px;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 70%;
`

const LabelText = styled.h2`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
  color: ${colors.black};
`

const Required = styled.span`
  color: #E34C4C
`

const CheckboxLabelText = styled.h2`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  color: ${colors.black};
  text-transform: uppercase;
`

const FlexBox = styled.div`
  display: flex;
`

const CheckboxContainer = styled.div`
  padding: 0 20px 0 0;
`

const StyledDivider = styled(Divider)`
  min-width: 180px !important;
  width: 180px !important;
  margin: 18px 0 !important;
`

const AddForm = styled.div`
  padding: 0 0 34px 0;
  text-align: right;
`

const StyledSpin = styled(Spin)`
  width: 100%;
`
const STATE_OPTIONS = Object.keys(US_STATES).map(key => ({ label: US_STATES[key], value: US_STATES[key] }))

const CLIENT_FILE_MAP = {
  seller: ["listing_with_accepted_offer", "dual", "external_referral_agreement"],
  buyer: ["pending_contract", "dual", "external_referral_agreement"],
  landlord: ["lease_listing", "external_referral_agreement"],
  tenant: ["pending_lease", "external_referral_agreement"],
  dual: ["dual"],
  other: ["external_referral_agreement"],
}

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)
// var clientId = '180520004723-ja501s6hkq27puptbn6oa20ldi47jt0d.apps.googleusercontent.com';
// var apiKey = 'AIzaSyC75MR5jnct4TwoKolQ06TJV6MPJcQrgIk';
// var scopes = 'https://www.googleapis.com/auth/contacts.readonly';

class BasicInfo extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      contacts: [],
      showCoListingform: [1],
      cityFormValue: {},
      agentValues: [],
      comingFromListings: false,
      representingSelected: null,
      comingFromOffers: false,
    }
  }

  formRef = React.createRef();

  componentDidMount() {
    const initializeGapi = async () => {
      try {
        await loadGapi()
        // Initialize gapi client
        window.gapi.load("client:auth2", this.fetchGoogleContacts)
      } catch (e) {
        //
      }
    }
    initializeGapi()
    const {
      getContractById, dealToView, match, history, setDealToView,
      getAllListingsResponse,
      setCurrentContractID,
    } = this.props
    let dealId = dealToView
    const contractOnly = 1
    const {
      location,
    } = history
    const queryParams = queryString.parse(location.search)
    const isListing = location.pathname.indexOf("/listings/coversheet/") > -1

    const {
      dealId: deal,
      offerId,
      status,
    } = queryParams

    console.log("getAllListingsResponse", getAllListingsResponse)

    const {
      isFetching: fetchingAllListings,
      data: allListings,
    } = getAllListingsResponse || {}

    //PATCH FIX FOR LISTINGS ISSUE
    const currentContractID = !offerId && this.getContractId(allListings, dealToView)

    if (deal && status === "listing_pending") {
      dealId = parseInt(deal, 10)
      this.setState({
        comingFromListings: true,
        comingFromOffers: false,
      })
    } else if (offerId) {
      dealId = parseInt(offerId, 10)
      this.setState({
        comingFromListings: false,
        comingFromOffers: true,
      })
    }

    // if (history?.location?.search) {
    //   const queryArr = history?.location?.search.split("?deal=")
    //   // dealId = queryArr[1] ? parseInt(queryArr[1], 10) : null
    //   if (queryArr[1] && queryArr[1].indexOf("deal") > -1) {

    //   } else if (queryArr[1] && queryArr[1].indexOf("offer") > -1) {
    //     dealId = parseInt(queryArr[1], 10)
    //     this.setState({
    //       comingFromOffers: true,
    //       comingFromListings: false,
    //     })
    //     console.log("offers block")
    //   }
    //   // contractOnly = 0
    // }

    if (dealId) {
      if (offerId) {
        setDealToView(dealId)
        getContractById({
          offerId: dealId,
        })
      } else if(isListing) {
        getContractById({
          listingId: dealToView,
          transactionType: "Listing",
        })
      } else {
        if(currentContractID) {
          setCurrentContractID(currentContractID)
        }
        getContractById({
          dealId: currentContractID || dealToView,
          transactionType: "Contract",
        })
      }
    }
  }

  getContractId = (data, id) => {
    if(!data || data === undefined || data === null) return ""

    const filtered = data.filter(item => item.id === parseInt(id, 10))

    if(filtered && filtered.length > 0) {
      return filtered[0].active_contract_id
    }

    return ""
  }

  formatLable = (value) => {
    // capitalizeFirstLetter
    const label = value.split("_")
    const capitalizeFirstLetterLabel = label.map(lb => capitalizeFirstLetter(lb))
    return capitalizeFirstLetterLabel.join(" ")
  }

  setRepresentingSelected = (value, manuallyChanged) => {
    this.setState({
      representingSelected: value,
    })
    if (manuallyChanged) {
      this.formRef.current.setFieldsValue({
        "file_type-0": null,
      })
    }
  }

  // login = () => {
  //   useGoogleLogin({
  //     onSuccess: codeResponse => console.log("codeResponse", codeResponse),
  //     flow: "auth-code",
  //   })
  // }

  // fetchGoogleContacts = () => {
  //   console.log("==TEST===11")
  //   gapi.client.init({
  //     apiKey: "AIzaSyC75MR5jnct4TwoKolQ06TJV6MPJcQrgIk",
  //     clientId: "180520004723-ja501s6hkq27puptbn6oa20ldi47jt0d.apps.googleusercontent.com",
  //     scope: "https://www.googleapis.com/auth/contacts.readonly",
  //     discoveryDocs: ["https://people.googleapis.com/$discovery/rest"],
  //   }).then(() => {
  //     console.log("==TEST===2")
  //     // return gapi.client.people.people.connections.list({
  //     //   resourceName: "people/me",
  //     //   pageSize: 10,
  //     //   personFields: "names,emailAddresses",
  //     // })
  //     const tempToken = cookie.load("google_access_token")
  //     return gapi.client.request({
  //       path: "https://content-people.googleapis.com/v1/people/me/connections?pageSize=2000&personFields=names%2CemailAddresses,phoneNumbers&sortOrder=FIRST_NAME_ASCENDING",
  //       headers: {
  //         Authorization: `Bearer ${tempToken}`,
  //       },
  //     })
  //   }).then((response) => {
  //     console.log("==TEST===3")
  //     if (response.result?.connections) {
  //       this.setState({
  //         contacts: response.result?.connections,
  //       })
  //     }
  //     console.log(response.result)
  //   }, (reason) => {
  //     console.log("==TEST===5")
  //     console.log(`Error: ${reason.result.error.message}`)
  //   })
  // }

  getOptions = () => {
    const { contacts } = this.state
    console.log("==contacts===", contacts)
    return contacts && contacts.length ? contacts.filter(ct => ct.names).map((ct, index) => ({ label: ct.names[0].displayName, value: ct.names[0].displayName })) : []
  }

  addColistingAgent = () => {
    const { showCoListingform } = this.state
    const colisting = showCoListingform
    colisting.push(showCoListingform.length + 1)
    this.setState({ showCoListingform: [...colisting] })
    setTimeout(() => {
      root.scrollTo(0, 3100)
    }, 300)
  }

  searchAgentDetails = (searchValue) => {
    const { getSearchedAgents } = this.props
    let timeout = 0
    if (timeout) {
      clearTimeout(timeout)
      timeout = 0
    }
    const callSearch = () => {
      getSearchedAgents({
        isInitial: true,
        currentPageNumber: 1,
        queryParams: {
          text_value: searchValue,
          unRegistered: false,
        },
      })
    }

    timeout = setTimeout(callSearch, 500)
  }

  getNextRoute = (isDraft) => {
    const { isFromDirectForm, mainPageRoute, nextFormRoute } = this.props
    if (!isFromDirectForm) {
      return isDraft ? "/realty/transaction-coordination" : "/realty/transaction-coordination/contract/client-info"
    }
    return isDraft ? mainPageRoute : nextFormRoute
  }

  onFinish = (fieldValues, isDraft) => {
    const {
      createContract, history, getListingCoverSheet, dealToView, updateContract, updateDealStatus,
      getContractByIdResponse, setDealToView, saveListingStepsResponse,
    } = this.props
    const { cityFormValue, comingFromOffers, comingFromListings } = this.state
    let agentInfo
    if (comingFromOffers) {
      agentInfo = getContractByIdResponse?.data
    } else {
      agentInfo = getContractByIdResponse?.data && Array.isArray(getContractByIdResponse.data) && getContractByIdResponse.data.length > 0
        ? getContractByIdResponse.data[0]
        : ""
    }
    const user = cookie.load("user") || {}
    Object.keys(fieldValues).forEach((key) => {
      if (fieldValues[key] === undefined) {
        // eslint-disable-next-line no-param-reassign
        fieldValues[key] = null
      }
    })
    const localFieldValues = {
      ...fieldValues,
    }
    const payload = []
    Object.keys(localFieldValues).forEach((fvKey) => {
      const index = parseInt(fvKey.split("-")[1], 10)
      const keyName = fvKey.split("-")[0]
      if (!payload[index]) {
        payload[index] = {
          // is_primary: index === 0 ? "Y" : "N",
          is_primary: index === 0 ? 1 : 0,
          agent_id: user.id,
        }
      }
      payload[index] = {
        ...payload[index],
        [keyName]: localFieldValues[fvKey],
      }
    })
    if (agentInfo?.deal_id) { //is a valid contract deal
      setDealToView(agentInfo.deal_id)
      updateContract({
        payload: {
          ...payload[0],
          current_contract_step: "basic_info",
          saveDraft: isDraft ? 1 : 0,
        },
        agent_id: agentInfo.agent_id,
        dealId: agentInfo.deal_id,
        nextRoute: this.getNextRoute(isDraft),
        history,
      })
    } else if (comingFromOffers && !agentInfo?.deal_id) { //its coming from offers -> and no deal id
      createContract({
        offerId: dealToView || null,
        payload: {
          ...payload[0],
          current_contract_step: "basic_info",
          saveDraft: isDraft ? 1 : 0,
        },
        callAPI: ["agent_info", "seller_info"],
        nextRoute: this.getNextRoute(isDraft),
        history,
        callback: (dealIdToSend) => {
          setDealToView(dealIdToSend)
          updateDealStatus({
            dealId: dealIdToSend,
            step: "incomplete",
          })
        },
      })
    } else if (comingFromListings) { //coming from listings -> basically changed to pending status
      setDealToView(agentInfo.id)
      updateContract({
        payload: {
          ...payload[0],
          current_contract_step: "basic_info",
          saveDraft: isDraft ? 1 : 0,
        },
        dealId: agentInfo.id,
        nextRoute: this.getNextRoute(isDraft),
        history,
      })
    } else if (dealToView && !comingFromListings && !comingFromOffers) { //opened from listing
      updateContract({
        payload: {
          ...payload[0],
          current_contract_step: "basic_info",
          saveDraft: isDraft ? 1 : 0,
        },
        dealId: dealToView,
        nextRoute: this.getNextRoute(isDraft),
        history,
      })
    } else { // new contract
      createContract({
        offerId: comingFromOffers || null,
        payload: {
          ...payload[0],
          current_contract_step: "basic_info",
          saveDraft: isDraft ? 1 : 0,
        },
        callAPI: ["agent_info", "seller_info"],
        nextRoute: this.getNextRoute(isDraft),
        history,
        callback: (dealIdToSend) => {
          setDealToView(dealIdToSend)
          updateDealStatus({
            dealId: dealIdToSend,
            step: "incomplete",
          })
        },
      })
    }
  }

  onFinishFailed = () => {
    // this.formRef.current.setFieldsValue({
    //   "name-0": "Hello world!",
    // })
    // alert("onFinishFailed")
  }

  changeOtherFieldsValues = (objString, index) => {
    const { agents } = this.props
    const obj = objString.split("::")
    const agent = agents.find(ag => ag.id === parseInt(obj[0], 10))
    this.formRef.current.setFieldsValue({
      [`agent_id-${index}`]: parseInt(obj[0], 10),
      [`name-${index}`]: obj[1],
      [`email-${index}`]: agent.email,
      [`phone-${index}`]: agent.phone,
    })
  }

  setCities = (location, index) => {
    const { cityFormValue } = this.state
    const locationDetails = {
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
    }
    this.formRef.current.setFieldsValue({
      [`city-${index}`]: `${locationDetails.city || ""}`,
      [`state-${index}`]: `${locationDetails.state || ""}`,
      [`country-${index}`]: `${locationDetails.country}`,
    })
    this.setState({
      cityFormValue: {
        ...cityFormValue,
        [`city-${index}`]: `${locationDetails.city}`,
      },
    })
  }

  setLocaCity = (value, cityFormName) => {
    const { cityFormValue } = this.state
    this.setState({
      cityFormValue: {
        ...cityFormValue,
        [cityFormName]: value,
      },
    })
  }

  onSaveAsDraft = (values) => {
    this.onFinish(this.formRef.current.getFieldsValue(), true)
    // this.formRef.current.setFieldsValue({
    //   "name-0": "Hello world!",
    // })
    // alert("onFinishFailed")
  }

  getInitialValues = () => {
    const { getContractByIdResponse } = this.props
    const {
      cityFormValue,
      showCoListingform,
      comingFromListings,
      representingSelected,
      comingFromOffers,
    } = this.state
    const agentInfo = comingFromOffers ? getContractByIdResponse?.data : getContractByIdResponse.data?.[0]
    const localShowCoListingform = showCoListingform
    console.log(agentInfo)
    const { client_type } = agentInfo || {}
    if (!isEmpty(agentInfo)) {
      this.setRepresentingSelected(client_type)
    }
    const result = {}
    if (agentInfo && !comingFromOffers) {
      const agentInformation = agentInfo.id ? [agentInfo] : agentInfo
      agentInformation.forEach((info, index) => {
        Object.keys(info).forEach((key) => {
          result[`${key}-${index}`] = info[key]
          if (comingFromListings && key === "client_type") {
            // result[`${key}-${index}`] = "seller"
            if (!representingSelected) {
              this.setRepresentingSelected("seller")
            }
          }
          if (comingFromListings && key === "file_type") {
            // result[`${key}-${index}`] = "listing_with_accepted_offer"
          }
          if (!comingFromListings && key === "file_type" && !representingSelected && info[key]) {
            const fileType = CLIENT_FILE_MAP[result[`client_type-${index}`]]
            this.setRepresentingSelected(result[`client_type-${index}`])
            if (!(fileType && fileType.includes(result[`${key}-${index}`]))) {
              result[`${key}-${index}`] = null
            }
          }
        })
        // need to check this logic
        // if (index > 0) localShowCoListingform.push(index + 1)
      })
    } else if (comingFromOffers) {
      result["listing_address-0"] = agentInfo.listing_address
      result["house_number-0"] = agentInfo.house_number
      result["city-0"] = agentInfo.city
      result["state-0"] = agentInfo.state
      result["zipcode-0"] = agentInfo.zipcode
      result["client_type-0"] = agentInfo.client_type
      this.setRepresentingSelected("buyer")
      result["file_type-0"] = agentInfo.file_type
    }
    return result
  }

  setStreet = (location) => {
    const locationDetails = {
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
    }
    let neighborhood = ""
    let zipcode = ""
    let county = ""
    if (location.street_number) {
      neighborhood += `${location.street_number.long_name} `
    }
    if (location.route) {
      neighborhood += location.route.long_name
    }
    if (location.postal_code) {
      zipcode = location.postal_code.long_name
    }
    if (locationDetails.county) {
      locationDetails.county = locationDetails.county.split(" County")?.[0]
      county = locationDetails.county
    }
    console.log(neighborhood)
    this.formRef.current.setFieldsValue({
      "city-0": `${locationDetails.city || ""}`,
      "state-0": `${locationDetails.state || ""}`,
      country: `${locationDetails.country}`,
      "zipcode-0": zipcode,
      "county-0": county,
    })
    if (neighborhood) {
      this.formRef.current.setFieldsValue({
        "listing_address-0": neighborhood || "",
      })
    }
  }

  getIsEdit = deal => deal && !(deal.step === "commission_verified" || deal.step === "closed" || deal.step === "payment_received")

  render() {
    const { showCoListingform, cityFormValue, representingSelected } = this.state
    const {
      history, createContractResponse, updateContractResponse, getContractByIdResponse,
      form, agents, isFetchingAgents, loaderOnAgentInfo, isFromDirectForm,
    } = this.props
    const commonRules = [
      {
        required: true,
        message: "Required",
      },
    ]
    // console.log("createListingCoverSheetResponse.isFetching || updateListingCoverSheetResponse.isFetching || getListingCoverSheetResponse.isFetching----", createListingCoverSheetResponse.isFetching, updateListingCoverSheetResponse.isFetching, getListingCoverSheetResponse.isFetching)
    return (
      <Wrap mt={isFromDirectForm ? "0px" : "52px"}>
        {!isFromDirectForm && (
          <HeaderNav>
            Basic Info
          </HeaderNav>
        )}
        <Container>
          {(getContractByIdResponse.isFetching) ? (<Loader />) : (
            <Form
              name="contract_agent_information"
              preserve
              colon={false}
              layout="vertical"
              requiredMark={false}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
              initialValues={this.getInitialValues()}
              ref={this.formRef}
              disabled={getContractByIdResponse?.data?.[0] ? !this.getIsEdit(getContractByIdResponse?.data?.[0]) : false}
            >
              {/* colisting */}
              {showCoListingform.map((lst, index) => (
                <>
                  {index > 0 && (<Divider />)}
                  <FlexBox>
                    <Form.Item name={`listing_address-${index}`} label={<LabelComponent text="Property Street Address" required />} rules={commonRules}>
                      {/* <Input /> */}
                      <GoogleAutoComplete
                        id="cities"
                        types={["address"]}
                        name="cities"
                        // formValue={cityFormValue}
                        onChange={(e) => { }}
                        location={(loc) => { this.setStreet(loc) }}
                        placeholder=""
                        styles={{
                          height: "49px",
                          borderRadius: "0px",
                          background: "white",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                        restrict={{
                          lat: 37.09024,
                          lng: -95.712891,
                          south_west: {
                            lat: 25.82,
                            lng: -124.39,
                          },
                          north_east: {
                            lat: 49.38,
                            lng: -66.94,
                          },
                          address: "United States",
                          country: {
                            long_name: "United States",
                            short_name: "US",
                            types: [
                              "country",
                              "political",
                            ],
                          },
                        }}
                      />
                    </Form.Item>

                    <Form.Item name={`house_number-${index}`} label={<LabelComponent text="Apt/Unit/Suite No." />}>
                      <Input />
                    </Form.Item>
                  </FlexBox>
                  <FlexBox>
                    <Form.Item name={`city-${index}`} label={<LabelComponent text="City" required />} rules={commonRules}>
                      <GoogleAutoComplete
                        id="cities"
                        types={["(cities)"]}
                        name="cities"
                        // formValue={cityFormValue[`city-${index}`]}
                        onChange={(e) => { this.setLocaCity(e.target.value, `city-${index}`) }}
                        location={(loc) => { this.setCities(loc, index) }}
                        placeholder=""
                        styles={{
                          height: "49px",
                          borderRadius: "0px",
                          background: "white",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                        restrict={{
                          lat: 37.09024,
                          lng: -95.712891,
                          south_west: {
                            lat: 25.82,
                            lng: -124.39,
                          },
                          north_east: {
                            lat: 49.38,
                            lng: -66.94,
                          },
                          address: "United States",
                          country: {
                            long_name: "United States",
                            short_name: "US",
                            types: [
                              "country",
                              "political",
                            ],
                          },
                        }}
                      />
                    </Form.Item>

                    <Form.Item name={`state-${index}`} label={<LabelComponent text="State" required />} rules={commonRules}>
                      <Select>
                        {/* <Input /> */}
                        {STATE_OPTIONS.map(st => (
                          <Select.Option value={st.value}>{st.label}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name={`zipcode-${index}`}
                      label={<LabelComponent text="Zip Code" required />}
                      rules={[
                        {
                          required: true,
                          message: "Invalid Input",
                          type: "string",
                          pattern: new RegExp(/^\d{5,}$/),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </FlexBox>
                  <Form.Item name={`client_type-${index}`} label={<LabelComponent text="Representing" required />} rules={commonRules}>
                    <Select onChange={(value) => { this.setRepresentingSelected(value, true) }}>
                      <Select.Option value="seller">Seller</Select.Option>
                      <Select.Option value="buyer">Buyer</Select.Option>
                      <Select.Option value="landlord">Landlord</Select.Option>
                      <Select.Option value="tenant">Tenant</Select.Option>
                      <Select.Option value="dual">Dual</Select.Option>
                      <Select.Option value="other">Other</Select.Option>
                    </Select>
                  </Form.Item>
                  {representingSelected && (
                    <Form.Item name={`file_type-${index}`} label={<LabelComponent text="This file is a" required />} rules={commonRules}>
                      <Select>
                        {CLIENT_FILE_MAP[representingSelected].map(fileMap => (
                          <Select.Option value={fileMap}>{this.formatLable(fileMap)}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </>
              ))}
              <FlexBox>
                <FlexBox wd="100%">
                  <Form.Item>
                  </Form.Item>
                </FlexBox>
                <Form.Item>
                  {!getContractByIdResponse?.data?.[0] || (getContractByIdResponse?.data?.[0] && this.getIsEdit(getContractByIdResponse?.data?.[0])) ? (
                    <Button
                      style={{
                        width: 119,
                        height: 48,
                        background: "#5A5FF2",
                        float: "right",
                        borderColor: "white",
                      }}
                      type="primary"
                      shape="round"
                      size="large"
                      loading={getContractByIdResponse.isFetching === true || updateContractResponse.isFetching === true || createContractResponse.isFetching === true}
                      htmlType="submit"
                    // onClick={() => { history.push("/realty/listings/coversheet/seller-info") }}
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      style={{
                        width: 119,
                        height: 48,
                        background: "#5A5FF2",
                        float: "right",
                        borderColor: "white",
                      }}
                      type="primary"
                      shape="round"
                      size="large"
                      disabled={false}
                      onClick={() => { history.push("/realty/transaction-coordination/contract/client-info") }}
                    >
                      Next
                    </Button>
                  )}
                </Form.Item>
              </FlexBox>
            </Form>
          )}
        </Container>
      </Wrap>
    )
  }
}
// const WrappedCoverSheet = Form.create()(CoverSheet)
export default TCContainer(withRouter(SearchContainer(TechAssetsContainer(BasicInfo))))
