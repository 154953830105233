import React, { useRef, useState , useEffect } from "react"
import {
  Form,
} from "@ui/antd"
import {
  InputField,
  InputNumber,
  InputLocation,
  AgentSearch,
  HiddenInputField,
} from "dumbComponents/common/InputFields"
import { AddButton } from "dumbComponents/OffersV3/components"
import { normalizePhone } from "services/formUtils"
import {
  Wrap,
  TabsWrap,
  TabsInfo,
  TabsTitle,
} from "dumbComponents/OffersV3/CoverSheet/Components/MLSTabs/styles"
import { StyledCheckbox } from "dumbComponents/OffersV2/DocusignDocs/Components/CheckboxList/styles"
import {
  FormWrap,
  FormTitle,
  HeaderWrap,
  CancelOrGoBackButton,
  CTAWrap,
  FormFieldWrap,
  CoAgentWrap,
  ListRowWrap,
} from "./commonStyles"
import {
  BlueButton,
  DangerButton,
} from "../../../../commonStyles"
import CoAgentIcon from "images/icons/co-agent-icons"
import RemoveAgentBinIcon from "images/icons/remove-agent-bin-icon"
import DeleteModal from "dumbComponents/Modals/DeleteModal.js";

const { List } = Form

/**
 *
 * @param {*} param0
 * @returns
 */

const BuyerAgentForm = ({
  onFinish,
  onFinishFailed,
  title,
  prefilledData,
  handleEditModeChange,
  isAddCoAgentForm,
  deleteAgent,
  form,
  isFetching,
  handleOnTypeChangeForBuyerAgentInfo,
  buyerAgentInformationFormRef,
  buyersAgentInformationObject,
}) => {
  const [isRadiusAgentBool, setRadiusAgentBoolForCoAgent] = useState({})
  const [isRemoveAgentModalVisible, setIsRemoveAgentModalVisible] = useState(false);
  const [agentToRemove, setAgentToRemove] = useState(null);

  useEffect(() => {
    if (prefilledData?.length) {
      const values = getInitialValues();
      if(buyerAgentInformationFormRef){
        buyerAgentInformationFormRef.setFieldsValue(values);
      }
    }
  }, [prefilledData, buyerAgentInformationFormRef]);

  // helper functions to manage name states 
  const openRemoveAgentModal = (name) => {
    setAgentToRemove(name);
    setIsRemoveAgentModalVisible(true);
  };

  const formRef = useRef(null)
  const getInitialValues = () => {
    let initValues = {}
    console.log("prefilledData buyerAgent", prefilledData)
    if (prefilledData) {
      initValues = {
        ...prefilledData[0],
      }
      initValues.phone = normalizePhone(initValues.phone)
      if (prefilledData.length > 1) {
        const sliced = prefilledData.slice(1)
        initValues.co_agent_details = sliced
      }
    }
    console.log("initValues buyeragent", initValues)

    return initValues
  }
  const saved = "Saved"
  const isSaving = "...Saving"
  const handleOnValuesChange = (changedValues, allValues) => {
    console.log(changedValues, allValues, "changedValues")
    handleOnTypeChangeForBuyerAgentInfo(changedValues, allValues)
  }
  return (
    <FormWrap
      isAddCoAgentForm={isAddCoAgentForm}
    >
      <HeaderWrap>
        <FormTitle>
          <p>{title || "Agent Form"}</p>
        </FormTitle>
        {/* <FormTitle>
          {isFetching === true ? isSaving : saved}
        </FormTitle> */}
      </HeaderWrap>

      <Form
        name="offers_buyer_agent_information"
        preserve
        colon={false}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={getInitialValues()}
        ref={formRef}
        onValuesChange={handleOnTypeChangeForBuyerAgentInfo}
        form={buyerAgentInformationFormRef}
      >
        {/**HIDDEN FIELDS*/}
        <HiddenInputField name="agent_id" />
        <HiddenInputField name="brokerage" />
        <HiddenInputField name="landline" />
        <HiddenInputField name="is_primary"/>

        <FormFieldWrap>
          {isAddCoAgentForm ? (
            <>
              <AgentSearch
                name="agent_first_name"
                inputType="text"
                placeholder="Co-Agent's Name"
                customRules={{}}
                isFormField
                formRef={formRef}
                text="Agent First Name"
                required="true"
              />

              <InputField
                name="agent_last_name"
                inputType="text"
                placeholder="Last Name"
                customRules={{}}
                text="Agent Last Name"
                required="true"
              />
            </>
          ) : (
            <>
              <InputField
                name="agent_first_name"
                inputType="text"
                placeholder="First Name"
                text="Agent First Name"
                required="true"
              />

              <InputField
                name="agent_last_name"
                inputType="text"
                placeholder="Last Name"
                customRules={{}}
                text="Agent Last Name"
                required="true"
              />
            </>
          )}
        </FormFieldWrap>
        
        <FormFieldWrap>
          <InputField
            name="email"
            inputType="email"
            type="email"
            placeholder="Email"
            text="Email"
            required="true"
          />

          <InputNumber
            name="phone"
            inputType="phone"
            type="phone"
            placeholder="Phone"
            text="Phone"
            required={false}
          />
        </FormFieldWrap>

        <FormFieldWrap>
          <InputField
            name="agent_license_number"
            inputType="text"
            type="text"
            placeholder="Agent License Number"
            text="Agent License Number"
            required={false}
          />
          {console.log("formRef", formRef)}
          <InputField
            name="brokerage_license_number"
            inputType="text"
            type="text"
            placeholder="Brokerage License Number"
            text="Brokerage License Number"
            required={false}
          />
        </FormFieldWrap>

        {/**Requires Google Address*/}
        <FormFieldWrap>
          <InputLocation
            name="brokerage_address"
            inputType="text"
            type="text"
            placeholder="Brokerage Street Address"
            formRef={formRef}
            text="Brokerage Street Address"
            //required={false}
            // requiredMessage="Required"
          />

          <InputField
            name="city"
            inputType="text"
            type="text"
            placeholder="City"
            text="City"
          />
        </FormFieldWrap>

        <FormFieldWrap>
          <InputField
            name="state"
            inputType="text"
            type="text"
            placeholder="State"
            text="State"
          />

          <InputField
            name="zipcode"
            inputType="text"
            type="text"
            placeholder="Zipcode"
            text="Zipcode"
          />
        </FormFieldWrap>
        <List name="co_agent_details">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ name }) => (
                <CoAgentWrap>
                  <DeleteModal visible={isRemoveAgentModalVisible} onOk={(e) => {
                        e.stopPropagation();
                        if (agentToRemove !== null) {
                          remove(agentToRemove);
                          setAgentToRemove(null);
                        }
                        setIsRemoveAgentModalVisible(false);
                      }}
                      onCancel={() => setIsRemoveAgentModalVisible(false)} />
                  <HeaderWrap>
                    <FormTitle>
                      <p> <CoAgentIcon/> Co-Buyer Agent Information</p>
                    </FormTitle>
                    <CancelOrGoBackButton
                      onClick={() => {
                        openRemoveAgentModal(name)
                      }}
                    >
                      <RemoveAgentBinIcon/>
                      <p> Remove </p>
                    </CancelOrGoBackButton>
                  </HeaderWrap>
                  {/* <HeaderWrap>
                    <ListRowWrap>
                      <StyledCheckbox
                        value="here"
                        onChange={(e) => {
                          const {
                            target: {
                              checked,
                            },
                          } = e || {}
                          setRadiusAgentBoolForCoAgent(existingValues => ({
                            ...existingValues,
                            [name]: checked,
                          }))
                        }}
                      >
                        Is agent part of Radius ?
                      </StyledCheckbox>
                    </ListRowWrap>
                  </HeaderWrap> */}
                  <FormFieldWrap>
                    {/* {isRadiusAgentBool[name] ? (
                      <AgentSearch
                        name={[name, "agent_first_name"]}
                        inputType="text"
                        placeholder="Co-Agent's Name"
                        customRules={{}}
                        isFormField
                        firstNameLabel="agent_first_name"
                        lastNameLabel="agent_last_name"
                        formRef={formRef}
                        text="Agent First Name"
                        formName="co_agent_details"
                        fieldIndex={name}
                        required="true"
                      />
                    ) : (
                      <InputField
                        name={[name, "agent_first_name"]}
                        inputType="text"
                        placeholder="First Name"
                        customRules={{}}
                        text="Agent First Name"
                        required="true"
                      />
                    )} */}
                    <AgentSearch
                        name={[name, "agent_first_name"]}
                        inputType="text"
                        placeholder="Co-Agent's Name"
                        customRules={{}}
                        isFormField
                        firstNameLabel="agent_first_name"
                        lastNameLabel="agent_last_name"
                        formRef={formRef}
                        text="Agent First Name"
                        formName="co_agent_details"
                        fieldIndex={name}
                        required="true"
                        isFromOffer={true}
                      />
                    <InputField
                      name={[name, "agent_last_name"]}
                      inputType="text"
                      placeholder="Last Name"
                      customRules={{}}
                      text="Agent Last Name"
                      required="true"
                    />
                  </FormFieldWrap>
                  <FormFieldWrap>
                    <InputField
                      name={[name, "email"]}
                      inputType="email"
                      type="email"
                      placeholder="Email"
                      text="Email"
                      required="true"
                    />

                    <InputNumber
                      name={[name, "phone"]}
                      inputType="phone"
                      type="phone"
                      placeholder="Phone"
                      text="Phone"
                      required={false}
                    />
                  </FormFieldWrap>
                  <FormFieldWrap>
                    <InputField
                      name={[name, "brokerage"]}
                      inputType="text"
                      type="text"
                      placeholder="Brokerage"
                      text="Brokerage"
                      required="true"
                    />

                    <InputField
                      name={[name, "agent_license_number"]}
                      inputType="text"
                      type="text"
                      placeholder="Agent License Number"
                      text="Agent License Number"
                      required={false}
                    />
                  </FormFieldWrap>
                  <FormFieldWrap>
                    <InputField
                      name={[name, "brokerage_license_number"]}
                      inputType="text"
                      type="text"
                      placeholder="Brokerage License Number"
                      text="Brokerage License Number"
                      required={false}
                    />

                    <InputLocation
                      name={[name, "brokerage_address"]}
                      inputType="text"
                      type="text"
                      placeholder="Brokerage Street Address"
                      formRef={formRef}
                      fieldIndex={name}
                      formName="co_agent_details"
                      text="Brokerage Street Address"
                      required={false}
                      // requiredMessage="Required"
                    />
                  </FormFieldWrap>
                </CoAgentWrap>
              ))}
              <FormFieldWrap>
                <CTAWrap>
                <AddButton 
                    name="ADD A CO-BUYER AGENT" 
                    onClick={() => add({
                      agent_first_name: '',
                      agent_last_name: '',
                      email: '',
                      phone: '',
                      brokerage: '',
                      agent_license_number: '',
                      brokerage_license_number: '',
                      brokerage_address: '',
                      city: '',
                      state: '',
                      zipcode: ''
                    })} 
                  />
                </CTAWrap>
              </FormFieldWrap>
            </>
          )}
        </List>
      </Form>
    </FormWrap>
  )
}

export default BuyerAgentForm
