import React from "react"
import styled from "styled-components"
import Box from "@ui/Box"
import DotLoader from "@ui/ThreeDotLoader"
import { SaveButton } from "./EditOptions/commonStyles"
import LoaderButton from "@ui/LoaderButton"

const Wrapper = styled.div`
  background: #FFF;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  margin-bottom: 20px;
  font-family: 'DM Sans', sans-serif;
`

const H2 = styled.h2`
  marging: 0;
  font-size: 18px;
  margin: 0px auto;
  font-weight: 500;
`

const P = styled.p`
  margin: 0;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 300;
`

const FollowListWrap = styled.ul`
  padding: 20px;
  background: #FFF;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  position: absolute;
  top: 40px;
  width: 270px;
  max-height: 500px;
  overflow-y: scroll;
  z-index: 1000;

  li {
    display: flex;
    margin: 5px 0px;
    div.image_wrap {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background: #CCC center no-repeat;
      background-size: cover;
    }

    div.name_wrap {
      margin: 0px 10px;
      flex: 1;
      text-align: left;
      font-weight: 300;
      font-size: 14px;
      color: #354052;
    }

    div.follow_wrap {
      div {
        color: #5A5FF2;
        font-size: 14px;
        font-weight: 300;
        text-decoration: none;
      }
    }
  }
`

const Unfollow = styled.span`
  color: #3c4858;
  border-bottom: 1px solid #3c4858;
  cursor: pointer;
`

const Follow = styled.span`
  color: #5A5FF2;
  border-bottom: 1px solid #5A5FF2;
  cursor: pointer;
`

const EmptyButton = styled.a`
  font-size: 14px;
  text-align: center;
  width: 100%;
  display: block;
  padding: 18px;
  text-decoration: none;
  color: #5A5FF2;
`

const createName = (first = "", last = "") => {
  if (first || last) {
    return `${first || ""} ${last || ""}`
  }
  return "Unknown"
}

const FollowList = ({
  list,
  followAgent,
  type,
  compareList = [],
  unfollowAgent,
  agentId,
  isShowFollowLoader,
  ownFollowing = [],
  isLogged,
}) => (
  <FollowListWrap>
    {list && list.length > 0 && list.map(item => (
      <li key={item.id}>
        <div className="image_wrap" style={{ backgroundImage: `url(${item.agent_image})` }}></div>
        <div className="name_wrap">{createName(item.firstname, item.lastname)}</div>
        <div className="follow_wrap">
          { isShowFollowLoader === item.id
            ? <DotLoader />
            : (
              isLogged && (
                <div>
                  <React.Fragment>
                    { ownFollowing && ownFollowing.filter(x => x.id === item.id).length !== 0
                      ? (
                        <Unfollow
                          onClick={() => { unfollowAgent({ unfollowId: item.id, activeId: agentId }) }}
                        >
                          Unfollow
                        </Unfollow>
                      ) : (
                        <Follow onClick={() => { followAgent(item) }}>Follow</Follow>
                      )}
                  </React.Fragment>
                  
                </div>
              )
            )
          }
        </div>
      </li>
    ))}
  </FollowListWrap>
)

const BORDER = "1px solid #eaeaea"

class FollowStat extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowPanel: false,
    }
  }

  componentDidMount() {
    this.setState({
      isMounted: true,
    })
  }

  handleMouseEnter = (type) => {
    this.setState({
      isShowPanel: type,
    })
  }

  handleMouseExit = () => {
    this.setState({
      isShowPanel: false,
    })
  }

  render() {
    const {
      followers = [],
      following = [],
      isLogged,
      handleClick,
      isOwnProfile,
      unfollowAgent,
      agentId,
      isShowFollowLoader,
      ownFollowersAndFollowing = {},
      agent,
      unblockUser,
      isUnblockingAgent,
    } = this.props
    console.log(this.props);
    const {
      following: ownFollowing,
      isMounted,
    } = ownFollowersAndFollowing
    const { isShowPanel } = this.state
    return (
      <Wrapper>
        <Box d="flex" ta="center">
          <Box
            style={{ borderRight: BORDER, borderBottom: BORDER, padding: 10 }}
            flex="1"
            onMouseEnter={() => { this.handleMouseEnter("followers") }}
            onMouseLeave={() => { this.handleMouseExit() }}
            position="relative"
          >
            <H2>{followers.length || 0 }</H2>
            <P>Followers</P>
            { isShowPanel === "followers" && followers.length !== 0 && (
              <FollowList
                agentId={agentId}
                list={followers}
                compareList={following}
                type="followers"
                unfollowAgent={unfollowAgent}
                followAgent={handleClick}
                isShowFollowLoader={isShowFollowLoader}
                ownFollowing={ownFollowing}
                isLogged={isLogged}
              />
            )}
          </Box>
          <Box
            position="relative"
            style={{ borderBottom: BORDER, padding: 10 }}
            flex="1"
            onMouseEnter={() => { this.handleMouseEnter("following") }}
            onMouseLeave={() => { this.handleMouseExit() }}
          >
            <H2>{following.length || 0}</H2>
            <P>Following</P>
            { isShowPanel === "following" && following.length !== 0 && (
              <FollowList
                agentId={agentId}
                list={following}
                type="following"
                unfollowAgent={unfollowAgent}
                followAgent={handleClick}
                isShowFollowLoader={isShowFollowLoader}
                ownFollowing={ownFollowing}
                isLogged={isLogged}
              />
            )}
          </Box>
        </Box>
        {agent && agent.is_blocked ? (
          <Box d="flex" fd="column" jc="center" ai="center" p="20px">
            <SaveButton
              isShowLoader={isUnblockingAgent}
              bsStyle="attention"
              onClick={unblockUser}
            >
              Unblock
            </SaveButton>
          </Box>
        ) : (
          <React.Fragment>
            { ownFollowing
              && ownFollowing.length > 0
              && ownFollowing.filter(x => x.id === agentId).length !== 0
              && (
                <Box d="flex" fd="column" jc="center" ai="center" p="20px">
                  <SaveButton
                    isShowLoader={isShowFollowLoader}
                    onClick={() => {
                      unfollowAgent({ unfollowId: agentId, activeId: agentId })
                    }}
                  >
                    Unfollow
                  </SaveButton>
                </Box>
              ) }
          </React.Fragment>
        )}
        {agent && !agent.is_blocked && (
          <React.Fragment>
            { !isOwnProfile
              && isLogged
              && ownFollowing
              && ownFollowing.filter(x => x.id === agentId).length === 0
              ? (
                <React.Fragment>
                  <Box d="flex" fd="column" jc="center" ai="center" p="20px">
                    <SaveButton
                      isShowLoader={isShowFollowLoader}
                      bsStyle="secondary"
                      onClick={() => {
                        handleClick(agent)
                      }}
                    >
                      Follow
                    </SaveButton>
                  </Box>
                </React.Fragment>
              ) : (
                !isLogged && (
                  <Box d="flex" fd="column" jc="center" ai="center" p="20px">
                    <a href={`${WEB_URL}login`}>
                      <SaveButton bsStyle="secondary">
                        Follow
                      </SaveButton>
                    </a>
                  </Box>
                )
              )}
          </React.Fragment>
        )}
        
        { isMounted && isOwnProfile && following.length === 0 && followers.length === 0 && (
          <EmptyButton href="http://network.radiusagent.com" target="_blank">
            Expand your network (232)
          </EmptyButton>
        )}
      </Wrapper>
    )
  }
}

export default FollowStat
