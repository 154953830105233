import React from "react"
import Button from "@ui/Button"
import { ModalButtons, ModalWrap } from "dumbComponents/VideoRooms/Modal/common"

const KickUserOutModal = ({
  onCancel,
  onKick,
}) => (
  <ModalWrap>
    <h3>
      Are you sure you want to kick this user?
    </h3>
    <ModalButtons>
      <Button
        bsStyle="marineBLueFill"
        style={{
          borderRadius: "18px",
          width: "50%",
          fontWeight: "300",
        }}
        onClick={onKick}
      >
        Kick
      </Button>
      <Button
        bsStyle="marineBLueOutlined"
        style={{
          borderRadius: "18px",
          width: "50%",
          fontWeight: "300",
          border: "1.5px solid #5A5FF2",
        }}
        onClick={onCancel}
      >
        Cancel
      </Button>
    </ModalButtons>
  </ModalWrap>
)

export default KickUserOutModal
