import React from "react"
import styled from "styled-components"
import CRMV2Container from "container/CRM"
import { get } from "lodash"
import { ATC } from "@fonts"
import colors from "@colors"
import LoaderGif from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/loader-gif.gif"
import donwloadArrow from "dumbComponents/Listings/images/download_1.svg"
import SuccessImage from "dumbComponents/FinancingDashboard/images/Success.png"
import { ShimmerBox } from "shared/styles/animation"
import { Button } from "@ui/antd"
import { handleDownload } from "./utils"

const Wrap = styled.div`
  margin-bottom: 15px;
`

const Container = styled.div`
  height: 53px;
  background: #5A5FF2;
  display: flex;
  align-items: center;
  padding-left: 15px;

  ${props => !props.justify && `
    justify-content: space-between;
    padding: 0px 15px;
  `}
`

const Title = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #F9F9F7;
  margin: 0;
`

const ImageWrap = styled.div`
  display: flex;
  align-items: center;
  width: 400px;
  margin-left: 12px;
  justify-content: space-between;
`

const DownloadButtonWrap = styled.div`
  display: flex;
  gap: 5px;
`

const DownloadButton = styled.p`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  margin: 0;

  &:hover {
    color: ${colors.new_primary};
  }
`

const MortgageBanner = ({
  getFinancingForTransactionResponse,
}) => {
  const { data: financeData, isFetching } = getFinancingForTransactionResponse || {}
  const incompleteApplication = financeData && financeData.length > 0
  const downloadUrl = !isFetching && financeData && get(financeData, "[0].pre_approved_letter_url")
  const letterAvailable = !isFetching && financeData && get(financeData, "[0].is_pre_approval_letter_available")
  const currentTitle = letterAvailable ? "Client pre-approval letter is ready."
    : (incompleteApplication ? "Client pre-approval letter is in progress." : "")
  return (
    <Wrap>
      {isFetching ? (
        <ShimmerBox
          h="53px"
        />
      ) : (
        <Container
          justify={letterAvailable}
        >
          <Title>
            {currentTitle}
          </Title>
          {/* when Michael has uploaded the PDF from SOUL, we show the download option */}
          {letterAvailable && (
            <ImageWrap>
              <Button
                onClick={() => {
                  handleDownload(downloadUrl, "pre-approval completed")
                }}
                style={{
                  borderRadius: "4px",
                  borderColor: `${colors.new_primary}`,
                }}
              >
                <DownloadButtonWrap>
                  <DownloadButton>
                    Download
                  </DownloadButton>
                  <img src={donwloadArrow} alt="download arrow" />
                </DownloadButtonWrap>
              </Button>
              <img src={SuccessImage} alt="success" height={53} />
            </ImageWrap>
          )}
          {/* when agent has submitted the mortgage application, we show the in progress bar */}
          {(incompleteApplication && !letterAvailable) && (
            <img
              src={LoaderGif}
              alt="Loader gif"
              height={25}
              width={25}
            />
          )}
        </Container>
      )}
    </Wrap>
  )
}

export default CRMV2Container(MortgageBanner)
