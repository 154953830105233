import React, { useState } from "react"
import OffersV2 from "container/ListingsV2/index"
import Listings from "container/Listings"
import OldOffers from "container/TechAssets"
import AppContainer from "container/App"
import createToast from "dumbComponents/common/Toast/customToast"
import {
  CTAWrap,
  SectionTitle,
  SectionWrap,
  BlueButton,
} from "dumbComponents/ListingsV2/commonStyles"
import {
  CloseButton,
  HeaderWrap,
} from "./styles"
import {
  EnvelopesWrap,
} from "../styles"

import Form from "./Form"
import Attachments from "./Attachments"

const SendOffer = ({
  goBack,
  completeOfferPackageToBeSent,
  sendOfferPackageMail,
  sendOfferPackageMailResponse,
  fetchUserInfo,
  propertyInformationObject,
  buyersAgentInformationObject,
  user,
  sendListingPackageMail,
  sendListingPackageMailResponse,
  listingIdToView,
  completeListingPackageToBeSent,
  currentBrokerageType,
  dealToView,
  offerIdToView,
}) => {
  const {
    isFetching: sendingListingPackage,
  } = sendListingPackageMailResponse || {}
  console.log("propertyInformationObject envelopecard", propertyInformationObject)
  console.log("buyersAgentInformationObject", buyersAgentInformationObject)
  const {
    envelopes,
  } = completeListingPackageToBeSent || {}
  const handleSend = (values) => {
    fetchUserInfo()
    const {
      from,
      from_email,
      to,
      to_email,
      cc,
      cc_email,
      offer_package_subject,
      custom_message,
      original_message,
    } = values || {}

    console.log("values --> ",values);


    if (currentBrokerageType === 'listing') {
      createToast("Sending listing package...")
    } else {
      createToast("Sending offer package...")
    }
    const v2Docs = envelopes.map(item => ({
      id: item.id,
      documents: [
        ...item.documents,
      ],
    }))

    let emailBody = ""
    emailBody = custom_message.replace(/\n/g, "<br>")

    const getCcEmail = () => {
      if (typeof cc_email === "string") {
        return [{
          name: "",
          email: cc_email,
        }]
      } if (Array.isArray(cc_email) && cc_email.length) {
        return cc_email.map(email => ({
          name: "",
          email,
        }))
      }

      return ""
    }

    const payload = {
      subject: offer_package_subject,
      listingId: listingIdToView,
      body: emailBody,
      to: [
        {
          name: to_email,
          email: to_email,
        },
      ],
      cc: getCcEmail(),
      from: {
        name: `${user.firstname} ${user.lastname}`,
        email: `${user.email}`
      },
      docusignEnvelopesV2: v2Docs || [],
      entityType: "offerPackage",
      entityId: listingIdToView,
    }
    const payloadOffer = {
      subject: offer_package_subject,
      offerId: offerIdToView || dealToView,
      body: emailBody,
      to: [
        {
          name: to_email,
          email: to_email,
        },
      ],
      cc: getCcEmail(),
      from: {
        name: `${user.firstname} ${user.lastname}`,
        email: `${user.email}`
      },
      docusignEnvelopesV2: v2Docs || [],
      entityId: listingIdToView,
    }
    if(currentBrokerageType == "offers"){
      sendListingPackageMail({
        type: currentBrokerageType,
        payload: payloadOffer,
      })
    } else {
      sendListingPackageMail({
        type: currentBrokerageType,
        payload,
      })
    }
    console.log(payload)
    goBack()
  }

  return (
    <EnvelopesWrap>
      <SectionWrap style={{ height: '90vh'}}>
        <Form
          onFinish={handleSend}
          sendingOfferPackage={sendingListingPackage}
          propertyInformationObject={propertyInformationObject}
          buyersAgentInformationObject={buyersAgentInformationObject}
        >
            <Attachments currentBrokerageType={currentBrokerageType}/>

        </Form>
      </SectionWrap>
    </EnvelopesWrap>
  )
}

export default Listings(AppContainer(OldOffers(OffersV2(SendOffer))))
