import React, { useState, useEffect } from "react"
import Container from "container/Settings/SubscriptionsContainer"
import SettingsContainer from "container/Settings"
import { ShimmerBox } from "shared/styles/animation"
import Navbar from "container/Navbar"
import moment from "moment"
import { isEmpty, get, sortBy } from "lodash"
import ReferralContainer from "container/Referral"
import CookiesStorage from "services/CookieStorage"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import CancelAddPayment from "dumbComponents/common/SettingsTab/Billings/Modals/CancelledAddPayment"
import SuccessModal from "dumbComponents/common/SettingsTab/Billings/Modals/SuccesModal"
import DeclineMethod from "dumbComponents/common/SettingsTab/Billings/Modals/DeclinedMethod"
import {
  AlertDiv,
  InfoDiv,
  CommonText,
  AlertButton,
} from "./styles"

const ExpiredBanner = ({
  getSubscriptionPlans,
  getSubscriptionPlansResponse,
  fetchPayment,
  fetchPaymentResponse,
  fetchAddOns,
  paymentObject,
  subscriptionStatus,
  setLoadingCard,
  subscriptionStatusObject,
  isFetchingSubscription,
  addSubscriptionPlan,
  makeDefaultMethod,
  getBrokerAddons,
  brokerAddonsResponse,
  fetchSubscriptionStatusResponse,
  fetchSubscriptionStatusEOIResponse,
}) => {
  const [bannerOpen, setBannerOpen] = useState(false)
  const [initialBillingData, setBillingDate] = useState(null)
  const [nonActivePlan, setNonActivePlan] = useState(null)
  const [defaultPaymentObject, setDefaultPayment] = useState(null)
  const agentId = CookiesStorage.load("agentId")
  const [errorValue, setErrorValue] = useState(null)
  const [isSuccess, setSuccess] = useState(false)
  const [declineCard, setDeclineCard] = useState(false)
  const closeDeclineModal = () => {
    setDeclineCard(false)
  }
  const handleDeclineCard = () => {
    setDeclineCard(false)
    setLoadingCard(false)
  }
  const openUpdateModal = () => {
    console.log("shrungiKatre open")
    setBannerOpen(true)
  }
  const closeUpdateModal = () => {
    setBannerOpen(false)
  }
  const closeSuccessModal = () => {
    setSuccess(false)
    window.location.reload()
  }
  const {
    isFetching,
    data: plansList,
  } = getSubscriptionPlansResponse || {}

  
  const {
    isFetching: fetchingSubsStatus,
  } = fetchSubscriptionStatusResponse || {}

  const {
    isFetching: fetchingPayments,
  } = fetchPaymentResponse || {}

  const  {
    isFetching: fetchingAddon,
    data: addOns
  } = brokerAddonsResponse || {}


  const EOIsubscriptionStatus = CookiesStorage.load("EOIstatusForSubscription")
  const subscriptionStatusForBanner = CookiesStorage.load("subscriptionStatus")
  const negativeStatuses = ["incomplete", "past_due", "paused", "incomplete_expired", "overdue", "canceled", "unpaid"];
const isEOIOrSubsStatusNegative = 
    negativeStatuses.includes(EOIsubscriptionStatus) || 
  negativeStatuses.includes(subscriptionStatusForBanner);
  

  useEffect(() => {
    getSubscriptionPlans({
      callback: () => {
        if (subscriptionStatus && subscriptionStatus != "incomplete_expired") {
          fetchPayment()
          getBrokerAddons()
        }
      },
    })
  }, [])

  useEffect(() => {
    if (subscriptionStatus && subscriptionStatus != "incomplete_expired") {
      fetchPayment()
      getBrokerAddons()
    }
  }, subscriptionStatus)

  useEffect(() => {
    if (subscriptionStatusObject && plansList) {
      setBillingDate(subscriptionStatusObject.start_date)
      let currentPlan
      let planFromSub
      const { data: items } = subscriptionStatusObject.items
      if (items && items.length) {
        for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
          const currentItem = items[itemIndex]
          const currentPlanName = get(currentItem, "plan.id")
          if (currentPlanName && currentPlanName.includes("broker")) {
            currentPlan = items[itemIndex].plan
            if (plansList && plansList.single_plans.broker) {
              planFromSub = plansList.single_plans.broker.find(x => x.plan_id === currentPlan.id)
              if (planFromSub) {
                setNonActivePlan(planFromSub)
                fetchAddOns({
                  planId: planFromSub.plan_id,
                  agentId,
                })
              }
            }
          } else if (currentPlanName && currentPlanName.includes("rn")) {
            currentPlan = items[itemIndex].plan
            if (plansList && plansList?.single_plans?.rn) {
              planFromSub = plansList?.single_plans?.rn.find(x => x.plan_id === currentPlan.id)
              if (planFromSub) {
                setNonActivePlan(planFromSub)
                fetchAddOns({
                  planId: planFromSub.plan_id,
                  agentId,
                })
              }
            }
          }
        }
      }
    }
  }, [subscriptionStatusObject, plansList])
  const handleAddCardDetails = ({value, status="success"}) => {
    if(status == "success" || status == "succeeded"){
      if (value) {
      makeDefaultMethod({
        callback: () => {
          const stripePlanId = nonActivePlan?.stripe_plan_id
          const addOnPlanIds = (addOns || [])
            .map(addOn => ({
            plan_id: addOn?.pricing?.plan_id,
            id: addOn?.id
          }))
          .filter(addOn => addOn.plan_id && addOn.id);
        const payload = {
        plansMap: [
        {
          planId: stripePlanId,
          couponCode: "",
        },
      ],
      add_on_plan_ids: addOnPlanIds.length > 0 ? addOnPlanIds : []
    }
          addSubscriptionPlan({
            payload,
            callback: (res) => {
              console.log("here back in loop", res)
              setLoadingCard(false)
              setBannerOpen(false)
              setSuccess(true)
            },
          })
        },
      })
      } else {
      const stripePlanId = nonActivePlan?.stripe_plan_id
          const addOnPlanIds = (addOns || [])
            .map(addOn => ({
            plan_id: addOn?.pricing?.plan_id,
            id: addOn?.id
          }))
          .filter(addOn => addOn.plan_id && addOn.id);
        const payload = {
        plansMap: [
        {
          planId: stripePlanId,
          couponCode: "",
        },
      ],
      add_on_plan_ids: addOnPlanIds.length > 0 ? addOnPlanIds : []
    }
    console.log("payload payload", payload)
      addSubscriptionPlan({
        payload,
        callback: (res) => {
          console.log("here back in loop", res)
          setLoadingCard(false)
          setBannerOpen(false)
          setSuccess(true)
        },
      })
      }
    } else{
      setLoadingCard(false)
      setErrorValue(status)
      setBannerOpen(false)
      setDeclineCard(true)
    }
  }
  useEffect(() => {
    if (paymentObject !== null && paymentObject.length > 0) {
      const defaultOption = paymentObject.filter(
        item => item.is_default_source === true
      )
      setDefaultPayment(defaultOption)
    }
  }, [paymentObject])
  const billingDate = moment.unix(initialBillingData).format("MMMM D, YYYY")
  return (
    <>
      {
        isFetching || fetchingSubsStatus || isFetchingSubscription || fetchingPayments || fetchingAddon ? (
          // <></>
          <ShimmerBox w="100%" h="80px" />
        ) : (
          <> 
          {
            !defaultPaymentObject || isEOIOrSubsStatusNegative ? (
              <>
              {declineCard && <DeclineMethod isOpen={declineCard} toClose={closeDeclineModal} handleProceed={handleDeclineCard} handleClickForCancel={closeDeclineModal} errorValue={errorValue} custom />}
              {isSuccess && <SuccessModal isOpen={isSuccess} planDetail={nonActivePlan} toClose={closeSuccessModal} handleClickForCancel={closeSuccessModal} addOns={addOns}/>}
            {
              bannerOpen && (
                <CancelAddPayment
                  contentType="expired_banner"
                  planDetail={nonActivePlan}
                  billingDate={billingDate}
                  defaultPaymentObject={defaultPaymentObject}
                  isOpen={bannerOpen}
                  toClose={closeUpdateModal}
                  handleProceed={handleAddCardDetails}
                  handleClickForCancel={closeUpdateModal}
                  addOns={addOns}
                  expired
                />
              )
            }
            <AlertDiv>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  alignSelf: "stretch",
                }}
              >
                <InfoDiv />
                <CommonText
                  color="#AA3A3A"
                  fontSize="14px"
                  weight="500"
                  lineHeigth
                  marginTop
                >
                  Your subscription payment date is coming soon. Please update your payment method to continue using all features.
                </CommonText>
              </div>
              <AlertButton onClick={openUpdateModal}>
                <p>
                  Update payment method
                </p>
              </AlertButton>
            </AlertDiv>
              </>
            ) : <></>
          }
            
          </>
        )
      }
    </>

  )
}

export default TeamProfileContainer(ReferralContainer(Navbar(SettingsContainer(Container(ExpiredBanner)))))
