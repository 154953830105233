import React from "react"
import styled from "styled-components"
import Loader from "@ui/Loader"

const ImageUploaderWrapper = styled.div`
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
`

const DisplayBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;
  height: 186px;
  width: 520px;
`

const IconText = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #F1F3F8;
  border: 1px dashed #5A5FF2;
  box-sizing: border-box;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  text-shadow: 0px 0px 0 #999;
  transition: background-color 0.2s ease-in, color 0.2s ease-in, border-color 0.2s ease-in, text-shadow 0.2s ease-in;
  .upload-button, .cancel-upload-button {
    margin: 0 10px;
    position: relative;
    z-index: 9999;
  }
  h4 {
    text-align: center;
  }
`

const Image = styled.img`
  height: 100px;
  width: 200px;
  border-radius: 10px;
  background: white;
`

const UploadArea = styled.div`
  text-align: center;
  h4 {
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
  }
`

const RemoveImageIcon = styled.div`
  margin-left: -20px;
  margin-top: -10px;
  display: inline;
  position: absolute;
  border-radius: 10em;
  padding: 2px 6px 3px;
  -webkit-text-decoration: none;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #4f26be;
  border: 3px solid #fff;
  color: #fff;
  text-shadow: 0 1px 2px rgb(0 0 0 / 50%);
  -webkit-transition: background 0.5s;
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
`

const InputContainer = styled.div`
  ${props => !props.display && `
    display: none;
  `}
`

const ErrorNotification = styled.div`
  animation: fadeInDown 0.5s;
  position: absolute;
  top: 20px;
  background-color: #dfb852;
  border-radius: 5px;
  color: #444;
  padding: 5px 10px;
  p {
      margin: 0;
      text-shadow: none;
  }
`

class ImageUploader extends React.Component {
  constructor() {
    super()
    this.state = {
      file: null,
      dragOver: false,
    }
  }

  handleDragEnter = (e) => {
    e.preventDefault()
  }

  handleDragOver = (e) => {
    e.preventDefault()
    const { dragOver } = this.state
    if (!dragOver) {
      this.setState({
        dragOver: true,
      })
    }
  }

  handleDragLeave = (e) => {
    e.preventDefault()
    this.setState({
      dragOver: false,
    })
  }

  handleDrop = (e) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    console.log('===file, ', file)
    // Validate file is of type Image
    const fileType = file.type.split("/")[0]
    // this.refs.image.files = e.dataTransfer.files;
    document.getElementById("upload-image-input").fileList = e.dataTransfer.files[0]
    this.setState({
      file,
      dragOver: false,
    })
    this.handleAddImage(null, file)
  }

  /**
     Handle Manually (File Input) Added Files
  **/
  handleAddImage = (e, fileParam) => {
    const { saveFileCallback, s3Bucket } = this.props
    // eslint-disable-next-line react/no-string-refs
    const file = fileParam || this.refs.image.files[0]
    const fd = new FormData()
    fd.append("imageFile", file)
    const data = {
      filePath: `${s3Bucket}/${Math.random().toString(36).replace(/[^a-z]+/g, "").substr(0, 5)}`,
      config: {
        bucket: "broker",
        convertTo: "jpeg",
      },
    }
    fd.append("requestBody", JSON.stringify(data))
    this.setState({
      file,
    })
    saveFileCallback({
      fd,
    })
  }

  handleCancelFile = () => {
    const { cancelFile } = this.props
    cancelFile()
    this.setState({
      file: null,
    })
  }

  uploadArea = () => {
    const { file } = this.state
    const { fileUrl, fileUploading } = this.props
    if (fileUploading) {
      return <Loader top="0" bottom="0" style={{ margin: "100px auto" }} />
    }
    if (fileUrl) {
      return (
        <UploadArea>
          <Image src={fileUrl} alt="" />
          <RemoveImageIcon onClick={this.handleCancelFile}>&#215;</RemoveImageIcon>
          <h4>{file && file.name}</h4>
        </UploadArea>
      )
    }
    return (
      <UploadArea>
        <h4>Drag & Drop File or Browse</h4>
      </UploadArea>
    )
  }

  render() {
    const { fileUrl } = this.props
    // Show Error message if file type is not an image
    const errorNotification = this.state.errorNotification
      ? (
        <ErrorNotification>
          <p>{this.state.errorNotification}</p>
        </ErrorNotification>
      )
      : null

    return (
      <ImageUploaderWrapper>
        <DisplayBox>
          <IconText>
            <div>
              {this.uploadArea()}
            </div>
            {errorNotification}
          </IconText>
          <InputContainer display={fileUrl === null || fileUrl === ""}>
            <input
              type="file"
              ref="image"
              id="upload-image-input"
              accept="image/*"
              onDrop={this.handleDrop}
              onDragEnter={this.handleDragEnter}
              onDragOver={this.handleDragOver}
              onDragLeave={this.handleDragLeave}
              onChange={this.handleAddImage}
            />
          </InputContainer>
        </DisplayBox>
      </ImageUploaderWrapper>
    )
  }
}

export default ImageUploader
