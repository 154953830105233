import { bindActionCreators } from "redux"
import { getFormValues } from "redux-form"
import { connect } from "react-redux"
import * as AppActions from "./actions"

const mapStateToProps = (state) => {
  const { onboardingSetup } = state
  const {
    // fetchUserInfoResponse,
    fetchOrientationInfoResponse,
    uploadDocsToBucketActionResponse,
    setFormStage,
    paymentBannerVisible,
  } = onboardingSetup

  const setUpForm1 = getFormValues("SETUP_FORM_1")(state)
  const setUpForm2 = getFormValues("SETUP_FORM_2")(state)
  const setUpForm3 = getFormValues("SETUP_FORM_3")(state)
  const setUpForm4 = getFormValues("SETUP_FORM_4")(state)

  return {
    // fetchUserInfoResponse,
    fetchOrientationInfoResponse,
    uploadDocsToBucketActionResponse,
    setFormStage,
    ...onboardingSetup,
    setUpForm: {
      ...setUpForm1,
      ...setUpForm2,
      ...setUpForm3,
      ...setUpForm4,
    },
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      // fetchUserProfileInfo: AppActions.fetchUserInfoAction.request,
      fetchUserOrientationInfo: AppActions.fetchUserOrientationInfoAction.request,
      createOrientationInfo: AppActions.createUserOrientationInfoAction.request,
      updateOrientationInfo: AppActions.updateUserOrientationInfoAction.request,
      uploadDocsToBucket: AppActions.uploadDocsToBucketAction.request,
      setFormStage: AppActions.setFormStage.call,
      setFileUploading: AppActions.setFileUploading.call,

      toggleSetupPopup: AppActions.toggleSetupPopup.call,
      getOnboardingSetup: AppActions.getOnboardingSetup.request,
      createOnboardingSetup: AppActions.createOnboardingSetup.request,
      updateOnboardingSetup: AppActions.updateOnboardingSetup.request,
      getStripeSecretKey: AppActions.getStripeSecretKeyAction.request,
      getFeeSchedule: AppActions.getFeeScheduleAction.request,
      sendVerifyLink: AppActions.sendVerifyEmailLinkAction.request,
      verifyToken: AppActions.verifyEmailLinkTokenAction.request,
      getDisplayScreen: AppActions.getDisplayScreenAction.request,
      getAllDocs: AppActions.getAllICADocListAction.request,
      getReceiverViewDoc: AppActions.getReceiverViewICAAction.request,
      updateEnvelopeStatus: AppActions.updateEnvelopeStatusAction.request,
      updateAgentEmail: AppActions.updateAgentEmailAction.request,
      checkPaymentBanner: AppActions.checkPaymentBannerAction.request,
      hubspotSubscriptionRequest: AppActions.hubspotSubscriptionAction.request,
    }, dispatch),
  }
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)

