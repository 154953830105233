import React from "react"
import root from "window-or-global"
import {
  InnerTab,
  NavLink,
} from "./commonStyles"
import MyAssets from "../Icons/NewIcons/MyAssets"

const AssetsTab = ({
  routeArray,
  isBroker,
  isSubscriptionExist,
}) => (
  <InnerTab
    isActive={(routeArray.length === 2 && routeArray[1] === "marketing-assets") || routeArray[1] === "my_assets"}
  >
    <div className="icon-block-new">
      <MyAssets />
    </div>
    <div className="text-block">
      <NavLink
        to={isBroker ? "/realty/marketing-assets" : "/realty/promos?type=my_assets"}
        // to={isSubscriptionExist ? "/realty/marketing-assets" : "/realty/promos?type=my_assets"}
        isActive={(routeArray.length === 2 && routeArray[1] === "marketing-assets") || routeArray[1] === "my_assets"}
        onClick={() => {
          root.scrollTo(0, 0)
          if (root.heap) {
            root.heap.track("click_sidebar_marketingAssetsRAR_web")
          }
        }}
      >
        My Assets
      </NavLink>
    </div>
  </InnerTab>
)

export default AssetsTab
