import React from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import queryString from "query-string"
import Modal from "@ui/Modal"
import colors from "@colors"
import { withRouter } from "react-router-dom"
import { ATC } from "@fonts"
import CRMV2Container from "container/CRM"
import CloseIcon from "dumbComponents/FinancingDashboard/images/Close_5.svg"

const Wrap = styled.div``
const Header = styled.div`
  display: flex;
  align-items: baseline;
  font-family: ${ATC};
  font-style: normal;
  margin-left: 30px;
  text-align: left;
  h1 {  
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    color: ${colors.black};
    margin-bottom: -5px;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: ${colors.black};
    display: inline;
    text-align: left;
    font-weight: 200;
  }
  b {
    font-size: 14px;
    line-height: 17px;
    color: #F53F3F;
    text-align: left;
  }
`

const Content = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${colors.black};
  text-align: left;
  font-weight: 200;
`

const ContentContainer = styled.div`
  padding: 20px 20px 0px 40px;
`

const ButtonWrap = styled.div`
  display: flex;
  padding: 20px 20px 30px 20px;
  flex-direction: row-reverse;
`

const ConfirmTransactionOffer = ({
  setCurrentDrawerPage,
  toggleUserOverview,
  createTransaction,
  setIsAddOffer,
  setIsCreateWarning,
  currentCRMDealId,
  setClientID,
  getCtIdResponse,
  location,
  getClientTransactionsById,
  getClient,
}) => {
  const parsedQuery = queryString.parse(location.search)
  const { client_id, transactionId } = parsedQuery
  const {
    data: clientTransactionById,
    isFetching: fetchingData,
  } = getCtIdResponse || {}

  const {
    id: cardId,
    agent_id: agentId,
  } = clientTransactionById || {}

  return (
    <Wrap>
      <>
        <Header>
          <h1>
            Create Offer
          </h1>
        </Header>
        <ContentContainer>
          <Content>
            Would you like to create this Offer?
          </Content>
        </ContentContainer>
        <ButtonWrap>
          <LoaderButton
            bsStyle="marineBLueFill"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            loaderOption={{
              color: "white",
            }}
            onClick={(e) => {
              e.stopPropagation()
              setIsAddOffer(false)
              setIsCreateWarning(false)
              createTransaction(currentCRMDealId,agentId)
              setCurrentDrawerPage("")
              toggleUserOverview({ showUserOverviewDrawer: false })
              setClientID({
                id: "",
                deal_id: "",
              })
            }}
          >
            Add Offer
          </LoaderButton>
          <LoaderButton
            bsStyle="marineBLueOutlined"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            loaderOption={{
              color: "white",
            }}
            onClick={() => {
              setIsAddOffer(false)
              setIsCreateWarning(false)
              getClientTransactionsById({ transactionId: cardId })
              getClient({ clientId: client_id })
            }}
          >
            No, thanks
          </LoaderButton>
        </ButtonWrap>
      </>
    </Wrap>
  )
}

export default withRouter(CRMV2Container(ConfirmTransactionOffer))
