import React from "react"
import root from "window-or-global"
import NewFeature from "images/newFeature.png"
import {
  InnerTab,
  NavLink,
} from "./commonStyles"
import ReferAndEarn from "../Icons/NewIcons/ReferAndEarn"

const JoinBrokeragePage = ({
  routeArray,
  isBroker,
}) => {
  const flag = !isBroker
  return (
    <>
      {flag ? (
        <InnerTab
          isActive
        >
          <div className="icon-block-new">
            <ReferAndEarn />
          </div>
          <div className="text-block">
            <NavLink
              to="/realty/join-brokerage"
              isActive={routeArray[1] === "join_brokerage"}
              onClick={() => {
                root.scrollTo(0, 0)
                //   if (root.heap) {
                //     root.heap.track("click_sidebar_recruitAndEarnRAR_web")
                //   }
              }}
            >
              Join Brokerage
            </NavLink>
          </div>
        </InnerTab>
      ) : (<></>)}
    </>
  )
}

export default JoinBrokeragePage
