import { createSignalAction, createSimpleCreator } from "shared/reduxUtils";

const BASE = "ONBOARDING_V2";

export const toggleOnboardingStep = createSimpleCreator(
  BASE,
  "TOGGLE_ONBOARDING_STEP"
);

export const getAppStatusAction = createSignalAction(
  BASE,
  "CONSUMER_APP_GET_APP_STATUS"
);

export const addPersonalInfoAction = createSignalAction(
  BASE,
  "POST_ONBOARDING_PERSONAL_INFO"
);

export const addOnboardingInfoAction = createSignalAction(
  BASE,
  "POST_ONBOARDING_INFO"
);

export const addOnboardingStepAction = createSignalAction(
  BASE,
  "ADD_ONBOARDING_INFO"
);

export const getStepsCompletedAction = createSignalAction(
  BASE,
  "GET_STEPS_COMPLETED"
);

export const getLicenseDetailsAction = createSignalAction(
  BASE,
  "GET_LICENSE_DETAIL"
);

export const getMLSAction = createSignalAction(BASE, "GET_MLS_DETAIL");

export const getOfficeAddressAction = createSignalAction(
  BASE,
  "GET_OFFICE_ADDRESS"
);

export const getDbaInfoAction = createSignalAction(BASE, "GET_DBA_INFO");

export const getLicenseStateAction = createSignalAction(
  BASE,
  "GET_LICENSE_STATE"
);

export const addAppDataAction = createSignalAction(BASE, "ADD_APP_DATA");

export const uploadAppAssetsAction = createSignalAction(BASE, "UPLOAD_APP_ASSETS");

export const getPersonalInfoAction = createSignalAction(BASE, "GET_PERSONAL_INFO");

export const getAssociationsAction = createSignalAction(BASE, "GET_ASSOCIATIONS");

export const addAppBrandingAction = createSignalAction(BASE, "ADD_APP_BRANDING");

export const getAppBrandingAction = createSignalAction(BASE, "GET_APP_BRANDING");

export const getStepCompletedForSaasAction = createSignalAction(BASE, "GET_SAAS_STEP");

export const getMLSListAction = createSignalAction(BASE, "GET_MLS_LIST");
