import styled from "styled-components"
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const Wrap = styled.div`
  width: 100%;
`

export const TabWrap = styled.div`
  display: flex;
  gap: 10px;
  text-align: center;
  cursor: pointer;
  padding: 8px 8px 0px 8px;

  ${props => props.showNeedHelpButton && `
    padding: 16px 0px 8px 0px;
    align-items: center;
  `}
`
export const TabInfo = styled.div`
  padding: 8px;
  border-radius: 8px 8px 0px 0px;
  background: transparent;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid transparent;

  ${props => props.isActive && `
    border-radius: 8px 8px 0px 0px;
    color: var(--Color-Primary-Primary, #5A5FF2);
    border-bottom: 1px solid var(--Color-Primary-Primary, #5A5FF2);
  `}

  ${props => props.disabled && `
     cursor: not-allowed;
     opacity: 0.5;
  `}
`
export const TabTitle = styled.p`
  margin: 0;
  color: var(--Grey, #292A37);
  font-family: ${MONTSERRAT};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  text-wrap: nowrap;

  ${props => props.isActive && `
    color: var(--Color-Primary-Primary, #5A5FF2);
  `}
`
