import React from "react"
import styled from "styled-components"
import { reduxForm, Field } from "redux-form"
import colors from "@colors"
import {
  preTabletWidth,
} from "@consts"
import Button from "@ui/Button"
import { ATC } from "@fonts"
import AppleIcon from "images/home-v7/apple-login.png"
import GoogleIcon from "images/home-v7/google-login.png"
import GoogleSignIn from "dumbComponents/Auth/GoogleSignIn"
import AppleSignIn from "dumbComponents/Auth/AppleSignIn"

import {
  required,
  email,
  minLengthWithSpecialCharacters,
  passwordValidation,
} from "services/formUtils"
import {
  Input,
  PhoneInput,
  Select,
} from "@ui/Form"
import ThreeDotLoader from "@ui/ThreeDotLoader"

const Wrap = styled.form`
  
`
const min5 = minLengthWithSpecialCharacters(5)

const Spacer = styled.div`
  height: 16px;
`

const SubmitButton = styled(Button)`
  height: 40px;
  width: 118px;
  border-radius: 4px;
  background: ${colors.new_primary};
  border: none;
  //padding: 20px;
  padding: 8px 32px 12px 32px;
  margin: 27px auto;
  display: block;
  > span {
    font-size: 16px;
  }
`

const SocialLoginWrap = styled.div`
  
`

const OrWrap = styled.div`
  height: 2px;
  color: ${colors.black};
  position: relative;
  margin: 35px auto;
  background: ${colors.black};
  max-width: 300px;

  > span {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    background: ${colors.white};
    font-family: ${ATC};
    padding: 2px 5px;
  }
`

const ButtonWrap = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 45px;
  ${preTabletWidth(`
    flex-direction: column;
  `)}
`

const SocialButton = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  > span {
    font-size: 18px;
  }

  > img {
    max-height: 25px;
    margin-right: 12px;
  }

  ${props => props.isGoogle && `
    background: #E4E4E4;
    color: ${colors.black};
  `}

  ${props => props.isApple && `
    background: #000;
    color: #fff;
  `}
`

const LoginForm = ({
  handleGoogleLogin,
  handleAppleLogin,
  handleSubmit,
  isLoading,
  googleSignIn,
  formStyles = { labelBG: colors.white },
  formDetails,
  className,
}) => (
  <Wrap
    className={className}
    onSubmit={handleSubmit}
  >
    <Spacer />
    <Field
      component={Input}
      name="first_name"
      placeholder=""
      label="First Name"
      validate={[required]}
      labelStyles={{
        color: colors.black,
        background: formStyles.labelBG,
        marginBottom: "10px",
      }}
      customInputStyle={{
        background: "#ffffff",
        border: "none",
        boxShadow: "0px 1px 11px 0px #0000000D",
      }}
    />
    <Spacer />
    <Field
      component={Input}
      name="last_name"
      placeholder=""
      label="Last Name"
      labelStyles={{
        color: colors.black,
        background: formStyles.labelBG,
        marginBottom: "10px",
      }}
      customInputStyle={{
        background: "#ffffff",
        border: "none",
        boxShadow: "0px 1px 11px 0px #0000000D",
      }}
    />
    <Spacer />
    <Field
      component={Input}
      name="email"
      label="Email"
      validate={[required, email]}
      labelStyles={{
        color: colors.black,
        background: formStyles.labelBG,
        marginBottom: "10px",
      }}
      customInputStyle={{
        background: "#ffffff",
        border: "none",
        boxShadow: "0px 1px 11px 0px #0000000D",
      }}
    />
    <Spacer />
    <Field
      component={Input}
      name="password"
      label="Password"
      type="password"
      validate={[required, min5, passwordValidation]}
      labelStyles={{
        color: colors.black,
        background: formStyles.labelBG,
        fontSize: "16px",
        marginBottom: "10px",
      }}
      customInputStyle={{
        background: "#ffffff",
        border: "none",
        boxShadow: "0px 1px 11px 0px #0000000D",
      }}
    />
    <SubmitButton>
      {isLoading ? (
        <ThreeDotLoader size={20} color={colors.white} />
      ) : (
        "Submit"
      )}
    </SubmitButton>
  </Wrap>
)

export default reduxForm({
  form: "NEW_SIGNUP_FORM",
})(LoginForm)
