import { takeLatest, call, put, select } from "redux-saga/effects"
import { union } from "lodash"
import { isSuccess } from "services/Utils"
import root from "window-or-global"
import queryString from "query-string"
import * as AppActions from "container/App/actions"
import customToast from "dumbComponents/OffersV3/components/Toast/customToast"
import * as NavbarActions from "./actions"
import {
  fetchNotificationAPI,
  fetchNotificationUnreadCountAPI,
  finishOnboardingAPI,
  notificationViewedAPI,
  getSubscriptionPlansAPI,
  addSubscriptionPlanAPI,
  fetchPaymentAPI,
  editPaymentAPI,
  defaultPaymentAPI,
  deletePaymentAPI,
  fetchAddOnsAPI,
  updateSubscriptionAPI,
  fetchInvoiceDataAPI,
} from "./api"

function* fetchNotification(action) {
  try {
    const {
      count,
      startTime,
      isFetchMore,
    } = action.data || {}
    const res = yield call(fetchNotificationAPI, count, startTime)
    if (isSuccess(res)) {
      const { response } = res.data
      const oldNotifications = yield select(state => state.navbar.notifications)
      const { notification: newNotifications = [] } = response
      if (isFetchMore) {
        try {
          const newNoti = union(oldNotifications, newNotifications)
          yield put(NavbarActions.fetchNotificationAction.success({
            notification: newNoti,
          }))
        } catch (error) {
          console.log("Error", error)
        }
      } else {
        yield put(NavbarActions.fetchNotificationAction.success(response))
      }
    }
  } catch (e) {
    yield put(NavbarActions.fetchNotificationAction.failure(e))
  }
}

function* fetchNotificationUnreadCount() {
  try {
    const res = yield call(fetchNotificationUnreadCountAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(NavbarActions.fetchNotificationUnreadCountAction.success(response))
    }
  } catch (e) {
    yield put(NavbarActions.fetchNotificationUnreadCountAction.failure(e))
  }
}

function* togglePressReleaseBanner(action) {
  try {
    const { isOpen } = action.data
    const $header = root.document.querySelector(".main-navbar")
    const $sidebar = root.document.getElementById("logged-in-sidebar")
    const $indexRoute = root.document.getElementById("index-layout")
    const { search } = root.location
    const parsedQuery = queryString.parse(search)
    const isAcademy = false
    if (isOpen) {
      // $header.style
      if (isAcademy) {
        $indexRoute.style.paddingTop = "60px"
      }
      $header.style.height = parsedQuery && parsedQuery.platform === "ios" ? "80px" : "125px"
      if ($sidebar) {
        $sidebar.style.top = "100px"
      }
      yield put(AppActions.togglebannerPaddingAction.call(120))
    } else {
      $header.style.height = "71px"
      if ($sidebar) {
        $sidebar.style.top = "50px"
      }
      yield put(AppActions.togglebannerPaddingAction.call(70))
      $indexRoute.style.paddingTop = "0px"
    }
  } catch (error) {
    console.log(error)
  }
}

function* finishTabsSpotlightFlow(action) {
  try {
    const { payload } = action
    const res = yield call(finishOnboardingAPI, payload)
    if (isSuccess(res)) {
      yield put(AppActions.fetchUserInfoAction.request())
    }
  } catch (error) {
    console.log(error)
  }
}

function* handleNotificationViewed(action) {
  try {
    const {
      type,
    } = action.data
    const payload = {
      featureName: type,
    }
    const res = yield call(notificationViewedAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(NavbarActions.notificationViewedAction
        .success(response))
    }
  } catch (error) {
    yield put(NavbarActions.notificationViewedAction.failure(error))
  }
}

function* handleGetSubscriptionPlans(action) {
  try {
    const {
      callback = null,
    } = action.data || {}
    const res = yield call(getSubscriptionPlansAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      if (response) {
        const filteredObject = {
          broker: response.single_plans?.broker?.filter(plan => plan.visible === 1) || [],
          rn: response.single_plans?.rn?.filter(plan => plan.visible === 1) || [],
        }
        yield put(NavbarActions.setPlanAvailableObjectAction.call(filteredObject))
        yield put(NavbarActions.getSubscriptionPlansAction.success(response))
      } else {
        yield put(NavbarActions.setPlanAvailableObjectAction.call([]))
        yield put(NavbarActions.getSubscriptionPlansAction.success(response))
      }
      if (callback) callback()
    }
  } catch (error) {
    console.log(error)
  }
}

function* handleFetchPayment() {
  try {
    const res = yield call(fetchPaymentAPI)
    if ((isSuccess(res))) {
      const { response } = res.data
      if (response) {
        yield put(NavbarActions.setPaymentObjectAction.call(response.payment_sources))
        yield put(NavbarActions.fetchPaymentAction.success(response))
      } else {
        yield put(NavbarActions.setPaymentObjectAction.call([]))
        yield put(NavbarActions.fetchPaymentAction.success([]))
      }
      yield put(NavbarActions.setLoadingCardAction.call(false))
    }
  } catch (error) {
    yield put(NavbarActions.setPaymentObjectAction.call([]))
    yield put(NavbarActions.setLoadingCardAction.call(false))
    yield put(NavbarActions.fetchPaymentAction.failure(error))
  }
}

function* handleMakeDefautMethod(action) {
  try {
    const {
      callback,
    } = action.data || {}
    const res = yield call(fetchPaymentAPI)
    if ((isSuccess(res))) {
      const { response } = res.data
      if (response) {
        const paymentData = response?.payment_sources
        console.log("paymentData", paymentData)
        if (paymentData && paymentData?.length > 0) {
          console.log("here ", paymentData[0])
          const payload = {
            payment_method_id: paymentData[0].id,
          }
          const resDefault = yield call(defaultPaymentAPI, payload)
          if ((isSuccess(resDefault))) {
            yield put(NavbarActions.makeDefaultMethodAction.success(res))
            if (callback) callback()
          }
          yield put(NavbarActions.makeDefaultMethodAction.success(res))
        }
      } else {
        yield put(NavbarActions.makeDefaultMethodAction.success(res))
        if (callback) callback()
      }
    }
  } catch (error) {
    console.log(error)
    yield put(NavbarActions.makeDefaultMethodAction.failure(error))
  }
}

function* handleEditPayment(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    const res = yield call(editPaymentAPI, payload)
    if ((isSuccess(res))) {
      const { response } = res.data
      if (response) {
        yield put(NavbarActions.editPaymentAction.success(response))
      } else {
        console.log(res)
        yield put(NavbarActions.editPaymentAction.success([]))
      }
      if (callback) callback()
    }
  } catch (error) {
    yield put(NavbarActions.editPaymentAction.failure(error))
  }
}

function* handleDeletePayment(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    console.log("payload", payload)
    const res = yield call(deletePaymentAPI, payload)
    if ((isSuccess(res))) {
      const { response } = res.data
      if (response) {
        yield put(NavbarActions.deletePaymentAction.success(response))
      } else {
        console.log(res)
        yield put(NavbarActions.deletePaymentAction.success([]))
      }
      if (callback) callback()
    }
  } catch (error) {
    yield put(NavbarActions.deletePaymentAction.failure(error))
  }
}

function* handleDefaultPayment(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    const res = yield call(defaultPaymentAPI, payload)
    if ((isSuccess(res))) {
      const { response } = res.data
      if (response) {
        yield put(NavbarActions.defaultPaymentAction.success(response))
      } else {
        console.log(res)
        yield put(NavbarActions.defaultPaymentAction.success([]))
      }
      if (callback) callback()
    }
  } catch (error) {
    yield put(NavbarActions.defaultPaymentAction.failure(error))
  }
}

function* handleUpdateSubscription(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    console.log("here updateSubscription", payload)
    const res = yield call(updateSubscriptionAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(NavbarActions.updateSubscriptionAction.success(response))
      if (callback) {
        callback()
      }
    }
  } catch (error) {
    yield put(NavbarActions.updateSubscriptionAction.failure(error))
  }
}

function* handleInvoiceData() {
  try {
    const res = yield call(fetchInvoiceDataAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(NavbarActions.fetchInvoiceDataAction.success(response))
    }
  } catch (e) {
    yield put(NavbarActions.fetchInvoiceDataAction.failure(e))
  }
}

function* handleFetchAddOns(action) {
  try {
    const {
      planId,
      agentId,
    } = action.data
    let query = `?plan_id=${planId}`
    if (agentId) {
      query = `${query}&agent_id=${agentId}`
    }
    const res = yield call(fetchAddOnsAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(NavbarActions.fetchAddOnsAction.success(response))
    }
  } catch (error) {
    yield put(NavbarActions.fetchAddOnsAction.failure(error))
  }
}
function* handleAddSubscriptionPlan(action) {
  try {
    console.log("here subsciprion")
    const {
      payload, callback, secureId,
    } = action.data
    console.log("payload", payload) 
    const res = yield call(addSubscriptionPlanAPI, payload)
    if (isSuccess(res)) {
      console.log("res", res) 
      yield put(NavbarActions.addSubscriptionPlanAction.success(res.stripe_id))
      if (callback) {
        callback(res)
      }
    }
  } catch (error) {
    yield put(NavbarActions.addSubscriptionPlanAction.failure(error))
    customToast("Please try again", "error")
  }
}

export default function* main() {
  yield takeLatest(NavbarActions.fetchNotificationAction.REQUEST, fetchNotification)
  yield takeLatest(NavbarActions.fetchNotificationUnreadCountAction.REQUEST, fetchNotificationUnreadCount)
  yield takeLatest(NavbarActions.togglePressReleaseBannerAction.type, togglePressReleaseBanner)
  yield takeLatest(NavbarActions.finishTabsSpotlightFlowAction.REQUEST, finishTabsSpotlightFlow)
  yield takeLatest(NavbarActions.notificationViewedAction.REQUEST, handleNotificationViewed)
  yield takeLatest(NavbarActions.getSubscriptionPlansAction.REQUEST, handleGetSubscriptionPlans)
  yield takeLatest(NavbarActions.addSubscriptionPlanAction.REQUEST, handleAddSubscriptionPlan)
  yield takeLatest(NavbarActions.fetchPaymentAction.REQUEST, handleFetchPayment)
  yield takeLatest(NavbarActions.editPaymentAction.REQUEST, handleEditPayment)
  yield takeLatest(NavbarActions.deletePaymentAction.REQUEST, handleDeletePayment)
  yield takeLatest(NavbarActions.defaultPaymentAction.REQUEST, handleDefaultPayment)
  yield takeLatest(NavbarActions.fetchAddOnsAction.REQUEST, handleFetchAddOns)
  yield takeLatest(NavbarActions.updateSubscriptionAction.REQUEST, handleUpdateSubscription)
  yield takeLatest(NavbarActions.fetchInvoiceDataAction.REQUEST, handleInvoiceData)
  yield takeLatest(NavbarActions.makeDefaultMethodAction.REQUEST, handleMakeDefautMethod)
}
